import React from 'react';
import { Button, Popover, OverlayTrigger }  from 'react-bootstrap';

import UiButton from './ui-button';

import "../../resources/styles/dashboard.scss";
import "bootstrap/dist/css/bootstrap.min.css";

export default function NotificationToggle(props) {

  const News = () => {
    if (!!props.notifications && props.notifications.length > 0) {
      return true;
    } else {
      return false;
    }
  }

  const popover = (
    <Popover className={`profile-toggle-wrapper notification-toggle-wrapper${News() ? ' some-news' : ' no-news'}`} id={`popover${Math.floor(Math.random()).toString()}`}>
      <Popover.Content>
        <div>
          <ul className="notifications-list">
            {News() && props.notifications.map(news => {
              return <li><div dangerouslySetInnerHTML={{__html: news.message}}></div><UiButton close variant="link" className="close-notification gray-color" onClick={e => props.setNotifications(news.id)}></UiButton></li>
            })}
            {!News() &&
              <p className="muted smaller-text">You have no new notifications</p>
            }
          </ul>
        </div>
      </Popover.Content>
    </Popover>
  );


  return(
    <OverlayTrigger rootClose={true} trigger="click" placement="bottom-end" overlay={popover}>
      <div className="notification-icon-wrapper">
        <img src={`../../photos/icons/dashboard/SVG/alarm-${News() ? 'green' : 'gray'}.svg`} className={`notification-toggle-icon${News() ? ' new-notifications' : ' muted'}`} width="30" />
        <span className="smaller-text">{News() && props.notifications.length}</span>
      </div>
    </OverlayTrigger>
  );
};