import axios from "axios";

const dbUrl = "https://bodiometerhomerestapi-dot-project-4839952831808961167.appspot.com";
// const dbUrl = "http://localhost:54928";

export const RetrieveToken = async () => {
	try {
		var configOne = {
			method: "POST",
			url: dbUrl + "/api/token/dbo",
			headers: {
				password: "746C419785D84DC8B3A20B9195363105",
			},
		};
		var responseOne = await axios(configOne, { timeout: 5000 });
		if (responseOne.data != "Invalid Credentials") {
			return responseOne.data;
		} else {
			return "";
		}
	} catch {
		return "";
	}
};
