import React, { useState, useEffect, useRef, Fragment } from "react";
import { PayPalButton } from "react-paypal-button-v2";
import { Col, Row, Form, Alert, OverlayTrigger, Popover } from "react-bootstrap";
import axios from "axios";
import { v4 as uuidv4 } from "uuid";

import UiAlert from "./ui-alert.js";
import UiButton from "./ui-button.js";
import UiSwitch from "./ui-switch.js";
import InfoPopup from "./info-popup";
import LicenseCard from "./license-card.js";
import ConvertDate from "../../resources/helpers/convert-date.js";
import UiTable from "./ui-table.js";
import Modal from "./modal.js";
import { RetrieveToken } from "../../lib/token";
import { dbUrl } from "../../lib/config";
import { LoadPaypalAccessToken, PaypalApiUrl, PaypalProductId, PaypalClientId } from "../../lib/scripts.js";

// import "bootstrap/dist/css/bootstrap.min.css";

const License = (props) => {
	const AnnualDiscountMonths = 10; // Change this to number of months to calculate (as discount) if yearly billing selected

	const UserPricing = {
		5: 0,
		10: 50,
		20: 150,
	};

	const PatientPricing = {
		1000: 0,
		2000: 50,
		3000: 100,
		4000: 150,
		5000: 200,
		6000: 250,
		7000: 300,
		8000: 350,
		9000: 400,
		10000: 450,
	};

	const defaultDateFormat = {
		year: "numeric",
		month: "long",
		day: "numeric",
	};

	const dateLocale = "en-Us";

	const CalculateDifferenceInDays = (d1, d2) => {
		// To calculate the time difference of two dates
		let Difference_In_Time = Math.abs(d1.getTime() - d2.getTime());

		// To calculate the no. of days between two dates
		let Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);

		return Difference_In_Days;
	};

	const AddDaysToDate = (days) => {
		let now = new Date();
		now.setDate(now.getDate() + days);
		return now;
	};

	const GetInitialTotal = () => {
		var currentPlanId = props?.ActiveOrganization?.license[0]?.details?.plan ?? -1;
		var isMonthly = (props?.ActiveOrganization?.license[0]?.details?.subscription?.frequency ?? "monthly") === "monthly";
		var currentAddons = props?.ActiveOrganization?.license[0]?.details?.addons ?? {};
		var currentMaxUsers = props?.ActiveOrganization?.license[0]?.details?.maxUsers ?? 5;
		var currentMaxPatients = props?.ActiveOrganization?.license[0]?.details?.maxPatients ?? 1000;

		// get price from active plan
		let planPrice = 0;
		props.plans.filter((plan) => {
			if (plan.id === currentPlanId) {
				planPrice += plan.price;
			}
		});

		planPrice = isMonthly ? planPrice : planPrice * AnnualDiscountMonths;

		var userPrice = isMonthly ? UserPricing[currentMaxUsers] : UserPricing[currentMaxUsers] * AnnualDiscountMonths;

		var patientPrice = isMonthly ? PatientPricing[currentMaxPatients] : PatientPricing[currentMaxPatients] * AnnualDiscountMonths;

		let addonPrice = 0;

		for (let i = 0; i < props.addons.length; i++) {
			if (props.addons[i].id in currentAddons) {
				addonPrice += props.addons[i].price;
			}
		}

		addonPrice = isMonthly ? addonPrice : addonPrice * AnnualDiscountMonths;

		return planPrice + userPrice + addonPrice + patientPrice;
	};

	const InitialTotal = GetInitialTotal();

	// const CalculateNewAddonAmountForCurrentBillCyle = (activeAddons) => {
	// 	let addonPrice = 0;
	// 	let currentSubscriptionId = props?.ActiveOrganization?.license[0]?.details?.subscriptionId ?? "";
	// 	let currentExpiry = props?.ActiveOrganization?.license[0]?.details?.expiry ?? "";
	// 	let licenseAddons = props?.ActiveOrganization?.license[0]?.details?.addons ?? {};
	// 	let currentAddons = activeAddons;
	// 	if (currentExpiry !== "") {
	// 		let addons = props.addons;

	// 		let remDays = CalculateDifferenceInDays(new Date(), new Date(currentExpiry));

	// 		let remMonths = Math.floor(remDays / 30);

	// 		remDays -= remMonths * 30;

	// 		remDays = Math.ceil(remDays);

	// 		for (let i = 0; i < addons.length; i++) {
	// 			if (addons[i].id in currentAddons && !(addons[i].id in licenseAddons)) {
	// 				addonPrice += props.addons[i].price * (remDays / 30) + props.addons[i].price * remMonths;
	// 			}
	// 		}
	// 	}
	// 	console.log(addonPrice, props?.ActiveOrganization?.license[0]?.details);
	// 	return addonPrice;
	// };

	const CalculateNewAddonAmountForCurrentBillCyle = (activeAddons) => {
		var addonPrice = 0;
		var subId = props?.ActiveOrganization?.license[0]?.details?.subscriptionId ?? "";
		if (!!!subId) {
			return addonPrice;
		}
		var currentAddons = props?.ActiveOrganization?.license[0]?.details?.addons ?? {};
		if (Object.keys(currentAddons).length <= 0) {
			return addonPrice;
		}
		var frequency = props?.ActiveOrganization?.license[0]?.details?.subscription?.frequency ?? "monthly";
		var currentExpiry = props?.ActiveOrganization?.license[0]?.details?.expiry ?? "";
		var remDays = CalculateDifferenceInDays(new Date(), new Date(currentExpiry));
		var addons = props.addons;
		if (frequency == "monthly") {
			for (let i = 0; i < addons.length; i++) {
				if (addons[i].id in currentAddons && !(addons[i].id in activeAddons)) {
					addonPrice += addons[i].price * (remDays / 30);
				}
			}
			return addonPrice;
		}
		var remMonths = Math.floor(remDays / 30);
		remDays -= remMonths * 30;
		for (let i = 0; i < addons.length; i++) {
			if (addons[i].id in currentAddons && !(addons[i].id in activeAddons)) {
				addonPrice += addons[i].price * (remDays / 30) + addons[i].price * remMonths;
			}
		}
		return addonPrice;
	};

	// if the current license is a trial license
	// ?. is for optional chaining ?? for default value
	// let TrialLicense = !!props.ActiveOrganization.license && props.ActiveOrganization.license[0].details.subscription && props.ActiveOrganization.license[0].details.subscription.type && props.ActiveOrganization.license[0].details.subscription.type == "trial" ? true : false;
	const TrialLicense = !(props?.ActiveOrganization?.license ?? false) || (props?.ActiveOrganization?.license[0]?.details?.subscription?.type ?? "") == "trial" ? true : false;

	const IsLicenseCancelled =
		!!(props?.parentState?.ActiveOrganization?.license ?? false) && (props?.parentState?.ActiveOrganization?.license[0]?.details?.subscription?.status ?? "NONE").toLowerCase() === "cancelled";

	// Used to reset the form when clicking 'cancel'
	const Initial = {
		Alert: {
			show: false,
			message: "",
			variant: "",
			cta: null,
			cancel: null,
		},
		CreateLicense: 0,
		NewPlan: false,
		ActiveLicenseIndex: 0,
		CreateFormValidated: false,
		SuccessPage: false,
		Different: false,
		EditMaxPatient: false,
		CancelPlan: false,
		PlanId: null,
		PaypalSubscription: null,
		Transactions: null,
		GoToPaypalModal: false,
		CouponCode: [false, ""],
		CouponObject: null,
		CouponInValid: [false, ""],
		Success: [false, ""],
		Error: [false, ""],
		Error: [false, ""],
		PaypalFetchFailed: false,
		Monthly: props.ActiveOrganization.license && !TrialLicense ? (props.ActiveOrganization.license[0].details.subscription.frequency === "monthly" ? true : false) : false,
		ActivePlan: props.ActiveOrganization.license && !TrialLicense ? props.ActiveOrganization.license[0].details.plan : 0,
		Total: InitialTotal,
		TotalBeforeCoupon: 0,
		ActiveAddons: props.ActiveOrganization.license && !TrialLicense ? { ...props?.ActiveOrganization?.license[0]?.details?.addons } ?? {} : {}, // used which addons are active
		// MaxPatients: props.ActiveOrganization.license ? props.ActiveOrganization.license[state.ActiveLicenseIndex].details.maxPatients : 50,
		MaxUsers: props.ActiveOrganization.license && !TrialLicense ? props.ActiveOrganization.license[0].details.maxUsers : 5,
		MaxPatients: props.ActiveOrganization.license && !TrialLicense ? props.ActiveOrganization.license[0].details.maxPatients : 1000,
		NewAddonAmountCurrentBillingCyle: 0,
		AccessToken: "",
	};

	const [state, setState] = useState({
		Alert: {
			show: false,
			message: "",
			variant: "",
			cta: null,
			cancel: null,
		},
		NewPlan: false, // bool used to indicate whether a an account with a current license will create a new license (this value is being used, but the multi license feature not fully implemented)
		ActiveLicenseIndex: 0, // used for switching between multiple licenses (this value is being used, but the multi license feature not fully implemented)
		CreateFormValidated: false,
		SuccessPage: false, // bool to display success message after license creation
		Different: false, // bool to revise plan (if current plan will be changed, this gets set to true)
		CancelPlan: false, // bool
		PlanId: null, // this is the paypal plan id (gets created if creating a new license, it also gets created again even if a license already exists !== current license subscription plan id, instead check state.PaypalSubscription.plan_id)
		PaypalSubscription: null,
		Transactions: null, // this gets populated with license paypal subscription transactions if available
		GoToPaypalModal: false, // bool to show modal which explains where users can edit their paypal subscription
		CouponCode: [false, ""], // if a coupon gets entered, this this gets set to [true, '--coupon code string here--',{ --coupon object here-- }]
		CouponObject: null, // gets set with coupon data. used for setting total that subscription will be renewed at after discount period ends
		CouponInValid: [false, ""], // used to display invalid coupon message / logic
		Success: [false, ""], // used to display successful alerts
		Error: [false, ""], // used to display error alerts
		PaypalFetchFailed: false, // bool that determines whether or not paypal subscription retrieval failed
		Monthly: props.ActiveOrganization.license ? (props.ActiveOrganization.license[0].details.subscription.frequency === "monthly" ? true : false) : false, // is subscription monthly, or yearly
		ActivePlan: props.ActiveOrganization.license && !TrialLicense ? props.ActiveOrganization.license[0].details.plan : 0, // used to determine which plan has been selected (active)
		Total: InitialTotal, // used to determine total price
		TotalBeforeCoupon: 0, // used to determine total price before coupon discount
		ActiveAddons: props.ActiveOrganization.license && !TrialLicense ? { ...props?.ActiveOrganization?.license[0]?.details?.addons } ?? {} : {}, // used which addons are active
		// MaxPatients: props.ActiveOrganization.license ? props.ActiveOrganization.license[state.ActiveLicenseIndex].details.maxPatients : 50, // max patient factor was removed, but could be added again?
		MaxUsers: props.ActiveOrganization.license && !TrialLicense ? props.ActiveOrganization.license[0].details.maxUsers : 5, // determines max user value for plan, factors in price
		MaxPatients: props.ActiveOrganization.license && !TrialLicense ? props.ActiveOrganization.license[0].details.maxPatients : 1000, // determines max user value for plan, factors in price
		NewAddonAmountCurrentBillingCyle: 0,
		AccessToken: "",
	});

	// hide success/error alert after 4 seconds
	useEffect(() => {
		const timer = setTimeout(() => {
			setState((prev) => ({ ...prev, Success: [false, ""] }));
		}, 4000);
		return () => clearTimeout(timer);
	}, [state.Success]);

	// Dates for fetching transactions
	const Today = new Date();
	const TodayMonth = Today.getMonth();
	const TodayCopy = new Date(Today); // these get mutated
	const TodayCopy2 = new Date(Today); // these get mutated
	const TodayCopy3 = new Date(Today); // these get mutated
	const TwoYearsAgo = new Date(TodayCopy.setMonth(TodayMonth - 24));
	const OneMonthFromToday = new Date(TodayCopy2.setMonth(TodayMonth + 1)).toLocaleDateString();
	const OneYearFromToday = new Date(TodayCopy3.setMonth(TodayMonth + 12)).toLocaleDateString();

	// returns the number of days between today and the input date (string, like 12/01/2021). if number is negative eg: -4, then the date is in the future, else it's in the past
	const DateDifference = (date) => {
		let inputDate = new Date(date);
		let today = Date.now();
		let diff_ms = today - inputDate;
		let diffDays = Math.floor(diff_ms / 86400000);

		return diffDays;
	};

	const HandleDeleteLicense = async () => {
		props.setState({ ...props.parentState, Preloader: true });

		try {
			var dbToken = await RetrieveToken();

			if (!!dbToken) {
				const deleteLicensePostHeaders = {
					headers: {
						Authorization: "Bearer " + dbToken,
						licenseKey: props.parentState.ActiveOrganization.license[state.ActiveLicenseIndex].details.licenseKey,
					},
					timeout: 10000,
				};

				axios
					.delete(dbUrl + "/api/businesslicenses/v1/remove/licensekey", deleteLicensePostHeaders)
					.then((deleteLicenseRes) => {
						if (deleteLicenseRes.data.success) {
							props.setState({ ...props.parentState, Refresh: props.parentState.Refresh + 1, View: "overview", Preloader: false });
						} else {
							props.setState({ ...props.parentState, Preloader: false });
							setState({ ...state, Error: [true, "error deleting license. please, contact bodiometer team for help"] });
						}
					})
					.catch(() => {
						props.setState({ ...props.parentState, Preloader: false });
						setState({ ...state, Error: [true, "error deleting license. please, contact bodiometer team for help"] });
					});
			} else {
				props.setState({ ...props.parentState, Preloader: false });
				setState({ ...state, Error: [true, "error deleting license. please, contact bodiometer team for help"] });
			}
		} catch {
			props.setState({ ...props.parentState, Preloader: false });
			setState({ ...state, Error: [true, "error deleting license. please, contact bodiometer team for help"] });
		}
	};

	// cancels paypal subscription
	const CancelSubscription = async () => {
		if (!!!props.parentState.ActiveOrganization.license[state.ActiveLicenseIndex].details.subscriptionId) {
			HandleDeleteLicense();
		} else {
			try {
				props.setState({ ...props.parentState, Preloader: true });

				var responseValues = await Promise.all([RetrieveToken(), LoadPaypalAccessToken()]);

				if (!!responseValues[0] && !!responseValues[1]) {
					var dbAccessToken = "Bearer " + responseValues[0];
					var paypalAccessToken = "Bearer " + responseValues[1];

					const promiseOne = axios({
						method: "POST",
						url: PaypalApiUrl + `/v1/billing/subscriptions/${props.ActiveOrganization.license[state.ActiveLicenseIndex].details.subscriptionId}/cancel`,
						headers: {
							"Content-Type": "application/json",
							Authorization: paypalAccessToken,
						},
						data: {
							reason: "No reason specified",
						},
					});

					promiseOne
						.then((responseOne) => {
							// update license with subscription: { "status": "Cancelled" }, and set expiryDate to what would be the next billing time. So license is still usable until expired
							const promiseTwo = axios({
								method: "PUT",
								url: dbUrl + `/api/businesslicenses/v1/update/licensekey`,
								headers: {
									Authorization: dbAccessToken,
									"Content-Type": "application/json",
									licenseKey: props.parentState.ActiveOrganization.license[state.ActiveLicenseIndex].details.licenseKey,
								},
								data: {
									subscription: `{\n\"frequency\": \"${props.parentState.ActiveOrganization.license[state.ActiveLicenseIndex].details.subscription.frequency}\",\n\"type\": \"trial\"\n}`,
									subscriptionId: "",
								},
							});

							promiseTwo
								.then((responseTwo) => {
									props.setState({ ...props.parentState, Refresh: props.parentState.Refresh + 1, View: "license", Preloader: false });
								})
								.catch((_) => {
									props.setState({ ...props.parentState, Refresh: props.parentState.Refresh + 1, View: "license", Preloader: false });
								});
						})
						.catch((_) => {
							props.setState({ ...props.parentState, Refresh: props.parentState.Refresh + 1, View: "license", Preloader: false });
						});
				} else {
					props.setState({ ...props.parentState, Refresh: props.parentState.Refresh + 1, View: "license", Preloader: false });
				}
			} catch {
				props.setState({ ...props.parentState, Refresh: props.parentState.Refresh + 1, View: "license", Preloader: false });
			}
		}
	};

	// Calculate total, and update state with new price. This runs whenever price factor values change
	useEffect(() => {
		var total = 0;

		// get price from active plan
		var planPrice = 0;
		props.plans.forEach((plan) => {
			if (plan.id === state.ActivePlan) {
				planPrice += plan.price;
				return;
			}
		});

		planPrice = state.Monthly ? planPrice : planPrice * AnnualDiscountMonths;

		var userPrice = state.Monthly ? UserPricing[state.MaxUsers] : UserPricing[state.MaxUsers] * AnnualDiscountMonths;

		var patientPrice = state.Monthly ? PatientPricing[state.MaxPatients] : PatientPricing[state.MaxPatients] * AnnualDiscountMonths;

		var addonPrice = 0;

		for (let i = 0; i < props.addons.length; i++) {
			if (props.addons[i].id in state.ActiveAddons) {
				addonPrice += props.addons[i].price;
			}
		}

		addonPrice = state.Monthly ? addonPrice : addonPrice * AnnualDiscountMonths;

		total = addonPrice + planPrice + userPrice + patientPrice;

		var remBalanceCurrentCycle = CalculateNewAddonAmountForCurrentBillCyle(state.ActiveAddons ?? {});

		var couponCode = [...state.CouponCode];

		var totalBeforeCoupon = 0;

		// calculate coupon discount
		if (state.CouponCode[0] && (state.CouponCode.length == 2 || state.CouponCode.length >= 3)) {
			// const coupon = MapCouponCode(state.CouponCode[1])
			const coupon = state.CouponObject;

			if (coupon) {
				totalBeforeCoupon += total;
				// push coupon object into couponCode (will be state.CouponCode[2])
				if (state.CouponCode.length != 3) {
					couponCode.push(coupon);
				}
				if (coupon.type === "percent") {
					//percent
					total = total - total * (coupon.value / 100);
				} else {
					// amount
					total = total - coupon.value;
				}
			} else {
				setState((prev) => ({ ...prev, CouponInValid: [true, "Invalid Code"], CouponCode: [false, ""] }));
			}
		}

		if (couponCode.length >= 3) {
			// if coupon gave 100% off, send price as 0.0 (or else will cause issues in paypal)
			if (total.toFixed(2) == "0.00") {
				setState((prev) => ({ ...prev, Total: "0", CouponCode: couponCode, TotalBeforeCoupon: totalBeforeCoupon, NewAddonAmountCurrentBillingCyle: remBalanceCurrentCycle }));
			} else {
				setState((prev) => ({ ...prev, TotalBeforeCoupon: totalBeforeCoupon, Total: total.toFixed(2), CouponCode: couponCode, NewAddonAmountCurrentBillingCyle: remBalanceCurrentCycle }));
			}
		} else {
			setState((prev) => ({ ...prev, Total: total.toFixed(2), NewAddonAmountCurrentBillingCyle: remBalanceCurrentCycle }));
		}
	}, [state.Monthly, state.ActivePlan, state.ActiveAddons, state.Different, state.MaxPatients]);

	const SetInitialData = async () => {
		try {
			props.setState({ ...props.parentState, Preloader: true }); // end preloader

			const monthly =
				props.ActiveOrganization.license && !TrialLicense ? (props.ActiveOrganization.license[state.ActiveLicenseIndex].details.subscription.frequency === "monthly" ? true : false) : false;
			const activePlan = props.ActiveOrganization.license && !TrialLicense ? props.ActiveOrganization.license[state.ActiveLicenseIndex].details.plan : 0;
			const activeAddons = props.ActiveOrganization.license && !TrialLicense ? props?.ActiveOrganization?.license[0]?.details?.addons ?? {} : {};

			var paypalAccessToken = await LoadPaypalAccessToken();

			if (props.ActiveOrganization.license && props.ActiveOrganization.license[state.ActiveLicenseIndex].details.subscriptionId !== "") {
				const PromiseOne = axios(
					{
						method: "GET",
						url: PaypalApiUrl + `/v1/billing/subscriptions/${props.ActiveOrganization.license[state.ActiveLicenseIndex].details.subscriptionId}`,
						headers: {
							"Content-Type": "application/json",
							Authorization: "Bearer " + paypalAccessToken,
						},
					},
					{ timeout: 5000 }
				);

				const PromiseTwo = axios(
					{
						method: "GET",
						url:
							PaypalApiUrl +
							`/v1/billing/subscriptions/${
								props.ActiveOrganization.license[state.ActiveLicenseIndex].details.subscriptionId
							}/transactions?start_time=${TwoYearsAgo.toISOString()}&end_time=${Today.toISOString()}`,
						headers: {
							"Content-Type": "application/json",
							Authorization: "Bearer " + paypalAccessToken,
						},
					},
					{ timeout: 5000 }
				);

				var ArrayOfValues = await Promise.all([PromiseOne, PromiseTwo]);

				var [subscription, transactions] = ArrayOfValues;

				if (transactions.data.transactions == undefined) {
					setState({
						...Initial,
						PaypalSubscription: subscription.data,
						Transactions: null,
						PlanId: subscription.data.plan_id,
						ActivePlan: activePlan,
						ActiveAddons: activeAddons,
						Monthly: monthly,
						AccessToken: "Bearer " + paypalAccessToken,
					});
				} else {
					setState({
						...Initial,
						PaypalSubscription: subscription.data,
						Transactions: transactions.data.transactions,
						PlanId: subscription.data.plan_id,
						ActivePlan: activePlan,
						ActiveAddons: activeAddons,
						Monthly: monthly,
						AccessToken: "Bearer " + paypalAccessToken,
					});
				}
			}

			setState({
				...Initial,
				ActivePlan: activePlan,
				ActiveAddons: activeAddons,
				Monthly: monthly,
				AccessToken: "Bearer " + paypalAccessToken,
			});

			props.setState({ ...props.parentState, Preloader: false });
		} catch {}
	};

	// Set active plan, and active addons based on current Active Organization
	useEffect(() => {
		SetInitialData();
	}, [props.ActiveOrganization]);

	// Create new paypal plan whenever total is changed
	useEffect(() => {
		console.log(state.ActiveAddons);
		if (state.ActivePlan && state.AccessToken) {
			const PayPalRequestId = uuidv4();

			var PayPalPlanPostData = () => {
				if (!!state.CouponCode[0] && Number(state.Total).toFixed(2) == "0.00") {
					return [
						{
							frequency: {
								interval_unit: state.CouponCode[2].interval ? state.CouponCode[2].interval : state.Monthly ? "MONTH" : "YEAR",
								interval_count: 1,
							},
							tenure_type: "TRIAL",
							sequence: 1,
							total_cycles: state.CouponCode[2].cycle,
						},
						{
							frequency: {
								interval_unit: state.Monthly ? "MONTH" : "YEAR",
								interval_count: 1,
							},
							tenure_type: "REGULAR",
							sequence: 2,
							total_cycles: 0,
							pricing_scheme: {
								fixed_price: {
									value: state.TotalBeforeCoupon != 0 ? state.TotalBeforeCoupon : "1",
									currency_code: "USD",
								},
							},
						},
					];
				} else if (!!state.CouponCode[0] && Number(state.Total).toFixed(2) != "0.00") {
					return [
						{
							frequency: {
								interval_unit: state.CouponCode[2].interval ? state.CouponCode[2].interval : state.Monthly ? "MONTH" : "YEAR",
								interval_count: 1,
							},
							tenure_type: "TRIAL",
							sequence: 1,
							total_cycles: state.CouponCode[2].cycle,
							pricing_scheme: {
								fixed_price: {
									value: state.Total.toString(),
									currency_code: "USD",
								},
							},
						},
						{
							frequency: {
								interval_unit: state.Monthly ? "MONTH" : "YEAR",
								interval_count: 1,
							},
							tenure_type: "REGULAR",
							sequence: 2,
							total_cycles: 0,
							pricing_scheme: {
								fixed_price: {
									value: state.TotalBeforeCoupon,
									currency_code: "USD",
								},
							},
						},
					];
				} else if (IsLicenseCancelled) {
					let diff = CalculateDifferenceInDays(new Date(), new Date(props?.ActiveOrganization?.license[0]?.details?.expiry ?? ""));
					let remWeek = Math.ceil(diff / 7);
					return [
						{
							frequency: {
								interval_unit: "WEEK",
								interval_count: 1,
							},
							tenure_type: "TRIAL",
							sequence: 1,
							total_cycles: remWeek,
							pricing_scheme: {
								fixed_price: {
									value: "0",
									currency_code: "USD",
								},
							},
						},
						{
							frequency: {
								interval_unit: state.Monthly ? "MONTH" : "YEAR",
								interval_count: 1,
							},
							tenure_type: "REGULAR",
							sequence: 2,
							total_cycles: 0,
							pricing_scheme: {
								fixed_price: {
									value: state.Total,
									currency_code: "USD",
								},
							},
						},
					];
				} else if (!IsLicenseCancelled) {
					return [
						{
							frequency: {
								interval_unit: state.Monthly ? "MONTH" : "YEAR",
								interval_count: 1,
							},
							tenure_type: "REGULAR",
							sequence: 1,
							total_cycles: 0,
							pricing_scheme: {
								fixed_price: {
									value: state.Total.toString(),
									currency_code: "USD",
								},
							},
						},
					];
				} else {
					return [
						{
							frequency: {
								interval_unit: state.Monthly ? "MONTH" : "YEAR",
								interval_count: 1,
							},
							tenure_type: "REGULAR",
							sequence: 1,
							total_cycles: 0,
							pricing_scheme: {
								fixed_price: {
									value: state.Total.toString(),
									currency_code: "USD",
								},
							},
						},
					];
				}
			};

			axios(
				{
					method: "POST",
					url: PaypalApiUrl + "/v1/billing/plans",
					headers: {
						"Content-Type": "application/json",
						Authorization: state.AccessToken,
						"PayPal-Request-Id": PayPalRequestId,
					},
					data: {
						product_id: state.ActivePlan === 2 ? PaypalProductId : "", // Add new product ID when we add new products..
						name: CreatePayPalSubscriptionName(),
						description: "Bodiometer subscription plan",
						status: "ACTIVE",
						billing_cycles: PayPalPlanPostData(),
						payment_preferences: {
							auto_bill_outstanding: true,
							setup_fee: {
								value: "0",
								currency_code: "USD",
							},
							setup_fee_failure_action: "CONTINUE",
							payment_failure_threshold: 1,
						},
						taxes: {
							percentage: "5",
							inclusive: false,
						},
					},
				},
				{ timeout: 10000 }
			)
				.then((res) => {
					setState((prev) => ({ ...prev, PlanId: res.data.id }));
				})
				.catch((_) => {
					setState({ ...state, Error: [true, "something went wrong"] });
				});
		}
	}, [state.Total]);

	// map plan name from props.parentState.Plans
	const GetPlanName = (planId) => {
		if (props.ActiveOrganization.license) {
			for (let plan of props.plans) {
				if (planId === plan.id) {
					return plan.name;
				}
			}
		} else if (!props.ActiveOrganization.license) {
			for (let plan of props.plans) {
				if (state.ActivePlan === plan.id) {
					return plan.name;
				}
			}
		}
	};

	// map addon name from props.parentState.Addons

	const GetAddonNames = () => {
		if (!!props.ActiveOrganization.license) {
			return props.addons
				.map((addon) => (addon.id in (props?.ActiveOrganization?.license[state.ActiveLicenseIndex]?.details?.addons ?? {}) ? addon.name : ""))
				.filter(Boolean)
				.join(", ");
		} else if (!props.ActiveOrganization.license) {
			var addonList = props.addons.filter((addon) => addon.id in state.ActiveAddons);
			if (addonList.length == 0) {
				return "None Selected";
			} else if (addonList.length == 0) {
				return addonList[0].name;
			} else {
				let addonNames = [];
				addonList.forEach((addon) => {
					addonNames.push(addon.name);
				});
				return addonNames.join(", ");
			}
		}
	};

	const GetSelectedAddonNames = () => {
		var addonList = props.addons.filter((addon) => addon.id in state.ActiveAddons);
		if (addonList.length == 0) {
			return "None Selected";
		} else if (addonList.length == 1) {
			return addonList[0].name;
		} else {
			var addonNames = [];
			addonList.forEach((addon) => {
				addonNames.push(addon.name);
			});
			return addonNames.join(", ");
		}
	};

	const HandlePlanClick = (plan) => {
		if (plan.id === state.ActivePlan && props.ActiveOrganization.license && !TrialLicense) {
			setState({ ...state, ActivePlan: plan.id, CancelPlan: true });
		} else {
			if (!props.ActiveOrganization.license || TrialLicense) {
				setState({ ...state, ActivePlan: plan.id });
			} else if (props.ActiveOrganization.license && !TrialLicense) {
				setState({ ...state, ActivePlan: plan.id, Different: true });
			}
		}
	};

	const HandleAddonClick = (addon) => {
		if (!props.ActiveOrganization.license || TrialLicense) {
			if (addon.id in state.ActiveAddons) {
				if (addon.id === 1) {
					delete state.ActiveAddons;
					setState({ ...state, ActiveAddons: {}, Different: true });
				} else {
					delete state.ActiveAddons[addon.id];
					setState({ ...state, ActiveAddons: { ...state.ActiveAddons }, Different: true });
				}
			} else {
				var activeAddons = { ...state.ActiveAddons };
				activeAddons[addon.id] = {
					issueDate: new Date().toLocaleDateString(),
					expiryDate: new Date(props?.ActiveOrganization?.license[0].details.expiry).toLocaleDateString(),
				};
				setState({ ...state, ActiveAddons: activeAddons, Different: true });
			}
		} else if (props.ActiveOrganization.license && !TrialLicense) {
			if (addon.id in state.ActiveAddons) {
				if (addon.id === 1) {
					delete state.ActiveAddons;
					setState({ ...state, ActiveAddons: {}, Different: true });
				} else {
					delete state.ActiveAddons[addon.id];
					setState({ ...state, ActiveAddons: { ...state.ActiveAddons }, Different: true });
				}
			} else {
				const activeAddons = { ...state.ActiveAddons };
				activeAddons[addon.id] = {
					issueDate: new Date().toLocaleDateString(),
					expiryDate: new Date(props?.ActiveOrganization?.license[0].details.expiry).toLocaleDateString(),
				};
				setState({ ...state, ActiveAddons: activeAddons, Different: true });
			}
		}
	};

	// This sets the name of the paypal plan to be descriptive of what factors make up the plan
	const CreatePayPalSubscriptionName = () => {
		let string = [];
		string[0] = state.Monthly ? "monthly" : "yearly";
		if (state.ActivePlan === 1) {
			string[1] = "desktop";
		} else if (state.ActivePlan === 2) {
			string[1] = "pro";
		}
		if (Object.keys(state.ActiveAddons).length > 0) {
			string[2] = props.addons
				.map((addon) => (addon.id in state.ActiveAddons ? addon.id : ""))
				.filter(Boolean)
				.sort()
				.join("_");
		}
		// string[3] = state.MaxPatients ? state.MaxPatients.toString() : '';
		string[3] = state.MaxUsers ? state.MaxUsers.toString() : "";

		string[4] = state.Total;

		if (state.CouponCode[2] !== undefined) {
			string[5] = `${state.CouponCode[2].value}_${state.CouponCode[2].type == "percent" ? "percent" : "dollars"}_off`;
		}

		return string.filter(Boolean).join("_");
	};

	// Show message when paypal subscription is not active
	useEffect(() => {
		if (state.PaypalSubscription && state.PaypalSubscription.status !== "ACTIVE" && !state.SuccessPage) {
			const alert = {
				show: true,
				message: "Your paypal billing account is inactive. Please review your Paypal account",
				variant: "danger",
				cta: {
					message: "More Info",
					onClickAction: (e) => {
						setState({ ...state, GoToPaypalModal: true });
					},
				},
				cancel: null,
			};

			setState({ ...state, Alert: alert });
		} else {
			const alert = {
				show: false,
				message: "",
				variant: "",
				cta: null,
				cancel: null,
			};

			setState({ ...state, Alert: alert });
		}
	}, [state.PaypalSubscription]);

	// build paypal transactions table fields
	const CreateColumns = () => {
		const cols = [];
		if (state.PaypalSubscription) {
			cols.push({
				field: "status",
				headerName: "Status",
				width: 200,
				// valueGetter: (params) =>
				//   params.getValue('status')
			});

			cols.push({
				field: "id",
				headerName: "Invoice ID",
				width: 220,
				// valueGetter: (params) =>
				//   params.getValue('id')
			});

			cols.push({
				field: "gross_amount",
				headerName: "Amount",
				width: 120,
				valueGetter: (params) => `$${params.row.amount_with_breakdown.gross_amount.value}`,
			});

			cols.push({
				field: "time",
				headerName: "Date",
				width: 200,
				valueGetter: (params) => ConvertDate(params.row.time),
			});
		}
		return cols;
	};

	const CreateRows = () => {
		const rows = [];
		if (state.PaypalSubscription && state.Transactions) {
			for (let i of state.Transactions) {
				i["amount"] = i.amount_with_breakdown.gross_amount.value;
				rows.push(i);
			}
		}
		return rows;
	};

	// fetch coupon when entered, and add coupon to state, if coupon, update price with discount
	const HandleCalculateCouponPrice = (e) => {
		// e.preventDefault();
		// e.stopPropagation();

		setState({ ...state, Different: false });

		// fetch coupon now
		let coupon;

		const GetCouponHeaders = {
			headers: {
				Authorization: props.parentState.DBAccessToken,
				code: state.CouponCode[1],
			},
		};

		axios
			.get(dbUrl + `/api/coupons/v1/code`, GetCouponHeaders)
			.then((couponRes) => {
				if (couponRes.data.success) {
					// there's a coupon
					let coupon = couponRes.data.data;

					// check if coupon is valid
					let canStillUse = true;
					if (!!coupon && !!coupon.useCount && !!coupon.maxCount) {
						// useCount and maxCount are set
						if (coupon.useCount > coupon.maxCount) {
							// canStillUse can stay true
							canStillUse = false;
						}
					}

					if (
						!!coupon &&
						DateDifference(coupon.expiry) < 0 &&
						DateDifference(coupon.start) > 0 &&
						canStillUse &&
						!props.parentState.ActiveOrganization.couponsUsed.includes(state.CouponCode[1]) /* && props.parentState.User.couponsUsed.includes(code.toUpperCase()) */
					) {
						// coupon is valid

						// add coupon object to state
						setState({ ...state, CouponInValid: [false, ""], CouponCode: [true, state.CouponCode[1]], CouponObject: coupon, Different: true });
					} else {
						// coupon is invalid
						setState({ ...state, CouponInValid: [true, "Code is invalid"], CouponCode: [false, ""], Different: true });
					}
				} else {
					// error fetching coupon
					setState({ ...state, CouponInValid: [true, "Code couldn't be fetched"], CouponCode: [false, ""], Different: true });
				}
			})
			.catch(() => {
				setState({ ...state, CouponInValid: [true, "coupon could not be applied."], CouponCode: [false, ""], Different: true });
			});
	};

	// enter coupon modal
	const AddCoupon = () => {
		return (
			<Popover className="action-toggle-wrapper" id={`popover${Math.floor(Math.random()).toString()}`}>
				<Popover.Content>
					<p>Enter Coupon Code</p>
					<Form inline className="flex-row coupon-wrapper">
						<Form.Group controlId="addCoupon">
							<Form.Control
								size="sm"
								type="input"
								placeholder="Coupon Code"
								isInvalid={state.CouponInValid[0]}
								value={state.CouponCode[1]}
								feedback="Error"
								onChange={(e) => setState({ ...state, CouponCode: [false, e.currentTarget.value] })}
							></Form.Control>
							{/* <Form.Control.Feedback type="invalid">{state.CouponInValid[1]}</Form.Control.Feedback> */}
							{state.CouponInValid[0] && <Form.Control.Feedback type="invalid">{state.CouponInValid[1]}</Form.Control.Feedback>}
						</Form.Group>

						<Form.Group controlId="submit">
							<UiButton onClick={(e) => HandleCalculateCouponPrice(e)} size="sm">
								Submit
							</UiButton>
						</Form.Group>
					</Form>
				</Popover.Content>
			</Popover>
		);
	};

	const HandlePlanUpdate = async () => {
		if (props.ActiveOrganization.license && (props?.ActiveOrganization?.license[state.ActiveLicenseIndex]?.details?.subscriptionId ?? "") !== "") {
			try {
				props.setState({ ...props.parentState, Preloader: true });

				var domain = process.env.NODE_ENV == "development" ? "http://localhost:3000" : "https://bodiometer.com";
				var licenseKey = props.parentState.ActiveOrganization.license[state.ActiveLicenseIndex].details.licenseKey;

				var responseValues = await Promise.all([RetrieveToken(), LoadPaypalAccessToken()]);

				if (!!responseValues[0] && !!responseValues[1]) {
					var dbAccessToken = "Bearer " + responseValues[0];
					var paypalAccessToken = "Bearer " + responseValues[1];

					var newData = {
						maxPatients: state.MaxPatients,
						subscription: `{\n\"frequency\": \"${state.Monthly ? "monthly" : "yearly"}\"\n}`,
						addons: state.ActiveAddons,
					};

					const PromiseOne = axios({
						method: "POST",
						url: `${PaypalApiUrl}/v1/billing/subscriptions/${props.parentState.ActiveOrganization.license[state.ActiveLicenseIndex].details.subscriptionId}/revise`,
						headers: {
							"Content-Type": "application/json",
							Authorization: paypalAccessToken,
						},
						data: {
							plan_id: state.PlanId,
							start_time: new Date().toLocaleDateString(),
							application_context: {
								brand_name: "bodiometer",
								locale: "en-US",
								shipping_preference: "SET_PROVIDED_ADDRESS",
								payment_method: {
									payer_selected: "PAYPAL",
									payee_preferred: "IMMEDIATE_PAYMENT_REQUIRED",
								},
								return_url: domain + `/license/${licenseKey}/approve`,
								cancel_url: domain + `/license/${licenseKey}/cancel`,
							},
						},
					});

					const PromiseTwo = axios({
						method: "PUT",
						url: dbUrl + `/api/businesslicenses/v1/update/licensekey`,
						headers: {
							Authorization: dbAccessToken,
							"Content-Type": "application/json",
							licenseKey: props.parentState.ActiveOrganization.license[state.ActiveLicenseIndex].details.licenseKey,
						},
						data: {
							tempLicenseData: JSON.stringify({ ...newData }),
						},
					});

					Promise.all([PromiseOne, PromiseTwo])
						.then((arrayValues) => {
							// approve link
							// this opens a new window so paypal users can authenticate (it also just opens a blank window with some error text when users subscribed without paypal account (as guest))
							// It would be great to be able to bypass this if user doesn't have a paypal account, but there's no real way of knowing at this point, unless we can change the buttons to be conditional? based on whether or not they'll checkout as guest...
							var goBack = window.open(arrayValues[0].data.links[0].href, "_blank", "location=yes,height=570,width=520,scrollbars=yes,status=yes");

							var timer = setInterval(() => {
								if (goBack.closed) {
									clearInterval(timer);
									props.setState({ ...props.parentState, Preloader: false });
									setState({ ...state, SuccessPage: props.parentState.ActiveOrganization.license[state.ActiveLicenseIndex].details.licenseKey });
								}
							}, 1000);
						})
						.catch((_) => {
							const alert = {
								show: true,
								message: "There was an issue updating your license in our database. contact the Bodiometer Team for support ",
								variant: "danger",
								cta: null,
								cancel: null,
							};

							setState({ ...state, Alert: alert });

							props.setState({ ...props.parentState, Preloader: false });
						});
				} else {
					setState({ ...state, Success: [false, "something went wrong"] });
					props.setState({ ...props.parentState, Preloader: false });
				}
			} catch {
				setState({ ...state, Success: [false, "something went wrong"] });
				props.setState({ ...props.parentState, Preloader: false });
			}
		} else {
			setState({ ...state, Success: [false, "something went wrong"] });
			props.setState({ ...props.parentState, Preloader: false });
		}
	};

	// function to determine bool if paypal subscription is cancelled
	const SubscriptionIsCancelled = () => {
		if (
			props.parentState.ActiveOrganization.license &&
			props.parentState.ActiveOrganization.license[0].details.subscription &&
			props.parentState.ActiveOrganization.license[0].details.subscription.status &&
			props.parentState.ActiveOrganization.license[0].details.subscription.status.toLowerCase() == "cancelled"
		) {
			return true;
		} else {
			return false;
		}
	};

	const HandlePayPalOnApproval = (data) => {
		props.setState({ ...props.parentState, Preloader: true }); // start preloader

		RetrieveToken()
			.then((token) => {
				// check for existing license
				// right now the product id is fixed to 2 (Bodiometer Pro)
				const CheckDBLicenseHeaders = {
					headers: {
						Authorization: "Bearer " + token,
						productId: 2,
						businessId: props.parentState.ActiveOrganization.id,
					},
					timeout: 10000,
				};

				axios
					.get(dbUrl + `/api/businesslicenses/v1/businessid/productid`, CheckDBLicenseHeaders)
					.then((dbLicenseRes) => {
						// if a license already exist
						// more complicated branch
						// need to take care of existing subscription
						if (dbLicenseRes.data.success) {
							// license exists already, delete it
							var licenseKey = dbLicenseRes.data.data.businessLicenses[Object.keys(dbLicenseRes.data.data.businessLicenses)[0]].licenseKey;

							const deleteLicensePostHeaders = {
								headers: {
									Authorization: "Bearer " + token,
									licenseKey: licenseKey,
								},
								timeout: 10000,
							};

							axios
								.delete(dbUrl + "/api/businesslicenses/v1/remove/licensekey", deleteLicensePostHeaders)
								.then((deleteLicenseRes) => {
									if (deleteLicenseRes.data.success) {
										// license deleted
										// create new license
										const PostHeaders = {
											headers: {
												Authorization: "Bearer " + token,
												"Content-Type": "application/json",
											},
											timeout: 10000,
										};

										const PostData = {
											businessId: props.parentState.ActiveOrganization.id,
											productId: state.ActivePlan,
											originalIssueDate: props.parentState.Today,
											expiryDate: state.Monthly ? AddDaysToDate(30).toLocaleDateString() : AddDaysToDate(365).toLocaleDateString(),
											numberOfDevices: 5,
											unlimited: true,
											maxNumberOfDevice: 20,
											maxBusinessUsers: state.MaxUsers,
											businessPlan: state.ActivePlan,
											maxPatients: state.MaxPatients,
											subscription: `{\n\"frequency\": \"${state.Monthly ? "monthly" : "yearly"}\"\n}`,
											addons: state.ActiveAddons,
											subscriptionId: data.subscriptionID,
										};

										axios.post(dbUrl + `/api/businesslicenses/v1/create`, PostData, PostHeaders).then(() => {
											// update coupon useCount if a coupon was used
											if (!!state.CouponObject) {
												// a coupon was used

												const CouponPostHeaders = {
													headers: {
														Authorization: "Bearer " + token,
														"Content-Type": "application/json",
													},
													timeout: 10000,
												};

												const CouponPostData = {
													...state.CouponObject,
													useCount: state.CouponObject.useCount + 1,
												};

												axios
													.put(dbUrl + `/api/coupons/v1/update`, CouponPostData, CouponPostHeaders)
													.then(() => {
														const AddCouponHeaders = {
															headers: {
																Authorization: "Bearer " + token,
																"Content-Type": "application/json",
															},
															timeout: 10000,
														};

														const AddCouponData = {
															code: state.CouponObject.code,
															businessId: props.parentState.ActiveOrganization.id,
														};

														axios
															.post(dbUrl + `/api/coupons/v1/businessaccounts/apply`, AddCouponData, AddCouponHeaders)

															.then((res) => {
																// business account updated
																props.setState({ ...props.parentState, Preloader: false });
																setState({ ...state, SuccessPage: data.subscriptionID });
															})
															.catch(() => {
																props.setState({ ...props.parentState, Preloader: false });
																setState({ ...state, SuccessPage: data.subscriptionID });
															});
													})
													.catch(() => {
														props.setState({ ...props.parentState, Preloader: false });
														setState({ ...state, SuccessPage: data.subscriptionID });
													});
											} else {
												props.setState({ ...props.parentState, Preloader: false });
												setState({ ...state, SuccessPage: data.subscriptionID });
											}
										});
									} else {
										setState({ ...state, Error: [true, "paypal subscription updated but license deletion failed, contact with the Bodiometer Team"] });
									}
								})
								.catch(() => {
									setState({ ...state, Error: [true, "paypal subscription updated but license not found"] });
								});
						}

						// if no license found
						// that means a new license can be created
						// no complications
						else {
							// no existing license, create new license
							const PostHeaders = {
								headers: {
									Authorization: "Bearer " + token,
									"Content-Type": "application/json",
								},
							};

							const PostData = {
								businessId: props.parentState.ActiveOrganization.id,
								productId: state.ActivePlan,
								originalIssueDate: props.parentState.Today,
								expiryDate: state.Monthly ? OneMonthFromToday : OneYearFromToday,
								numberOfDevices: 3,
								unlimited: true,
								maxNumberOfDevice: 20,
								maxBusinessUsers: state.MaxUsers,
								businessPlan: state.ActivePlan,
								maxPatients: state.MaxPatients,
								subscription: `{\n\"frequency\": \"${state.Monthly ? "monthly" : "yearly"}\"\n}`,
								addons: state.ActiveAddons,
								subscriptionId: data.subscriptionID,
							};

							axios.post(dbUrl + `/api/businesslicenses/v1/create`, PostData, PostHeaders).then(() => {
								// props.setState(({...props.parentState, Refresh: props.parentState.Refresh + 1, Preloader: false}));
								props.setState({ ...props.parentState, Preloader: false });
								setState({ ...state, SuccessPage: data.subscriptionID });

								// update coupon useCount if a coupon was used
								if (state.CouponObject) {
									// a coupon was used

									const CouponPostHeaders = {
										headers: {
											Authorization: "Bearer " + token,
											"Content-Type": "application/json",
										},
									};

									const CouponPostData = {
										...state.CouponObject,
										useCount: state.CouponObject.useCount + 1,
									};

									axios.put(dbUrl + `/api/coupons/v1/update`, CouponPostData, CouponPostHeaders).then(() => {
										const AddCouponHeaders = {
											headers: {
												Authorization: "Bearer " + token,
												"Content-Type": "application/json",
											},
										};

										const AddCouponData = {
											code: state.CouponObject.code,
											businessId: props.parentState.ActiveOrganization.id,
										};

										axios
											.post(dbUrl + `/api/coupons/v1/businessaccounts/apply`, AddCouponData, AddCouponHeaders)

											.then((res) => {
												// business account updated
											});
									});
								}
							});
						}
					})
					.catch(() => {
						setState({ ...state, Error: [true, "something went wrong during license verification process"] });
					});
			})
			.catch((_) => {});
	};

	const HandleReactivation = (data) => {
		props.setState({ ...props.parentState, Preloader: true }); // start preloader

		let diff = CalculateDifferenceInDays(new Date(), new Date(props?.ActiveOrganization?.license[0]?.details?.expiry ?? ""));
		let remWeek = Math.ceil(diff / 7);
		let newExpiryDate = AddDaysToDate(remWeek * 7);

		let currentAddons = props?.ActiveOrganization?.license[state.ActiveLicenseIndex]?.details?.addons ?? {};
		// let addonKeys = Object.keys(currentAddons);
		// for (let i = 0; i < addonKeys.length; i++) {
		// 	currentAddons[addonKeys[i]].expiryDate = "";
		// }

		axios({
			method: "PUT",
			url: dbUrl + `/api/businesslicenses/v1/update/licensekey`,
			headers: {
				Authorization: props.parentState.DBAccessToken,
				"Content-Type": "application/json",
				licenseKey: props.parentState.ActiveOrganization.license[state.ActiveLicenseIndex].details.licenseKey,
			},
			data: {
				subscription: `{\n\"frequency\": \"${state.Monthly ? "monthly" : "yearly"}\"\n}`,
				subscriptionId: data.subscriptionID,
				expiryDate: newExpiryDate.toLocaleDateString(),
				addons: currentAddons,
			},
		})
			.then((updateDBLicense) => {
				if (updateDBLicense.data.success) {
					// setState({...Initial, Success: [true, "license reactivated successfully"]});
					props.setState({ ...props.parentState, Refresh: props.parentState.Refresh + 1, View: "overview", Preloader: false });
				} else {
					const alert = {
						show: true,
						message: "There was an issue updating your license in our database, contact the Bodiometer Team",
						variant: "danger",
						cta: null,
						cancel: null,
					};

					props.setState({ ...props.parentState, Preloader: false }); // stop preloader
					setState({ ...state, Alert: alert });
				}
			})
			.catch((_) => {
				const alert = {
					show: true,
					message: "There was an issue updating your license in our database",
					variant: "danger",
					cta: null,
					cancel: null,
				};
				props.setState({ ...props.parentState, Preloader: false }); // stop preloader
				setState({ ...state, Alert: alert });
			});
	};

	return (
		<div
			className="ui-section-wrapper"
			// onClick={(e) => {
			//   e.preventDefault();
			//   return state.ShowRemovePopover ? setState({...state, ShowRemovePopover: false}) : "";
			// }}
		>
			<div
				className="invisible-reset"
				// onClick={(e) => {
				//   e.preventDefault();
				//   return !!state.ShowRemovePopover ? setState({...state, ShowRemovePopover: false}) : "";
				// }}
			></div>

			<Modal state={state} setState={setState} show={state.GoToPaypalModal} refName="GoToPaypalModal">
				<div className="centered-text">
					<h3>Manage Billing</h3>
					<p>
						To access your billing information, please login to your paypal account, <br />
						then navigate to 'Payments' then 'Manage Automatic Payments'
					</p>
					<a href="https://www.paypal.com/signin" target="_blank" className="a-button">
						Go to Paypal
					</a>
				</div>
			</Modal>

			<section>
				{/* 
          license error and success message
        */}

				<Row>
					<Col className="flex-row no-wrap">
						<div>
							<p className="uppercase p-heading">{props.ActiveOrganization.name}</p>
							<h1>License</h1>
						</div>
						<Alert variant="warning" show={state.Error[0] ? true : false}>
							<div className="flex-row">
								<p>{state.Error[1]}</p>
							</div>
						</Alert>
						<Alert variant="info" show={state.Success[0] ? true : false}>
							<div className="flex-row">
								<p>{state.Success[1]}</p>
							</div>
						</Alert>
					</Col>
				</Row>

				{IsLicenseCancelled && (
					<Alert variant="warning" show={true}>
						<div className="flex-row" style={{ textAlign: "center" }}>
							<p style={{ width: "100%" }}>
								Your current subscription expires{" "}
								<b>{new Date(props?.ActiveOrganization?.license[state.ActiveLicenseIndex]?.details?.expiry ?? "").toLocaleDateString(dateLocale, defaultDateFormat)}</b>{" "}
							</p>

							<p style={{ width: "100%" }}>click paypal button to reactivate</p>

							<Fragment>
								<p>
									{/* <UiButton variant="success" type="submit" onClick={(_) => HandleReactivation()}>
                    reactivate
                  </UiButton> */}
									<PayPalButton
										options={{
											clientId: PaypalClientId, // client id set in application.js state
											currency: "USD", // currency always fixed to USD
											debug: false, // disable during production
											// commit: true,
											intent: "subscription",
											components: ["buttons", "marks"],
											// disableFunding: "card", // disables bank card (not credit card)
											vault: true,
										}}
										createSubscription={(data, action) => {
											return action.subscription.create({
												plan_id: state.PlanId,
											});
										}}
										onSuccess={(_) => {}}
										onApprove={(data, action) => {
											// Capture the funds from the transaction
											return action.subscription.get().then((_) => {
												// Show a success message to your buyer
												HandleReactivation(data);
											});
										}}
										onError={(_) => {}}
									/>
								</p>
							</Fragment>
						</div>
					</Alert>
				)}

				{/* This section is only visible when the subscription id is empty */}
				{/* <Alert variant="warning" show={(props?.ActiveOrganization?.license[state.ActiveLicenseIndex]?.details?.subscriptionId ?? "") === "" && !TrialLicense}>
          <div className="flex-row" style={{textAlign: "center"}}>
            <p>
              Your current subscription ends <b>{new Date(props?.ActiveOrganization?.license[state.ActiveLicenseIndex]?.details?.expiry ?? "").toLocaleDateString(dateLocale, defaultDateFormat)}</b>{" "}
            </p>
            {!state.Different && (
              <Fragment>
                <p style={{fontStyle: "oblique"}}>
                  If you subscribe now with the selected plan you will pay a discounted fee of 
                </p>
                <p>
                  <UiButton variant="secondary" type="submit" onClick={(_) => {}}>
                    subscribe
                  </UiButton>
                </p>
              </Fragment>
            )}
          </div>
        </Alert> */}

				{/* 
          plan change confirmation alert
        */}
				<Alert
					variant="warning"
					show={!TrialLicense && !state.CouponCode[0] && state.Different && (props?.ActiveOrganization?.license[state.ActiveLicenseIndex]?.details?.subscriptionId ?? "") !== "" ? true : false}
				>
					{(props?.ActiveOrganization?.license[state.ActiveLicenseIndex]?.details?.subscriptionId ?? "") !== "" && (
						<div className="flex-row">
							<p>Are you sure you would like to change your current subscription?</p>

							{/* {state.NewAddonAmountCurrentBillingCyle > 0 && (
								<Fragment>
									<p style={{ textAlign: "center" }}>
										You will be charged a fee of {`\$${state.NewAddonAmountCurrentBillingCyle.toFixed(2)} USD`} for the current billing period and{" "}
										{`\$${state.Total} USD/${state.Monthly ? "month" : "year"}`} for the subsequent billing period{" "}
									</p>
									<p style={{ textAlign: "center" }}>
										<UiButton variant="secondary" type="submit" onClick={(_) => HandlePlanUpdate()}>
											Change Subscription
										</UiButton>
									</p>
								</Fragment>
							)} */}

							{/* {state.NewAddonAmountCurrentBillingCyle <= 0 && (
								<UiButton variant="secondary" type="submit" onClick={(_) => HandlePlanUpdate()}>
									Change Subscription
								</UiButton>
							)} */}

							<UiButton variant="secondary" type="submit" onClick={(_) => HandlePlanUpdate()}>
								Change Subscription
							</UiButton>

							<UiButton
								onClick={(e) => {
									// setState({ ...Initial, Different: false, PaypalSubscription: state.PaypalSubscription, Transactions: state.Transactions });
									SetInitialData();
								}}
								variant="link"
								className="gray-color no-padding"
							>
								Keep Current Subscription
							</UiButton>
						</div>
					)}
				</Alert>

				{/* 
          alert confirmation when plan is cancelled
        */}
				<Alert variant="danger" show={state.CancelPlan ? true : false}>
					<div className="flex-row">
						<p>
							Are you sure you would like to{" "}
							{props.ActiveOrganization.license &&
							props.ActiveOrganization.license[state.ActiveLicenseIndex] &&
							props.ActiveOrganization.license[state.ActiveLicenseIndex].details.subscriptionId !== ""
								? "cancel"
								: "delete"}{" "}
							your plan?
						</p>
						<UiButton onClick={(e) => CancelSubscription()} variant="danger" type="submit">
							Yes,{" "}
							{props.ActiveOrganization.license &&
							props.ActiveOrganization.license[state.ActiveLicenseIndex] &&
							props.ActiveOrganization.license[state.ActiveLicenseIndex].details.subscriptionId !== ""
								? "Cancel"
								: "Delete"}{" "}
							Plan
						</UiButton>

						<UiButton
							onClick={(e) => {
								// setState({ ...Initial, Different: false });
								SetInitialData();
							}}
							variant="link"
							className="gray-color no-padding"
						>
							Don't{" "}
							{props.ActiveOrganization.license &&
							props.ActiveOrganization.license[state.ActiveLicenseIndex] &&
							props.ActiveOrganization.license[state.ActiveLicenseIndex].details.subscriptionId !== ""
								? "Cancel"
								: "Delete"}
						</UiButton>
					</div>
				</Alert>

				<UiAlert
					Alert={state.Alert} // UI ALERT
				/>

				{/* Multi license ui.. disabling for now */}
				{/* <Row >
          <Col>
            <div className="flex-row flex-center license-nav-wrapper">
              { props.parentState.ActiveOrganization.license.map(license => {
                return (
                        <UiButton
                          variant={state.ActiveLicenseIndex === props.parentState.ActiveOrganization.license.indexOf(license) && !state.NewPlan ? 'primary' : 'outline-primary'}
                          onClick={e => setState({...state, NewPlan: false, ActiveLicenseIndex: props.parentState.ActiveOrganization.license.indexOf(license)})}
                        >
                          {GetPlanName(license.details.plan)}
                        </UiButton>
                )
              })

              }

              { !state.NewPlan &&
                <UiButton
                  plus
                  onClick={e => setState({...state, NewPlan: true })}
                  variant="link"
                  className="green-color"
                >
                  Add New License
                </UiButton>
              }

            </div>
          </Col>
        </Row> */}
			</section>

			{state.PaypalFetchFailed && ( // display option to remove license if paypal fetch failed (mostly used for deleting licenses created in sandbox mode, since they will fail to fetch on live api calls)
				<Fragment>
					<section>
						<Row>
							<Col>
								<UiButton variant="primary" onClick={(e) => HandleDeleteLicense(e)}>
									Remove License
								</UiButton>
							</Col>
						</Row>
					</section>
				</Fragment>
			)}

			{/* 
        the following section renders when the user has a normal license and not a trial license
        the license's paypal subscription can be active or cancelled
        the following variables and their associated state renders this section
        the user needs to have a license, props.ActiveOrganization.license (true)
        the user can't be in the success page, state.SuccessPage (false)
        the user has not selected a new plan, state.newPlan (false)
        the user pay pal request has not failed, state.PaypalFetchFailed (false)
        the user license is not a trial license, TrialLicense (false)
      */}

			{!!props.ActiveOrganization.license &&
				!!!state.SuccessPage &&
				!!!state.NewPlan &&
				!!!state.PaypalFetchFailed &&
				!!!TrialLicense && ( // display the UI for when user has a license already (or when users' license is a TrialLicense)
					<Fragment>
						{/* 
              the following section is the top section of the license page 
              where license information is displayed in two columns
              this section renders regardless of states of other variables
              first column shows: license name, max users, subscription name, subscription status, and addons
              second column shows: next charge date (if license is active) or next manual charge date (if cancelled)
            */}

						<section className="license-info-section-wrapper">
							<Row className="info-details license-details-wrapper">
								{/* 
                  column 1
                */}

								<Col md={8}>
									<p>
										License: <span>{GetPlanName(props.parentState.ActiveOrganization.license[state.ActiveLicenseIndex].details.plan)}</span>
									</p>
									{/* <p>
										Max patients: <span>{props.parentState.ActiveOrganization.license[state.ActiveLicenseIndex].details.maxPatients}</span>
									</p> */}

									<p>
										Max patients:{" "}
										{!state.EditMaxPatient && (
											<UiButton
												onClick={(e) => {
													setState({ ...state, Different: true, EditMaxPatient: true });
												}}
												variant="link"
												className="no-padding"
											>
												<img src="../../photos/icons/dashboard/SVG/edit-green.svg" width="25" alt="edit icon" />
											</UiButton>
										)}
									</p>
									{state.EditMaxPatient ? (
										<Form.Control
											size="sm"
											as="select"
											placeholder="max patients"
											custom
											className="ui-select small-int-select"
											value={state.MaxPatients}
											onChange={(e) => setState({ ...state, MaxPatients: e.currentTarget.value, Different: true })}
										>
											<option value={1000} selected={state.MaxPatients == 1000}>
												1000
											</option>
											<option value={2000} selected={state.MaxPatients == 2000}>
												2000
											</option>
											<option value={3000} selected={state.MaxPatients == 3000}>
												3000
											</option>
											<option value={4000} selected={state.MaxPatients == 4000}>
												4000
											</option>
											<option value={5000} selected={state.MaxPatients == 5000}>
												5000
											</option>
											<option value={6000} selected={state.MaxPatients == 6000}>
												6000
											</option>
											<option value={7000} selected={state.MaxPatients == 7000}>
												7000
											</option>
											<option value={8000} selected={state.MaxPatients == 8000}>
												8000
											</option>
											<option value={9000} selected={state.MaxPatients == 9000}>
												9000
											</option>
											<option value={10000} selected={state.MaxPatients == 10000}>
												10000+
											</option>
										</Form.Control>
									) : (
										<p>
											<span>{state.MaxPatients}</span>
										</p>
									)}

									<p>
										Subscription: <span>{props.parentState.ActiveOrganization.license[state.ActiveLicenseIndex].details.subscription.frequency}</span>
									</p>
									<p>
										Status: <span>{props.ActiveOrganization.license[state.ActiveLicenseIndex].details.status}</span>
									</p>

									{props.ActiveOrganization.license[state.ActiveLicenseIndex].details.addons.length > 0 && (
										<p>
											Addons: <span>{GetAddonNames()}</span>
										</p>
									)}
								</Col>

								{/* 
                  column 2
                */}
								<Col md={4}>
									{/* 
                    renders when there is a paypal subscription
                  */}

									{!!props.ActiveOrganization.license[state.ActiveLicenseIndex].details.subscriptionId && (
										<Fragment>
											{/* <p className={`smaller-text${state.PaypalSubscription ? "" : " loading-bg"}`}></p> */}
											<p className={`smaller-text`}>
												{props.parentState.ActiveOrganization.license[0].details.subscription.status &&
												props.parentState.ActiveOrganization.license[0].details.subscription.status.toLowerCase() == "cancelled"
													? "Expires:"
													: "Next charge on "}

												{ConvertDate(
													state.PaypalSubscription && !SubscriptionIsCancelled()
														? state.PaypalSubscription.billing_info.next_billing_time
														: props.parentState.ActiveOrganization.license[0].details.expiry,
													{ year: "numeric", month: "long", day: "numeric" }
												)}
											</p>

											<UiButton variant="link" className="no-padding green-color" onClick={(e) => setState({ ...state, GoToPaypalModal: true })}>
												Manage Billing
											</UiButton>
										</Fragment>
									)}

									{/* 
                    renders when there is no paypal subscription
                  */}

									{!!!props.ActiveOrganization.license[state.ActiveLicenseIndex].details.subscriptionId && (
										<p className={"smaller-text"}>
											{props.parentState.ActiveOrganization.license[0].details.subscription.status &&
											props.parentState.ActiveOrganization.license[0].details.subscription.status.toLowerCase() == "cancelled"
												? "Expires:"
												: "Next manual charge on"}{" "}
											{ConvertDate(props.parentState.ActiveOrganization.license[0].details.expiry, { year: "numeric", month: "long", day: "numeric" })}
										</p>
									)}

									{/* 
                    renders regardless of other variables
                    displays the subscription amount
                  */}

									<div className="form-nav-price-wrapper2">
										<h3 className={state.Monthly ? "mo" : "yr"}>{state.Total} USD</h3>
										<p className="smaller-text tax-text">+ applicable taxes</p>
									</div>
								</Col>
							</Row>
						</section>

						{/* 
              the following section displays available plans 
              always renders when there is a license
            */}

						<section>
							{/* 
                first row offers option to select monthly / yearly subscription
              */}

							<Row>
							<Col xs={12}>
									<h4>BASIC</h4>
								</Col>
								{!IsLicenseCancelled && (
									<Col className="table-cta-top">
										<UiSwitch
											checked={state.Monthly}
											onClick={() => setState({ ...state, Monthly: !state.Monthly, Different: true })}
											label="Monthly"
											falseLabel="Annually"
											className={state.Monthly ? "monthlyChecked" : "yearlyChecked"}
										/>
									</Col>
								)}
								{/* {
                  <Col className="table-cta-top">
                    <UiSwitch checked={state.Monthly} onClick={() => setState({...state, Monthly: !state.Monthly, Different: true})} label="Monthly" falseLabel="Annually" className={state.Monthly ? "monthlyChecked" : "yearlyChecked"} />
                  </Col>
                } */}
							</Row>

							{/* 
								available plans are displayed vertically stacked using a card-like ui
								which can be used to delete the plan and update subscription
							*/}

							<Row>
								<Col>
									<ul className="no-padding">
										{props.plans.map((plan) => {
											return (
												<li>
													<LicenseCard
														existingLicense={IsLicenseCancelled}
														img={plan.logo}
														price={state.Monthly ? plan.price : plan.price * AnnualDiscountMonths}
														includes={plan.features.includes}
														excludes={plan.features.excludes}
														value={1}
														cta={
															props.parentState.ActiveOrganization.license[0].details.subscription.status &&
															props.parentState.ActiveOrganization.license[0].details.subscription.status.toLowerCase() == "cancelled"
																? "hide"
																: plan.id === state.ActivePlan && props.parentState.ActiveOrganization.license[0].details.subscriptionId != ""
																? "Cancel"
																: plan.id === state.ActivePlan && props.parentState.ActiveOrganization.license[0].details.subscriptionId == ""
																? "Delete"
																: "Select"
														}
														active={plan.id === state.ActivePlan ? true : false}
														onCtaClick={(e) => HandlePlanClick(plan)}
														parentState={props.parentState}
													/>
												</li>
											);
										})}
									</ul>
								</Col>
							</Row>
						</section>

						{/* 
							the following section displays available addons 
							always renders when there is a license
						*/}

						<section>
							<Row>
								<Col>
									<h4>PRO</h4>
								</Col>
							</Row>
							<Row>
								<Col>
									<ul className="no-padding">
										{props.addons.map((addon) => {
											return (
												<li>
													<LicenseCard
														existingLicense={IsLicenseCancelled}
														img={false}
														price={state.Monthly ? addon.price : addon.price * AnnualDiscountMonths}
														includes={addon.features.includes}
														excludes={addon.features.excludes}
														value={1}
														cta={SubscriptionIsCancelled() ? "hide" : state.ActiveAddons && addon.id in state.ActiveAddons ? "Remove" : "Select"}
														active={state.ActiveAddons && addon.id in state.ActiveAddons ? true : false}
														onCtaClick={(e) => HandleAddonClick(addon)}
														name={addon.name}
													/>
												</li>
											);
										})}
									</ul>
								</Col>
							</Row>
						</section>

						{/* 
							the following section displays previous transactions 
							this section is only rendered when
							there is a subscription id, props.ActiveOrganization.license[state.ActiveLicenseIndex].details.subscriptionId (true)
							there is valid transactions, state.Transactions (true)
						*/}
						{!!props.ActiveOrganization.license[state.ActiveLicenseIndex].details.subscriptionId && !!state.Transactions && (
							<section className={state.Transactions ? "" : "loading-bg"}>
								<Row>
									<Col>
										<h4>Transaction History</h4>
									</Col>
								</Row>
								<Row>
									<Col>
										<UiTable cols={CreateColumns()} rows={CreateRows()}></UiTable>
									</Col>
								</Row>
							</section>
						)}

						{/*  
							this section is only rendered when there are no previous transactions
						*/}

						{!!!state.Transactions && (
							<section>
								<Row>
									<Col>
										<h4 className="muted">No Transaction History</h4>
									</Col>
								</Row>
							</section>
						)}
					</Fragment>
				)}

			{/* 
				the following section renders when the user has a trial license (gets deleted before making a new one) or
				the user wants to create a new license
				the following section renders if 
						the success page is not displayed,  state.SuccessPage (false)
						
						and (&)

								there is no license, props.ActiveOrganization.license (false)

								or (||)

								the license is a trial license, TrialLicense (true)

								or (||)

								the user selects a new plan, state.NewPlan (true)

				the user needs to have a license, props.ActiveOrganization.license (true)
				the user can't be in the success page, state.SuccessPage (false)
				the user has not selected a new plan, state.newPlan (false)
				the user pay pal request has not failed, state.PaypalFetchFailed (false)
				the user license is not a trial license, TrialLicense (false)
			*/}

			{(!!!props.ActiveOrganization.license || !!TrialLicense || !!state.NewPlan) &&
				!!!state.SuccessPage && ( // display UI to create a new license (this also gets displayed if user has TrialLicense, which will get deleted before making the new one)
					<Form validated={state.CreateFormValidated} className="license-form-wrapper">
						{/* 
              the following section displays available plans
            */}

						<section>
							<Row>
								<Col lg={6} md={5}>
									<h4>Select Plan</h4>
								</Col>
								<Col lg={6} md={7} className="table-cta-top">
									<div className="flex-row flex-center">
										<UiSwitch
											checked={state.Monthly}
											onClick={() => setState({ ...state, Monthly: !state.Monthly })}
											label="Monthly"
											falseLabel="Annually"
											className={state.Monthly ? "monthlyChecked" : "yearlyChecked"}
										/>
										<InfoPopup trigger="click" symbol="?" content={`Switch to annual payments to earn ${12 - AnnualDiscountMonths} months savings discount`} placement="top-end" />
									</div>
								</Col>
							</Row>

							{/* <Row className="max-wrapper">
								<Col md={12}>
									<div className="flex-row max-participants-form-group align-items-center">
										<p>Maximum Users: </p>
										<Form.Control
											size="sm"
											as="select"
											placeholder="max users"
											custom
											className="ui-select small-int-select"
											value={state.MaxUsers}
											onChange={(e) => setState({ ...state, MaxUsers: e.currentTarget.value })}
										>
											{props.licenseDefaults.maxUsers.map((maxItem) => (
												<option value={maxItem[0]}>{maxItem[0]}</option>
											))}
										</Form.Control>
										<InfoPopup trigger="click" symbol="?" content="5 is FREE, each additional 5 adds $50/month to subscription" placement="top-end" />
									</div>
								</Col>
							</Row> */}

							<Row className="max-wrapper">
								<Col xs={12}>
									<h4>BASIC</h4>
								</Col>
								<Col md={12}>
									<div className="flex-row max-participants-form-group align-items-center">
										<p>Maximum Patients: </p>
										<Form.Control
											size="sm"
											as="select"
											placeholder="max patients"
											custom
											className="ui-select small-int-select"
											value={state.MaxPatients}
											onChange={(e) => setState({ ...state, MaxPatients: e.currentTarget.value })}
										>
											<option value={1000} selected={state.MaxPatients == 1000}>
												1000
											</option>
											<option value={2000} selected={state.MaxPatients == 2000}>
												2000
											</option>
											<option value={3000} selected={state.MaxPatients == 3000}>
												3000
											</option>
											<option value={4000} selected={state.MaxPatients == 4000}>
												4000
											</option>
											<option value={5000} selected={state.MaxPatients == 5000}>
												5000
											</option>
											<option value={6000} selected={state.MaxPatients == 6000}>
												6000
											</option>
											<option value={7000} selected={state.MaxPatients == 7000}>
												7000
											</option>
											<option value={8000} selected={state.MaxPatients == 8000}>
												8000
											</option>
											<option value={9000} selected={state.MaxPatients == 9000}>
												9000
											</option>
											<option value={10000} selected={state.MaxPatients == 10000}>
												10000+
											</option>
										</Form.Control>
									</div>
								</Col>
							</Row>

							{/* 
								the following section displays available plans 
							*/}

							<Row>
								<Col>
									<ul className="no-padding">
										{props.plans.map((plan) => {
											return (
												<li>
													<LicenseCard
														newPlan={!props.ActiveOrganization.license || TrialLicense ? true : false}
														img={plan.logo}
														price={state.Monthly ? plan.price : plan.price * AnnualDiscountMonths}
														includes={plan.features.includes}
														excludes={plan.features.excludes}
														value={1}
														cta={
															props.ActiveOrganization.license && !TrialLicense && !state.NewPlan
																? plan.id === state.ActivePlan && !state.NewPlan
																	? "Cancel"
																	: "Select"
																: plan.id === state.ActivePlan && !state.NewPlan
																? "Selected"
																: "Select"
														}
														active={plan.id === state.ActivePlan && !state.NewPlan ? true : false}
														onCtaClick={(e) => HandlePlanClick(plan)}
													/>
												</li>
											);
										})}
									</ul>
								</Col>
							</Row>
						</section>

						{/* 
							the following section displays available addons 
						*/}

						<section>
							<Row>
								<Col>
									<h4>PRO</h4>
								</Col>
							</Row>
							<Row>
								<Col>
									<ul className="no-padding">
										{props.addons.map((addon) => {
											return (
												<li>
													<LicenseCard
														img={addon.logo}
														price={state.Monthly ? addon.price : addon.price * AnnualDiscountMonths}
														includes={addon.features.includes}
														excludes={addon.features.excludes}
														value={1}
														cta={state.ActiveAddons && addon.id in state.ActiveAddons ? "Remove" : "Select"}
														active={state.ActiveAddons && addon.id in state.ActiveAddons && !state.NewPlan ? true : false}
														onCtaClick={(e) => HandleAddonClick(addon)}
														name={addon.name}
													/>
												</li>
											);
										})}
									</ul>
								</Col>
							</Row>
						</section>

						{/* 
							displays pricing details when a plan is selected 
						*/}

						{!!state.PlanId && (
							<Fragment>
								<section className="new-license-info-wrapper">
									<Row>
										<Col>
											<h4>Details</h4>
										</Col>
									</Row>
									<Row className="info-details">
										<Col>
											<p>
												Selected Plan: <span>{GetPlanName(state.ActivePlan)}</span>
											</p>
											<p>
												Selected Addon(s): <span>{GetSelectedAddonNames()}</span>
											</p>
											<p>
												Maximum Patients: <span>{state.MaxPatients >= 10000 ? 10000 : state.MaxPatients}</span>
											</p>
											<div className="flex-row license-paypal-link">
												<p>Checkout with </p>
												<div className="paypal-btn">
													<PayPalButton
														options={{
															clientId: PaypalClientId, // client id set in application.js state
															currency: "USD", // currency always fixed to USD
															debug: false, // disable during production
															// commit: true,
															intent: "subscription",
															components: ["buttons", "marks"],
															// disableFunding: "card", // disables bank card (not credit card)
															vault: true,
														}}
														createSubscription={(data, action) => {
															return action.subscription.create({
																plan_id: state.PlanId,
															});
														}}
														onApprove={(data, actions) => {
															HandlePayPalOnApproval(data);
														}}
													/>
												</div>
											</div>
										</Col>
										<Col>
											<div className="form-nav-price-wrapper">
												<h3 className={`${state.Monthly ? "mo" : "yr"} ${state.CouponCode[0] ? "green-color" : ""}`}>{state.Total} USD</h3>
												<p className="smaller-text tax-text">+ applicable taxes</p>
												{state.CouponCode.length < 3 && state.Monthly && (
													<OverlayTrigger rootClose={true} trigger="click" placement="bottom-end" overlay={AddCoupon()}>
														<UiButton plus variant="outline-secondary" size="sm">
															Enter Coupon
														</UiButton>
													</OverlayTrigger>
												)}

												{state.CouponCode.length === 3 && (
													<>
														{state.CouponCode[2].type === "percent" && (
															<div>
																<p className="green-color smaller-text">
																	{state.CouponCode[2].value} Percent discount applied for {state.CouponCode[2].cycle} {state.CouponCode[2].interval.toLowerCase()}
																	{state.CouponCode[2].cycle == 1 ? "" : "s"}! (Renews at ${state.TotalBeforeCoupon} USD/{state.Monthly ? "month" : "year"})
																</p>
															</div>
														)}

														{state.CouponCode[2].type === "amount" && (
															<div>
																<p className="green-color smaller-text">
																	You saved ${state.CouponCode[2].value} for {state.CouponCode[2].cycle} {state.CouponCode[2].interval.toLowerCase()}
																	{state.CouponCode[2].cycle == 1 ? "" : "s"}! (Renews at ${state.TotalBeforeCoupon} USD/{state.Monthly ? "month" : "year"})
																</p>
															</div>
														)}
													</>
												)}
											</div>
										</Col>
									</Row>
								</section>
							</Fragment>
						)}
					</Form>
				)}

			{state.SuccessPage && ( // show success message after a license was created successfully
				<div>
					<section>
						<Row>
							<Col>
								<h3>Success! you're subscription has been setup</h3>
							</Col>
						</Row>
					</section>
					<section>
						<Row>
							<Col>
								<UiButton
									back
									onClick={(e) => {
										props.setState({ ...props.parentState, Refresh: props.parentState.Refresh + 1, Preloader: false, View: "overview" });
									}}
								>
									Go Back
								</UiButton>
							</Col>
						</Row>
					</section>
				</div>
			)}
		</div>
	);
};

export default License;

// // Old max patients dropdown beside the max users dropdown, for reference if needded again

// <Col md={5}>
// <div className="flex-row max-participants-form-group">
//   <p>Maximum Patients: </p>
//   <Form.Group as={Col} controlId="max-patients" lg={3}>
//     <Form.Control size="sm" as="select" placeholder="First Name" custom className="ui-select" value={state.MaxPatients} onChange={e => setState({...state, MaxPatients: e.currentTarget.value })} >
//       {/* <option value={state.MaxPatients}>{state.MaxPatients}</option> */}
//       {props.licenseDefaults.maxPatients.map(maxItem => <option value={maxItem[0]}>{maxItem[0]}</option>)}
//     </Form.Control>
//   </Form.Group>
//   <InfoPopup
//     trigger='click'
//     symbol='?'
//     content='50 is FREE, each additional 100 adds $50/month to subscription'
//     placement='right'
//   />
// </div>
// </Col>
