import React, { useState, Fragment } from 'react';
import { Container, Col, Row, Button, Image, Modal, Form, Alert } from 'react-bootstrap';

import "bootstrap/dist/css/bootstrap.min.css";
import "../App.css";
import "../resources/styles/application.css";
import "../resources/styles/nucca.scss";

import StandardHero from "../components/standard-hero.js";
import NavBar from "../components/navbar.component.js";
import Footer from "../components/footer.component.js";
import UiButton from "../components/dashboard/ui-button.js";

import MetaDecorator from "../components/metaDecorator.js";


export default function KnowledgeBase(props) {

  const [state, setState] = useState({
    CreateAccount: false,
    EditAccount: false,
    AddStaff: false,
    CreateOrganization: false,
    EditOrganization: false,
    AddLicense: false,
    DashboardAddPatient: false,
    DashboardEditPatient: false,
    DashboardViewPatientScans: false
  });

    
  return(

      <div className="knowledge-base-div">
          <NavBar />

          <MetaDecorator
            description="Bodiometer provides smart biomechanic scanning and analytics for consumers and clinical professionals to help prevent injury, and track and improve postural health!"
            title="Bodiometer - Knowledge Base"
            url="https://www.bodiometer.com/knowledge-base"
          />

          <StandardHero tall={false} bg={false}>
              <div>
                  <h1 className="blue-color">Knowledge Base</h1>
              </div>
          </StandardHero>

          <section className="no-padding">
            <Modal size="xl" className="video-modal-wrapper" centered show={state.CreateAccount} onHide={() => setState({...state, CreateAccount: false})}>
              <Modal.Header closeButton>
              </Modal.Header>
              <Modal.Body>
                <video autoPlay={true} loop={false} width="100%" controls>
                  <source src="../../videos/tutorials/Create-Account.mp4" type="video/mp4" />
                </video>
              </Modal.Body>
            </Modal>
            <Modal size="xl" className="video-modal-wrapper" centered show={state.EditAccount} onHide={() => setState({...state, EditAccount: false})}>
              <Modal.Header closeButton>
              </Modal.Header>
              <Modal.Body>
                <video autoPlay={true} loop={false} width="100%" controls>
                  <source src="../../videos/tutorials/Edit-Account-2.mp4" type="video/mp4" />
                </video>
              </Modal.Body>
            </Modal>
            <Modal size="xl" className="video-modal-wrapper" centered show={state.AddStaff} onHide={() => setState({...state, AddStaff: false})}>
              <Modal.Header closeButton>
              </Modal.Header>
              <Modal.Body>
                <video autoPlay={true} loop={false} width="100%" controls>
                  <source src="../../videos/tutorials/Add-Staff-2.mp4" type="video/mp4" />
                </video>
              </Modal.Body>
            </Modal>
            <Modal size="xl" className="video-modal-wrapper" centered show={state.CreateOrganization} onHide={() => setState({...state, CreateOrganization: false})}>
              <Modal.Header closeButton>
              </Modal.Header>
              <Modal.Body>
                <video autoPlay={true} loop={false} width="100%" controls>
                  <source src="../../videos/tutorials/Create-Organization.mp4" type="video/mp4" />
                </video>
              </Modal.Body>
            </Modal>
            <Modal size="xl" className="video-modal-wrapper" centered show={state.EditOrganization} onHide={() => setState({...state, EditOrganization: false})}>
              <Modal.Header closeButton>
              </Modal.Header>
              <Modal.Body>
                <video autoPlay={true} loop={false} width="100%" controls>
                  <source src="../../videos/tutorials/Edit-Organization.mp4" type="video/mp4" />
                </video>
              </Modal.Body>
            </Modal>
            <Modal size="xl" className="video-modal-wrapper" centered show={state.AddLicense} onHide={() => setState({...state, AddLicense: false})}>
              <Modal.Header closeButton>
              </Modal.Header>
              <Modal.Body>
                <video autoPlay={true} loop={false} width="100%" controls>
                  <source src="../../videos/tutorials/Add-License.mp4" type="video/mp4" />
                </video>
              </Modal.Body>
            </Modal>
            <Modal size="xl" className="video-modal-wrapper" centered show={state.DashboardAddPatient} onHide={() => setState({...state, DashboardAddPatient: false})}>
              <Modal.Header closeButton>
              </Modal.Header>
              <Modal.Body>
                <video autoPlay={true} loop={false} width="100%" controls>
                  <source src="../../videos/tutorials/Dashboard-Add-Patient.mp4" type="video/mp4" />
                </video>
              </Modal.Body>
            </Modal>
            <Modal size="xl" className="video-modal-wrapper" centered show={state.DashboardEditPatient} onHide={() => setState({...state, DashboardEditPatient: false})}>
              <Modal.Header closeButton>
              </Modal.Header>
              <Modal.Body>
                <video autoPlay={true} loop={false} width="100%" controls>
                  <source src="../../videos/tutorials/Dashboard-Edit-Patient.mp4" type="video/mp4" />
                </video>
              </Modal.Body>
            </Modal>
            <Modal size="xl" className="video-modal-wrapper" centered show={state.DashboardViewPatientScans} onHide={() => setState({...state, DashboardViewPatientScans: false})}>
              <Modal.Header closeButton>
              </Modal.Header>
              <Modal.Body>
                <video autoPlay={true} loop={false} width="100%" controls>
                  <source src="../../videos/tutorials/Dashboard-View-Patient-Scans.mp4" type="video/mp4" />
                </video>
              </Modal.Body>
            </Modal>
          </section>


          <section>
            <Container>
                <Row className="centered-text centered-row">
                  <Col>
                    <h2 className="green-color centered-text">Bodiometer Pro</h2>
                    <br/>
                    <br/>
                  </Col>
                </Row>
              </Container>
              <Container className="centered-text">
              <br/>
                  <Row className="kb-row">
                    <Col md={4} sm={12}>
                      <img src="../../videos/tutorials/play-icon.svg" width="80" height="80" onClick={() => setState({...state, CreateAccount: true})} />
                      <h3 className="blue-color" onClick={() => setState({...state, CreateAccount: true})}>Create an Account</h3>
                      <p className="gray-color">Watch the video to learn how to create a Bodiometer Pro user account.</p>
                    </Col>
                    <Col md={4} sm={12}>
                      <img src="../../videos/tutorials/play-icon.svg" width="80" height="80" onClick={() => setState({...state, EditAccount: true})} />
                      <h3 className="blue-color" onClick={() => setState({...state, EditAccount: true})}>Edit an Account</h3>
                      <p className="gray-color">Watch the video to learn how to edit your Bodiometer Pro user account.</p>
                    </Col>
                    <Col md={4} sm={12}>
                      <img src="../../videos/tutorials/play-icon.svg" width="80" height="80" onClick={() => setState({...state, CreateOrganization: true})} />
                      <h3 className="blue-color" onClick={() => setState({...state, CreateOrganization: true})}>Create Organization</h3>
                      <p className="gray-color">Watch the video to learn how to create a Bodiometer Pro organization account.</p>
                    </Col>
                  </Row>
                  <br/>
                  <Row className="kb-row">
                    <Col md={4} sm={12}>
                      <img src="../../videos/tutorials/play-icon.svg" width="80" height="80" onClick={() => setState({...state, EditOrganization: true})} />
                      <h3 className="blue-color" onClick={() => setState({...state, EditOrganization: true})}>Edit an Organization Account</h3>
                      <p className="gray-color">Watch the video to learn how to edit your Bodiometer Pro organization account.</p>
                    </Col>
                    <Col md={4} sm={12}>
                      <img src="../../videos/tutorials/play-icon.svg" width="80" height="80" onClick={() => setState({...state, AddStaff: true})} />
                      <h3 className="blue-color" onClick={() => setState({...state, AddStaff: true})}>Add Staff</h3>
                      <p className="gray-color">Watch the video to learn how to add staff to your organization account.</p>
                    </Col>
                    <Col md={4} sm={12}>
                      <img src="../../videos/tutorials/play-icon.svg" width="80" height="80" onClick={() => setState({...state, AddLicense: true})} />
                      <h3 className="blue-color" onClick={() => setState({...state, AddLicense: true})}>Add License</h3>
                      <p className="gray-color">Watch the video to learn how to add a license to your organization account.</p>
                    </Col>
                  </Row>
              </Container>
          </section>
          <section className="padding-mobile light-grad-bulge">
            <Container className="centered-container">
              <div className="title-icon stack-mobile">
                <h2 className="green-color centered-text">Bodiometer Dashboard</h2>
              </div>
              <br/>
            </Container>
            <Container className="centered-text">
            <br/>
              <Row className="kb-row">
                <Col md={4} sm={12}>
                  <img src="../../videos/tutorials/play-icon.svg" width="80" height="80" onClick={() => setState({...state, DashboardAddPatient: true})} />
                  <h3 className="blue-color" onClick={() => setState({...state, DashboardAddPatient: true})}>Add a Patient to your Dashboard</h3>
                  <p className="gray-color">Watch the video to learn how to add a new patient to your dashboard.</p>
                </Col>
                <Col md={4} sm={12}>
                  <img src="../../videos/tutorials/play-icon.svg" width="80" height="80" onClick={() => setState({...state, DashboardEditPatient: true})} />
                  <h3 className="blue-color" onClick={() => setState({...state, DashboardEditPatient: true})}>Edit Patient Info</h3>
                  <p className="gray-color">Watch the video to learn how to edit patient info.</p>
                </Col>
                <Col md={4} sm={12}>
                  <img src="../../videos/tutorials/play-icon.svg" width="80" height="80" onClick={() => setState({...state, DashboardViewPatientScans: true})} />
                  <h3 className="blue-color" onClick={() => setState({...state, DashboardViewPatientScans: true})}>View Patient Scans</h3>
                  <p className="gray-color">Watch the video to learn how to view patient scans and data in the dashboard.</p>
                </Col>
              </Row>
            </Container>
          </section>

          {/* <section className="nucca-details-wrapper">
            <Container className="centered-container">
              <div className="title-icon stack-mobile">
                <h2 className="green-color centered-text">Bodiometer Home</h2>
              </div>
              <br/>
              <Row xs={1} md={3} lg={3}>
                
              </Row>
              
            </Container>
          </section> */}

          <Footer />
      </div>
  );
};
