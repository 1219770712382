import React, {useState} from "react";
import {Card, Button, DropdownButton, Dropdown} from "react-bootstrap";

import UiButton from "./ui-button.js";

export default function LicenseCard(props) {
  const [state, setState] = useState({
    show: false,
  });

  return (
    <Card className={`license-card${props.active ? " active-plan" : ""}`}>
      {props.active && <p className="active-tag">Active</p>}
      <div className="license-card-name-wrapper">
        {props.img && <img src={props.img} width="100%" alt="Individual Bodiometer Product Image" />}
        {!props.img && <h3 className="blue-color">{props.name}</h3>}
        <hr />
        <h3>
          {typeof props.price === "number" && "$"}
          {props.price} USD
        </h3>
      </div>
      <ul>
        {props.includes.map((item) => {
          return (
            <li>
              <p className="includes">{item}</p>
            </li>
          );
        })}
        {props.excludes.map((item) => {
          return (
            <li>
              <p className="excludes">{item}</p>
            </li>
          );
        })}
      </ul>
      {props.existingLicense && props.active && props.img && props.parentState.ActiveOrganization.myAccessLevel === 1 && props.cta !== "hide" && (
        <div className="license-card-cta-wrapper">
          <UiButton onClick={(e) => props.onCtaClick()} size="sm" close={props.active && !props.newPlan ? true : false} variant={props.active ? `outline-${props.img && !props.newPlan ? "danger" : "dark"}` : "primary"} value={props.value}>
            {props.cta}
          </UiButton>
        </div>
      )}
      {!props.existingLicense && props.cta !== "hide" && (
        <div className="license-card-cta-wrapper">
          <UiButton onClick={(e) => props.onCtaClick()} size="sm" close={props.active && !props.newPlan ? true : false} variant={props.active ? `outline-${props.img && !props.newPlan ? "danger" : "dark"}` : "primary"} value={props.value}>
            {props.cta}
          </UiButton>
        </div>
      )}
    </Card>
  );
}
