import React from 'react';
import {Container, Col, Row, Button, Carousel} from 'react-bootstrap';
import { Chrono } from "react-chrono";
import { Link } from 'react-router-dom';

import "bootstrap/dist/css/bootstrap.min.css";
import "../App.css";
import "../resources/styles/about.scss";

import StandardHero from "../components/standard-hero.js";
import NavBar from "../components/navbar.component.js";
import Footer from "../components/footer.component.js";
import ScrollingCarousel from "../components/scrolling-carousel.js";

// Custom page meta with react-helmet
import MetaDecorator from "../components/metaDecorator.js";

export default function About(props) {

  const items = [{
    title: "July 2020",
    cardTitle: "Bodiometer notable highlight",
    cardSubtitle:"Lorem ipsum dolor sit amet, consectetuer adipiscing elit..."
  },
  {
    title: "January 2020",
    cardTitle: "Bodiometer notable highlight",
    cardSubtitle:"Lorem ipsum dolor sit amet, consectetuer adipiscing elit..."
  },
  {
    title: "May 2019",
    cardTitle: "Bodiometer notable highlight",
    cardSubtitle:"Lorem ipsum dolor sit amet, consectetuer adipiscing elit..."
  },
  {
    title: "Feb 2018",
    cardTitle: "Bodiometer notable highlight",
    cardSubtitle:"Lorem ipsum dolor sit amet, consectetuer adipiscing elit..."
  },
  {
    title: "June 2016",
    cardTitle: "Bodiometer notable highlight",
    cardSubtitle:"Lorem ipsum dolor sit amet, consectetuer adipiscing elit..."
  }
];

    return(

        <div>

            <NavBar />
            <MetaDecorator
                description="Bodiometer provides smart biomechanic scanning and analytics for consumers and clinical professionals to help prevent injury, and track and improve postural health!"
                title="Bodiometer - About Us"
                url="https://www.bodiometer.com/about"
            />

            <StandardHero tall={false} bg={false}>
                <div>
                    <img src="../../photos/icons/about-blue.svg" width="80" alt="about us icon" />
                    <h1 className="blue-color">About Us</h1>
                </div>
            </StandardHero>

            <section className="body-top-wrapper">
                <Container>
                    <Row className="centered-text centered-row">
                        <Col lg={9}>
                          <div>
                              <h2 className="gray-color">Building Software For a Better World</h2>
                              <hr/>
                              <br/>
                              <p>We are a group of innovators, entrepreneurs, scientists, and healthcare professionals who are building the future of biomechanics assessment. We build software that improves quality of life of users through innovative precision measurement on their mobile devices. We work with elite professionals in healthcare, occupational health and safety, human performance, and insurance to replace their current subjective, archaic, and inconsistent measurement tools with our objective, accurate, and engaging solutions.</p>
                          </div>
                        </Col>
                    </Row>
                </Container>
                {/* Timeline */}
                {/* <Container className="timeline-wrapper desktop-timeline">
                  <Chrono items={items} mode="VERTICAL_ALTERNATING" theme={{primary: "#2a93d0", secondary: "linear-gradient(90deg, rgba(42,147,208,1) 0%, rgba(69,179,73,1) 100%)", cardBgColor: "#eaf4f9", cardForeColor: "#2a93d0" }}/>
                </Container>
                <Container fluid className="timeline-wrapper mobile-timeline">
                  <Chrono items={items} mode="VERTICAL" theme={{primary: "#2a93d0", secondary: "linear-gradient(90deg, rgba(42,147,208,1) 0%, rgba(69,179,73,1) 100%)", cardBgColor: "#eaf4f9", cardForeColor: "#2a93d0" }}/>
                </Container> */}
            </section>
              <Container fluid>
                <Row xs={1} md={1} lg={2} noGutters className="application-section-wrapper about-application-wrapper">
                  <Col lg={8}>
                    <div className="professionals-applications-wrapper">
                      <img src="../../photos/for-professionals/applications-bg.png" width="100%" alt="professional applications background" />
                      <Carousel hover="pause" className="applications-carousel" fade={false} pause="hover" interval={null} nextIcon={<img src="../../photos/consumer-landing/next-arrow.svg" height="27" alt="next carousel item - arrow icon" />}>
                        <Carousel.Item>
                          <h3>Healthcare</h3>
                          <hr />
                          <p>We believe the future of healthcare is built on a foundation of ‘precision measurement’. The problem for many professionals is the expense of precision measurement tools (like MRI and CT) and their lack of portability. Bodiometer solves this problem by putting a real-time motion capture tool in the pockets of professionals who depend on precision measurements to excel. Using Bodiometer Apps professionals can aim their iPhone or iPad cameras at a person and automatically capture their posture and body mechanics in a fraction of the time of the current solutions. This helps them to track people’s progress and outcomes digitally as well as educating them immediately.</p>
                        </Carousel.Item>
                        <Carousel.Item>
                          <h3>Occupational Health and Safety</h3>
                          <hr />
                          <p>Economic burden of musculoskeletal disorders (MSD) in Canada is estimated to be $22 Billion. Employers are required by law to take every reasonable precaution to protect workers from hazards, including those that may result in musculoskeletal (MSK) injuries. Bodiometer puts a real-time motion-capture tool in the pockets of ergonomists to point their iPhone or iPad at a person and track their posture in a few seconds. This reduces assessment time, increases precision and objectivity and enhances engagement and education. Bodiometer can change the game in the field of occupational health and safety by replacing the current subjective, anecdotal and inconsistent measurement tools with an objective, accurate and engaging mobile tool.</p>
                        </Carousel.Item>
                        <Carousel.Item>
                          <h3>Human Performance</h3>
                          <hr />
                          <p>The human body is a wonder of engineering at every level and amazing humans continue to push the boundaries of what can be done. From a child's first steps to professional athletes on game day we rely on our biomechanical systems to perform. Our technology enables objective analysis of these systems and the critical relationships within and between them that must be maintained to promote activity and prevent injury. Our technology has the capacity to decode the body language of action so that we can finally hear what the body has been telling us all this time.</p>
                        </Carousel.Item>
                      </Carousel>
                    </div>
                  </Col>
                  <Col lg={4}>
                    <div className="title-icon-stacked">
                      <div>
                        <img src="../../photos/icons/applications-blue.svg" width="80" alt="industry applications icon" />
                        <h2 className="blue-color">Industry<br/>Applications</h2>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Container>

              <section className="about-supporters-wrapper">

                {/* old scroller */}
                {/* <Container>
                  <Row xs={1} md={1}>
                    <Col md={12} lg={5}>
                      <div className="title-icon stack-mobile">
                        <img src="../../photos/icons/handshake-green.svg" width="80" alt="handshake icon" />
                        <h2 className="green-color">Our Supporters</h2>
                      </div>
                    </Col>
                    <Col lg={7} md={12}>
                      <Carousel className="scrolling-carousel on-white about-carousel" fade={false} pause={false} interval={3000} nextIcon={<img src="../../photos/consumer-landing/next-arrow.svg" height="27" alt="next carousel item - arrow icon" />}>
                        <Carousel.Item>
                          <Row xs={2} md={2}>
                            <Col>
                              <a href="https://www.plugandplaytechcenter.com/" target="_blank" rel="noopener noreferrer"><img src="../../photos/about/supporters/plug-and-play.png" width="100%" alt="plug and play - logo" /></a>
                            </Col>
                            <Col>
                              <a href="https://albertainnovates.ca/" target="_blank" rel="noopener noreferrer"><img src="../../photos/about/supporters/ab-innovates.png" width="100%" alt="ab innovatees - logo" /></a> 
                            </Col>
                          </Row>
                        </Carousel.Item>
                        <Carousel.Item>
                          <Row xs={2} md={2}>
                            <Col>
                              <a href="https://innovatecalgary.com/" target="_blank" rel="noopener noreferrer"><img src="../../photos/about/supporters/innovate-calgary.png" width="100%" alt="innovate calgary - logo" /></a>
                            </Col>
                            <Col>
                              <a href="https://nrc.canada.ca/en" target="_blank" rel="noopener noreferrer"><img src="../../photos/about/supporters/nrc-cnrc.png" width="100%" alt="nrc-cnrc - logo" /></a>
                            </Col>
                          </Row>
                        </Carousel.Item>
                        <Carousel.Item>
                          <Row xs={2} md={2}>
                            <Col>
                              <a href="https://www.nserc-crsng.gc.ca/index_eng.asp" target="_blank" rel="noopener noreferrer"><img src="../../photos/about/supporters/nserc.png" width="100%" alt="nserc - logo" /></a>
                            </Col>
                            <Col>
                              <a href="http://cdmn.ca/" target="_blank" rel="noopener noreferrer"><img src="../../photos/about/supporters/cdmn.png" width="100%" alt="cdmn - logo" /></a>
                            </Col>
                          </Row>
                        </Carousel.Item>
                        <Carousel.Item>
                          <Row xs={2} md={2}>
                            <Col>
                              <a href="https://haskayne.ucalgary.ca/" target="_blank" rel="noopener noreferrer"><img src="../../photos/about/supporters/haskayne.png" width="100%" alt="u of c haskayne school of business - logo" /></a>
                            </Col>
                            <Col>
                              <a href="https://www.w21c.org/" target="_blank" rel="noopener noreferrer"><img src="../../photos/about/supporters/w21c.png" width="100%" alt="w21c - logo" /></a>
                            </Col>
                          </Row>
                        </Carousel.Item>
                        <Carousel.Item>
                          <Row xs={2} md={2}>
                            <Col>
                              <a href="https://www.tecedmonton.com/" target="_blank" rel="noopener noreferrer"><img src="../../photos/about/supporters/tec-edmonton.png" width="100%" alt="tec edmonton - logo" /></a>
                            </Col>
                            <Col>
                              <a href="https://tecterra.com/" target="_blank" rel="noopener noreferrer"><img src="../../photos/about/supporters/tecterra.png" width="100%" alt="tecterra - logo" /></a>
                            </Col>
                          </Row>
                        </Carousel.Item>
                        <Carousel.Item>
                          <Row>
                            <Col>
                              <a href="https://www.wd-deo.gc.ca/eng/home.asp" target="_blank" rel="noopener noreferrer"><img src="../../photos/about/supporters/wedc.png" width="100%" alt="Western Economic Diversification Canada - logo" /></a>
                            </Col>
                          </Row>
                        </Carousel.Item>
                      </Carousel>
                    </Col>
                  </Row>
                </Container> */}

                <Container>
                  <Row xs={1} md={1}>
                    <Col md={12} lg={5}>
                      <div className="title-icon stack-mobile">
                        <img src="../../photos/icons/handshake-green.svg" width="80" alt="handshake icon" />
                        <h2 className="green-color">Our Supporters</h2>
                      </div>
                    </Col>
                    <Col lg={7} md={12}>
                      <ScrollingCarousel 
                        images={[
                          {src: '../../photos/about/supporters/plug-and-play.png', link: 'https://www.plugandplaytechcenter.com/', alt: 'plug and play - logo'},
                          {src: '../../photos/about/supporters/ab-innovates.png', link: 'https://albertainnovates.ca/', alt: 'ab innovatees - logo'},
                          {src: '../../photos/about/supporters/innovate-calgary.png', link: 'https://innovatecalgary.com/', alt: 'innovate calgary - logo'},
                          {src: '../../photos/about/supporters/nrc-cnrc.png', link: 'https://nrc.canada.ca/en', alt: 'nrc-cnrc - logo'},
                          {src: '../../photos/about/supporters/nserc.png', link: 'https://www.nserc-crsng.gc.ca/index_eng.asp', alt: 'nserc - logo'},
                          {src: '../../photos/about/supporters/cdmn.png', link: 'http://cdmn.ca/', alt: 'cdmn - logo'},
                          {src: '../../photos/about/supporters/haskayne.png', link: 'https://haskayne.ucalgary.ca/', alt: 'u of c haskayne school of business - logo'},
                          {src: '../../photos/about/supporters/w21c.png', link: 'https://www.w21c.org/', alt: 'w21c - logo'},
                          {src: '../../photos/about/supporters/tec-edmonton.png', link: 'https://www.tecedmonton.com/', alt: 'tec edmonton - logo'},
                          {src: '../../photos/about/supporters/tecterra.png', link: 'https://tecterra.com/', alt: 'tecterra - logo'},
                          {src: '../../photos/about/supporters/wedc.png', link: 'https://www.wd-deo.gc.ca/eng/home.asp', alt: 'Western Economic Diversification Canada - logo'}
                        ]}
                      />
                    </Col>
                  </Row>
                </Container>





              </section>

              <section className="cta-centered about-cta-wrapper">
                <Container className="centered-container">
                  <h2 className="gray-color">Get in Touch!</h2>
                  <hr></hr>
                  <Link to="/contact"><Button size="lg" variant="primary">Contact Us</Button></Link>
                </Container>
              </section>

            <Footer />

        </ div>
    );
};
