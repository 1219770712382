import React, { useState, useMemo, useEffect, useRef, Fragment } from "react";
import { Container, Col, Row, Form } from "react-bootstrap";
import Datetime from "react-datetime";
import axios from "axios";
import countryList from "react-select-country-list";
import md5Hash from "../resources/helpers/md5.js";

import UiButton from "../components/dashboard/ui-button.js";
import UserUiPassword from "../components/dashboard/user-ui-password.js";
import { dbUrl } from "../lib/config";

import "../resources/styles/dashboard.scss";
import "bootstrap/dist/css/bootstrap.min.css";

const UserRegister = (props) => {
	const [state, setState] = useState({
		Validated: false,
		Fname: "",
		Lname: "",

		Email1: "",
		Email2: "",
		Email1InValid: [false, ""],
		Email2InValid: [false, ""],
		EmailPass: false,
		Country: "",
		DateOfBirth: new Date().toLocaleDateString(),
		Sex: "",

		Password1: "",
		PasswordEye1: false,
		Password1InValid: [false, ""],
		Password2: "",
		PasswordEye2: false,
		Password2InValid: [false, ""],
		PasswordValid: false,
		HasAgreed: false,

		Username: "",
		UsernameInValid: [false, ""],

		UserRegistrationSuccessful: false,
		UserRegistrationFailed: false,
	});

	const ValidateEmail = (email) => {
		return String(email)
			.toLowerCase()
			.match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
	};

	const dateTimePickerRef = useRef(null);

	useEffect(() => {
		let value1 = [true, "Something went wrong..."];
		let value2 = [true, "Something went wrong..."];

		if (!!state.Email1) {
			if (ValidateEmail(state.Email1)) {
				value1 = [false, ""];
			}
		} else {
			value1 = [false, ""];
		}

		if (!!state.Email2) {
			if (ValidateEmail(state.Email2)) {
				value2 = [false, ""];
			}
		} else {
			value2 = [false, ""];
		}

		let value3 = !!state.Email1 && !!state.Email2 && !value1[0] && !value2[0] ? true : false;

		setState({ ...state, Email1InValid: value1, Email2InValid: value2, EmailPass: value3 });
	}, [state.Email1, state.Email2]);

	const HandleSubmit = (e) => {
		e.preventDefault();
		e.stopPropagation();

		if (state.EmailPass && state.PasswordValid && !!state.Fname && state.HasAgreed && !!state.Username) {
			let configOne = {
				method: "post",
				url: dbUrl + "/api/token/dbo",
				headers: {
					password: "746C419785D84DC8B3A20B9195363105",
				},
			};

			axios(configOne, { timeout: 10000 })
				.then((responseOne) => {
					if (responseOne.data != "Invalid Credentials") {
						var data = JSON.stringify({
							userId: state.Username,
							password: md5Hash(state.Password1),
							email: state.Email1,
							firstName: state.Fname,
							lastName: state.Lname,
							country: state.Country,
							gender: state.Sex,
							dateOfBirth: state.DateOfBirth,
							createdAt: new Date().toLocaleTimeString(),
							createdAtOffset: 0,
						});

						var configTwo = {
							method: "post",
							url: dbUrl + "/api/personalusers/v1/create",
							headers: {
								Authorization: "Bearer " + responseOne.data,
								"Content-Type": "application/json",
							},
							data: data,
						};

						axios(configTwo, { timeout: 10000 })
							.then((responseTwo) => {
								if (responseTwo.data.success) {
									var configThree = {
										method: "post",
										url: dbUrl + "/api/email/personalusers/confirmation/",
										headers: {
											Authorization: "Bearer " + responseOne.data,
											"Content-Type": "application/json",
										},
										data: JSON.stringify({ userId: state.Username }),
									};

									axios(configThree, { timeout: 10000 })
										.then((_) => {})
										.catch((_) => {});
									setState({ ...state, UserRegistrationSuccessful: true });
								} else {
									setState({ ...state, UserRegistrationFailed: true });
								}
							})
							.catch((_) => {
								setState({ ...state, UserRegistrationFailed: true });
							});
					} else {
						setState({ ...state, UserRegistrationFailed: true });
					}
				})
				.catch((errorOne) => {
					setState({ ...state, UserRegistrationFailed: true });
				});
		}
	};

	const Countries = useMemo(() => countryList().getLabels(), []);

	const HandlePasswordEyeClick_1 = (_) => {
		setState({ ...state, PasswordEye1: !state.PasswordEye1 });
	};

	const HandlePasswordEyeClick_2 = (_) => {
		setState({ ...state, PasswordEye2: !state.PasswordEye2 });
	};

	const HandlePasswordChange_1 = (e) => {
		let value = e.currentTarget.value;
		setState({ ...state, Password1: value });
	};

	const HandlePasswordChange_2 = (e) => {
		let value = e.currentTarget.value;
		console.log(value);
		setState({ ...state, Password2: value });
	};

	useEffect(() => {
		let value1 = [false, ""];
		let value2 = [false, ""];

		if (!!state.Password1) {
			if (state.Password1.length >= 6) {
				value1 = [false, ""];
			} else {
				value1 = [true, "too short"];
			}
		} else {
			value1 = [false, ""];
		}

		if (!!state.Password2) {
			if (state.Password2.length >= 6) {
				value2 = [false, ""];
			} else {
				value2 = [true, "too short"];
			}
		} else {
			value2 = [false, ""];
		}

		let value3 = !!state.Password1 && !!state.Password2 && !value1[0] && !value2[0] && state.Password1 == state.Password2 ? true : false;

		setState({ ...state, Password1InValid: value1, Password2InValid: value2, PasswordValid: value3 });
	}, [state.Password1, state.Password2]);

	return (
		<section className="landing-form-section-wrapper">
			<Container className="centered-container">
				<Row>
					<Col className="landing-form-wrapper" lg={5} md={6} sm={10}>
						<img src="../../photos/bodiometer-home-logo-stacked.svg" width="250" alt="Bodiometer Logo" />

						{/* starts here */}

						{!!!state.UserRegistrationSuccessful && !!!state.UserRegistrationFailed && (
							<Fragment>
								<h2 className="blue-color">Register</h2>
								<p>{props.description ? props.description : "Create a new user account"}</p>
								<Form validated={state.Validated} onSubmit={(e) => HandleSubmit(e)}>
									<Row>
										<Form.Group as={Col} controlId="register_fname">
											<Form.Control size="sm" type="input" value={state.Fname} onChange={(e) => setState({ ...state, Fname: e.currentTarget.value })} placeholder="First Name" required />
										</Form.Group>
										<Form.Group as={Col} controlId="register_lname">
											<Form.Control size="sm" type="input" value={state.Lname} onChange={(e) => setState({ ...state, Lname: e.currentTarget.value })} placeholder="Last Name" required />
										</Form.Group>
									</Row>

									<Row>
										<Form.Group as={Col} controlId="register-email-1">
											<Form.Control
												size="sm"
												type="email"
												value={state.Email1}
												onChange={(e) => setState({ ...state, Email1: e.currentTarget.value })}
												placeholder="Email"
												required
												isInvalid={state.Email1InValid[0]}
											/>
											<Form.Control.Feedback type="invalid">{state.Email1InValid[1]}</Form.Control.Feedback>
										</Form.Group>
									</Row>

									<Row>
										<Form.Group as={Col} controlId="register-email-2">
											<Form.Control
												size="sm"
												type="email"
												value={state.Email2}
												onChange={(e) => setState({ ...state, Email2: e.currentTarget.value })}
												placeholder="Confirm Email"
												required
												isInvalid={state.Email2InValid[0]}
											/>
											<Form.Control.Feedback type="invalid">{state.Email2InValid[1]}</Form.Control.Feedback>
										</Form.Group>
									</Row>

									<Row>
										<Form.Group as={Col} controlId="register-country">
											<Form.Control
												size="sm"
												as="select"
												options={Countries}
												placeholder="Country"
												custom
												className="ui-select"
												value={state.Country}
												onChange={(e) => setState({ ...state, Country: e.currentTarget.value })}
												required
											>
												<option value="canada">Canada</option>
												{Countries.map((country) => (
													<option value={country}>{country}</option>
												))}
											</Form.Control>
										</Form.Group>

										<Form.Group as={Col} controlId="date-of-birth">
											{/* <Form.Control size="sm" type="input" value={state.Email2} onChange={(e) => setState({...state, Email2: e.currentTarget.value})} placeholder="Date of Birth (MM-DD-YYYY)" required /> */}

											<Datetime
												// style={{fontSize: "0.875rem", fontWeight: "400", color: "#495057", lineHeight: "1.5"}}
												initialValue={state.DateOfBirth}
												onChange={(e) => {
													try {
														if (!!e) {
															let dob = e.format("MM/DD/YYYY");
															let dateObj = new Date(dob);
															var month = dateObj.getUTCMonth() + 1; //months from 1-12
															var day = dateObj.getUTCDate();
															var year = dateObj.getUTCFullYear();
															setState({ ...state, DateOfBirth: new Date(year, month, day).toLocaleDateString() });
														}
													} catch {}
												}}
												dateFormat="MM/DD/YYYY"
												timeFormat={false}
												ref={dateTimePickerRef}
												closeOnSelect
											/>
										</Form.Group>
									</Row>

									<Row>
										<Form.Group as={Col} controlId="register_sex">
											<Form.Control
												size="sm"
												as="select"
												placeholder="Country"
												custom
												className="ui-select"
												value={state.Sex}
												onChange={(e) => setState({ ...state, Sex: e.currentTarget.value })}
												required
											>
												<option value="">Select Sex</option>
												<option value="male">Male</option>
												<option value="female">Female</option>
												<option value="other">Other</option>
											</Form.Control>
										</Form.Group>
										<Form.Group as={Col} controlId="register_username">
											<Form.Control size="sm" type="input" value={state.Username} onChange={(e) => setState({ ...state, Username: e.currentTarget.value })} placeholder="Username" required />
											<Form.Control.Feedback type="invalid">{state.UsernameInValid[1]}</Form.Control.Feedback>
										</Form.Group>
									</Row>

									<Row>
										<UserUiPassword
											isEyeOpen={state.PasswordEye1}
											value={state.Password1}
											onChange={HandlePasswordChange_1}
											onBlur={HandlePasswordChange_1}
											placeholder="Password"
											text="At least 6 characters, but longer is better."
											controlId="register_password_1"
											feedback="Password must be at least 6 characters in length"
											onEyeClick={HandlePasswordEyeClick_1}
											isInvalid={state.Password1InValid[0]}
											message={state.Password1InValid[1]}
										/>
									</Row>

									<Row>
										<UserUiPassword
											isEyeOpen={state.PasswordEye2}
											value={state.Password2}
											onChange={HandlePasswordChange_2}
											onBlur={HandlePasswordChange_2}
											placeholder="Retype Password"
											controlId="register_password_2"
											retype
											onEyeClick={HandlePasswordEyeClick_2}
											isInvalid={state.Password2InValid[0]}
											message={state.Password2InValid[1]}
										/>
									</Row>

									<Row>
										<Form.Group controlId="terms_checkbox" className="form-footer-wrapper terms-wrapper">
											<Form.Check type="checkbox">
												<Form.Check.Input
													checked={state.HasAgreed}
													onChange={() => {
														setState({ ...state, HasAgreed: !state.HasAgreed });
													}}
													type={"checkbox"}
													isValid={state.HasAgreed}
												/>
												<Form.Check.Label className="gray-color">
													I agree to the{" "}
													<a target="_blank" href="/terms">
														{"Terms & Conditions"}
													</a>{" "}
													and{" "}
													<a target="_blank" href="/privacy">
														{"Privacy Policy"}
													</a>
												</Form.Check.Label>
												{/* <Form.Control.Feedback type="invalid">You have to check the box</Form.Control.Feedback> */}
											</Form.Check>
										</Form.Group>
									</Row>

									<Row>
										<Form.Group controlId="consent" className="form-footer-wrapper">
											<UiButton disabled={!state.HasAgreed || !state.EmailPass || !state.PasswordValid} type="submit" onSubmit={HandleSubmit}>
												Register
											</UiButton>
										</Form.Group>
									</Row>
								</Form>
							</Fragment>
						)}

						{state.UserRegistrationSuccessful && (
							<div>
								<p>Please check your email inbox for further instructions</p>
								<UiButton variant="secondary" onClick={(_) => props.setState({ ...props.state, View: "login" })} back>
									Back
								</UiButton>
							</div>
						)}

						{state.UserRegistrationFailed && (
							<div>
								<p>Something has gone wrong with your request. Please contact someone from the Bodiometer Team. </p>
								<UiButton variant="secondary" onClick={(_) => props.setState({ ...props.state, View: "login" })} back>
									Back
								</UiButton>
							</div>
						)}
					</Col>
				</Row>
			</Container>
			<br />
			{!!!state.UserRegistrationSuccessful && !!!state.UserRegistrationFailed && (
				<p className="centered-text smaller-text">
					Already have an account?{" "}
					<a href="#" onClick={(_) => props.setState({ ...props.state, View: "login" })}>
						Login
					</a>
				</p>
			)}
		</section>
	);
};

export default UserRegister;
