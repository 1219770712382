import React from 'react';
import { Button}  from 'react-bootstrap';


import "../../resources/styles/dashboard.scss";
import "bootstrap/dist/css/bootstrap.min.css";

export default function UiButton(props) {
  return(
    <Button
      
      href={props.href}
      onClick={props.onClick}
      disabled={props.disabled}
      onSubmit={props.onSubmit}
      type={props.type}
      value={props.value}
      variant={props.variant ? props.variant : 'primary'}
      size={props.size}
      className={props.className + (props.visibility ? ' hidden' : '')}
      style={{color: "grey !important"}}
    >
      {props.back &&
        <span style={{display: 'inline-block', transform: 'scale(1.1)'}}> 
          &lt; &nbsp;
        </span>
      }
      {props.close &&
        <span style={{display: 'inline'}}> 
          X &nbsp;
        </span>
      }
      {props.children}
      {props.next &&
        <span style={{display: 'inline-block', transform: 'scale(1.1)'}}> 
          &nbsp; &gt;
        </span>
      }
      {props.plus &&
        <span style={{display: 'inline-block', transform: 'scale(1.3)'}}> 
          &nbsp; +
        </span>
      }
    </Button>
  );
};