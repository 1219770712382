import React, { useState } from 'react';
import { Card, Button, DropdownButton, Dropdown } from 'react-bootstrap';

export default function PricingCard(props) {

  const [state, setState] = useState({
    show: false
  });

  return (
    <Card className="pricing-card">
      <a href={props.url}><img src={props.img} width="180" alt="Individual Bodiometer Product Image"/></a>
      <br/>
      <hr/>
      <br/>
      <h3>{typeof props.price === 'number' && '$'}{props.price}</h3>
      {typeof props.price === 'number' &&
      <p className="gray-color">Annually</p>
      }
      <ul>
        {props.includes.map(item => {
          return <li className="includes">{item}</li>
        })}
        {props.excludes.map(item => {
          return <li className="excludes">{item}</li>
        })}
      </ul>
      {/* <a href={props.url} className="blue-color learn-more-btn">Learn More</a> */}


      {/* { props.cta !== 'Download' &&
      <Button variant="primary" value={props.value} onClick={props.ShowCart}>{props.cta}</Button>
      } */}

      { props.cta === 'Buy Now' &&
      <Button variant="primary" value={props.value} onClick={props.ShowCart}>{props.cta}</Button>
      }

      { props.cta === 'Get Started' &&
      <>
      <p className="smaller-text blue-color">Register today to claim your {props.freeTrialLength} Day Free Trial</p>
      <Button variant="primary" href="/portal/register" target="_self">{props.cta}</Button>
      </>
      }

      { props.cta === 'Get A Quote' &&
      <>
      <p className="smaller-text blue-color">Inquire today for pricing</p>
      <Button variant="primary" href="/contact" target="_self">{props.cta}</Button>
      </>
      }

      { props.cta === 'Download' &&
      <Button variant="outline-secondary" target="_blank" href="https://apps.apple.com/us/app/bodiometer-home/id1461395471" onClick="ga('send', 'event', { eventCategory: 'cta', eventAction: 'click', eventLabel: 'bodiometerHome', eventValue: '1'});">{props.cta}</Button>
      }

      <br/>
      { props.download &&
        <Button variant="outline-secondary" target="_blank" href={props.download}>Download</Button>
      }
    </Card>
  );
}
