import {useContext, createContext} from "react";

export const AppContext = createContext(null);
export const UserDashboardContext = createContext(null);

export function useAppContext(){
    return useContext(AppContext);
}
export function useUserDashboardContext(){
    return useContext(UserDashboardContext);
}
