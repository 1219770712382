import React from 'react';
import {Container, Col, Row, Image, Button} from 'react-bootstrap';

import NavBar from "../components/navbar.component.js";
import Footer from "../components/footer.component.js";

import "bootstrap/dist/css/bootstrap.min.css";
import "../App.css";
import "../resources/styles/solution.scss";

import prodImgLite from "../resources/photos/products/lite.jpg";
import prodImgMobile from "../resources/photos/products/mobile.jpg";
import prodImgDesktop from "../resources/photos/products/desktop.jpg";

// Custom page meta with react-helmet
import MetaDecorator from "../components/metaDecorator.js";

export default function Solution() {

    const TabletIcon = () => {
        return(
            <svg viewBox="0 0 24 24">
                <path fill="#000000" d="M19,19H4V3H19M11.5,23A1.5,1.5 0 0,1 10,21.5A1.5,1.5 0 0,1 11.5,20A1.5,1.5 0 0,1 13,21.5A1.5,1.5 0 0,1 11.5,23M18.5,0H4.5A2.5,2.5 0 0,0 2,2.5V21.5A2.5,2.5 0 0,0 4.5,24H18.5A2.5,2.5 0 0,0 21,21.5V2.5A2.5,2.5 0 0,0 18.5,0Z" />
            </svg>
        );
    }

    const DesktopIcon = () => {
        return(
            <svg viewBox="0 0 24 24">
                <path fill="#000000" d="M21,14H3V4H21M21,2H3C1.89,2 1,2.89 1,4V16A2,2 0 0,0 3,18H10L8,21V22H16V21L14,18H21A2,2 0 0,0 23,16V4C23,2.89 22.1,2 21,2Z" />
            </svg>
        );
    }

    const MobileIcon = () => {
        return(
            <svg viewBox="0 0 24 24">
                <path fill="#000000" d="M17,19H7V5H17M17,1H7C5.89,1 5,1.89 5,3V21A2,2 0 0,0 7,23H17A2,2 0 0,0 19,21V3C19,1.89 18.1,1 17,1Z" />
            </svg>
        );
    }

    return(
        <>
            <MetaDecorator
                description="Bodiometer offers smart biomechanic scanning and analytics for consumers and clinical professionals."
                title="Bodiometer - Solution"
                url="https://www.bodiometer.com/solution"
            />
            <NavBar />

                <section className="solutions-wrapper">
                    <Container id="solution-background" fluid>
                        <Row>
                            <Col>
                                <h1>Solutions</h1>
                            </Col>
                        </Row>
                    </Container>


                    <Container id="solution-lite" fluid>
                        <Row>
                            <Col sm={12} md={6}>
                                <h2>Bodiometer Desktop {DesktopIcon()}</h2>
                                
                                <hr />
                                <p>Bodiometer Desktop is our most advanced clinical biomechanics assessment tool. Using markerless 
                                    motion-tracking, you can quickly and accurately measure range of motion, posture, and functional 
                                    movement patterns. Our range of motion module allows you to measure 14 different joints in a variety 
                                    of different movements. Our posture module allows you to quickly measure posture in the coronal, 
                                    sagittal and traverse planes. Lastly, our functional movement module allows you to measure any kind 
                                    of functional movement you can think of and compare a patient’s performance over time/  Bodiometer 
                                    allows you to track patient data easily, and show a patient their progress with engaging and 
                                    informative progress reports. Bodiometer gives you the tools to collect accurate, objective data 
                                    quickly, and educate your patients about the structure of their bodies.</p>
                                <Button variant="success" onClick={() => { window.location.replace("/product/desktop")}}>find out more</Button>
                            </Col>
                            <Col sm={12} md={6}>
                                <Image src={prodImgDesktop}></Image>
                            </Col>
                        </Row>
                    </Container>

                    <Container id="solution-mobile" fluid>
                        <Row>
                            
                            <Col sm={12} md={6}>
                                <Image src={prodImgMobile}></Image>
                            </Col>
                            <Col sm={12} md={6}>
                                <h2>Bodiometer Mobile {TabletIcon()}</h2>
                                <hr />
                                <p>As the world's first real-time posture assessment app, Bodiometer Mobile enables clinicians to 
                                    quickly and accurately assess patient posture without markers or other devices in almost any 
                                    setting. Bodiometer utilizes an AI-driven computer vision system to automatically extract patient 
                                    skeletal data relying only on the camera of the mobile device. Patient scans are saved in app, 
                                    enabling users to track progress or changes in their posture over the course of treatment. Clinicians 
                                    can compare scans directly with comparative reports. These visual reports are easy to print or share 
                                    with the patient as a pdf. These reports makes a huge difference in patient education and engagement 
                                    which means better outcomes for everyone.</p>
                                <Button variant="success" onClick={() => { window.location.replace("/product/mobile")}}>find out more</Button>
                            </Col>
                        </Row>
                    </Container>

                    <Container id="solution-desktop" fluid>
                        <Row>
                            <Col sm={12} md={6}>
                                <h2>Bodiometer Lite {MobileIcon()} </h2>
                                <hr />
                                <p>Bodiometer Lite provides curious users the option to test out the basic function of the app 
                                    without a subscription. The core functionality is the ability to rapidly take a posture scan, 
                                    produce a report and show the report to the patient/client. This version is scaled down but is 
                                    useful as a demonstration tool and for quick assessment. Bodiometer Lite is also the first 
                                    version available on iPhone.</p>
                                <Button variant="success" onClick={() => { window.location.replace("/product/lite")}}>find out more</Button>
                            </Col>
                            <Col sm={12} md={6}>
                                <Image src={prodImgLite}></Image>
                            </Col>
                        </Row>
                    </Container>

                </section>

            <Footer />
        </>
    );
};