import React, { Fragment, useEffect, useState, useRef } from "react";
import { Alert, Row, Col, Popover, OverlayTrigger, Modal, Form, Button, Spinner } from "react-bootstrap";
import axios from "axios";
import Datetime from "react-datetime";
import { useAppContext } from "../lib/context.js";

import DisplayPhone from "../resources/helpers/display-phone";
import UiButton from "../components/dashboard/ui-button.js";

import "bootstrap/dist/css/bootstrap.min.css";
import "../resources/styles/dashboard.scss";

const UserProfile = ({ dbUrl }) => {
  const { appState } = useAppContext();
  const dateTimePickerRef = useRef(null);
  const userId = appState.userData.Id;

  const [state, setState] = useState({
    isProfileLoaded: false,
    userProfile: {},
    userProfileEdited: {},
    isFormValidated: false,
    isChanged: false,
    success: [false, ""],
    error: [false, ""],
  });

  const LoadProfile = async (userId) => {
    try {
      let configOne = {
        method: "POST",
        url: dbUrl + "/api/token/dbo",
        headers: {
          password: "746C419785D84DC8B3A20B9195363105",
        },
      };

      let responseOne = await axios(configOne, { timeout: 5000 });
      if (responseOne.data != "Invalid Credentials") {
        let configTwo = {
          method: "GET",
          url: dbUrl + "/api/personalusers/v1/get/id",
          headers: {
            Authorization: "Bearer " + responseOne.data,
            userId: userId,
          },
        };

        let responseTwo = await axios(configTwo, { timeout: 5000 });

        if (responseTwo.data.success) {
          setState({
            ...state,
            userProfile: { ...responseTwo.data.data },
            userProfileEdited: { ...responseTwo.data.data },
          });
        }
      }
    } catch (err) {
      // console.log(err);
    }
  };

  const HandleEditFormSubmit = async (evt, UserProfile, userId) => {
    
    evt.preventDefault();
    evt.stopPropagation();

    try {
      let configOne = {
        method: "POST",
        url: dbUrl + "/api/token/dbo",
        headers: {
          password: "746C419785D84DC8B3A20B9195363105",
        },
      };

      let responseOne = await axios(configOne, { timeout: 5000 });
      if (responseOne.data != "Invalid Credentials") {
        let configTwo = {
          method: "PUT",
          url: dbUrl + "/api/personalusers/v1/update/id",
          headers: {
            Authorization: "Bearer " + responseOne.data,
            userId: userId,
            "Content-Type": "application/json",
          },
          data: JSON.stringify(UserProfile),
        };

        let responseTwo = await axios(configTwo, { timeout: 5000 });

        if (responseTwo.data.success) {
          setState({
            ...state,
            userProfile: { ...responseTwo.data.data },
            userProfileEdited: { ...responseTwo.data.data },
            isChanged: false,
          });
        }
      }
    } catch (err) {
      // console.log(err);
    }
  };

  useEffect(() => {
    LoadProfile(userId);
  }, []);

  return (
    <div style={{ width: "100%", paddingTop: "10vh", paddingLeft: "20px" }}>
      <div className="ui-section-wrapper">
        <section>
          <Row>
            <Col className="flex-row no-wrap">
              <h1>Profile</h1>
              <Alert variant="warning" show={state.error[0] ? true : false}>
                <div className="flex-row">
                  <p>{state.error[1]}</p>
                </div>
              </Alert>
              <Alert variant="info" show={state.success[0] ? true : false}>
                <div className="flex-row">
                  <p>{state.success[1]}</p>
                </div>
              </Alert>
            </Col>
          </Row>
        </section>
        <section className="form-section-wrapper">
          <Row>
            <Col xl={7} lg={8} md={12}>
              <Form validated={state.isFormValidated}>
                <Row>
                  <Form.Group as={Col} md={6} sm={12} controlId="update_username">
                    <Form.Label>Username (Cannot change):</Form.Label>
                    <Form.Control
                      size="sm"
                      disabled
                      type="input"
                      value={state.userProfileEdited.UserId}
                      placeholder="User Id"
                      required
                    />
                  </Form.Group>
                  <Form.Group as={Col} md={6} sm={12} controlId="update_email">
                    <Form.Label>
                      Email (
                      {state.userProfileEdited.EmailActivated ? (
                        <span style={{ color: "green" }}>activated</span>
                      ) : (
                        "not activated"
                      )}
                      ):
                    </Form.Label>
                    <Form.Control
                      size="sm"
                      type="input"
                      value={state.userProfileEdited.Email}
                      placeholder="Email"
                      onChange={(e) => {
                        setState({
                          ...state,
                          userProfileEdited: { ...state.userProfileEdited, Email: e.currentTarget.value },
                          isChanged: true,
                        });
                      }}
                      required
                    />
                  </Form.Group>
                </Row>

                <Row>
                  <Form.Group as={Col} controlId="update_title" md={4}>
                    <Form.Label>Salutation:</Form.Label>
                    <Form.Control
                      size="sm"
                      as="select"
                      placeholder="Salutation"
                      value={state.userProfileEdited.Salutation}
                      onChange={(e) =>
                        setState({
                          ...state,
                          userProfileEdited: { ...state.userProfileEdited, Salutation: e.currentTarget.value },
                          isChanged: true,
                        })
                      }
                      custom
                      className="ui-select"
                    >
                      <option value={state.userProfileEdited.Salutation}>{state.userProfileEdited.Salutation}</option>
                      {["Dr.", "Mr.", "Mrs.", "Miss"].map((title) => (
                        <option value={title} selected={state.userProfileEdited.Salutation === title}>
                          {title}
                        </option>
                      ))}
                    </Form.Control>
                  </Form.Group>
                  <Form.Group as={Col} md={4} sm={12} controlId="update_fname">
                    <Form.Label>First Name:</Form.Label>
                    <Form.Control
                      size="sm"
                      type="input"
                      value={state.userProfileEdited.FirstName}
                      onChange={(e) =>
                        setState({
                          ...state,
                          userProfileEdited: { ...state.userProfileEdited, FirstName: e.currentTarget.value },
                          isChanged: true,
                        })
                      }
                      placeholder="First Name"
                      required
                    />
                  </Form.Group>
                  <Form.Group as={Col} md={4} sm={12} controlId="update_lname">
                    <Form.Label>Last Name:</Form.Label>
                    <Form.Control
                      size="sm"
                      type="input"
                      value={state.userProfileEdited.LastName}
                      onChange={(e) =>
                        setState({
                          ...state,
                          userProfileEdited: { ...state.userProfileEdited, LastName: e.currentTarget.value },
                          isChanged: true,
                        })
                      }
                      placeholder="Last Name"
                      required
                    />
                  </Form.Group>
                </Row>

                <Row>
                  <Form.Group as={Col} controlId="update_gender">
                    <Form.Label>Gender:</Form.Label>
                    <Form.Control
                      size="sm"
                      as="select"
                      placeholder="Gender"
                      custom
                      className="ui-select"
                      value={state.userProfileEdited.Gender}
                      onChange={(e) =>
                        setState({
                          ...state,
                          userProfileEdited: { ...state.userProfileEdited, Gender: e.currentTarget.value },
                          isChanged: true,
                        })
                      }
                      required
                    >
                      <option
                        value=""
                        selected={
                          !!state.userProfileEdited.Gender && state.userProfileEdited.Gender.toLowerCase() === ""
                        }
                      ></option>
                      <option
                        value="male"
                        selected={
                          !!state.userProfileEdited.Gender && state.userProfileEdited.Gender.toLowerCase() === "male"
                        }
                      >
                        Male
                      </option>
                      <option
                        value="female"
                        selected={
                          !!state.userProfileEdited.Gender && state.userProfileEdited.Gender.toLowerCase() === "female"
                        }
                      >
                        Female
                      </option>
                      <option
                        value="other"
                        selected={
                          !!state.userProfileEdited.Gender && state.userProfileEdited.Gender.toLowerCase() === "other"
                        }
                      >
                        Other
                      </option>
                    </Form.Control>
                  </Form.Group>

                  <Form.Group as={Col} controlId="update_dateofbirth">
                    <Form.Label>Date Of Birth:</Form.Label>
                    <Datetime
                      value={new Date(state.userProfileEdited.DateOfBirth)}
                      onChange={(e) => {
                        try {
                          if (!!e) {
                            let dob = e.format("MM/DD/YYYY");
                            let dateObj = new Date(dob);
                            var month = dateObj.getUTCMonth() + 1; //months from 1-12
                            var day = dateObj.getUTCDate();
                            var year = dateObj.getUTCFullYear();

                            setState({
                              ...state,
                              userProfileEdited: {
                                ...state.userProfileEdited,
                                DateOfBirth: new Date(year, month, day).toLocaleDateString(),
                              },
                              isChanged: true,
                            });
                          }
                        } catch {}
                      }}
                      dateFormat="MM/DD/YYYY"
                      timeFormat={false}
                      ref={dateTimePickerRef}
                      closeOnSelect
                    />
                  </Form.Group>
                </Row>

                <Row>
                  <Form.Group as={Col} md={6} sm={12} controlId="update_cellphone">
                    <Form.Label>Cell Phone:</Form.Label>
                    <Form.Control
                      size="sm"
                      type="phone"
                      value={DisplayPhone(state.userProfileEdited.CellPhone)}
                      onChange={(e) => {
                        setState({
                          ...state,
                          userProfileEdited: { ...state.userProfileEdited, CellPhone: e.currentTarget.value },
                          isChanged: true,
                        });
                      }}
                      placeholder="Cell Phone"
                      required
                    />
                  </Form.Group>
                  <Form.Group as={Col} md={6} sm={12} controlId="update_workphone">
                    <Form.Label>Work Phone:</Form.Label>
                    <Form.Control
                      size="sm"
                      type="phone"
                      value={DisplayPhone(state.userProfileEdited.WorkPhone)}
                      onChange={(e) => {
                        setState({
                          ...state,
                          userProfileEdited: { ...state.userProfileEdited, WorkPhone: e.currentTarget.value },
                          isChanged: true,
                        });
                      }}
                      placeholder="Work Phone"
                      required
                    />
                  </Form.Group>
                </Row>

                <Row>
                  <Form.Group as={Col} md={6} sm={12} controlId="update_homephone">
                    <Form.Label>Home Phone:</Form.Label>
                    <Form.Control
                      size="sm"
                      type="phone"
                      value={DisplayPhone(state.userProfileEdited.HomePhone)}
                      onChange={(e) => {
                        setState({
                          ...state,
                          userProfileEdited: { ...state.userProfileEdited, HomePhone: e.currentTarget.value },
                          isChanged: true,
                        });
                      }}
                      placeholder="Home Phone"
                      required
                    />
                  </Form.Group>
                </Row>

                <Row>
                  <Form.Group controlId="consent" className="form-footer-wrapper">
                    <UiButton
                      type="submit"
                      variant="secondary"
                      disabled={state.isChanged ? false : true}
                      onClick={(evt) => {
                        HandleEditFormSubmit(evt, state.userProfileEdited, userId);
                      }}
                    >
                      Save
                    </UiButton>
                  </Form.Group>

                  <Form.Group controlId="consent" className="form-footer-wrapper">
                    <UiButton
                      onClick={(e) =>
                        setState({ ...state, userProfileEdited: { ...state.userProfile }, isChanged: false })
                      }
                      variant="link"
                      className="gray-color no-padding"
                      visibility={state.isChanged ? false : true}
                    >
                      Cancel
                    </UiButton>
                  </Form.Group>
                </Row>
              </Form>
            </Col>
          </Row>
        </section>
      </div>
    </div>
  );
};

export default UserProfile;
