import React, { Fragment } from "react";

import { useUserDashboardContext } from "../../lib/context.js";

import "../../resources/styles/dashboard.scss";
import "bootstrap/dist/css/bootstrap.min.css";

const UserSideNavLink = (props) => {
  const { dashboardState, setDashboardState } = useUserDashboardContext();

  const color = props.active ? "green" : "blue";
  return (
    <div>
      <button
        // href={props.href}
        onClick={(_) => {
          setDashboardState({ ...dashboardState, ActiveSideTab: props.icon });
        }}
        className={`side-nav-button ${color}-color${props.active ? " active-sn" : ""}`}
        // disabled={props.disabled ? true : false }
      >
        <img src={`../../photos/icons/dashboard/SVG/${props.icon}-${color}.svg`} width="16"></img>
        {/* <img className={`caret${props.active ? ' active-caret' : ''}`} src={`../../photos/icons/dashboard/SVG/caret-${color}.svg`} width="14" /> */}
        {props.collapsed ? "" : <span>{props.children}</span>}
      </button>
    </div>
  );
};

export default UserSideNavLink;
