import React, { useState, Fragment, useRef, useEffect } from "react";
import { Col, Row, Button, Popover, OverlayTrigger, Spinner } from "react-bootstrap";
import axios from "axios";
import { jsPDF } from "jspdf";
import { cloneDeep } from "lodash";
import { Line } from "react-chartjs-2";
import bodiometerLogoImg from "../../../resources/logos/bodiometer-logo.png";
import { RetrieveToken } from "../../../lib/token";

import "bootstrap/dist/css/bootstrap.min.css";
import "./style/style.scss";

const Questionnaire = ({ CaseId, PatientId, ActiveOrganization, dbUrl, onBack }) => {
	const [fqDb, setFqDb] = useState({});
	const [fqDbPrev, setFqDbPrev] = useState({});
	const [fqChartData, setFqChartData] = useState({ ...fqDefaultChartData });
	const [fqLineChartOptions, setFqLineChartOptions] = useState({});
	const [fqPred, setFqPred] = useState([]);
	const [fqIds, setFqIds] = useState([]);
	const [fqId, setFqId] = useState("");
	const [newFq, setNewFq] = useState({});
	const fqChartRef = useRef();
	const [isCreateFQConfirmationVisible, setIsCreateFQConfirmationVisible] = useState(false);
	const [isUpdateFQConfirmationVisible, setIsUpdateFQConfirmationVisible] = useState(false);
	const [isRemoveConfirmationVisible, setIsRemoveConfirmationVisible] = useState(false);
	const [personalInfo, setPersonalInfo] = useState({
		email: "",
		name: "",
		age: "",
		gender: "",
	});
	const [patientInfo, setPatientInfo] = useState({});
	const [isLoading, setIsLoading] = useState(false);
	const [tabIndex, setTabIndex] = useState(0);
	const [isCreatedMode, setIsCreatedMode] = useState(false);
	const [isEditMode, setIsEditMode] = useState(false);
	const [isPdfSDownloadSelected, setIsPdfSDownloadSelected] = useState(false);
	const [isPdfMDownloadSelected, setIsPdfMDownloadSelected] = useState(false);

	const LoadData = async (CaseId, PatientId) => {
		try {
			
			setIsLoading(true);

			RetrieveToken()
				.then(async (token) => {
					var configTwo = {
						method: "GET",
						url: dbUrl + "/api/patientfunctionalquestions/v2/caseid",
						headers: {
							Authorization: "Bearer " + token,
							caseId: CaseId,
						},
					};
					var configThree = {
						method: "GET",
						url: dbUrl + "/api/patients/v4/patientid",
						headers: {
							Authorization: "Bearer " + token,
							PatientId: PatientId,
						},
					};

					var responseTwo = await axios(configTwo, { timeout: 5000 });
					var responseThree = await axios(configThree, { timeout: 5000 });

					if (responseTwo.data.success) {
						var fqList = responseTwo.data.data;
						for (let i = 0; i < fqList.length; i++) {
							let tempObj = fqList[i];
							let keys = Object.keys(tempObj);
							let n = keys.length;
							let newObj = {};
							while (n--) {
								newObj[keys[n].charAt(0).toLowerCase() + keys[n].slice(1)] = tempObj[keys[n]];
							}
							fqList[i] = newObj;
						}
						

						// var newFqDb = { ...responseTwo.data.data };

						var newFqDb = { ...fqList };

						// var newFqIds = Object.keys(newFqDb).sort((i, j) => {
						// 	return new Date(AddHoursToDate(newFqDb[i].createdAt, newFqDb[i].createdAtOffset)).getTime() < new Date(AddHoursToDate(newFqDb[j].createdAt, newFqDb[j].createdAtOffset)).getTime()
						// 		? 1
						// 		: -1;
						// });

						var newFqIds = Object.keys(newFqDb);

						if (newFqIds.length >= 2) {
							var arrayOne = newFqDb[newFqIds[1]].answers.concat([newFqDb[newFqIds[1]].overallPain]);
							var arrayTwo = newFqDb[newFqIds[0]].answers.concat([newFqDb[newFqIds[0]].overallPain]);
							var configFour = {
								method: "POST",
								url: dbUrl + "/api/patientfunctionalquestions/predict/v1/next",
								headers: {
									Authorization: "Bearer " + token,
									caseId: CaseId,
								},
								data: { value: arrayOne.concat(arrayTwo) },
							};
							var responseFour = await axios(configFour, { timeout: 5000 });
							var newFqPred = responseFour.data.data.value[0];
							var newChartData = ComputeFQChartData(newFqDb, newFqIds, newFqPred, fqChartData);
							setFqPred(newFqPred);
							setFqChartData(newChartData);
						} else {
							setFqPred([]);
							setFqChartData(fqChartData);
						}

						setFqDb({ ...newFqDb });
						setFqDbPrev(cloneDeep(newFqDb));
						setFqIds([...newFqIds]);
						setFqId(newFqIds.length > 0 ? newFqIds[0] : "");

						setIsCreateFQConfirmationVisible(false);
						setIsUpdateFQConfirmationVisible(false);
						setIsCreatedMode(false);
						setIsEditMode(false);
					} else {
						setNewFq({ ...DefaultFQ });
						setFqDb({});
						setFqDbPrev(cloneDeep({}));
						setFqIds([]);
						setFqId("");
						setFqPred([]);
						setFqChartData(fqChartData);
						setIsCreateFQConfirmationVisible(false);
						setIsUpdateFQConfirmationVisible(false);
						setIsCreatedMode(false);
						setIsEditMode(false);
					}
					if (responseThree.data.success) {
						setPersonalInfo({
							email: responseThree.data.data.Personal.Email,
							name: responseThree.data.data.Personal.FirstName + " " + responseThree.data.data.Personal.LastName,
							age: CalculateAge(new Date(responseThree.data.data.Personal.DateOfBirth).toLocaleDateString()),
							gender: responseThree.data.data.Personal.Gender,
						});
						setPatientInfo({ ...responseThree.data.data });
					}
					setIsLoading(false);
				})
				.catch((err) => {
					setIsLoading(false);
					console.log(err);
				});
		} catch (err) {
			setIsLoading(false);
		}
	};

	const CreateFQ = async (fq) => {
		try {
			setIsLoading(true);

			RetrieveToken()
				.then(async (token) => {
					var createdAt = new Date().toISOString();

					var postedData = {
						caseId: fq.caseId,
						createdAt,
						createdAtOffset: -(new Date().getTimezoneOffset() / 60),
						lastSubmitted: createdAt,
						lastSubmittedOffset: -(new Date().getTimezoneOffset() / 60),
						answers: fq.answers,
						overallPain: fq.overallPain,
					};

					var configTwo = {
						method: "POST",
						url: dbUrl + "/api/patientFunctionalquestions/v1/create",
						headers: {
							Authorization: "Bearer " + token,
							caseId: CaseId,
						},
						data: postedData,
					};

					var responseTwo = await axios(configTwo, { timeout: 5000 });
					if (responseTwo.data.success) {
						LoadData(CaseId, PatientId);
					} else {
						setIsLoading(false);
					}
				})
				.catch((err) => {
					setIsLoading(false);
					console.log(err);
				});
		} catch (err) {
			setIsLoading(false);
		}
	};

	const RemoveFQ = async (fqId) => {
		try {
			setIsLoading(true);

			RetrieveToken()
				.then(async (token) => {
					var configTwo = {
						method: "DELETE",
						url: dbUrl + "/api/patientfunctionalquestions/v1/remove/id",
						headers: {
							Authorization: "Bearer " + token,
							id: fqId,
						},
					};

					var responseTwo = await axios(configTwo, { timeout: 5000 });
					if (responseTwo.data.success) {
						LoadData(CaseId, PatientId);
					} else {
						setIsLoading(false);
					}
				})
				.catch((err) => {
					setIsLoading(false);
					console.log(err);
				});
		} catch (err) {
			setIsLoading(false);
		}
	};

	const UpdateFQ = async (fq) => {
		try {
			setIsLoading(true);

			RetrieveToken()
				.then(async (token) => {
					var configTwo = {
						method: "PUT",
						url: dbUrl + "/api/patientfunctionalQuestions/v1/update",
						headers: {
							Authorization: "Bearer " + token,
							caseId: CaseId,
						},
						data: {
							...fq,
							lastSubmitted: new Date().toISOString(),
							lastSubmittedOffset: -(new Date().getTimezoneOffset() / 60),
						},
					};

					var responseTwo = await axios(configTwo, { timeout: 5000 });
					if (responseTwo.data.success) {
						var tempFqDb = { ...fqDb };
						tempFqDb[fqId] = fq;
						setFqDb(tempFqDb);
						setFqDbPrev(tempFqDb);
						setIsLoading(false);
					} else {
						setIsLoading(false);
					}
				})
				.catch((err) => {
					setIsLoading(false);
					console.log(err);
				});
		} catch (err) {
			setIsLoading(false);
		}
	};

	const RenderFQDateSelectionOverlay = () => {
		return (
			<Popover className="posture-date-overlay-wrapper" style={{ backgroundColor: "var(--primary-gray)" }} id={`popover${Math.floor(Math.random()).toString()}`}>
				<Popover.Content>
					<div>
						<ul>
							{fqIds.map((id, key) => {
								return (
									<li>
										<Button className={fqDb[fqId].id === fqDb[id].id ? "active-date" : "white-link"} onClick={() => setFqId(id)}>
											<span style={fqDb[id].type == "home" ? { color: "#a5ced5" } : {}}>{AddHoursToDate(fqDb[id].createdAt, fqDb[id].createdAtOffset)}</span>
											{
												fqDb[id].type == "intake" && <span style={{marginLeft: "5px", marginRight: "5px", paddingLeft: "5px", paddingRight: "5px", fontWeight: "bold", border: "2px solid", verticalAlign: "top", fontSize: "6px"}}>INTAKE</span>
											}
											{fqDb[fqId].id === fqDb[id].id ? <img src="../../photos/icons/dashboard/SVG/check-green.svg" width="15" /> : null}
										</Button>
									</li>
								);
							})}
						</ul>
					</div>
				</Popover.Content>
			</Popover>
		);
	};

	const CreateFQConfirmationOverlay = () => {
		return (
			<Popover className="action-toggle-wrapper roles-toggle-wrapper" id={`popover${Math.floor(Math.random()).toString()}`}>
				<Popover.Content>
					<Row>
						<Col>
							<h3 className="green-color">Do you want to create a new assessment?</h3>
						</Col>
					</Row>
					<Row>
						<Col>
							<Button
								style={{ background: "var(--logo-color-2)" }}
								onClick={(e) => {
									CreateFQ(newFq);
									setIsCreateFQConfirmationVisible(false);
								}}
							>
								Yes
							</Button>
							<Button style={{ background: "var(--logo-color-1)" }} onClick={(e) => setIsCreateFQConfirmationVisible(false)}>
								Cancel
							</Button>
						</Col>
					</Row>
				</Popover.Content>
			</Popover>
		);
	};

	const UpdateFQConfirmationOverlay = () => {
		return (
			<Popover className="action-toggle-wrapper roles-toggle-wrapper" id={`popover${Math.floor(Math.random()).toString()}`}>
				<Popover.Content>
					<Row>
						<Col>
							<h3 className="green-color">Are you sure you want to submit the answers?</h3>
						</Col>
					</Row>
					<Row>
						<Col>
							<Button
								style={{ background: "var(--logo-color-2)" }}
								onClick={(e) => {
									UpdateFQ(newFq);
									setIsUpdateFQConfirmationVisible(false);
									setIsEditMode(false);
								}}
							>
								Yes
							</Button>
							<Button style={{ background: "var(--logo-color-1)" }} onClick={(e) => setIsUpdateFQConfirmationVisible(false)}>
								Cancel
							</Button>
						</Col>
					</Row>
				</Popover.Content>
			</Popover>
		);
	};

	const RemoveFQConfirmationOverlay = () => {
		return (
			<Popover className="action-toggle-wrapper roles-toggle-wrapper" id={`popover${Math.floor(Math.random()).toString()}`}>
				<Popover.Content>
					<Row>
						<Col>
							<h3 className="green-color">Do you want to remove the selected assessment?</h3>
						</Col>
					</Row>
					<Row>
						<Col>
							<Button
								style={{ background: "var(--logo-color-2)" }}
								onClick={(e) => {
									setIsRemoveConfirmationVisible(false);
									RemoveFQ(fqDb[fqId].id);
								}}
							>
								Yes
							</Button>
							<Button style={{ background: "var(--logo-color-1)" }} onClick={(e) => setIsRemoveConfirmationVisible(false)}>
								Cancel
							</Button>
						</Col>
					</Row>
				</Popover.Content>
			</Popover>
		);
	};

	useEffect(() => {
		LoadData(CaseId, PatientId);
	}, [CaseId, PatientId]);

	useEffect(() => {
		if (isPdfSDownloadSelected) {
			setIsLoading(true);
			GeneratePdfS();
			setIsPdfSDownloadSelected(false);
			setIsLoading(false);
		} else if (isPdfMDownloadSelected) {
			setIsLoading(true);
			GeneratePdfM();
			setIsPdfMDownloadSelected(false);
			setIsLoading(false);
		}
	}, [isPdfSDownloadSelected, isPdfMDownloadSelected]);

	const GeneratePdfS = () => {
		var pdf = new jsPDF(DefaultPdfReportConfig);

		RetrieveToken()
			.then((token) => {
				axios({
					method: "get",
					url: dbUrl + "/api/storage/v1/image/ba/logo/id/base64",
					headers: {
						Authorization: "Bearer " + token,
						"Content-Type": "application/json",
						businessId: ActiveOrganization.id,
					},
				})
					.then((logoRes) => {
						if (logoRes.status === 200) {
							var imageDb = {};
							var ActiveOrganizationLogo = logoRes.data;
							var fqChart = fqChartRef.current.toBase64Image();

							if (!!bodiometerLogoImg) {
								imageDb["bodiometer.logo"] = new Image();
								imageDb["bodiometer.logo"].src = bodiometerLogoImg;
							} else {
								imageDb["bodiometer.logo"] = null;
							}

							if (!!ActiveOrganizationLogo) {
								imageDb["clinic.logo"] = new Image();
								imageDb["clinic.logo"].src = ActiveOrganizationLogo;
							} else {
								imageDb["clinic.logo"] = null;
							}

							if (!!fqChart) {
								imageDb["chart.fqChart"] = new Image();
								imageDb["chart.fqChart"].src = fqChart;
							}

							var imageKeys = Object.keys(imageDb);
							var imageCount = 0;
							var nImgLoaded = 0;
							for (var i = 0; i < imageKeys.length; i++) {
								if (imageDb[imageKeys[i]] != null) {
									imageCount++;
								}
							}

							for (var i = 0; i < imageKeys.length; i++) {
								if (imageDb[imageKeys[i]] != null) {
									imageDb[imageKeys[i]].onload = () => {
										nImgLoaded++;
										if (nImgLoaded === imageCount) {
											// Page 1 : Basic Layout
											GenerateBasicLayout(pdf, imageDb["bodiometer.logo"], imageDb["clinic.logo"], 1, 3);

											// Page 1 : Patient Details & Functional Chart
											GenerateFQPdfPageOne(pdf, imageDb["chart.fqChart"]);

											// Page Break
											pdf.addPage(pdf);

											// Page 2 : Basic Layout
											GenerateBasicLayout(pdf, imageDb["bodiometer.logo"], imageDb["clinic.logo"], 2, 3);

											// Page 2 : FQ Page 1
											GenerateSingleFQPage(pdf, 1, QuesOrder.length - 8, fqId);

											// Page Break
											pdf.addPage(pdf);

											// Page 3 : Basic Layout
											GenerateBasicLayout(pdf, imageDb["bodiometer.logo"], imageDb["clinic.logo"], 3, 3);

											// Page 3 : FQ Page 2
											GenerateSingleFQPage(pdf, QuesOrder.length - 7, QuesOrder.length, fqId);

											pdf.save(`fq_report_single.pdf`);
										}
									};
								}
							}

							// setState((prev) => ({ ...prev, ActiveOrganizationLogo: logoRes.data, Preloader: false }));
						}
					})
					.catch((err) => console.log(err));
			})
			.catch((err) => console.log(err));
	};

	const GeneratePdfM = () => {
		var pdf = new jsPDF(DefaultPdfReportConfig);

		RetrieveToken()
			.then((token) => {
				axios({
					method: "get",
					url: dbUrl + "/api/storage/v1/image/ba/logo/id/base64",
					headers: {
						Authorization: "Bearer " + token,
						"Content-Type": "application/json",
						businessId: ActiveOrganization.id,
					},
				})
					.then((logoRes) => {
						if (logoRes.status === 200) {
							var imageDb = {};
							var ActiveOrganizationLogo = logoRes.data;
							var fqChart = fqChartRef.current.toBase64Image();

							if (!!bodiometerLogoImg) {
								imageDb["bodiometer.logo"] = new Image();
								imageDb["bodiometer.logo"].src = bodiometerLogoImg;
							} else {
								imageDb["bodiometer.logo"] = null;
							}

							if (!!ActiveOrganizationLogo) {
								imageDb["clinic.logo"] = new Image();
								imageDb["clinic.logo"].src = ActiveOrganizationLogo;
							} else {
								imageDb["clinic.logo"] = null;
							}

							if (!!fqChart) {
								imageDb["chart.fqChart"] = new Image();
								imageDb["chart.fqChart"].src = fqChart;
							}

							var imageKeys = Object.keys(imageDb);
							var imageCount = 0;
							var nImgLoaded = 0;
							for (var i = 0; i < imageKeys.length; i++) {
								if (imageDb[imageKeys[i]] != null) {
									imageCount++;
								}
							}

							for (var i = 0; i < imageKeys.length; i++) {
								if (imageDb[imageKeys[i]] != null) {
									imageDb[imageKeys[i]].onload = () => {
										nImgLoaded++;
										if (nImgLoaded === imageCount) {
											// Page 1 : Basic Layout
											GenerateBasicLayout(pdf, imageDb["bodiometer.logo"], imageDb["clinic.logo"], 1, 1 + fqIds.length * 2);

											// Page 1 : Patient Details & Functional Chart
											GenerateFQPdfPageOne(pdf, imageDb["chart.fqChart"]);

											for (var j = 0; j < fqIds.length; j++) {
												// Page Break
												pdf.addPage(pdf);

												// Page 2 : Basic Layout
												GenerateBasicLayout(pdf, imageDb["bodiometer.logo"], imageDb["clinic.logo"], 1 + j * 2 + 1, 1 + fqIds.length * 2);

												// Page 2 : FQ Page 1
												GenerateSingleFQPage(pdf, 1, QuesOrder.length - 8, fqIds[j]);

												// Page Break
												pdf.addPage(pdf);

												// Page 3 : Basic Layout
												GenerateBasicLayout(pdf, imageDb["bodiometer.logo"], imageDb["clinic.logo"], 1 + j * 2 + 2, 1 + fqIds.length * 2);

												// Page 3 : FQ Page 2
												GenerateSingleFQPage(pdf, QuesOrder.length - 7, QuesOrder.length, fqIds[j]);
											}

											pdf.save(`fq_report_all.pdf`);
										}
									};
								}
							}

							// setState((prev) => ({ ...prev, ActiveOrganizationLogo: logoRes.data, Preloader: false }));
						}
					})
					.catch((err) => console.log(err));
			})
			.catch((err) => console.log(err));
	};

	const GenerateSingleFQPage = (pdf, s, e, fqId) => {
		let width = pdf.internal.pageSize.getWidth();
		let verticalStart = 75;
		let horizontalStart = 30;
		let verticalGap = 10;
		let horizontalMidPoint = width / 2;
		let offsetLeft = 20;

		if (s < 10) {
			pdf.setFontSize(12);
			pdf.setFont(undefined, "bold");
			verticalStart += verticalGap;
			pdf.text(`Date: ${new Date(AddHoursToDate(fqDb[fqId].createdAt, fqDb[fqId].createdAtOffset)).toLocaleString(dateLocale, defaultDateFormat)}`, offsetLeft + 15, verticalStart - 30);
			verticalStart += 2 * verticalGap;
		} else {
			verticalStart += 2 * verticalGap;
		}

		pdf.setDrawColor(33, 136, 56);
		pdf.setFillColor(33, 136, 56);

		pdf.roundedRect(horizontalStart, verticalStart - 5, 40, 30, 10, 10, "F");
		pdf.roundedRect(horizontalStart + 80 * 1, verticalStart - 5, 40, 30, 10, 10, "F");
		pdf.roundedRect(horizontalStart + 80 * 2, verticalStart - 5, 40, 30, 10, 10, "F");
		pdf.roundedRect(horizontalStart + 80 * 3, verticalStart - 5, 40, 30, 10, 10, "F");
		pdf.roundedRect(horizontalStart + 80 * 4, verticalStart - 5, 40, 30, 10, 10, "F");

		pdf.setTextColor("white");
		pdf.setFontSize(16);
		pdf.setFont(undefined, "bold");
		pdf.text("0", horizontalStart + 20, verticalStart - 5 + 20, { align: "center" });
		pdf.text("1", horizontalStart + 20 + 80 * 1, verticalStart - 5 + 20, { align: "center" });
		pdf.text("2", horizontalStart + 20 + 80 * 2, verticalStart - 5 + 20, { align: "center" });
		pdf.text("3", horizontalStart + 20 + 80 * 3, verticalStart - 5 + 20, { align: "center" });
		pdf.text("4", horizontalStart + 20 + 80 * 4, verticalStart - 5 + 20, { align: "center" });

		pdf.setTextColor("#2994d1");
		pdf.setFontSize(12);
		pdf.setFont(undefined, "bold");
		pdf.text("Unable", horizontalStart + 20, verticalStart - 5 + 20 - 25, { align: "center" });
		pdf.text("Very", horizontalStart + 20 + 80 * 1, verticalStart - 5 + 20 - 35, { align: "center" });
		pdf.text("Difficult", horizontalStart + 20 + 80 * 1, verticalStart - 5 + 20 - 25, { align: "center" });
		pdf.text("Moderately", horizontalStart + 20 + 80 * 2, verticalStart - 5 + 20 - 35, { align: "center" });
		pdf.text("Difficult", horizontalStart + 20 + 80 * 2, verticalStart - 5 + 20 - 25, { align: "center" });
		pdf.text("Minimally", horizontalStart + 20 + 80 * 3, verticalStart - 5 + 20 - 35, { align: "center" });
		pdf.text("Difficult", horizontalStart + 20 + 80 * 3, verticalStart - 5 + 20 - 25, { align: "center" });
		pdf.text("Normal", horizontalStart + 20 + 80 * 4, verticalStart - 5 + 20 - 25, { align: "center" });

		verticalStart = verticalStart - 5 + 50;

		pdf.setDrawColor("#6c7d85");
		pdf.setFillColor("#6c7d85");
		pdf.setLineWidth(2);
		pdf.line(horizontalMidPoint - 100, verticalStart, horizontalMidPoint + 100, verticalStart, "F");

		verticalStart = verticalStart + 20;

		for (let i = s - 1; i < e; i++) {
			let index = QuesOrder[i];
			if (index === 1) {
				pdf.setTextColor("#2994d1");
				pdf.setFontSize(12);
				pdf.setFont(undefined, "bold");
				pdf.text("SLEEPING", horizontalStart + 5, verticalStart, { align: "left" });
				verticalStart += 15;
			} else if (index === 2) {
				pdf.setTextColor("#2994d1");
				pdf.setFontSize(12);
				pdf.setFont(undefined, "bold");
				pdf.text("SITTING", horizontalStart + 5, verticalStart, { align: "left" });
				verticalStart += 15;
			} else if (index === 4) {
				pdf.setTextColor("#2994d1");
				pdf.setFontSize(12);
				pdf.setFont(undefined, "bold");
				pdf.text("MOVING - STANDING/WALKING/RUNNING", horizontalStart + 5, verticalStart, { align: "left" });
				verticalStart += 15;
			} else if (index === 10) {
				pdf.setTextColor("#2994d1");
				pdf.setFontSize(12);
				pdf.setFont(undefined, "bold");
				pdf.text("DAILY ACTIVITIES", horizontalStart + 5, verticalStart, { align: "left" });
				verticalStart += 15;
			} else if (index === 16) {
				pdf.setTextColor("#2994d1");
				pdf.setFontSize(12);
				pdf.setFont(undefined, "bold");
				pdf.text("JOB ACTIVITIES", horizontalStart + 5, verticalStart, { align: "left" });
				verticalStart += 15;
			}

			pdf.setTextColor("#000000");
			pdf.setFontSize(10);
			pdf.setFont(undefined, "normal");
			pdf.text(`${i + 1}. ${QuesList[index - 1]}`, horizontalStart + 5, verticalStart, { align: "left" });
			verticalStart += 15;

			let horizontalStartingPointRect = horizontalMidPoint - 30 * 3 + 15;
			let horizontalStartingPointText = horizontalMidPoint - 30 * 3 + 15 + 8;

			pdf.setFontSize(8);
			pdf.setFont(undefined, "bold");

			for (let j = 0; j < 6; j++) {
				if (fqDb[fqId].answers[index - 1] === j) {
					pdf.setDrawColor(41, 148, 209);
					pdf.setFillColor(41, 148, 209);
					pdf.setTextColor("#ffffff");
				} else {
					pdf.setDrawColor(217, 240, 252);
					pdf.setFillColor(217, 240, 252);
					pdf.setTextColor("#6c7d85");
				}
				pdf.roundedRect(horizontalStartingPointRect + j * 30, verticalStart - 5, 15, 15, 15, 15, "F");
				pdf.text(j === 5 ? "NA" : `${j}`, horizontalStartingPointText + j * 30, verticalStart - 5 + 10, {
					align: "center",
				});
			}

			verticalStart += 25;
		}

		if (e >= 10) {
			pdf.setTextColor("#2994d1");
			pdf.setFontSize(12);
			pdf.setFont(undefined, "bold");
			pdf.text("PAIN SCALE", horizontalStart + 5, verticalStart, { align: "left" });
			verticalStart += 15;
			pdf.setTextColor("#000000");
			pdf.setFontSize(10);
			pdf.setFont(undefined, "normal");
			pdf.text("The selected number describes the pain you have experienced over the last week with 0 being no", horizontalStart + 5, verticalStart, { align: "left" });
			verticalStart += 10;
			pdf.text("pain (when not taking pain medication).", horizontalStart + 5, verticalStart, { align: "left" });
			verticalStart += 20;

			let horizontalStartingPointRect = horizontalMidPoint - 30 * 5 + 15;
			let horizontalStartingPointText = horizontalMidPoint - 30 * 5 + 15 + 8;

			pdf.setFontSize(8);
			pdf.setFont(undefined, "bold");

			for (let i = 0; i < 11; i++) {
				if (fqDb[fqId].overallPain === i) {
					pdf.setDrawColor(41, 148, 209);
					pdf.setFillColor(41, 148, 209);
					pdf.setTextColor("#ffffff");
				} else {
					pdf.setDrawColor(217, 240, 252);
					pdf.setFillColor(217, 240, 252);
					pdf.setTextColor("#6c7d85");
				}
				pdf.roundedRect(horizontalStartingPointRect + i * 25, verticalStart - 5, 15, 15, 15, 15, "F");
				pdf.text(`${i}`, horizontalStartingPointText + i * 25, verticalStart - 5 + 10, { align: "center" });
			}
		}
	};

	const GenerateBasicLayout = (pdf, bodiometerLogo, clinicLogo, pageNumber, totalPages) => {
		let width = pdf.internal.pageSize.getWidth();
		let height = pdf.internal.pageSize.getHeight();
		let offsetLeft = 20;

		if (bodiometerLogo != null) {
			pdf.addImage(bodiometerLogo, width - 100, 20, (bodiometerLogo.width / bodiometerLogo.height) * 20, 20);
		}
		if (clinicLogo != null) {
			pdf.addImage(clinicLogo, 10, 10, (clinicLogo.width / clinicLogo.height) * 30, 30);
		}

		pdf.setFontSize(8);
		pdf.setFont(undefined, "normal");
		pdf.setDrawColor(0, 0, 0);
		pdf.line(20, 45, width - 20, 45);
		pdf.line(20, height - 45, width - 20, height - 45);
		pdf.text(`Address: ${ActiveOrganization?.address}, ${ActiveOrganization?.city}, ${ActiveOrganization?.country}`, 20, height - 35, {
			align: "left",
		});
		pdf.text(`Email: ${ActiveOrganization?.email}, Phone: ${ActiveOrganization?.phone}, Fax: ${ActiveOrganization?.fax}`, 20, height - 25, {
			align: "left",
		});
		pdf.text(`Page ${pageNumber} of ${totalPages}`, width - offsetLeft - pdf.getTextWidth(`Page ${pageNumber} of ${totalPages}`), height - 30, { align: "left" });
	};

	const GenerateFQPdfPageOne = (pdf, fqChart) => {
		let width = pdf.internal.pageSize.getWidth();
		let verticalStart = 75;
		let verticalGap = 10;
		let offsetLeft = 20;

		pdf.setFontSize(16);
		pdf.setFont(undefined, "bold");
		pdf.text("Patient Information", offsetLeft, verticalStart);
		verticalStart += 2 * verticalGap;
		pdf.setFontSize(12);
		pdf.setFont(undefined, "bold");
		pdf.text("Personal", offsetLeft + 10, verticalStart);
		pdf.setFontSize(10);
		pdf.setFont(undefined, "normal");
		verticalStart += verticalGap;
		pdf.text(
			!IsEmpty(patientInfo.Personal.FirstName + patientInfo.Personal.LastName) ? `Name: ${patientInfo.Personal.FirstName + " " + patientInfo.Personal.LastName}` : "Name: -",
			offsetLeft + 15,
			verticalStart
		);
		verticalStart += verticalGap;
		pdf.text(!IsEmpty(patientInfo.Personal.Gender) ? `Sex: ${patientInfo.Personal.Gender}` : "Sex: -", offsetLeft + 15, verticalStart);
		verticalStart += verticalGap;
		pdf.text(!IsEmpty(patientInfo.Personal.Email) ? `Email: ${patientInfo.Personal.Email}` : "Email: -", offsetLeft + 15, verticalStart);
		verticalStart += verticalGap;
		pdf.text(
			!!patientInfo.Personal.DateOfBirth ? `Date of Birth (DD/MM/YYYY): ${new Date(patientInfo.Personal.DateOfBirth).toLocaleDateString()}` : "Date of Birth (DD/MM/YYYY): -",
			offsetLeft + 15,
			verticalStart
		);
		verticalStart += verticalGap;
		pdf.text(!IsEmpty(patientInfo.Personal.CellPhone) ? `Cellphone: ${patientInfo.Personal.CellPhone}` : "Cellphone: -", offsetLeft + 15, verticalStart);
		verticalStart += verticalGap;
		pdf.text(!IsEmpty(patientInfo.Personal.Address.country) ? `Country: ${patientInfo.Personal.Address.Country}` : "Country: -", offsetLeft + 15, verticalStart);
		verticalStart += verticalGap;
		pdf.text(!IsEmpty(patientInfo.Personal.Address.province) ? `Province: ${patientInfo.Personal.Address.Province}` : "Province: -", offsetLeft + 15, verticalStart);
		verticalStart += verticalGap;
		pdf.text(!IsEmpty(patientInfo.Personal.Address.city) ? `City: ${patientInfo.Personal.Address.City}` : "City: -", offsetLeft + 15, verticalStart);
		verticalStart += verticalGap;
		pdf.text(
			!IsEmpty(patientInfo.Personal.Address.StreetAddress1 + patientInfo.Personal.Address.StreetAddress2)
				? `Street Address: ${patientInfo.Personal.Address.StreetAddress1 + " " + patientInfo.Personal.Address.StreetAddress2}`
				: "Street Address: -",
			offsetLeft + 15,
			verticalStart
		);
		verticalStart += verticalGap;
		pdf.text(!IsEmpty(patientInfo.Personal.Address.PostalCode) ? `Postal Code: ${patientInfo.Personal.Address.PostalCode}` : "Postal Code: -", offsetLeft + 15, verticalStart);

		verticalStart += 2 * verticalGap;
		pdf.setFontSize(12);
		pdf.setFont(undefined, "bold");
		pdf.text("Emergency Contact", offsetLeft + 10, verticalStart);
		pdf.setFontSize(10);
		pdf.setFont(undefined, "normal");
		verticalStart += verticalGap;
		pdf.text(
			!IsEmpty(patientInfo.Medical.EmergencyFirstName + patientInfo.Medical.EmergencyFirstName)
				? `Name: ${patientInfo.Medical.EmergencyFirstName + " " + patientInfo.Medical.EmergencyFirstName}`
				: "Name: -",
			offsetLeft + 15,
			verticalStart
		);
		verticalStart += verticalGap;
		pdf.text(!IsEmpty(patientInfo.Medical.EmergencyPhone) ? `Phone: ${patientInfo.Medical.EmergencyPhone}` : "Phone: -", offsetLeft + 15, verticalStart);

		verticalStart += 2 * verticalGap;
		pdf.setFontSize(12);
		pdf.setFont(undefined, "bold");
		pdf.text("Report", offsetLeft + 10, verticalStart);
		pdf.setFontSize(10);
		pdf.setFont(undefined, "normal");
		verticalStart += verticalGap;
		pdf.text(`Time: ${new Date().toLocaleString(dateLocale, defaultDateFormat)}`, offsetLeft + 15, verticalStart);

		if (fqChart != null) {
			verticalStart += 3 * verticalGap;
			pdf.addImage(fqChart, offsetLeft + 20, verticalStart, width - 2 * (offsetLeft + 20), (width - 2 * (offsetLeft + 20)) * (fqChart.height / fqChart.width), undefined, "FAST");
			verticalStart += (width - 2 * (offsetLeft + 20)) * (fqChart.height / fqChart.width) + verticalGap;
			pdf.setFontSize(12);
			pdf.setFont(undefined, "bold");
			pdf.text("FUNCTIONAL SCORE VS OVERALL PAIN", width / 2, verticalStart, { align: "center" });
		}
	};

	const defaultFqLineChartOptions = {
		scales: {
			y: {
				max: 10,
				min: 0,
				beginAtZero: true,
				suggestedMin: 0,
				grid: {
					display: false,
				},
			},
			x: {
				offset: true,
				bounds: {
					ticks: {
						labelOffset: 2,
					},
				},
			},
		},
		plugins: {
			legend: {
				display: true,
				labels: {
					usePointStyle: true,
					pointStyle: "rectRounded",
					borderRadius: 2,
					generateLabels: (chart) => {
						let labels = [];

						if (chart.data.datasets.length > 2) {
							for (let i = 2; i < chart.data.datasets.length; i++) {
								labels.push({
									text: `${chart.data.datasets[i].label}`,
									fillStyle: `${chart.data.datasets[i].backgroundColor}`,
									borderRadius: `${3}`,
									hidden: chart.data.datasets[i].hidden,
									datasetIndex: i,
									pointStyle: "rectRounded",
								});
							}
						} else {
							for (let i = 0; i < chart.data.datasets.length; i++) {
								labels.push({
									text: `${chart.data.datasets[i].label}`,
									fillStyle: `${chart.data.datasets[i].backgroundColor}`,
									borderRadius: `${3}`,
									hidden: chart.data.datasets[i].hidden,
									datasetIndex: i,
									pointStyle: "rectRounded",
								});
							}
						}
						return labels;
					},
				},
				onClick: (event, legendItem, legend) => {
					let tempFQData = { ...fqChartData };
					// let tempFQLineChartOptions = { ...fqLineChartOptions };
					tempFQData.datasets[legendItem.datasetIndex].hidden = !tempFQData.datasets[legendItem.datasetIndex].hidden;
					setFqChartData(tempFQData);
				},
			},
			annotation: {
				annotations: [
					{
						drawTime: "beforeDraw",
						id: "box1",
						type: "box",
						scaleID: "y-axis-0",
						yMin: 0,
						yMax: 2,
						backgroundColor: "rgba(69,178,73, 0.2)",
						borderWidth: 0,
						borderColor: "white",
					},
				],
			},
		},
		tooltips: {},
	};

	return (
		<Fragment>
			{isLoading && (
				<div className="preloader-wrapper">
					<Spinner animation="grow" variant="secondary" />
				</div>
			)}

			<div style={{ marginBottom: "5vh" }}>
				<span style={{ cursor: "pointer", fontSize: "1.2rem", fontWeight: "500" }} onClick={onBack}>
					{"<"} back{" "}
				</span>
			</div>

			{!isLoading && (
				<Fragment>
					{/* these chart would be hidden */}
					<div style={{ position: "absolute", left: "-9999px", top: "-9999px", width: "100%", height: "100%" }}>
						<Row>
							<Col>
								<Line ref={fqChartRef} data={fqChartData} options={defaultFqLineChartOptions} />
							</Col>
						</Row>
					</div>

					<section style={Styles.Info}>
						<h2>{personalInfo.name}</h2>
						<p>
							<a href={`mailto:${personalInfo.email}`}>{personalInfo.email}</a>, {personalInfo.gender} {personalInfo.age} years
						</p>
					</section>

					<section className="module-top-nav">
						<Row>
							<Col>
								<div className={tabIndex === 0 ? "active" : ""} onClick={() => setTabIndex(0)}>
									Questions
								</div>
								<div style={{ minWidth: "180px" }} className={tabIndex === 1 ? "active" : ""} onClick={() => setTabIndex(1)}>
									Progress Over Time
								</div>
							</Col>
						</Row>
						<Row>
							<Col>
								<div className="border"></div>
							</Col>
						</Row>
					</section>

					{tabIndex === 0 ? (
						<Fragment>
							{(!!fqId || isCreatedMode) && (
								<Fragment>
									<section className="module-date-selection">
										<Row>
											<Col style={{ alignItems: "center" }}>
												{!(isCreatedMode || isEditMode) && (
													<Fragment>
														<div>{AddHoursToDate(fqDb[fqId].createdAt, fqDb[fqId].createdAtOffset)}</div>
														<OverlayTrigger key={`fq-overlay-dates`} rootClose={true} trigger="click" placement="right" overlay={RenderFQDateSelectionOverlay()}>
															<div style={{ cursor: "pointer", color: "#2A92CF" }}>Change</div>
														</OverlayTrigger>

														<Button
															style={{ backgroundColor: "var(--primary-green)", marginRight: "5px" }}
															onClick={() => {
																setNewFq({ ...fqDb[fqIds[0]] });
																setIsCreatedMode(true);
															}}
														>
															New
														</Button>

														{!(!!fqId && fqDb[fqId].type == "intake") && (
															<Fragment>
																<Button
																	style={{ backgroundColor: "var(--primary-gray)", marginRight: "5px" }}
																	onClick={() => {
																		setNewFq({ ...fqDb[fqId] });
																		setIsEditMode(true);
																	}}
																>
																	Edit
																</Button>
																<OverlayTrigger rootClose={true} show={isRemoveConfirmationVisible} trigger="click" placement="right" overlay={RemoveFQConfirmationOverlay()}>
																	<Button
																		variant="danger"
																		style={{ marginRight: "5px" }}
																		onClick={() => {
																			// setNewFq({ ...fqDb[fqIds[0]] });
																			setIsRemoveConfirmationVisible(true);
																		}}
																	>
																		Remove
																	</Button>
																</OverlayTrigger>
															</Fragment>
														)}
													</Fragment>
												)}
											</Col>
										</Row>
									</section>

									<section style={{ padding: "1rem 6%" }}>
										<Row>
											<Col style={{ textAlign: "center", fontWeight: "bold", color: "var(--logo-color-1)" }}>Unable</Col>
											<Col style={{ textAlign: "center", fontWeight: "bold", color: "var(--logo-color-1)" }}>Very Difficult</Col>
											<Col style={{ textAlign: "center", fontWeight: "bold", color: "var(--logo-color-1)" }}>Moderately Difficult</Col>
											<Col style={{ textAlign: "center", fontWeight: "bold", color: "var(--logo-color-1)" }}>Minimally Difficult</Col>
											<Col style={{ textAlign: "center", fontWeight: "bold", color: "var(--logo-color-1)" }}>Normal</Col>
										</Row>
										<Row>
											<Col style={{ textAlign: "center", fontWeight: "bold" }}>
												<div className="module-score-highlight-1">0</div>
											</Col>
											<Col style={{ textAlign: "center", fontWeight: "bold" }}>
												<div className="module-score-highlight-1">1</div>
											</Col>
											<Col style={{ textAlign: "center", fontWeight: "bold" }}>
												<div className="module-score-highlight-1">2</div>
											</Col>
											<Col style={{ textAlign: "center", fontWeight: "bold" }}>
												<div className="module-score-highlight-1">3</div>
											</Col>
											<Col style={{ textAlign: "center", fontWeight: "bold" }}>
												<div className="module-score-highlight-1">4</div>
											</Col>
										</Row>
									</section>

									<section style={{ padding: "1rem 6%" }}>
										<hr style={{ width: "40%", height: "2px" }} />
									</section>

									<section style={{ padding: "1rem 6%" }}>
										{QuesOrder.map((v, k) => {
											return (
												<Fragment>
													{k + 1 === 1 && (
														<Row style={{ marginBottom: "20px" }}>
															<Col style={{ textAlign: "left" }}>
																<span style={{ color: "var(--logo-color-1)", fontSize: "120%", fontWeight: "bold" }}>SLEEPING</span>
															</Col>
														</Row>
													)}
													{k + 1 === 2 && (
														<Row style={{ marginBottom: "20px" }}>
															<Col style={{ textAlign: "left" }}>
																<span style={{ color: "var(--logo-color-1)", fontSize: "120%", fontWeight: "bold" }}>SITTING</span>
															</Col>
														</Row>
													)}
													{k + 1 === 4 && (
														<Row style={{ marginBottom: "20px" }}>
															<Col style={{ textAlign: "left" }}>
																<span style={{ color: "var(--logo-color-1)", fontSize: "120%", fontWeight: "bold" }}>MOVING - STANDING/WALKING/RUNNING</span>
															</Col>
														</Row>
													)}
													{k + 1 === 10 && (
														<Row style={{ marginBottom: "20px" }}>
															<Col style={{ textAlign: "left" }}>
																<span style={{ color: "var(--logo-color-1)", fontSize: "120%", fontWeight: "bold" }}>DAILY ACTIVITIES</span>
															</Col>
														</Row>
													)}
													{k + 1 === 16 && (
														<Row style={{ marginBottom: "20px" }}>
															<Col style={{ textAlign: "left" }}>
																<span style={{ color: "var(--logo-color-1)", fontSize: "120%", fontWeight: "bold" }}>JOB ACTIVITIES</span>
															</Col>
														</Row>
													)}

													<Row style={{ marginBottom: "20px" }}>
														<Col style={{ textAlign: "left" }}>
															{k + 1}. {QuesList[v - 1]}
														</Col>
													</Row>
													<Row style={{ marginBottom: "50px" }}>
														<Col style={{ textAlign: "center" }}>
															{Array(6)
																.fill(0)
																.map((v1, k1) => {
																	return (
																		<Button
																			style={{ width: "50px", height: "50px" }}
																			disabled={!(isEditMode || isCreatedMode)}
																			className={
																				isCreatedMode || isEditMode
																					? newFq.answers[v - 1] === k1
																						? "fq-rating-button-active"
																						: "fq-rating-button-inactive"
																					: fqDb[fqId].answers[v - 1] === k1
																					? "fq-rating-button-active"
																					: "fq-rating-button-inactive"
																			}
																			onClick={() => {
																				let tempFQ = { ...newFq };
																				tempFQ.answers[v - 1] = k1;
																				setNewFq({ ...tempFQ });
																			}}
																		>
																			{k1 === 5 ? "NA" : k1}
																		</Button>
																	);
																})}
														</Col>
													</Row>
												</Fragment>
											);
										})}

										<Row style={{ marginBottom: "20px" }}>
											<Col style={{ textAlign: "left" }}>
												<span style={{ color: "var(--logo-color-1)", fontSize: "120%", fontWeight: "bold" }}>PAIN SCALE</span>
											</Col>
										</Row>

										<Row style={{ marginBottom: "20px" }}>
											<Col style={{ textAlign: "left" }}>
												Please select the number that describes the pain you have experienced over the last week with 0 being no pain <b>(when not taking pain medication) </b>.
											</Col>
										</Row>

										<Row style={{ marginBottom: "50px" }}>
											<Col style={{ textAlign: "center" }}>
												{Array(11)
													.fill(0)
													.map((v, k) => {
														return (
															<Button
																style={{ width: "50px", height: "50px" }}
																disabled={!(isCreatedMode || isEditMode)}
																className={
																	isCreatedMode || isEditMode
																		? newFq.overallPain == k
																			? "fq-rating-button-active"
																			: "fq-rating-button-inactive"
																		: fqDb[fqId].overallPain == k
																		? "fq-rating-button-active"
																		: "fq-rating-button-inactive"
																}
																onClick={() => {
																	let tempFQ = { ...newFq };
																	tempFQ.overallPain = k;
																	setNewFq({ ...tempFQ });
																}}
															>
																{k}
															</Button>
														);
													})}
											</Col>
										</Row>

										<Row>
											<Col style={{ textAlign: "center" }}>
												{isCreatedMode && (
													<div>
														<OverlayTrigger rootClose={true} show={isCreateFQConfirmationVisible} trigger="click" placement="right" overlay={CreateFQConfirmationOverlay()}>
															<Button onClick={() => setIsCreateFQConfirmationVisible(true)} style={{ background: "var(--primary-blue)", marginRight: "10px" }}>
																Submit
															</Button>
														</OverlayTrigger>

														<Button onClick={() => setIsCreatedMode(false)} style={{ background: "var(--primary-red)" }}>
															Cancel
														</Button>
													</div>
												)}
												{isEditMode && (
													<div>
														<OverlayTrigger rootClose={true} show={isUpdateFQConfirmationVisible} trigger="click" placement="right" overlay={UpdateFQConfirmationOverlay()}>
															<Button onClick={() => setIsUpdateFQConfirmationVisible(true)} style={{ background: "var(--primary-blue)", marginRight: "10px" }}>
																Update
															</Button>
														</OverlayTrigger>

														<Button onClick={() => setIsEditMode(false)} style={{ background: "var(--primary-red)" }}>
															Cancel
														</Button>
													</div>
												)}
											</Col>
										</Row>
									</section>
								</Fragment>
							)}

							{!!fqId && !(isCreatedMode || isEditMode) && (
								<section>
									<Row>
										<Col style={{ textAlign: "center" }}>
											<Button
												variant="success"
												style={{ marginRight: "5px" }}
												onClick={() => {
													setIsPdfSDownloadSelected(true);
												}}
											>
												Download
											</Button>

											<Button
												variant="success"
												style={{ marginRight: "5px" }}
												onClick={() => {
													setIsPdfMDownloadSelected(true);
												}}
											>
												Download All
											</Button>
										</Col>
									</Row>
								</section>
							)}

							{!!!fqId && !(isCreatedMode || isEditMode) && (
								<Fragment>
									<section className="module-top-nav">
										<Button
											style={{ backgroundColor: "var(--primary-green)", marginRight: "5px" }}
											onClick={() => {
												setNewFq({ ...DefaultFQ, caseId: CaseId, createdAt: new Date().toISOString(), createdAtOffset: 0 });
												setIsCreatedMode(true);
											}}
										>
											New
										</Button>
									</section>
								</Fragment>
							)}
						</Fragment>
					) : null}

					{tabIndex === 1 ? (
						<Fragment>
							<section className="progress-charts-container">
								<Row>
									<Col>
										<Line data={fqChartData} options={defaultFqLineChartOptions} id={`fq-chart-1`} key={`fq-chart-1`} />
									</Col>
								</Row>
								<Row>
									<Col>
										<h3>FUNCTIONAL SCORE VS OVERALL PAIN</h3>
									</Col>
								</Row>
							</section>
						</Fragment>
					) : (
						<Fragment></Fragment>
					)}
				</Fragment>
			)}
		</Fragment>
	);
};

const CalculateAge = (dateString) => {
	var today = new Date();
	var birthDate = new Date(dateString);
	var age = today.getFullYear() - birthDate.getFullYear();
	var m = today.getMonth() - birthDate.getMonth();
	if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
		age--;
	}
	return age;
};

const AddHoursToDate = (str, h) => {
	let newDate = new Date(str);
	newDate.setTime(newDate.getTime() + h * 60 * 60 * 1000);
	return newDate.toLocaleString(dateLocale, defaultDateFormat);
};

const defaultDateFormat = {
	year: "numeric",
	month: "short",
	day: "numeric",
	hour: "numeric",
	minute: "numeric",
};

const defaultChartDateFormat = {
	year: "2-digit",
	month: "short",
	day: "numeric",
	weekday: "short",
};
const dateLocale = "en-Us";

const Styles = {
	Info: {
		padding: "5px",
		textAlign: "center",
	},
};

const QuesOrder = [1, 10, 6, 11, 4, 15, 2, 14, 9, 13, 3, 12, 5, 7, 8, 16, 17];

const QuesList = [
	"How normally do you sleep?", // 1 - 1
	"How difficult is it for you to move up and down stairs?", // 2 - 7
	"How difficult is it for you to perform food preparation / cooking / eating?", // 3 - 11
	"How difficult is it for you to walk?", // 4 - 5
	"How difficult is it for you to groom (bath, comb, hair, shave, etc.)?", // 5 - 13
	"How difficult is it for you to get up or down from bed or chair?", // 6 - 3
	"How well do you manage normal dressing activities?", // 7 - 14
	"How well do you manage to tie shoes / button shirt?", // 8 - 15
	"How difficult is it for you to carry items up to 10 pounds?", // 9 - 9
	"How difficult is it for you to sit for normal periods of time?", // 10 - 2
	"How difficult is it for you to stand for normal periods of time?", // 11 - 4
	"How difficult is it for you to reach above head or across body?", // 12 - 12
	"How difficult is it for you to perform leisure / recreational / sports activities?", // 13 - 10
	"How difficult is it for you to squat down to pick up items?", // 14 - 8
	"How difficult is it for you to perform running / jogging?", // 15 - 6
	"How difficult is it for you to drive? ", // 16 - 16
	"How difficult is it for you to perform regular job related activities?", // 17 - 17
];

const ComputeFQChartData = (fqDb, fqIds, fqPred, fqChartData) => {
	var fqList = fqIds.map((id) => fqDb[id]);
	let labels = fqList.map((v, k) => {
		return new Date(AddHoursToDate(fqList[k].createdAt, fqList[k].createdAtOffset)).toLocaleDateString(dateLocale, defaultDateFormat);
	});

	labels.reverse();

	let tempFQChartData = { ...fqChartData };

	tempFQChartData.labels = labels;

	tempFQChartData.datasets[0].data = [];
	tempFQChartData.datasets[1].data = [];
	tempFQChartData.datasets[2].data = [];
	tempFQChartData.datasets[3].data = [];

	for (let j = fqList.length - 1; j >= 0; j--) {
		tempFQChartData.datasets[3].data.push(fqList[j].overallPain);
		if (j === 0) {
			tempFQChartData.datasets[1].data.push(fqList[j].overallPain);
		} else {
			tempFQChartData.datasets[1].data.push(null);
		}
	}

	for (let j = fqList.length - 1; j >= 0; j--) {
		let counter = 0;
		let sumScores = 0;
		for (let i = 0; i < 15; i++) {
			if (fqList[j].answers[i] >= 0 && fqList[j].answers[i] <= 4) {
				counter += 1;
				sumScores += fqList[j].answers[i];
			}
		}
		if (counter > 0) {
			tempFQChartData.datasets[2].data.push((sumScores / 6).toFixed(1));
			if (j === 0) {
				tempFQChartData.datasets[0].data.push((sumScores / 6).toFixed(1));
			} else {
				tempFQChartData.datasets[0].data.push(null);
			}
		} else {
			tempFQChartData.datasets[2].data.push(-1);
			// tempFQChartData.datasets[0].data.push(-1);
			if (j === 0) {
				tempFQChartData.datasets[0].data.push(-1);
			} else {
				tempFQChartData.datasets[0].data.push(null);
			}
		}
	}

	if (fqPred.length > 0) {
		tempFQChartData.labels.push("Next Visit Prediction");
		tempFQChartData.datasets[1].data.push(fqPred[fqPred.length - 1]);

		let counter = 0;
		let sumScores = 0;
		for (let i = 0; i < 15; i++) {
			if (fqPred[i] >= 0 && fqPred[i] <= 4) {
				counter += 1;
				sumScores += fqPred[i];
			}
		}
		if (counter > 0) {
			tempFQChartData.datasets[0].data.push((sumScores / 6).toFixed(1));
		} else {
			tempFQChartData.datasets[0].data.push(-1);
		}
	}

	return tempFQChartData;
};

const GetFQDataset = () => {
	let scoreData = {
		label: "Functional Score",
		hidden: false,
		fill: false,
		lineTension: 0.1,
		backgroundColor: "#2a93d0",
		borderCapStyle: "round",
		borderColor: "#2a93d0",
		borderDash: [],
		borderDashOffset: 0.0,
		borderJoinStyle: "round",
		borderWidth: 3.0,
		pointBorderColor: "#2a93d0",
		pointBackgroundColor: "#2a93d0",
		pointBorderWidth: 0,
		pointHoverRadius: 9,
		pointHoverBackgroundColor: "#2a93d0",
		pointHoverBorderColor: "#2a93d0",
		pointHoverBorderWidth: 0,
		pointRadius: 6,
		pointHitRadius: 9,
		data: [],
	};

	let painData = {
		label: "Overall Pain",
		hidden: false,
		fill: false,
		lineTension: 0.1,
		backgroundColor: "#45b349",
		borderCapStyle: "round",
		borderColor: "#45b349",
		borderDash: [],
		borderDashOffset: 0.0,
		borderJoinStyle: "round",
		borderWidth: 3.0,
		pointBorderColor: "#45b349",
		pointBackgroundColor: "#45b349",
		pointBorderWidth: 0,
		pointHoverRadius: 9,
		pointHoverBackgroundColor: "#45b349",
		pointHoverBorderColor: "#45b349",
		pointHoverBorderWidth: 0,
		pointRadius: 6,
		pointHitRadius: 9,
		data: [],
	};

	let expectedScoreData = {
		label: "Expected Functional Score",
		hidden: false,
		fill: false,
		lineTension: 0.1,
		backgroundColor: "#ff4538",
		borderCapStyle: "round",
		borderColor: "#2a93d0",
		borderDash: [10, 10],
		borderDashOffset: 0.0,
		borderJoinStyle: "miter",
		borderWidth: 2.0,
		pointBorderColor: "#2a93d0",
		pointBackgroundColor: "#2a93d0",
		pointBorderWidth: 0,
		pointHoverRadius: 9,
		pointHoverBackgroundColor: "#2a93d0",
		pointHoverBorderColor: "#2a93d0",
		pointHoverBorderWidth: 0,
		pointRadius: 6,
		pointHitRadius: 9,
		data: [],
	};

	let expectedPainData = {
		label: "Expected Overall Pain",
		hidden: false,
		fill: false,
		lineTension: 0.1,
		backgroundColor: "#88c775",
		borderCapStyle: "round",
		borderColor: "#45b349",
		borderDash: [10, 10],
		borderDashOffset: 0.0,
		borderJoinStyle: "miter",
		borderWidth: 2.0,
		pointBorderColor: "#45b349",
		pointBackgroundColor: "#45b349",
		pointBorderWidth: 0,
		pointHoverRadius: 9,
		pointHoverBackgroundColor: "#45b349",
		pointHoverBorderColor: "#45b349",
		pointHoverBorderWidth: 0,
		pointRadius: 6,
		pointHitRadius: 9,
		data: [],
	};

	let dataset = [{ ...expectedScoreData }, { ...expectedPainData }, { ...scoreData }, { ...painData }];

	return dataset;
};

const fqDefaultChartData = {
	labels: [],
	datasets: GetFQDataset(),
};

const DefaultFQ = {
	id: "",
	caseId: "",
	overallPain: -1,
	therapistName: "",
	type: "clinic",
	createdAt: "",
	createdAtOffset: 0,
	answers: [5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5],
};

const DefaultPdfReportConfig = {
	orientation: "p",
	unit: "px",
	format: "a4",
	putOnlyUsedFonts: true,
	floatPrecision: "smart", // or "smart", default is 16
};

const IsEmpty = (val) => {
	return val === undefined || val === null || val.length <= 0 ? true : false;
};

export default Questionnaire;
