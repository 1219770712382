import React, { useState, useEffect } from "react";
import { Col, Row, Form, Alert, OverlayTrigger, Popover } from "react-bootstrap";
import axios from "axios";

import UiButton from "./ui-button.js";
import InfoPopup from "./info-popup.js";

import "bootstrap/dist/css/bootstrap.min.css";

export default function Roles(props) {
	const activeAccessLevel = props.parentState.ActiveOrganization.myAccessLevel;

	const [state, setState] = useState({
		Different: false,
		Title: props.parentState.ActiveOrganization.roleEntries[0] && props.parentState.ActiveOrganization.roleEntries[0].title,
		NewRoleName: "",
		NewRoleAccessLevel: 2,
		AccessLevel: props.parentState.ActiveOrganization.roleEntries[0] && props.parentState.ActiveOrganization.roleEntries[0].accessLevel,
		ActiveRole: props.parentState.ActiveOrganization.roleEntries[0] && props.parentState.ActiveOrganization.roleEntries[0].id,
		Error: [false, ""],
		Success: [false, ""],
	});

	const Initial = {
		Different: false,
		Title: props.parentState.ActiveOrganization.roleEntries[0] && props.parentState.ActiveOrganization.roleEntries[0].title,
		NewRoleName: "",
		NewRoleAccessLevel: 2,
		AccessLevel: props.parentState.ActiveOrganization.roleEntries[0] && props.parentState.ActiveOrganization.roleEntries[0].accessLevel,
		ActiveRole: props.parentState.ActiveOrganization.roleEntries[0] && props.parentState.ActiveOrganization.roleEntries[0].id,
		Error: [false, ""],
		Success: [false, ""],
	};

	// hide success/error alert after 4 seconds
	useEffect(() => {
		const timer = setTimeout(() => {
			setState((prev) => ({ ...prev, Success: [false, ""], Error: [false, ""] }));
		}, 4000);
		return () => clearTimeout(timer);
	}, [state.Success, state.Error]);

	const AddRole = () => {
		return (
			<Popover className="action-toggle-wrapper roles-toggle-wrapper" id={`popover${Math.floor(Math.random()).toString()}`}>
				<Popover.Content>
					<Row>
						<Col>
							<h3 className="green-color">Create new role</h3>
						</Col>
					</Row>
					<Form>
						<Row>
							<Form.Group as={Col} controlId="create_role">
								<Form.Label className="gray-color">Role Name:</Form.Label>
								<Form.Control size="sm" type="input" placeholder="eg: Admin" value={state.NewRoleName} onChange={(e) => SetThing("NewRoleName", e)} />
							</Form.Group>

							<Form.Group as={Col} controlId="create_AccessLevel">
								<Form.Label className="gray-color">Access Level:</Form.Label>
								<div className="flex-row align-items-center">
									<Form.Control
										size="sm"
										as="select"
										placeholder="AccessLevel"
										custom
										className="ui-select margin-left small-int-select"
										value={state.NewRoleAccessLevel}
										onChange={(e) => SetThing("NewRoleAccessLevel", e)}
									>
										{AccessLevels.map((level) => (
											<option value={level}>{level}</option>
										))}
									</Form.Control>
									<InfoPopup
										trigger="click"
										symbol="?"
										content="1 - All access <br/> 2 - Can't modify license or delete staff <br/> 3 - Can't modify license, delete staff, or modify roles or organization"
										placement="right"
									/>
								</div>
							</Form.Group>
						</Row>
						<Row>
							<Form.Group as={Col} controlId="submit">
								<UiButton onClick={(e) => HandleAddNewRole(e)} type="submit" size="sm">
									Create
								</UiButton>
							</Form.Group>
						</Row>
					</Form>
				</Popover.Content>
			</Popover>
		);
	};

	const HandleAddNewRole = (e) => {
		e.preventDefault();
		e.stopPropagation();

		if (state.NewRoleName.length > 0 && state.NewRoleAccessLevel) {
			if (!props.parentState.ActiveOrganization.roles.includes(state.NewRoleName)) {
				const RolePostData = {
					businessId: props.parentState.ActiveOrganization.id,
					userId: null,
					title: state.NewRoleName,
					accessCategory: state.NewRoleName,
					accessLevel: Number(state.NewRoleAccessLevel),
					description: "",
					status: "active",
					createdAt: props.parentState.Today,
					createdAtOffset: 3,
				};

				const RolePostHeaders = {
					headers: {
						Authorization: props.parentState.DBAccessToken,
						"Content-Type": "application/json",
					},
				};

				axios
					.post(props.dbUrl + "/api/businessroles/v1/create", RolePostData, RolePostHeaders)

					.then((res) => {
						if (res.data.success) {
							props.setState({ ...props.parentState, Refresh: props.parentState.Refresh + 1 });
							setState({ ...state, Success: [true, "Role was successfully added"] });
						} else {
							setState({ ...state, Error: [true, "Something went wrong"] });
						}
					});
			} else {
				setState({ ...state, Error: [true, "Role already exists"] });
			}
		}
	};

	const SetThing = (thing, e) => {
		return setState({ ...state, [thing]: e.target.value, Different: true });
	};

	const DeleteRole = () => {
		const GetOwnerRoleId = () => {
			for (let i of props.parentState.ActiveOrganization.roleEntries) {
				if (i.title === "Owner") {
					return i.id;
				}
			}
		};

		props.setState({ ...props.parentState, Preloader: true }); // start preloader

		// get all roles for active business
		axios({
			method: "get",
			url: props.dbUrl + "/api/businessroles/v1/businessid",
			headers: {
				Authorization: props.parentState.DBAccessToken,
				businessId: props.parentState.ActiveOrganization.id,
			},
		}).then((roles) => {
			let businessRoles = roles.data.data.businessRoles;

			let inUse = false;

			// if there's a user tied to the role, lets not delete it
			for (let i of Object.values(businessRoles)) {
				console.log("businessRoles: ", businessRoles);
				console.log("i: ", i);

				if (i.title === state.Title && i.userId.length !== 0) {
					inUse = true;
				}
			}
			console.log("inUse: ", inUse);

			if (inUse) {
				setState({ ...state, Error: [true, "This role is in use, and cannot be deleted"] });
				props.setState({ ...props.parentState, Preloader: false }); // end preloader
			} else {
				// the role is not in use, safe to delete all empty roles

				for (let i of Object.values(businessRoles)) {
					if (i.title === state.Title && i.userId.length === 0) {
						const DeleteHeaders = {
							headers: {
								Authorization: props.parentState.DBAccessToken,
								roleId: i.roleId,
							},
						};

						axios.delete(props.dbUrl + "/api/businessroles/v1/remove/roleid", DeleteHeaders).then((res) => {});
					}
				}

				setState({ ...Initial, Success: [true, "Role was deleted successfully"], Different: false, ActiveRole: GetOwnerRoleId(), Title: "Owner" });
				props.setState({ ...props.parentState, Preloader: false, Refresh: props.parentState.Refresh + 1 }); // end preloader
			}
		});
	};

	const AccessLevels = [
		1, // All access
		2, // Can't modify license or delete staff
		3, // Can't modify license, delete staff, modify roles or organization
		// 4, // ??
		// 5 // ???
	];
	let rolesFiltered = [];

	const DisplayRoles = (role) => {
		if (!rolesFiltered.includes(role.title)) {
			rolesFiltered.push(role.title);
			return (
				<UiButton
					size="sm"
					variant={state.Title === role.title ? "primary" : "outline-primary"}
					value={role.id}
					onClick={(e) => setState({ ...state, ActiveRole: role.id, AccessLevel: role.accessLevel, Title: role.title })}
				>
					{role.title}
				</UiButton>
			);
		}
	};

	return (
		<div className="ui-section-wrapper">
			<section>
				<Row>
					<Col className="flex-row no-wrap">
						<h1>Roles</h1>
						<Alert variant="warning" show={state.Error[0] ? true : false}>
							<div className="flex-row">
								<p>{state.Error[1]}</p>
							</div>
						</Alert>
						<Alert variant="info" show={state.Success[0] ? true : false}>
							<div className="flex-row">
								<p>{state.Success[1]}</p>
							</div>
						</Alert>
					</Col>
				</Row>
			</section>

			<section className="form-section-wrapper">
				<Row>
					<Row>
						<div className="flex-row roles-selection-wrapper">
							{props.parentState.ActiveOrganization.roleEntries.map((role) => DisplayRoles(role))}
							{activeAccessLevel <= 1 && (
								<OverlayTrigger rootClose={true} trigger="click" placement="bottom-end" overlay={AddRole()}>
									<UiButton plus variant="link" className="green-color">
										Add Role
									</UiButton>
								</OverlayTrigger>
							)}
						</div>
					</Row>
					<div className="role-section-wrapper">
						{/* <Form> */}

						<Col lg={6} md={6} sm={12}>
							<Row className="role-info info-details">
								<Col>
									<p>
										Role name: <span>{state.Title}</span>
									</p>
								</Col>
								<Col>
									{/* <div className="flex-row align-items-center"> */}
									<p>
										Access level: <span>{state.AccessLevel}</span>
									</p>
									{/* <InfoPopup
                        trigger='click' 
                        symbol='?'
                        content="1 - All access <br/> 2 - Can't modify license or delete staff <br/> 3 - Can't modify license, delete staff, or modify roles or organization"
                        placement='right'
                      /> */}

									{/* </div> */}
								</Col>
								{/* <Col>

                  </Col> */}
							</Row>

							{state.Title.toLowerCase() !== "owner" && state.Title.toLowerCase() !== "unassigned" && activeAccessLevel <= 1 && (
								<Form.Group controlId="consent" className="form-footer-wrapper">
									<UiButton onClick={(e) => DeleteRole()} variant="link-danger" size="sm" className="no-padding muted">
										Delete Role
									</UiButton>
								</Form.Group>
							)}
						</Col>

						<Col lg={6} md={6} sm={12}>
							<div className="page-info-bubble">
								<h5 className="blue-color">Access Levels:</h5>
								<ul>
									<li>
										<p>1 - All access</p>
									</li>
									<li>
										<p>2 - Cannot modify license. Cannot edit or delete staff. Can invite new staff member. Has complete access to the dashboard.</p>
									</li>
									<li>
										<p>3 - Can only update personal information.</p>
									</li>
								</ul>
							</div>
						</Col>

						{/* <Row>
                <Form.Group as={Col} controlId="update_title">
                  <Form.Label>Role Name:</Form.Label>
                  <Form.Control size="sm" type="input" value={state.ActiveRole} onChange={e => SetThing('ActiveRole',e)} placeholder="Role Name" required/>
                </Form.Group>
                <Form.Group as={Col} controlId="update_AccessLevel" lg={6}>
                  <Form.Label>Access Level:</Form.Label>
                  <Form.Control size="sm" as="select" placeholder="First Name" value={state.AccessLevel} onChange={e => SetThing('AccessLevel', e)} custom className="ui-select">
                    {AccessLevels.map(role => <option value={role}>{role}</option> )}
                  </Form.Control>
                </Form.Group>

              </Row> */}

						{/* <Row> */}
						{/* <Form.Group controlId="consent" className="form-footer-wrapper">
                  <UiButton
                  type="submit"
                  variant="secondary"
                  disabled={state.Different ? false : true}
                  onClick={e => HandleEditFormSubmit(e)}
                  >
                    Save
                  </UiButton>
                </Form.Group>

                <Form.Group controlId="consent" className="form-footer-wrapper">
                  <UiButton
                  onClick={e => setState({...Initial})}
                  variant="link"
                  className="gray-color no-padding"
                  visibility={state.Different ? false : true}
                  >
                    Cancel
                  </UiButton>
                </Form.Group> */}

						{/* { state.Title !== 'Owner' && state.Title !== 'owner' &&

                <Form.Group controlId="consent" className="form-footer-wrapper">
                  <UiButton
                  onClick={e => DeleteRole()}
                  variant="link-danger"
                  size="sm"
                  className="no-padding"
                  >
                    Delete Role
                  </UiButton>
                </Form.Group>
                } */}

						{/* </Row> */}
						{/* </Form> */}
					</div>
				</Row>
			</section>
		</div>
	);
}
