import React, {useState, useEffect, Fragment } from 'react';
import { useParams } from 'react-router-dom';
import {Container, Col, Row, Image, Button } from 'react-bootstrap';
import ReactHtmlParser from 'react-html-parser';
import {FacebookShareButton, LinkedinShareButton, TwitterShareButton} from 'react-share';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTwitter, faFacebookF, faLinkedinIn } from '@fortawesome/free-brands-svg-icons';
import {faCaretSquareLeft} from '@fortawesome/free-solid-svg-icons';
import Fade from 'react-reveal/Fade';

import "bootstrap/dist/css/bootstrap.min.css";
import "../resources/styles/blog.scss";

import NavBar from "../components/navbar.component.js";
import Footer from "../components/footer.component.js";
import StandardHero from "../components/standard-hero.js";

import localPosts from "../resources/blog/posts/localPosts.json";

// Sets custom page meta with react-helmet
import MetaDecorator from "../components/metaDecorator.js";

export default function Blog(props) {
    console.log(localPosts);

  // filter blogs to just display news related if props.news
  if (props.news) {
    let result = {}
    Object.values(localPosts.post).forEach(post => {
      if (post.categories.includes(8)) {
        result[post.id] = post;
      }
    })
    localPosts.post = result;
  }
    
    const [state, setState] = useState({
        authors: localPosts.author,
        blogs: {},
        blogsCount: 0,
        currentBlogDate: '',
        currentPostComments: [],
        blogsSortedByDate: [],
        postsLoaded: false,

        displayBlogList: true,          // Display blog in an orderly fashion
        displayBlogBasedOnSlug: false,   // Display blog based only on slug value / using URL
        displayBlogBasedOnId: false,

        metaTitle: "Bodiometer - News",
        metaDescription: "Information hub for topics related to smart body analytics and biomechanics tracking. Stay informed. Help prevent injury. Improve your Health!",
        metaUrl: "https://www.bodiometer.com/news"
        
    });


    const ConvertDate = (date) => {
        let publicationDate = new Date(date);
        let today = Date.now();
        let diff_ms = today - publicationDate;
        let Difference = (dif) => {
            return {
                ms : Math.floor( dif                              ),
                s  : Math.floor( dif /                       1000 ),
                min: Math.floor( dif /                 (60 * 1000)),
                h  : Math.floor( dif /            (60 * 60 * 1000)),
                d  : Math.floor( dif /       (24 * 60 * 60 * 1000)),
                w  : Math.floor( dif /   (7 * 24 * 60 * 60 * 1000)),
                m  : Math.floor( dif /  (30 * 24 * 60 * 60 * 1000)),
                y  : Math.floor( dif / (365 * 24 * 60 * 60 * 1000)),
            }
        }
        let options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
        let elapsedTime = Difference(diff_ms);
        return publicationDate.toLocaleDateString("en-US", options);
    };



    // params will grab URL params if present
    const params = useParams();

    useEffect(() => {
        // If url is for available single post
        if(Object.values(localPosts.slugMapper).includes(params.news_slug)) {


          let blogs = {}
          let blog_id = Object.keys(localPosts.slugMapper).find(key => localPosts.slugMapper[key] === params.news_slug);
          blogs[blog_id] = localPosts.post[blog_id];

          setState({
              ...state,
              blogs: blogs,
              currentBlog: blog_id,
              currentBlogDate: ConvertDate(blogs[blog_id].date),
              postsLoaded: true,
              displayBlogList: false,          // Display blog in an orderly fashion
              displayBlogBasedOnSlug: true,   // Display blog based only on slug value / using URL
              displayBlogBasedOnId: false,
              metaTitle: "Bodiometer - " + localPosts.post[blog_id].title,
              metaDescription: RemoveHtmlTags(localPosts.post[blog_id].excerpt),
              metaUrl: "https://www.bodiometer.com/news/" + localPosts.post[blog_id].slug
          })
        }
        else {
          let authorInfo = {};
          let blogInfo = {};

          Object.keys(localPosts.author).forEach((author) => {
              authorInfo[author] = localPosts.author[author];
          });

          let blogList = [];
          let postCount = 0;
          let elapsedTime = 0;
          let publishLocalString = "";

          Object.keys(localPosts.post).forEach((post) => {
              if(localPosts.post[post].status === 'publish'){
                  postCount++;
                  blogInfo[post] = localPosts.post[post];
                  let publicationDate = new Date(localPosts.post[post].date);
                  let today = Date.now();
                  let diff_ms = today - publicationDate;
                  let Difference = (dif) => {
                      return {
                          ms : Math.floor( dif                              ),
                          s  : Math.floor( dif /                       1000 ),
                          min: Math.floor( dif /                 (60 * 1000)),
                          h  : Math.floor( dif /            (60 * 60 * 1000)),
                          d  : Math.floor( dif /       (24 * 60 * 60 * 1000)),
                          w  : Math.floor( dif /   (7 * 24 * 60 * 60 * 1000)),
                          m  : Math.floor( dif /  (30 * 24 * 60 * 60 * 1000)),
                          y  : Math.floor( dif / (365 * 24 * 60 * 60 * 1000)),
                      }
                  }
                  let options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
                  elapsedTime = Difference(diff_ms);
                  publishLocalString = publicationDate.toLocaleDateString("en-US", options);
                  blogList.push({
                      post_id: post,
                      author_id: localPosts.post[post].author,
                      elapsed_time: elapsedTime,
                      publication_local_string: publishLocalString
                  });
              }
          })
          const Compare = (a, b) => {
              if(a.elapsed_time.ms > b.elapsed_time.ms){
                  return 1;
              }
              else{
                  return -1;
              }
          }

          // sort the blog list based on elapsed time
          let blogListSorted = blogList.sort(Compare);
          if(postCount > 0){
              setState(state => ({
                  ...state,
                  authors: authorInfo,
                  blogs: blogInfo,
                  blogsCount: postCount,
                  blogsSortedByDate: blogListSorted,
                  currentBlog: blogListSorted[0].post_id,
                  postsLoaded: true,
              }))
          }
        }

    },[])

    const DisplayBlogListBody = (post_key, author_key, pub_date) => {
        return(
                <Container id="blog" fluid>
                    <a href={`/news/${localPosts.slugMapper[post_key]}/`}>
                        <Image src={`..${localPosts.post[post_key].post_image_url}`} alt={state.blogs[post_key].title}/>
                    </a>
                    {/* <Row id="blog-author-description">
                        <Col xs={3}>
                            <Image src={`..${localPosts.author[author_key].avatar_url}`} alt={state.authors[author_key].name} roundedCircle rounded/>
                        </Col>
                        <Col>
                            <Row xs={9}>
                                <span id="blog-author-name">
                                    {state.authors[author_key].name} 
                                </span>
                            </Row>
                            <Row>
                                <span id="blog-post-time">
                                    {pub_date}
                                </span>
                            </Row>
                        </Col>
                    </Row> */}
                    <Row id="blog-title">
                        <Col>
                            <a href={`/news/${localPosts.slugMapper[post_key]}/`}>
                                <h3 className="blue-color">{state.blogs[post_key].title}</h3>
                            </a>
                        </Col>
                        
                    </Row>

                    <Row id="blog-subtitle">
                        <Col>
                            {ReactHtmlParser(state.blogs[post_key].excerpt)}
                            {/* </a> */}
                        </Col>
                        
                    </Row>

                      <a href={`/news/${localPosts.slugMapper[post_key]}/`}>
                    <Row id="blog-footer">
                        <Button variant="primary">Read More</Button>

                    </Row>
                      </a>
                </Container>

            

        );
    }

    const TransformHtml = (node, index) => {
        if(node.attribs){
            if(node.attribs.class){
                node.attribs.class = "";
            }
            if(node.attribs.style){
                node.attribs.style = "";
            }
            
        }
    }

    const RemoveHtmlTags = (html) => {
        var div = document.createElement("div");
        div.innerHTML = html;
        return div.innerText;
    }

    const DisplayBlog = (k) => {
        
        return(
            <Container fluid>
                <Row id="blog-main-nav">
                    <Col>
                        <a href="/news/">
                            <FontAwesomeIcon icon={faCaretSquareLeft} />&nbsp;<h3 className="blue-color">Back</h3>
                        </a>
                    </Col>
                </Row>

                <div id="blog-main-body">
                <Row>

                    <Col>
                        <Image src={`../..${localPosts.post[k].post_image_url}`}/>
                    </Col>

                </Row>

                <Row>
                    <Col className="main-body-col">
                      <h1>
                          {state.blogs[k].title}
                      </h1>
                    </Col>
                </Row>

                <Row>

                    <Col>
                        { 
                            ReactHtmlParser(state.blogs[k].content, {
                                transform: TransformHtml
                            })
                        }
                    </Col>

                </Row>
                </div>

                <Row>
                    <Col className='social-media' id='social-facebook' xs={1}>

                        <FacebookShareButton url={`https://www.bodiometer.com/news/${state.blogs[k].slug}`} quote={RemoveHtmlTags(state.blogs[k].excerpt)}> 
                            
                            <a href="#">
                                <FontAwesomeIcon icon={faFacebookF} />
                            </a>
                        </FacebookShareButton>
                        
                    </Col>
                    <Col className='social-media' id='social-twitter' xs={1}>
                        <TwitterShareButton url={`https://www.bodiometer.com/news/${state.blogs[k].slug}`} title={RemoveHtmlTags(state.blogs[k].excerpt)}> 
                                
                                <a href="#">
                                    <FontAwesomeIcon icon={faTwitter} />
                                </a>
                            
                            
                        </TwitterShareButton>
                    </Col>
                    <Col className='social-media' id='social-linkedin' xs={1}>
                        <LinkedinShareButton url={`https://www.bodiometer.com/news/${state.blogs[k].slug}`} quote={RemoveHtmlTags(state.blogs[k].excerpt)}> 
                            <a href="#">
                                <FontAwesomeIcon icon={faLinkedinIn} />
                            </a>
                        </LinkedinShareButton>
                    </Col>
                    
                </Row>

                <Row id="blog-main-nav">
                    <Col>
                        <hr />
                        <a href="/news">
                            <FontAwesomeIcon icon={faCaretSquareLeft} />&nbsp;<h3>Back to all posts</h3>
                        </a>
                        
                        
                    </Col>
                </Row>
            </Container>
        );
    }


    const DisplayBlogBySlug = (k) => {
        
        return(
            <Container className="thin-container">

                <Row id="blog-main-nav" xs={1} md={2}>
                    <Col className="back-button-wrapper">
                        <a href='/news/'>
                            <img src="../../photos/icons/arrow-blue.svg" height="20" alt="back arrow icon" />&nbsp;<h3 className="blue-color">Back</h3>
                        </a>
                    </Col>
                    <Col className="author-description-wrapper">
                        <Row>
                            <Col>
                                <div>
                                    <p id="blog-author-name">
                                        {state.authors[state.blogs[k].author].name} 
                                    </p>
                                    <p id="blog-post-time">
                                        <span>{state.currentBlogDate}</span>                                    </p>
                                </div>
                                <Image src={`../..${state.authors[state.blogs[k].author].avatar_url}`} alt={state.authors[state.blogs[k].author].name} width="60" roundedCircle rounded/>
                                <hr></hr>
                            </Col>
                        </Row>
                    </Col>
                </Row>


                <Row id="blog-author-description">
                    </Row>

                <div id="blog-main-body">

                {/* <Row>
                    <Col className="main-body-col">
                    <h1>
                        {state.blogs[k].title}
                    </h1>
                    </Col>
                </Row> */}

                <Row>

                    <Col>
                        { 
                            ReactHtmlParser(state.blogs[k].content, {
                                transform: TransformHtml
                            })
                        }
                    </Col>

                </Row>
                </div>

                <Row className="social-media-wrapper">
                    <Col className='social-media' id='social-facebook' xs={1}>

                        <FacebookShareButton url={`https://www.bodiometer.com/news/${state.blogs[k].slug}`} quote={RemoveHtmlTags(state.blogs[k].excerpt)}> 
                            
                            <a href="#">
                                <FontAwesomeIcon icon={faFacebookF} />
                            </a>
                        </FacebookShareButton>
                        
                    </Col>
                    <Col className='social-media' id='social-twitter' xs={1}>
                        <TwitterShareButton url={`https://www.bodiometer.com/news/${state.blogs[k].slug}`} title={RemoveHtmlTags(state.blogs[k].excerpt)}> 
                                
                                <a href="#">
                                    <FontAwesomeIcon icon={faTwitter} />
                                </a>
                            
                            
                        </TwitterShareButton>
                    </Col>
                    <Col className='social-media' id='social-linkedin' xs={1}>
                        <LinkedinShareButton url={`https://www.bodiometer.com/news/${state.blogs[k].slug}`} quote={RemoveHtmlTags(state.blogs[k].excerpt)}> 
                            <a href="#">
                                <FontAwesomeIcon icon={faLinkedinIn} />
                            </a>
                        </LinkedinShareButton>
                    </Col>
                    
                </Row>

                <Row id="blog-main-nav">
                    <Col className="back-button-wrapper">
                        <a href='/news/'>
                            <img src="../../photos/icons/arrow-blue.svg" height="20" alt="back arrow icon" />&nbsp;<h3 className="blue-color">Back</h3>
                        </a>
                    </Col>
                </Row>
            </Container>
        );
    }

    const BlogColumn = (num) => {
        var items = [];
        for(let i = num-1; i < state.blogsCount; i += 3){
            let p_id = state.blogsSortedByDate[i].post_id;
            let auth_id = state.blogsSortedByDate[i].author_id;
            let pub_str = state.blogsSortedByDate[i].publication_local_string;
            items.push(<div key={i}>{DisplayBlogListBody(p_id, auth_id, pub_str)}</div>);
        }
        return items;
    }
    


    return(
        <Fragment>
            <NavBar />
            <MetaDecorator
              description={state.metaDescription}
              title={state.metaTitle}
              url={state.metaUrl}
            />

            {state.displayBlogList && state.postsLoaded &&
                <Fragment>
                    
                    <StandardHero tall={false} bg={false}>
                        <div>
                            <img src="../../photos/icons/blog-blue.svg" width="80" alt="news icon" />
                            <h1 className="blue-color">Latest News</h1>
                        </div>
                    </StandardHero>

                    <section className="body-top-wrapper">
                        <Container>
                            <Row className="centered-text centered-row">
                                <Col lg={9}>
                                <div>
                                    <h2 className="gray-color">Stay informed with the latest news and updates in biomechanics and postural health</h2>
                                    <hr/>
                                </div>
                                </Col>
                            </Row>
                        </Container>
                    </section>

                    <Container id="blogbody">
                        
                        <Row>
                            <Col lg={4}>
                                {BlogColumn(1)}
                            </Col>
                            <Col lg={4}>
                                {BlogColumn(2)}
                            </Col>
                            <Col lg={4}>
                                {BlogColumn(3)}
                            </Col>

                        </Row>

                    </Container>
                </Fragment>
            }

            {state.displayBlogBasedOnId &&
                <Fragment>
                    <StandardHero tall={false} bg={false}>
                        <div>
                            <img src="../../photos/icons/blog-blue.svg" width="80" alt="news icon" />
                            <h1 className="blue-color">Latest News</h1>
                        </div>
                    </StandardHero>
                    <Container id="blog-main">
                        
                        {DisplayBlog(state.currentBlog)}
                    
                    </Container>
                </Fragment>
            }

            {state.displayBlogBasedOnSlug && state.postsLoaded &&
                <Fragment>
                    <StandardHero tall={true} bg={state.blogs[state.currentBlog].post_image_url}>
                        <div>
                            <h1 className="white-color">{state.blogs[state.currentBlog].title}</h1>
                        </div>
                    </StandardHero>
                    <Container id="blog-main">
                        
                        {DisplayBlogBySlug(state.currentBlog)}
                    
                    </Container>


                {/* Temporary divider until comments are built */}
                <section className="no-padding">
                    <img src="../../photos/consumer-landing/grad-rule-concave.svg" width="100%" alt="rule svg" />
                </section>

                {/* <section className="light-grad-bulge bg-grad-inverse faq-wrapper">
                    <Container className="centered-container thin-container">
                        <div className="title-icon">
                            <img src="../../photos/icons/chat-gray.svg" width="80" alt="comments icon" />
                            <h2 className="gray-color">Comments</h2>
                        </div>
                    <Row className="comments-wrapper">
                        <Col>
                        <br/>
                            { state.currentPostComments.length > 0 &&
                            <div className="posted-comments-wrapper">
                                <h3 className="blue-color">{state.currentPostComments.length} comments:</h3>

                            </div>
                            }
                            { state.currentPostComments.length === 0 &&
                            <div>
                                <h3 className="gray-color">{state.currentPostComments.length} comments:</h3>
                                <p>No comments have been submitted yet, but yours could be the first!</p>
                            </div>
                            }
                            <br/>
                            <h3 className="gray-color">Submit a Comment</h3>
                            <Form>
                                <Form.Group controlId="commentForm">
                                    <Form.Control type="input" placeholder="Name" required />
                                    <Form.Control as="textarea" rows="3" placeholder="Write your comment here.." required/>
                                </Form.Group>
                                <Button variant="primary" type="submit">Post</Button>
                            </Form>
                            
                        </Col>
                    </Row>
                </Container>
            </section> */}
            <section>
                <Container>
                    <Row xs={1} md={2} className="professionals-infographic-wrapper blog-cta-wrapper">
                        <Col md={4} lg={6}>
                            <Fade left delay={300}>
                                <img src="../../photos/consumer-landing/how-1.gif" width="100%" alt="Hodiometer Home - illustration" /> 
                            </Fade>
                        </Col>
                        <Col md={8} lg={6}>
                            <h3 className="green-color">Bodiometer Smart BodyScanning and Tracking</h3>
                            <p>Bodiometer is the world’s first real-time posture assessment app. You can use it to capture, track, and assess your posture with just a few clicks.</p>
                            <hr className="hr-style-four"/>
                            <Button href="/" variant="primary">Learn More</Button>
                        </Col>
                    </Row>
                </Container>
            </section>
            </Fragment>
            }
        
            <Footer />
            {/* <!-- Start of HubSpot Embed Code --> */}
                <script type="text/javascript" id="hs-script-loader" async defer src="//js.hs-scripts.com/2936832.js"></script>
            {/* <!-- End of HubSpot Embed Code --> */}
        </Fragment>
    );
};