import React, { useState, Fragment, useRef, useEffect } from "react";
import { Col, Row, Form, Button, ButtonGroup } from "react-bootstrap";
import PhoneInput, { isPossiblePhoneNumber, formatPhoneNumberIntl, parsePhoneNumber } from "react-phone-number-input";
import Datetime from "react-datetime";
import moment from "moment";

import { CountryList } from "./CountryList";
import { Default as DefaultIntake, DefaultIsValid } from "../Models/index";

import "bootstrap/dist/css/bootstrap.min.css";
import "react-phone-number-input/style.css";
import "react-datetime/css/react-datetime.css";
import "../Assets/style.scss";

const InjuryOptions = [
	"",
	"Sprain Strain",
	"Repetitive Movement",
	"Slip and Fall",
	"Contusion",
	"Collision / Crash",
	"Burn",
	"Fracture",
	"Cuts / Laceration",
	"Stress",
	"Chemical / Toxic Exposure",
	"Exposure To Sound / Light",
	"Poison",
	"Other",
];

const BodyPartOptions = [
	"Head / Face",
	"Neck",
	"Shoulder",
	"Arm",
	"Elbow",
	"Wrist",
	"Head / Fingers",
	"Chest",
	"Abdomen",
	"Upper Back",
	"Lower Back",
	"Pelvis / Groin",
	"Hip",
	"Leg",
	"Knee",
	"Ankle",
	"Foot / Toes",
];

const WorkRelatedInjury = ({ data, requiredDb, validatedDb, isValidDb, pageCompleted, setPageCompleted, activeIndex, viewInvalidFields, onChange }) => {
	const [validatedFields, setValidatedFields] = useState({ ...validatedDb });
	const [isValidFields, setIsValidFields] = useState({ ...isValidDb });
	const requiredFields = { ...requiredDb };

	const Separator = () => {
		return <div style={{ width: "100%", height: "1px", background: "var(--primary-gradient)", marginTop: "25px", marginBottom: "25px", position: "relative" }}></div>;
	};

	const Header = ({ label }) => {
		return <h2 style={{ textAlign: "center", marginBottom: "20px" }}>{label}</h2>;
	};

	const dateTimePickerRef = useRef(null);
	const [bodyPart, setBodyPart] = useState("");

	const IsFieldValid = (str, type) => {
		if (type == "text") {
			return !!str;
		} else if (type == "email") {
			return (
				!!str &&
				str.toLowerCase().match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/).length > 0
			);
		}
		return true;
	};

	const CheckIfFieldsAreValid = () => {
		var isValid = true;
		if (data.ExistsWorkRelatedInjury) {
			isValid = isValid && (requiredFields.InjuryDate ? isValidFields.InjuryDate : true);
			isValid = isValid && (requiredFields.InjuryType ? isValidFields.InjuryType : true);
			isValid = isValid && (requiredFields.BodyParts ? isValidFields.BodyParts : true);
			isValid = isValid && (requiredFields.WcbClaim ? isValidFields.WcbClaim : true);
			isValid = isValid && (requiredFields.EmployerCompanyName ? isValidFields.EmployerCompanyName : true);
			isValid = isValid && (requiredFields.EmployerRepresentativeFirstName ? isValidFields.EmployerRepresentativeFirstName : true);
			isValid = isValid && (requiredFields.EmployerRepresentativeLastName ? isValidFields.EmployerRepresentativeLastName : true);
			isValid = isValid && (requiredFields.EmployerPhone ? isValidFields.EmployerPhone : true);
			isValid = isValid && (requiredFields.EmployerPhoneExt ? isValidFields.EmployerPhoneExt : true);
			isValid = isValid && (requiredFields.EmployerAddress.Country ? isValidFields.EmployerAddress.Country : true);
			isValid = isValid && (requiredFields.EmployerAddress.City ? isValidFields.EmployerAddress.City : true);
			isValid = isValid && (requiredFields.EmployerAddress.Province ? isValidFields.EmployerAddress.Province : true);
			isValid = isValid && (requiredFields.EmployerAddress.StreetAddress1 ? isValidFields.EmployerAddress.StreetAddress1 : true);
			isValid = isValid && (requiredFields.EmployerAddress.StreetAddress2 ? isValidFields.EmployerAddress.StreetAddress2 : true);
			isValid = isValid && (requiredFields.EmployerAddress.PostalCode ? isValidFields.EmployerAddress.PostalCode : true);
			isValid = isValid && (requiredFields.AllowEmployerContact ? isValidFields.AllowEmployerContact : true);
		}
		return isValid;
	};

	const InitialFieldsValidity = () => {
		var cIsValid = { ...isValidFields };
		cIsValid.ExistsWorkRelatedInjury = data.ExistsWorkRelatedInjury == false || data.ExistsWorkRelatedInjury == true;
		cIsValid.InjuryDate = IsFieldValid(data.InjuryDate, "text");
		cIsValid.InjuryType = IsFieldValid(data.InjuryType, "text");
		cIsValid.BodyParts = cIsValid.InjuryType ? data.BodyParts.length > 0 : data.BodyParts.length <= 0;
		cIsValid.WcbClaim = IsFieldValid(data.WcbClaim, "text");
		cIsValid.EmployerCompanyName = IsFieldValid(data.EmployerCompanyName, "text");
		cIsValid.EmployerRepresentativeFirstName = IsFieldValid(data.EmployerRepresentativeFirstName, "text");
		cIsValid.EmployerRepresentativeLastName = IsFieldValid(data.EmployerRepresentativeLastName, "text");
		cIsValid.EmployerPhone = IsFieldValid(data.EmployerPhone, "text");
		cIsValid.EmployerPhoneExt = IsFieldValid(data.EmployerPhoneExt, "text");
		cIsValid.EmployerAddress.Country = IsFieldValid(data.EmployerAddress.Country, "text");
		cIsValid.EmployerAddress.City = IsFieldValid(data.EmployerAddress.City, "text");
		cIsValid.EmployerAddress.Province = IsFieldValid(data.EmployerAddress.Province, "text");
		cIsValid.EmployerAddress.StreetAddress1 = IsFieldValid(data.EmployerAddress.StreetAddress1, "text");
		cIsValid.EmployerAddress.StreetAddress2 = IsFieldValid(data.EmployerAddress.StreetAddress2, "text");
		cIsValid.EmployerAddress.PostalCode = IsFieldValid(data.EmployerAddress.PostalCode, "text");
		cIsValid.AllowEmployerContact = data.AllowEmployerContact == false || data.AllowEmployerContact == true;
		return cIsValid;
	};

	useEffect(() => {
		pageCompleted[activeIndex] = CheckIfFieldsAreValid();
		setPageCompleted([...pageCompleted]);
	}, [isValidFields]);

	useEffect(() => {
		setIsValidFields({ ...InitialFieldsValidity() });
	}, []);

	useEffect(() => {
		const iterate = (obj) => {
			Object.keys(obj).forEach((key) => {
				if (typeof obj[key] === "object" && obj[key] !== null) {
					iterate(obj[key]);
				} else {
					obj[key] = true;
				}
			});
		};
		if (viewInvalidFields) {
			window.scrollTo({
				top: "0",
				behavior: "smooth",
			});
			var newObj = { ...validatedFields };
			iterate(newObj);
			setValidatedFields({ ...newObj });
		}
	}, [viewInvalidFields]);

	return (
		<div className="intake" style={styles.screen}>
			<Header label={"Workrelated Injury Information"} />

			{/* work related injury? */}
			<Row>
				<Col md={8}>
					<Form.Label>Are you here due to work related injury?</Form.Label>
				</Col>
				<Col md={4} style={{ textAlign: "end" }}>
					<ButtonGroup>
						<Button
							active={data.ExistsWorkRelatedInjury}
							onClick={(evt) => {
								onChange({ ...data, ExistsWorkRelatedInjury: true });
								setValidatedFields({ ...validatedFields, ExistsWorkRelatedInjury: true });
								setIsValidFields({ ...isValidFields, ExistsWorkRelatedInjury: true });
							}}
						>
							Yes
						</Button>
						<Button
							active={!data.ExistsWorkRelatedInjury}
							onClick={(evt) => {
								onChange({ ...DefaultIntake.WorkRelatedInjury, ExistsWorkRelatedInjury: false });
								setValidatedFields({ ...validatedFields, ExistsWorkRelatedInjury: true });
								setIsValidFields({ ...DefaultIsValid.WorkRelatedInjury, ExistsWorkRelatedInjury: true });
							}}
						>
							No
						</Button>
					</ButtonGroup>
				</Col>
			</Row>

			{data.ExistsWorkRelatedInjury && (
				<Fragment>
					{/* injury date & type */}
					<Row>
						<Col md={2}>
							<Form.Label>Date of Injury (DD/MM/YYYY) {requiredFields.InjuryDate ? "*" : ""}</Form.Label>
						</Col>
						<Col md={4}>
							<div className={validatedFields.InjuryDate && requiredFields.InjuryDate && !isValidFields.InjuryDate ? "isinvalid" : "isvalid"}>
								<Datetime
									initialValue={!!data.InjuryDate ? moment(data.InjuryDate).format("MM/DD/YYYY") : ""}
									onChange={(evt) => {
										onChange({
											...data,
											InjuryDate: moment(evt._d).format("MM/DD/YYYY"),
										});
										setValidatedFields({ ...validatedFields, InjuryDate: true });
										setIsValidFields({ ...isValidFields, InjuryDate: IsFieldValid(evt._d, "text") });
									}}
									dateFormat="MM/DD/YYYY"
									timeFormat={false}
									ref={dateTimePickerRef}
									closeOnSelect
								/>
							</div>
						</Col>
						<Col md={2}>
							<Form.Label>Injury Type {requiredFields.InjuryType ? "*" : ""}</Form.Label>
						</Col>
						<Col md={4}>
							<Form.Group className={validatedFields.InjuryType && requiredFields.InjuryType && !isValidFields.InjuryType ? "isinvalid" : "isvalid"}>
								<Form.Control
									as="select"
									onChange={(evt) => {
										onChange({ ...data, InjuryType: evt.target.value });
										setValidatedFields({ ...validatedFields, InjuryType: true });
										setIsValidFields({
											...isValidFields,
											InjuryType: IsFieldValid(evt.target.value, "text"),
											BodyParts: IsFieldValid(evt.target.value, "text") ? data.BodyParts.length > 0 : data.BodyParts.length <= 0,
										});
									}}
								>
									{InjuryOptions.map((injury) => {
										return <option selected={data.InjuryType === injury ? true : false}>{injury}</option>;
									})}
								</Form.Control>
							</Form.Group>
						</Col>
					</Row>

					{/* body parts injured */}
					<Row>
						<Col md={2}>
							<Form.Label>Body Part Injured (You can select and add multiple body parts) {requiredFields.BodyParts ? "*" : ""}</Form.Label>
						</Col>
						<Col md={4}>
							<Form.Group className={validatedFields.BodyParts && requiredFields.BodyParts && !isValidFields.BodyParts ? "isinvalid" : "isvalid"}>
								<Form.Control as="select" onChange={(evt) => setBodyPart(evt.target.value)}>
									<option selected></option>
									{BodyPartOptions.map((part) => {
										return <option selected={bodyPart === part ? true : false}>{part}</option>;
									})}
								</Form.Control>
							</Form.Group>
						</Col>
						<Col md={2} style={{ textAlign: "start" }}>
							<Button
								style={{ backgroundColor: "var(--primary-blue)" }}
								onClick={() => {
									if (!!bodyPart) {
										var existingParts = [...data.BodyParts];
										if (!existingParts.includes(bodyPart)) {
											existingParts.push(bodyPart);
										}
										onChange({ ...data, BodyParts: existingParts });
										setValidatedFields({ ...validatedFields, BodyParts: true });
										setIsValidFields({ ...isValidFields, BodyParts: isValidFields.InjuryType ? existingParts.length > 0 : existingParts.length == 0 });
									}
								}}
							>
								Add
							</Button>
						</Col>
					</Row>

					{data.BodyParts.map((part) => {
						return (
							<Row>
								<Col md={2}></Col>
								<Col md={8} style={{ top: "-15px" }}>
									&#8618; {part}{" "}
									<Button
										variant="link"
										onClick={() => {
											var newBodyParts = data.BodyParts.filter((item) => item != part);
											onChange({ ...data, BodyParts: newBodyParts });
											setIsValidFields({
												...isValidFields,
												BodyParts: isValidFields.InjuryType ? newBodyParts.length > 0 : newBodyParts.length <= 0,
											});
										}}
									>
										delete
									</Button>
								</Col>
							</Row>
						);
					})}

					{/* wcb claim */}
					<Row>
						<Col md={2}>
							<Form.Label>WCB Claim {requiredFields.WcbClaim ? "*" : ""}</Form.Label>
						</Col>
						<Col md={4}>
							<Form.Group className={validatedFields.WcbClaim && requiredFields.WcbClaim && !isValidFields.WcbClaim ? "isinvalid" : "isvalid"}>
								<Form.Control
									placeholder="wcb claim"
									value={data.WcbClaim}
									onChange={(evt) => {
										onChange({ ...data, WcbClaim: evt.target.value });
										setValidatedFields({ ...validatedFields, WcbClaim: true });
										setIsValidFields({ ...isValidFields, WcbClaim: IsFieldValid(evt.target.value, "text") });
									}}
								/>
							</Form.Group>
						</Col>
					</Row>

					<Separator />

					{/* compnay name */}
					<Row>
						<Col md={2}>
							<Form.Label>Company Name {requiredFields.EmployerCompanyName ? "*" : ""}</Form.Label>
						</Col>
						<Col md={4}>
							<Form.Group className={validatedFields.EmployerCompanyName && requiredFields.EmployerCompanyName && !isValidFields.EmployerCompanyName ? "isinvalid" : "isvalid"}>
								<Form.Control
									placeholder="company name"
									value={data.EmployerCompanyName}
									onChange={(evt) => {
										onChange({ ...data, EmployerCompanyName: evt.target.value });
										setValidatedFields({ ...validatedFields, EmployerCompanyName: true });
										setIsValidFields({ ...isValidFields, EmployerCompanyName: IsFieldValid(evt.target.value, "text") });
									}}
								/>
							</Form.Group>
						</Col>
					</Row>

					{/* employer representative first & last name  */}
					<Row>
						<Col md={2}>
							<Form.Label>Employer Representative First Name {requiredFields.EmployerRepresentativeFirstName ? "*" : ""}</Form.Label>
						</Col>
						<Col md={4}>
							<Form.Group
								className={
									validatedFields.EmployerRepresentativeFirstName && requiredFields.EmployerRepresentativeFirstName && !isValidFields.EmployerRepresentativeFirstName ? "isinvalid" : "isvalid"
								}
							>
								<Form.Control
									placeholder="first name"
									value={data.EmployerRepresentativeFirstName}
									onChange={(evt) => {
										onChange({ ...data, EmployerRepresentativeFirstName: evt.target.value });
										setValidatedFields({ ...validatedFields, EmployerRepresentativeFirstName: true });
										setIsValidFields({ ...isValidFields, EmployerRepresentativeFirstName: IsFieldValid(evt.target.value, "text") });
									}}
								/>
							</Form.Group>
						</Col>
						<Col md={2}>
							<Form.Label>Employer Representative Last Name {requiredFields.EmployerRepresentativeLastName ? "*" : ""}</Form.Label>
						</Col>
						<Col md={4}>
							<Form.Group
								className={validatedFields.EmployerRepresentativeLastName && requiredFields.EmployerRepresentativeLastName && !isValidFields.EmployerRepresentativeLastName ? "isinvalid" : "isvalid"}
							>
								<Form.Control
									placeholder="last name"
									value={data.EmployerRepresentativeLastName}
									onChange={(evt) => {
										onChange({ ...data, EmployerRepresentativeLastName: evt.target.value });
										setValidatedFields({ ...validatedFields, EmployerRepresentativeLastName: true });
										setIsValidFields({ ...isValidFields, EmployerRepresentativeLastName: IsFieldValid(evt.target.value, "text") });
									}}
								/>
							</Form.Group>
						</Col>
					</Row>

					{/* company address line 1 & 2 */}
					<Row>
						<Col md={2}>
							<Form.Label>Address Line 1 {requiredFields.EmployerAddress.StreetAddress1 ? "*" : ""}</Form.Label>
						</Col>
						<Col md={4}>
							<Form.Group
								className={validatedFields.EmployerAddress.StreetAddress1 && requiredFields.EmployerAddress.StreetAddress1 && !isValidFields.EmployerAddress.StreetAddress1 ? "isinvalid" : "isvalid"}
							>
								<Form.Control
									placeholder="address line 1"
									value={data.EmployerAddress.StreetAddress1}
									onChange={(evt) => {
										onChange({
											...data,
											EmployerAddress: {
												...data.EmployerAddress,
												StreetAddress1: evt.target.value,
											},
										});
										setValidatedFields({
											...validatedFields,
											EmployerAddress: {
												...validatedFields.EmployerAddress,
												StreetAddress1: true,
											},
										});
										setIsValidFields({
											...isValidFields,
											EmployerAddress: {
												...isValidFields.EmployerAddress,
												StreetAddress1: IsFieldValid(evt.target.value, "text"),
											},
										});
									}}
								/>
							</Form.Group>
						</Col>
						<Col md={2}>
							<Form.Label>Address Line 2 {requiredFields.EmployerAddress.StreetAddress2 ? "*" : ""}</Form.Label>
						</Col>
						<Col md={4}>
							<Form.Group
								className={validatedFields.EmployerAddress.StreetAddress2 && requiredFields.EmployerAddress.StreetAddress2 && !isValidFields.EmployerAddress.StreetAddress2 ? "isinvalid" : "isvalid"}
							>
								<Form.Control
									placeholder="address line 2"
									value={data.EmployerAddress.StreetAddress2}
									onChange={(evt) => {
										onChange({
											...data,
											EmployerAddress: {
												...data.EmployerAddress,
												StreetAddress2: evt.target.value,
											},
										});
										setValidatedFields({
											...validatedFields,
											EmployerAddress: {
												...validatedFields.EmployerAddress,
												StreetAddress2: true,
											},
										});
										setIsValidFields({
											...isValidFields,
											EmployerAddress: {
												...isValidFields.EmployerAddress,
												StreetAddress2: IsFieldValid(evt.target.value, "text"),
											},
										});
									}}
								/>
							</Form.Group>
						</Col>
					</Row>

					{/* province & city */}
					<Row>
						<Col md={2}>
							<Form.Label>Province {requiredFields.EmployerAddress.Province ? "*" : ""}</Form.Label>
						</Col>
						<Col md={4}>
							<Form.Group className={validatedFields.EmployerAddress.Province && requiredFields.EmployerAddress.Province && !isValidFields.EmployerAddress.Province ? "isinvalid" : "isvalid"}>
								<Form.Control
									placeholder="province"
									value={data.EmployerAddress.Province}
									onChange={(evt) => {
										onChange({
											...data,
											EmployerAddress: {
												...data.EmployerAddress,
												Province: evt.target.value,
											},
										});
										setValidatedFields({
											...validatedFields,
											EmployerAddress: {
												...validatedFields.EmployerAddress,
												Province: true,
											},
										});
										setIsValidFields({
											...isValidFields,
											EmployerAddress: {
												...isValidFields.EmployerAddress,
												Province: IsFieldValid(evt.target.value, "text"),
											},
										});
									}}
								/>
							</Form.Group>
						</Col>
						<Col md={2}>
							<Form.Label>City {requiredFields.EmployerAddress.City ? "*" : ""}</Form.Label>
						</Col>
						<Col md={4}>
							<Form.Group className={validatedFields.EmployerAddress.City && requiredFields.EmployerAddress.City && !isValidFields.EmployerAddress.City ? "isinvalid" : "isvalid"}>
								<Form.Control
									placeholder="city"
									value={data.EmployerAddress.City}
									onChange={(evt) => {
										onChange({
											...data,
											EmployerAddress: {
												...data.EmployerAddress,
												City: evt.target.value,
											},
										});
										setValidatedFields({
											...validatedFields,
											EmployerAddress: {
												...validatedFields.EmployerAddress,
												City: true,
											},
										});
										setIsValidFields({
											...isValidFields,
											EmployerAddress: {
												...isValidFields.EmployerAddress,
												City: IsFieldValid(evt.target.value, "text"),
											},
										});
									}}
								/>
							</Form.Group>
						</Col>
					</Row>

					{/* country & postal code */}
					<Row>
						<Col md={2}>
							<Form.Label>Country {requiredFields.EmployerAddress.Country ? "*" : ""}</Form.Label>
						</Col>
						<Col md={4}>
							<Form.Group className={validatedFields.EmployerAddress.Country && requiredFields.EmployerAddress.Country && !isValidFields.EmployerAddress.Country ? "isinvalid" : "isvalid"}>
								<Form.Control
									as="select"
									onChange={(evt) => {
										onChange({
											...data,
											EmployerAddress: {
												...data.EmployerAddress,
												Country: evt.target.value,
											},
										});
										setValidatedFields({
											...validatedFields,
											EmployerAddress: {
												...validatedFields.EmployerAddress,
												Country: true,
											},
										});
										setIsValidFields({
											...isValidFields,
											EmployerAddress: {
												...isValidFields.EmployerAddress,
												Country: IsFieldValid(evt.target.value, "text"),
											},
										});
									}}
								>
									<option selected></option>
									{CountryList.map((country) => {
										return <option selected={data.EmployerAddress.Country === country ? true : false}>{country}</option>;
									})}
								</Form.Control>
							</Form.Group>
						</Col>
						<Col md={2}>
							<Form.Label>Postal Code {requiredFields.EmployerAddress.PostalCode ? "*" : ""}</Form.Label>
						</Col>
						<Col md={4}>
							<Form.Group className={validatedFields.EmployerAddress.PostalCode && requiredFields.EmployerAddress.PostalCode && !isValidFields.EmployerAddress.PostalCode ? "isinvalid" : "isvalid"}>
								<Form.Control
									placeholder="postal code"
									value={data.EmployerAddress.PostalCode}
									onChange={(evt) => {
										onChange({
											...data,
											EmployerAddress: {
												...data.EmployerAddress,
												PostalCode: evt.target.value,
											},
										});
										setValidatedFields({
											...validatedFields,
											EmployerAddress: {
												...validatedFields.EmployerAddress,
												PostalCode: true,
											},
										});
										setIsValidFields({
											...isValidFields,
											EmployerAddress: {
												...isValidFields.EmployerAddress,
												PostalCode: IsFieldValid(evt.target.value, "text"),
											},
										});
									}}
								/>
							</Form.Group>
						</Col>
					</Row>

					{/* company phone & ext */}
					<Row>
						<Col md={2}>
							<Form.Group>
								<Form.Label>Phone Number {requiredFields.EmployerPhone ? "*" : ""}</Form.Label>
							</Form.Group>
						</Col>
						<Col md={4}>
							<div className={validatedFields.EmployerPhone && requiredFields.EmployerPhone && !isValidFields.EmployerPhone ? "isinvalid" : "isvalid"}>
								<PhoneInput
									placeholder="phone number"
									defaultCountry={"CA"}
									value={formatPhoneNumberIntl(data.EmployerPhone)}
									onChange={(value) => {
										onChange({
											...data,
											EmployerPhone: value,
										});
										setValidatedFields({ ...validatedFields, EmployerPhone: true });
										setIsValidFields({ ...isValidFields, EmployerPhone: IsFieldValid(value, "text") });
									}}
								/>
							</div>
						</Col>
						<Col md={2}>
							<Form.Group className={validatedFields.EmployerPhoneExt && requiredFields.EmployerPhoneExt && !isValidFields.EmployerPhoneExt ? "isinvalid" : "isvalid"}>
								<Form.Control
									placeholder="ext"
									value={data.EmployerPhoneExt}
									onChange={(evt) => {
										onChange({
											...data,
											EmployerPhoneExt: evt.target.value,
										});
										setValidatedFields({ ...validatedFields, EmployerPhoneExt: true });
										setIsValidFields({ ...isValidFields, EmployerPhoneExt: IsFieldValid(evt.target.value, "text") });
									}}
								/>
							</Form.Group>
						</Col>
					</Row>

					{/* allow employer contact */}
					<Row>
						<Col md={8}>
							<Form.Label>May we contact your employer?</Form.Label>
						</Col>
						<Col md={4} style={{ textAlign: "end" }}>
							<ButtonGroup>
								<Button active={data.AllowEmployerContact} onClick={(evt) => onChange({ ...data, AllowEmployerContact: true })}>
									Yes
								</Button>
								<Button active={!data.AllowEmployerContact} onClick={(evt) => onChange({ ...data, AllowEmployerContact: false })}>
									No
								</Button>
							</ButtonGroup>
						</Col>
					</Row>
				</Fragment>
			)}
		</div>
	);
};

const styles = {
	screen: {
		margin: "20px auto",
		maxWidth: "800px",
	},
};

export default WorkRelatedInjury;
