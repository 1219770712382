export default function DisplayPhone(string) {
  if (string) {
    const Numbers = ['0','1','2','3','4','5','6','7','8','9'];
    
    let res = string.split('').filter(num => Numbers.includes(num))
    if (res.length === 10) {
    
      let final = `(${res[0] !== undefined ? res[0] : ''}${res[1] !== undefined ? res[1] : ''}${res[2] !== undefined ? res[2] : ''}) ${res[3] !== undefined ? res[3] : ''}${res[4] !== undefined ? res[4] : ''}${res[5] !== undefined ? res[5] : ''}-${res[6] !== undefined ? res[6] : ''}${res[7] !== undefined ? res[7] : ''}${res[8] !== undefined ? res[8] : ''}${res[9] !== undefined ? res[9] : ''}`;
  
      return final;

    } else {
      return string;
    }
  } else {
    return undefined;
  }
}