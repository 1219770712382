import React from 'react';
import {Container, Col, Row, Button } from 'react-bootstrap';

import "bootstrap/dist/css/bootstrap.min.css";
import "../App.css";
import "../resources/styles/about.scss";

import NavBar from "../components/navbar.component.js";
import Footer from "../components/footer.component.js";

// Custom page meta with react-helmet
import MetaDecorator from "../components/metaDecorator.js";

export default function FourOFour(props) {



    return(

        <div>

            <NavBar />
            <MetaDecorator
                description="Bodiometer Smart biomechanic scanning and analytics for consumers and clinical professionals"
                title="Bodiometer - Page not found"
                url="https://www.bodiometer.com/404"
            />

            {/* <StandardHero tall={false} bg={false}>
                <div>
                    <img src="../../photos/icons/about-blue.svg" width="80" alt="Careers icon" />
                    <h1 className="blue-color">Careers</h1>
                </div>
            </StandardHero> */}

            <section>
                <Container>
                    <Row className="centered-text centered-row">
                        <Col lg={9}>
                          <div>
                              <h2 className="gray-color">Oops, That page wasn't found..</h2>
                              <p>Please check the URL spelling again, or navigate to our home page.</p>
                              <hr/>
                              <br/>
                              <Button size="md" variant="primary" href="/">Home</Button>
                          </div>
                        </Col>
                    </Row>
                </Container>
            </section>

            <Footer />

        </ div>
    );
};
