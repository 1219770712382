// version production
export const dbUrl = "https://bodiometerhomerestapi-dot-project-4839952831808961167.appspot.com";

// version localhost
// export const dbUrl = "http://localhost:54928";

// version 1: 20200524t180709 (sandbox)
// export const dbUrl = "http://20200524t180709.bodiometerhomerestapi.project-4839952831808961167.appspot.com";

// version 2: 20220301t090934 (live)
// export const dbUrl = "http://20220301t090934.bodiometerhomerestapi.project-4839952831808961167.appspot.com";
