import React, { useEffect, useState } from "react";
import { Container, Col, Row, Card, Toast } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import axios from "axios";

import { RetrieveToken } from "../lib/token";
import { dbUrl } from "../lib/config";

import "bootstrap/dist/css/bootstrap.min.css";
import "../App.css";
import "../resources/styles/contact.scss";

import StandardHero from "../components/standard-hero.js";
import NavBar from "../components/navbar.component.js";
import Footer from "../components/footer.component.js";

// Custom page meta with react-helmet
import MetaDecorator from "../components/metaDecorator.js";

export default function Contact(props) {
	const [state, setState] = useState({
		signup: true,
		validated: false,
		showToast: false,
	});

	const [firstName, setFirstName] = useState("");
	const [lastName, setLastName] = useState("");
	const [email, setEmail] = useState("");
	const [comment, setComment] = useState("");
	const [hasSignedUp, setHasSignedUp] = useState(false);
	const [isFormValid, setIsFormValid] = useState(false);

	const hideToast = (e) => {
		setState({
			...state,
			showToast: false,
		});
	};

	const showToast = (e) => {
		setState({
			...state,
			showToast: true,
		});
	};

	const handleChange = (event) => {
		setState({
			...state,
			validated: true,
		});
	};

	useEffect(() => {
		setIsFormValid(!!firstName && !!lastName && !!email && !!comment);
	}, [firstName, lastName, email, comment, hasSignedUp]);

	const SubmitContact = async (evt) => {
		try {
			evt.preventDefault();

			RetrieveToken()
				.then(async (token) => {
					var newContact = {
						firstName,
						lastName,
						email,
						phone: "",
						comment,
						date: new Date().toLocaleString("en-US"),
						hasSignedUp,
					};

					let configTwo = {
						method: "POST",
						url: dbUrl + "/api/email/v1/contact",
						headers: {
							Authorization: `Bearer ${token}`,
							"Content-Type": "application/json",
						},
						data: JSON.stringify(newContact),
					};

					var responseTwo = await axios(configTwo, { timeout: 5000 });

					if (responseTwo.data.success) {
						showToast();
						alert("message delivered successfully");
						window.location.href = "/";
					} else {
						alert("could not deliver");
						window.location.href = "/";
					}
				})
				.catch((_) => {
					alert("could not deliver");
					window.location.href = "/";
				});
		} catch (_) {
			alert("could not deliver");
			window.location.href = "/";
		}
	};

	return (
		<div>
			<NavBar />
			<MetaDecorator
				description="Bodiometer provides smart biomechanic analytics for consumers and clinical professionals to help prevent injury, and track and improve postural health!"
				title="Bodiometer - Smart Body Analytics - Get in Touch"
				url="https://www.bodiometer.com/contact"
			/>

			{/* <StandardHero tall={false}> */}
			<StandardHero tall={false} bg={false}>
				<div>
					<img src="../../photos/icons/chat-blue.svg" width="80" alt="contact us icon" />
					<h1 className="blue-color">Contact Us</h1>
				</div>
			</StandardHero>

			<section className="contact-section-wrapper">
				<Container>
					<Row xs={1} md={1} lg={2}>
						<Col lg={4} md={12}>
							<h2 className="gray-color">Get in Touch</h2>
							<hr className="hr-style-four" />
							<p>
								We love to chat, feel free to get in touch with us by email at:{" "}
								<span>
									<a href="mailto:info@bodiometer.com" rel="noopener noreferrer" target="_blank">
										info@bodiometer.com
									</a>
								</span>
								, or by filling out the following form.
							</p>
						</Col>
						<Col lg={8} md={12} className="contact-form-wrapper">
							<Card variant="dark">
								<Card.Body>
									<Row className="justify-content-center">
										{state.showToast && (
											<Toast onClose={hideToast} show={state.showToast} delay={5000} autohide>
												<Toast.Header className="text-success">
													<strong className="mr-auto">Message Delivered Successfully!!!</strong>
												</Toast.Header>
												<Toast.Body>
													<small>this message will disappear in 5 seconds</small>
												</Toast.Body>
											</Toast>
										)}
									</Row>

									<Form validated={state.validated} onSubmit={SubmitContact}>
										<Form.Row>
											<Form.Group as={Col} controlId="contact_fname">
												<Form.Label>
													First Name <span style={{ color: "red" }}>*</span>
												</Form.Label>
												<Form.Control type="input" placeholder="First Name" value={firstName} onChange={(evt) => setFirstName(evt.target.value)} required />
											</Form.Group>
											<Form.Group as={Col} controlId="contact_lname">
												<Form.Label>
													Last Name <span style={{ color: "red" }}>*</span>
												</Form.Label>
												<Form.Control type="input" placeholder="Last Name" value={lastName} onChange={(evt) => setLastName(evt.target.value)} required />
											</Form.Group>
										</Form.Row>
										<Form.Row>
											<Form.Group as={Col} controlId="contact_email">
												<Form.Label>
													Email <span style={{ color: "red" }}>*</span>
												</Form.Label>
												<Form.Control type="email" placeholder="Email" value={email} onChange={(evt) => setEmail(evt.target.value)} required />
											</Form.Group>
										</Form.Row>

										<Form.Row>
											<Form.Group as={Col} controlId="contact_comment">
												<Form.Label>
													Message <span style={{ color: "red" }}>*</span>
												</Form.Label>
												<Form.Control as="textarea" rows="3" placeholder="Message" value={comment} onChange={(evt) => setComment(evt.target.value)} required />
											</Form.Group>
										</Form.Row>

										<Form.Row>
											<Form.Group controlId="consent" className="form-footer-wrapper">
												<Form.Check
													type="checkbox"
													isValid={hasSignedUp}
													defaultChecked={hasSignedUp}
													onChange={() => setHasSignedUp(!hasSignedUp)}
													label="Sign up for our email list for updates, promotions, and more."
												/>
												<Button disabled={!isFormValid} variant="primary" type="submit" onSubmit={handleChange}>
													Send
												</Button>
											</Form.Group>
										</Form.Row>
									</Form>
								</Card.Body>
							</Card>
						</Col>
					</Row>
				</Container>
			</section>

			<Footer />
		</div>
	);
}
