import React, { useState, useEffect } from 'react';
import "bootstrap/dist/css/bootstrap.min.css";
import "../App.css";
import "../resources/styles/consumerlanding.scss";
import { Carousel, Row, Col, Container, Modal, Button, Accordion, Card, Form } from 'react-bootstrap';
import Fade from 'react-reveal/Fade';
import { useLocation, Link } from 'react-router-dom';

import NavBar from "../components/navbar.component.js";
import Footer from "../components/footer.component.js";
import ConsumerHero from '../components/consumer-hero';
import ScrollingCarousel from '../components/scrolling-carousel';

import MetaDecorator from "../components/metaDecorator.js"; // Helmet for dynamic meta tags in header

import Mailchimp from 'react-mailchimp-form'; // For mailchimp subscribe form

import holidayIcon from "../resources/photos/holiday/holiday-script.svg";
import giftIcon from "../resources/photos/holiday/gifts.svg";

export default function ConsumerLanding() {

  const [state, setState] = useState({
    videoModal: false,
    formModal: false,
    faqKey: 0,
    isHolidayModalVisible: false // holiday modal if needed in the future
  });

  // Apply selected faq class for styling
  const HandleFaqClick = (e) => {

    let element = document.querySelector('.faq-wrapper .card .collapse.show').previousElementSibling;

    if (element.className.includes('active-faq')) {
        element.className = "card-header";
      } else {
        element.className += ' active-faq';
      }

    const element1 = e.target;
    if (element.className.includes('active-faq')) {
      element1.className = "card-header"
    } else {
      element1.className += ' active-faq';
    }

  };

  let location = useLocation();

  // useEffect(() => {
  //   setTimeout(
  //     () => {
  //       try{
  //         let _CHPOPUP = localStorage.getItem("_CHPOPUP");
  //         if(!!!_CHPOPUP){
  //           localStorage.setItem("_CHPOPUP", true);
  //           setState({...state, isHolidayModalVisible: true});
  //         }
  //       }
  //       catch{
  //         localStorage.setItem("_CHPOPUP", true);
  //         setState({...state, isHolidayModalVisible: true});
  //       }
        
  //     }, 5000);

  // }, []);

  useEffect(() => {
    if (location.state) {
      if (location.state.homeFaq) {
        document.getElementById('homeFaq').scrollIntoView();
        location.state.homeFaq = false;
      }
    }
  })

  useEffect(() => {
    if (state.formModal) {
      // const form = 
      document.querySelector('.mailchimp-form-wrapper form').tabIndex = "-1";

      document.querySelector('.mailchimp-form-wrapper form input:nth-child(1)').addEventListener('blur', () => {
        document.querySelector('.mailchimp-form-wrapper form input:nth-child(2)').focus()
        document.querySelector('.mailchimp-form-wrapper form input:nth-child(2)').addEventListener('blur', () => {
          document.querySelector('.mailchimp-form-wrapper form input:nth-child(3)').focus()
        });
      });

    }

  },[state.formModal]);

  const dbUrl = "https://bodiometerhomerestapi-dot-project-4839952831808961167.appspot.com";

  const RegisterEvent = (eventSource) => {
    let myHeaders = new Headers();
    myHeaders.append("password", "746C419785D84DC8B3A20B9195363105");

    let requestOptions = {
      method: 'POST',
      headers: myHeaders,
      redirect: 'follow'
    };

    fetch( dbUrl + "/api/token/dbo", requestOptions)
      .then(response => response.text())
      .then(authKey => {

        let myHeadersOne = new Headers();
        myHeadersOne.append("Authorization", "Bearer " + authKey);
        myHeadersOne.append("Content-Type", "application/json");

        let rawOne = JSON.stringify({
          "eventName": `${eventSource}`,
          "eventTime": `${new Date().toJSON()}`,
          "eventOffset": "0"
        });

        let requestOptionsOne = {
          method: 'POST',
          headers: myHeadersOne,
          body: rawOne,
          redirect: 'follow'
        };

        fetch(dbUrl + "/api/eventtrackers/v1/create", requestOptionsOne)
          .then(responseOne => responseOne.text())
          .then(resultOne => console.log(resultOne))
          .catch(errorOne => console.log('error', errorOne));

      })
      .catch(error => console.log('error', error));
  }

  return(
    <div>
      <MetaDecorator
          description="Bodiometer Home is a Smart Postural Assessment App. Prevent Injury. Track and Improve Your Postural Health. Discover Your Biomechanical Score."
          title="Bodiometer Home - Smart Postural Assessment App"
          url="https://www.bodiometer.com"
      />
      <NavBar />

      {/* Holiday Modal is Hidden Right Now */}
      {/* <Modal
          size="lg"
          show={state.isHolidayModalVisible}
        >
          <Modal.Body style={{padding: "0px"}}>
          
            <Row>
              <Col style={{overflow: "auto", padding: "2.5em 1.8em"}}>
                
                <Row>
                  <Col>
                    <img src={holidayIcon} loading="lazy" alt="" class="holiday-script" />
                    <h3 class="holiday-italic">from the Bodiometer team</h3>
                    <div class="holiday-rule"></div>
                  </Col>
                </Row>

                <Row className='holiday-content'>
                  <Col lg={5}>
                    <h3 class="holiday-heading">Holiday Special!</h3>
                    <p class="holiday-body">Register before Dec. 31, 2021 to claim <del>14 day</del> 30 day Free trial of Bodiometer <a href="/product/dashboard" target='_blank'>patient engagement platform</a>.</p>
                    <a href="/portal/register" target='_blank' onClick={() => {RegisterEvent("popup register button")}} class="holiday-button">Register Now</a>
                  </Col>
                  <Col lg={7}>
                    <img src={giftIcon} loading="lazy" alt="" class="holiday-gift" />
                  </Col>
                </Row>

                <a href="#" onClick={() => setState({...state, isHolidayModalVisible: false})} class="close-link w-inline-block">
                  <div class="x-line"></div>
                  <div class="x-line bottom"></div>
                </a>
                
              </Col>
            </Row>
          </Modal.Body>
        </Modal> */}


      <ConsumerHero />
      <section className="light-grad-convex bg-grad-inverse">
        <Container className="centered-container">
          <div className="title-icon">
            <h2 className="gray-color">Why Track Posture</h2>
            <img src="../../photos/icons/question-gray.svg" width="80" alt="Why track posture? - question mark icon" />
          </div>
          <Carousel className="why-carousel" fade={false} pause="hover" interval={null} nextIcon={<img src="../../photos/consumer-landing/next-arrow.svg" height="27" alt="next carousel item - arrow icon" />}>
            <Carousel.Item>
              <Row xs={1} md={3} lg={3}>
                <Col>
                  <img src="../../photos/consumer-landing/neck.svg" height="80" alt="Prevent and treat neck pain - icon" />
                  <h3 className="green-color">Prevent and treat<br/>neck pain</h3>
                  <hr></hr>
                  <p>60% of people with neck pain have forward head posture, which is a predictive factor for the occurrence of neck pain</p>
                </Col>
                <Col>
                  <img src="../../photos/consumer-landing/breath.svg" height="80" alt="Increase airflow / breathe easy - icon" />
                  <h3 className="green-color">Increase airflow and<br/>breathe easy</h3>
                  <hr></hr>
                  <p>Fixing protracted shoulder posture opens up your chest and can allow for good breathing mechanics and increased oxygen intake</p>
                </Col>
                <Col>
                  <img src="../../photos/consumer-landing/fall.svg" height="80" alt="Decrease falling risks - icon" />
                  <h3 className="green-color">Decrease your risks<br/>of falling</h3>
                  <hr></hr>
                  <p>Forward head posture can negatively affect the body’s sense of joint position and stability increasing the risk of falling</p>
                </Col>
              </Row>
            </Carousel.Item>
            <Carousel.Item>
              <Row xs={1} md={3} lg={3}>
                <Col>
                  <img src="../../photos/consumer-landing/back.svg" height="80" alt="Prevent and treat back pain - icon" />
                  <h3 className="green-color">Prevent and treat<br/>back pain</h3>
                  <hr></hr>
                  <p>Prevent undue stress on your back by tracking and improving your postural score to maintain a healthy spine</p>
                </Col>
                <Col>
                  <img src="../../photos/consumer-landing/spine.svg" height="80" alt="Identify youth muscle imbalance - icon" />
                  <h3 className="green-color">Identify youth<br/>muscle imbalance</h3>
                  <hr></hr>
                  <p>For competitive young athletes it’s important to identify muscle imbalance and skeletal misalignment that may lead to development of painful conditions</p>
                </Col>
                <Col>
                  <img src="../../photos/consumer-landing/longevity.svg" height="80" alt="Increase longevity - icon" />
                  <h3 className="green-color">Increase<br/>longevity</h3>
                  <hr></hr>
                  <p>Tracking postural health can contribute to prevention of hunchback posture which may increase longevity</p>
                </Col>
              </Row>
            </Carousel.Item>
          </Carousel>
        </Container>
      </section>
      <section className="how-wrapper">
        <Container className="centered-container">
            <div className="title-icon">
              <img src="../../photos/icons/mobile-blue.svg" width="80" alt="Bodiometer postural assessment mobile app - how it's done icon" />
              <h2 className="blue-color">How it's Done</h2>
            </div>
            <Row xs={1} md={2}>
              <Col lg={7}>
                <Fade left delay={300}>
                  <img src="../../photos/consumer-landing/how-1.gif" width="100%" alt="Pose in front of the camera - Bodiometer postural assessment how to animation" />
                </Fade>
              </Col>
              <Col lg={5}>
                <article>
                  <h3 className="blue-color">Pose</h3>
                  <p>Pose in front of your mobile device,<br/>first in front view,<br/>then in side view.</p>
                </article>
              </Col>
            </Row>
            <Row xs={1} md={2}>
              <Col lg={5}>
                <article>
                  <h3 className="blue-color">Scan</h3>
                  <p>Bodiometer will assess your<br/>posture from both views in<br/>real-time.</p>
                </article>
              </Col>
              <Col lg={7}>
                <Fade right delay={300}>
                  <img src="../../photos/consumer-landing/how-2.gif" width="100%" alt="Bodiometer will assess your posture - Bodiometer postural assessment how to animation" />
                </Fade>
              </Col>
            </Row>
            <Row xs={1} md={2}>
              <Col lg={7}>
                <Fade left delay={300}>
                  <img src="../../photos/consumer-landing/how-3.gif" width="100%" alt="Keep track of your postual health with assessment progress charts - Bodiometer postural assessment how to animation" />
                </Fade>
              </Col>
              <Col lg={5}>
                <article>
                  <h3 className="blue-color">Track</h3>
                  <p>Keep track of your postural<br/>assessment with<br/>precise analytics.</p>
                </article>
              </Col>
            </Row>
        </Container>
      </section>
      <Container fluid className="on-top">
        <Row xs={1} md={2} noGutters className="video-section-wrapper">
          <Col md={4}>
            <div className="title-icon-stacked">
              <img src="../../photos/icons/video-gray.svg" width="80" alt="See Bodiometer Home in Action - video icon" />
              <h2 className="gray-color">See it<br/>in Action</h2>
            </div>
          </Col>
          <Col md={8} lg={8}>
            <div className="consumer-video-wrapper">
              <img src="../../photos/consumer-landing/video-background-image.png" width="100%" alt="See Bodiometer Home in Action - background image" />          
              <img src="../../photos/icons/play-white.svg" width="150" className="video-play-icon" onClick={() => setState({...state, videoModal: true})} alt="See Bodiometer Home in Action - play icon" />     
            </div>
            <Modal size="xl" className="video-modal-wrapper" centered show={state.videoModal} onHide={() => setState({...state, videoModal: false})}>
              <Modal.Header closeButton>
              </Modal.Header>
              <Modal.Body>
                <video autoPlay={true} loop={true} width="100%" controls>
                  <source src="../../videos/bodiometer-in-action-vr3.mp4" type="video/mp4" />
                </video>
              </Modal.Body>
            </Modal>
          </Col>
        </Row>
      </Container>
      <section className="light-grad-bulge features-wrapper">
        <Container className="centered-container">
          <Row xs={1} md={3}>
            <Col>
              <div className="title-icon">
                <img src="../../photos/icons/real-time.svg" height="35" alt="Bodiometer features - real-time icon" />
                <h3 className="blue-color">Real-time</h3>
              </div>
              <p>Stay engaged with real-time visualization of your posture</p>
            </Col>
            <Col>
              <div className="title-icon">
                <img src="../../photos/icons/mobile-friendly.svg" height="35" alt="Bodiometer features - mobile friendly icon"/>
                <h3 className="blue-color">Mobile Friendly</h3>
              </div>
              <p>Optimized for iPhone and iPad running the latest iOS</p>
            </Col>
            <Col>
              <div className="title-icon">
                <h2 className="green-color">Key<br/>Features</h2>
                <img src="../../photos/icons/check-green.svg" height="80" alt="Bodiometer features - list icon"/>
              </div>
            </Col>
          </Row>
          <Row xs={1} md={3}>
            <Col>
              <div className="title-icon">
                <img src="../../photos/icons/precision.svg" height="35" alt="Bodiometer features - precise measures icon"/>
                <h3 className="blue-color">Precise Measures</h3>
              </div>
              <p>Postural assessments are accurate and consistent</p>
            </Col>
            <Col>
              <div className="title-icon">
                <img src="../../photos/icons/risk-mitigation.svg" height="35" alt="Bodiometer features - risk mitigation icon"/>
                <h3 className="blue-color">Risk Mitigation</h3>
              </div>
              <p>Improve your postural score to minimize your risk of injury</p>
            </Col>
            <Col>
              <div className="title-icon">
                <img src="../../photos/icons/tracking.svg" height="35" alt="Bodiometer features - progress charts icon"/>
                <h3 className="blue-color">Progress Charts</h3>
              </div>
              <p>Stay in control of your postural health with progress charts</p>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="cta-centered consumer-landing-cta">
        <Container className="centered-container">
          <h3 className="gray-color">Ready to improve your postural health?<br/>Download <a rel="noopener noreferrer" href="https://apps.apple.com/us/app/bodiometer-home/id1461395471" target="_blank" className="blue-color" alt="Download Bodiometer Home postual assessment app from the Apple Store - text link">Bodiometer Home</a> today on the Apple Store</h3>
          <hr/>
          <a target="_blank" rel="noopener noreferrer" href="https://apps.apple.com/us/app/bodiometer-home/id1461395471"><img src="../../photos/icons/download-gray.svg" height="60" alt="Download Bodiometer Home postual assessment app from the Apple Store - download button" /></a>
        </Container>
      </section>
      <section className="partners-wrapper">
        <Container>
          <Row xs={1} md={1}>
            <Col md={5}>
              <div className="title-icon">
                <img src="../../photos/icons/handshake-blue.svg" width="80" alt="Bodiometer is used by Leading Healthcare Professionals - handshake icon" />
                <h2 className="blue-color">Used by Leading Healthcare Professionals</h2>
              </div>
            </Col>
            <Col md={7}>
              {/* <Carousel className="scrolling-carousel on-white" fade={false} pause={false} interval={3000} nextIcon={<img src="../../photos/consumer-landing/next-arrow.svg" height="27" alt="next carousel item - arrow icon" />}>
                <Carousel.Item>
                  <Row xs={2} md={2}>
                    <Col>
                      <a href="https://caleohealth.ca/" target="_blank" rel="noopener"><img src="../../photos/consumer-landing/professionals/caleo-health.png" width="100%" alt="Bodiometer partner - Caleo Health logo" /></a> 
                    </Col>
                    <Col>
                      <a href="https://www.momentumhealth.ca/" target="_blank" rel="noopener"><img src="../../photos/consumer-landing/professionals/momentum-health.png" width="100%" alt="Bodiometer partner - Momentum Health logo" /></a>
                    </Col>
                  </Row>
                </Carousel.Item>
                <Carousel.Item>
                  <Row xs={2} md={2}>
                    <Col>
                      <a href="https://www.vitalposture.com/" target="_blank" rel="noopener"><img src="../../photos/consumer-landing/professionals/the-vital-posture-clinic.png" width="100%" alt="Bodiometer partner - The Vital Posture Clinic logo" /></a>
                    </Col>
                    <Col>
                      <a href="https://providencenucca.com/" target="_blank" rel="noopener"><img src="../../photos/consumer-landing/professionals/providence-chiropractic.png" width="100%" alt="Bodiometer partner - Providence Nucca Clinic logo" /></a>
                    </Col>
                  </Row>
                </Carousel.Item>
            </Carousel> */}
            <ScrollingCarousel 
              images={[
                {src: '../../photos/consumer-landing/professionals/caleo-health.png', link: 'https://caleohealth.ca/', alt: 'Bodiometer partner - Caleo Health logo'},
                {src: '../../photos/consumer-landing/professionals/momentum-health.png', link: 'https://www.momentumhealth.ca/', alt: 'Bodiometer partner - Momentum Health logo'},
                {src: '../../photos/consumer-landing/professionals/the-vital-posture-clinic.png', link: 'https://www.vitalposture.com/', alt: 'Bodiometer partner - The Vital Posture Clinic logo'},
                {src: '../../photos/consumer-landing/professionals/providence-chiropractic.png', link: 'https://providencenucca.com/', alt: 'Bodiometer partner - Providence Nucca Clinic logo'},
                {src: '../../photos/consumer-landing/professionals/dr-hipp.png', link: 'https://drhipp.com/', alt: 'Bodiometer partner - Dr. Hipp logo'}
              ]}
            />
            </Col>
          </Row>
          <Row xs={1} md={2} className="for-professionals-wrapper">
            <Col md={6}>
              <Fade left delay={300}> 
                <img src="../../photos/consumer-landing/professionals-graphic.svg" width="100%" alt="Bodiometer for professionals - professional clinician illustration"/>
              </Fade>
            </Col>
            <Col>
              <h3 className="green-color">Biomechanic Tracking Solutions<br/>for Professionals</h3>
              <p>We have professional solutions built for clinicians, ergonomists, and human performance coaches. Find out more about these solutions and how you can get started for free.</p>
              <Link to="/professionals">
                <Button variant="secondary">Learn More</Button>
              </Link>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="light-grad-bulge bg-grad-inverse faq-wrapper" id="homeFaq">
        <Container className="centered-container">
          <div className="title-icon">
            <img src="../../photos/icons/question-blue.svg" width="80" alt="Frequently Asked Questions - question mark icon" />
            <h2 className="blue-color">Frequently Asked Questions</h2>
          </div>
          <Row>
            <Col>
              <Accordion defaultActiveKey="0" onChange={e => setState({...state})}>
                <Card>
                  <Accordion.Toggle as={Card.Header} eventKey="0" onClick={e => HandleFaqClick(e)} className="active-faq">
                    Who can benefit from Bodiometer Home?
                  </Accordion.Toggle>
                  <Accordion.Collapse eventKey="0">
                    <Card.Body>Bodiometer Home is best suited for anyone who is hoping to improve their overall health by keeping track of their posture with postural assessments. And for those who want to reduce their risks of developing health issues that can result from incorrect posture.</Card.Body>
                  </Accordion.Collapse>
                </Card>
                <Card>
                  <Accordion.Toggle as={Card.Header} eventKey="1" onClick={e => HandleFaqClick(e)}>
                    How far away should I stand from the camera?
                  </Accordion.Toggle>
                  <Accordion.Collapse eventKey="1">
                    <Card.Body>Typically about 8 feet away from the camera so that your full body (from head to toe) is visible in the camera view.</Card.Body>
                  </Accordion.Collapse>
                </Card>
                <Card>
                  <Accordion.Toggle as={Card.Header} eventKey="2" onClick={e => HandleFaqClick(e)}>
                    Does Bodiometer Home work for all body types?
                  </Accordion.Toggle>
                  <Accordion.Collapse eventKey="2">
                    <Card.Body>Yes. Our AI algorithm is trained to automatically detect human anatomical landmarks for all body types.</Card.Body>
                  </Accordion.Collapse>
                </Card>
                <Card>
                  <Accordion.Toggle as={Card.Header} eventKey="3" onClick={e => HandleFaqClick(e)}>
                    How can I save my posture data?
                  </Accordion.Toggle>
                  <Accordion.Collapse eventKey="3">
                    <Card.Body>The most recent scan is available from the dashboard. You can upgrade to Bodiometer Home Plus to access your previous scans.</Card.Body>
                  </Accordion.Collapse>
                </Card>
                <Card>
                  <Accordion.Toggle as={Card.Header} eventKey="4" onClick={e => HandleFaqClick(e)}>
                    What does my score mean?
                  </Accordion.Toggle>
                  <Accordion.Collapse eventKey="4">
                    <Card.Body>According to Biomechanical literature, the perfect human’s postural score is 0. Any joint misalignments increase your score, thus the lower your score the better.</Card.Body>
                  </Accordion.Collapse>
                </Card>
                <Card>
                  <Accordion.Toggle as={Card.Header} eventKey="5" onClick={e => HandleFaqClick(e)}>
                    How do I know if I am aligning the device properly?
                  </Accordion.Toggle>
                  <Accordion.Collapse eventKey="5">
                    <Card.Body>The levelling box at the top of the screen turns green when the device is parallel to the person and perpendicular to the floor.</Card.Body>
                  </Accordion.Collapse>
                </Card>
              </Accordion>
            </Col>
          </Row>
        </Container>
      </section>
      <section>
        <Container>
          <Row xs={1} md={2} className="infographic-wrapper">
            <Col md={8} lg={6}>
              <h3 className="blue-color">Subscribe to our newsletter and download our FREE <span className="green-color">Better Posture infographic</span></h3>
              <p>Stay up to date on our latest news, and get access to our Better Posture infographic to help improve your postural health today.</p>

                <Modal size="xl" className="form-modal-wrapper" centered show={state.formModal} onHide={() => setState({...state, formModal: false})}>
                  <Modal.Header closeButton>
                  </Modal.Header>
                  <Modal.Body>
                    <Container className="thin-container mailchimp-form-wrapper">
                      <Row>
                        <Col>
                          <h3 className="gray-color">Subscribe to our Newsletter</h3>
                          <Mailchimp
                            action='https://bodiometer.us5.list-manage.com/subscribe/post?u=32c10cd5759b793dac0d760ad&amp;id=b8b3f041e7'
                            fields={[
                              {
                                name: 'FNAME',
                                placeholder: 'First Name',
                                type: 'text',
                                style: 'position: relative',
                                required: true
                              },
                              {
                                name: 'LNAME',
                                placeholder: 'Last Name',
                                type: 'text',
                                required: true
                              },
                              {
                                name: 'EMAIL',
                                placeholder: 'Email',
                                type: 'email',
                                required: true
                              }
                            ]}
                            messages = {
                              {
                                sending: "Sending...",
                                success: "Thank you for subscribing! Please check your inbox.",
                                error: "An unexpected internal error has occurred.",
                                empty: "You must fill out the fields",
                                duplicate: "Too many subscribe attempts for this email address",
                                button: "Subscribe"
                              }
                            }
                          />
                        </Col>
                      </Row>
                    </Container>
                  </Modal.Body>
                </Modal>
                <Button variant="primary" onClick={() => setState({...state, formModal: true})}>
                  Sign Up
                </Button>
            </Col>
            <Col md={4} lg={6}>
              <Fade right delay={300}>
                <img src="../../photos/consumer-landing/infographic-graphic.svg" width="100%" alt="Bodiometer Better Posture - infographic illustration" /> 
              </Fade>
            </Col>
          </Row>
        </Container>
      </section>
      <Footer />
      {/* <!-- Start of HubSpot Embed Code --> */}
        <script type="text/javascript" id="hs-script-loader" async defer src="//js.hs-scripts.com/2936832.js"></script>
      {/* <!-- End of HubSpot Embed Code --> */}
    </div>
  );
};