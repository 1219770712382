import React, { useState, Fragment, useRef, useEffect } from "react";
import { Col, Row, Form, Container, InputGroup } from "react-bootstrap";
import PhoneInput, { formatPhoneNumberIntl } from "react-phone-number-input";
import Datetime from "react-datetime";
import moment from "moment";

import { CountryList } from "./CountryList";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-phone-number-input/style.css";
import "react-datetime/css/react-datetime.css";
import "../Assets/style.scss";

const Personal = ({ data, requiredDb, validatedDb, isValidDb, pageCompleted, setPageCompleted, activeIndex, viewInvalidFields, onChange }) => {
	const dateTimePickerRef = useRef(null);

	const [validatedFields, setValidatedFields] = useState({ ...validatedDb });
	const [isValidFields, setIsValidFields] = useState({ ...isValidDb });
	const requiredFields = { ...requiredDb };

	const ChangeWeightLbs = (evt) => {
		var lbs = evt.target.value;
		var rgx = /^\d*\.?\d*$/;
		if (!!lbs && lbs.match(rgx)) {
			onChange({ ...data, WeightLbs: lbs, WeightKgs: (parseFloat(lbs) / 2.205).toFixed(1) });
		} else {
			onChange({ ...data, WeightLbs: lbs, WeightKgs: lbs });
		}
	};

	const ChangeWeightKgs = (evt) => {
		var kgs = evt.target.value;
		var rgx = /^\d*\.?\d*$/;
		if (!!kgs && kgs.match(rgx)) {
			onChange({ ...data, WeightLbs: (parseFloat(kgs) * 2.205).toFixed(1), WeightKgs: kgs });
		} else {
			onChange({ ...data, WeightLbs: kgs, WeightKgs: kgs });
		}
	};

	const ChangeHeightFt = (evt) => {
		var ft = evt.target.value;
		var rgx = /^\d*$/;
		if (ft.match(rgx)) {
			var inch = data.HeightInch;
			var nft = !!ft ? parseFloat(ft) : 0;
			inch = !!inch ? parseFloat(inch) : 0;
			var inches = nft * 12 + inch;
			onChange({
				...data,
				HeightFt: ft,
				HeightMeter: (inches / 39.37).toFixed(1),
			});
		}
	};

	const ChangeHeightInch = (evt) => {
		var inch = evt.target.value;
		var rgx = /^\d*$/;
		var ft = data.HeightFt;
		if (inch.match(rgx)) {
			ft = !!ft ? parseFloat(ft) : 0;
			inch = !!inch ? parseFloat(inch) : 0;
			var inches = ft * 12 + inch;
			onChange({
				...data,
				HeightInch: inch,
				HeightMeter: (inches / 39.37).toFixed(1),
			});
		}
	};

	const ChangeHeightMeter = (evt) => {
		var meter = evt.target.value;
		var rgx = /^\d*\.?\d*$/;
		if (meter.match(rgx)) {
			var nmeter = !!meter ? parseFloat(meter) : 0;
			var inches = nmeter * 39.37;
			var ft = Math.floor(inches / 12);
			var inch = Math.round(inches) % 12;
			onChange({ ...data, HeightMeter: meter, HeightFt: ft, HeightInch: inch });
		}
	};

	const Separator = () => {
		return <div style={{ width: "100%", height: "1px", background: "var(--primary-gradient)", marginTop: "25px", marginBottom: "25px", position: "relative" }}></div>;
	};

	const Header = ({ label }) => {
		return <h2 style={{ textAlign: "center", marginBottom: "20px" }}>{label}</h2>;
	};

	const IsFieldValid = (str, type) => {
		if (type == "text") {
			return !!str;
		} else if (type == "email") {
			return !!str && str.toLowerCase().match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
		}
		return true;
	};

	const CheckIfFieldsAreValid = () => {
		var isValid = true;
		isValid = isValid && (requiredFields.Salutation ? isValidFields.Salutation : true);
		isValid = isValid && (requiredFields.FirstName ? isValidFields.FirstName : true);
		isValid = isValid && (requiredFields.LastName ? isValidFields.LastName : true);
		isValid = isValid && (requiredFields.Email ? isValidFields.Email : true);
		isValid = isValid && (requiredFields.Address.Country ? isValidFields.Address.Country : true);
		isValid = isValid && (requiredFields.Address.Province ? isValidFields.Address.Province : true);
		isValid = isValid && (requiredFields.Address.City ? isValidFields.Address.City : true);
		isValid = isValid && (requiredFields.Address.StreetAddress1 ? isValidFields.Address.StreetAddress1 : true);
		isValid = isValid && (requiredFields.Address.StreetAddress2 ? isValidFields.Address.StreetAddress2 : true);
		isValid = isValid && (requiredFields.Address.PostalCode ? isValidFields.Address.PostalCode : true);
		isValid = isValid && (requiredFields.HomePhone ? isValidFields.HomePhone : true);
		isValid = isValid && (requiredFields.HomePhoneExt ? isValidFields.HomePhoneExt : true);
		isValid = isValid && (requiredFields.CellPhone ? isValidFields.CellPhone : true);
		isValid = isValid && (requiredFields.CellPhoneExt ? isValidFields.CellPhoneExt : true);
		isValid = isValid && (requiredFields.WorkPhone ? isValidFields.WorkPhone : true);
		isValid = isValid && (requiredFields.WorkPhoneExt ? isValidFields.WorkPhoneExt : true);
		isValid = isValid && (requiredFields.DateOfBirth ? isValidFields.DateOfBirth : true);
		isValid = isValid && (requiredFields.Gender ? isValidFields.Gender : true);
		isValid = isValid && (requiredFields.Handedness ? isValidFields.Handedness : true);
		isValid = isValid && (requiredFields.WeightLbs ? isValidFields.WeightLbs : true);
		isValid = isValid && (requiredFields.WeightKgs ? isValidFields.WeightKgs : true);
		isValid = isValid && (requiredFields.HeightFt ? isValidFields.HeightFt : true);
		isValid = isValid && (requiredFields.HeightInch ? isValidFields.HeightInch : true);
		isValid = isValid && (requiredFields.HeightMeter ? isValidFields.HeightMeter : true);
		isValid = isValid && (requiredFields.PersonalHealthNumber ? isValidFields.PersonalHealthNumber : true);
		isValid = isValid && (requiredFields.Occupation ? isValidFields.Occupation : true);
		isValid = isValid && (requiredFields.ReferredBy ? isValidFields.ReferredBy : true);
		return isValid;
	};

	const InitialFieldsValidity = () => {
		var cIsValid = { ...isValidFields };
		cIsValid.Salutation = IsFieldValid(data.Salutation, "text");
		cIsValid.FirstName = IsFieldValid(data.FirstName, "text");
		cIsValid.LastName = IsFieldValid(data.LastName, "text");
		cIsValid.Email = IsFieldValid(data.Email, "email");
		cIsValid.Address.Country = IsFieldValid(data.Address.Country, "text");
		cIsValid.Address.City = IsFieldValid(data.Address.City, "text");
		cIsValid.Address.Province = IsFieldValid(data.Address.Province, "text");
		cIsValid.Address.StreetAddress1 = IsFieldValid(data.Address.StreetAddress1, "text");
		cIsValid.Address.StreetAddress2 = IsFieldValid(data.Address.StreetAddress2, "text");
		cIsValid.Address.PostalCode = IsFieldValid(data.Address.PostalCode, "text");
		cIsValid.HomePhone = IsFieldValid(data.HomePhone, "text");
		cIsValid.HomePhoneExt = IsFieldValid(data.HomePhoneExt, "text");
		cIsValid.CellPhone = IsFieldValid(data.CellPhone, "text");
		cIsValid.CellPhoneExt = IsFieldValid(data.CellPhoneExt, "text");
		cIsValid.WorkPhone = IsFieldValid(data.WorkPhone, "text");
		cIsValid.WorkPhoneExt = IsFieldValid(data.WorkPhoneExt, "text");
		cIsValid.DateOfBirth = IsFieldValid(data.DateOfBirth, "text");
		cIsValid.Gender = IsFieldValid(data.Gender, "text");
		cIsValid.Handedness = IsFieldValid(data.Handedness, "text");
		cIsValid.WeightLbs = IsFieldValid(data.WeightLbs, "text");
		cIsValid.WeightKgs = IsFieldValid(data.WeightKgs, "text");
		cIsValid.HeightFt = IsFieldValid(data.HeightFt, "text");
		cIsValid.HeightInch = IsFieldValid(data.HeightInch, "text");
		cIsValid.HeightMeter = IsFieldValid(data.HeightMeter, "text");
		cIsValid.PersonalHealthNumber = IsFieldValid(data.PersonalHealthNumber, "text");
		cIsValid.Occupation = IsFieldValid(data.Occupation, "text");
		cIsValid.ReferredBy = IsFieldValid(data.ReferredBy, "text");
		return cIsValid;
	};

	useEffect(() => {
		pageCompleted[activeIndex] = CheckIfFieldsAreValid();
		setPageCompleted([...pageCompleted]);
	}, [isValidFields]);

	useEffect(() => {
		setIsValidFields({ ...InitialFieldsValidity() });
	}, []);

	useEffect(() => {
		const iterate = (obj) => {
			Object.keys(obj).forEach((key) => {
				if (typeof obj[key] === "object" && obj[key] !== null) {
					iterate(obj[key]);
				} else {
					obj[key] = true;
				}
			});
		};
		if (viewInvalidFields) {
			window.scrollTo({
				top: "0",
				behavior: "smooth",
			});
			var newObj = { ...validatedFields };
			iterate(newObj);
			setValidatedFields({ ...newObj });
		}
	}, [viewInvalidFields]);

	return (
		<div className="intake" style={styles.screen}>
			<Container fluid>
				<Header label={"Personal Information"} />

				{/* Salutation */}
				<Row>
					<Col md={2}>
						<Form.Label>Salutation {requiredFields.Salutation ? "*" : ""}</Form.Label>
					</Col>
					<Col md={4}>
						<Form.Group className={validatedFields.Salutation && requiredFields.Salutation && !isValidFields.Salutation ? "isinvalid" : "isvalid"}>
							<Form.Control
								as="select"
								onChange={(evt) => {
									onChange({ ...data, Salutation: evt.target.value });
									setValidatedFields({ ...validatedFields, Salutation: true });
									setIsValidFields({ ...isValidFields, Salutation: IsFieldValid(evt.target.value, "text") });
								}}
							>
								<option selected></option>
								<option selected={data.Salutation === "Mr." ? true : false}>Mr.</option>
								<option selected={data.Salutation === "Mrs." ? true : false}>Mrs.</option>
								<option selected={data.Salutation === "Dr." ? true : false}>Dr.</option>
								<option selected={data.Salutation === "Ms." ? true : false}>Ms.</option>
								<option selected={data.Salutation === "Miss" ? true : false}>Miss</option>
								<option selected={data.Salutation === "Sir" ? true : false}>Sir</option>
							</Form.Control>
						</Form.Group>
					</Col>
				</Row>

				{/* First Name & Last Name */}
				<Row>
					<Col md={2}>
						<Form.Label>First Name {requiredFields.FirstName ? "*" : ""}</Form.Label>
					</Col>
					<Col md={4}>
						<Form.Group className={validatedFields.FirstName && requiredFields.FirstName && !isValidFields.FirstName ? "isinvalid" : "isvalid"}>
							<Form.Control
								placeholder="first name"
								value={data.FirstName}
								onChange={(evt) => {
									onChange({ ...data, FirstName: evt.target.value });
									setValidatedFields({ ...validatedFields, FirstName: true });
									setIsValidFields({ ...isValidFields, FirstName: IsFieldValid(evt.target.value, "text") });
								}}
							/>
						</Form.Group>
					</Col>
					<Col md={2}>
						<Form.Label>Last Name {requiredFields.LastName ? "*" : ""}</Form.Label>
					</Col>
					<Col md={4}>
						<Form.Group className={validatedFields.LastName && requiredFields.LastName && !isValidFields.LastName ? "isinvalid" : "isvalid"}>
							<Form.Control
								placeholder="last name"
								value={data.LastName}
								onChange={(evt) => {
									onChange({ ...data, LastName: evt.target.value });
									setValidatedFields({ ...validatedFields, LastName: true });
									setIsValidFields({ ...isValidFields, LastName: IsFieldValid(evt.target.value, "text") });
								}}
							/>
						</Form.Group>
					</Col>
				</Row>

				{/* Email */}
				<Row>
					<Col md={2}>
						<Form.Label>Email {requiredFields.Email ? "*" : ""}</Form.Label>
					</Col>
					<Col md={4}>
						<Form.Group className={validatedFields.Email && requiredFields.Email && !isValidFields.Email ? "isinvalid" : "isvalid"}>
							<Form.Control
								placeholder="email"
								value={data.Email}
								onChange={(evt) => {
									onChange({ ...data, Email: evt.target.value });
									setValidatedFields({ ...validatedFields, Email: true });
									setIsValidFields({ ...isValidFields, Email: IsFieldValid(evt.target.value, "email") });
								}}
							/>
						</Form.Group>
					</Col>
				</Row>

				<Separator />

				{/* Address Line 1 & 2 */}
				<Row>
					<Col md={2}>
						<Form.Label>Address Line 1 {requiredFields.Address.StreetAddress1 ? "*" : ""}</Form.Label>
					</Col>
					<Col md={4}>
						<Form.Group className={validatedFields.Address.StreetAddress1 && requiredFields.Address.StreetAddress1 && !isValidFields.Address.StreetAddress1 ? "isinvalid" : "isvalid"}>
							<Form.Control
								placeholder="address line 1"
								value={data.Address.StreetAddress1}
								onChange={(evt) => {
									onChange({
										...data,
										Address: {
											...data.Address,
											StreetAddress1: evt.target.value,
										},
									});
									setValidatedFields({
										...validatedFields,
										Address: {
											...validatedFields.Address,
											StreetAddress1: true,
										},
									});

									setIsValidFields({
										...isValidFields,
										Address: {
											...isValidFields.Address,
											StreetAddress1: IsFieldValid(evt.target.value, "text"),
										},
									});
								}}
							/>
						</Form.Group>
					</Col>
					<Col md={2}>
						<Form.Label>Address Line 2 {requiredFields.Address.StreetAddress2 ? "*" : ""}</Form.Label>
					</Col>
					<Col md={4}>
						<Form.Group className={validatedFields.Address.StreetAddress2 && requiredFields.Address.StreetAddress2 && !isValidFields.Address.StreetAddress2 ? "isinvalid" : "isvalid"}>
							<Form.Control
								placeholder="address line 2"
								value={data.Address.StreetAddress2}
								onChange={(evt) => {
									onChange({
										...data,
										Address: {
											...data.Address,
											StreetAddress2: evt.target.value,
										},
									});
									setValidatedFields({
										...validatedFields,
										Address: {
											...validatedFields.Address,
											StreetAddress2: true,
										},
									});

									setIsValidFields({
										...isValidFields,
										Address: {
											...isValidFields.Address,
											StreetAddress2: IsFieldValid(evt.target.value, "text"),
										},
									});
								}}
							/>
						</Form.Group>
					</Col>
				</Row>

				{/* Province & City */}
				<Row>
					<Col md={2}>
						<Form.Label>Province {requiredFields.Address.Province ? "*" : ""}</Form.Label>
					</Col>
					<Col md={4}>
						<Form.Group className={validatedFields.Address.Province && requiredFields.Address.Province && !isValidFields.Address.Province ? "isinvalid" : "isvalid"}>
							<Form.Control
								placeholder="province"
								value={data.Address.Province}
								onChange={(evt) => {
									onChange({
										...data,
										Address: {
											...data.Address,
											Province: evt.target.value,
										},
									});
									setValidatedFields({
										...validatedFields,
										Address: {
											...validatedFields.Address,
											Province: true,
										},
									});

									setIsValidFields({
										...isValidFields,
										Address: {
											...isValidFields.Address,
											Province: IsFieldValid(evt.target.value, "text"),
										},
									});
								}}
							/>
						</Form.Group>
					</Col>
					<Col md={2}>
						<Form.Label>City {requiredFields.Address.City ? "*" : ""}</Form.Label>
					</Col>
					<Col md={4}>
						<Form.Group className={validatedFields.Address.City && requiredFields.Address.City && !isValidFields.Address.City ? "isinvalid" : "isvalid"}>
							<Form.Control
								placeholder="city"
								value={data.Address.City}
								onChange={(evt) => {
									onChange({
										...data,
										Address: {
											...data.Address,
											City: evt.target.value,
										},
									});
									setValidatedFields({
										...validatedFields,
										Address: {
											...validatedFields.Address,
											City: true,
										},
									});
									setIsValidFields({
										...isValidFields,
										Address: {
											...isValidFields.Address,
											City: IsFieldValid(evt.target.value, "text"),
										},
									});
								}}
							/>
						</Form.Group>
					</Col>
				</Row>

				{/* Country & Postal Code */}
				<Row>
					<Col md={2}>
						<Form.Label>Country {requiredFields.Address.Country ? "*" : ""}</Form.Label>
					</Col>
					<Col md={4}>
						<Form.Group className={validatedFields.Address.Country && requiredFields.Address.Country && !isValidFields.Address.Country ? "isinvalid" : "isvalid"}>
							<Form.Control
								as="select"
								onChange={(evt) => {
									onChange({
										...data,
										Address: {
											...data.Address,
											Country: evt.target.value,
										},
									});
									setValidatedFields({
										...validatedFields,
										Address: {
											...validatedFields.Address,
											Country: true,
										},
									});
									setIsValidFields({
										...isValidFields,
										Address: {
											...isValidFields.Address,
											Country: IsFieldValid(evt.target.value, "text"),
										},
									});
								}}
							>
								<option selected></option>
								{CountryList.map((country) => {
									return <option selected={data.Address.Country === country ? true : false}>{country}</option>;
								})}
							</Form.Control>
						</Form.Group>
					</Col>
					<Col md={2}>
						<Form.Label>Postal Code {requiredFields.Address.PostalCode ? "*" : ""}</Form.Label>
					</Col>
					<Col md={4}>
						<Form.Group className={validatedFields.Address.PostalCode && requiredFields.Address.PostalCode && !isValidFields.Address.PostalCode ? "isinvalid" : "isvalid"}>
							<Form.Control
								placeholder="postal code"
								value={data.Address.PostalCode}
								onChange={(evt) => {
									onChange({
										...data,
										Address: {
											...data.Address,
											PostalCode: evt.target.value,
										},
									});
									setValidatedFields({
										...validatedFields,
										Address: {
											...validatedFields.Address,
											PostalCode: true,
										},
									});
									setIsValidFields({
										...isValidFields,
										Address: {
											...isValidFields.Address,
											PostalCode: IsFieldValid(evt.target.value, "text"),
										},
									});
								}}
							/>
						</Form.Group>
					</Col>
				</Row>

				{/* Home Phone */}
				<Row>
					<Col md={2}>
						<Form.Group>
							<Form.Label>Home Phone {requiredFields.HomePhone ? "*" : ""}</Form.Label>
						</Form.Group>
					</Col>
					<Col md={4}>
						<div className={validatedFields.HomePhone && requiredFields.HomePhone && !isValidFields.HomePhone ? "isinvalid" : "isvalid"}>
							<PhoneInput
								placeholder="phone number"
								defaultCountry={"CA"}
								value={formatPhoneNumberIntl(data.HomePhone)}
								onChange={(value) => {
									onChange({
										...data,
										HomePhone: value,
									});
									setValidatedFields({ ...validatedFields, HomePhone: true });
									setIsValidFields({ ...isValidFields, HomePhone: IsFieldValid(value, "text") });
								}}
							/>
						</div>
					</Col>
					<Col md={2}>
						<Form.Group className={validatedFields.HomePhoneExt && requiredFields.HomePhoneExt && !isValidFields.HomePhoneExt ? "isinvalid" : "isvalid"}>
							<Form.Control
								placeholder="ext"
								value={data.HomePhoneExt}
								onChange={(evt) => {
									onChange({
										...data,
										HomePhoneExt: evt.target.value,
									});
									setValidatedFields({ ...validatedFields, HomePhoneExt: true });
									setIsValidFields({ ...isValidFields, HomePhoneExt: IsFieldValid(evt.target.value, "text") });
								}}
							/>
						</Form.Group>
					</Col>
				</Row>

				{/* Cell Phone */}
				<Row>
					<Col md={2}>
						<Form.Group>
							<Form.Label>Cell Phone {requiredFields.CellPhone ? "*" : ""}</Form.Label>
						</Form.Group>
					</Col>
					<Col md={4}>
						<div className={validatedFields.CellPhone && requiredFields.CellPhone && !isValidFields.CellPhone ? "isinvalid" : "isvalid"}>
							<PhoneInput
								placeholder="cellphone number"
								defaultCountry={"CA"}
								value={formatPhoneNumberIntl(data.CellPhone)}
								onChange={(value) => {
									onChange({
										...data,
										CellPhone: value,
									});
									setValidatedFields({ ...validatedFields, CellPhone: true });
									setIsValidFields({ ...isValidFields, CellPhone: IsFieldValid(value, "text") });
								}}
							/>
						</div>
					</Col>
					<Col md={2}>
						<Form.Group className={validatedFields.CellPhoneExt && requiredFields.CellPhoneExt && !isValidFields.CellPhoneExt ? "isinvalid" : "isvalid"}>
							<Form.Control
								placeholder="ext"
								value={data.CellPhoneExt}
								onChange={(evt) => {
									onChange({
										...data,
										CellPhoneExt: evt.target.value,
									});
									setValidatedFields({ ...validatedFields, CellPhoneExt: true });
									setIsValidFields({ ...isValidFields, CellPhoneExt: IsFieldValid(evt.target.value, "text") });
								}}
							/>
						</Form.Group>
					</Col>
				</Row>

				{/* Work Phone */}
				<Row>
					<Col md={2}>
						<Form.Group>
							<Form.Label>Work Phone {requiredFields.WorkPhone ? "*" : ""}</Form.Label>
						</Form.Group>
					</Col>
					<Col md={4}>
						<div className={validatedFields.WorkPhone && requiredFields.WorkPhone && !isValidFields.WorkPhone ? "isinvalid" : "isvalid"}>
							<PhoneInput
								placeholder="homephone number"
								defaultCountry={"CA"}
								value={formatPhoneNumberIntl(data.WorkPhone)}
								onChange={(value) => {
									onChange({
										...data,
										WorkPhone: value,
									});
									setValidatedFields({ ...validatedFields, WorkPhone: true });
									setIsValidFields({ ...isValidFields, WorkPhone: IsFieldValid(value, "text") });
								}}
							/>
						</div>
					</Col>
					<Col md={2}>
						<Form.Group className={validatedFields.WorkPhoneExt && requiredFields.WorkPhoneExt && !isValidFields.WorkPhoneExt ? "isinvalid" : "isvalid"}>
							<Form.Control
								placeholder="ext"
								value={data.WorkPhoneExt}
								onChange={(evt) => {
									onChange({
										...data,
										WorkPhoneExt: evt.target.value,
									});
									setValidatedFields({ ...validatedFields, WorkPhoneExt: true });
									setIsValidFields({ ...isValidFields, WorkPhoneExt: IsFieldValid(evt.target.value, "text") });
								}}
							/>
						</Form.Group>
					</Col>
				</Row>

				<Separator />

				{/* Date of Birth & Gender */}
				<Row>
					<Col md={2}>
						<Form.Label>Date of birth (MM/DD/YYYY) {requiredFields.DateOfBirth ? "*" : ""}</Form.Label>
					</Col>
					<Col md={4}>
						<div className={validatedFields.DateOfBirth && requiredFields.DateOfBirth && !isValidFields.DateOfBirth ? "isinvalid" : "isvalid"}>
							<Datetime
								initialValue={!!data.DateOfBirth ? moment(new Date(data.DateOfBirth).toISOString().substring(0, 10)).format("MM/DD/YYYY") : ""}
								onChange={(evt) => {
									onChange({
										...data,
										DateOfBirth: moment(evt._d).format("MM/DD/YYYY"),
									});
									setValidatedFields({ ...validatedFields, DateOfBirth: true });
									setIsValidFields({ ...isValidFields, DateOfBirth: IsFieldValid(moment(evt._d).format("MM/DD/YYYY"), "text") });
								}}
								dateFormat="MM/DD/YYYY"
								timeFormat={false}
								ref={dateTimePickerRef}
								closeOnSelect
							/>
						</div>
					</Col>
					<Col md={2}>
						<Form.Label>Gender {requiredFields.Gender ? "*" : ""}</Form.Label>
					</Col>
					<Col md={4}>
						<Form.Group className={validatedFields.Gender && requiredFields.Gender && !isValidFields.Gender ? "isinvalid" : "isvalid"}>
							<Form.Control
								as="select"
								onChange={(evt) => {
									onChange({ ...data, Gender: evt.target.value });
									setValidatedFields({ ...validatedFields, Gender: true });
									setIsValidFields({ ...isValidFields, Gender: IsFieldValid(evt.target.value, "text") });
								}}
							>
								<option selected></option>
								<option selected={data.Gender.toLowerCase() === "male" ? true : false}>Male</option>
								<option selected={data.Gender.toLowerCase() === "female" ? true : false}>Female</option>
								<option selected={data.Gender.toLowerCase() === "other" ? true : false}>Other</option>
							</Form.Control>
						</Form.Group>
					</Col>
				</Row>

				{/* Handedness */}
				<Row>
					<Col md={2}>
						<Form.Label>Handedness {requiredFields.Handedness ? "*" : ""}</Form.Label>
					</Col>
					<Col md={4}>
						<Form.Group className={validatedFields.Handedness && requiredFields.Handedness && !isValidFields.Handedness ? "isinvalid" : "isvalid"}>
							<Form.Control
								as="select"
								onChange={(evt) => {
									onChange({ ...data, Handedness: evt.target.value });
									setValidatedFields({ ...validatedFields, Handedness: true });
									setIsValidFields({ ...isValidFields, Handedness: IsFieldValid(evt.target.value, "text") });
								}}
							>
								<option value={-1} selected={data.Handedness == -1 ? true : false}></option>
								<option value={0} selected={data.Handedness == 0 ? true : false}>
									Right-Handed
								</option>
								<option value={1} selected={data.Handedness == 1 ? true : false}>
									Left-Handed
								</option>
								<option value={2} selected={data.Handedness == 2 ? true : false}>
									Cross-Dominant / Mixed-Handed
								</option>
								<option value={3} selected={data.Handedness == 3 ? true : false}>
									Ambidexter
								</option>
							</Form.Control>
						</Form.Group>
					</Col>
				</Row>

				{/* Weight */}
				<Row>
					<Col md={2}>
						<Form.Label>Weight (lbs) {requiredFields.WeightLbs ? "*" : ""}</Form.Label>
					</Col>
					<Col md={4}>
						<Form.Group className={validatedFields.WeightLbs && requiredFields.WeightLbs && !isValidFields.WeightLbs ? "isinvalid" : "isvalid"}>
							<Form.Control
								placeholder="lbs"
								value={data.WeightLbs}
								onChange={(evt) => {
									ChangeWeightLbs(evt);
									setValidatedFields({ ...validatedFields, WeightLbs: true });
									setIsValidFields({ ...isValidFields, WeightLbs: IsFieldValid(evt.target.value, "text") });
								}}
							/>
						</Form.Group>
					</Col>
					<Col md={2}>
						<Form.Label>Weight (Kgs) {requiredFields.WeightKgs ? "*" : ""}</Form.Label>
					</Col>
					<Col md={4}>
						<Form.Group className={validatedFields.WeightKgs && requiredFields.WeightKgs && !isValidFields.WeightKgs ? "isinvalid" : "isvalid"}>
							<Form.Control
								placeholder="kgs"
								value={data.WeightKgs}
								onChange={(evt) => {
									ChangeWeightKgs(evt);
									setValidatedFields({ ...validatedFields, WeightKgs: true });
									setIsValidFields({ ...isValidFields, WeightKgs: IsFieldValid(evt.target.value, "text") });
								}}
							/>
						</Form.Group>
					</Col>
				</Row>

				{/* Height */}
				<Row>
					<Col md={2}>
						<Form.Label>Height (Ft) {requiredFields.HeightFt ? "*" : ""}</Form.Label>
					</Col>
					<Col md={2}>
						<Form.Group className={validatedFields.HeightFt && requiredFields.HeightFt && !isValidFields.HeightFt ? "isinvalid" : "isvalid"}>
							<Form.Control
								placeholder="ft(s)"
								value={data.HeightFt}
								onChange={(evt) => {
									ChangeHeightFt(evt);
									setValidatedFields({ ...validatedFields, HeightFt: true });
									setIsValidFields({ ...isValidFields, HeightFt: IsFieldValid(evt.target.value, "text") });
								}}
							/>
						</Form.Group>
					</Col>
					<Col md={2}>
						<Form.Group className={validatedFields.HeightInch && requiredFields.HeightInch && !isValidFields.HeightInch ? "isinvalid" : "isvalid"}>
							<Form.Control
								placeholder="inch(s)"
								value={data.HeightInch}
								onChange={(evt) => {
									ChangeHeightInch(evt);
									setValidatedFields({ ...validatedFields, HeightInch: true });
									setIsValidFields({ ...isValidFields, HeightInch: IsFieldValid(evt.target.value, "text") });
								}}
							/>
						</Form.Group>
					</Col>
					<Col md={2}>
						<Form.Label>Height (Meter) {requiredFields.HeightMeter ? "*" : ""}</Form.Label>
					</Col>
					<Col md={4}>
						<Form.Group className={validatedFields.HeightMeter && requiredFields.HeightMeter && !isValidFields.HeightMeter ? "isinvalid" : "isvalid"}>
							<Form.Control
								placeholder="meter(s)"
								value={data.HeightMeter}
								onChange={(evt) => {
									ChangeHeightMeter(evt);
									setValidatedFields({ ...validatedFields, HeightMeter: true });
									setIsValidFields({ ...isValidFields, HeightMeter: IsFieldValid(evt.target.value, "text") });
								}}
							/>
						</Form.Group>
					</Col>
				</Row>

				{/* Personal Health Number & Occupation */}
				<Row>
					<Col md={2}>
						<Form.Label>Personal Health Number {requiredFields.PersonalHealthNumber ? "*" : ""}</Form.Label>
					</Col>
					<Col md={4}>
						<Form.Group className={validatedFields.PersonalHealthNumber && requiredFields.PersonalHealthNumber && !isValidFields.PersonalHealthNumber ? "isinvalid" : "isvalid"}>
							<Form.Control
								placeholder="personal health number"
								value={data.PersonalHealthNumber}
								onChange={(evt) => {
									onChange({ ...data, PersonalHealthNumber: evt.target.value });
									setValidatedFields({ ...validatedFields, PersonalHealthNumber: true });
									setIsValidFields({ ...isValidFields, PersonalHealthNumber: IsFieldValid(evt.target.value, "text") });
								}}
							/>
						</Form.Group>
					</Col>
					<Col md={2}>
						<Form.Label>Occupation {requiredFields.Occupation ? "*" : ""}</Form.Label>
					</Col>
					<Col md={4}>
						<Form.Group className={validatedFields.Occupation && requiredFields.Occupation && !isValidFields.Occupation ? "isinvalid" : "isvalid"}>
							<Form.Control
								placeholder="occupation"
								value={data.Occupation}
								onChange={(evt) => {
									onChange({ ...data, Occupation: evt.target.value });
									setValidatedFields({ ...validatedFields, Occupation: true });
									setIsValidFields({ ...isValidFields, Occupation: IsFieldValid(evt.target.value, "text") });
								}}
							/>
						</Form.Group>
					</Col>
				</Row>

				{/* Referred by */}
				<Row>
					<Col md={2}>
						<Form.Label>Referred By {requiredFields.ReferredBy ? "*" : ""}</Form.Label>
					</Col>
					<Col md={4}>
						<Form.Group className={validatedFields.ReferredBy && requiredFields.ReferredBy && !isValidFields.ReferredBy ? "isinvalid" : "isvalid"}>
							<Form.Control
								placeholder="referred by"
								value={data.ReferredBy}
								onChange={(evt) => {
									onChange({ ...data, ReferredBy: evt.target.value });
									setValidatedFields({ ...validatedFields, ReferredBy: true });
									setIsValidFields({ ...isValidFields, ReferredBy: IsFieldValid(evt.target.value, "text") });
								}}
							/>
						</Form.Group>
					</Col>
				</Row>
			</Container>
		</div>
	);
};

const styles = {
	screen: {
		margin: "20px auto",
		maxWidth: "800px",
	},
};

export default Personal;
