import React, { useState, useEffect, Fragment } from "react";
import { Container, Col, Row, Alert, Spinner } from "react-bootstrap";
import CryptoJS from "crypto-js";
import axios from "axios";

import LandingRegister from "./landing-register";
import LandingRegisterBusiness from "./landing-register-business";
import LandingLogin from "./landing-login";
import PasswordReset from "./password-reset";

import UiButton from "../components/dashboard/ui-button.js";
import SideNav from "../components/dashboard/sidenav.js";
import TopNav from "../components/dashboard/top-nav.js";
import CreateOrganization from "../components/dashboard/create-organization.js";
import EditOrganization from "../components/dashboard/edit-organization.js";
import Overview from "../components/dashboard/overview.js";
import PatientList from "../components/dashboard/patient-list";
import License from "../components/dashboard/license.js";
import Profile from "../components/dashboard/profile.js";
import Roles from "../components/dashboard/roles.js";
import Help from "../components/dashboard/help.js";
import Security from "../components/dashboard/security.js";
import Notifications from "../components/dashboard/notifications.js";
import { dbUrl } from "../lib/config";
import { RetrieveToken } from "../lib/token";
import { LoadBusinessUserCommonLoginData, LoadOrganizationLogo, LoadPaypalAccessToken, PaypalApiUrl } from "../lib/scripts";

import CouponTool from "../components/dashboard/coupon-tool.js";

import "../resources/styles/dashboard.scss";
import "bootstrap/dist/css/bootstrap.min.css";

const Application = (props) => {
	const calculateOffset = (offset) => {
		if (offset > 0) {
			return -(offset / 60);
		} else {
			return offset / 60;
		}
	};

	const initialStateValue = {
		Refresh: 1,
		Today: new Date().toLocaleString("en-US"),
		TodayOffset: calculateOffset(new Date().getTimezoneOffset()),
		DBAccessToken: "",
		AccessToken: "",
		Preloader: false,
		View: props.view ? props.view : "login",
		MainAlert: [false, ""],
		ActiveOrganization: null,
		ActiveOrganizationLogo: null,
		Organizations: [],
		Types: ["Chiropractic", "General Practitioner", "Physical Therapy", "Fitness Training", "Massage Therapy", "Occupational Therapy", "Athletic Therapy", "Osteopathy", "Naturopathy", "Other"],
		Titles: ["Title", "Dr.", "Mr.", "Mrs.", "Miss"],
		Collapsed: false,
		User: null,
		Users: null,
		Notifications: [],
		FreeTrial: null,
		FreeTrialLength: props.freeTrialLength,
		OwnerRole: false, // if true, they can't have any more trial accounts
		BodioAdmin: false,
		Org: {},
		Plans: [
			// {
			//   id: 1,
			//   name: 'Bodiometer Desktop',
			//   features: {includes: ["Unlimited number of clinicians / users", "Unlimited number of patients", "Automatic markerless motion tracking technology", "Automatic posture assessment", "Track patient progress over time", "Generate & send progress reports", "70+ range of motion tests", "Posture endurance testing", "Custom functional movement analysis"], excludes: []},
			//   price: 16000,
			//   logo: '../photos/products/desktop.svg'      },
			{
				id: 2,
				name: "Bodiometer Pro",
				features: {
					includes: ["unlimited posture", "dashboard", "unlimited dynamic posture (gait)", "track patient progress over time", "generate & send progress reports"],
					excludes: ["connection to the Bodiometer Home Dashboard"],
				},
				price: 99,
				logo: "../photos/products/pro.svg",
			},
		],
		Addons: [
			// Can add multiple addons
			// {
			// 	id: 1,
			// 	name: "Dashboard",
			// 	features: { includes: ["view patient analytics", "view patient clinical scans", "view patient home scans"], excludes: [] },
			// 	price: 60,
			// 	logo: null,
			// },
			// {
			// 	id: 2,
			// 	name: "Functional Questionnaire",
			// 	features: { includes: ["record functional progress", "view functional preogress chart", "add / remove / modify functional assessments"], excludes: [] },
			// 	price: 20,
			// 	logo: null,
			// },
			{
				id: 3,
				name: "Home Monitoring",
				features: {
					includes: ["posure at home - with connection to the Bodiometer Home Dashboard", "view functional questionnaire (connection to Bodiometer Home Dashboard)", "digital intake", "forceplate"],
					excludes: [],
				},
				price: 75,
				logo: null,
			},
		],
		LicenseDefaults: {
			maxPatients: [
				// only for dashboard addon
				1000, 2000, 3000, 4000, 5000,
			],
			maxUsers: [
				[5, 0],
				[10, 5000],
				[20, 15000],
			],
			coupons: [
				{
					DISCOUNT10: {
						type: "percent",
						value: 10,
						expiry: "05/01/2031",
						cycles: 1,
						interval: "MONTH",
					},
				},
				{
					DISCOUNT5050: {
						type: "amount",
						value: 50,
						expiry: "05/01/2031",
						cycles: 1,
						interval: "MONTH",
					},
				},
				{
					DISCOUNT9999: {
						type: "percent",
						value: 99,
						expiry: "05/01/2031",
						cycles: 1,
						interval: "MONTH",
					},
				},
				{
					JPEFRVUBCD: {
						type: "percent", // "amount" or "percent"
						value: 100, // in CAD Dollars or percent value
						expiry: "08/27/2021", // date the coupon will expire. format: MM/DD/YEAR
						cycles: 2, // how many times the discounted rate will run before renewing at normal rate
						interval: "MONTH", // 'MONTH' or 'YEAR'
						useCount: 0, // how many times the coupon has been used
						maxUseCount: 1, // if 1, can only be used once, if 10, can only be used 10 times..
					},
				},
				{
					PLXF7VAK3Z: {
						type: "percent", // "amount" or "percent"
						value: 50, // in CAD dollars or percent value
						expiry: "09/27/2021", // date the coupon will expire. format: MM/DD/YEAR
						cycles: 3, // how many times the discounted rate will run before renewing at normal rate
						interval: "MONTH", // 'MONTH' or 'YEAR'
						useCount: 0, // how many times the coupon has been used
						maxUseCount: 1, // if 1, can only be used once, if 10, can only be used 10 times..
					},
				},
			],
		},
		BussinessAccountRegisterSuccessAlert:false,
		BussinessAccountRegisterFailureAlert:false
	};

	const AssignInitialStateValue = () => {
		var propsView = props.view ? props.view : "login";
		try {
			if (localStorage.getItem("_LD") != null) {
				let jsonStr = CryptoJS.AES.decrypt(localStorage.getItem("_LD"), "7LF0MN9XR5-9JOAOZ451A").toString(CryptoJS.enc.Utf8);
				let parsedData = JSON.parse(jsonStr);
				let { rawData, timeStamp } = parsedData;
				if (Math.abs(timeStamp - new Date().getTime()) < 23 * 60 * 60 * 1000) {
					return { ...initialStateValue, ...rawData, View: "overview", Preloader: false };
				} else {
					return { ...initialStateValue, View: propsView };
				}
			} else {
				return { ...initialStateValue, View: propsView };
			}
		} catch {
			return { ...initialStateValue, View: propsView };
		}
	};

	const [state, setState] = useState(AssignInitialStateValue());

	const setThing = (thing) => {
		if (thing === "Notifications") {
			return (value) => {
				const data = state.Notifications.filter((item) => item.id !== value);
				setState({ ...state, Notifications: data });
			};
		} else if (thing === "ActiveOrganization") {
			return (value) => {
				const org = state.Organizations.filter((org) => org.id === value)[0];
				setState({ ...state, ActiveOrganization: org });
			};
		} else {
			return (value) => setState({ ...state, [thing]: value });
		}
	};

	// First access token check
	useEffect(() => {
		var propsView = props.view ? props.view : "login";
		try {
			if (localStorage.getItem("_LD") != null) {
				var jsonStr = CryptoJS.AES.decrypt(localStorage.getItem("_LD"), "7LF0MN9XR5-9JOAOZ451A").toString(CryptoJS.enc.Utf8);
				var parsedData = JSON.parse(jsonStr);
				var { rawData, timeStamp } = parsedData;
				if (Math.abs(timeStamp - new Date().getTime()) < 23 * 60 * 60 * 1000) {
					setState({ ...state, ...rawData, View: "overview", Preloader: false });
				} else {
					// RefreshData();
					setState({ ...state, View: propsView });
					// FetchDbAccessToken({...state});
				}
			} else {
				// RefreshData();
				setState({ ...state, View: propsView });
				// FetchDbAccessToken({...state});
			}
		} catch {
			// RefreshData();
			setState({ ...state, View: propsView });
			// FetchDbAccessToken({...state});
		}

		

	}, []);

	// MAIN FETCH

	const RefreshData = async () => {
		setState({ ...state, Preloader: true });

		var promiseOne = RetrieveToken();
		var promiseTwo = LoadPaypalAccessToken();

		var responseValues = await Promise.all([promiseOne, promiseTwo]);

		var dbAccessToken = "Bearer " + responseValues[0];
		var payPalToken = responseValues[1];

		if (!!payPalToken) {
			var paypalAccessToken = "Bearer " + payPalToken;
			var orgLogo = null;

			var activeOrgId = "";
			var localOrgId = localStorage.getItem("_DO");
			var freeTrial = null;
			if(state.Organizations.length > 0 && !!localOrgId){
				var orgList = state.Organizations.filter((org) => org.id === localOrgId);
				if(orgList.length > 0){
					activeOrgId = localOrgId;
					freeTrial = orgList[0].freeTrial;
				}
				else{
					activeOrgId = state.ActiveOrganization?.id ?? "";
					freeTrial = state.ActiveOrganization?.freeTrial ?? null;
				}
			}
			else{
				activeOrgId = state.ActiveOrganization?.id ?? "";
				freeTrial = state.ActiveOrganization?.freeTrial ?? null;
			}

			if(!!activeOrgId){
				localStorage.setItem("_DO", activeOrgId);
			}

			var orgLogo = null;

			try{
				var logoResponse = await LoadOrganizationLogo(dbAccessToken, activeOrgId);

				orgLogo = logoResponse.data;
			}
			catch {

			}

			var promiseFour = LoadBusinessUserCommonLoginData(
				dbAccessToken,
				paypalAccessToken,
				PaypalApiUrl,
				"userId",
				"userid",
				state.User.userId,
				activeOrgId
			);

			try{

				var responseFour = await LoadBusinessUserCommonLoginData(
					dbAccessToken,
					paypalAccessToken,
					PaypalApiUrl,
					"userId",
					"userid",
					state.User.userId,
					activeOrgId
				);

				var localData = { ...responseFour };

				var freeTrial = state.Organizations.length > 0 ? state.Organizations.filter((org) => org.id === activeOrgId)[0].freeTrial : null;

				setState({ ...state, ...localData, ActiveOrganizationLogo: orgLogo, AccessToken: paypalAccessToken, DBAccessToken: dbAccessToken, FreeTrial: freeTrial, Preloader: false });
			}
			catch {
				setState({ ...state, Preloader: false });
			}
		} else {
			setState({ ...state, Preloader: false });
		}
	};

	useEffect(() => {
		try {
			if (state.User && state.Refresh >= 1) {
				RefreshData();
			}
		} catch {}
	}, [state.Refresh]);

	useEffect(() => {
		var stateView = "";
		switch (state.View) {
			case "login":
				stateView = "login";
				break;
			case "password_reset":
				stateView = "Password Reset";
				break;
			case "register":
				stateView = "register";
				break;
			case "registerIntoBusiness":
				stateView = "Business Registration";
				break;
			default:
				stateView = "login";
		}
		document.title = `Bodiometer - ${stateView[0].toUpperCase() + stateView.substring(1)}`;
	}, [state.View]);

	return (
		<Fragment>
			{state.Preloader && (
				<div className="preloader-wrapper">
					<Spinner animation="grow" variant="secondary" />
				</div>
			)}

			{!state.User && state.View === "login" && <LandingLogin dbUrl={dbUrl} parentState={state} setState={setState} setView={setThing("View")} />}

			{!state.User && state.View === "password_reset" && <PasswordReset dbUrl={dbUrl} parentState={state} setState={setState} setView={setThing("View")} />}

			{!state.User && state.View === "register" && <LandingRegister dbUrl={dbUrl} titles={state.Titles} parentState={state} setState={setState} setView={setThing("View")} />}
			{!state.User && state.View === "registerIntoBusiness" && <LandingRegisterBusiness dbUrl={dbUrl} titles={state.Titles} parentState={state} setState={setState} setView={setThing("View")} />}

			{state.User && (
				<Fragment>
					<Alert variant="success" className="main-alert" show={state.BussinessAccountRegisterSuccessAlert}>
						<p>Business account registered successfully. Please, check your inbox to confirm email.</p>
					</Alert>
					<Alert variant="warning" className="main-alert" show={state.BussinessAccountRegisterFailureAlert}>
						<p>Business account registration failed!!!</p>
					</Alert>
					<Alert variant="warning" className="main-alert" show={!state.MainAlert[0]}>
						<div className="flex-row flex-center">
							<p className="no-margin">{state.MainAlert[1]} at {state.User.email}</p>
						</div>
						<div className="flex-row flex-center">
							<p>Didn't receive the confirmation email? </p>
						</div>
						<div className="flex-row flex-center">
							<UiButton size={'sm'} variant={'secondary'} onClick={() => {
								const pRPostHeaders = {
									headers: {
									  Authorization: state.DBAccessToken,
									  "Content-Type": "application/json",
									},
								};

								const pRPostData = {
									userId: state.User.id,
								};
						  
								axios.post(dbUrl + "/api/email/v2/businessusers/confirmation", pRPostData, pRPostHeaders).then((res) => {
									// email sent!
									setState({...state, MainAlert: [true, ""]});
								});
							}}>resend</UiButton>
						</div>
					</Alert>
					<div className="application-wrapper">
						<SideNav
							setState={setState}
							parentState={state}
							collapsed={state.Collapsed}
							setCollapsed={setThing("Collapsed")}
							view={state.View}
							setView={setThing("View")}
							ActiveOrganization={state.ActiveOrganization && state.ActiveOrganization.id}
							setActiveOrganization={setThing("ActiveOrganization")}
							Organizations={state.Organizations}
						/>
						<main>
							{state.BodioAdmin && state.View === "adminTools" && (
								<Fragment>
									<section>
										<Row>
											<Col>
												<h2>Admin Tools</h2>
												<p>This is page is only viewable by bodiometer staff.</p>

												<CouponTool dbUrl={dbUrl} parentState={state} setState={setState} />
											</Col>
										</Row>
									</section>
								</Fragment>
							)}

							{state.View === "overview" && state.Organizations.length === 0 && !state.ActiveOrganization && state.User.emailActivated && (
								<section>
									<Container className="centered-container centered-text centered-ui-wrapper">
										<h1 className="blue-color">Welcome, {state.User.fname}</h1>
										<h5>
											You haven’t created an organization yet,
											<br />
											create one now and claim your <em className="blue-color">free {state.FreeTrialLength} day trial!</em>
										</h5>
										<div className="duel-buttons-wrapper">
											<UiButton onClick={(e) => setState({ ...state, View: "create-organization" })} plus variant="secondary">
												New Organization
											</UiButton>
											{/* <span>or</span>
												<UiButton onClick={(e) => setState({...state, View: "join-organization"})} variant="outline-secondary">
													Join Organization
												</UiButton> 
											*/}
										</div>
									</Container>
								</section>
							)}
							
							{state.View === "overview" && state.Organizations.length === 0 && Object.keys(state.ActiveOrganization).length == 0 && !state.User.emailActivated && (
								<section>
									<Container className="centered-container centered-text centered-ui-wrapper">
										<h1 className="blue-color">Welcome, {state.User.fname}</h1>
										<br />
										<h5>
											Please verify your email address by clicking on the link
											<br />
											that has been sent by email to {state.User.email}.
											<br />
											<hr></hr>
											Once verified, you can create your organization account
											<br />
											and claim your <em className="blue-color">free {state.FreeTrialLength} day trial!</em>
										</h5>
									</Container>
								</section>
							)}


							{state.View === "overview" && state.Organizations.length === 0 && Object.keys(state.ActiveOrganization).length == 0 && state.User.emailActivated && (
								<section>
									<Container className="centered-container centered-text centered-ui-wrapper">
										<h1 className="blue-color">Welcome, {state.User.fname}</h1>
										<br />
										<h5>
											You can create your organization account
											<br />
											and claim your free {state.FreeTrialLength} day trial! by clicking on the <em className="blue-color">Select Clinic</em> button and 
											<br />
											then clicking on the <em className="blue-color">New Organization +</em> button
										</h5>
									</Container>
								</section>
							)}

							{state.View === "create-organization" && (
								<section className="landing-form-section-wrapper">
									<UiButton close onClick={(e) => setState({ ...state, View: "overview" })} variant="link" className="no-padding gray-color exit-link">
										Cancel
									</UiButton>
									<Container className="centered-container centered-text">
										<CreateOrganization dbUrl={dbUrl} parentState={state} setState={setState} setView={setThing("View")} types={state.Types} />
									</Container>
								</section>
							)}

							{state.View === "edit-organization" && (
								<div>
									<UiButton close onClick={(e) => setState({ ...state, View: "overview" })} variant="link" className="no-padding gray-color exit-link">
										Cancel
									</UiButton>
									<EditOrganization
										dbUrl={dbUrl}
										parentState={state}
										setState={setState}
										organization={state.ActiveOrganization}
										user={state.User}
										view={state.View}
										setView={setThing("View")}
										types={state.Types}
									/>
									{/* <EditOrganization dbUrl={"http://localhost:54928"} parentState={state} setState={setState} organization={state.ActiveOrganization} user={state.User} view={state.View} setView={setThing("View")} types={state.Types} /> */}
								</div>
							)}

							{/* {state.View === "join-organization" && <JoinOrganization dbUrl={dbUrl} parentState={state} setState={setState} />} */}

							{state.View === "overview" && state.Organizations.length > 0 && state.ActiveOrganization && (
								<Overview
									dbUrl={dbUrl}
									parentState={state}
									setState={setState}
									plans={state.Plans}
									users={state.Users}
									setUsers={setThing("Users")}
									freeTrial={state.FreeTrial}
									Organizations={state.Organizations}
									ActiveOrganizationId={state.ActiveOrganization && state.ActiveOrganization.id}
									ActiveOrganization={state.ActiveOrganization}
									view={state.View}
									setView={setThing("View")}
								/>
							)}

							{state.View === "dashboard" && state.Organizations.length > 0 && state.ActiveOrganization && (
								<PatientList
									dbUrl={dbUrl}
									parentState={state}
									setState={setState}
									plans={state.Plans}
									users={state.Users}
									setUsers={setThing("Users")}
									freeTrial={state.FreeTrial}
									Organizations={state.Organizations}
									ActiveOrganizationId={state.ActiveOrganization && state.ActiveOrganization.id}
									ActiveOrganization={state.ActiveOrganization}
									view={state.View}
									setView={setThing("View")}
								/>
							)}
							{/* {state.View === "dashboard" && state.Organizations.length > 0 && state.ActiveOrganization && <PatientList dbUrl={"http://localhost:54928"} parentState={state} setState={setState} plans={state.Plans} users={state.Users} setUsers={setThing("Users")} freeTrial={state.FreeTrial} Organizations={state.Organizations} ActiveOrganizationId={state.ActiveOrganization && state.ActiveOrganization.id} ActiveOrganization={state.ActiveOrganization} view={state.View} setView={setThing("View")} />} */}

							{state.View === "license" && (
								<License
									dbUrl={dbUrl}
									parentState={state}
									setState={setState}
									addons={state.Addons}
									plans={state.Plans}
									licenseDefaults={state.LicenseDefaults}
									users={state.Users}
									setUsers={setThing("Users")}
									freeTrial={state.FreeTrial}
									Organizations={state.Organizations}
									ActiveOrganization={state.ActiveOrganization}
									view={state.View}
									setView={setThing("View")}
								/>
							)}

							{state.View === "success" && <h2>Success!</h2>}

							{state.View === "roles" && <Roles dbUrl={dbUrl} parentState={state} setState={setState} titles={state.Titles} user={state.User} view={state.View} setView={setThing("View")} />}

							{state.View === "help" && <Help parentState={state} setState={setState} view={state.View} setView={setThing("View")} />}

							{state.View === "profile" && <Profile dbUrl={dbUrl} parentState={state} setState={setState} titles={state.Titles} user={state.User} view={state.View} setView={setThing("View")} />}

							{state.View === "security" && <Security dbUrl={dbUrl} parentState={state} setState={setState} user={state.User} view={state.View} setView={setThing("View")} />}

							{state.View === "notifications" && <Notifications user={state.User} view={state.View} setView={setThing("View")} />}
						</main>
						<TopNav
							freeTrial={state.FreeTrial}
							notifications={state.Notifications}
							setNotifications={setThing("Notifications")}
							view={state.View}
							setView={setThing("View")}
							user={state.User}
							setUser={setThing("User")}
							ActiveOrganization={state.ActiveOrganization}
							Organizations={state.Organizations}
							parentState={state}
							setState={setState}
						/>
					</div>
				</Fragment>
			)}
		</Fragment>
	);
};

export default Application;
