import React from 'react';
import { Alert }  from 'react-bootstrap';

import UiButton from "./ui-button.js";

import "../../resources/styles/dashboard.scss";
import "bootstrap/dist/css/bootstrap.min.css";

export default function UiAlert(props) {

  return(
    <>
      <Alert variant={props.Alert.variant ? props.Alert.variant : 'info'} show={props.Alert.show}>
        <div className="flex-row space-between">
          <p>{props.Alert.message ? props.Alert.message : ''}</p>
          {props.children && !props.Alert.message && 
            props.children
          }
          <div>
            {props.Alert.cta &&
              <UiButton
                onClick={e => props.Alert.cta.onClickAction(e)}
                variant={props.Alert.variant === "danger" ? "danger" : "primary"}
              >
                {props.Alert.cta.message}
              </UiButton>
            }

            {props.Alert.cancel &&
              <UiButton
                onClick={e => props.Alert.cancel.onClickCancel(e)}
                variant="link"
                className="gray-color no-padding"
              >
                {props.Alert.cancel.message}
              </UiButton>
            }
          </div>
        </div>
      </Alert>
    </>
  );

};