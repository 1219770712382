import React, {useState, useEffect} from 'react';
import { PayPalButton } from "react-paypal-button-v2";
import {Container, Col, Row, Button, Image} from 'react-bootstrap';
import MediaQuery from 'react-responsive';

import '../resources/styles/checkout.scss';

import NavBar from "../components/navbar.component.js";
import Footer from "../components/footer.component.js";

import cartEmptyImg from "../resources/photos/checkout/cart_empty.png";
import paymentSuccessImg from "../resources/photos/checkout/payment_success.png";

import prodImgLite from "../resources/photos/products/lite.jpg";
import prodImgMobile from "../resources/photos/products/mobile.jpg";
import prodImgDesktop from "../resources/photos/products/desktop.jpg";

import store from 'local-storage';

// Custom page meta with react-helmet
import MetaDecorator from "../components/metaDecorator.js";

export default function Cart(props) {

    const [state, setState] = useState({
        isPayPalEnabled : false,
        cart: false,
        paymentSuccess: false,
        product_list : [
            {
                name : "Bodiometer " + "Lite",
                description: "Bodiometer Lite provides curious users the option to test out the basic function of the app without a subscription. The core functionality is the ability to rapidly take a posture scan, produce a report and show the report to the patient/client. This version is scaled down but is useful as a demonstration tool and for quick assessment. Bodiometer Lite is also the first version available on iPhone.",
                image: prodImgLite,
                quantity: 0,
                price: 1200,
                price_per_month: 100,
                price_per_year: 1200,
                sale_ongoing: false,
                sale_occasion: "Black Friday",
                sale_price_per_month: 50,
                sale_price_per_year: 600,
                plan: 'M', // M: Monthly, Y: Yearly
                n_months: 1, // number of months if monthly subscription is selected

            },
            {
                name : "Bodiometer " + "Pro",
                description: "As the world's first real-time posture assessment app, Bodiometer Pro enables clinicians to quickly and accurately assess patient posture without markers or other devices in almost any setting. Bodiometer utilizes an AI-driven computer vision system to automatically extract patient skeletal data relying only on the camera of the mobile device. Patient scans are saved in app, enabling users to track progress or changes in their posture over the course of treatment. Clinicians can compare scans directly with comparative reports. These visual reports are easy to print or share with the patient as a pdf. These reports makes a huge difference in patient education and engagement which means better outcomes for everyone.",
                image: prodImgMobile,
                quantity: 0,
                price: 800,
                price_per_month: 80,
                price_per_year: 800,
                sale_ongoing: true,
                sale_occasion: "Black Friday",
                sale_price_per_month: 65,
                sale_price_per_year: 650,
                plan: 'M', // M: Monthly, Y: Yearly
                n_months: 1, // number of months if monthly subscription is selected
            },
            {
                name : "Bodiometer " + "Desktop",
                description: "Bodiometer Desktop is our most advanced clinical biomechanics assessment tool. Using markerless motion-tracking, you can quickly and accurately measure range of motion, posture, and functional movement patterns. Our range of motion module allows you to measure 14 different joints in a variety of different movements. Our posture module allows you to quickly measure posture in the coronal, sagittal and traverse planes. Lastly, our functional movement module allows you to measure any kind of functional movement you can think of and compare a patient’s performance over time/  Bodiometer allows you to track patient data easily, and show a patient their progress with engaging and informative progress reports. Bodiometer gives you the tools to collect accurate, objective data quickly, and educate your patients about the structure of their bodies.",
                image: prodImgDesktop,
                quantity: 0,
                price: 1550,
                price_per_month: 155,
                price_per_year: 1550,
                sale_ongoing: false,
                sale_occasion: "Black Friday",
                sale_price_per_month: 155,
                sale_price_per_year: 1550,
                plan: 'M', // M: Monthly, Y: Yearly
                n_months: 1, // number of months if monthly subscription is selected
            }
        ],  

        currency: 'Canadian Dollar',
        currencyAbbr: 'CAD',
        currencySymbol: 'C$',
        
        totalAmount: 0,

        encryptionKey: 'secured information of the bodiometer product page',
    });

    const EncryptedObject = (obj) => {
        // loading encrytion module
        var CryptoJS = require("crypto-js");
        var ciphertext = CryptoJS.AES.encrypt(JSON.stringify(obj), state.encryptionKey);
        return ciphertext.toString();
    }

    const DecryptObject = (obj) => {
        // loading decryption module
        var CryptoJS = require("crypto-js");
        var bytes  = CryptoJS.AES.decrypt(obj, state.encryptionKey);
        var decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
        return decryptedData;
    }

    useEffect(() => {
        if(store.get('product_list')){
            const prodList = DecryptObject(store.get('product_list'));
            if(prodList){
                let status = false;
                let sum = 0;
                prodList.forEach( (x) => {
                    if(x.sale_ongoing){
                        if(x.plan == 'M'){
                            let fractionSaved = 0;
                            if(x.n_months > 1){
                                fractionSaved = (x.sale_price_per_month * 12 - x.sale_price_per_year) * (x.n_months / 12) * x.quantity;
                            }
                            
                            sum = x.quantity * x.sale_price_per_month * x.n_months - fractionSaved + sum;
                        }
                        else{
                            sum = x.quantity * x.sale_price_per_year + sum;
                        }
        
                    }
                    else{
                        if(x.plan == 'M'){
                            let fractionSaved = 0;
                            if(x.n_months > 1){
                                fractionSaved = (x.price_per_month * 12 - x.price_per_year) * (x.n_months / 12) * x.quantity;
                            }
                            sum = x.quantity * x.price_per_month * x.n_months - fractionSaved + sum;
                        }
                        else{
                            sum = x.quantity * x.price_per_year + sum;
                        }
                        
                    }
                    
                });
                    
                if(sum > 0){
                    status = true;
                }

                setState({
                    ...state,
                    product_list : prodList,
                    cart : status,
                    totalAmount: sum
                });
            }
        }
        
        
        
    },[])

    // Attempt to add GA tracking to success page (not working currently)

    // useEffect(() => {
    //     const container = document.querySelector('#successContainer');
    //     if (container !== null) {
    //         window.onload = () => {
    //             ga('send', 'event', { eventCategory: 'purchase', eventAction: 'success', eventLabel: 'bodiometerForProfessionals', eventValue: 1});
    //         }
    //     }
    // }, [state.paymentSuccess])


    const RemoveProduct = (e) => {
        var prodList = state.product_list;
        const prodName = e.target.value;
        prodList.forEach(
            (x) => {
                if(x.name === prodName){
                    x.quantity = 0;
                }
            }
        ) 

        var status = false;

        prodList.forEach((e) =>{
            if(e.quantity > 0){
                status = true;
            }
        });
        
        store.set('product_list', EncryptedObject(prodList));
        setState({
            ...state,
            product_list: prodList,
            cart: status
        });
        
    }

    const ComputeTotal = () => {
        let sum = 0;
        state.product_list.forEach( (x) => {
            if(x.sale_ongoing){
                if(x.plan == 'M'){
                    let fractionSaved = 0;
                    if(x.n_months > 1){
                        fractionSaved = (x.sale_price_per_month * 12 - x.sale_price_per_year) * (x.n_months / 12) * x.quantity;
                    }
                    
                    sum = x.quantity * x.sale_price_per_month * x.n_months - fractionSaved + sum;
                }
                else{
                    sum = x.quantity * x.sale_price_per_year + sum;
                }

            }
            else{
                if(x.plan == 'M'){
                    let fractionSaved = 0;
                    if(x.n_months > 1){
                        fractionSaved = (x.price_per_month * 12 - x.price_per_year) * (x.n_months / 12) * x.quantity;
                    }
                    sum = x.quantity * x.price_per_month * x.n_months - fractionSaved + sum;
                }
                else{
                    sum = x.quantity * x.price_per_year + sum;
                }
                
            }
            
        });
        return sum;
        
    }
    
    const DisplaySubscriptionName = (x) => {
        if(x.plan == 'M'){
            if(x.n_months == 1){
                return (
                    <>
                        Monthly License
                    </>
                );
            }
            else{
                return (
                    <>
                            {x.n_months} Months License
                    </>
                );
            }
        }
        else{
            return (
                <>
                        Annual License
                </>
            );
        }
    }

    const DisplaySubTotal = (x) => {
        
        if(x.sale_ongoing){
            if(x.plan == 'M'){
                let fractionSaved = 0;
                if(x.n_months > 1){
                    fractionSaved = (x.sale_price_per_month * 12 - x.sale_price_per_year) * (x.n_months / 12) * x.quantity;
                }
                return(
                    <>
                        {(x.sale_price_per_month * x.quantity * x.n_months - fractionSaved).toFixed(1)}
                    </>
                );
            }
            else{
                
                return(
                    <>
                        {(x.sale_price_per_year * x.quantity).toFixed(1)}
                    </>
                );
            }
            
        }
        else{
            if(x.plan == 'M'){
                let fractionSaved = 0;
                if(x.n_months > 1){
                    fractionSaved = (x.price_per_month * 12 - x.price_per_year) * (x.n_months / 12) * x.quantity;
                }
                else{
                    // fractionSaved = (x.price_per_month - x.sale_price_per_month) * x.quantity; 
                }
                return(
                    <>
                        {(x.price_per_month * x.quantity * x.n_months - fractionSaved).toFixed(1)}
                    </>
                );
            }
            else{
                return(
                    <>
                        {(x.price_per_year * x.quantity).toFixed(1)}
                    </>
                );
            }
        }
    }

    const DisplaySavedAmount = (x) => {
        if(x.sale_ongoing){
            if(x.plan == 'M'){
                let fractionSaved = 0;
                if(x.n_months > 1){
                    fractionSaved = (x.price_per_month * 12 - x.sale_price_per_year) * (x.n_months / 12) * x.quantity;
                }
                else{
                    fractionSaved = (x.price_per_month - x.sale_price_per_month) * x.quantity; 
                }
                return(
                    <>
                        <div style={{color: 'green'}}>
                            You Saved&nbsp;
                            {(fractionSaved).toFixed(1)}&nbsp;
                            {state.currencyAbbr}
                        </div>

                    </>
                );
            }
            else{
                let fractionSaved  = (x.price_per_month * 12 - x.sale_price_per_year) * x.quantity;
                
                return(
                    <>
                        <div style={{color: 'green'}}>
                            You Saved&nbsp;
                            {(fractionSaved).toFixed(1)}&nbsp;
                            {state.currencyAbbr}
                        </div>
                    </>
                );
            }
            
        }
        else{
            return(
                <>
                
                </>
            );
        }
    }

    const OnSuccess = (details, data) => {
        
        store.remove('product_list');

        fetch('/api/purchase/', {
            method: 'POST', 
            headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
            },
            method: "POST",
            body: JSON.stringify({data: data, details: details, cart: state.product_list}) 
        })
        .then(response => {
            console.log(response);
        })
        .catch(err => {
            console.log(err);
        })

        setState({
            ...state,
            paymentSuccess: true,
            cart: false,
            product_list : [
                
            ],
        });


        
        

        
    }
    
    return(
        
        <>
            <MetaDecorator
                description="Product checkout - Bodiometer smart biomechanic scanning and analytics for clinical professionals."
                title="Bodiometer - Checkout"
                url="https://www.bodiometer.com/checkout"
            />
            <NavBar />

            <Container className="fluid" id='checkout'>
                {
                    !state.cart && !state.paymentSuccess &&
                    
                        <Container fluid>
                            <Row>
                                <Col>
                                    <h1>Your Cart is Empty</h1>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Image src={cartEmptyImg} className="empty-cart-icon"/>
                                </Col>
                            </Row>
                        </Container>
                        
                    
                    
                    
                }

                {
                    state.paymentSuccess &&
                    <>
                        <Container className="centered-container payment-success-container" id="successContainer">
                            <Row>
                                <Image height="100" src={paymentSuccessImg} />
                            </Row>
                            <Row>
                                <h3 className="centered-text">You have successfully completed the purchase.</h3>
                            </Row>
                            <Row>
                                <Button variant="success" onClick={() => { window.location.replace("/")}}>Back to Home</Button>
                            </Row>
                            
                        </Container>
                        
                    
                    </> 
                    
                }

                {
                    state.cart &&

                    <Container className="show-cart-container" fluid>

                        <Row>
                            <Col>
                                <h1>Your Cart</h1>
                                {
                                    state.product_list.filter(x => x.quantity > 0).map(x => {
                                        return (
                                            <>
                                                <MediaQuery minWidth='600px'>
                                                    <Row className="cart-query-600">
                                                        <Col className="cart-query-align-left" xs={4}>
                                                            <Image src={x.image} width='128px' height='auto' fluid={true} />
                                                        </Col>
                                                        <Col className="cart-query-align-right" xs={8}>
                                                            <div><span>Product:</span> {x.name} - {DisplaySubscriptionName(x)}</div>
                                                            <div><span>{DisplaySubscriptionName(x)} Price:</span> {DisplaySubTotal(x)} {state.currencyAbbr}</div>
                                                            <div><span>Number of Devices:</span> {x.quantity}</div>
                                                            {DisplaySavedAmount(x)}
                                                            <Button variant="outline-danger" size='sm' value={x.name} onClick={RemoveProduct}>Remove</Button>
                                                        </Col>
                                                    </Row>
                                                </MediaQuery>

                                                <MediaQuery maxWidth='599px'>
                                                    <Row className="cart-query-599">
                                                        <Col className="cart-query-col1">
                                                            <Image src={x.image} width='128px' height='auto' fluid={true} />
                                                        </Col>
                                                    </Row>
                                                    <Row style={{backgroundColor: '#f7f9fc', borderRadius: '5px', fontSize: '75%', marginBottom: '10px'}}>
                                                        <Col className="cart-query-col2">
                                                            <div><span>Product:</span> {x.name} - {DisplaySubscriptionName(x)}</div>
                                                            <div><span>{DisplaySubscriptionName(x)} Price:</span> {DisplaySubTotal(x)} {state.currencyAbbr}</div>
                                                            <div><span>Number of Devices:</span> {x.quantity}</div>
                                                            {DisplaySavedAmount(x)}
                                                            <Button variant="light" size='sm' value={x.name} onClick={RemoveProduct}>Remove</Button>
                                                        </Col>
                                                    </Row>
                                                </MediaQuery>
                                            </>
                                        ); 
                                    })
                                }
                                <h3>Total ({state.currencyAbbr}): {
                                    ComputeTotal() 
                                }.0  {state.currencyAbbr} </h3>
                                
                            </Col>
                                
                        </Row>
                        {}
                        <Row id="paypal-button">
                            <Col>
                                <PayPalButton
                                    amount={state.totalAmount}
                                    onSuccess={OnSuccess}
                                    options={{
                                            clientId: "Adkxtn2oivVGfxIH7HB6jW6qgkxRgMALYY9yXLbkr5UaPQozjCwTzcVvbQPB45hmZgJY1f-HIYNTt3l5", // live
                                            // clientId: "AS8A6_MQaFCREmi7TmJ7acVgB4Nta23bHReP2KcrzbZV_-_XofPx5nT9OvCRNV-3BS8gqEVjueY8JR2T", // sandbox
                                            currency: "CAD",
                                            debug: false, // disable during production
                                            commit: true,
                                            components: ['buttons', 'marks'],
                                            disableFunding: 'card',
                                            // merchantId: '2B6QN6MJFUKVY'
                                            
                                        }
                                        
                                    }

                                />
                            </Col>
                        </Row>
                        <Row>

                        </Row>
                    </Container>
                }

            </Container>

            <Footer />
            
        </>
    );

};