import React from "react";
import { Container } from "react-bootstrap";

import NavBar from "../components/navbar.component.js";
import Footer from "../components/footer.component.js";

// Custom page meta with react-helmet
import MetaDecorator from "../components/metaDecorator.js";

export default function Terms() {
	return (
		<>
			<MetaDecorator
				description="Terms of Use - Bodiometer smart biomechanic scanning and analytics for consumers and clinical professionals."
				title="Bodiometer - Terms of Use"
				url="https://www.bodiometer.com/terms"
			/>
			<NavBar />
			<Container className="terms-wrapper">
				<h1 style={{ textAlign: "center" }}>TERMS OF USE – BODIOMETER</h1>
				<h3>LAST UPDATED: AUGUST 11, 2020</h3>
				<p>
					THESE TERMS AND CONDITIONS OF USE (THE “TERMS OF USE”) RELATE TO BODIOMETER, THE BODIOMETER WEBSITES, THE BODIOMETER DESKTOP APPLICATIONS, AND THE BODIOMETER MOBILE APPLICATIONS (THE
					“BODIOMETER APP”). THE BODIOMETER APP IS OWNED AND OPERATED BY ANIMATIONLEADER TECHNOLOGY INC. (“<b>BODIOMETER</b>”).
				</p>

				<p>
					ACCESS AND USE OF THE BODIOMETER APP (AND ITS RELATED SERVICES) IS PROVIDED BY BODIOMETER TO YOU ON CONDITION THAT YOU ACCEPT THESE TERMS OF USE, AND BY ACCESSING OR USING THE BODIOMETER APP
					(OR ANY OF ITS RELATED SERVICES), YOU AGREE TO THESE TERMS OF USE. IF YOU DO NOT AGREE TO ACCEPT AND ABIDE BY THESE TERMS OF USE YOU SHOULD NOT ACCESS OR USE THE BODIOMETER APP (OR ANY OF
					ITS RELATED SERVICES).
				</p>

				<p>
					You agree that the agreement formed by these Terms of Use is like any written negotiated agreement signed by you, and you agree to be bound by, and fully comply with, its terms. You
					represent and warrant that you have all necessary right, power and authority to enter into these Terms of Use and to perform and otherwise discharge all of your obligations hereunder.
				</p>

				<p>
					Bodiometer may modify these terms of use from time to time and such modification shall be effective upon posting by Bodiometer at{" "}
					<a href="https://bodiometer.com/terms">www.bodiometer.com</a>. You agree to be bound to any changes to these terms of use when you use the Bodiometer App (or any of its related services)
					after any such modification is posted. It is therefore important that you review these terms of use regularly to ensure you are updated as to any changes.
				</p>

				<p>
					You may not interfere with the security of, or otherwise abuse the Bodiometer App or any websites, system resources, services or networks connected to or accessible through the Bodiometer
					App. You may only use the Bodiometer App (or any of its related services) for lawful purposes.
				</p>

				<h3>Jurisdiction and Age</h3>

				<p>
					If you are residing in a jurisdiction which restricts the use of internet-based applications according to age, or which restricts the ability to enter into agreements such as set out in
					these terms of use according to age and you are under such a jurisdiction and under such age limit, you may not enter into these Terms of Use or use the Bodiometer App (or any of its related
					services).
				</p>

				<p>
					Furthermore, if you are residing in a jurisdiction where it is forbidden by law to use the services offered by the Bodiometer App, you may not enter into these Terms of Use or use the
					Bodiometer App (or any of its related services).
				</p>

				<p>
					By using the Bodiometer App you are explicitly stating that you have verified in your own jurisdiction if your use of the Bodiometer App (or any of its related services) is allowed. Your
					profile may be deleted and these Terms of Use may be terminated without warning, if we believe that you are under age or your use of the Bodiometer App (or any of its related services) is
					not allowed.
				</p>

				<h3>User Accounts</h3>

				<p>
					You will be required to open an account to use the Bodiometer App (or any of its related services). To open an account you must complete the registration process by providing complete and
					accurate information requested on the registration form. You may be asked to provide a username and password.
				</p>

				<p>
					Where we offer you the ability to choose your own username(s) in connection with the Bodiometer App (or any of its related services), we will use reasonable efforts to assign such
					username(s) to you upon your request, provided however that you may not select, and we reserve the right to reject, any username that (i) has already been assigned, (ii) we determine, in our
					sole discretion, is offensive, violates applicable law, is an attempt to reserve username(s) without the intent to use them, is an attempt to impersonate another, or may interfere or be
					confused with, violate, exploit, or capitalize on, the name, goodwill, trade-name, trademark, registered trademark, service mark, or proprietary or other rights of another. Where we reject a
					requested username, we may, in our sole discretion, assign an alternate username, ask you to select another username or terminate these Terms of Use.
				</p>

				<p>
					You agree that you are entirely responsible for maintaining the confidentiality of your username and password and that Bodiometer and any of its partners, affiliates, officers, directors,
					employees, consultants, agents, and representatives will not be held responsible for any loss related to any lost, stolen or otherwise misappropriated username or password.
				</p>

				<p>You may not use the account, username, or password of anyone else at any time, unless you are authorized to do so by such other user and Bodiometer.</p>

				<p>
					You acknowledge and agree that you shall be responsible for each and every access, use or employment of the Bodiometer App (and any of its related services) that occurs in conjunction with
					your passwords and user identifications, and that Bodiometer is authorized to accept the user identifications and passwords as conclusive evidence that you have accessed, utilized, or
					otherwise employed of the Bodiometer App (or any of its related services).
				</p>
				<p>
					You agree to notify Bodiometer immediately on any unauthorized use, theft or misappropriation of your account, username, or password. Bodiometer shall not be liable for any loss that you
					incur as a result of someone else using your username or password, either with or without your knowledge.
				</p>

				<p>
					You may deactivate your user account at any time, for any reason, by contacting Bodiometer. Please see our <a href="https://www.bodiometer.com/privacy">Privacy Policy</a> in relation to our
					management of requests for deactivation of a User Account.
				</p>

				<h3>Health Care, Privacy & Health Information</h3>

				<p>
					You may use the Bodiometer App to exchange your data with your practitioner (including, their office or clinic), other practitioners or your patient. You are responsible for and control who
					you authorize to access the personal information managed by you. Bodiometer is not a health care provider. The Bodiometer App (and its related services) enables secure data transfer between
					patients and their practitioners and other practitioners. These practitioners are regulated and licensed by various public or regulatory bodies. Should you have any questions about your
					practitioner please direct such questions directly to them or the applicable regulatory body. For example, the relationship between you and your chiropractor is a practitioner-patient
					relationship. Bodiometer is not a party to that relationship. Each practitioner is independent of Bodiometer and is solely responsible for any services they provide to you.
				</p>

				<p>
					A practitioner may establish a Bodiometer account for their office or clinic; this account may be accessible by such practitioner, the other practitioners who are part of such office or
					clinic, and their staff. When you communicate with such an account, you are consenting to your information being accessible by each of them.
				</p>

				<p>
					THERE ARE RESTRICTIONS ON THE SERVICES THAT CAN BE PROVIDED THROUGH THE BODIOMETER APP. YOU MUST NOT USE THE BODIOMETER APP FOR EMERGENCY MEDICAL NEEDS. IF YOU BELIEVE THAT YOU ARE DEALING
					WITH A MEDICAL EMERGENCY, CALL 911 (OR YOUR LOCAL EMERGENCY NUMBER) IMMEDIATELY OR ATTEND YOUR NEAREST EMERGENCY ROOM.
				</p>

				<p>
					Bodiometer is committed to respecting the privacy of the personal information of the individuals with whom we interact. We have developed a Privacy Policy to describe our privacy policies
					and practices and how we collect, use and disclose the personal information of those individuals who use the Bodiometer App (or any of its related services). Please see our{" "}
					<a href="https://www.bodiometer.com/privacy">Privacy Policy</a> for further details.
				</p>

				<p>
					You acknowledge and agree that access to and use of the Bodiometer App (and any of its related services) is provided via the Internet. Information stored in the Bodiometer App is stored in
					Canada, but may be accessible in other jurisdictions.
				</p>

				<p>The Bodiometer App (and its related services) may be used to exchange health information between practitioners and their patients.</p>

				<p>
					Our systems may record certain information related to your use of the Bodiometer App, by using various types of technology, including cookies, “clear gifs” or “web beacons.” This information
					may include the IP address or other device address or ID, web browser and/or device type, your photo and biomechanics measurements entered into the Bodiometer App, the web pages or sites
					visited just before or just after using the Bodiometer App, the pages or other content you view or interact with on the Bodiometer App, the dates and times of the visit, access, or use of
					the Bodiometer App and what country you are from. You may limit the collection of certain information by the Bodiometer App, for instance by disabling the cookies using your browser options.
					Please be aware that by doing so it may prevent you from using specific features on the Bodiometer App, such as maintaining an online account. We use the collected information on the
					Bodiometer App through cookies and similar technologies to: (i) monitor our system performance (such as number of visits, average time spent, screen usage), to enable us to operate and
					manage our systems and services (ii) personalize the Bodiometer App, such as remembering your information so that you will not have to re-enter it during a visit or on subsequent visits;
					(iii) better understand our user community so that we can work to continually upgrade our systems and services (iv) provide customized advertisements, content, and information; (v) monitor
					and analyze the effectiveness of the Bodiometer App and third party marketing activities; (vi) monitor aggregate site usage metrics such as total number of visitors and pages viewed; and
					(vii) track your entries, submissions, and status in any promotions or other activities on the Bodiometer App (or any of its related services).
				</p>

				<p>
					If you are an individual, your use of the Bodiometer App (and its related services) indicates your consent to use the Bodiometer App (and its related services), including the exchange,
					storage and management of your health information in same.
				</p>

				<h3>Acceptable Use</h3>

				<p>
					You are solely responsible for the content of any communications by you with third parties, including any communications about, concerning or through the Bodiometer App (or any of its
					related services). Bodiometer does not condone or allow misinformation. You agree that you will not use the Bodiometer App (or any of its related services) for malingering or fraudulent
					claims.
				</p>

				<p>
					You shall not, directly or indirectly, send, submit, post, receive or otherwise facilitate, or in any way use, or permit to be used, the Bodiometer App (or any of its related services), to
					send, submit, post, receive or otherwise facilitate the transmission of any materials that are abusive, obscene, profane, sexually explicit, threatening, or materials containing blatant
					expressions of bigotry, racism or hate. You shall comply with all applicable laws (including, but not limited to export/import laws and laws relating to privacy, obscenity, copyright,
					trademark, confidential information, trade secret, libel, slander or defamation). Although Bodiometer is not responsible for any such communications or materials, we may delete any such
					communications or materials of which we become aware, at any time without notice to you. You acknowledge and agree that you shall be responsible and liable for all uses made by you of the
					Bodiometer App (or any of its related services).
				</p>

				<p>
					You agree that you will not attempt to, nor permit any third party to, enter restricted areas of Bodiometer’s computer systems or perform functions that you are not authorized to perform
					pursuant to these Terms of Use. Bodiometer may, without notice, temporarily suspend your, or any other parties, access to the Bodiometer App (and any of its related services), (including,
					without limitation, any specific areas hosted within the Bodiometer App) by deactivating any password(s) or links to the Internet if Bodiometer reasonably suspects that you, or any other
					parties, are obtaining unauthorized access to Bodiometer’s other systems or information, or are using otherwise valid user identifications or passwords in any other unauthorized manner.
					These suspensions will be for such periods of time as Bodiometer may reasonably determine is necessary to permit the thorough investigation of such suspended activity.
				</p>

				<p>
					In addition, you agree that you will not (i) remove or alter any copyright, trademark, brand elements or other proprietary notices; (ii) reproduce, modify, prepare derivative works based
					upon, distribute, license, lease, sell, resell, transfer, publicly display, publicly perform, transmit, stream, broadcast or otherwise exploit the Bodiometer App, except as expressly
					permitted by Bodiometer; (iii) link to, mirror or frame any portion of the Bodiometer App; (iv) cause or launch any programs or scripts for the purpose of scraping, indexing, surveying, or
					otherwise data mining any portion of the Bodiometer App, or unduly burdening or hindering the operation and/or functionality of any aspect of the Bodiometer App.
				</p>

				<h3>Bodiometer Materials</h3>

				<p>
					Bodiometer may make certain software or other electronic materials (including all files, text, URLs, video, audio and images contained in or generated by such materials, and accompanying
					data) (collectively "<b>Bodiometer Materials</b>") available to you from the Bodiometer App (or any of its related services). If you download or use Bodiometer Materials, you agree that such
					materials: (i) may only be used for the purpose for which they were provided, (ii) may not be modified, used to create a derivative work, incorporated into any other work or otherwise
					exploited without Bodiometer's prior written permission, and (iii) shall be only be used in compliance with any license terms accompanying such materials. Bodiometer does not transfer either
					the title or the intellectual property rights to the Bodiometer Materials, and retains full and complete title to the Bodiometer Materials as well as all intellectual property rights
					therein. You agree not to sell, share, redistribute, or reproduce the Bodiometer Materials. You further agree not to decompile, reverse-engineer, disassemble, or otherwise convert any
					Bodiometer Materials into a human-perceivable form. Bodiometer or its licensors own all related trademarks and logos, and you agree not to copy or use them in any manner.
				</p>

				<p>
					The Bodiometer App, (including its organization and presentation and Bodiometer Materials) is the property of Bodiometer and its licensors and may be protected by intellectual property laws
					including laws relating to patents, copyrights, trade-marks, trade-names, internet domain names and other similar rights.
				</p>

				<p>
					Bodiometer Materials are provided for information purposes only and do not constitute, nor should they be relied upon as, medical or health care advice or any endorsement, representation or
					warranty regarding the safety, appropriateness or effectiveness of any particular treatment or medication for you. Bodiometer does not provide any health care services.
				</p>

				<h3>Your Materials</h3>

				<p>
					When you load or create materials (including files, text, video, audio and images contained in or generated by such materials and accompanying data) on the Bodiometer App ("
					<b>User Materials</b>") you are granting Bodiometer, its affiliates, partners, officers, directors, employees, consultants, agents, and representatives a perpetual license to use your User
					Materials in connection with the operation of the Bodiometer App (or any of its related services).
				</p>

				<p>
					We use the User Materials in a variety of ways in order to provide better service and operate our business, including improving Smart Features/Artificial Intelligence. When reviewing your
					case, practitioners have the opportunity to utilize algorithms powered by artificial intelligence to support their clinical decisions for an enhanced level of care. In order for the
					Bodiometer App’s algorithms to become “smarter” over time to fulfill this mission of improved care, they must be continuously trained. To support this intrinsic need, every time a
					practitioner uses one of our artificial intelligence-powered algorithms, the Bodiometer App has the opportunity to “learn” and become better for you and other patients around the world. Any
					images and data used for these algorithms have been de-identified, which means that all identifiable information such as name, date of birth and email have been removed. You acknowledge and
					agree that Bodiometer owns access to use your de-identified information to enhance Bodiometer App’s features such as smart features that may be used by practitioners.
				</p>

				<p>
					By loading or creating User Materials on the Bodiometer App, you warrant and represent that you own the rights to the User Materials or are otherwise authorized to load or create such User
					Materials and that such User Materials do not violate any applicable laws. You agree that the burden of proving that any User Materials do not violate any laws or third party rights rests
					solely with you.
				</p>

				<p>
					You are solely responsible for your User Materials. You are solely responsible for making and keeping back up copies of your User Materials and for applying the appropriate level of access
					rights to same. Bodiometer shall not be responsible or liable for the deletion or accuracy of any User Materials.
				</p>

				<p>
					You are solely responsible for obtaining and maintaining any necessary consents or permissions from any third party whose information may be collected, recorded, processed, stored, used,
					disclosed, transferred, exchanged or otherwise handled as a result of, or as part of, any User Materials or any communications involving the use of the Bodiometer App (or any of its related
					services) and agree to obtain and maintain all such consents or permissions throughout the term of these Terms of Use.
				</p>

				<h3>Fees for Related Services</h3>

				<p>
					Bodiometer may, from time to time, provide certain services through the Bodiometer App on a fee basis. Where you elect to receive such services, you agree to pay, through the payment
					mechanism selected by you and approved by us, all amounts due and owing for such services as more particularly set out in the order confirmation provided at the time that you ordered such
					services. We may amend the Bodiometer App and any of its related services and/or the amounts that we charge for same at any time, without prior notice. Fees do not include any applicable
					sales, use, excise, value-added or other taxes or governmental charges, and you shall be responsible for, and shall promptly pay, same. If Bodiometer terminates these Terms of Use because of
					your breach of its terms, you shall not be entitled to the refund of any unused portion of any fees you have already paid. You agree that Bodiometer may collect interest at the lesser of
					1.5% per month (18% per annum) or the highest amount permitted by law on any amounts not paid when due.
				</p>

				<h3>Currency of website</h3>
				<p>
					Bodiometer may make information or related resources available to you through the Bodiometer App. Bodiometer may update such information and related resources periodically. However,
					Bodiometer cannot guarantee or accept any responsibility or liability for the accuracy, currency or completeness of the information or related resources on the Bodiometer App. Bodiometer may
					revise, supplement or delete information and/or the resources contained in the Bodiometer App and reserves the right to make such changes without prior notification to past, current or
					prospective visitors or users.
				</p>

				<h3>Linked websites</h3>

				<p>
					The Bodiometer App may provide links to third party websites for your convenience only. The inclusion of these links does not imply that Bodiometer monitors or endorses these websites.
					Bodiometer does not accept any responsibility for such websites. Bodiometer shall not be responsible or liable, directly or indirectly, for any damage or loss, caused or alleged to be caused
					by or in connection with the use of or the reliance upon any information, content, goods or services available on or through any third party websites or linked resources.
				</p>

				<h3>Internet software or computer viruses</h3>

				<p>
					Due to technical difficulties with the Internet, Internet software or transmission problems could produce inaccurate or incomplete copies of information contained on the Bodiometer App.
					Computer viruses or other destructive programs may also be inadvertently downloaded from the Bodiometer App.
				</p>

				<p>
					Bodiometer shall not be responsible or liable for any software, computer viruses or other destructive, harmful or disruptive files or programs that may infect or otherwise impact your use of
					your computer equipment or other property on account of your access to, use of, or browsing on the Bodiometer App or your downloading of any of the Bodiometer Materials or User Materials
					(collectively, "<b>Materials</b>") from the Bodiometer App. Bodiometer recommends that you install appropriate anti-virus or other protective software.
				</p>

				<p>
					Bodiometer is not liable for your information stored or recorded by any computer, tablet, mobile device or any network, whether public or private, that you may use to access the Bodiometer
					App.
				</p>

				<h3>Website and Materials Provided “As Is”</h3>

				<p>
					THE BODIOMETER APP, ITS RELATED SERVICES AND THE MATERIALS ARE PROVIDED "AS IS" WITHOUT REPRESENTATION, WARRANTY OR CONDITION OF ANY KIND, EITHER EXPRESS OR IMPLIED, INCLUDING, BUT NOT
					LIMITED TO THE IMPLIED REPRESENTATIONS, WARRANTIES OR CONDITIONS OF MERCHANTABILITY, OR FITNESS FOR A PARTICULAR PURPOSE. BODIOMETER DOES NOT REPRESENT OR WARRANT THAT THE BODIOMETER APP,
					ITS RELATED SERVICES OR THE MATERIALS WILL MEET YOUR REQUIREMENTS OR THAT THEIR USE WILL BE UNINTERRUPTED OR ERROR FREE.
				</p>

				<h3>Limitation of liability</h3>

				<p>YOU ASSUME ALL RESPONSIBILITY AND RISK FOR USE OF THE BODIOMETER APP, ITS RELATED SERVICES AND THE MATERIALS INCLUDING WITHOUT LIMITATION ANY OF THE INFORMATION CONTAINED THEREIN.</p>

				<p>
					IN NO EVENT SHALL BODIOMETER OR ANY OF ITS DIRECTORS, OFFICERS, EMPLOYEES, SHAREHOLDERS, PARTNERS, OR AGENTS BE LIABLE FOR ANY SPECIAL, INCIDENTAL, INDIRECT, PUNITIVE, EXEMPLARY,
					CONSEQUENTIAL OR OTHER SIMILAR DAMAGES, OR FOR ANY DAMAGES FOR LOSS OF PROFITS, INTERRUPTION, LOSS OF HEALTH, BUSINESS OR OTHER INFORMATION, OR ANY OTHER SIMILAR LOSS IN CONNECTION WITH ANY
					CLAIM, LOSS, DAMAGE, ACTION, SUIT OR OTHER PROCEEDING ARISING UNDER OR OUT OF THESE TERMS OF USE, INCLUDING WITHOUT LIMITATION YOUR USE OF, RELIANCE UPON, ACCESS TO, OR EXPLOITATION OF THE
					BODIOMETER APP, ITS RELATED SERVICES, THE MATERIALS OR ANY PART THEREOF, OR ANY RIGHTS GRANTED TO YOU HEREUNDER, EVEN IF WE HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES, WHETHER THE
					ACTION IS BASED ON CONTRACT, TORT (INCLUDING NEGLIGENCE), INFRINGEMENT OF INTELLECTUAL PROPERTY RIGHTS OR OTHERWISE.
				</p>
				<p>
					IN EVERY EVENT, BODIOMETER’S TOTAL MAXIMUM AGGREGATE LIABILITY UNDER THESE TERMS OF USE OR THE USE OR EXPLOITATION OF ANY OR ALL PART OF THE BODIOMETER APP, ITS RELATED SERVICES OR THE
					MATERIALS IN ANY MANNER WHATSOEVER SHALL BE LIMITED TO FIVE ($5.00) US DOLLARS.
				</p>

				<p>
					THE CONSIDERATION BEING PAID HEREUNDER DOES NOT INCLUDE ANY CONSIDERATION FOR BODIOMETER TO ASSUME ANY RISKS BEYOND THOSE EXPRESSLY ASSUMED HEREIN AND IF ANY SUCH RISKS WERE TO BE ASSUMED BY
					BODIOMETER, BODIOMETER WOULD NOT HAVE ENTERED INTO THESE TERMS OF USE WITHOUT CHARGING SUBSTANTIALLY HIGHER FEES.
				</p>
				<p>SOME JURISDICTIONS DO NOT ALLOW FOR THE LIMITATION OR EXCLUSION OF LIABILITY FOR INCIDENTAL OR CONSEQUENTIAL DAMAGES, SO THE ABOVE LIMITATION OR EXCLUSION MAY NOT APPLY TO YOU.</p>

				<h3>Indemnification</h3>

				<p>
					You agree to indemnify and hold Bodiometer harmless against all claims or liability asserted against Bodiometer arising out of or in connection with any breach by you or anyone acting on
					your behalf of any of these Terms of Use.
				</p>

				<h3>Email</h3>

				<p>
					Feel free to email your comments, suggestions and feedback (collectively, “<b>Feedback</b>”) to Bodiometer at the e-mail addresses provided in the Bodiometer App. However, the Internet is
					not a fully secure medium and any Feedback may be lost, intercepted or altered. You agree with respect to any Feedback provided by you to us via e-mail that: (i) Bodiometer has no obligation
					concerning such Feedback; (ii) Feedback is non-confidential; (iii) Bodiometer may use, disclose, distribute or copy the Feedback and may use any ideas, concepts or know-how contained in the
					Feedback for any purpose; and (iv) the Feedback is truthful and disclosure of the Feedback does not violate the legal rights of others.
				</p>

				<h3>Access Related to Alleged Violations</h3>

				<p>
					To ensure that Bodiometer provides a high quality experience for you and for other users of the Bodiometer App (and any of its related services), you agree that Bodiometer or its
					representatives may access your account and all information and any records related thereto on a case-by-case basis to investigate complaints or allegations of abuse, infringement of third
					party rights, or other unauthorized uses of the Bodiometer App (and any of its related services), and you agree to give Bodiometer full access to your account and all information and records
					related thereto without the need for additional authorization and without any claim to privacy or other rights to such account and related information and records.
				</p>

				<p>
					Bodiometer does not intend to disclose the existence or occurrence of such an investigation unless required by law or if requested by law enforcement officials, but Bodiometer reserves the
					right to terminate your account or your access to the Bodiometer App (and any of its related services) immediately, with or without notice to you, and without liability to you, if Bodiometer
					believes that you have violated any of these Terms of Use, furnished Bodiometer with false or misleading information, or interfered with use of the Bodiometer App (or any of its related
					services) by others.
				</p>

				<h3>Term and Termination</h3>

				<p>
					Bodiometer may, from time to time, but is in no way obligated to, permit you to access and use the Bodiometer App (or any of its related services) in accordance with these Terms of Use and
					in the manner more particularly set out herein. You acknowledge and agree that access to the Bodiometer App (or any of its related services) may not be available from time to time, may be
					amended, revised, replaced, suspended or terminated in whole or in part at any time and without notice, and that Bodiometer shall not, in any event, be responsible to you in any way should
					you be unable to access the Bodiometer App (or any of its related services) at any time or from time to time.
				</p>

				<p>
					Bodiometer may, at its sole discretion, at any time and from time to time, without notice, suspend your right to use the Bodiometer App (or any of its related services) and/or terminate
					these Terms of Use or any of the licenses granted hereunder. Without limiting the foregoing, these Terms of Use and the licenses granted herein shall automatically terminate, without notice,
					if you materially fail to perform or comply with these Terms of Use or any provision hereof. Upon termination of these Terms of Use, you shall immediately cease and desist all use of the
					Bodiometer App (and any of its related services).
				</p>

				<h3>Applicable law</h3>

				<p>
					The Bodiometer App is controlled, operated and administered by Bodiometer from within the Province of Alberta, Canada. The Bodiometer App can be accessed from all provinces and territories
					of Canada, as well as from other countries around the world. As each of these jurisdictions has laws that may differ from those of the Province of Alberta, by accessing the Bodiometer App,
					you acknowledge and agree that all matters relating to access to or use of the Bodiometer App shall be governed by the laws of the Province of Alberta and the federal laws of Canada
					applicable therein (without reference to conflicts of laws principles).
				</p>

				<p>
					You also agree that any claims or disputes whatsoever arising hereunder shall be submitted to the exclusive jurisdiction and venue of the courts of the Province of Alberta and acknowledge
					that you do so voluntarily.
				</p>

				<p>
					WHERE PERMITTED UNDER THE APPLICABLE LAW, WE EACH AGREE THAT EACH OF US WILL BRING CLAIMS AGAINST THE OTHER ONLY IN OUR INDIVIDUAL CAPACITY AND NOT AS A PLAINTIFF OR CLASS MEMBER IN ANY
					PURPORTED CLASS OR REPRESENTATIVE ACTION. UNLESS BOTH OF US AGREE, NO ARBITRATOR OR JUDGE MAY CONSOLIDATE MORE THAN ONE PERSON’S CLAIMS OR OTHERWISE PRESIDE OVER ANY FORM OF A REPRESENTATIVE
					OR CLASS PROCEEDING.
				</p>

				<h3>General</h3>

				<p>Bodiometer’s failure to insist upon or enforce strict performance of any provision of these Terms of Use shall not be construed as a waiver of any provision or right.</p>

				<p>You shall not assign these Terms of Use or any of your rights hereunder without the prior written consent of Bodiometer.</p>

				<p>Your obligations under these Terms of Use will survive the termination of these Terms of Use, or of any license granted under these Terms of Use, for whatever reason.</p>

				<p>
					If any provision or part thereof of these Terms of Use is wholly or partially unenforceable the parties or, in the event the parties are unable to agree, a court of competent jurisdiction,
					shall put in place thereof an enforceable provision or provisions, or part thereof, that as nearly as possible reflects the terms of the unenforceable provision or part thereof.
				</p>

				<p>
					The parties have requested that these Terms of Use and all documents relating thereto be drawn up in English. Les parties ont demand que cette convention ainsi que tous les documents qui s'y
					rattachent soient en anglais.
				</p>

				{/*  */}
			</Container>
			<Footer />
		</>
	);
}
