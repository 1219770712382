import React, { useState, useEffect } from 'react';
import { Container, Col, Row, Form, Alert } from 'react-bootstrap';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';

import UiButton from "./ui-button.js";
import convertToId from "../../resources/helpers/convert-to-id.js";
import formatPhone from "../../resources/helpers/format-phone.js";
import DisplayPhone from "../../resources/helpers/display-phone.js";

import "bootstrap/dist/css/bootstrap.min.css";

export default function CouponTool(props) {
  const [state, setState] = useState({
    Code: '',
    Interval: '',
    Cycle: null,
    UseCount: 0,
    MaxCount: null,
    Expiry: '',
    Value: null,
    Type: '',
    Start: '',
    Error: [false, ""],
    Success: [false, ""],
    AllSet: false
  });

  const initial = {
    Code: '',
    Interval: '',
    Cycle: null,
    UseCount: 0,
    MaxCount: null,
    Expiry: '',
    Value: null,
    Type: '',
    Start: '',
    Error: [false, ""],
    Success: [false, ""],
    AllSet: false
  }

  const createId = () => {
    let tempId = uuidv4()
    let tempIdSized = tempId.substring(0,16)
    return tempIdSized
  }


  const GenerateCode = () => {
    setState({...state, Code: createId()})
  }

  const IsButtonDisabled = () => {
    if (
      state.Code !== '' &&
      state.Interval !== '' &&
      state.Cycle !== null &&
      state.MaxCount !== null &&
      state.Expiry !== '' &&
      state.Value !== null &&
      state.Type !== '' &&
      state.Start !== ''
    ) {
      return false
    } else {
      return true
    }
  }


  const HandleSubmit = (e) => {

    e.preventDefault();
    // e.stopPropagation();
      

    const CouponHeaders = {
      headers: {
        'Authorization': props.parentState.DBAccessToken,
        'code': state.Code,
      }
    };

    const CouponData = {
      code: state.Code,
      interval: state.Interval,
      cycle: state.Cycle,
      useCount: state.UseCount,
      maxCount: state.MaxCount,
      expiry: state.Expiry,
      value: state.Value,
      type: state.Type,
      start: state.Start
    };

    axios.post(
      props.dbUrl + `/api/coupons/v1/create`,
      CouponData,
      CouponHeaders
    )

    .then((res) => {
      if (res.data.success) {
        // success
        setState({...initial, Success: [true, `The coupon was created successfully, the code is: ${state.Code}`]})
      } else {
        // something went wrong
        setState({...state, Error: [true, "Something went wrong.."]})
      }
    })
        
  };

  
  return(

      <div className="ui-section-wrapper">
        <section>
          <Row>
            <Col className="flex-row no-wrap">
              <h1>Create New Coupon</h1>
              <Alert variant="warning" show={state.Error[0] ? true : false}>
                <div className="flex-row">
                  <p>{state.Error[1]}</p>
                </div>
              </Alert>
              <Alert variant="info" show={state.Success[0] ? true : false}>
                <div className="flex-row">
                  <p>{state.Success[1]}</p>
                </div>
              </Alert>
            </Col>
          </Row>
        </section>

        <section className="form-section-wrapper">
          <Row>
            <Col className="landing-form-wrapper create-org-wrapper" xl={7} lg={8} md={12}>
              <Form /*validated={state.Validated}*/ onSubmit={e => HandleSubmit(e)}>
                <Row>

                  <Row>
                    <Form.Group as={Col} md={6} sm={12} controlId="create-code">
                      <Form.Control size="sm" type="input" value={state.Code} onChange={e => setState({...state, Code: e.currentTarget.value})} placeholder="Code" required/>
                    </Form.Group>
                    <Form.Group as={Col} md={6} sm={12}>
                      <UiButton onClick={e => GenerateCode(e)} variant="outline-primary">Generate Random Code</UiButton>
                    </Form.Group>
                  </Row>

                  <Row>
                    <Form.Group as={Col} md={6} sm={12} controlId="create">
                      <Form.Label>interval (MONTH or YEAR)</Form.Label>
                      <Form.Control size="sm" type="input" value={state.Interval} onChange={e => setState({...state, Interval: e.currentTarget.value})} placeholder="eg: MONTH" required/>
                    </Form.Group>

                    <Form.Group as={Col} md={6} sm={12} controlId="create-organization_postal">
                      <Form.Label>maxCount (if set to 1, can only be used once)</Form.Label>
                      <Form.Control size="sm" type="input" value={state.MaxCount} onChange={e => setState({...state, MaxCount: e.currentTarget.value})} placeholder="maxCount (integer only)" required/>
                    </Form.Group>
                  </Row>
                  
                  <Row>
                    <Form.Group as={Col} md={12} sm={12} controlId="create-organization_city">
                      <Form.Label>cycle (how many times the discount is applied, based on interval - MONTH or YEAR)</Form.Label>
                      <Form.Control size="sm" type="input" value={state.Cycle} onChange={e => setState({...state, Cycle: e.currentTarget.value})} placeholder="Cycle (integer)" required/>
                    </Form.Group>
                  </Row>

                  <Row>
                    <Form.Group as={Col} md={6} sm={12} controlId="create-organization_city">
                      <Form.Label>value (dollars or percent)</Form.Label>
                      <Form.Control size="sm" type="input" value={state.Value} onChange={e => setState({...state, Value: e.currentTarget.value})} placeholder="integer only (no symbols)" required/>
                    </Form.Group>
                    <Form.Group as={Col} md={6} sm={12} controlId="create-organization_province">
                      <Form.Label>type (either amount or percent off)</Form.Label>
                      <Form.Control size="sm" type="input" value={state.Type} onChange={e => setState({...state, Type: e.currentTarget.value})} placeholder="eg: amount" required/>
                    </Form.Group>
                  </Row>

                  <Row>
                    <Form.Group as={Col} md={6} sm={12} controlId="create-organization_city">
                      <Form.Label>start (date when the coupon can start to be redeemed)</Form.Label>
                      <Form.Control size="sm" type="input" value={state.Start} onChange={e => setState({...state, Start: e.currentTarget.value})} placeholder="eg: 02/20/2021" required/>
                    </Form.Group>
                    <Form.Group as={Col} md={6} sm={12} controlId="create-organization_province">
                      <Form.Label>expiry (date when the coupon will no longer be redeemable)</Form.Label>
                      <Form.Control size="sm" type="input" value={state.Expiry} onChange={e => setState({...state, Expiry: e.currentTarget.value})} placeholder="eg: 02/20/2023" required/>
                    </Form.Group>
                  </Row>


                </Row>
                
                <Row>
                  <Form.Group controlId="consent" className="form-footer-wrapper">
                    <UiButton
                    type="submit"
                    variant="secondary" 
                    onSubmit={HandleSubmit}
                    disabled={IsButtonDisabled() ? true : false}
                    >
                      Create Coupon
                    </UiButton>
                  </Form.Group>
                </Row>
              </Form>
            </Col>
          </Row>

        </section>



      </div>


  );
};