import React, { useEffect, useState } from "react";
import { Container, Col, Row, Form, Button } from "react-bootstrap";
import { useParams } from "react-router-dom";
import axios from "axios";
import CryptoJS from "crypto-js";
import md5Hash from "../resources/helpers/md5.js";

import UiButton from "../components/dashboard/ui-button.js";
import UiPassword from "../components/dashboard/ui-password.js";
import { RetrieveToken } from "../lib/token";
import { dbUrl } from "../lib/config";
import { LoadBusinessUserCommonLoginData, LoadOrganizationLogo, LoadPaypalAccessToken, PaypalApiUrl } from "../lib/scripts.js";

import "../resources/styles/dashboard.scss";
import "bootstrap/dist/css/bootstrap.min.css";

export default function LandingLogin(props) {
	const [state, setState] = useState({
		Validated: false,
		Username: "",
		PassType: "password",
		PassValue: "",
		UserInValid: [false, "Incorrect Username or Email"],
		InValid: [false, "Something went wrong..."],
		DBAccessToken: "",
		Error: [false, ""],
		FreeTrialLength: props.parentState.FreeTrialLength,
		Code: "",
		Refresh: 1,
	});

	// hide success/error alert after 4 seconds
	useEffect(() => {
		const timer = setTimeout(() => {
			setState((prev) => ({ ...prev, Error: [false, ""] }));
		}, 9000);
		return () => clearTimeout(timer);
	}, [state.Error]);

	const params = useParams();

	const HandlePassChange = (e) => {
		setState({ ...state, PassValue: e.currentTarget.value });
	};

	const PasswordCheck = (e, retype) => {
		const field = e.currentTarget;

		if (retype) {
			if (field.value !== state.PassValue) {
				setState({ ...state, InValid2: [true, "Password doesn't match"] });
			} else {
				setState({ ...state, InValid2: [false, ""] });
			}
		}
	};

	const HandleLogin = async (evt) => {
		evt.preventDefault();
		evt.stopPropagation();

		try {
			// Call db and check if user exists and matches
			var HeaderName = state.Username.includes("@") ? "email" : "userId";
			var UrlName = state.Username.includes("@") ? "email" : "userid";

			props.setState({ ...props.parentState, Preloader: true }); // start preloader

			var promiseOne = RetrieveToken();
			var promiseTwo = LoadPaypalAccessToken();

			var responseValues = await Promise.all([promiseOne, promiseTwo]);

			var DbAccessToken = "Bearer " + responseValues[0];
			var PaypalAccessToken = "Bearer " + responseValues[1];

			const loginCallPostData =
				HeaderName === "email"
					? {
							email: state.Username,
							password: md5Hash(state.PassValue),
					  }
					: {
							userId: state.Username,
							password: md5Hash(state.PassValue),
					  };

			var responseOne = await axios(
				{
					method: "POST",
					url: dbUrl + "/api/businessusers/v1/login",
					headers: {
						Authorization: DbAccessToken,
					},
					data: { ...loginCallPostData },
				},
				{ timeout: 15000 }
			);

			// responseOne.data.success = true; // shortcut to access any account; remove before deploying

			if (responseOne.data.success) {
				var orgId = !!localStorage.getItem("_DO") ? localStorage.getItem("_DO") : "";
				
				var loginData = await LoadBusinessUserCommonLoginData(DbAccessToken, PaypalAccessToken, PaypalApiUrl, HeaderName, UrlName, state.Username, orgId);

				var orgLogo = null;

				try {
					var logoResponse = await LoadOrganizationLogo(DbAccessToken, loginData?.ActiveOrganization?.id ?? "");

					orgLogo = logoResponse.data;
				} catch {}

				let emailActivated = loginData?.User?.emailActivated ?? false; console.log(emailActivated);

				var localState = {
					...state,
					...loginData,
					ActiveOrganizationLogo: orgLogo,
					View: "overview",
					MainAlert: [emailActivated, !emailActivated ? "Please check your email inbox to verify your account" : ""],
				};

				if (!!(localState?.ActiveOrganization?.id ?? "")) {
					localStorage.setItem("_LD", CryptoJS.AES.encrypt(JSON.stringify({ rawData: localState, timeStamp: new Date().getTime() }), "7LF0MN9XR5-9JOAOZ451A"));
					localStorage.setItem("_A", JSON.stringify({ isLoggedIn: true, user: "pro" }));
					props.setState({
						...props.parentState,
						...localState,
						AccessToken: PaypalAccessToken,
						DBAccessToken: DbAccessToken,
						FreeTrial: localState?.ActiveOrganization?.freeTrial ?? null,
						Preloader: false,
					});
				} else {
					props.setState({ ...props.parentState, ...localState, AccessToken: PaypalAccessToken, DBAccessToken: DbAccessToken, Preloader: false });
					// setState({ ...state, UserInValid: [true, "Incorrect Username or Email"] });
				}
			} else {
				props.setState({ ...props.parentState, AccessToken: PaypalAccessToken, DBAccessToken: DbAccessToken, Preloader: false });
				setState({ ...state, UserInValid: [true, "Incorrect Username or Email"] });
			}
		} catch {
			props.setState({ ...props.parentState, Preloader: false });

			// login failed
			setState({ ...state, UserInValid: [true, "Incorrect Username or Email"] });
		}
	};

	return (
		<section className="landing-form-section-wrapper">
			<Button
				style={{ position: "absolute", top: "3vh", left: "3vw" }}
				onClick={() => {
					window.location.href = "/login";
				}}
			>
				{"< "} Back
			</Button>
			<Container className="centered-container">
				<Row>
					<Col className="landing-form-wrapper" lg={4} md={6} sm={10}>
						<img src="../../photos/bodiometer-pro-logo-stacked.svg" width="250" alt="Bodiometer Logo" />
						<h1 style={{ textAlign: "center" }} className="blue-color">
							Login
						</h1>
						<p>Sign in to your Bodiometer account</p>
						<Form validated={state.Validated} onSubmit={(e) => HandleLogin(e)}>
							<Row>
								<Form.Group as={Col} controlId="register_userId">
									<Form.Control
										size="sm"
										type="input"
										value={state.Username}
										onChange={(e) => setState({ ...state, Username: e.currentTarget.value })}
										placeholder="User ID or Email"
										required
										isInvalid={state.UserInValid[0]}
									/>
									<Form.Control.Feedback type="invalid">{state.UserInValid[1]}</Form.Control.Feedback>
								</Form.Group>
							</Row>

							<Row>
								<UiPassword
									placeholder="Password"
									controlId="register_password"
									feedback="Please enter a valid password"
									value={state.PassValue}
									onChange={HandlePassChange}
									onBlur={PasswordCheck}
									isInvalid={state.InValid[0]}
									message={state.InValid[1]}
								/>
							</Row>
							{state.Error[0] && <p className="error-login">{state.Error[1]}</p>}

							<Row>
								<Form.Group controlId="consent" className="form-footer-wrapper">
									<UiButton type="submit" onSubmit={HandleLogin}>
										Sign In
									</UiButton>
								</Form.Group>
							</Row>
						</Form>
					</Col>
				</Row>
			</Container>
			<br />
			<p className="centered-text smaller-text">
				Don't have an account?{" "}
				<a href="#" onClick={(e) => props.setView("register")}>
					Register
				</a>{" "}
				<br /> Forgot password?{" "}
				<a href="#" onClick={(e) => props.setView("password_reset")}>
					Click Here
				</a>
			</p>
			<Form className="login-hidden">
				<Form.Control size="sm" type="input" value={state.Code} onChange={(e) => setState({ ...state, Code: e.currentTarget.value })} placeholder="" tabIndex="-1" />
			</Form>
		</section>
	);
}

// added business license

// {
//   "businessId": "clinic4",
//   "productId": 1,
//   "originalIssueDate": "3/8/2021",
//   "expiryDate": "2/23/2023",
//   "numberOfDevices": 2,
//   "unlimited": true,
//   "maxNumberOfDevice": 20,
//   "maxBusinessUsers": 5,
//   "businessPlan": 1,
//   "maxPatients": 50,
//   "subscription": "monthly",
//   "addons": "",
//   "subscriptionId": "I-DYP8G3W5S6KP"
// }

// Products added to DB

// {
//   "id": 1,
//   "title": "bodiometer desktop",
//   "description": "Using markerless motion-tracking, you can quickly and accurately perform biomechanical analysis and measure range of motion, posture, and functional movement patterns. Our range of motion module allows you to measure 14 different joints in a variety of different movements. Our posture module allows you to quickly measure posture in the coronal, sagittal and traverse planes. Lastly, our functional movement module allows you to measure any kind of functional movement you can think of and compare a patient’s performance over time.",
//   "additionalDetail": ""
// }

// {
//   "id": 2,
//   "title": "bodiometer pro",
//   "description": "Bodiometer Pro body scanner app enables clinicians to quickly and accurately assess patient posture without markers or other devices in almost any setting. Bodiometer utilizes an AI-driven computer vision system to automatically extract patient skeletal data relying only on the camera of the mobile device.",
//   "additionalDetail": ""
// }
