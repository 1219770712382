import React, { useState } from "react";
import { Button } from "react-bootstrap";

import SideNavLink from "./sidenav-link";
import UiButton from "./ui-button";
import UiToggleButton from "./ui-toggle-button";

import Match from "../../resources/helpers/match";

import "../../resources/styles/dashboard.scss";
import "bootstrap/dist/css/bootstrap.min.css";

export default function SideNav(props) {
	const [state, setState] = useState({
		MobileNav: true,
	});

	const HandleCollapse = (e) => {
		props.setCollapsed(!props.collapsed);
	};
	const HandleMobileCollapse = (e) => {
		setState({ ...state, MobileNav: !state.MobileNav });
	};

	return (
		<div className={"side-nav" + (props.collapsed ? " side-collapsed" : "") + (state.MobileNav ? " mobile-active" : "")}>
			<img
				style={{ cursor: "pointer" }}
				onClick={() => props.setView("overview")}
				src={`../../photos/${props.collapsed ? "bodiometer_logo_mark_colour" : "bodiometer-logo"}.svg`}
				width={props.collapsed ? "60" : "240"}
			/>
			<hr />
			<article>
				{/* {props.Organizations.length > 0 && props.ActiveOrganization && (
					<UiToggleButton
						mobileNav={state.MobileNav}
						setMobileNav={setState}
						setState={props.setState}
						parentState={props.parentState}
						collapsed={props.collapsed}
						setView={props.setView}
						Organizations={props.Organizations}
						ActiveOrganization={props.ActiveOrganization}
						setActiveOrganization={props.setActiveOrganization}
						className="organization-toggle"
					>
						{props.Organizations.filter((org) => org.id === props.ActiveOrganization)[0].name}
					</UiToggleButton>
				)} */}

				<UiToggleButton
					mobileNav={state.MobileNav}
					setMobileNav={setState}
					setState={props.setState}
					parentState={props.parentState}
					collapsed={props.collapsed}
					setView={props.setView}
					Organizations={props.Organizations}
					ActiveOrganization={props.ActiveOrganization}
					setActiveOrganization={props.setActiveOrganization}
					className="organization-toggle"
				>
					{props.Organizations.length > 0 && !!props.ActiveOrganization ? props.Organizations.filter((org) => org.id === props.ActiveOrganization)[0].name : "Select Clinic"}
				</UiToggleButton>
				<SideNavLink
					active={props.view === "overview" || props.view === "license" || props.view === "roles" ? true : false}
					onClick={(e) => {
						props.setView("overview");
						// if (!state.MobileNav) {
						//   setState({...state.MobileNav, MobileNav: !state.MobileNav})
						// }
					}}
					icon="overview"
					collapsed={props.collapsed}
					ActiveOrganization={props.ActiveOrganization}
					list={[
						props.parentState.ActiveOrganization && props.parentState.ActiveOrganization.myAccessLevel <= 1 && (
							<li>
								<a
									className={Match(props.view, "license", "active-dd-link")}
									onClick={(e) => {
										props.setView("license");
										if (!state.MobileNav) {
											setState({ ...state.MobileNav, MobileNav: !state.MobileNav });
										}
									}}
								>
									License
								</a>
							</li>
						),
						props.parentState.ActiveOrganization && props.parentState.ActiveOrganization.myAccessLevel <= 2 && (
							<li>
								<a
									className={Match(props.view, "roles", "active-dd-link")}
									onClick={(e) => {
										props.setView("roles");
										if (!state.MobileNav) {
											setState({ ...state.MobileNav, MobileNav: !state.MobileNav });
										}
									}}
								>
									Roles
								</a>
								{/* <ul className="side-nav-nested-ul2">
              {tempRoles.map((role) => {
                if (role === 'Owner') {
                  return <li><a className={Match(props.view, role.toLowerCase(),'active-dd-link')} onClick={e => props.setView('roles')}>{role}</a> <img src="../../photos/icons/dashboard/SVG/lock-gray.svg" width="7" className="muted" /></li>;                  
                }
                return <li><a className={Match(props.view, role.toLowerCase(),'active-dd-link')} onClick={e => props.setView('roles')}>{role}</a></li>;
              })}
              <UiButton className="muted no-padding smaller-text" variant="link-secondary" plus>Add Role</UiButton>
            </ul> */}
							</li>
						),
					]}
				>
					Overview
				</SideNavLink>

				{props.parentState.ActiveOrganization &&
					props.parentState.ActiveOrganization.myAccessLevel <= 2 &&
					(props.parentState.ActiveOrganization.freeTrial > 0 || props.parentState.ActiveOrganization.license.length > 0) && (
						<SideNavLink
							active={Match(props.view, "dashboard")}
							onClick={(e) => {
								props.setState({ ...props.parentState, View: "dashboard", Refresh: 1 });
							}}
							icon="dashboard"
							disabled={false}
							collapsed={props.collapsed}
						>
							Dashboard
						</SideNavLink>
					)}

				<SideNavLink
					active={props.view === "profile" || props.view === "security" || props.view === "notifications" ? true : false}
					onClick={(e) => {
						props.setView("profile");
						// if (!state.MobileNav) {
						//   setState({...state.MobileNav, MobileNav: !state.MobileNav})
						// }
					}}
					icon="profile"
					collapsed={props.collapsed}
					ActiveOrganization={true}
					list={[
						<li>
							<a
								className={Match(props.view, "security", "active-dd-link")}
								onClick={(e) => {
									props.setView("security");
									if (!state.MobileNav) {
										setState({ ...state.MobileNav, MobileNav: !state.MobileNav });
									}
								}}
							>
								Security
							</a>
						</li>,
						<li>
							<a
								className={Match(props.view, "notifications", "active-dd-link")}
								onClick={(e) => {
									props.setView("notifications");
									if (!state.MobileNav) {
										setState({ ...state.MobileNav, MobileNav: !state.MobileNav });
									}
								}}
							>
								Notifications
							</a>
						</li>,
					]}
				>
					Profile
				</SideNavLink>
				{props.parentState.BodioAdmin && (
					<SideNavLink
						active={props.view === "adminTools" ? true : false}
						onClick={(e) => {
							props.setView("adminTools");
						}}
						icon="gear"
					>
						Admin Tools
					</SideNavLink>
				)}
			</article>
			<img src="../../photos/icons/dashboard/SVG/nav-toggle-15.svg" className={"mobile-nav-collapse-icon"} width="40" onClick={(e) => HandleMobileCollapse(e)} />
			<img
				src="../../photos/icons/dashboard/SVG/double-caret-stroke-gray.svg"
				className={"side-nav-collapse-icon" + (props.collapsed ? " reverse" : "")}
				width="30"
				onClick={(e) => HandleCollapse(e)}
			/>
		</div>
	);
}
