import React, { useState, Fragment, useRef, useEffect } from "react";
import { Col, Row, Form, Alert, Button, Popover, OverlayTrigger, Modal, Tooltip, Overlay, Spinner, Container } from "react-bootstrap";
import PhoneInput, { isPossiblePhoneNumber, formatPhoneNumberIntl, parsePhoneNumber } from "react-phone-number-input";
import Datetime from "react-datetime";
import moment from "moment";

import { CountryList } from "./CountryList";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-phone-number-input/style.css";
import "react-datetime/css/react-datetime.css";
import "../Assets/style.scss";

const RelationOptions = ["Parent", "Grandparent", "Spouse", "Father", "Mother", "Sister", "Brother", "Cousin", "Roommate", "Neighbour", "Close Relative", "Distant Relative", "Friend", "Other"];

const Medical = ({ data, requiredDb, validatedDb, isValidDb, pageCompleted, setPageCompleted, activeIndex, viewInvalidFields, onChange }) => {
	const [validatedFields, setValidatedFields] = useState({ ...validatedDb });
	const [isValidFields, setIsValidFields] = useState({ ...isValidDb });
	const requiredFields = { ...requiredDb };

	const Separator = () => {
		return <div style={{ width: "100%", height: "1px", background: "var(--primary-gradient)", marginTop: "25px", marginBottom: "25px", position: "relative" }}></div>;
	};

	const Header = ({ label }) => {
		return <h2 style={{ textAlign: "center", marginBottom: "20px" }}>{label}</h2>;
	};

	const IsFieldValid = (str, type) => {
		if (type == "text") {
			return !!str;
		} else if (type == "email") {
			return !!str && str.toLowerCase().match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
		}
		return true;
	};

	const CheckIfFieldsAreValid = () => {
		var isValid = true;
		isValid = isValid && (requiredFields.EmergencyFirstName ? isValidFields.EmergencyFirstName : true);
		
		isValid = isValid && (requiredFields.EmergencyLastName ? isValidFields.EmergencyLastName : true);
		isValid = isValid && (requiredFields.EmergencyPhone ? isValidFields.EmergencyPhone : true);
		isValid = isValid && (requiredFields.EmergencyPhoneExt ? isValidFields.EmergencyPhoneExt : true);
		isValid = isValid && (requiredFields.EmergencyRelation ? isValidFields.EmergencyRelation : true);
		isValid = isValid && (requiredFields.EmergencyEmail ? isValidFields.EmergencyEmail : true);
		isValid = isValid && (requiredFields.EmergencyAddress.Country ? isValidFields.EmergencyAddress.Country : true);
		isValid = isValid && (requiredFields.EmergencyAddress.Province ? isValidFields.EmergencyAddress.Province : true);
		isValid = isValid && (requiredFields.EmergencyAddress.City ? isValidFields.EmergencyAddress.City : true);
		isValid = isValid && (requiredFields.EmergencyAddress.StreetAddress1 ? isValidFields.EmergencyAddress.StreetAddress1 : true);
		isValid = isValid && (requiredFields.EmergencyAddress.StreetAddress2 ? isValidFields.EmergencyAddress.StreetAddress2 : true);
		isValid = isValid && (requiredFields.EmergencyAddress.PostalCode ? isValidFields.EmergencyAddress.PostalCode : true);
		isValid = isValid && (requiredFields.PhysicianFirstName ? isValidFields.PhysicianFirstName : true);
		isValid = isValid && (requiredFields.PhysicianLastName ? isValidFields.PhysicianLastName : true);
		isValid = isValid && (requiredFields.PhysicianPhone ? isValidFields.PhysicianPhone : true);
		isValid = isValid && (requiredFields.PhysicianPhoneExt ? isValidFields.PhysicianPhoneExt : true);
		isValid = isValid && (requiredFields.PhysicianClinic ? isValidFields.PhysicianClinic : true);
		isValid = isValid && (requiredFields.PhysicianEmail ? isValidFields.PhysicianEmail : true);
		isValid = isValid && (requiredFields.PhysicianFax ? isValidFields.PhysicianFax : true);
		// isValid = isValid && (requiredFields.Injury ? isValidFields.Injury : true);
		isValid = isValid && (requiredFields.PhysicianAddress.Country ? isValidFields.PhysicianAddress.Country : true);
		isValid = isValid && (requiredFields.PhysicianAddress.City ? isValidFields.PhysicianAddress.City : true);
		isValid = isValid && (requiredFields.PhysicianAddress.Province ? isValidFields.PhysicianAddress.Province : true);
		isValid = isValid && (requiredFields.PhysicianAddress.StreetAddress1 ? isValidFields.PhysicianAddress.StreetAddress1 : true);
		isValid = isValid && (requiredFields.PhysicianAddress.StreetAddress2 ? isValidFields.PhysicianAddress.StreetAddress2 : true);
		isValid = isValid && (requiredFields.PhysicianAddress.PostalCode ? isValidFields.PhysicianAddress.PostalCode : true);
		return isValid;
	};

	const InitialFieldsValidity = () => {
		var cIsValid = { ...isValidFields };
		cIsValid.EmergencyFirstName = IsFieldValid(data.EmergencyFirstName, "text");
		cIsValid.EmergencyLastName = IsFieldValid(data.EmergencyLastName, "text");
		cIsValid.EmergencyPhone = IsFieldValid(data.EmergencyPhone, "text");
		cIsValid.EmergencyPhoneExt = IsFieldValid(data.EmergencyPhoneExt, "text");
		cIsValid.EmergencyRelation = IsFieldValid(data.EmergencyRelation, "text");
		cIsValid.EmergencyEmail = IsFieldValid(data.EmergencyEmail, "email");
		cIsValid.EmergencyAddress.Country = IsFieldValid(data.EmergencyAddress.Country, "text");
		cIsValid.EmergencyAddress.City = IsFieldValid(data.EmergencyAddress.City, "text");
		cIsValid.EmergencyAddress.Province = IsFieldValid(data.EmergencyAddress.Province, "text");
		cIsValid.EmergencyAddress.StreetAddress1 = IsFieldValid(data.EmergencyAddress.StreetAddress1, "text");
		cIsValid.EmergencyAddress.StreetAddress2 = IsFieldValid(data.EmergencyAddress.StreetAddress2, "text");
		cIsValid.EmergencyAddress.PostalCode = IsFieldValid(data.EmergencyAddress.PostalCode, "text");
		cIsValid.PhysicianFirstName = IsFieldValid(data.PhysicianFirstName, "text");
		cIsValid.PhysicianLastName = IsFieldValid(data.PhysicianLastName, "text");
		cIsValid.PhysicianPhone = IsFieldValid(data.PhysicianPhone, "text");
		cIsValid.PhysicianPhoneExt = IsFieldValid(data.PhysicianPhoneExt, "text");
		cIsValid.PhysicianClinic = IsFieldValid(data.PhysicianClinic, "text");
		cIsValid.PhysicianEmail = IsFieldValid(data.PhysicianEmail, "email");
		cIsValid.PhysicianFax = IsFieldValid(data.PhysicianFax, "text");
		cIsValid.Injury = IsFieldValid(data.Injury, "text");
		cIsValid.PhysicianAddress.Country = IsFieldValid(data.PhysicianAddress.Country, "text");
		cIsValid.PhysicianAddress.City = IsFieldValid(data.PhysicianAddress.City, "text");
		cIsValid.PhysicianAddress.Province = IsFieldValid(data.PhysicianAddress.Province, "text");
		cIsValid.PhysicianAddress.StreetAddress1 = IsFieldValid(data.PhysicianAddress.StreetAddress1, "text");
		cIsValid.PhysicianAddress.StreetAddress2 = IsFieldValid(data.PhysicianAddress.StreetAddress2, "text");
		cIsValid.PhysicianAddress.PostalCode = IsFieldValid(data.PhysicianAddress.PostalCode, "text");
		return cIsValid;
	};

	useEffect(() => {
		
		pageCompleted[activeIndex] = CheckIfFieldsAreValid();
		setPageCompleted([...pageCompleted]);
		
	}, [isValidFields]);

	useEffect(() => {
		setIsValidFields({ ...InitialFieldsValidity() });
	}, []);

	useEffect(() => {
		const iterate = (obj) => {
			Object.keys(obj).forEach((key) => {
				if (typeof obj[key] === "object" && obj[key] !== null) {
					iterate(obj[key]);
				} else {
					obj[key] = true;
				}
			});
		};
		if (viewInvalidFields) {
			window.scrollTo({
				top: "0",
				behavior: "smooth",
			});
			var newObj = { ...validatedFields };
			iterate(newObj);
			setValidatedFields({ ...newObj });
		}
	}, [viewInvalidFields]);

	return (
		<div className="intake" style={styles.screen}>
			<Container fluid>
				<Header label={"Medical Information"} />

				{/* emergency first name & last name */}
				<Row>
					<Col md={2}>
						<Form.Label>Emergency First Name {requiredFields.EmergencyFirstName ? "*" : ""}</Form.Label>
					</Col>
					<Col md={4}>
						<Form.Group className={validatedFields.EmergencyFirstName && requiredFields.EmergencyFirstName && !isValidFields.EmergencyFirstName ? "isinvalid" : "isvalid"}>
							<Form.Control
								placeholder="first name"
								value={data.EmergencyFirstName}
								onChange={(evt) => {
									onChange({ ...data, EmergencyFirstName: evt.target.value });
									setValidatedFields({ ...validatedFields, EmergencyFirstName: true });
									setIsValidFields({ ...isValidFields, EmergencyFirstName: IsFieldValid(evt.target.value, "text") });
								}}
							/>
						</Form.Group>
					</Col>
					<Col md={2}>
						<Form.Label>Emergency Last Name {requiredFields.EmergencyLastName ? "*" : ""}</Form.Label>
					</Col>
					<Col md={4}>
						<Form.Group className={validatedFields.EmergencyLastName && requiredFields.EmergencyLastName && !isValidFields.EmergencyLastName ? "isinvalid" : "isvalid"}>
							<Form.Control
								placeholder="last name"
								value={data.EmergencyLastName}
								onChange={(evt) => {
									onChange({ ...data, EmergencyLastName: evt.target.value });
									setValidatedFields({ ...validatedFields, EmergencyLastName: true });
									setIsValidFields({ ...isValidFields, EmergencyLastName: IsFieldValid(evt.target.value, "text") });
								}}
							/>
						</Form.Group>
					</Col>
				</Row>

				{/* emergency relation & email */}
				<Row>
					<Col md={2}>
						<Form.Group>
							<Form.Label>Relationship {requiredFields.EmergencyRelation ? "*" : ""}</Form.Label>
						</Form.Group>
					</Col>
					<Col md={4}>
						<Form.Group className={validatedFields.EmergencyRelation && requiredFields.EmergencyRelation && !isValidFields.EmergencyRelation ? "isinvalid" : "isvalid"}>
							<Form.Control
								as="select"
								onChange={(evt) => {
									onChange({ ...data, EmergencyRelation: evt.target.value });
									setValidatedFields({ ...validatedFields, EmergencyRelation: true });
									setIsValidFields({ ...isValidFields, EmergencyRelation: IsFieldValid(evt.target.value, "text") });
								}}
							>
								<option selected></option>
								{RelationOptions.map((relation) => {
									return <option selected={data.EmergencyRelation === relation ? true : false}>{relation}</option>;
								})}
							</Form.Control>
						</Form.Group>
					</Col>
					<Col md={2}>
						<Form.Label>Email {requiredFields.EmergencyEmail ? "*" : ""}</Form.Label>
					</Col>
					<Col md={4}>
						<Form.Group className={validatedFields.EmergencyEmail && requiredFields.EmergencyEmail && !isValidFields.EmergencyEmail ? "isinvalid" : "isvalid"}>
							<Form.Control
								placeholder="email"
								value={data.EmergencyEmail}
								onChange={(evt) => {
									onChange({ ...data, EmergencyEmail: evt.target.value });
									setValidatedFields({ ...validatedFields, EmergencyEmail: true });
									setIsValidFields({ ...isValidFields, EmergencyEmail: IsFieldValid(evt.target.value, "email") });
								}}
							/>
						</Form.Group>
					</Col>
				</Row>

				{/* emergency phone number & extension */}
				<Row>
					<Col md={2}>
						<Form.Group>
							<Form.Label>Emergency Number {requiredFields.EmergencyPhone ? "*" : ""}</Form.Label>
						</Form.Group>
					</Col>
					<Col md={4}>
						<div className={validatedFields.EmergencyPhone && requiredFields.EmergencyPhone && !isValidFields.EmergencyPhone ? "isinvalid" : "isvalid"}>
							<PhoneInput
								placeholder="phone number"
								defaultCountry={"CA"}
								value={formatPhoneNumberIntl(data.EmergencyPhone)}
								onChange={(value) => {
									onChange({
										...data,
										EmergencyPhone: value,
									});
									setValidatedFields({ ...validatedFields, EmergencyPhone: true });
									setIsValidFields({ ...isValidFields, EmergencyPhone: IsFieldValid(value, "text") });
								}}
							/>
						</div>
					</Col>
					<Col md={2}>
						<Form.Group className={validatedFields.EmergencyPhoneExt && requiredFields.EmergencyPhoneExt && !isValidFields.EmergencyPhoneExt ? "isinvalid" : "isvalid"}>
							<Form.Control
								placeholder="ext"
								value={data.EmergencyPhoneExt}
								onChange={(evt) => {
									onChange({
										...data,
										EmergencyPhoneExt: evt.target.value,
									});
									setValidatedFields({ ...validatedFields, EmergencyPhoneExt: true });
									setIsValidFields({ ...isValidFields, EmergencyPhoneExt: IsFieldValid(evt.target.value, "text") });
								}}
							/>
						</Form.Group>
					</Col>
				</Row>

				<Separator />

				{/* family physician first & last name */}
				<Row>
					<Col md={2}>
						<Form.Label>Family Physician First Name {requiredFields.PhysicianFirstName ? "*" : ""}</Form.Label>
					</Col>
					<Col md={4}>
						<Form.Group className={validatedFields.PhysicianFirstName && requiredFields.PhysicianFirstName && !isValidFields.PhysicianFirstName ? "isinvalid" : "isvalid"}>
							<Form.Control
								placeholder="first name"
								value={data.PhysicianFirstName}
								onChange={(evt) => {
									onChange({ ...data, PhysicianFirstName: evt.target.value });
									setValidatedFields({ ...validatedFields, PhysicianFirstName: true });
									setIsValidFields({ ...isValidFields, PhysicianFirstName: IsFieldValid(evt.target.value, "text") });
								}}
							/>
						</Form.Group>
					</Col>
					<Col md={2}>
						<Form.Label>Family Physician Last Name {requiredFields.PhysicianLastName ? "*" : ""}</Form.Label>
					</Col>
					<Col md={4}>
						<Form.Group className={validatedFields.PhysicianLastName && requiredFields.PhysicianLastName && !isValidFields.PhysicianLastName ? "isinvalid" : "isvalid"}>
							<Form.Control
								placeholder="last name"
								value={data.PhysicianLastName}
								onChange={(evt) => {
									onChange({ ...data, PhysicianLastName: evt.target.value });
									setValidatedFields({ ...validatedFields, PhysicianLastName: true });
									setIsValidFields({ ...isValidFields, PhysicianLastName: IsFieldValid(evt.target.value, "text") });
								}}
							/>
						</Form.Group>
					</Col>
				</Row>

				{/* family physician clinic & email */}
				<Row>
					<Col md={2}>
						<Form.Label>Clinic Name {requiredFields.PhysicianClinic ? "*" : ""}</Form.Label>
					</Col>
					<Col md={4}>
						<Form.Group className={validatedFields.PhysicianClinic && requiredFields.PhysicianClinic && !isValidFields.PhysicianClinic ? "isinvalid" : "isvalid"}>
							<Form.Control
								placeholder="clinic name"
								value={data.PhysicianClinic}
								onChange={(evt) => {
									onChange({
										...data,
										PhysicianClinic: evt.target.value,
									});
									setValidatedFields({ ...validatedFields, PhysicianClinic: true });
									setIsValidFields({ ...isValidFields, PhysicianClinic: IsFieldValid(evt.target.value, "text") });
								}}
							/>
						</Form.Group>
					</Col>
					<Col md={2}>
						<Form.Label>Email {requiredFields.PhysicianEmail ? "*" : ""}</Form.Label>
					</Col>
					<Col md={4}>
						<Form.Group className={validatedFields.PhysicianEmail && requiredFields.PhysicianEmail && !isValidFields.PhysicianEmail ? "isinvalid" : "isvalid"}>
							<Form.Control
								placeholder="email"
								value={data.PhysicianEmail}
								onChange={(evt) => {
									onChange({
										...data,
										PhysicianEmail: evt.target.value,
									});
									setValidatedFields({ ...validatedFields, PhysicianEmail: true });
									setIsValidFields({ ...isValidFields, PhysicianEmail: IsFieldValid(evt.target.value, "email") });
								}}
							/>
						</Form.Group>
					</Col>
				</Row>

				{/* family physician street address 1 & 2 */}
				{/* <Row>
					<Col md={2}>
						<Form.Label>Address Line 1 {requiredFields.PhysicianAddress.StreetAddress1 ? "*" : ""}</Form.Label>
					</Col>
					<Col md={4}>
						<Form.Group
							className={validatedFields.PhysicianAddress.StreetAddress1 && requiredFields.PhysicianAddress.StreetAddress1 && !isValidFields.PhysicianAddress.StreetAddress1 ? "isinvalid" : "isvalid"}
						>
							<Form.Control
								placeholder="address line 1"
								value={data.PhysicianAddress.StreetAddress1}
								onChange={(evt) => {
									onChange({
										...data,
										PhysicianAddress: {
											...data.PhysicianAddress,
											StreetAddress1: evt.target.value,
										},
									});
									setValidatedFields({
										...validatedFields,
										PhysicianAddress: {
											...validatedFields.PhysicianAddress,
											StreetAddress1: true,
										},
									});
									setIsValidFields({
										...isValidFields,
										PhysicianAddress: {
											...isValidFields.PhysicianAddress,
											StreetAddress1: IsFieldValid(evt.target.value, "text"),
										},
									});
								}}
							/>
						</Form.Group>
					</Col>
					<Col md={2}>
						<Form.Label>Address Line 2 {requiredFields.PhysicianAddress.StreetAddress2 ? "*" : ""}</Form.Label>
					</Col>
					<Col md={4}>
						<Form.Group
							className={validatedFields.PhysicianAddress.StreetAddress2 && requiredFields.PhysicianAddress.StreetAddress2 && !isValidFields.PhysicianAddress.StreetAddress2 ? "isinvalid" : "isvalid"}
						>
							<Form.Control
								placeholder="address line 2"
								value={data.PhysicianAddress.StreetAddress2}
								onChange={(evt) => {
									onChange({
										...data,
										PhysicianAddress: {
											...data.PhysicianAddress,
											StreetAddress2: evt.target.value,
										},
									});
									setValidatedFields({
										...validatedFields,
										PhysicianAddress: {
											...validatedFields.PhysicianAddress,
											StreetAddress2: true,
										},
									});
									setIsValidFields({
										...isValidFields,
										PhysicianAddress: {
											...isValidFields.PhysicianAddress,
											StreetAddress2: IsFieldValid(evt.target.value, "text"),
										},
									});
								}}
							/>
						</Form.Group>
					</Col>
				</Row> */}

				{/* family physician province & city */}
				{/* <Row>
					<Col md={2}>
						<Form.Label>Province {requiredFields.PhysicianAddress.Province ? "*" : ""}</Form.Label>
					</Col>
					<Col md={4}>
						<Form.Group className={validatedFields.PhysicianAddress.Province && requiredFields.PhysicianAddress.Province && !isValidFields.PhysicianAddress.Province ? "isinvalid" : "isvalid"}>
							<Form.Control
								placeholder="province"
								value={data.PhysicianAddress.Province}
								onChange={(evt) => {
									onChange({
										...data,
										PhysicianAddress: {
											...data.PhysicianAddress,
											Province: evt.target.value,
										},
									});
									setValidatedFields({
										...validatedFields,
										PhysicianAddress: {
											...validatedFields.PhysicianAddress,
											Province: true,
										},
									});
									setIsValidFields({
										...isValidFields,
										PhysicianAddress: {
											...isValidFields.PhysicianAddress,
											Province: IsFieldValid(evt.target.value, "text"),
										},
									});
								}}
							/>
						</Form.Group>
					</Col>
					<Col md={2}>
						<Form.Label>City {requiredFields.PhysicianAddress.City ? "*" : ""}</Form.Label>
					</Col>
					<Col md={4}>
						<Form.Group className={validatedFields.PhysicianAddress.City && requiredFields.PhysicianAddress.City && !isValidFields.PhysicianAddress.City ? "isinvalid" : "isvalid"}>
							<Form.Control
								placeholder="city"
								value={data.PhysicianAddress.City}
								onChange={(evt) => {
									onChange({
										...data,
										PhysicianAddress: {
											...data.PhysicianAddress,
											City: evt.target.value,
										},
									});
									setValidatedFields({
										...validatedFields,
										PhysicianAddress: {
											...validatedFields.PhysicianAddress,
											City: true,
										},
									});
									setIsValidFields({
										...isValidFields,
										PhysicianAddress: {
											...isValidFields.PhysicianAddress,
											City: IsFieldValid(evt.target.value, "text"),
										},
									});
								}}
							/>
						</Form.Group>
					</Col>
				</Row> */}

				{/* family physician country & postal code */}
				{/* <Row>
					<Col md={2}>
						<Form.Label>Country {requiredFields.PhysicianAddress.Country ? "*" : ""}</Form.Label>
					</Col>
					<Col md={4}>
						<Form.Group className={validatedFields.PhysicianAddress.Country && requiredFields.PhysicianAddress.Country && !isValidFields.PhysicianAddress.Country ? "isinvalid" : "isvalid"}>
							<Form.Control
								as="select"
								onChange={(evt) => {
									onChange({
										...data,
										PhysicianAddress: {
											...data.PhysicianAddress,
											Country: evt.target.value,
										},
									});
									setValidatedFields({
										...validatedFields,
										PhysicianAddress: {
											...validatedFields.PhysicianAddress,
											Country: true,
										},
									});
									setIsValidFields({
										...isValidFields,
										PhysicianAddress: {
											...isValidFields.PhysicianAddress,
											Country: IsFieldValid(evt.target.value, "text"),
										},
									});
								}}
							>
								<option selected></option>
								{CountryList.map((country) => {
									return <option selected={data.PhysicianAddress.Country === country ? true : false}>{country}</option>;
								})}
							</Form.Control>
						</Form.Group>
					</Col>
					<Col md={2}>
						<Form.Label>Postal Code {requiredFields.PhysicianAddress.PostalCode ? "*" : ""}</Form.Label>
					</Col>
					<Col md={4}>
						<Form.Group className={validatedFields.PhysicianAddress.PostalCode && requiredFields.PhysicianAddress.PostalCode && !isValidFields.PhysicianAddress.PostalCode ? "isinvalid" : "isvalid"}>
							<Form.Control
								placeholder="postal code"
								value={data.PhysicianAddress.PostalCode}
								onChange={(evt) => {
									onChange({
										...data,
										PhysicianAddress: {
											...data.PhysicianAddress,
											PostalCode: evt.target.value,
										},
									});
									setValidatedFields({
										...validatedFields,
										PhysicianAddress: {
											...validatedFields.PhysicianAddress,
											PostalCode: true,
										},
									});
									setIsValidFields({
										...isValidFields,
										PhysicianAddress: {
											...isValidFields.PhysicianAddress,
											PostalCode: IsFieldValid(evt.target.value, "text"),
										},
									});
								}}
							/>
						</Form.Group>
					</Col>
				</Row> */}

				{/* family physician phone & ext */}
				<Row>
					<Col md={2}>
						<Form.Group>
							<Form.Label>Physician Phone Number {requiredFields.PhysicianPhone ? "*" : ""}</Form.Label>
						</Form.Group>
					</Col>
					<Col md={4}>
						<div className={validatedFields.PhysicianPhone && requiredFields.PhysicianPhone && !isValidFields.PhysicianPhone ? "isinvalid" : "isvalid"}>
							<PhoneInput
								placeholder="phone number"
								defaultCountry={"CA"}
								value={formatPhoneNumberIntl(data.PhysicianPhone)}
								onChange={(value) => {
									onChange({
										...data,
										PhysicianPhone: value,
									});
									setValidatedFields({ ...validatedFields, PhysicianPhone: true });
									setIsValidFields({ ...isValidFields, PhysicianPhone: IsFieldValid(value, "text") });
								}}
							/>
						</div>
					</Col>
					<Col md={2}>
						<Form.Group className={validatedFields.PhysicianPhoneExt && requiredFields.PhysicianPhoneExt && !isValidFields.PhysicianPhoneExt ? "isinvalid" : "isvalid"}>
							<Form.Control
								placeholder="ext"
								value={data.PhysicianPhoneExt}
								onChange={(evt) => {
									onChange({
										...data,
										PhysicianPhoneExt: evt.target.value,
									});
									setValidatedFields({ ...validatedFields, PhysicianPhoneExt: true });
									setIsValidFields({ ...isValidFields, PhysicianPhoneExt: IsFieldValid(evt.target.value, "text") });
								}}
							/>
						</Form.Group>
					</Col>
				</Row>

				{/* family physician fax */}
				<Row>
					<Col md={2}>
						<Form.Label>Fax Number {requiredFields.PhysicianFax ? "*" : ""}</Form.Label>
					</Col>
					<Col md={4}>
						<Form.Group className={validatedFields.PhysicianFax && requiredFields.PhysicianFax && !isValidFields.PhysicianFax ? "isinvalid" : "isvalid"}>
							<Form.Control
								placeholder="fax number"
								value={data.PhysicianFax}
								onChange={(evt) => {
									onChange({
										...data,
										PhysicianFax: evt.target.value,
									});
									setValidatedFields({ ...validatedFields, PhysicianFax: true });
									setIsValidFields({ ...isValidFields, PhysicianFax: IsFieldValid(evt.target.value, "text") });
								}}
							/>
						</Form.Group>
					</Col>
				</Row>
			</Container>
		</div>
	);
};

const styles = {
	screen: {
		margin: "20px auto",
		maxWidth: "800px",
	},
};

export default Medical;
