import React, { useState, useEffect, Fragment } from "react";
import { Nav, Navbar, Image, NavDropdown, Container, Row, Col } from "react-bootstrap";
import { NavLink, Link } from "react-router-dom";
import { NavHashLink } from "react-router-hash-link";
import { useMediaQuery } from "react-responsive";

import "bootstrap/dist/css/bootstrap.min.css";
import "../resources/styles/navbar.scss";
import "../App.css";

import storage from "local-storage";

export default function NavBar() {
  const [state, setState] = useState({
    cart: storage.get("productlist") || [],
    showSolutionNavLinks: false,
    showForProfessionalNavLinks: false,
    active: false,
  });

  // const isDesktopOrLaptop = useMediaQuery({ query: '(min-width: 1225px)' });
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });

  const ShowSolutionNavDropdown = () => {
    setState({ ...state, showSolutionNavLinks: true });
  };

  const ShowForProfessionalNavDropdown = () => {
    setState({ ...state, showForProfessionalNavLinks: true });
  };

  const HideSolutionNavDropdown = () => {
    setState({ ...state, showSolutionNavLinks: false });
  };

  const HideForProfessionalNavDropdown = () => {
    setState({ ...state, showForProfessionalNavLinks: false });
  };

  const SetActive = (e) => {
    setState({ ...state, active: 1 });
  };

  useEffect(() => {
    window.addEventListener("scroll", HandleScroll);
    return () => {
      window.removeEventListener("scroll", HandleScroll);
    };
  }, []);

  const HandleScroll = () => {
    if (window.scrollY >= 120) {
      document.querySelector("#main-navbar img").style.width = "250px";
      document.querySelector("#main-navbar").style.padding = ".5em 3.15em";
      document.querySelector("#main-navbar").style.background = "rgba(255,255,255,1)";
      document.querySelector("#main-navbar .nav-hr").style.width = "0px";
      if (document.querySelector(".portal-nav-links-wrapper")) {
        document.querySelector(".portal-nav-links-wrapper").style.top = "6px";
      }
    } else if (window.scrollY < 30) {
      document.querySelector("#main-navbar").style.padding = "1.4em 3.15em";
      document.querySelector("#main-navbar img").style.width = "330px";
      document.querySelector("#main-navbar .nav-hr").style.width = "300px";
      document.querySelector("#main-navbar").style.background = "rgba(255,255,255,1)";
      if (document.querySelector(".portal-nav-links-wrapper")) {
        document.querySelector(".portal-nav-links-wrapper").style.top = "28px";
      }
    }
  };

  const DesktopIcon = () => {
    return (
      <svg viewBox="0 0 24 24">
        <path
          fill="#6c7d85"
          d="M21,14H3V4H21M21,2H3C1.89,2 1,2.89 1,4V16A2,2 0 0,0 3,18H10L8,21V22H16V21L14,18H21A2,2 0 0,0 23,16V4C23,2.89 22.1,2 21,2Z"
        />
      </svg>
    );
  };

  const MobileIcon = () => {
    return (
      <svg viewBox="0 0 24 24">
        <path
          fill="#6c7d85"
          d="M17,19H7V5H17M17,1H7C5.89,1 5,1.89 5,3V21A2,2 0 0,0 7,23H17A2,2 0 0,0 19,21V3C19,1.89 18.1,1 17,1Z"
        />
      </svg>
    );
  };

  const scrollWithOffset = (el) => {
    const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
    const yOffset = 900;
    window.scrollTo({ top: yCoordinate + yOffset, behavior: "smooth" });
  };

  const dbUrl = "https://bodiometerhomerestapi-dot-project-4839952831808961167.appspot.com";

  const RegisterEvent = (eventSource) => {
    let myHeaders = new Headers();
    myHeaders.append("password", "746C419785D84DC8B3A20B9195363105");

    let requestOptions = {
      method: "POST",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(dbUrl + "/api/token/dbo", requestOptions)
      .then((response) => response.text())
      .then((authKey) => {
        let myHeadersOne = new Headers();
        myHeadersOne.append("Authorization", "Bearer " + authKey);
        myHeadersOne.append("Content-Type", "application/json");

        let rawOne = JSON.stringify({
          eventName: `${eventSource}`,
          eventTime: `${new Date().toJSON()}`,
          eventOffset: "0",
        });

        let requestOptionsOne = {
          method: "POST",
          headers: myHeadersOne,
          body: rawOne,
          redirect: "follow",
        };

        fetch(dbUrl + "/api/eventtrackers/v1/create", requestOptionsOne)
          .then((responseOne) => responseOne.text())
          .then((resultOne) => console.log(resultOne))
          .catch((errorOne) => console.log("error", errorOne));
      })
      .catch((error) => console.log("error", error));
  };

  return (
    <Fragment>
      {/* <div style={{textAlign: "center", backgroundColor: "#E34140", color: "#fbfbfd", position: "relative", left: "0px", width: "100%", height: "30px", zIndex: "9999", top: "0px", padding: "5px"}}>
      Ready to increase patient engagment with Bodiometer Dashboard? Register today and claim your <del>14 day</del> 30 day Free Trial! <a style={{color: "white"}} target="_blank" href="/portal/register">{'>'}</a>
      </div> */}
      {/* <Navbar expand="lg" sticky="top" style={{zIndex: "1050px", backgroundColor: "#E34140", color: "#fbfbfd"}}>
        
          <Nav>
          Ready to increase patient engagment with Bodiometer Dashboard? Register today and claim your <del>14 day</del> 30 day Free Trial! <a style={{color: "white"}} target="_blank" href="/portal/register">{'>'}</a>
          </Nav>
        
      </Navbar> */}
      {/* <Container style={{textAlign: "center", backgroundColor: "#E34140", color: "#fbfbfd", padding: "15px"}} fluid>
        <a style={{color: "white"}} target="_blank" href="/portal/register" onClick={() => RegisterEvent("banner register")}>
        <Row>
          
          <Col>
            HOLIDAY GIVEAWAY! Register before Dec. 31, 2021 to get <del>14 day</del> 30 day Free Trial of Bodiometer patient engagement platform. 
          </Col>
          
        </Row>
        </a>
      </Container> */}

      <Navbar expand="lg" variant="light" sticky="top" id="main-navbar" collapseOnSelect>
        <Navbar.Brand href="/">
          <Image src="../../photos/bodiometer-logo.svg" alt="Brand Logo" />
          {/* <Image src="../../photos/bodiometer-logo-holiday.png" alt="Brand Logo" /> */}
          {/* <Image src="../../photos/bodiometer-logo-holiday-1.png" alt="Brand Logo" /> */}
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end">
          <Nav>
            <NavLink exact={true} activeClassName="is-active" className="nav-link" to="/">
              Home
            </NavLink>

            {/* <NavLink activeClassName='is-active' className="nav-link" to="/professionals">For Professionals</NavLink> */}

            {/* <div onMouseEnter={ShowForProfessionalNavDropdown} onMouseLeave={HideForProfessionalNavDropdown}>
              <NavDropdown title="For Professionals" id="pro-dropdown" align="center" show={state.showForProfessionalNavLinks}>
                <NavDropdown.Item target={"_self"} href="/professionals">
                  For Professionals
                </NavDropdown.Item>
                <NavDropdown.Item target={"_blank"} href="/portal/login">
                  Sign In
                </NavDropdown.Item>
                <NavDropdown.Item target={"_blank"} href="/portal/register">
                  Register
                </NavDropdown.Item>
              </NavDropdown>
            </div> */}

            <NavLink activeClassName="is-active" className="nav-link" to="/professionals">
              For Professionals
            </NavLink>

            <div onMouseEnter={ShowSolutionNavDropdown} onMouseLeave={HideSolutionNavDropdown}>
              <NavDropdown title="Solutions" id="dropdown" align="center" show={state.showSolutionNavLinks}>
                <NavDropdown.Item href="/product/pro">
                  {/* <Link to={{pathname: "/product/pro", state: "flushDeal"}} target="_self">
                    {MobileIcon()} Bodiometer Pro
                  </Link> */}
                  {MobileIcon()} Bodiometer Pro
                </NavDropdown.Item>
                <NavDropdown.Item href="/product/desktop">
                  {/* <Link to={{pathname: "/product/desktop", state: "flushDeal"}} target="_self">
                    {DesktopIcon()} Bodiometer Desktop
                  </Link> */}
                  {DesktopIcon()} Bodiometer Desktop
                </NavDropdown.Item>
                <NavDropdown.Item href="/product/dashboard">
                  <img
                    style={{ width: "24px", marginRight: "2px" }}
                    src="../../photos/icons/dashboard/SVG/dashboard-logo-2.svg"
                    alt="Bodiometer body scanner app features - interactive icon"
                  />
                  Bodiometer Dashboard
                </NavDropdown.Item>
              </NavDropdown>
            </div>

            <NavLink activeClassName="is-active" className="nav-link" to="/about">
              About
            </NavLink>

            <NavLink activeClassName="is-active" className="nav-link" to="/news">
              News
            </NavLink>
            <NavLink activeClassName="is-active" className="nav-link" to="/contact">
              Contact
            </NavLink>

            <NavLink activeClassName="is-active" className="nav-link" to="/login">
              Login
            </NavLink>

            <NavLink activeClassName="" className="consumer-download-button" to="/pricing">
              Try Now
            </NavLink>

            <div className="mobile-portal-nav-links">
              <a className="gray-color" href="/portal" target="_blank">
                Login
              </a>
              <a className="gray-color" href="/portal/register" target="_blank">
                Register
              </a>
            </div>
          </Nav>
        </Navbar.Collapse>
        <hr
          className={`nav-hr${
            window.location.pathname !== "/" &&
            window.location.pathname !== "/news" &&
            window.location.pathname !== "/contact" &&
            window.location.pathname !== "/about"
              ? " professionals-nav-hr"
              : ""
          }`}
        ></hr>
      </Navbar>
    </Fragment>
  );
}
