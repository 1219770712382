import React from "react";
import {Button, Popover, OverlayTrigger} from "react-bootstrap";

import UiButton from "./ui-button";

import "../../resources/styles/dashboard.scss";
import "bootstrap/dist/css/bootstrap.min.css";

export default function UiToggleButton(props) {

  let shouldShow = false;

  const HandlePopup = (e, org) => {
    if (props.ActiveOrganization !== org) {
      const organization = props.parentState.Organizations.filter((item) => item.id === org)[0];
      shouldShow = false;
      console.log(org, organization, props.parentState);

      localStorage.setItem("_DO", org);

      props.setState({...props.parentState, Refresh: props.parentState.Refresh + 1, ActiveOrganization: organization, View: 'overview'});
      if (!props.mobileNav) {
        props.setMobileNav({...props.mobileNav, MobileNav: !props.mobileNav});
      }
      // props.setActiveOrganization(org)
    }
  };

  const popover = (
    <Popover id={`popover${Math.floor(Math.random()).toString()}`} rootClose={true} rootCloseEvent="click" show={shouldShow}>
      <Popover.Content>
        <div className="organizations-wrapper">
          <h5>Organizations</h5>
          <ul>
            {props.Organizations.length > 0 &&
              props.Organizations.map((org) => {
                return (
                  <li key={`ui-toggle-li`}>
                    <UiButton onClick={(e) => HandlePopup(e, org.id)} className={props.ActiveOrganization === org.id ? "active-organization" : "white-link"} variant={props.ActiveOrganization === org.id ? "secondary" : "link"}>
                      {org.name}
                      {props.ActiveOrganization === org.id ? <img src="../../photos/icons/dashboard/SVG/check-green.svg" width="15" /> : ""}
                    </UiButton>
                  </li>
                );
              })}
          </ul>
          <UiButton
            onClick={(e) => {
              props.setView("create-organization");
              if (!props.mobileNav) {
                props.setMobileNav({...props.mobileNav, MobileNav: !props.mobileNav});
              }
            }}
            plus
            variant="link"
            className="no-padding smaller-text muted white-link"
          >
            New Organization
          </UiButton>
          {/* <UiButton
            onClick={e => {
              props.setView("join-organization");
              if (!props.mobileNav) {
                props.setMobileNav({...props.mobileNav, MobileNav: !props.mobileNav});
              }
            }}
            plus
            variant="link"
            className="no-padding smaller-text muted white-link"
          >
            Join Organization
          </UiButton> */}
        </div>
      </Popover.Content>
    </Popover>
  );

  return (
    <OverlayTrigger rootClose={true} trigger="click" placement={!props.mobileNav ? "bottom-start" : "right"} overlay={popover}>
      {/* <OverlayTrigger rootClose={true} trigger="click" placement='bottom-start' overlay={popover}> */}
      <Button
        // onClick={e => HandlePopup(e)}
        variant="primary"
        className={props.className}
        style={{textAlign: "center"}}
      >
        {props.next && <span style={{display: "inline-block", transform: "scale(1.1)", width: props.IsSideNavCollapsed ? "80%" : "fit-content"}}>&nbsp; &gt;</span>}
        {props.collapsed ? props.children.slice(0, 1) : props.children}
        {!props.collapsed && <img src={`../../photos/icons/dashboard/SVG/caret-stroke-blue.svg`} height="30" />}
      </Button>
    </OverlayTrigger>
  );
}
