import React, {Fragment, useState} from 'react';
import {Button, Form, Row, Col} from 'react-bootstrap';

import NavBar from "../components/navbar.component.js";
import Footer from "../components/footer.component.js";

import SuccessImg from "../resources/photos/icons/check-circle-solid.svg";

// Custom page meta with react-helmet
import MetaDecorator from "../components/metaDecorator.js";

import "../resources/styles/14DayFreeTrial.scss";

export default function FreeTrial() {

    const [state, setState] = useState({
        firstName: null,
        lastName: null,
        email: null,
        isEmailValid: false,
        hasSubmitted: false
    });

    const IsFormValid = () => {
        return state.isEmailValid && !!(state.firstName) && !!(state.lastName);
    }

    const SendFreeTrialForm = () => {
        fetch("/api/sign-up/14-day-free-trial", 
            {
                headers: {
                    'Content-Type': 'application/json'
                },
                method: "POST",
                body: JSON.stringify({firstName: state.firstName, lastName: state.lastName, email: state.email})
            }
        )
        .then(response => {
            response.json()
            .then(response => {
                if(!!response){
                    setState({...state, hasSubmitted: true});
                }
            })
            .catch(err => console.log(err))
        })
        .catch(err => console.log(err));
    }

    return(
        <Fragment>
            <MetaDecorator
                description="Sign up to recieve 14 days free trial of Bodiometer smart biomechanic scanning and analytics for clinical professionals."
                title="Bodiometer - 14 day free trial"
                url="https://www.bodiometer.com/14-days-free-trial"
            />
            <NavBar />
            <div className="signup-main-container">
                {
                    !state.hasSubmitted &&
                    <Fragment>
                        <Row>
                            <Col>
                                <Form.Label><h2>Get your 14 day free trial</h2></Form.Label>
                                <Form.Control muted onChange={(evt) => setState({...state, firstName: evt.target.value})} type="text" placeholder="First Name" />
                                <Form.Control onChange={(evt) => setState({...state, lastName: evt.target.value})} type="text" placeholder="Last Name" />
                                <Form.Control onChange={(evt) => {
                                    let email = evt.target.value;
                                    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                                    let regex = new RegExp(re);
                                    setState(prev => ({...prev, isEmailValid: regex.test(email)}));
                                    setState(prev => ({...prev, email: email}));
                                }} type="email" placeholder="Email" />
                                <Button disabled={!(IsFormValid())} onClick={() => SendFreeTrialForm()} variant="success" size="md">Submit</Button>
                            </Col>
                        </Row>

                    </Fragment>
                }
                {
                    state.hasSubmitted &&
                    <Fragment>
                        <Form.Label className="submitted-form"><h2>Thank you for your interest in Bodiometer. Someone from the Bodiometer team will contact you in the next 48 hours to set-up your trial license.</h2><img src={SuccessImg} width={32} /></Form.Label>
                    </Fragment>
                }
            </div>
            <Footer />
        </Fragment>
    )
}