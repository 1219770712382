import React, { useState, Fragment, useEffect } from "react";
import { Col, Row, Form, Button, Popover, OverlayTrigger, Spinner } from "react-bootstrap";
import axios from "axios";
import { dbUrl } from "../../../lib/config";

import "bootstrap/dist/css/bootstrap.min.css";
import "./style/style.scss";

const ForcePlate = ({ CaseId, PatientId, BusinessId, onBack }) => {
	const [isLoading, setIsLoading] = useState(false);
	const [isCreatedMode, setIsCreatedMode] = useState(false);
	const [isEditMode, setIsEditMode] = useState(false);
	const [fpDb, setFpDb] = useState([]);
	const [currentKey, setCurrentKey] = useState(-1);
	const [newFp, setNewFp] = useState({});
	const [isCreateFPConfirmationVisible, setIsCreateFPConfirmationVisible] = useState(false);
	const [isUpdateFPConfirmationVisible, setIsUpdateFPConfirmationVisible] = useState(false);
	const [isRemoveFPConfirmationVisible, setIsRemoveFPConfirmationVisible] = useState(false);

	const LoadInitialData = async () => {
		try {
			setIsLoading(true);

			var configOne = {
				method: "POST",
				url: dbUrl + "/api/token/dbo",
				headers: {
					password: "746C419785D84DC8B3A20B9195363105",
				},
			};

			var responseOne = await axios(configOne, { timeout: 5000 });

			if (responseOne.data != "Invalid Credentials") {
				var configTwo = {
					method: "GET",
					url: dbUrl + "/api/forceplate/v1/caseid/patientid",
					headers: {
						Authorization: `Bearer ${responseOne.data}`,
						CaseId,
						PatientId,
					},
				};

				var responseTwo = await axios(configTwo, { timeout: 5000 });

				if (responseTwo.data.success) {
					var db = [...responseTwo.data.data];
					db.sort((a, b) => {
						return new Date(a.CreatedAt).getTime() >= new Date(b.CreatedAt).getTime() ? -1 : 1;
					});
					setCurrentKey(db.length > 0 ? 0 : -1);
					setFpDb([...db]);
					setIsLoading(false);
				} else {
					// handle error
					setIsLoading(false);
				}
			} else {
				// error
				setIsLoading(false);
			}
		} catch (_) {
			setIsLoading(false);
		}
	};

	const UpdateFp = async () => {
		try {
			setIsLoading(true);

			var configOne = {
				method: "POST",
				url: dbUrl + "/api/token/dbo",
				headers: {
					password: "746C419785D84DC8B3A20B9195363105",
				},
			};

			var responseOne = await axios(configOne, { timeout: 5000 });

			if (responseOne.data != "Invalid Credentials") {
				var configTwo = {
					method: "PUT",
					url: dbUrl + "/api/forceplate/v1/update",
					headers: {
						Authorization: `Bearer ${responseOne.data}`,
						"Content-Type": "application/json",
					},
					data: JSON.stringify({ ...newFp }),
				};

				var responseTwo = await axios(configTwo, { timeout: 5000 });

				if (responseTwo.data.success) {
					LoadInitialData();
				} else {
					// handle error
					setIsLoading(false);
				}
			} else {
				// error
				setIsLoading(false);
			}
		} catch (_) {
			setIsLoading(false);
		}
	};

	const CreateFp = async () => {
		try {
			setIsLoading(true);

			var configOne = {
				method: "POST",
				url: dbUrl + "/api/token/dbo",
				headers: {
					password: "746C419785D84DC8B3A20B9195363105",
				},
			};

			var responseOne = await axios(configOne, { timeout: 5000 });

			if (responseOne.data != "Invalid Credentials") {
				var configTwo = {
					method: "POST",
					url: dbUrl + "/api/forceplate/v1/submit",
					headers: {
						Authorization: `Bearer ${responseOne.data}`,
						"Content-Type": "application/json",
					},
					data: JSON.stringify({ ...newFp }),
				};

				var responseTwo = await axios(configTwo, { timeout: 5000 });

				if (responseTwo.data.success) {
					LoadInitialData();
				} else {
					// handle error
					setIsLoading(false);
				}
			} else {
				// error
				setIsLoading(false);
			}
		} catch (_) {
			setIsLoading(false);
		}
	};

	const RemoveFp = async () => {
		try {
			setIsLoading(true);

			var configOne = {
				method: "POST",
				url: dbUrl + "/api/token/dbo",
				headers: {
					password: "746C419785D84DC8B3A20B9195363105",
				},
			};

			var responseOne = await axios(configOne, { timeout: 5000 });

			if (responseOne.data != "Invalid Credentials") {
				var configTwo = {
					method: "DELETE",
					url: dbUrl + "/api/forceplate/v1/remove/id",
					headers: {
						Authorization: `Bearer ${responseOne.data}`,
						"Content-Type": "application/json",
						Id: fpDb[currentKey].Id,
					},
				};

				var responseTwo = await axios(configTwo, { timeout: 5000 });

				if (responseTwo.data.success) {
					LoadInitialData();
				} else {
					// handle error
					setIsLoading(false);
				}
			} else {
				// error
				setIsLoading(false);
			}
		} catch (_) {
			setIsLoading(false);
		}
	};

	useEffect(() => {
		LoadInitialData();
	}, []);

	const RenderFPDateSelectionOverlay = () => {
		return (
			<Popover className="posture-date-overlay-wrapper" style={{ backgroundColor: "var(--primary-gray)" }} id={`popover${Math.floor(Math.random()).toString()}`}>
				<Popover.Content>
					<div>
						<ul>
							{fpDb.map((fp, k) => {
								return (
									<li>
										<Button className={k === currentKey ? "active-date" : "white-link"} onClick={() => setCurrentKey(k)}>
											{AddHoursToDate(fp.CreatedAt, fp.CreatedAtOffset)}
											{k === currentKey ? <img src="../../photos/icons/dashboard/SVG/check-green.svg" width="15" /> : null}
										</Button>
									</li>
								);
							})}
						</ul>
					</div>
				</Popover.Content>
			</Popover>
		);
	};

	const RemoveFPConfirmationOverlay = () => {
		return (
			<Popover className="action-toggle-wrapper roles-toggle-wrapper" id={`popover${Math.floor(Math.random()).toString()}`}>
				<Popover.Content>
					<Row>
						<Col>
							<h3 className="green-color">Do you want to remove the selected assessment?</h3>
						</Col>
					</Row>
					<Row>
						<Col>
							<Button
								style={{ background: "var(--logo-color-2)" }}
								onClick={(e) => {
									setIsRemoveFPConfirmationVisible(false);
									setIsCreatedMode(false);
									setIsEditMode(false);
									RemoveFp();
								}}
							>
								Yes
							</Button>
							<Button style={{ background: "var(--logo-color-1)" }} onClick={(e) => setIsRemoveFPConfirmationVisible(false)}>
								Cancel
							</Button>
						</Col>
					</Row>
				</Popover.Content>
			</Popover>
		);
	};

	const CreateFPConfirmationOverlay = () => {
		return (
			<Popover className="action-toggle-wrapper roles-toggle-wrapper" id={`popover${Math.floor(Math.random()).toString()}`}>
				<Popover.Content>
					<Row>
						<Col>
							<h3 className="green-color">Do you want to create a new assessment?</h3>
						</Col>
					</Row>
					<Row>
						<Col>
							<Button
								style={{ background: "var(--logo-color-2)" }}
								onClick={(e) => {
									setIsCreateFPConfirmationVisible(false);
									setIsCreatedMode(false);
									setIsEditMode(false);
									CreateFp();
								}}
							>
								Yes
							</Button>
							<Button style={{ background: "var(--logo-color-1)" }} onClick={(e) => setIsCreateFPConfirmationVisible(false)}>
								Cancel
							</Button>
						</Col>
					</Row>
				</Popover.Content>
			</Popover>
		);
	};

	const UpdateFPConfirmationOverlay = () => {
		return (
			<Popover className="action-toggle-wrapper roles-toggle-wrapper" id={`popover${Math.floor(Math.random()).toString()}`}>
				<Popover.Content>
					<Row>
						<Col>
							<h3 className="green-color">Are you sure you want to submit the answers?</h3>
						</Col>
					</Row>
					<Row>
						<Col>
							<Button
								style={{ background: "var(--logo-color-2)" }}
								onClick={(e) => {
									setIsUpdateFPConfirmationVisible(false);
									setIsEditMode(false);
									UpdateFp();
								}}
							>
								Yes
							</Button>
							<Button style={{ background: "var(--logo-color-1)" }} onClick={(e) => setIsUpdateFPConfirmationVisible(false)}>
								Cancel
							</Button>
						</Col>
					</Row>
				</Popover.Content>
			</Popover>
		);
	};

	const RenderCardRowTypeA = ({ k1, k2, k3, k4, k5, k6, label, target }) => {
		return (
			<div className="forceplate-row">
				<Form.Check
					custom
					disabled={!(isEditMode || isCreatedMode)}
					type={"checkbox"}
					label={""}
					id={`checkbox-${k1}-${k2}-${k3}-${target}`}
					checked={isEditMode || isCreatedMode ? newFp[k1][k2][k3] == target : fpDb[currentKey][k1][k2][k3] == target}
					onChange={() => {
						var tempFp = { ...newFp };
						tempFp[k1][k2][k3] = tempFp[k1][k2][k3] == target ? -1 : target;
						setNewFp({ ...tempFp });
					}}
				/>
				<Form.Check
					custom
					disabled={!(isEditMode || isCreatedMode)}
					type={"checkbox"}
					label={""}
					id={`checkbox-${k4}-${k5}-${k6}-${target}`}
					checked={isEditMode || isCreatedMode ? newFp[k4][k5][k6] == target : fpDb[currentKey][k4][k5][k6] == target}
					onChange={() => {
						var tempFp = { ...newFp };
						tempFp[k4][k5][k6] = tempFp[k4][k5][k6] == target ? -1 : target;
						setNewFp({ ...tempFp });
					}}
				/>
				<div>{label}</div>
			</div>
		);
	};

	const RenderCardRowTypeB = ({ k1, k2, label, target }) => {
		return (
			<div className="forceplate-row">
				<Form.Check
					custom
					disabled={!(isEditMode || isCreatedMode)}
					type={"checkbox"}
					label={""}
					id={`checkbox-${k1}-${k2}-${target}`}
					checked={isEditMode || isCreatedMode ? newFp[k1][k2] == target : fpDb[currentKey][k1][k2] == target}
					onChange={() => {
						var tempFp = { ...newFp };
						tempFp[k1][k2] = tempFp[k1][k2] == target ? -1 : target;
						setNewFp({ ...tempFp });
					}}
				/>
				<div>{label}</div>
			</div>
		);
	};

	return (
		<Fragment>
			{isLoading && (
				<div className="preloader-wrapper">
					<Spinner animation="grow" variant="secondary" />
				</div>
			)}

			<div style={{ marginBottom: "5vh" }}>
				<span style={{ cursor: "pointer", fontSize: "1.2rem", fontWeight: "500" }} onClick={onBack}>
					{"<"} back{" "}
				</span>
			</div>

			{!isLoading && (
				<Fragment>
					{(currentKey >= 0 && !(isCreatedMode || isEditMode) ) ? (
						<Fragment>
							<section className="module-date-selection">
								<Row>
									<Col style={{ alignItems: "center" }}>
										<div>{AddHoursToDate(fpDb[currentKey].CreatedAt, fpDb[currentKey].CreatedAtOffset)}</div>
										<OverlayTrigger key={`fq-overlay-dates`} rootClose={true} trigger="click" placement="right" overlay={RenderFPDateSelectionOverlay()}>
											<div style={{ cursor: "pointer", color: "#2A92CF" }}>Change</div>
										</OverlayTrigger>

										{!(isCreatedMode || isEditMode) && (
											<Fragment>
												<Button
													style={{ backgroundColor: "var(--primary-green)", marginRight: "5px" }}
													onClick={() => {
														setNewFp({ ...fpDb[0], CreatedAt: new Date().toISOString(), CreatedAtOffset: -(new Date().getTimezoneOffset() / 60), LastModified: new Date().toISOString(), LastModifiedOffset: -(new Date().getTimezoneOffset() / 60) });
														setIsCreatedMode(true);
													}}
												>
													New
												</Button>
												<Button
													style={{ backgroundColor: "var(--primary-gray)", marginRight: "5px" }}
													onClick={() => {
														setNewFp({ ...fpDb[currentKey], LastModified: new Date().toISOString(), LastModifiedOffset: -(new Date().getTimezoneOffset() / 60) });
														setIsEditMode(true);
													}}
												>
													Edit
												</Button>
												<OverlayTrigger rootClose={true} show={isRemoveFPConfirmationVisible} trigger="click" placement="right" overlay={RemoveFPConfirmationOverlay()}>
													<Button
														variant="danger"
														style={{ marginRight: "5px" }}
														onClick={() => {
															setIsRemoveFPConfirmationVisible(true);
														}}
													>
														Remove
													</Button>
												</OverlayTrigger>
											</Fragment>
										)}
									</Col>
								</Row>
							</section>
						</Fragment>
					) : (
						<Fragment>
							{!(isCreatedMode || isEditMode) && (
								<Button
									style={{ backgroundColor: "var(--primary-green)", marginRight: "5px" }}
									onClick={() => {
										setNewFp({ ...DefaultFp, CaseId, PatientId, BusinessId, CreatedAt: new Date().toISOString(), CreatedAtOffset: -(new Date().getTimezoneOffset() / 60), LastModified: new Date().toISOString(), LastModifiedOffset: -(new Date().getTimezoneOffset() / 60) });
										setIsCreatedMode(true);
									}}
								>
									New
								</Button>
							)}
						</Fragment>
					)}

					{currentKey >= 0 || isCreatedMode ? (
						<Fragment>
							<section className="module-date-selection">
								<Row>
									<Col>
										<h4>Static (Standing)</h4>
										
									</Col>
								</Row>
								<Row>
									<Col md={4}>
										<div className="forceplate-card">
											<h6>FORE-FOOT PRESSURE</h6>
											<div className="forceplate-row">
												<div style={{ width: "24px" }}>L</div>
												<div style={{ width: "24px" }}>R</div>
											</div>
											<RenderCardRowTypeA k1="Static" k2="Left" k3="ForeFootPressure" k4="Static" k5="Right" k6="ForeFootPressure" target={2} label="High" />
											<RenderCardRowTypeA k1="Static" k2="Left" k3="ForeFootPressure" k4="Static" k5="Right" k6="ForeFootPressure" target={1} label="Medium" />
											<RenderCardRowTypeA k1="Static" k2="Left" k3="ForeFootPressure" k4="Static" k5="Right" k6="ForeFootPressure" target={0} label="Low" />
										</div>
									</Col>
									<Col md={4}>
										<div className="forceplate-card">
											<h6>MID-FOOT PRESSURE</h6>
											<div className="forceplate-row">
												<div style={{ width: "24px" }}>L</div>
												<div style={{ width: "24px" }}>R</div>
											</div>
											<RenderCardRowTypeA k1="Static" k2="Left" k3="MidFootPressure" k4="Static" k5="Right" k6="MidFootPressure" target={2} label="High" />
											<RenderCardRowTypeA k1="Static" k2="Left" k3="MidFootPressure" k4="Static" k5="Right" k6="MidFootPressure" target={1} label="Medium" />
											<RenderCardRowTypeA k1="Static" k2="Left" k3="MidFootPressure" k4="Static" k5="Right" k6="MidFootPressure" target={0} label="Low" />
										</div>
									</Col>
									<Col md={4}>
										<div className="forceplate-card">
											<h6>HIND-FOOT PRESSURE</h6>
											<div className="forceplate-row">
												<div style={{ width: "24px" }}>L</div>
												<div style={{ width: "24px" }}>R</div>
											</div>
											<RenderCardRowTypeA k1="Static" k2="Left" k3="HindFootPressure" k4="Static" k5="Right" k6="HindFootPressure" target={2} label="High" />
											<RenderCardRowTypeA k1="Static" k2="Left" k3="HindFootPressure" k4="Static" k5="Right" k6="HindFootPressure" target={1} label="Medium" />
											<RenderCardRowTypeA k1="Static" k2="Left" k3="HindFootPressure" k4="Static" k5="Right" k6="HindFootPressure" target={0} label="Low" />
										</div>
									</Col>
									<Col md={4}>
										<div className="forceplate-card">
											<h6>FOOT BIOMECHANICS</h6>
											<div className="forceplate-row">
												<div style={{ width: "24px" }}>L</div>
												<div style={{ width: "24px" }}>R</div>
											</div>
											<RenderCardRowTypeA k1="Static" k2="Left" k3="FootBiomechanics" k4="Static" k5="Right" k6="FootBiomechanics" target={0} label="Neutral" />
											<RenderCardRowTypeA k1="Static" k2="Left" k3="FootBiomechanics" k4="Static" k5="Right" k6="FootBiomechanics" target={1} label="Supination" />
											<RenderCardRowTypeA k1="Static" k2="Left" k3="FootBiomechanics" k4="Static" k5="Right" k6="FootBiomechanics" target={2} label="Pronation" />
										</div>
									</Col>
								</Row>

								<Row>
									<Col>
										<h4>Dynamic (Walking)</h4>
										
									</Col>
								</Row>

								<Row>
									<Col md={4}>
										<div className="forceplate-card">
											<h6>FORE-FOOT PRESSURE</h6>
											<div className="forceplate-row">
												<div style={{ width: "24px" }}>L</div>
												<div style={{ width: "24px" }}>R</div>
											</div>
											<RenderCardRowTypeA k1="Dynamic" k2="Left" k3="ForeFootPressure" k4="Dynamic" k5="Right" k6="ForeFootPressure" target={2} label="High" />
											<RenderCardRowTypeA k1="Dynamic" k2="Left" k3="ForeFootPressure" k4="Dynamic" k5="Right" k6="ForeFootPressure" target={1} label="Medium" />
											<RenderCardRowTypeA k1="Dynamic" k2="Left" k3="ForeFootPressure" k4="Dynamic" k5="Right" k6="ForeFootPressure" target={0} label="Low" />
										</div>
									</Col>
									<Col md={4}>
										<div className="forceplate-card">
											<h6>MID-FOOT PRESSURE</h6>
											<div className="forceplate-row">
												<div style={{ width: "24px" }}>L</div>
												<div style={{ width: "24px" }}>R</div>
											</div>
											<RenderCardRowTypeA k1="Dynamic" k2="Left" k3="MidFootPressure" k4="Dynamic" k5="Right" k6="MidFootPressure" target={2} label="High" />
											<RenderCardRowTypeA k1="Dynamic" k2="Left" k3="MidFootPressure" k4="Dynamic" k5="Right" k6="MidFootPressure" target={1} label="Medium" />
											<RenderCardRowTypeA k1="Dynamic" k2="Left" k3="MidFootPressure" k4="Dynamic" k5="Right" k6="MidFootPressure" target={0} label="Low" />
										</div>
									</Col>
									<Col md={4}>
										<div className="forceplate-card">
											<h6>HIND-FOOT PRESSURE</h6>
											<div className="forceplate-row">
												<div style={{ width: "24px" }}>L</div>
												<div style={{ width: "24px" }}>R</div>
											</div>
											<RenderCardRowTypeA k1="Dynamic" k2="Left" k3="HindFootPressure" k4="Dynamic" k5="Right" k6="HindFootPressure" target={2} label="High" />
											<RenderCardRowTypeA k1="Dynamic" k2="Left" k3="HindFootPressure" k4="Dynamic" k5="Right" k6="HindFootPressure" target={1} label="Medium" />
											<RenderCardRowTypeA k1="Dynamic" k2="Left" k3="HindFootPressure" k4="Dynamic" k5="Right" k6="HindFootPressure" target={0} label="Low" />
										</div>
									</Col>
									<Col md={4}>
										<div className="forceplate-card">
											<h6>FOOT BIOMECHANICS</h6>
											<div className="forceplate-row">
												<div style={{ width: "24px" }}>L</div>
												<div style={{ width: "24px" }}>R</div>
											</div>
											<RenderCardRowTypeA k1="Dynamic" k2="Left" k3="FootBiomechanics" k4="Dynamic" k5="Right" k6="FootBiomechanics" target={0} label="Neutral" />
											<RenderCardRowTypeA k1="Dynamic" k2="Left" k3="FootBiomechanics" k4="Dynamic" k5="Right" k6="FootBiomechanics" target={1} label="Supination" />
											<RenderCardRowTypeA k1="Dynamic" k2="Left" k3="FootBiomechanics" k4="Dynamic" k5="Right" k6="FootBiomechanics" target={2} label="Pronation" />
										</div>
									</Col>
								</Row>

								<Row>
									<Col>
										<h4>Gait Characteristics</h4>
										
									</Col>
								</Row>

								<Row>
									<Col md={4}>
										<div className="forceplate-card">
											<h6>GAIT TYPE</h6>
											<RenderCardRowTypeB k1="Gait" k2="Type" target={0} label="Normal" />
											<RenderCardRowTypeB k1="Gait" k2="Type" target={1} label="Limping" />
											<RenderCardRowTypeB k1="Gait" k2="Type" target={2} label="Difficulty Walking" />
										</div>
									</Col>
								</Row>

								<Row>
									<Col>
										<h4>General Notes</h4>
										
									</Col>
								</Row>

								<Row>
									<Col>
										<Form.Control
											as="textarea"
											rows="3"
											disabled={!(isEditMode || isCreatedMode)}
											value={isEditMode || isCreatedMode ? newFp.GeneralNotes : fpDb[currentKey].GeneralNotes}
											placeholder="notes"
											onChange={(evt) => {
												setNewFp({ ...newFp, GeneralNotes: evt.target.value });
											}}
										/>
									</Col>
								</Row>

								{/* <Row>
								<Col md={2}>
									<Form.Label>Therapist Name</Form.Label>
								</Col>
								<Col md={4}>
									<Form.Group>
										<Form.Control
											placeholder="therapist name"
											value={isEditMode || isCreatedMode ? newFp.TherapistName : fpDb[currentKey].TherapistName}
											onChange={(evt) => {
												setNewFp({ ...newFp, TherapistName: evt.target.value });
											}}
										/>
									</Form.Group>
								</Col>
							</Row> */}

								<Row>
									<Col style={{ textAlign: "center" }}>
										{isCreatedMode && (
											<div>
												<OverlayTrigger rootClose={true} show={isCreateFPConfirmationVisible} trigger="click" placement="right" overlay={CreateFPConfirmationOverlay()}>
													<Button onClick={() => setIsCreateFPConfirmationVisible(true)} style={{ background: "var(--primary-blue)", marginRight: "10px" }}>
														Submit
													</Button>
												</OverlayTrigger>

												<Button onClick={() => setIsCreatedMode(false)} style={{ background: "var(--primary-red)" }}>
													Cancel
												</Button>
											</div>
										)}
										{isEditMode && (
											<div>
												<OverlayTrigger rootClose={true} show={isUpdateFPConfirmationVisible} trigger="click" placement="right" overlay={UpdateFPConfirmationOverlay()}>
													<Button onClick={() => setIsUpdateFPConfirmationVisible(true)} style={{ background: "var(--primary-blue)", marginRight: "10px" }}>
														Update
													</Button>
												</OverlayTrigger>

												<Button onClick={() => setIsEditMode(false)} style={{ background: "var(--primary-red)" }}>
													Cancel
												</Button>
											</div>
										)}
									</Col>
								</Row>
							</section>
						</Fragment>
					) : null}
				</Fragment>
			)}
		</Fragment>
	);
};

const defaultDateFormat = {
	year: "numeric",
	month: "short",
	day: "numeric",
	hour: "numeric",
	minute: "numeric",
};
const dateLocale = "en-Us";

const AddHoursToDate = (str, h) => {
	let newDate = new Date(str.slice(0, 19));
	newDate.setTime(newDate.getTime() + h * 60 * 60 * 1000);
	return newDate.toLocaleString(dateLocale, defaultDateFormat);
};

const DefaultFp = {
	Id: "",
	PatientId: "",
	CaseId: "",
	BusinessId: "",
	Static: {
		Left: {
			ForeFootPressure: -1,
			MidFootPressure: -1,
			HindFootPressure: -1,
			FootBiomechanics: -1,
		},
		Right: {
			ForeFootPressure: -1,
			MidFootPressure: -1,
			HindFootPressure: -1,
			FootBiomechanics: -1,
		},
	},
	Dynamic: {
		Left: {
			ForeFootPressure: -1,
			MidFootPressure: -1,
			HindFootPressure: -1,
			FootBiomechanics: -1,
		},
		Right: {
			ForeFootPressure: -1,
			MidFootPressure: -1,
			HindFootPressure: -1,
			FootBiomechanics: -1,
		},
	},
	Gait: {
		Type: -1,
	},
	GeneralNotes: "",
	TherapistName: "",
	CreatedAt: "",
	CreatedAtOffset: 0.0,
	LastModified: "",
	LastModifiedOffset: 0.0,
};

export default ForcePlate;
