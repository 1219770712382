import React, { useState, Fragment} from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import axios from "axios";
import CryptoJS from "crypto-js";
import md5Hash from "../resources/helpers/md5.js";

import UiPassword from "../components/dashboard/ui-password.js";
import UiButton from "../components/dashboard/ui-button.js";
import {useAppContext} from "../lib/context.js";
import { dbUrl } from "../lib/config";

import "../resources/styles/dashboard.scss";
import "bootstrap/dist/css/bootstrap.min.css";

const UserLogin = props => {

    const {appState, setAppState} = useAppContext();
    
    const InitialStateValue = {
        Validated: false,
        Username: "",
        UserInValid: [false, "Incorrect Username or Email"],
        InValid: [false, "Something went wrong..."],
        PassValue: "",
        Error: [false, ""]
    }

    const HandleSubmit = (e) => {
        e.preventDefault();
        e.stopPropagation();

        props.setState({...props.state, Preloader: true}); // start preloader

        let configOne = {
            method: 'post',
            url: dbUrl + '/api/token/dbo',
            headers: { 
                'password': '746C419785D84DC8B3A20B9195363105'
            }
        };
        
        axios(configOne)
        .then(responseOne => {
            let config = {
                method: 'get',
                url: dbUrl + '/api/personalusers/v2/verify/emailorid',
                headers: { 
                  'Authorization': 'Bearer ' + responseOne.data, 
                  'id': state.Username, 
                  'password': md5Hash(state.PassValue)
                }
              };
              
              axios(config)
              .then(responseTwo => {
                
                if(responseTwo.data){
                    if(responseTwo.data.success){
                        
                        localStorage.setItem("_USER", CryptoJS.AES.encrypt(JSON.stringify({isUserAuthenticated: true, userData: responseTwo.data.data}), appState.PASSPHRASE));
                        localStorage.setItem("_A", JSON.stringify({isLoggedIn: true, user: "home"}));

                        props.setState({...props.state, Preloader: false}); // end preloader
                        
                        setAppState({...appState, isUserAuthenticated: true, userData: responseTwo.data.data});
                        
                        window.location.href = "/user";
                        
                    }
                    else{
                        setState({...state, Error: [true, "Something went wrong"]});
                        props.setState({...props.state, Preloader: false}); // end preloader
                    }
                }
                else{
                    setState({...state, Error: [true, "Incorrect Username or Password"]});
                    props.setState({...props.state, Preloader: false}); // end preloader
                }
                
              })
              .catch(errorTwo => {
                props.setState({...props.state, Preloader: false}); // end preloader
                setState({...state, Error: [true, "Incorrect Username or Password"]});
              })
        })
        .catch(errorOne => {
            props.setState({...props.state, Preloader: false}); // end preloader
            setState({...state, Error: [true, "Incorrect Username or Password"]});
        })
    }

    const HandlePassChange = (e) => {

        setState({...state, PassValue: e.currentTarget.value});

        
    };

    const PasswordCheck = (e, retype) => {
        const field = e.currentTarget;
    
        if (retype) {
            if (field.value !== state.PassValue) {
                setState({...state, InValid: [true, "Password doesn't match"]});
            } else {
                setState({...state, InValid: [false, ""]});
            }
        }
    };

    const [state, setState] = useState({...InitialStateValue});

    return(
        <Fragment>
            <Button style={{position: "absolute", top: "3vh", left: "3vw"}} onClick={() => {
                window.location.href = "/login";
            }}>
                {"< "} Back 
            </Button>
            <section className="landing-form-section-wrapper">
                <Container className="centered-container">
                    <Row>
                        <Col style={{maxWidth: "350px"}} className="landing-form-wrapper" lg={6} md={8} sm={10}>
                            <img src="../../photos/bodiometer-home-logo-stacked.svg" width="250" alt="Bodiometer Home Logo" />
                            <h2 className="blue-color">Login</h2>
                            <p>Sign in to your Bodiometer home account</p>
                            <Form validated={state.Validated} onSubmit={e => HandleSubmit(e)}>
                                <Row>
                                    <Form.Group as={Col} controlId="register_userId">
                                    <Form.Control size="sm" type="input" value={state.Username} onChange={(e) => setState({...state, Username: e.currentTarget.value})} placeholder="User ID or Email" required isInvalid={state.UserInValid[0]} />
                                    <Form.Control.Feedback type="invalid">{state.UserInValid[1]}</Form.Control.Feedback>
                                    </Form.Group>
                                </Row>

                                <Row>
                                    <UiPassword placeholder="Password" controlId="register_password" feedback="Please enter a valid password" value={state.PassValue} onChange={HandlePassChange} onBlur={PasswordCheck} isInvalid={state.InValid[0]} message={state.InValid[1]} />
                                    
                                </Row>
                                {state.Error[0] && <p className="error-login">{state.Error[1]}</p>}

                                <Row>
                                    <Form.Group controlId="consent" className="form-footer-wrapper">
                                    <UiButton type="submit" onSubmit={HandleSubmit}>
                                        Sign In
                                    </UiButton>
                                    </Form.Group>
                                </Row>
                                </Form>
                        </Col>
                    </Row>
                </Container>
                <br />
                <p className="centered-text smaller-text">
                    Don't have an account?{" "}
                    <a href="#" onClick={ _ => props.setState({...props.state, View: "register"})}>
                        Register
                    </a>{" "}
                    <br /> Forgot password?{" "}
                    <a href="#" onClick={ _ => props.setState({...props.state, View: "password-reset"})}>
                    Click Here
                    </a>
                </p>
            </section>
        </Fragment>
    )
}

export default UserLogin;