import React, { Fragment } from "react";
import { dbUrl } from "./config";
import axios from "axios";
import CryptoJS from "crypto-js";

const Mode = "live";

const Phrase = "secret key 12353232";

const PayPalCredentials = {
	test: {
		username: "AS8A6_MQaFCREmi7TmJ7acVgB4Nta23bHReP2KcrzbZV_-_XofPx5nT9OvCRNV-3BS8gqEVjueY8JR2T",
		password: "EB_r0H7ElU0pZdNWk0MbZJoNHZEJ5xXkCf_Xgh1aWyxOL6JMeQb8974_eEcjJ_Z7H36K_cSawnxIMh0M",
		product: "bodiometerpro",
	},
	live: {
		username: CryptoJS.AES.decrypt(
			"U2FsdGVkX19GEzaMYeCvfjOxjB4K7AdOjL2ElQzS0ygXcJPxfySmBqx9IohM7y0/Jw0GxNfGNnT+OqKhfAW6cMS2WMKXUWNJuXLuTCoQrKfAVYi4fLymjaAyBon92A/d++LqzCTrmAcKRkKxU2wtQA==",
			Phrase
		).toString(CryptoJS.enc.Utf8),
		password: CryptoJS.AES.decrypt(
			"U2FsdGVkX1+yaApqXEZzTqP+Uyq5KxkIt4wYs6POqAYAO6OWneFJzqNyAE8muSP9bo2aWnpQz2N+yEB8tdHanr2RVKAQ/lcaEftTiSgR+hatMBoKTg2U6qek0AlU2ceOzw5ZCDHlQN/kElySpgWC6Q==",
			Phrase
		).toString(CryptoJS.enc.Utf8),
		product: "bodiometer02",
	},
};

const PaypalApiUrl = Mode === "test" ? "https://api-m.sandbox.paypal.com" : "https://api-m.paypal.com";

const PaypalProductId = Mode === "test" ? PayPalCredentials.test.product : PayPalCredentials.live.product;
const PaypalClientId = Mode === "test" ? PayPalCredentials.test.username : PayPalCredentials.live.username;

const LoadBusinessUserCommonLoginData = async (dbAccessToken, payPalAccessToken, paypalApiUrl, headerName, urlName, userName, currentOrgId = "") => {
	var localData = {
		Users: [],
		User: {},
		OwnerRole: false,
		Organizations: [],
		ActiveOrganization: {},
	};
	try {
		const responseOne = await axios(
			{
				method: "GET",
				url: dbUrl + `/api/businessusers/v2/${urlName}/details/full`,
				headers: {
					Authorization: dbAccessToken,
					[headerName]: userName,
				},
			},
			{ timeout: 10000 }
		);

		if (responseOne.data.success) {
			var businessUserObj = responseOne.data.data.businessUser;
			var orgsArray = [];
			var hasOwnerRole = false;
			var businessAccounts = responseOne.data.data.businessAccounts;
			var businessIds = Object.keys(businessAccounts);

			if (businessIds.length <= 0) {
				var obj = { ...FormatBusinessUserObj(businessUserObj, []) };
				
				return { ...localData, User: obj, Users: [obj] };
			}

			for (let i = 0; i < businessIds.length; i++) {
				// Get license info for businessAccount from associated businessLicense
				var businessLicenseList = [];
				var businessLicenses = businessAccounts[businessIds[i]].businessLicenses;

				for (let j = 0; j < businessLicenses.length; j++) {
					businessLicenseList.push({
						details: {
							subscription: businessLicenses[j].subscription,
							subscriptionId: businessLicenses[j].subscriptionId,
							addons: businessLicenses[j]?.addons ?? {},
							tempLicenseData: businessLicenses[j]?.tempLicenseData ?? {},
							maxPatients: businessLicenses[j].maxPatients,
							plan: businessLicenses[j].businessPlan,
							expiry: businessLicenses[j].expiryDate,
							originalIssue: businessLicenses[j].originalIssueDate,
							licenseKey: businessLicenses[j].licenseKey,
							maxUsers: businessLicenses[j].maxBusinessUsers ? businessLicenses[j].maxBusinessUsers : 5,
							status: businessLicenses[j].subscription.status ? businessLicenses[j].subscription.status : CheckStatus(businessLicenses[j].expiryDate),
						},
					});
				}

				// Get roles for businessAccount
				var businessRoleAccessCategories = [];
				var roleEntries = [];
				var businessUsersArray = [];
				var businessRoles = businessAccounts[businessIds[i]].businessRoles;
				for (let j = 0; j < businessRoles.length; j++) {
					roleEntries.push({
						id: businessRoles[j].roleId,
						userId: businessRoles[j].userId,
						title: businessRoles[j].title,
						accessCategory: businessRoles[j].accessCategory,
						accessLevel: businessRoles[j].accessLevel,
						description: businessRoles[j].description,
						status: businessRoles[j].status,
						createdAt: businessRoles[j].createdAt,
					});

					businessUsersArray.push({
						roleId: businessRoles[j].roleId,
						userId: businessRoles[j].userId,
						businessId: businessRoles[j].businessId,
						title: businessRoles[j].accessCategory,
						status: businessRoles[j].status,
						createdAt: businessRoles[j].createdAt,
					});
					if (!businessRoleAccessCategories.includes(businessRoles[j].accessCategory)) {
						businessRoleAccessCategories.push(businessRoles[j].accessCategory);
					}
					// check if user has an owner role (because if they dont, they can still create a trial license, otherwise they can't.. they'll have to pay)
					if (businessRoles[j].userId === businessUserObj.userId && businessRoles[j].title.toLowerCase() === "owner") {
						hasOwnerRole = true;
					}
				}

				var myAccessLevel = null;

				for (let j = 0; j < businessRoles.length; j++) {
					if (businessRoles[j].userId === businessUserObj.userId) {
						myAccessLevel = businessRoles[j].accessLevel;
					}
				}

				var businessDetail = businessAccounts[businessIds[i]].businessDetail;

				var objEntry = {
					id: businessDetail.businessId,
					created: businessDetail.createdAt,
					name: businessDetail.businessName,
					freeTrial: businessLicenseList.length === 0 ? null : CountFreeTrialDays(businessLicenseList[0].details.expiry),
					type: businessDetail.businessType,
					email: businessDetail.email,
					emailActivated: businessDetail.emailActivated,
					phone: businessDetail.phoneNumber,
					fax: businessDetail.fax,
					address: businessDetail.address.streetAddress1,
					city: businessDetail.address.city,
					province: businessDetail.address.province,
					country: businessDetail.address.country,
					postal: businessDetail.address.postalCode,
					myAccessLevel: myAccessLevel,
					roles: businessRoleAccessCategories,
					roleEntries: roleEntries,
					license: businessLicenseList.length === 0 ? false : [...businessLicenseList],
					couponsUsed: [...businessDetail.coupons],
				};

				orgsArray.push(objEntry);
			}

			// const userObj = {
			// 	id: businessUserObj.userId,
			// 	fname: businessUserObj.firstName,
			// 	lname: businessUserObj.lastName,
			// 	title: businessUserObj.salutation,
			// 	phone: businessUserObj.phoneNumber,
			// 	ext: businessUserObj.phoneNumberExt,
			// 	userId: businessUserObj.userId,
			// 	email: businessUserObj.email,
			// 	emailActivated: businessUserObj.emailActivated,
			// 	organization: businessIds,
			// 	lastLogin: businessUserObj.lastLoggedIn,
			// 	country: businessUserObj.address.country,
			// 	notifications: { newStaff: true, staffChange: false },
			// };
			const userObj = FormatBusinessUserObj(businessUserObj, businessIds);

			// Get users for businesses
			const usersArray = [];

			currentOrgId = !!currentOrgId && currentOrgId in businessAccounts ? currentOrgId : Object.keys(businessAccounts)[0];

			const ComputeActiveOrgIndex = (orgId) => {
				if (!!orgId) {
					for (var i = 0; i < orgsArray.length; i++) {
						if (orgsArray[i].id === orgId) {
							return orgsArray.indexOf(orgsArray[i]);
						}
						if (i === orgsArray.length - 1) {
							return 0;
						}
					}
				} else {
					return 0;
				}
			};

			var activeOrg = orgsArray[ComputeActiveOrgIndex(currentOrgId)];

			if (!!currentOrgId) {
				for (let i = 0; i < businessAccounts[currentOrgId].businessUsers.length; i++) {
					for (let j = 0; j < businessAccounts[currentOrgId].businessRoles.length; j++) {
						if (businessAccounts[currentOrgId].businessUsers[i].userId === businessAccounts[currentOrgId].businessRoles[j].userId) {
							usersArray.push({
								id: businessAccounts[currentOrgId].businessUsers[i].userId,
								fname: businessAccounts[currentOrgId].businessUsers[i].firstName,
								lname: businessAccounts[currentOrgId].businessUsers[i].lastName,
								title: businessAccounts[currentOrgId].businessUsers[i].salutation,
								phone: businessAccounts[currentOrgId].businessUsers[i].phoneNumber,
								ext: businessAccounts[currentOrgId].businessUsers[i].phoneNumberExt,
								email: businessAccounts[currentOrgId].businessUsers[i].email,
								organization: [
									{
										id: businessAccounts[currentOrgId].businessRoles[j].businessId,
										roleId: businessAccounts[currentOrgId].businessRoles[j].roleId,
										role: businessAccounts[currentOrgId].businessRoles[j].title,
										status: businessAccounts[currentOrgId].businessRoles[j].status,
										created: FormatDate(businessAccounts[currentOrgId].businessRoles[j].createdAt, {
											year: "numeric",
											month: "long",
											day: "numeric",
										}),
									},
								],
								notifications: { newStaff: true, staffChange: false },
							});
						}
					}
				}

				if (activeOrg && activeOrg.license && activeOrg.license[0].details.subscriptionId !== "") {
					var subId = activeOrg?.license[0]?.details.subscriptionId;

					// subId = "I-14DRXSWRMCF0";

					var responseTwo = null;

					try {
						responseTwo = await axios(
							{
								method: "GET",
								url: paypalApiUrl + `/v1/billing/subscriptions/${subId}`,
								headers: {
									"Content-Type": "application/json",
									Authorization: payPalAccessToken,
								},
							},
							{ timeout: 5000 }
						);
					} catch {}

					var payPalStatus = responseTwo?.data?.status ?? "";

					if (payPalStatus === "ACTIVE") {
						var newAddons = activeOrg.license[0].details.addons;
						var newExpiry = new Date(activeOrg.license[0].details.expiry);
						var nextPaymentTime = new Date(responseTwo.data.billing_info.next_billing_time);
						var addonList = Object.keys(newAddons);
						for (let k = 0; k < addonList.length; k++) {
							var key = addonList[k];
							var newDate = new Date(newAddons[key].expiryDate);
							if (nextPaymentTime.getTime() >= newDate.getTime()) {
								newAddons[key].expiryDate = ConvertDate(nextPaymentTime);
							}
						}
						if (nextPaymentTime.getTime() >= newExpiry.getTime()) {
							newExpiry = ConvertDate(nextPaymentTime);
						}

						var responseThree = await axios(
							{
								method: "PUT",
								url: dbUrl + `/api/businesslicenses/v1/update/licensekey`,
								headers: {
									Authorization: dbAccessToken,
									"Content-Type": "application/json",
									licenseKey: activeOrg.license[0].details.licenseKey,
								},
								data: {
									expiryDate: newExpiry,
									addons: newAddons,
								},
							},
							{ timeout: 5000 }
						);

						if (responseThree.data.success) {
							activeOrg.license[0].details = {
								...activeOrg.license[0].details,
								expiry: newExpiry,
								addons: newAddons,
								status: "Active",
							};
						}
					} else if (payPalStatus === "CANCELLED") {
						var responseThree = await axios(
							{
								method: "PUT",
								url: dbUrl + `/api/businesslicenses/v1/update/licensekey`,
								headers: {
									Authorization: dbAccessToken,
									"Content-Type": "application/json",
									licenseKey: activeOrg.license[0].details.licenseKey,
								},
								data: {
									subscription: `{\n\"frequency\": \"monthly\",\n\"type\": \"trial\"\n}`,
									subscriptionId: "",
								},
							},
							{ timeout: 3000 }
						);

						if (responseThree.data.success) {
							activeOrg.license[0].details = {
								...activeOrg.license[0].details,
								subscription: {
									frequency: "monthly",
									type: "trial",
								},
								subscriptionId: "",
								status: CheckStatus(activeOrg.license[0].details.expiry),
							};
						}
					}
				}
			}

			return {
				...localData,
				Users: usersArray,
				User: userObj,
				OwnerRole: hasOwnerRole,
				Organizations: orgsArray,
				ActiveOrganization: activeOrg,
			};
		} else {
			return { ...localData };
		}
	} catch (err) {
		console.log(err);
		return { ...localData };
	}
};

const FormatBusinessUserObj = (obj, businessIds) => {
	return {
		id: obj.userId,
		fname: obj.firstName,
		lname: obj.lastName,
		title: obj.salutation,
		phone: obj.phoneNumber,
		ext: obj.phoneNumberExt,
		userId: obj.userId,
		email: obj.email,
		emailActivated: obj.emailActivated,
		organization: businessIds,
		lastLogin: obj.lastLoggedIn,
		country: obj.address.country,
		notifications: { newStaff: true, staffChange: false },
	};
};

const LoadOrganizationLogo = async (dbAccessToken, orgId) => {
	var logo = null;
	try {
		var responseValue = await axios(
			{
				method: "GET",
				url: dbUrl + "/api/storage/v1/image/ba/logo/id/base64",
				headers: {
					Authorization: dbAccessToken,
					"Content-Type": "application/json",
					businessId: orgId,
				},
			},
			{ timeout: 5000 }
		);
		logo = responseValue.data;
	} catch {}
	return {
		data: logo,
	};
};

const LoadPaypalAccessToken = async () => {
	try {
		var _username = Mode == "live" ? PayPalCredentials.live.username : PayPalCredentials.test.username;
		var _password = Mode == "live" ? PayPalCredentials.live.password : PayPalCredentials.test.password;

		var responseValue = await axios(
			{
				method: "POST",
				url: PaypalApiUrl + "/v1/oauth2/token",
				headers: {
					"content-Type": "application/x-www-form-urlencoded",
					"accept-language": "en_US",
					authorization: "Basic " + window.btoa(_username + ":" + _password),
				},
				data: new URLSearchParams({
					grant_type: "client_credentials",
				}),
			},
			{ timeout: 5000 }
		);
		return responseValue.data.access_token;
	} catch {
		return "";
	}
};

const LoadDashboardPatientList = async (dbAccessToken, orgId, skip, pageSize, patientName = "") => {
	try {
		return await axios(
			{
				method: "GET",
				url: dbUrl + "/api/patients/v5/dashboard/main/table/summary/businessid",
				headers: {
					Authorization: dbAccessToken,
					BusinessId: orgId,
					skip,
					pageSize,
					patientName,
				},
			},
			{ timeout: 10000 }
		);
	} catch {
		return {
			success: false,
			message: "patient information not found.",
			data: {
				patients: {},
				cases: {},
			},
		};
	}
};

const LoadDashboardAnalytics = async (dbAccessToken, orgId) => {
	try {
		return await axios(
			{
				method: "GET",
				url: dbUrl + "/api/patientcases/v5/dashboard/analytics/typea",
				headers: {
					Authorization: dbAccessToken,
					BusinessId: orgId,
				},
			},
			{ timeout: 10000 }
		);
	} catch {
		return {
			success: false,
			message: "patient information not found.",
			data: {
				Patients: {
					Total: 0,
					PastMonth: 0,
					PastPastMonth: 0,
				},
				Postures: {
					Total: 0,
					PastMonth: 0,
					PastPastMonth: 0,
				},
			},
		};
	}
};

const SubmitHealthStatusUpdateV1 = async (dbAccessToken, PatientId, BusinessId) => {
	try {
		return await axios(
			{
				method: "POST",
				url: dbUrl + "/api/hsu/v1/submit",
				headers: {
					Authorization: dbAccessToken,
					"Content-Type": "application/json",
				},
				data: {
					PatientId,
					BusinessId,
				},
			},
			{ timeout: 10000 }
		);
	} catch {
		return {
			success: false,
			message: "something went wrong.",
			data: {},
		};
	}
};

const RemoveHealthStatusUpdateByIdV1 = async (dbAccessToken, Id) => {
	try {
		return await axios(
			{
				method: "DELETE",
				url: dbUrl + "/api/hsu/v1/id",
				headers: {
					Authorization: dbAccessToken,
					"Content-Type": "application/json",
					Id,
				}
			},
			{ timeout: 10000 }
		);
	} catch {
		return {
			success: false,
			message: "something went wrong.",
			data: {},
		};
	}
};


const LoadHealthStatusUpdateByPatientAndBusinessIdV1 = async (dbAccessToken, patientId, businessId) => {
	try {
		return await axios(
			{
				method: "GET",
				url: dbUrl + "/api/hsu/v1/patientid",
				headers: {
					Authorization: dbAccessToken,
					patientId,
					businessId,
				},
			},
			{ timeout: 10000 }
		);
	} catch {
		return {
			success: false,
			message: "patient info not found.",
			data: {
				patientInfo: {
					Salutation: "",
					FirstName: "",
					LastName: "",
					Email: "",
					DateOfBirth: null,
					Gender: "",
				},
				businessInfo: {
					Email: "",
					BusinessName: "",
				},
				hsuList: [],
			},
		};
	}
};

const LoadHealthStatusUpdateByIdV1 = async (dbAccessToken, Id) => {
	try {
		return await axios(
			{
				method: "GET",
				url: dbUrl + "/api/hsu/v1/id",
				headers: {
					Authorization: dbAccessToken,
					Id,
				},
			},
			{ timeout: 10000 }
		);
	} catch {
		return {
			success: false,
			message: "something went wrong.",
			data: {},
		};
	}
};

const LoadHealthStatusUpdateDataV1 = async (dbAccessToken, data) => {
	try {
		return await axios(
			{
				method: "PUT",
				url: dbUrl + "/api/hsu/v1/update",
				headers: {
					Authorization: dbAccessToken,
					"Content-Type": "application/json",
				},
				data: { ...data, Status: "COMPLETED", LastModifiedAt: new Date().toISOString(), CompletedAt: new Date().toISOString() },
			},
			{ timeout: 10000 }
		);
	} catch {
		return {
			success: false,
			message: "something went wrong.",
			data: {},
		};
	}
};

const DisplayHealthStatusUpdateV1EmailClient = ({ Id, patientInfo, businessInfo }) => {
	return (
		<Fragment>
			<Mailto
				email={patientInfo.Email}
				subject={`Patient Health Status Update`}
				body={`Dear ${patientInfo?.FirstName} ${patientInfo?.LastName},\n\nThis email is sent by ${businessInfo.BusinessName} to collect information about your health status. Once you complete and submit the form, the information will be automatically sent to us.\n\nPlease, click on the following link to proceed to the health status update form:\n\nhttps://www.bodiometer.com/api/public/hsupdate/v1/${Id} \n\nThank you for choosing our clinic ${businessInfo.BusinessName} \n\nIf you encounter any technical issues, please contact us at ${businessInfo.Email}.\n\n`}
			>
				Send
			</Mailto>
		</Fragment>
	);
};

const Mailto = ({ email, subject = "", body = "" }) => {
	let params = subject || body ? "?" : "";
	if (subject) params += `subject=${encodeURIComponent(subject)}`;
	if (body) params += `${subject ? "&" : ""}body=${encodeURIComponent(body)}`;
	window.location.href = `mailto:${email}${params}`;
	return null;
};

const CheckStatus = (date) => {
	var publicationDate = new Date(date);
	var today = new Date();
	if (publicationDate.getTime() >= today.getTime()) {
		return "Active";
	}
	return "Expired";
};

const FormatDate = (date, options) => {
	return new Date(date).toLocaleDateString("en-US", options);
};

const ConvertDate = (date) => {
	var yyyy = date.getFullYear().toString().padStart(2, "0");
	var mm = (date.getMonth() + 1).toString().padStart(2, "0");
	var dd = date.getDate().toString().padStart(2, "0");
	var hh = date.getHours().toString().padStart(2, "0");
	var mn = date.getMinutes().toString().padStart(2, "0");
	var sc = date.getSeconds().toString().padStart(2, "0");
	return `${yyyy}-${mm}-${dd}T${hh}:${mn}:${sc}`;
};

const CountFreeTrialDays = (date) => {
	var publicationDate = new Date(date);
	var today = new Date();
	return Math.round((publicationDate.getTime() - today.getTime()) / 86400000);
};

const GetAccessLevelFromProp = (props) => {
	const activeRoles = props.ActiveOrganization.roleEntries;
	const activeUser = props.parentState.User;
	const matchedRoles = activeRoles.filter((role) => role.userId == activeUser.id);
	const accessLevel = matchedRoles.length > 0 ? matchedRoles[0].accessLevel : 10;
	return accessLevel;
};

const CustomFormatPhoneNumber = (number, country) => {
	if (country.toLowerCase() == "canada" && number.length >= 10) {
		return number.substring(0, 4) + "." + number.substring(4, 7) + "." + number.substring(7);
	}
	return number;
};

const CalculateAge = (dateString) => {
	var today = new Date();
	var birthDate = new Date(dateString);
	var age = today.getFullYear() - birthDate.getFullYear();
	var m = today.getMonth() - birthDate.getMonth();
	if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
		age--;
	}
	return age;
};

const AddHoursToDate = (str, h) => {
	var newDate = new Date(str.slice(0, 19));
	newDate.setTime(newDate.getTime() + h * 60 * 60 * 1000);
	return newDate.toLocaleString(DateLocale, DefaultDateFormat);
};

const GenerateComponentKey = (v, k) => {
	return `${Math.random()}-${v}-${k}`
}

const DefaultDateFormat = {
	year: "numeric",
	month: "short",
	day: "numeric",
	hour: "numeric",
	minute: "numeric",
};

const DateLocale = "en-Us";

export {
	LoadBusinessUserCommonLoginData,
	LoadOrganizationLogo,
	LoadPaypalAccessToken,
	LoadDashboardPatientList,
	LoadDashboardAnalytics,
	PaypalApiUrl,
	PaypalProductId,
	PaypalClientId,
	GetAccessLevelFromProp,
	CustomFormatPhoneNumber,
	CalculateAge,
	AddHoursToDate,
	GenerateComponentKey,
	DisplayHealthStatusUpdateV1EmailClient,
	DefaultDateFormat,
	DateLocale,
	SubmitHealthStatusUpdateV1,
	RemoveHealthStatusUpdateByIdV1,
	LoadHealthStatusUpdateByPatientAndBusinessIdV1,
	LoadHealthStatusUpdateByIdV1,
	LoadHealthStatusUpdateDataV1,
};
