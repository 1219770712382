import React, { useState, Fragment, useEffect } from "react";
import { Col, Row, Button, Form } from "react-bootstrap";

import "bootstrap/dist/css/bootstrap.min.css";
import "react-phone-number-input/style.css";
import "react-datetime/css/react-datetime.css";
import "../Assets/style.scss";

const ConsentForm = ({ data, pageCompleted, setPageCompleted, activeIndex, companyName }) => {
	const Separator = () => {
		return <div style={{ width: "100%", height: "1px", background: "var(--primary-gradient)", marginTop: "25px", marginBottom: "25px", position: "relative" }}></div>;
	};

	const Header = ({ label }) => {
		return <h2 style={{ textAlign: "center", marginBottom: "20px" }}>{label}</h2>;
	};

	const [consentOne, setConsentOne] = useState(false);
	const [consentTwo, setConsentTwo] = useState(false);
	const [consentThree, setConsentThree] = useState(false);

	useEffect(() => {
		pageCompleted[activeIndex] = consentOne && consentTwo && consentThree;
		setPageCompleted([...pageCompleted]);
	}, [consentOne, consentTwo, consentThree]);

	return (
		<div className="intake" style={styles.screen}>
			<Header label={"Consent Form"} />

			<Row>
				<Col>
					<span style={{ fontWeight: "bold" }}>Dear Patient:</span> Your information is confidential. We need this information so that we can better care for you. Your consent will help us determine
					if treatment in our office will benefit you. If we do not sincerely believe your condition satisfactorily, we will not accept your case. In order for us to understand your condition
					properly, Please be as neat and complete as possible while completing all forms.
				</Col>
			</Row>

			<Separator />

			<Row>
				<Col>
					<span style={{ fontWeight: "bold" }}>Consent to Assessment and Treatment</span>
				</Col>
			</Row>

			<Row>
				<Col>
					Our clinic, {companyName}, offers the performance of physiotherapy, acupuncture, chiropractic, and/or massage including various modalities, and gait analysis, which may be deemed appropriate
					for therapeutic and or diagnostic purposes. These modalities may include, but are not limited to: electrical muscle/nerve stimulation; spinal manipulative therapy; laser/electrical
					acupuncture, therapeutic ultrasound; mobilization; soft tissue manipulation; diagnostic x-rays; and various forms of physio/physical therapy performed by the physiotherapist, acupuncturist,
					chiropractor, therapist aide, kinesiologist and/or anyone authorized by the therapist.
				</Col>
			</Row>

			<Row>
				<Col>
					As is the case with most health care interventions, there is a certain inherent risk of complication associated with physiotherapy, acupuncture, chiropractic, massage, physiotherapeutic
					and/or rehabilitative procedures, which may include but is not limited to skin irritation; bruising; muscle strains/sprains; disc injuries; infection and stroke. The risk of
					complication/injury associated with the aforementioned procedures is small and subsequently reduced when performed by a certified and/or licensed professional.
				</Col>
			</Row>

			<Row>
				<Col>
					Acupuncture and dry needling techniques include the use of sterile, single-use disposable needles to penetrate the skin. Additional treatment methods can include, but are not limited to:
					acupressure, the electrical stimulation of needles, cupping, moxibustion, gua sha (scraping) and tuina (massage). Before any of these procedures are performed my therapist will discuss my
					treatment options and only proceed if my consent is given.
				</Col>
			</Row>

			<Row>
				<Col>
					I understand that some of the main risks and common symptoms of acupuncture treatments, which can include, but are not limited to: bleeding, bruising, soreness, discoloration of skin,
					blistering, allergic reactions, nausea, fainting, shock, stuck needles and the possibility of other unforeseen risks.
				</Col>
			</Row>

			<Row>
				<Col>
					My therapist or doctor will not be able to anticipate and explain all risks and complications, but I elect to rely on his/her clinical expertise and judgment to determine reasonable
					course(s) of clinical action, based upon known facts, which are considered to be in my best interest. The results are not guaranteed and I will have the opportunity to discuss the purposes
					and risks associated with any and all recommended evaluations and treatment procedures.
				</Col>
			</Row>

			<Row>
				<Col>
					I, {data.FirstName} {data.LastName} understand and hereby request and consent to the above. I hereby acknowledge that I have read and understood the preceding statements. I have also had an
					opportunity to ask questions and address any concerns in regards to its content. I will inform my therapist if I currently have or develop any major health issues, if I suffer from any type
					of bleeding disorder, if I am taking anti-coagulants, if I am pregnant, or if I use a pacemaker. I will let my therapist know if I am carrying, or believe to have any infectious agents,
					including but not limited to: HIV, TB and hepatitis. I intend this consent form to serve as written consent throughout the entire course of treatment of my present condition and for any
					future condition(s) for which I may seek treatment. If at any time I decide that I am unwilling to engage or continue with these procedures, I reserve the right to inform my therapist or
					doctor of such, and withdraw my consent at anytime in any or all forms of evaluation and/or treatment.
				</Col>
			</Row>

			<Row>
				<Col className="checkbox">
					<Form.Check custom type={"checkbox"} label={""} id={`consent-checkbox-1`} checked={consentOne} defaultChecked={consentOne} onChange={() => setConsentOne(!consentOne)} />
					<Form.Label>I hereby declare that I have read and fully accept all the above-mentioned terms and conditions *</Form.Label>
				</Col>
			</Row>

			<Row>
				<Col>
					<span style={{ fontWeight: "bold" }}>Interdisciplinary Consultation</span>
				</Col>
			</Row>

			<Row>
				<Col>
					I authorize the clinic to release my personal and medical information to the Medical Team at {companyName}. I understand that my medical information will be shared in an expanded circle of
					care, but limited to only authorized members of the {companyName}. I understand that the information gathered will be used to by medical providers on an ongoing basis as necessary
					to facilitate care coordination and medical management if needed. I understand my reports will be sent only to community clinic provider, and I will be able to access any reports from the
					local community clinic. I understand that if I wish to have any additional information, I can discuss these matters with my community clinic provider, and when appropriate an in-clinic
					evaluation with the {companyName} can be arranged. I understand the Interdisciplinary Consult Medical process is an AHS-funded service and give consent.
				</Col>
			</Row>

			<Row>
				<Col className="checkbox">
					<Form.Check custom type={"checkbox"} label={""} id={`consent-checkbox-2`} checked={consentTwo} defaultChecked={consentTwo} onChange={() => setConsentTwo(!consentTwo)} />
					<Form.Label>I authorize and give consent *</Form.Label>
				</Col>
			</Row>

			<Row>
				<Col>
					<span style={{ fontWeight: "bold" }}>Acknowledgement of Risk from Exercise</span>
				</Col>
			</Row>

			<Row>
				<Col>
					I understand that my care may include exercise. I understand that with exercise that there is a risk for: increased pain, damage to soft tissue, irritation of nerves, increased swelling and
					stiffness, falls, and potential aggravation of underlying medical conditions. I understand that if I am unclear, uncertain, or feel nervous, I will stop the exercise immediately. I
					understand that I am to inform the {companyName} if I had been advised to avoid exercise by other health care providers in the past.
				</Col>
			</Row>

			<Row>
				<Col className="checkbox">
					<Form.Check custom type={"checkbox"} label={""} id={`consent-checkbox-3`} checked={consentThree} defaultChecked={consentThree} onChange={() => setConsentThree(!consentThree)} />
					<Form.Label>I agree *</Form.Label>
				</Col>
			</Row>

		</div>
	);
};

const styles = {
	screen: {
		margin: "20px auto",
		maxWidth: "800px",
	},
};

export default ConsentForm;
