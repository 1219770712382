import React, { useState, useEffect } from 'react';
import { Col, Form } from 'react-bootstrap';

import showPass from "../../resources/photos/show-password.png";
import hidePass from "../../resources/photos/hide-password.png";

import "../../resources/styles/dashboard.scss";
import "bootstrap/dist/css/bootstrap.min.css";

export default function UiPassword2(props) {
  const [state, setState] = useState({
    Validated: undefined,
    PassType: 'password'
  });







  const HandleEyeball = () => {
    if (state.PassType === 'password') {
      setState({...state, PassType: 'text'});
    } else {
      setState({...state, PassType: 'password'});
    }
  };

  return(
    <>
      <Form.Group as={Col} controlId={props.controlId} className="password-input">
        {props.label && <Form.Label>{props.label}</Form.Label>}
        <Form.Control onChange={e => props.onChange(e, props.retype ? true : false)} onBlur={e => props.onBlur(e, props.retype ? true : false)} isInvalid={props.isInvalid} validated={state.Validated} size="sm" type={state.PassType} placeholder={props.placeholder} value={props.value} required/>
        <img className="password-eyeball" src={state.PassType === 'password' ? showPass : hidePass} width="25" onClick={HandleEyeball} onChange={props.onChange}/>
        {props.text && 
          <Form.Text muted>
            {props.text}
          </Form.Text>
        }
          <Form.Control.Feedback type="invalid">
            {props.message}
          </Form.Control.Feedback>
      </Form.Group>
    </>
  );
};