import React, {useState, useEffect} from "react";
import {Container, Col, Row, Form} from "react-bootstrap";
import axios from "axios";

import UiButton from "../components/dashboard/ui-button.js";

import ConvertDate from "../resources/helpers/convert-date.js";

import "../resources/styles/dashboard.scss";
import "bootstrap/dist/css/bootstrap.min.css";

export default function PasswordReset(props) {
  const [state, setState] = useState({
    Success: false,
    Validated: false,
    Username: "",
    InValid: [false, "Something went wrong..."],
    DBAccessToken: "",
  });

  useEffect(() => {
    axios({
      method: "post",
      url: props.dbUrl + "/api/token/dbo",
      headers: {
        password: "746C419785D84DC8B3A20B9195363105",
      },
      timeout: 10000,
    }).then((responseOne) => {
      if (responseOne.status == 200) {
        // console.log(responseOne.data);
        setState((prev) => {
          return {...prev, DBAccessToken: "Bearer " + responseOne.data};
        });
      }
    });
  }, []);

  const HandlePassReset = (e) => {
    e.preventDefault();
    e.stopPropagation();

    // Call db and check if user exists
    let HeaderName = state.Username.includes("@") ? "email" : "userId";
    let UrlName = state.Username.includes("@") ? "email" : "userid";

    const Headers = {
      headers: {
        Authorization: state.DBAccessToken,
        [HeaderName]: state.Username,
      },
    };

    axios.get(props.dbUrl + `/api/businessusers/v1/${UrlName}/details/summary`, Headers).then((res) => {
      const person = res.data.data;

      if (res.data.success) {
        // Send password reset email

        const pRPostData = {
          userId: person.userId,
        };

        const pRPostHeaders = {
          headers: {
            Authorization: state.DBAccessToken,
            "Content-Type": "application/json",
          },
        };

        axios.post(props.dbUrl + "/api/email/v2/businessusers/resetpassword", pRPostData, pRPostHeaders).then((res) => {
          // email sent!
          setState({...state, Success: true});
        });
      } else {
        setState({...state, InValid: [true, "Username / Email not registered"]});
      }
    });
  };

  return (
    <section className="landing-form-section-wrapper">
      <Container className="centered-container">
        <Row>
          <Col className="landing-form-wrapper" lg={5} md={6} sm={10}>
            <img src="../../photos/bodiometer-pro-logo-stacked.svg" width="250" alt="Bodiometer Logo" />
            <h2 className="blue-color">Reset Password</h2>
            {!state.Success && (
              <>
                <p>Please enter your account Username or Email</p>
                <Form validated={state.Validated} onSubmit={(e) => HandlePassReset(e)}>
                  <Row>
                    <Form.Group as={Col} controlId="register_userId">
                      <Form.Control isInvalid={state.InValid[0]} size="sm" type="input" value={state.Username} onChange={(e) => setState({...state, Username: e.currentTarget.value})} placeholder="User ID or Email" required />
                      <Form.Control.Feedback type="invalid">{state.InValid[1]}</Form.Control.Feedback>
                    </Form.Group>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Group controlId="consent" className="form-footer-wrapper">
                        <br />
                        <UiButton type="submit">Send Email</UiButton>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <br />
                    <p className="centered-text smaller-text">
                      Back to{" "}
                      <a href="#" onClick={(e) => props.setView("login")}>
                        login
                      </a>
                    </p>
                  </Row>
                </Form>
              </>
            )}
            {state.Success && (
              <div>
                <p>Please check your email inbox for further instructions</p>
                <UiButton variant="secondary" onClick={(e) => props.setView("login")} back>
                  Back
                </UiButton>
              </div>
            )}
          </Col>
        </Row>
      </Container>
    </section>
  );
}
