import React, { useState, useEffect } from 'react';
import { Col, Row, Form, Alert } from 'react-bootstrap';
import axios from 'axios';
import md5Hash from "../../resources/helpers/md5.js";

import UiButton from "./ui-button.js";
import UiPassword from "./ui-password.js";

import "bootstrap/dist/css/bootstrap.min.css";

export default function Security(props) {
  const [state, setState] = useState({
    EditFormValidated: false,
    Error: [false, ''],
    Success: [false, ''],
    Different: false,
    PassType: 'password',
    PassValue0: '',
    PassValue: '',
    PassValue2: '',
    InValid0: [false, "Incorrect password"],
    InValid: [false,"Something went wrong..."],
    InValid2: [false,"Something went wrong..."]
  });

  const Initial = {
    EditFormValidated: false,
    Error: [false, ''],
    Success: [false, ''],
    Different: false,
    PassType: 'password',
    PassValue0: '',
    PassValue: '',
    PassValue2: '',
    InValid0: [false, "Incorrect password"],
    InValid: [false,"Something went wrong..."],
    InValid2: [false,"Something went wrong..."]
  }

  // hide success/error alert after 4 seconds
  useEffect(() => {
    const timer = setTimeout(() => {
      setState(prev => ({...prev, Success: [false, ""], Error: [false, ""]}))
    }, 4000);
    return () => clearTimeout(timer);
  }, [state.Success, state.Error]);

  const HandleSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();
    
    // Check if password matches
    const Headers = {
      headers: {
        'Authorization': props.parentState.DBAccessToken,
        'userId': props.parentState.User.id
      }
    };
    
    axios.get(
      props.dbUrl + `/api/businessusers/v1/userid/details/full`,  
      Headers
    )
    .then((user) => {

      if (user.data.success) {

        const person = user.data.data;

        if (person.password == md5Hash(state.PassValue0)) {


          props.setState({...props.parentState, Preloader: true}); // start preloader
    
          const PostData = {
            userDetail: {
              password: md5Hash(state.PassValue),
            }
          }
          
          const PostHeaders = {
            headers: {
              'Authorization': props.parentState.DBAccessToken,
              'Content-Type': 'application/json',
              'userId': props.parentState.User.id
            }
          };
          
          axios.put(
            props.dbUrl + '/api/businessusers/v1/update/userid', 
            PostData, 
            PostHeaders
          )
          .then((user) => {
            
            props.setState({...props.parentState, Preloader: false}); // end preloader
        
            if (user.data.success) {
              props.setState({...props.parentState, Refresh: props.parentState.Refresh + 1})
              // props.setState( prev => ({...prev, Refresh: prev['Refresh'] + 1 }));
              setState({...state, Success: [true, "New password saved successfully"], Different: false})
            } else {
              setState({...state, Error: [true, "Oops, Something went wrong.."]})
            }
      
          });


        } else {
          setState({...state, InValid0: [true, 'Incorrect password']})
        }


      }


    })




  };

  const PasswordCheck = (e, retype) => {

    const field = e.currentTarget;

    if (field.value.length < 6) {
      setState({...state, InValid: [true, "Password is too short"]});
    } else if (field.value.length >= 6) {
      setState({...state, InValid: [false, ""]});
    }
    if (retype) {
      if (field.value !== state.PassValue) {
        setState({...state, InValid2: [true, "Password doesn't match"]});
      } else {
        setState({...state, InValid2: [false, ""], Different: true});
      }
    }
  }

  const HandlePassChange = (e, retype) => {
    if (!retype) {
      setState({...state, PassValue: e.currentTarget.value});
    } else if (retype) {
      setState({...state, PassValue2: e.currentTarget.value});
    }
  }

  const HandleCurrentCheck = (e) => {
    // if password matches current user password
    if (e.currentTarget.value) {
      return true;
    } else {
      setState({...state, InValid0: [true, "Incorrect password"]});
    }
  }

  const HandleCurrentChange = (e) => {
    setState({...state, PassValue0: e.currentTarget.value});
  }
  
  return(
    <div className="ui-section-wrapper">
      <section>
        <Row>
          <Col className="flex-row no-wrap">
            <h1>Security</h1>
            <Alert variant="warning" show={state.Error[0] ? true : false}>
              <div className="flex-row">
                <p>{state.Error[1]}</p>
              </div>
            </Alert>
            <Alert variant="info" show={state.Success[0] ? true : false}>
              <div className="flex-row">
                <p>{state.Success[1]}</p>
              </div>
            </Alert>
          </Col>
        </Row>
      </section>

      <section className="form-section-wrapper">
        <Row>
          <Col xl={5} lg={8} md={12}>
            <Form validated={state.EditFormValidated}>
              <Row>
                <UiPassword
                  // label="Current Password"
                  placeholder="Current Password"
                  controlId="current_password"
                  feedback="Please enter your current password"
                  value={state.PassValue0}
                  onChange={HandleCurrentChange} 
                  onBlur={HandleCurrentCheck}
                  isInvalid={state.InValid0[0]}
                  message={state.InValid0[1]}
                />
              </Row>

              <Row>
                <UiPassword 
                  // label="New Password"
                  text="At least 6 characters, but longer is better."
                  placeholder="New Password"
                  controlId="new_password"
                  feedback="Password must be at least 6 characters in length"
                  value={state.PassValue}
                  onChange={HandlePassChange} 
                  onBlur={PasswordCheck}
                  isInvalid={state.InValid[0]}
                  message={state.InValid[1]}
                />
              </Row>

              <Row>
                <UiPassword 
                  // label="Retype New Password"
                  placeholder="Retype New Password"
                  controlId="new_password2"
                  retype
                  value={state.PassValue2}
                  onChange={HandlePassChange} 
                  onBlur={PasswordCheck}
                  isInvalid={state.InValid2[0]}
                  message={state.InValid2[1]}
                />
              </Row>
              
              <Row>
                <Form.Group controlId="consent" className="form-footer-wrapper">
                  <UiButton
                  type="submit"
                  variant="secondary"
                  disabled={state.Different ? false : true}
                  onClick={e => HandleSubmit(e)}
                  >
                    Save
                  </UiButton>
                </Form.Group>

                <Form.Group controlId="consent" className="form-footer-wrapper">
                  <UiButton
                  onClick={e => setState({...Initial})}
                  variant="link"
                  className="gray-color no-padding"
                  visibility={state.Different ? false : true}
                  >
                    Cancel
                  </UiButton>
                </Form.Group>
              </Row>
            </Form>
          </Col>
        </Row>

      </section>
    </div>
  );
};