import React, {useEffect, useState, Fragment} from "react";
import { Container, Row, Col, Spinner, Alert } from "react-bootstrap";
import CryptoJS from "crypto-js";
import {useAppContext} from "../lib/context.js";

import "../resources/styles/dashboard.scss";
import "bootstrap/dist/css/bootstrap.min.css";

import UserLogin from "./user-login";
import UserPasswordReset from "./user-password-reset";
import UserRegister from "./user-register.js";


export default function UserMain(props){
    
    const {appState} = useAppContext();

    const InitialStateValue = {
        Preloader: false,
        View: "login",
        User: null,
        MainAlert: [false, ""],
        IsSideNavCollapsed: false 
    }

    const dbUrl = "https://bodiometerhomerestapi-dot-project-4839952831808961167.appspot.com";
    // const dbUrl = "http://localhost:54928";

    const AssignInitialStateValue = () => {
        let routeArray = window.location.pathname.toLocaleLowerCase().split("/");
        let propsView = "login";
        propsView = routeArray.includes("user") && routeArray.includes("login") ? "login" : propsView;
        propsView = routeArray.includes("user") && routeArray.includes("register") ? "register" : propsView;
        propsView = routeArray.includes("password") && routeArray.includes("reset") ? "password-reset" : propsView;
        return {...InitialStateValue, View: propsView}
    }

    const [state, setState] = useState(AssignInitialStateValue());

    useEffect(() => {
        let AuthData = localStorage.getItem("_USER");
        if(!!AuthData){
            AuthData = JSON.parse(CryptoJS.enc.Utf8.stringify(CryptoJS.AES.decrypt(AuthData, appState.PASSPHRASE)));
            appState.isUserAuthenticated = AuthData.isUserAuthenticated;
            appState.userData = AuthData.userData;
            if(appState.isUserAuthenticated){
                window.location.href = "/user";
            }
        }
    }, []);

    return(
        <Fragment>
            {
                state.Preloader && 
                <div className="preloader-wrapper">
                    <Spinner animation="grow" variant="secondary" />
                </div>
            }
            
            {
                state.View == "login" && 
                <UserLogin dbUrl={dbUrl} state={state} setState={setState} />
            }

            {
                state.View == "password-reset" && 
                <UserPasswordReset dbUrl={dbUrl} state={state} setState={setState} />
            }

            {
                state.View == "register" && 
                <UserRegister dbUrl={dbUrl} state={state} setState={setState} />
            }

            
        </Fragment>
    )
}

