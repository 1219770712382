import React from 'react';
import { Button}  from 'react-bootstrap';

import InfoPopup from './info-popup';


import "../../resources/styles/dashboard.scss";
import "bootstrap/dist/css/bootstrap.min.css";

export default function SideNavLink(props) {

  let color;

  if (props.active) {
    color = 'green';
  } else {
    color = 'blue';
  }

    return(
      <>
      <div>
        <button 
          href={props.href} 
          onClick={e => props.onClick(e)} 
          className={`side-nav-button ${color}-color${props.active ? ' active-sn': ''}`}
          disabled={props.disabled ? true : false }
        >
          <img 
            src={`../../photos/icons/dashboard/SVG/${props.icon}-${color}.svg`} 
            width="13">
          </img>
          {props.list && !props.collapsed && props.ActiveOrganization &&
            <img className={`caret${props.active ? ' active-caret' : ''}`} src={`../../photos/icons/dashboard/SVG/caret-${color}.svg`} width="14" />}
              {props.collapsed ? '' : <span>{props.children}</span>}
          {props.infoPopup && !props.collapsed ? <InfoPopup
                                trigger={props.infoPopup.trigger} 
                                symbol={props.infoPopup.symbol} 
                                content={props.infoPopup.content}
                                placement={props.infoPopup.placement}
                                cta={props.infoPopup.cta}
                                /> : ''}
                                </button>
        {props.list && props.active && !props.collapsed && props.ActiveOrganization &&
          <ul className='side-nav-button-dd'>
            {props.list[0]}
            {props.list[1]}
          </ul>
        }
        </div>
      </>
    );
};