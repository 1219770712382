import React from "react";
import {Button, Popover, OverlayTrigger} from "react-bootstrap";

import UiButton from "./ui-button";

import "../../resources/styles/dashboard.scss";
import "bootstrap/dist/css/bootstrap.min.css";

export default function ProfileToggle(props) {
  const Logout = (e) => {
    props.setState((prev) => {
      localStorage.removeItem("_LD");
      localStorage.removeItem("_A");
      localStorage.setItem("_DO", props.parentState.ActiveOrganization.id)
      // return {...props.parentState, User: null, View: "login"};
      delete prev.Refresh;

      return {...prev, User: null, View: "login"};
    });
  };

  const popover = (
    <Popover className="profile-toggle-wrapper" id={`popover${Math.floor(Math.random()).toString()}`}>
      <Popover.Content>
        <div>
          <h5 className="blue-color">
            {props.user.fname && props.user.fname} {props.user.lname && props.user.lname}
          </h5>
          <h5 className="blue-color muted smaller-text">{props.user.fname && props.parentState.ActiveOrganization && props.parentState.ActiveOrganization.name}</h5>
          <hr />
          <UiButton onClick={(e) => props.setView("profile")} variant="link" className="no-padding gray-color">
            Manage Profile
          </UiButton>
          <UiButton
            as={"<a>"}
            href="#"
            onClick={(e) => {
              Logout();
              window.location.href = "/portal";
            }}
            variant="link"
            className="no-padding gray-color"
          >
            Sign out
          </UiButton>
        </div>
      </Popover.Content>
    </Popover>
  );

  return (
    <OverlayTrigger rootClose={true} trigger="click" placement="bottom-end" overlay={popover}>
      <Button variant="outline-primary" className="profile-toggle-button">
        {props.user.fname && props.user.fname.slice(0, 1)}
        {props.user.fname && props.user.lname.slice(0, 1)}
      </Button>
    </OverlayTrigger>
  );
}
