import React, { useState, Fragment, useRef, useEffect } from "react";
import { Col, Row, Form, Button, ButtonGroup } from "react-bootstrap";
import PhoneInput, { isPossiblePhoneNumber, formatPhoneNumberIntl, parsePhoneNumber } from "react-phone-number-input";
import Datetime from "react-datetime";
import moment from "moment";

import { Default as DefaultIntake, DefaultIsValid } from "../Models/index";

import "bootstrap/dist/css/bootstrap.min.css";
import "react-phone-number-input/style.css";
import "react-datetime/css/react-datetime.css";
import "../Assets/style.scss";

const MotorVehicleInjury = ({ data, requiredDb, validatedDb, isValidDb, pageCompleted, setPageCompleted, activeIndex, viewInvalidFields, onChange }) => {
	const [validatedFields, setValidatedFields] = useState({ ...validatedDb });
	const [isValidFields, setIsValidFields] = useState({ ...isValidDb });
	const requiredFields = { ...requiredDb };

	const Header = ({ label }) => {
		return <h2 style={{ textAlign: "center", marginBottom: "20px" }}>{label}</h2>;
	};

	const dateTimePickerRef = useRef(null);

	const IsFieldValid = (str, type) => {
		if (type == "text") {
			return !!str;
		} else if (type == "email") {
			return (
				!!str &&
				str.toLowerCase().match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/).length > 0
			);
		}
		return true;
	};

	const CheckIfFieldsAreValid = () => {
		var isValid = true;
		if (data.ExistsMotorVehicleInjury) {
			isValid = isValid && (requiredFields.AccidentDate ? isValidFields.AccidentDate : true);
			isValid = isValid && (requiredFields.InsuranceCompanyName ? isValidFields.InsuranceCompanyName : true);
			isValid = isValid && (requiredFields.Claim ? isValidFields.Claim : true);
			isValid = isValid && (requiredFields.Policy ? isValidFields.Policy : true);
			isValid = isValid && (requiredFields.OwnPolicy ? isValidFields.OwnPolicy : true);
			isValid = isValid && (requiredFields.AdjusterName ? isValidFields.AdjusterName : true);
			isValid = isValid && (requiredFields.Phone ? isValidFields.Phone : true);
			isValid = isValid && (requiredFields.PhoneExt ? isValidFields.PhoneExt : true);
			isValid = isValid && (requiredFields.Fax ? isValidFields.Fax : true);
			isValid = isValid && (requiredFields.NameOnPolicy ? isValidFields.NameOnPolicy : true);
			isValid = isValid && (requiredFields.LegalRepresentative ? isValidFields.LegalRepresentative : true);
		}
		return isValid;
	};

	const InitialFieldsValidity = () => {
		var cIsValid = { ...isValidFields };
		cIsValid.ExistsMotorVehicleInjury = data.ExistsMotorVehicleInjury == false || data.ExistsMotorVehicleInjury == true;
		cIsValid.AccidentDate = IsFieldValid(data.AccidentDate, "text");
		cIsValid.InsuranceCompanyName = IsFieldValid(data.InsuranceCompanyName, "text");
		cIsValid.Claim = IsFieldValid(data.Claim, "text");
		cIsValid.Policy = IsFieldValid(data.Policy, "text");
		cIsValid.OwnPolicy = data.OwnPolicy == false || data.OwnPolicy == true;
		cIsValid.AdjusterName = IsFieldValid(data.AdjusterName, "text");
		cIsValid.Phone = IsFieldValid(data.Phone, "text");
		cIsValid.PhoneExt = IsFieldValid(data.PhoneExt, "text");
		cIsValid.Fax = IsFieldValid(data.Fax, "text");
		cIsValid.NameOnPolicy = IsFieldValid(data.NameOnPolicy, "text");
		cIsValid.LegalRepresentative = IsFieldValid(data.LegalRepresentative, "text");
		return cIsValid;
	};

	useEffect(() => {
		pageCompleted[activeIndex] = CheckIfFieldsAreValid();
		setPageCompleted([...pageCompleted]);
	}, [isValidFields]);

	useEffect(() => {
		setIsValidFields({ ...InitialFieldsValidity() });
	}, []);

	useEffect(() => {
		const iterate = (obj) => {
			Object.keys(obj).forEach((key) => {
				if (typeof obj[key] === "object" && obj[key] !== null) {
					iterate(obj[key]);
				} else {
					obj[key] = true;
				}
			});
		};
		if (viewInvalidFields) {
			window.scrollTo({
				top: "0",
				behavior: "smooth",
			});
			var newObj = { ...validatedFields };
			iterate(newObj);
			setValidatedFields({ ...newObj });
		}
	}, [viewInvalidFields]);

	return (
		<div className="intake" style={styles.screen}>
			<Header label={"Motorvehicle related Injury Information"} />

			{/* motor vehicle injury? */}
			<Row>
				<Col md={8}>
					<Form.Label>Are you here due to a motor vehicle accident? {requiredFields.ExistsMotorVehicleInjury ? "*" : ""}</Form.Label>
				</Col>
				<Col md={4} style={{ textAlign: "end" }}>
					<ButtonGroup>
						<Button
							active={data.ExistsMotorVehicleInjury}
							onClick={(evt) => {
								onChange({ ...data, ExistsMotorVehicleInjury: true });
								setValidatedFields({ ...validatedFields, ExistsMotorVehicleInjury: true });
								setIsValidFields({ ...isValidFields, ExistsMotorVehicleInjury: true });
							}}
						>
							Yes
						</Button>
						<Button
							active={!data.ExistsMotorVehicleInjury}
							onClick={(evt) => {
								onChange({ ...DefaultIntake.MotorVehicleInjury, ExistsMotorVehicleInjury: false });
								setValidatedFields({ ...validatedFields, ExistsMotorVehicleInjury: true });
								setIsValidFields({ ...DefaultIsValid.MotorVehicleInjury, ExistsMotorVehicleInjury: true });
							}}
						>
							No
						</Button>
					</ButtonGroup>
				</Col>
			</Row>

			{data.ExistsMotorVehicleInjury && (
				<Fragment>
					{/* accident date & insurance company name */}
					<Row>
						<Col md={2}>
							<Form.Label>Date of Accident (DD/MM/YYYY) {requiredFields.AccidentDate ? "*" : ""}</Form.Label>
						</Col>
						<Col md={4}>
							<div className={validatedFields.AccidentDate && requiredFields.AccidentDate && !isValidFields.AccidentDate ? "isinvalid" : "isvalid"}>
								<Datetime
									initialValue={!!data.AccidentDate ? moment(data.AccidentDate).format("MM/DD/YYYY") : ""}
									onChange={(evt) => {
										onChange({
											...data,
											AccidentDate: moment(evt._d).format("MM/DD/YYYY"),
										});
										setValidatedFields({ ...validatedFields, AccidentDate: true });
										setIsValidFields({ ...isValidFields, AccidentDate: IsFieldValid(evt._d, "text") });
									}}
									dateFormat="MM/DD/YYYY"
									timeFormat={false}
									ref={dateTimePickerRef}
									closeOnSelect
								/>
							</div>
						</Col>
						<Col md={2}>
							<Form.Label>Insurance Company Name {requiredFields.InsuranceCompanyName ? "*" : ""}</Form.Label>
						</Col>
						<Col md={4}>
							<Form.Group className={validatedFields.InsuranceCompanyName && requiredFields.InsuranceCompanyName && !isValidFields.InsuranceCompanyName ? "isinvalid" : "isvalid"}>
								<Form.Control
									placeholder="insurance company name"
									value={data.InsuranceCompanyName}
									onChange={(evt) => {
										onChange({ ...data, InsuranceCompanyName: evt.target.value });
										setValidatedFields({ ...validatedFields, InsuranceCompanyName: true });
										setIsValidFields({ ...isValidFields, InsuranceCompanyName: IsFieldValid(evt.target.value, "text") });
									}}
								/>
							</Form.Group>
						</Col>
					</Row>

					{/* claim & policy */}
					<Row>
						<Col md={2}>
							<Form.Label>Claim # {requiredFields.Claim ? "*" : ""}</Form.Label>
						</Col>
						<Col md={4}>
							<Form.Group className={validatedFields.Claim && requiredFields.Claim && !isValidFields.Claim ? "isinvalid" : "isvalid"}>
								<Form.Control
									placeholder="claim number"
									value={data.Claim}
									onChange={(evt) => {
										onChange({ ...data, Claim: evt.target.value });
										setValidatedFields({ ...validatedFields, Claim: true });
										setIsValidFields({ ...isValidFields, Claim: IsFieldValid(evt.target.value, "text") });
									}}
								/>
							</Form.Group>
						</Col>
						<Col md={2}>
							<Form.Label>Policy # {requiredFields.Policy ? "*" : ""}</Form.Label>
						</Col>
						<Col md={4}>
							<Form.Group className={validatedFields.Policy && requiredFields.Policy && !isValidFields.Policy ? "isinvalid" : "isvalid"}>
								<Form.Control
									placeholder="policy number"
									value={data.Policy}
									onChange={(evt) => {
										onChange({ ...data, Policy: evt.target.value });
										setValidatedFields({ ...validatedFields, Policy: true });
										setIsValidFields({ ...isValidFields, Policy: IsFieldValid(evt.target.value, "text") });
									}}
								/>
							</Form.Group>
						</Col>
					</Row>

					{/* own policy */}
					<Row>
						<Col md={8}>
							<Form.Label>Is this your policy? {requiredFields.OwnPolicy ? "*" : ""}</Form.Label>
						</Col>
						<Col md={4} style={{ textAlign: "end" }}>
							<ButtonGroup>
								<Button
									active={data.OwnPolicy}
									onClick={(evt) => {
										onChange({ ...data, OwnPolicy: true });
									}}
								>
									Yes
								</Button>
								<Button
									active={!data.OwnPolicy}
									onClick={(evt) => {
										onChange({ ...data, OwnPolicy: false });
									}}
								>
									No
								</Button>
							</ButtonGroup>
						</Col>
					</Row>

					{/* adjuster name & fax  */}
					<Row>
						<Col md={2}>
							<Form.Label>Adjuster Name {requiredFields.AdjusterName ? "*" : ""}</Form.Label>
						</Col>
						<Col md={4}>
							<Form.Group className={validatedFields.AdjusterName && requiredFields.AdjusterName && !isValidFields.AdjusterName ? "isinvalid" : "isvalid"}>
								<Form.Control
									placeholder="adjuster name"
									value={data.AdjusterName}
									onChange={(evt) => {
										onChange({ ...data, AdjusterName: evt.target.value });
										setValidatedFields({ ...validatedFields, AdjusterName: true });
										setIsValidFields({ ...isValidFields, AdjusterName: IsFieldValid(evt.target.value, "text") });
									}}
								/>
							</Form.Group>
						</Col>

						<Col md={2}>
							<Form.Label>Fax {requiredFields.Fax ? "*" : ""}</Form.Label>
						</Col>
						<Col md={4}>
							<Form.Group className={validatedFields.Fax && requiredFields.Fax && !isValidFields.Fax ? "isinvalid" : "isvalid"}>
								<Form.Control
									placeholder="fax number"
									value={data.Fax}
									onChange={(evt) => {
										onChange({ ...data, Fax: evt.target.value });
										setValidatedFields({ ...validatedFields, Fax: true });
										setIsValidFields({ ...isValidFields, Fax: IsFieldValid(evt.target.value, "text") });
									}}
								/>
							</Form.Group>
						</Col>
					</Row>

					{/* phone & ext */}
					<Row>
						<Col md={2}>
							<Form.Group>
								<Form.Label>Phone Number {requiredFields.Phone ? "*" : ""}</Form.Label>
							</Form.Group>
						</Col>
						<Col md={4}>
							<div className={validatedFields.Phone && requiredFields.Phone && !isValidFields.Phone ? "isinvalid" : "isvalid"}>
								<PhoneInput
									placeholder="phone number"
									defaultCountry={"CA"}
									value={formatPhoneNumberIntl(data.Phone)}
									onChange={(value) => {
										onChange({
											...data,
											Phone: value,
										});
										setValidatedFields({ ...validatedFields, Phone: true });
										setIsValidFields({ ...isValidFields, Phone: IsFieldValid(value, "text") });
									}}
								/>
							</div>
						</Col>
						<Col md={2}>
							<Form.Group className={validatedFields.PhoneExt && requiredFields.PhoneExt && !isValidFields.PhoneExt ? "isinvalid" : "isvalid"}>
								<Form.Control
									placeholder="ext"
									value={data.PhoneExt}
									onChange={(evt) => {
										onChange({
											...data,
											PhoneExt: evt.target.value,
										});
										setValidatedFields({ ...validatedFields, PhoneExt: true });
										setIsValidFields({ ...isValidFields, PhoneExt: IsFieldValid(evt.target.value, "text") });
									}}
								/>
							</Form.Group>
						</Col>
					</Row>

					{/* name on policy & legal representative */}
					<Row>
						<Col md={2}>
							<Form.Label>Name on policy {requiredFields.NameOnPolicy ? "*" : ""}</Form.Label>
						</Col>
						<Col md={4}>
							<Form.Group className={validatedFields.NameOnPolicy && requiredFields.NameOnPolicy && !isValidFields.NameOnPolicy ? "isinvalid" : "isvalid"}>
								<Form.Control
									placeholder="adjuster name"
									value={data.NameOnPolicy}
									onChange={(evt) => {
										onChange({ ...data, NameOnPolicy: evt.target.value });
										setValidatedFields({ ...validatedFields, NameOnPolicy: true });
										setIsValidFields({ ...isValidFields, NameOnPolicy: IsFieldValid(evt.target.value, "text") });
									}}
								/>
							</Form.Group>
						</Col>

						<Col md={2}>
							<Form.Label>Legal Representative {requiredFields.LegalRepresentative ? "*" : ""}</Form.Label>
						</Col>
						<Col md={4}>
							<Form.Group className={validatedFields.LegalRepresentative && requiredFields.LegalRepresentative && !isValidFields.LegalRepresentative ? "isinvalid" : "isvalid"}>
								<Form.Control
									placeholder="legal representative"
									value={data.LegalRepresentative}
									onChange={(evt) => {
										onChange({ ...data, LegalRepresentative: evt.target.value });
										setValidatedFields({ ...validatedFields, LegalRepresentative: true });
										setIsValidFields({ ...isValidFields, LegalRepresentative: IsFieldValid(evt.target.value, "text") });
									}}
								/>
							</Form.Group>
						</Col>
					</Row>
				</Fragment>
			)}
		</div>
	);
};

const styles = {
	screen: {
		margin: "20px auto",
		maxWidth: "800px",
	},
};

export default MotorVehicleInjury;
