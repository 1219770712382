import React, { useState, Fragment, useRef, useEffect } from "react";
import { Col, Row, Form, Alert, Button, Popover, OverlayTrigger, Modal, Tooltip, Overlay } from "react-bootstrap";
import axios from "axios";
import Datetime from "react-datetime";
import { Line } from "react-chartjs-2";
import { Chart } from "chart.js";
import { jsPDF } from "jspdf";
import annotationPlugin from "chartjs-plugin-annotation";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faUserAlt, faFileArchive, faExclamation, faLock, faSync, faTrash } from "@fortawesome/free-solid-svg-icons";
import { cloneDeep, isEqual } from "lodash";
import moment from "moment";

import "../../resources/styles/dashboard.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-datetime/css/react-datetime.css";

import trendUp from "../../resources/Icons/trend-up.svg";
import trendDown from "../../resources/Icons/trend-down.svg";
import bodiometerLogoImg from "../../resources/logos/bodiometer-logo.png";

import UIButton from "./ui-button.js";
import UiSwitch from "./ui-switch.js";
import Questionnaire from "./FunctionalQuestionnaire/questionnaire";
import ForcePlate from "./ForcePlate/index";
import IntakeDashboard from "./Intake/IntakeDashboard";
import HealthStatus from "./HealthStatus/health_status";
import { RetrieveToken } from "../../lib/token";
import { LoadDashboardPatientList, LoadDashboardAnalytics, GetAccessLevelFromProp, GenerateComponentKey } from "../../lib/scripts";

export default function PatientList(props) {
	const CurrentLicense = props.ActiveOrganization.license[0].details;
	const HasForcePlateAccess = "3" in CurrentLicense.addons && new Date().getTime() <= new Date(CurrentLicense.addons["3"].expiryDate).getTime();
	const HasIntakeAccess = "3" in CurrentLicense.addons && new Date().getTime() <= new Date(CurrentLicense.addons["3"].expiryDate).getTime();
	// const HasFQAccess = "3" in CurrentLicense.addons && new Date().getTime() <= new Date(CurrentLicense.addons["3"].expiryDate).getTime();
	const HasFQAccess = true;
	const HasHomeConnection = "3" in CurrentLicense.addons && new Date().getTime() <= new Date(CurrentLicense.addons["3"].expiryDate).getTime();
	const HasPostureAccess = new Date().getTime() <= new Date(CurrentLicense.expiry).getTime();
	const activeAccessLevel = GetAccessLevelFromProp(props);
	const HasHSAccess = true;

	var searchTimer;
	const doneTypingInterval = 1000;

	const [state, setState] = useState({
		patientId: "",
		caseId: "",
		view: "view-dashboard-overview-section",
		patientList: {},
		filteredPatientList: {},
		analytics: {
			Patients: {
				Total: 0,
				PastMonth: 0,
				PastPastMonth: 0,
			},
			Postures: {
				Total: 0,
				PastMonth: 0,
				PastPastMonth: 0,
			},
		},

		activePageNumber: 1,
		totalPages: 0,
		singlePageTotal: 20,
		totalFilteredPatients: 0,

		filterCriteria: {},
		selectedPatientCasePair: { patientId: "", caseId: "" },
		searchedPatientName: "",

		postureActiveTab: "",
		postureActiveType: "clinic",
		postureIds: [],
		postureId: "",
		postureDb: {},
		journalDb: [],
		journalPain: -1,
		journalNotes: "",
		journalKey: -1,
		journalError: [false, ""],

		fqActiveTab: "",
		fqId: -1,
		fqDb: [],
		fqDbPrev: [],
		fqDbPred: [],
		fqError: [false, ""],
		isUpdateFQConfirmationOpened: false,
		isRemoveFQConfirmationOpened: false,
		isCreateFQConfirmationOpened: false,
		fqChartData: { ...fqDefaultChartData },
		fqLineChartOptions: {},

		postureVPainData: { ...postureVPainDefaultData },
		postureVPainLineChartOptions: {},

		frontPostureData: { ...frontPostureDefaultData },
		frontPostureLineChartOptions: {},
		sidePostureData: { ...sidePostureDefaultData },
		sidePostureLineChartOptions: {},

		homeFrontPostureData: { ...frontPostureDefaultData },
		homeFrontPostureLineChartOptions: {},
		homeSidePostureData: { ...sidePostureDefaultData },
		homeSidePostureLineChartOptions: {},

		journalPainData: { ...journalPainDefaultData },
		isPostureImageZoomed: false,
		zoomedViewDirection: "front",
		painScore: 0,
		painType: "",
		generalNotes: "",
		isPostureGeneralNotesChanged: false,

		selectedPatientData: JSON.parse(JSON.stringify(defaultPatientData)),
		selectedPatientKey: "",
		selectedPatientId: "",
		selectedCaseId: "",
		newPatientInfo: JSON.parse(JSON.stringify(defaultPatientData)),
		newCaseInfo: { ...defaultCaseData },
		error: [false, ""],

		postureScanError: [false, ""],
		postureProgressError: [false, ""],
		editPatientError: [false, ""],
		addPatientError: [false, ""],
		patientListError: [false, ""],

		isPostNewCaseModalVisible: false,
		isAddPatientConfirmationVisible: false,
		isEditPatientConfirmationVisible: false,

		isPatientStatusConfirmationVisible: false,
		isPatientStatusConfirmationTarget: null,
		isPatientToArchiveConfirmationVisible: false,
		isPatientToArchiveConfirmationTarget: null,
		isPatientFromArchiveConfirmationVisible: false,
		isPatientFromArchiveConfirmationTarget: null,

		isPatientRemoveConfirmationVisible: false,
		isPatientRemoveConfirmationTarget: null,
		isRemovePostureConfirmationVisible: false,
		isNewPatientInfoInValid: { ...defaultIsNewPatientDataInValid },
		isNewPatientInfoValidated: { ...defaultIsNewPatientDataValidated },
		isSelectedPatientDataInValid: { ...defaultIsSelectedPatientDataInValid },
		isSelectedPatientDataValidated: { ...defaultIsSelectedPatientDataValidated },

		pdfReportConfig: {
			orientation: "p",
			unit: "px",
			format: "a4",
			putOnlyUsedFonts: true,
			floatPrecision: "smart", // or "smart", default is 16
			compress: true,
		},

		isPPdfDownloadSelected: false, // is posture pdf download selected
		isPPdfDownloadAllSelected: false, // is posture pdf download all selected
		isPEmailClicked: false, // is posture send email button clicked
		isHomeConnectionAccessViolationModalVisible: false,
	});

	const maxNumberOfPatientsInSingleView = 20;

	const frontPostureMainCanvasRef = useRef();
	const sidePostureMainCanvasRef = useRef();
	const postureZoomedCanvasRef = useRef();

	const postureVPainHiddenChartRef = useRef();
	const frontPostureHiddenChartRef = useRef();
	const sidePostureHiddenChartRef = useRef();
	const fqOverallScoreHiddenChartRef = useRef();

	const dateTimePickerRef = useRef(null);

	const isPatientStatusUpdateConfirmationRef = useRef(null);
	const isPatientToArchiveConfirmationRef = useRef(null);
	const isPatientRemoveConfirmationRef = useRef(null);
	const isPatientFromArchiveConfirmationRef = useRef(null);

	const dashboardPaginationRef = useRef(null);

	useEffect(() => {
		Chart.register(annotationPlugin);

		setState({
			...state,
			postureVPainLineChartOptions: { ...postureVPainLineChartOptions },
			frontPostureLineChartOptions: { ...frontPostureLineChartOptions },
			sidePostureLineChartOptions: { ...sidePostureLineChartOptions },
			homeFrontPostureLineChartOptions: { ...frontPostureLineChartOptions },
			homeSidePostureLineChartOptions: { ...sidePostureLineChartOptions },
			journalPainLineChartOptions: { ...journalPainLineChartOptions },
			fqLineChartOptions: { ...fqLineChartOptions },
		});
	}, []);

	const FormatDashboardData = (data) => {
		var tempData = {};
		try {
			if ("patients" in data && !!data.patients && Object.keys(data.patients).length > 0) {
				for (const [pId, pData] of Object.entries(data.patients)) {
					if (!(pId in tempData)) {
						tempData[pId] = { summary: {}, caseList: {} };
						tempData[pId]["summary"]["patientId"] = pData["PatientId"];
						tempData[pId]["summary"]["name"] = !!pData["LastName"] ? pData["FirstName"] + " " + pData["LastName"] : pData["FirstName"];
						tempData[pId]["summary"]["age"] = CalculateAge(pData["DateOfBirth"]);
						tempData[pId]["summary"]["gender"] = pData["Gender"];
						tempData[pId]["summary"]["email"] = pData["Email"];
						tempData[pId]["summary"]["isArchived"] = pData["IsArchived"];
						tempData[pId]["summary"]["isAnonymized"] = pData["IsAnonymized"];
						tempData[pId]["summary"]["dateCreated"] = AddHoursToDate(pData["CreatedAt"], pData["CreatedAtOffset"]);
					}

					var caseInfo = pId in data.cases ? data.cases[pId] : {};
					if (Object.keys(caseInfo).length > 0) {
						tempData[pId]["caseList"][caseInfo["CaseId"]] = {};
						tempData[pId]["caseList"][caseInfo["CaseId"]]["posture"] = {};
						tempData[pId]["caseList"][caseInfo["CaseId"]]["fq"] = {};
						tempData[pId]["caseList"][caseInfo["CaseId"]]["fp"] = {};
						tempData[pId]["caseList"][caseInfo["CaseId"]]["intake"] = {};
						tempData[pId]["caseList"][caseInfo["CaseId"]]["hsu"] = {};
						tempData[pId]["caseList"][caseInfo["CaseId"]]["dateCreated"] = AddHoursToDate(caseInfo["CreatedAt"], caseInfo["CreatedAtOffset"]);
						tempData[pId]["caseList"][caseInfo["CaseId"]]["overallStatus"] = caseInfo["OverallStatus"];
						tempData[pId]["caseList"][caseInfo["CaseId"]]["isNewPatient"] = caseInfo["IsNewPatient"];
						tempData[pId]["caseList"][caseInfo["CaseId"]]["expectedVisits"] = caseInfo["ExpectedVisits"];
						tempData[pId]["caseList"][caseInfo["CaseId"]]["posture"]["numOfAssessments"] = caseInfo["BodiometerNumOfSubmission"];
						tempData[pId]["caseList"][caseInfo["CaseId"]]["posture"]["lastSubmitted"] = !!caseInfo["BodiometerLastSubmitted"]
							? AddHoursToDate(caseInfo["BodiometerLastSubmitted"], caseInfo["BodiometerLastSubmittedOffset"])
							: "-";
						tempData[pId]["caseList"][caseInfo["CaseId"]]["fq"]["numOfAssessments"] = caseInfo["FunctionalQuestionnaireNumOfSubmission"];
						tempData[pId]["caseList"][caseInfo["CaseId"]]["fq"]["lastSubmitted"] = !!caseInfo["FunctionalQuestionnaireLastSubmitted"]
							? AddHoursToDate(caseInfo["FunctionalQuestionnaireLastSubmitted"], caseInfo["FunctionalQuestionnaireLastSubmittedOffset"])
							: "-";

						tempData[pId]["caseList"][caseInfo["CaseId"]]["fp"]["numOfAssessments"] = caseInfo["ForcePlateNumOfSubmission"];
						tempData[pId]["caseList"][caseInfo["CaseId"]]["fp"]["lastSubmitted"] = !!caseInfo["ForcePlateLastSubmitted"]
							? AddHoursToDate(caseInfo["ForcePlateLastSubmitted"], caseInfo["ForcePlateLastSubmittedOffset"])
							: "-";

						tempData[pId]["caseList"][caseInfo["CaseId"]]["hsu"]["numOfAssessments"] = caseInfo["healthStatusUpdateV1NumOfSubmission"];
						tempData[pId]["caseList"][caseInfo["CaseId"]]["hsu"]["lastSubmitted"] = !!caseInfo["healthStatusUpdateV1LastSubmitted"]
							? AddHoursToDate(caseInfo["healthStatusUpdateV1LastSubmitted"], -(new Date().getTimezoneOffset() / 60))
							: "-";

						tempData[pId]["caseList"][caseInfo["CaseId"]]["intake"]["lastSubmitted"] = !!caseInfo["InTakeSubmitted"]
							? AddHoursToDate(caseInfo["InTakeSubmitted"], -(new Date().getTimezoneOffset() / 60))
							: "-";
					}
				}
			}
		} catch (err) {
			console.log(err);
		}
		return tempData;
	};

	const ReloadDashboardOverviewSection = (activePageNumber, singlePageTotal, patientName) => {
		props.setState({
			...props.parentState,
			Preloader: true,
		});
		var promiseOne = LoadDashboardAnalytics(props.parentState.DBAccessToken, props.parentState.ActiveOrganization.id);
		var promiseTwo = LoadDashboardPatientList(props.parentState.DBAccessToken, props.parentState.ActiveOrganization.id, (activePageNumber - 1) * singlePageTotal, singlePageTotal, patientName);
		Promise.all([promiseOne, promiseTwo])
			.then((responseValues) => {
				const [responseOne, responseTwo] = responseValues;
				var defaultAnalytics = {
					Patients: {
						Total: 0,
						PastMonth: 0,
						PastPastMonth: 0,
					},
					Postures: {
						Total: 0,
						PastMonth: 0,
						PastPastMonth: 0,
					},
				};

				if (responseOne.data.success) {
					defaultAnalytics = { ...responseOne.data.data };
				}

				if (responseTwo.data.success) {
					var responseData = { ...responseTwo.data.data };

					var tempPatientList = { ...FormatDashboardData(responseData) };

					var keys = Object.keys(tempPatientList);
					for (let i = 0; i < keys.length; i++) {
						tempPatientList[keys[i]].summary.cases = Object.keys(tempPatientList[keys[i]].caseList).length;
					}

					var nPatients = !!patientName ? keys.length : defaultAnalytics.Patients.Total;
					var totalPages = parseInt(Math.ceil(nPatients / singlePageTotal));

					if (nPatients > 0) {
						setState({
							...state,
							analytics: { ...defaultAnalytics },
							patientList: { ...tempPatientList },
							filteredPatientList: { ...tempPatientList },
							totalPages: totalPages > 0 ? totalPages : 1,
							totalFilteredPatients: keys.length,
							activePageNumber: !!patientName ? 1 : activePageNumber,
							singlePageTotal,
							searchedPatientName: patientName,
						});
					}

					props.setState({
						...props.parentState,
						Preloader: false,
					});
				} else {
					setState({ ...state, error: [false, ""] });
					props.setState({
						...props.parentState,
						Preloader: false,
					});
				}
			})
			.catch((err) => {
				setState({ ...state, error: [true, "Please, check internet connection."] });
				props.setState({
					...props.parentState,
					Preloader: false,
				});
			});
	};

	useEffect(() => {
		if (state.view === "view-dashboard-overview-section") {
			ReloadDashboardOverviewSection(state.activePageNumber, state.singlePageTotal, state.searchedPatientName);
		} else if (state.view === "view-patient-information-selected" || state.view === "view-edit-patient-form") {
			if (!!state.selectedPatientKey) {
				props.setState({
					...props.parentState,
					Preloader: true,
				});

				RetrieveToken()
					.then((token) => {
						axios(
							{
								method: "GET",
								url: props.dbUrl + "/api/patients/v4/patientid",
								headers: {
									Authorization: "Bearer " + token,
									PatientId: state.selectedPatientKey,
								},
							},
							{ timeout: 10000 }
						)
							.then((response) => {
								if (response.status === 200) {
									props.setState({
										...props.parentState,
										Preloader: false,
									});
									if (response.data.success) {
										try {
											response.data.data.Personal.DateOfBirth = response.data.data.Personal.DateOfBirth.substring(0, 10);
										} catch {}

										setState({
											...state,
											selectedPatientData: { ...response.data.data },
											isSelectedPatientDataInValid: {
												...state.isSelectedPatientDataInValid,
												firstName: !!response.data.data.Personal.FirstName ? [false, ""] : [true, "first name is required"],
												lastName: !!response.data.data.Personal.LastName ? [false, ""] : [true, "last name is required"],
												// email: !!response.data.data.Personal.Email && ValidateEmail(response.data.data.Personal.Email) ? [false, ""] : [true, "email is required and must be valid"],
											},
										});
									}
								}
							})
							.catch((err) => {
								setState({ ...state, error: [true, "Please, check internet connection."] });
								props.setState({
									...props.parentState,
									Preloader: false,
								});
							});
					})
					.catch((err) => console.log(err));
			}
		}
	}, [state.view]);

	// useEffect(() => {
	// 	let tempPatientList = GetPatientListFilteredBasedOnCriteria(state.patientList, state.filterCriteria);
	// 	let keys = Object.keys(tempPatientList);
	// 	let nPatients = keys.length;
	// 	setState({
	// 		...state,
	// 		filteredPatientList: { ...tempPatientList },
	// 		activePageNumber: 1,
	// 		totalPages: parseInt(Math.ceil(nPatients / state.singlePageTotal)),
	// 	});
	// }, [state.patientList, state.filterCriteria]);

	const FilterCaseIdByType = (postureDb, type, keys) => {
		keys = keys.filter((key) => {
			if (type === "clinic") {
				return postureDb[key].type === type;
			} else {
				return postureDb[key].type === type || postureDb[key].type === "";
			}
		});
		return keys;
	};

	const SortCaseIdByDate = (postureDb, keys) => {
		const compareDates = (a, b) => {
			if (new Date(postureDb[a].createdAt).getTime() >= new Date(postureDb[b].createdAt).getTime()) {
				return -1;
			} else {
				return 1;
			}
		};
		if (keys.length > 0) {
			return keys.sort(compareDates);
		}
		return [];
	};

	const ComputeFQChartData = () => {
		let labels = state.fqDb.map((v, k) => {
			return new Date(AddHoursToDate(state.fqDb[k].createdAt, state.fqDb[k].createdAtOffset)).toLocaleDateString(dateLocale, defaultDateFormat);
		});

		labels.reverse();

		let tempFQData = { ...fqDefaultChartData };

		tempFQData.labels = labels;

		tempFQData.datasets[0].data = [];
		tempFQData.datasets[1].data = [];
		tempFQData.datasets[2].data = [];
		tempFQData.datasets[3].data = [];

		for (let j = state.fqDb.length - 1; j >= 0; j--) {
			tempFQData.datasets[3].data.push(state.fqDb[j].overallPain);
			// tempFQData.datasets[1].data.push(state.fqDb[j].overallPain);
			if (j === 0) {
				tempFQData.datasets[1].data.push(state.fqDb[j].overallPain);
			} else {
				tempFQData.datasets[1].data.push(null);
			}
		}

		for (let j = state.fqDb.length - 1; j >= 0; j--) {
			let counter = 0;
			let sumScores = 0;
			for (let i = 0; i < 15; i++) {
				if (state.fqDb[j].answers[i] >= 0 && state.fqDb[j].answers[i] <= 4) {
					counter += 1;
					sumScores += state.fqDb[j].answers[i];
				}
			}
			if (counter > 0) {
				tempFQData.datasets[2].data.push((sumScores / 6).toFixed(2));
				// tempFQData.datasets[0].data.push((sumScores / 6).toFixed(2));
				if (j === 0) {
					tempFQData.datasets[0].data.push((sumScores / 6).toFixed(2));
				} else {
					tempFQData.datasets[0].data.push(null);
				}
			} else {
				tempFQData.datasets[2].data.push(-1);
				// tempFQData.datasets[0].data.push(-1);
				if (j === 0) {
					tempFQData.datasets[0].data.push(-1);
				} else {
					tempFQData.datasets[0].data.push(null);
				}
			}
		}

		if (state.fqDbPred.length > 0) {
			tempFQData.labels.push("Next Visit Prediction");
			tempFQData.datasets[1].data.push(state.fqDbPred[state.fqDbPred.length - 1]);

			let counter = 0;
			let sumScores = 0;
			for (let i = 0; i < 15; i++) {
				if (state.fqDbPred[i] >= 0 && state.fqDbPred[i] <= 4) {
					counter += 1;
					sumScores += state.fqDbPred[i];
				}
			}
			if (counter > 0) {
				tempFQData.datasets[0].data.push((sumScores / 6).toFixed(2));
			} else {
				tempFQData.datasets[0].data.push(-1);
			}
		}

		return tempFQData;
	};

	const ComputeChartData = (postureDb, postureActiveType, postureKeys) => {
		const compareDates = (a, b) => {
			if (new Date(postureDb[a].createdAt).getTime() <= new Date(postureDb[b].createdAt).getTime()) {
				return -1;
			} else {
				return 1;
			}
		};
		postureKeys = postureKeys.sort(compareDates);

		postureKeys = postureKeys.filter((key) => {
			if (postureActiveType === "clinic") {
				return postureDb[key].type === postureActiveType;
			} else {
				return postureDb[key].type === postureActiveType || postureDb[key].type === "";
			}
		});

		let labels = postureKeys.map((key) => {
			return new Date(AddHoursToDate(postureDb[key].createdAt, postureDb[key].createdAtOffset)).toLocaleDateString(dateLocale, defaultDateFormat);
		});

		let globalPostureScores = postureKeys.map((key) => {
			return postureDb[key].globalPostureNumber;
		});

		let frontPostureScores = postureKeys.map((key) => {
			return postureDb[key].front.frontPostureNumber;
		});

		let sidePostureScores = postureKeys.map((key) => {
			return postureDb[key].side.sidePostureNumber;
		});

		let painScores = postureKeys.map((key) => {
			return postureDb[key].painScore * 6;
		});

		let tempPostureVPainData = { ...postureVPainDefaultData };

		// Main Data
		tempPostureVPainData.labels = labels;
		tempPostureVPainData.datasets[0].data = globalPostureScores;
		tempPostureVPainData.datasets[1].data = frontPostureScores;
		tempPostureVPainData.datasets[2].data = sidePostureScores;
		tempPostureVPainData.datasets[3].data = painScores;

		// Sample Data
		// tempPostureVPainData.labels = ["Wed, Jan 29, 19", "Thu, Jan 1, 19", "Fri, Feb 2, 19", "Sat, Apr 29, 19", "Sun, Aug 2, 20", "Wed, Jan 29, 21"];
		// tempPostureVPainData.datasets[0].data = [52, 61, 30, 20, 25, 21];
		// tempPostureVPainData.datasets[1].data = [65, 40, 38, 30, 25, 35];
		// tempPostureVPainData.datasets[2].data = [85, 61, 75, 50, 45, 15];
		// tempPostureVPainData.datasets[3].data = [42, 32, 25, 20, 15, 5];

		let tempFrontPostureData = { ...frontPostureDefaultData };

		// Main Data
		let frontHeadScores = postureKeys.map((key) => {
			return postureDb[key].front.frontHeadAngle.toFixed(2);
		});
		let frontShoulderScores = postureKeys.map((key) => {
			return postureDb[key].front.frontShoulderAngle.toFixed(2);
		});
		let frontHipScores = postureKeys.map((key) => {
			return postureDb[key].front.frontHipAngle.toFixed(2);
		});
		let frontKneeScores = postureKeys.map((key) => {
			return postureDb[key].front.frontKneeAngle.toFixed(2);
		});
		let frontAnkleScores = postureKeys.map((key) => {
			return postureDb[key].front.frontAnkleAngle.toFixed(2);
		});
		tempFrontPostureData.labels = labels;
		tempFrontPostureData.datasets[0].data = frontHeadScores;
		tempFrontPostureData.datasets[1].data = frontShoulderScores;
		tempFrontPostureData.datasets[2].data = frontHipScores;
		tempFrontPostureData.datasets[3].data = frontKneeScores;
		tempFrontPostureData.datasets[4].data = frontAnkleScores;

		// Sample Data
		// tempFrontPostureData.labels = ["Wed, Jan 29, 19", "Thu, Jan 1, 19", "Fri, Feb 2, 19", "Sat, Apr 29, 19", "Sun, Aug 2, 20", "Wed, Jan 29, 21"];
		// tempFrontPostureData.datasets[0].data = [-20, -25, -30, -15, -10, 5];
		// tempFrontPostureData.datasets[1].data = [-5, 20, -15, -25, 0, 25];
		// tempFrontPostureData.datasets[2].data = [-25, -10, 15, 0, 15, 5];
		// tempFrontPostureData.datasets[3].data = [15, 15, 20, 10, -5, 0];
		// tempFrontPostureData.datasets[4].data = [-40, -35, -15, -10, 10, 5];

		let tempSidePostureData = { ...sidePostureDefaultData };

		// Main Data
		let sideHeadScores = postureKeys.map((key) => {
			return postureDb[key].side.sideHeadDeviation.toFixed(2);
		});
		let sideShoulderScores = postureKeys.map((key) => {
			return postureDb[key].side.sideShoulderDeviation.toFixed(2);
		});
		let sideHipScores = postureKeys.map((key) => {
			return postureDb[key].side.sideHipDeviation.toFixed(2);
		});
		let sideKneeScores = postureKeys.map((key) => {
			return postureDb[key].side.sideKneeDeviation.toFixed(2);
		});
		tempSidePostureData.labels = labels;
		tempSidePostureData.datasets[0].data = sideHeadScores;
		tempSidePostureData.datasets[1].data = sideShoulderScores;
		tempSidePostureData.datasets[2].data = sideHipScores;
		tempSidePostureData.datasets[3].data = sideKneeScores;

		// Sample Data
		// tempSidePostureData.labels = ["Wed, Jan 29, 19", "Thu, Jan 1, 19", "Fri, Feb 2, 19", "Sat, Apr 29, 19", "Sun, Aug 2, 20", "Wed, Jan 29, 21"];
		// tempSidePostureData.datasets[0].data = [-20, -25, -30, -15, -10, 5];
		// tempSidePostureData.datasets[1].data = [-5, 20, -15, -25, 0, 25];
		// tempSidePostureData.datasets[2].data = [-25, -10, 15, 0, 15, 5];
		// tempSidePostureData.datasets[3].data = [15, 15, 20, 10, -5, 0];

		let tempJournalPainData = { ...journalPainDefaultData };
		tempJournalPainData.labels = [];
		tempJournalPainData.datasets[0].data = [];
		for (let i = state.journalDb.length - 1; i >= 0; i--) {
			tempJournalPainData.labels.push(new Date(AddHoursToDate(state.journalDb[i].createdAt, state.journalDb[i].createdAtOffset)).toLocaleString(dateLocale, defaultDateFormat));
			tempJournalPainData.datasets[0].data.push(state.journalDb[i].painScore * 10);
		}

		return {
			postureVPainData: { ...tempPostureVPainData },
			frontPostureData: { ...tempFrontPostureData },
			sidePostureData: { ...tempSidePostureData },
			journalPainData: { ...tempJournalPainData },
		};
	};

	useEffect(() => {
		if (state.view === "view-patient-posture-main") {
			// If the view is set to progress charts
			if (state.postureActiveTab === "view-posture-progress-charts-tab") {
				if (Object.keys(state.postureDb).length > 0) {
					let keys = Object.keys(state.postureDb);
					keys = FilterCaseIdByType(state.postureDb, state.postureActiveType, [...keys]);
					keys = SortCaseIdByDate(state.postureDb, [...keys]);
					if (keys.length > 0) {
						let computedChartData = ComputeChartData(state.postureDb, state.postureActiveType, keys);
						setState({
							...state,
							postureVPainData: { ...computedChartData.postureVPainData },
							frontPostureData: { ...computedChartData.frontPostureData },
							sidePostureData: { ...computedChartData.sidePostureData },
							journalPainData: { ...computedChartData.journalPainData },
							postureScanError: [false, ""],
						});
					} else {
						setState({
							...state,
							postureId: "",
							postureIds: [],
							postureScanError: [true, `no posture data found for the ${state.postureActiveType} version`],
						});
					}
				} else {
					// GetInitialPostureDataAndDraw();
					setState({
						...state,
						postureId: "",
						postureIds: [],
						postureScanError: [true, `no posture data found for the ${state.postureActiveType} version`],
					});
				}
			}
			// if the view is set to individual scans
			else if (state.postureActiveTab === "view-posture-individual-scan-tab") {
				let allKeys = Object.keys(state.postureDb);

				// if posture data is not empty
				if (allKeys.length > 0) {
					let filteredKeys = FilterCaseIdByType(state.postureDb, state.postureActiveType, [...allKeys]);
					let sortedKeys = SortCaseIdByDate(state.postureDb, [...filteredKeys]);

					// if posture data after type selection is not empty
					if (sortedKeys.length > 0) {
						let jKey = FilterJournal(sortedKeys[0], state.postureDb, state.journalDb);

						// if images of the selected posture is already loaded
						if (state.postureDb[sortedKeys[0]].front.imageLoaded && state.postureDb[sortedKeys[0]].side.imageLoaded) {
							// setState({...state, postureIds: sortedKeys, postureScanError: [false, ""], painScore: state.postureDb[sortedKeys[0]].painScore, generalNotes: state.postureDb[sortedKeys[0]].generalNotes, journalKey: jKey, journalPain: jKey > 0 ? state.journalDb[jKey].painScore : -1, journalNotes: jKey > 0 ? state.journalDb[jKey].journal : ""});
							setState((prev) => {
								return {
									...prev,
									postureId: sortedKeys[0],
									postureIds: sortedKeys,
									postureScanError: [false, ""],
									painScore: state.postureDb[sortedKeys[0]].painScore,
									painType: state.postureDb[sortedKeys[0]].painType,
									generalNotes: state.postureDb[sortedKeys[0]].generalNotes,
									journalKey: jKey,
									journalPain: jKey >= 0 ? state.journalDb[jKey].painScore : -1,
									journalNotes: jKey >= 0 ? state.journalDb[jKey].journal : "",
								};
							});
							DrawMainCanvas(sortedKeys[0], state.postureDb);
						}
						// if images of the selected posture is not loaded then refresh and redraw
						else {
							let updates = {
								postureId: sortedKeys[0],
								postureIds: sortedKeys,
								painScore: state.postureDb[sortedKeys[0]].painScore,
								painType: state.postureDb[sortedKeys[0]].painType,
								generalNotes: state.postureDb[sortedKeys[0]].generalNotes,
								journalKey: jKey,
								journalPain: jKey >= 0 ? state.journalDb[jKey].painScore : -1,
								journalNotes: jKey >= 0 ? state.journalDb[jKey].journal : "",
							};

							RefreshAndDrawPostureImages(sortedKeys[0], state.postureDb, updates);
						}
					}
					// if posture data after type selection is empty
					else {
						GetInitialPostureDataAndDraw();
					}
				}
				// if posture data is empty
				else {
					GetInitialPostureDataAndDraw();
				}
			} else if (state.postureActiveTab === "view-journals-tab") {
				if (state.postureActiveType == "home") {
					var allKeys = Object.keys(state.journalDb);
					var jKey = allKeys.length > 0 ? allKeys[0] : -1;
					setState((prev) => {
						return {
							...prev,
							journalKey: jKey,
							journalPain: jKey >= 0 ? state.journalDb[jKey].painScore : -1,
							journalNotes: jKey >= 0 ? state.journalDb[jKey].journal : "",
						};
					});
				}
			}
		} else if (state.view === "view-patient-fq-main") {
			// If the view is set to progress charts
			if (state.fqActiveTab === "view-fq-progress-charts-tab") {
				let newFQChartData = ComputeFQChartData();
				setState({ ...state, fqChartData: newFQChartData });
			}
			// if the view is set to individual fq questions
			else if (state.fqActiveTab === "view-fq-individual-qa-tab") {
				GetInitialFQDb();
			}
		}
	}, [state.view, state.postureActiveTab, state.postureActiveType, state.fqActiveTab]);

	useEffect(() => {
		if (!!state.postureId && state.postureId in state.postureDb) {
			let jKey = FilterJournal(state.postureId, state.postureDb, state.journalDb);
			if (state.postureDb[state.postureId].front.imageLoaded) {
				setState({
					...state,
					painScore: state.postureDb[state.postureId].painScore,
					painType: state.postureDb[state.postureId].painType,
					generalNotes: state.postureDb[state.postureId].generalNotes,
					journalKey: jKey,
					journalPain: jKey >= 0 ? state.journalDb[jKey].painScore : -1,
					journalNotes: jKey >= 0 ? state.journalDb[jKey].journal : "",
				});
				DrawMainCanvas(state.postureId, state.postureDb);
			} else {
				// setState({...state, painScore: state.postureDb[state.postureId].painScore, generalNotes: state.postureDb[state.postureId].generalNotes, journalKey: jKey, journalPain: jKey > 0 ? state.journalDb[jKey].painScore : -1, journalNotes: jKey > 0 ? state.journalDb[jKey].journal : ""});
				let updates = {
					painScore: state.postureDb[state.postureId].painScore,
					painType: state.postureDb[state.postureId].painType,
					generalNotes: state.postureDb[state.postureId].generalNotes,
					journalKey: jKey,
					journalPain: jKey >= 0 ? state.journalDb[jKey].painScore : -1,
					journalNotes: jKey >= 0 ? state.journalDb[jKey].journal : "",
				};

				RefreshAndDrawPostureImages(state.postureId, state.postureDb, updates);
			}
		}
	}, [state.postureId]);

	const FilterJournal = (postureId, postureDb, journalDb) => {
		if (!!postureId && journalDb.length > 0) {
			let d1 = new Date(AddHoursToDate(postureDb[postureId].createdAt, postureDb[postureId].createdAtOffset));
			for (let i = journalDb.length - 1; i >= 0; i--) {
				let d2 = new Date(AddHoursToDate(journalDb[i].createdAt, journalDb[i].createdAtOffset));
				if (d1.getFullYear() === d2.getFullYear() && d1.getMonth() === d2.getMonth() && d1.getDate() === d2.getDate()) {
					return i;
				}
			}
			// return journalDb.length > 0 ? 0 : -1;
			return -1;
		}
		// return journalDb.length > 0 ? 0 : -1;
		return -1;
	};

	useEffect(() => {
		if (state.isPPdfDownloadSelected) {
			GenerateSAPPdf();
			setState({ ...state, isPPdfDownloadSelected: false });
		}
		if (state.isPPdfDownloadAllSelected) {
			GenerateMAPPdf();
			setState({ ...state, isPPdfDownloadAllSelected: false });
		}
	}, [state.isPPdfDownloadSelected, state.isPPdfDownloadAllSelected]);

	// Generate Multiple Assessment Posture Pdf
	const GenerateMAPPdf = () => {
		props.setState({
			...props.parentState,
			Preloader: true,
		});

		RetrieveToken()
			.then((token) => {
				var promiseList = [];
				var promiseListDescription = [];
				promiseList.push(
					axios(
						{
							method: "GET",
							url: props.dbUrl + "/api/patients/v4/patientid",
							headers: {
								Authorization: "Bearer " + token,
								PatientId: state.patientId,
							},
						},
						{ timeout: 10000 }
					)
				);
				promiseListDescription.push({
					label: "patient.list",
				});

				let postureKeys = Object.keys(state.postureDb).filter((key) => {
					if (state.postureDb[key].type === "clinic") {
						return true;
					} else {
						return false;
					}
				});

				postureKeys = SortCaseIdByDate(state.postureDb, postureKeys);

				let postureKeysLoaded = postureKeys.filter((key) => {
					if (state.postureDb[key].front.imageLoaded) {
						return false;
					} else {
						return true;
					}
				});
				for (let i = 0; i < postureKeysLoaded.length; i++) {
					promiseList.push(
						axios(
							{
								method: "get",
								url: props.dbUrl + "/api/storage/v1/image/posture/front/id/base64",
								headers: {
									Authorization: "Bearer " + token,
									postureId: postureKeysLoaded[i],
								},
							},
							{ timeout: 10000 }
						)
					);
					promiseListDescription.push({
						label: "posture.list",
						direction: "front",
						postureId: postureKeysLoaded[i],
					});
					promiseList.push(
						axios(
							{
								method: "get",
								url: props.dbUrl + "/api/storage/v1/image/posture/side/id/base64",
								headers: {
									Authorization: "Bearer " + token,
									postureId: postureKeysLoaded[i],
								},
							},
							{ timeout: 10000 }
						)
					);
					promiseListDescription.push({
						label: "posture.list",
						direction: "side",
						postureId: postureKeysLoaded[i],
					});
				}

				Promise.all(promiseList)
					.then((arrayValues) => {
						try {
							let patientInfo = null;
							let postureDb = { ...state.postureDb };

							for (let i = 0; i < promiseList.length; i++) {
								if (promiseListDescription[i].label === "patient.list") {
									patientInfo = { ...arrayValues[i].data.data };
								} else if (promiseListDescription[i].label === "posture.list") {
									if (promiseListDescription[i].direction === "front") {
										postureDb[promiseListDescription[i].postureId].front.image = arrayValues[i].data;
										postureDb[promiseListDescription[i].postureId].front.imageLoaded = true;
									} else if (promiseListDescription[i].direction === "side") {
										postureDb[promiseListDescription[i].postureId].side.image = arrayValues[i].data;
										postureDb[promiseListDescription[i].postureId].side.imageLoaded = true;
									}
								}
							}

							let pdf = new jsPDF(state.pdfReportConfig);

							let imageDb = {};
							let postureVPainChart = postureVPainHiddenChartRef.current.toBase64Image();
							let frontPostureChart = frontPostureHiddenChartRef.current.toBase64Image();
							let sidePostureChart = sidePostureHiddenChartRef.current.toBase64Image();

							if (!!bodiometerLogoImg) {
								imageDb["bodiometer.logo"] = new Image();
								imageDb["bodiometer.logo"].src = bodiometerLogoImg;
							} else {
								imageDb["bodiometer.logo"] = null;
							}
							if (!!props.parentState.ActiveOrganizationLogo) {
								imageDb["clinic.logo"] = new Image();
								imageDb["clinic.logo"].src = props.parentState.ActiveOrganizationLogo;
							} else {
								imageDb["clinic.logo"] = null;
							}

							if (!!postureVPainChart) {
								imageDb["chart.postureVPainChart"] = new Image();
								imageDb["chart.postureVPainChart"].src = postureVPainChart;
							} else {
								imageDb["chart.postureVPainChart"] = null;
							}
							if (!!frontPostureChart) {
								imageDb["chart.frontPostureChart"] = new Image();
								imageDb["chart.frontPostureChart"].src = frontPostureChart;
							} else {
								imageDb["chart.frontPostureChart"] = null;
							}
							if (!!sidePostureChart) {
								imageDb["chart.sidePostureChart"] = new Image();
								imageDb["chart.sidePostureChart"].src = sidePostureChart;
							} else {
								imageDb["chart.sidePostureChart"] = null;
							}

							for (let i = 0; i < postureKeys.length; i++) {
								if (postureDb[postureKeys[i]].front.imageLoaded) {
									imageDb[`${postureKeys[i]}.front.image`] = new Image();
									imageDb[`${postureKeys[i]}.front.image`].src = postureDb[postureKeys[i]].front.image;
								} else {
									imageDb[`${postureKeys[i]}.front.image`] = null;
								}
								if (postureDb[postureKeys[i]].side.imageLoaded) {
									imageDb[`${postureKeys[i]}.side.image`] = new Image();
									imageDb[`${postureKeys[i]}.side.image`].src = postureDb[postureKeys[i]].side.image;
								} else {
									imageDb[`${postureKeys[i]}.side.image`] = null;
								}
							}

							let imageKeys = Object.keys(imageDb);
							let imageCount = 0;
							let nImgLoaded = 0;
							for (let i = 0; i < imageKeys.length; i++) {
								if (imageDb[imageKeys[i]] != null) {
									imageCount++;
								}
							}

							for (let i = 0; i < imageKeys.length; i++) {
								if (imageDb[imageKeys[i]] != null) {
									imageDb[imageKeys[i]].onload = () => {
										nImgLoaded++;
										if (nImgLoaded === imageCount) {
											// Page 1 : Basic Layout
											GenerateBasicLayout(pdf, imageDb["bodiometer.logo"], imageDb["clinic.logo"], 1, postureKeys.length + 2);

											// Page 1 : Patient Details & Posture Versus Pain Chart
											GeneratePPdfPageOne(pdf, patientInfo, imageDb["chart.postureVPainChart"]);

											// Page Break
											pdf.addPage(pdf);

											// Page 2 : Basic Layout
											GenerateBasicLayout(pdf, imageDb["bodiometer.logo"], imageDb["clinic.logo"], 2, postureKeys.length + 2);

											// Page 2 : Front & Side Posture Chart
											GeneratePPdfPageTwo(pdf, imageDb["chart.frontPostureChart"], imageDb["chart.sidePostureChart"]);

											// Page 3 : Front & Side Posture Image
											// GenerateSinglePosturePage(pdf, state.postureDb, state.postureId, imageDb[`${state.postureId}.front.image`], imageDb[`${state.postureId}.side.image`]);

											for (let j = 0; j < postureKeys.length; j++) {
												// Page Break
												pdf.addPage(pdf);

												// Page 2 : Basic Layout
												GenerateBasicLayout(pdf, imageDb["bodiometer.logo"], imageDb["clinic.logo"], j + 2, postureKeys.length + 2);

												GenerateSinglePosturePage(pdf, postureDb, postureKeys[j], imageDb[`${postureKeys[j]}.front.image`], imageDb[`${postureKeys[j]}.side.image`]);
											}

											pdf.save(`multiple_assessment_report.pdf`);

											props.setState({
												...props.parentState,
												Preloader: false,
											});

											setState({ ...state, postureDb: { ...postureDb }, isPPdfDownloadAllSelected: false });
										}
									};
								}
							}
						} catch {
							props.setState({
								...props.parentState,
								Preloader: false,
							});
						}

						props.setState({
							...props.parentState,
							Preloader: false,
						});
					})
					.catch((err) => {
						props.setState({
							...props.parentState,
							Preloader: false,
						});
					});
			})
			.catch((err) => console.log(err));
	};

	// Generate Single Assessment Posture Pdf
	const GenerateSAPPdf = () => {
		props.setState({
			...props.parentState,
			Preloader: true,
		});

		RetrieveToken()
			.then((token) => {
				var pdf = new jsPDF(state.pdfReportConfig);

				const promiseOne = axios(
					{
						method: "GET",
						url: props.dbUrl + "/api/patients/v4/patientid",
						headers: {
							Authorization: "Bearer " + token,
							PatientId: state.patientId,
						},
					},
					{ timeout: 10000 }
				);

				promiseOne
					.then((responseOne) => {
						if (responseOne.data.success) {
							let imageDb = {};
							let frontPostureImg = state.postureDb[state.postureId].front.image;
							let sidePostureImg = state.postureDb[state.postureId].side.image;
							let postureVPainChart = postureVPainHiddenChartRef.current.toBase64Image();
							let frontPostureChart = frontPostureHiddenChartRef.current.toBase64Image();
							let sidePostureChart = sidePostureHiddenChartRef.current.toBase64Image();

							if (!!bodiometerLogoImg) {
								imageDb["bodiometer.logo"] = new Image();
								imageDb["bodiometer.logo"].src = bodiometerLogoImg;
							} else {
								imageDb["bodiometer.logo"] = null;
							}
							if (!!props.parentState.ActiveOrganizationLogo) {
								imageDb["clinic.logo"] = new Image();
								imageDb["clinic.logo"].src = props.parentState.ActiveOrganizationLogo;
							} else {
								imageDb["clinic.logo"] = null;
							}

							if (!!frontPostureImg) {
								imageDb[`${state.postureId}.front.image`] = new Image();
								imageDb[`${state.postureId}.front.image`].src = frontPostureImg;
							} else {
								imageDb[`${state.postureId}.front.image`] = null;
							}
							if (!!sidePostureImg) {
								imageDb[`${state.postureId}.side.image`] = new Image();
								imageDb[`${state.postureId}.side.image`].src = sidePostureImg;
							} else {
								imageDb[`${state.postureId}.side.image`] = null;
							}
							if (!!postureVPainChart) {
								imageDb["chart.postureVPainChart"] = new Image();
								imageDb["chart.postureVPainChart"].src = postureVPainChart;
							} else {
								imageDb["chart.postureVPainChart"] = null;
							}
							if (!!frontPostureChart) {
								imageDb["chart.frontPostureChart"] = new Image();
								imageDb["chart.frontPostureChart"].src = frontPostureChart;
							} else {
								imageDb["chart.frontPostureChart"] = null;
							}
							if (!!sidePostureChart) {
								imageDb["chart.sidePostureChart"] = new Image();
								imageDb["chart.sidePostureChart"].src = sidePostureChart;
							} else {
								imageDb["chart.sidePostureChart"] = null;
							}
							let imageKeys = Object.keys(imageDb);
							let imageCount = 0;
							let nImgLoaded = 0;
							for (let i = 0; i < imageKeys.length; i++) {
								if (imageDb[imageKeys[i]] != null) {
									imageCount++;
								}
							}
							for (let i = 0; i < imageKeys.length; i++) {
								if (imageDb[imageKeys[i]] != null) {
									imageDb[imageKeys[i]].onload = () => {
										nImgLoaded++;
										if (nImgLoaded === imageCount) {
											// Page 1 : Basic Layout
											GenerateBasicLayout(pdf, imageDb["bodiometer.logo"], imageDb["clinic.logo"], 1, 3);

											// Page 1 : Patient Details & Posture Versus Pain Chart
											GeneratePPdfPageOne(pdf, responseOne.data.data, imageDb["chart.postureVPainChart"]);

											// Page Break
											pdf.addPage(pdf);

											// Page 2 : Basic Layout
											GenerateBasicLayout(pdf, imageDb["bodiometer.logo"], imageDb["clinic.logo"], 2, 3);

											// Page 2 : Front & Side Posture Chart
											GeneratePPdfPageTwo(pdf, imageDb["chart.frontPostureChart"], imageDb["chart.sidePostureChart"]);

											// Page Break
											pdf.addPage(pdf);

											// Page 3 : Basic Layout
											GenerateBasicLayout(pdf, imageDb["bodiometer.logo"], imageDb["clinic.logo"], 3, 3);

											// Page 3 : Front & Side Posture Image
											GenerateSinglePosturePage(pdf, state.postureDb, state.postureId, imageDb[`${state.postureId}.front.image`], imageDb[`${state.postureId}.side.image`]);

											pdf.save(`single_assessment_report.pdf`);

											props.setState({
												...props.parentState,
												Preloader: false,
											});
										}
									};
								}
							}
						} else {
							props.setState({
								...props.parentState,
								Preloader: false,
							});
						}
					})
					.catch(() => {
						props.setState({
							...props.parentState,
							Preloader: false,
						});
					});
			})
			.catch((err) => console.log(err));
	};

	const RoundedImage = (context, x, y, width, height, radius) => {
		context.beginPath();
		context.moveTo(x + radius, y);
		context.lineTo(x + width - radius, y);
		context.quadraticCurveTo(x + width, y, x + width, y + radius);
		context.lineTo(x + width, y + height - radius);
		context.quadraticCurveTo(x + width, y + height, x + width - radius, y + height);
		context.lineTo(x + radius, y + height);
		context.quadraticCurveTo(x, y + height, x, y + height - radius);
		context.lineTo(x, y + radius);
		context.quadraticCurveTo(x, y, x + radius, y);
		context.closePath();
	};

	const GenerateBasicLayout = (pdf, bodiometerLogo, clinicLogo, pageNumber, totalPages) => {
		let width = pdf.internal.pageSize.getWidth();
		let height = pdf.internal.pageSize.getHeight();
		let verticalStart = 75;
		let horizontalStart = 30;
		let verticalGap = 10;
		let horizontalMidPoint = width / 2;
		let offsetLeft = 20;
		let tempOffset = offsetLeft;

		if (bodiometerLogo != null) {
			pdf.addImage(bodiometerLogo, width - 100, 20, (bodiometerLogo.width / bodiometerLogo.height) * 20, 20);
		}
		if (clinicLogo != null) {
			pdf.addImage(clinicLogo, 10, 10, (clinicLogo.width / clinicLogo.height) * 30, 30);
		}

		pdf.setFontSize(8);
		pdf.setFont(undefined, "normal");
		pdf.setDrawColor(0, 0, 0);
		pdf.line(20, 45, width - 20, 45);
		pdf.line(20, height - 45, width - 20, height - 45);
		pdf.text(`Address: ${props?.parentState?.ActiveOrganization?.address}, ${props?.parentState?.ActiveOrganization?.city}, ${props?.parentState?.ActiveOrganization?.country}`, 20, height - 35, {
			align: "left",
		});
		pdf.text(`Email: ${props?.parentState?.ActiveOrganization?.email}, Phone: ${props?.parentState?.ActiveOrganization?.phone}, Fax: ${props?.parentState?.ActiveOrganization?.fax}`, 20, height - 25, {
			align: "left",
		});
		pdf.text(`Page ${pageNumber} of ${totalPages}`, width - offsetLeft - pdf.getTextWidth(`Page ${pageNumber} of ${totalPages}`), height - 30, { align: "left" });
	};

	const GeneratePPdfPageOne = (pdf, patientInfo, postureVPainChart) => {
		let width = pdf.internal.pageSize.getWidth();
		let height = pdf.internal.pageSize.getHeight();
		let verticalStart = 75;
		let horizontalStart = 30;
		let verticalGap = 10;
		let horizontalMidPoint = width / 2;
		let offsetLeft = 20;

		pdf.setFontSize(16);
		pdf.setFont(undefined, "bold");
		pdf.text("Patient Information", offsetLeft, verticalStart);
		verticalStart += 2 * verticalGap;
		pdf.setFontSize(12);
		pdf.setFont(undefined, "bold");
		pdf.text("Personal", offsetLeft + 10, verticalStart);
		pdf.setFontSize(10);
		pdf.setFont(undefined, "normal");
		verticalStart += verticalGap;
		pdf.text(
			!IsEmpty(patientInfo.Personal.FirstName + patientInfo.Personal.LastName) ? `Name: ${patientInfo.Personal.FirstName + " " + patientInfo.Personal.LastName}` : "Name: -",
			offsetLeft + 15,
			verticalStart
		);
		verticalStart += verticalGap;
		pdf.text(!IsEmpty(patientInfo.Personal.Gender) ? `Sex: ${patientInfo.Personal.Gender}` : "Sex: -", offsetLeft + 15, verticalStart);
		verticalStart += verticalGap;
		pdf.text(!IsEmpty(patientInfo.Personal.Email) ? `Email: ${patientInfo.Personal.Email}` : "Email: -", offsetLeft + 15, verticalStart);
		verticalStart += verticalGap;
		pdf.text(
			!!patientInfo.Personal.DateOfBirth ? `Date of Birth (DD/MM/YYYY): ${new Date(patientInfo.Personal.DateOfBirth.replace(/-/g, '\/').replace(/T.+/, '')).toLocaleDateString("en-US")}` : "Date of Birth (DD/MM/YYYY): -",
			offsetLeft + 15,
			verticalStart
		);
		verticalStart += verticalGap;
		pdf.text(!IsEmpty(patientInfo.Personal.CellPhone) ? `Cellphone: ${patientInfo.Personal.CellPhone}` : "Cellphone: -", offsetLeft + 15, verticalStart);
		verticalStart += verticalGap;
		pdf.text(!IsEmpty(patientInfo.Personal.Address.Country) ? `Country: ${patientInfo.Personal.Address.Country}` : "Country: -", offsetLeft + 15, verticalStart);
		verticalStart += verticalGap;
		pdf.text(!IsEmpty(patientInfo.Personal.Address.Province) ? `Province: ${patientInfo.Personal.Address.Province}` : "Province: -", offsetLeft + 15, verticalStart);
		verticalStart += verticalGap;
		pdf.text(!IsEmpty(patientInfo.Personal.Address.City) ? `City: ${patientInfo.Personal.Address.City}` : "City: -", offsetLeft + 15, verticalStart);
		verticalStart += verticalGap;
		pdf.text(
			!IsEmpty(patientInfo.Personal.Address.StreetAddress1 + patientInfo.Personal.Address.StreetAddress2)
				? `Street Address: ${patientInfo.Personal.Address.StreetAddress1 + " " + patientInfo.Personal.Address.StreetAddress2}`
				: "Street Address: -",
			offsetLeft + 15,
			verticalStart
		);
		verticalStart += verticalGap;
		pdf.text(!IsEmpty(patientInfo.Personal.Address.PostalCode) ? `Postal Code: ${patientInfo.Personal.Address.PostalCode}` : "Postal Code: -", offsetLeft + 15, verticalStart);

		verticalStart += 2 * verticalGap;
		pdf.setFontSize(12);
		pdf.setFont(undefined, "bold");
		pdf.text("Emergency Contact", offsetLeft + 10, verticalStart);
		pdf.setFontSize(10);
		pdf.setFont(undefined, "normal");
		verticalStart += verticalGap;
		pdf.text(
			!IsEmpty(patientInfo.Medical.EmergencyFirstName + patientInfo.Medical.EmergencyLastName)
				? `Name: ${patientInfo.Medical.EmergencyFirstName + " " + patientInfo.Medical.EmergencyLastName}`
				: "Name: -",
			offsetLeft + 15,
			verticalStart
		);
		verticalStart += verticalGap;
		pdf.text(!IsEmpty(patientInfo.Medical.EmergencyPhone) ? `Phone: ${patientInfo.Medical.EmergencyPhone}` : "Phone: -", offsetLeft + 15, verticalStart);

		verticalStart += 2 * verticalGap;
		pdf.setFontSize(12);
		pdf.setFont(undefined, "bold");
		pdf.text("Report", offsetLeft + 10, verticalStart);
		pdf.setFontSize(10);
		pdf.setFont(undefined, "normal");
		verticalStart += verticalGap;
		pdf.text(`Time: ${new Date().toLocaleString(dateLocale, defaultDateFormat)}`, offsetLeft + 15, verticalStart);

		if (postureVPainChart != null) {
			verticalStart += 3 * verticalGap;
			pdf.addImage(
				postureVPainChart,
				offsetLeft + 20,
				verticalStart,
				width - 2 * (offsetLeft + 20),
				(width - 2 * (offsetLeft + 20)) * (postureVPainChart.height / postureVPainChart.width),
				undefined,
				"FAST"
			);
			verticalStart += (width - 2 * (offsetLeft + 20)) * (postureVPainChart.height / postureVPainChart.width) + verticalGap;
			pdf.setFontSize(12);
			pdf.setFont(undefined, "bold");
			pdf.text("POSTURE SCORE VS OVERALL PAIN", width / 2, verticalStart, { align: "center" });
		}
	};

	const GenerateFQPdfPageOne = (pdf, patientInfo, fqChart) => {
		let width = pdf.internal.pageSize.getWidth();
		let height = pdf.internal.pageSize.getHeight();
		let verticalStart = 75;
		let horizontalStart = 30;
		let verticalGap = 10;
		let horizontalMidPoint = width / 2;
		let offsetLeft = 20;

		pdf.setFontSize(16);
		pdf.setFont(undefined, "bold");
		pdf.text("Patient Information", offsetLeft, verticalStart);
		verticalStart += 2 * verticalGap;
		pdf.setFontSize(12);
		pdf.setFont(undefined, "bold");
		pdf.text("Personal", offsetLeft + 10, verticalStart);
		pdf.setFontSize(10);
		pdf.setFont(undefined, "normal");
		verticalStart += verticalGap;
		pdf.text(
			!IsEmpty(patientInfo.Personal.FirstName + patientInfo.Personal.LastName) ? `Name: ${patientInfo.Personal.FirstName + " " + patientInfo.Personal.LastName}` : "Name: -",
			offsetLeft + 15,
			verticalStart
		);
		verticalStart += verticalGap;
		pdf.text(!IsEmpty(patientInfo.Personal.Gender) ? `Sex: ${patientInfo.Personal.Gender}` : "Sex: -", offsetLeft + 15, verticalStart);
		verticalStart += verticalGap;
		pdf.text(!IsEmpty(patientInfo.Personal.Email) ? `Email: ${patientInfo.Personal.Email}` : "Email: -", offsetLeft + 15, verticalStart);
		verticalStart += verticalGap;
		pdf.text(
			!!patientInfo.Personal.DateOfBirth ? `Date of Birth (DD/MM/YYYY): ${new Date(patientInfo.Personal.DateOfBirth).toLocaleDateString()}` : "Date of Birth (DD/MM/YYYY): -",
			offsetLeft + 15,
			verticalStart
		);
		verticalStart += verticalGap;
		pdf.text(!IsEmpty(patientInfo.Personal.CellPhone) ? `Cellphone: ${patientInfo.Personal.CellPhone}` : "Cellphone: -", offsetLeft + 15, verticalStart);
		verticalStart += verticalGap;
		pdf.text(!IsEmpty(patientInfo.Personal.Address.country) ? `Country: ${patientInfo.Personal.Address.Country}` : "Country: -", offsetLeft + 15, verticalStart);
		verticalStart += verticalGap;
		pdf.text(!IsEmpty(patientInfo.Personal.Address.province) ? `Province: ${patientInfo.Personal.Address.Province}` : "Province: -", offsetLeft + 15, verticalStart);
		verticalStart += verticalGap;
		pdf.text(!IsEmpty(patientInfo.Personal.Address.city) ? `City: ${patientInfo.Personal.Address.City}` : "City: -", offsetLeft + 15, verticalStart);
		verticalStart += verticalGap;
		pdf.text(
			!IsEmpty(patientInfo.Personal.Address.StreetAddress1 + patientInfo.Personal.Address.StreetAddress2)
				? `Street Address: ${patientInfo.Personal.Address.StreetAddress1 + " " + patientInfo.Personal.Address.StreetAddress2}`
				: "Street Address: -",
			offsetLeft + 15,
			verticalStart
		);
		verticalStart += verticalGap;
		pdf.text(!IsEmpty(patientInfo.Personal.Address.PostalCode) ? `Postal Code: ${patientInfo.Personal.Address.PostalCode}` : "Postal Code: -", offsetLeft + 15, verticalStart);

		verticalStart += 2 * verticalGap;
		pdf.setFontSize(12);
		pdf.setFont(undefined, "bold");
		pdf.text("Emergency Contact", offsetLeft + 10, verticalStart);
		pdf.setFontSize(10);
		pdf.setFont(undefined, "normal");
		verticalStart += verticalGap;
		pdf.text(
			!IsEmpty(patientInfo.Medical.EmergencyFirstName + patientInfo.Medical.EmergencyFirstName)
				? `Name: ${patientInfo.Medical.EmergencyFirstName + " " + patientInfo.Medical.EmergencyFirstName}`
				: "Name: -",
			offsetLeft + 15,
			verticalStart
		);
		verticalStart += verticalGap;
		pdf.text(!IsEmpty(patientInfo.Medical.EmergencyPhone) ? `Phone: ${patientInfo.Medical.EmergencyPhone}` : "Phone: -", offsetLeft + 15, verticalStart);

		verticalStart += 2 * verticalGap;
		pdf.setFontSize(12);
		pdf.setFont(undefined, "bold");
		pdf.text("Report", offsetLeft + 10, verticalStart);
		pdf.setFontSize(10);
		pdf.setFont(undefined, "normal");
		verticalStart += verticalGap;
		pdf.text(`Time: ${new Date().toLocaleString(dateLocale, defaultDateFormat)}`, offsetLeft + 15, verticalStart);

		if (fqChart != null) {
			verticalStart += 3 * verticalGap;
			pdf.addImage(fqChart, offsetLeft + 20, verticalStart, width - 2 * (offsetLeft + 20), (width - 2 * (offsetLeft + 20)) * (fqChart.height / fqChart.width), undefined, "FAST");
			verticalStart += (width - 2 * (offsetLeft + 20)) * (fqChart.height / fqChart.width) + verticalGap;
			pdf.setFontSize(12);
			pdf.setFont(undefined, "bold");
			pdf.text("FUNCTIONAL SCORE VS OVERALL PAIN", width / 2, verticalStart, { align: "center" });
		}
	};

	const GeneratePPdfPageTwo = (pdf, frontPostureScoreChart, sidePostureScoreChart) => {
		let width = pdf.internal.pageSize.getWidth();
		let height = pdf.internal.pageSize.getHeight();
		let verticalStart = 75;
		let horizontalStart = 30;
		let verticalGap = 10;
		let horizontalMidPoint = width / 2;
		let offsetLeft = 20;

		verticalStart += 4 * verticalGap;

		if (frontPostureScoreChart != null) {
			pdf.addImage(
				frontPostureScoreChart,
				offsetLeft + 20,
				verticalStart,
				width - 2 * (offsetLeft + 20),
				(width - 2 * (offsetLeft + 20)) * (frontPostureScoreChart.height / frontPostureScoreChart.width),
				undefined,
				"FAST"
			);
			verticalStart += (width - 2 * (offsetLeft + 20)) * (frontPostureScoreChart.height / frontPostureScoreChart.width) + verticalGap;
			pdf.setFontSize(12);
			pdf.setFont(undefined, "bold");
			pdf.text("FRONT POSTURE CHART", width / 2, verticalStart, { align: "center" });
		}

		if (sidePostureScoreChart != null) {
			verticalStart += 2 * verticalGap;
			pdf.addImage(
				sidePostureScoreChart,
				offsetLeft + 20,
				verticalStart,
				width - 2 * (offsetLeft + 20),
				(width - 2 * (offsetLeft + 20)) * (sidePostureScoreChart.height / sidePostureScoreChart.width),
				undefined,
				"FAST"
			);
			verticalStart += (width - 2 * (offsetLeft + 20)) * (sidePostureScoreChart.height / sidePostureScoreChart.width) + verticalGap;
			pdf.setFontSize(12);
			pdf.setFont(undefined, "bold");
			pdf.text("SIDE POSTURE CHART", width / 2, verticalStart, { align: "center" });
		}
	};

	const GenerateSingleFQPage = (pdf, s, e, fqId) => {
		let width = pdf.internal.pageSize.getWidth();
		let height = pdf.internal.pageSize.getHeight();
		let verticalStart = 75;
		let horizontalStart = 30;
		let verticalGap = 10;
		let horizontalMidPoint = width / 2;
		let offsetLeft = 20;
		let tempOffset = offsetLeft;

		if (s < 10) {
			pdf.setFontSize(12);
			pdf.setFont(undefined, "bold");
			verticalStart += verticalGap;
			pdf.text(`Date: ${new Date(AddHoursToDate(state.fqDb[fqId].createdAt, state.fqDb[fqId].createdAtOffset)).toLocaleString(dateLocale, defaultDateFormat)}`, offsetLeft + 15, verticalStart - 30);
			verticalStart += 2 * verticalGap;
		} else {
			verticalStart += 2 * verticalGap;
		}

		pdf.setDrawColor(219, 239, 223);
		pdf.setFillColor(219, 239, 223);

		pdf.roundedRect(horizontalStart, verticalStart - 5, 40, 30, 10, 10, "F");
		pdf.roundedRect(horizontalStart + 80 * 1, verticalStart - 5, 40, 30, 10, 10, "F");
		pdf.roundedRect(horizontalStart + 80 * 2, verticalStart - 5, 40, 30, 10, 10, "F");
		pdf.roundedRect(horizontalStart + 80 * 3, verticalStart - 5, 40, 30, 10, 10, "F");
		pdf.roundedRect(horizontalStart + 80 * 4, verticalStart - 5, 40, 30, 10, 10, "F");

		pdf.setTextColor("#6c7d85");
		pdf.setFontSize(16);
		pdf.setFont(undefined, "bold");
		pdf.text("0", horizontalStart + 20, verticalStart - 5 + 20, { align: "center" });
		pdf.text("1", horizontalStart + 20 + 80 * 1, verticalStart - 5 + 20, { align: "center" });
		pdf.text("2", horizontalStart + 20 + 80 * 2, verticalStart - 5 + 20, { align: "center" });
		pdf.text("3", horizontalStart + 20 + 80 * 3, verticalStart - 5 + 20, { align: "center" });
		pdf.text("4", horizontalStart + 20 + 80 * 4, verticalStart - 5 + 20, { align: "center" });

		pdf.setTextColor("#2994d1");
		pdf.setFontSize(12);
		pdf.setFont(undefined, "bold");
		pdf.text("Unable", horizontalStart + 20, verticalStart - 5 + 20 - 25, { align: "center" });
		pdf.text("Very", horizontalStart + 20 + 80 * 1, verticalStart - 5 + 20 - 35, { align: "center" });
		pdf.text("Difficult", horizontalStart + 20 + 80 * 1, verticalStart - 5 + 20 - 25, { align: "center" });
		pdf.text("Moderately", horizontalStart + 20 + 80 * 2, verticalStart - 5 + 20 - 35, { align: "center" });
		pdf.text("Difficult", horizontalStart + 20 + 80 * 2, verticalStart - 5 + 20 - 25, { align: "center" });
		pdf.text("Minimally", horizontalStart + 20 + 80 * 3, verticalStart - 5 + 20 - 35, { align: "center" });
		pdf.text("Difficult", horizontalStart + 20 + 80 * 3, verticalStart - 5 + 20 - 25, { align: "center" });
		pdf.text("Normal", horizontalStart + 20 + 80 * 4, verticalStart - 5 + 20 - 25, { align: "center" });

		verticalStart = verticalStart - 5 + 50;

		pdf.setDrawColor("#6c7d85");
		pdf.setFillColor("#6c7d85");
		pdf.setLineWidth(2);
		pdf.line(horizontalMidPoint - 100, verticalStart, horizontalMidPoint + 100, verticalStart, "F");

		verticalStart = verticalStart + 20;

		for (let i = s - 1; i < e; i++) {
			let index = fqQuesOrder[i];
			if (index === 1) {
				pdf.setTextColor("#2994d1");
				pdf.setFontSize(12);
				pdf.setFont(undefined, "bold");
				pdf.text("SLEEPING", horizontalStart + 5, verticalStart, { align: "left" });
				verticalStart += 15;
			} else if (index === 2) {
				pdf.setTextColor("#2994d1");
				pdf.setFontSize(12);
				pdf.setFont(undefined, "bold");
				pdf.text("SITTING", horizontalStart + 5, verticalStart, { align: "left" });
				verticalStart += 15;
			} else if (index === 4) {
				pdf.setTextColor("#2994d1");
				pdf.setFontSize(12);
				pdf.setFont(undefined, "bold");
				pdf.text("MOVING - STANDING/WALKING/RUNNING", horizontalStart + 5, verticalStart, { align: "left" });
				verticalStart += 15;
			} else if (index === 10) {
				pdf.setTextColor("#2994d1");
				pdf.setFontSize(12);
				pdf.setFont(undefined, "bold");
				pdf.text("DAILY ACTIVITIES", horizontalStart + 5, verticalStart, { align: "left" });
				verticalStart += 15;
			} else if (index === 16) {
				pdf.setTextColor("#2994d1");
				pdf.setFontSize(12);
				pdf.setFont(undefined, "bold");
				pdf.text("JOB ACTIVITIES", horizontalStart + 5, verticalStart, { align: "left" });
				verticalStart += 15;
			}

			pdf.setTextColor("#000000");
			pdf.setFontSize(10);
			pdf.setFont(undefined, "normal");
			pdf.text(`${i + 1}. ${fqQuesList[index - 1]}`, horizontalStart + 5, verticalStart, { align: "left" });
			verticalStart += 15;

			let horizontalStartingPointRect = horizontalMidPoint - 30 * 3 + 15;
			let horizontalStartingPointText = horizontalMidPoint - 30 * 3 + 15 + 8;

			pdf.setFontSize(8);
			pdf.setFont(undefined, "bold");

			for (let j = 0; j < 6; j++) {
				if (state.fqDb[fqId].answers[index - 1] === j) {
					pdf.setDrawColor(69, 179, 73);
					pdf.setFillColor(69, 179, 73);
					pdf.setTextColor("#ffffff");
				} else {
					pdf.setDrawColor(219, 239, 223);
					pdf.setFillColor(219, 239, 223);
					pdf.setTextColor("#6c7d85");
				}
				pdf.roundedRect(horizontalStartingPointRect + j * 30, verticalStart - 5, 15, 15, 15, 15, "F");
				pdf.text(j === 5 ? "NA" : `${j}`, horizontalStartingPointText + j * 30, verticalStart - 5 + 10, {
					align: "center",
				});
			}

			verticalStart += 25;
		}

		if (e >= 10) {
			pdf.setTextColor("#2994d1");
			pdf.setFontSize(12);
			pdf.setFont(undefined, "bold");
			pdf.text("PAIN SCALE", horizontalStart + 5, verticalStart, { align: "left" });
			verticalStart += 15;
			pdf.setTextColor("#000000");
			pdf.setFontSize(10);
			pdf.setFont(undefined, "normal");
			pdf.text("The selected number describes the pain you have experienced over the last week with 0 being no", horizontalStart + 5, verticalStart, { align: "left" });
			verticalStart += 10;
			pdf.text("pain (when not taking pain medication).", horizontalStart + 5, verticalStart, { align: "left" });
			verticalStart += 20;

			let horizontalStartingPointRect = horizontalMidPoint - 30 * 5 + 15;
			let horizontalStartingPointText = horizontalMidPoint - 30 * 5 + 15 + 8;

			pdf.setFontSize(8);
			pdf.setFont(undefined, "bold");

			for (let i = 0; i < 11; i++) {
				if (state.fqDb[fqId].overallPain === i) {
					pdf.setDrawColor(69, 179, 73);
					pdf.setFillColor(69, 179, 73);
					pdf.setTextColor("#ffffff");
				} else {
					pdf.setDrawColor(219, 239, 223);
					pdf.setFillColor(219, 239, 223);
					pdf.setTextColor("#6c7d85");
				}
				pdf.roundedRect(horizontalStartingPointRect + i * 25, verticalStart - 5, 15, 15, 15, 15, "F");
				pdf.text(`${i}`, horizontalStartingPointText + i * 25, verticalStart - 5 + 10, { align: "center" });
			}
		}
	};

	const GenerateSinglePosturePage = (pdf, postureDb, postureId, frontImg, sideImg) => {
		let width = pdf.internal.pageSize.getWidth();
		let height = pdf.internal.pageSize.getHeight();
		let verticalStart = 75;
		let horizontalStart = 30;
		let verticalGap = 10;
		let horizontalMidPoint = width / 2;
		let offsetLeft = 20;
		let tempOffset = offsetLeft;

		tempOffset = offsetLeft;
		pdf.setFontSize(12);
		pdf.setFont(undefined, "bold");
		pdf.text("Date: ", tempOffset, verticalStart, { align: "left" });
		tempOffset += pdf.getTextWidth("Date: ");
		pdf.setFont(undefined, "normal");
		pdf.text(`${new Date(AddHoursToDate(state.postureDb[postureId].createdAt, state.postureDb[postureId].createdAtOffset)).toLocaleString(dateLocale, defaultDateFormat)}`, tempOffset, verticalStart, {
			align: "left",
		});

		// tempOffset = width - offsetLeft - pdf.getTextWidth(`Type: ${IsEmpty(postureDb[postureId].type) ? "clinic" : postureDb[postureId].type}`);
		// pdf.setFont(undefined, "bold");
		// pdf.text("Type: ", tempOffset, verticalStart, {align: "left"});
		// tempOffset += pdf.getTextWidth("Type: ");
		// pdf.setFont(undefined, "normal");
		// pdf.text(`${IsEmpty(postureDb[postureId].type) ? "clinic" : postureDb[postureId].type}`, tempOffset, verticalStart, {align: "left"});

		verticalStart += 2 * verticalGap;
		pdf.setDrawColor(255, 0, 0);
		pdf.setFillColor(255, 0, 0);

		const canvas_1 = document.createElement("canvas");
		canvas_1.width = frontImg.width;
		canvas_1.height = frontImg.height;
		const context_1 = canvas_1.getContext("2d");

		RoundedImage(context_1, 0, 0, frontImg.width, frontImg.height, 20);
		context_1.clip();
		context_1.drawImage(frontImg, 0, 0, frontImg.width, frontImg.height);
		context_1.restore();
		DrawFrontViewSkeleton(postureId, postureDb, context_1, {
			maxWidth: frontImg.width,
			maxHeight: frontImg.height,
			additionalMeasurements: true,
		});

		pdf.addImage(canvas_1.toDataURL("image/png", 0.6), "PNG", offsetLeft, verticalStart, width / 2 - 60, (width / 2 - 60) * (frontImg.height / frontImg.width), undefined, "FAST");

		const canvas_2 = document.createElement("canvas");
		canvas_2.width = sideImg.width;
		canvas_2.height = sideImg.height;
		const context_2 = canvas_2.getContext("2d");

		RoundedImage(context_2, 0, 0, sideImg.width, sideImg.height, 20);
		context_2.clip();
		context_2.drawImage(sideImg, 0, 0, sideImg.width, sideImg.height);
		context_2.restore();
		DrawSideViewSkeleton(postureId, postureDb, context_2, {
			maxWidth: sideImg.width,
			maxHeight: sideImg.height,
			additionalMeasurements: true,
		});

		pdf.addImage(canvas_2.toDataURL("image/png", 0.6), "PNG", width / 2 + 20, verticalStart, width / 2 - 60, (width / 2 - 60) * (sideImg.height / sideImg.width), undefined, "FAST");
		verticalStart += (width / 2 - 60) * (sideImg.height / sideImg.width);

		verticalStart += 2 * verticalGap;
		let tempVerticalGap = verticalStart;

		tempOffset = offsetLeft;
		pdf.setFontSize(12);
		pdf.setFont(undefined, "bold");
		pdf.setTextColor(0, 0, 0);
		pdf.text("FRONT POSTURE SCORE: ", tempOffset, verticalStart, { align: "left" });
		tempOffset += pdf.getTextWidth("FRONT POSTURE SCORE: ");
		pdf.setTextColor(42, 146, 216);
		pdf.text(`${Math.abs(postureDb[postureId].front.frontPostureNumber).toFixed(2)}`, tempOffset, verticalStart, {
			align: "left",
		});
		tempOffset = offsetLeft;

		// Front Posture Head
		verticalStart += verticalGap;
		pdf.setFontSize(10);
		pdf.setFont(undefined, "normal");
		pdf.setTextColor(0, 0, 0);
		pdf.text("Head: ", tempOffset, verticalStart, { align: "left" });
		tempOffset += pdf.getTextWidth("Head: ");
		pdf.setTextColor(42, 146, 216);
		pdf.text(`${Math.abs(postureDb[postureId].front.frontHeadAngle).toFixed(2)}°`, tempOffset, verticalStart, {
			align: "left",
		});
		tempOffset += pdf.getTextWidth(`${Math.abs(postureDb[postureId].front.frontHeadAngle).toFixed(2)}°`);
		pdf.setTextColor(0, 0, 0);
		pdf.text(" lower on the ", tempOffset, verticalStart, { align: "left" });
		tempOffset += pdf.getTextWidth(" lower on the ");
		pdf.setTextColor(42, 146, 216);
		pdf.text(`${postureDb[postureId].front.frontHeadAngle >= 0 ? "right." : "left."}`, tempOffset, verticalStart, {
			align: "left",
		});
		pdf.setTextColor(0, 0, 0);
		tempOffset = offsetLeft;

		// Front Posture Shoulder
		verticalStart += verticalGap;
		pdf.setFontSize(10);
		pdf.setFont(undefined, "normal");
		pdf.setTextColor(0, 0, 0);
		pdf.text("Shoulder: ", tempOffset, verticalStart, { align: "left" });
		tempOffset += pdf.getTextWidth("Shoulder: ");
		pdf.setTextColor(42, 146, 216);
		pdf.text(`${Math.abs(postureDb[postureId].front.frontShoulderAngle).toFixed(2)}°`, tempOffset, verticalStart, {
			align: "left",
		});
		tempOffset += pdf.getTextWidth(`${Math.abs(postureDb[postureId].front.frontShoulderAngle).toFixed(2)}°`);
		pdf.setTextColor(0, 0, 0);
		pdf.text(" lower on the ", tempOffset, verticalStart, { align: "left" });
		tempOffset += pdf.getTextWidth(" lower on the ");
		pdf.setTextColor(42, 146, 216);
		pdf.text(`${postureDb[postureId].front.frontShoulderAngle >= 0 ? "right." : "left."}`, tempOffset, verticalStart, {
			align: "left",
		});
		pdf.setTextColor(0, 0, 0);
		tempOffset = offsetLeft;

		// Front Posture Hip
		verticalStart += verticalGap;
		pdf.setFontSize(10);
		pdf.setFont(undefined, "normal");
		pdf.setTextColor(0, 0, 0);
		pdf.text("Hip: ", tempOffset, verticalStart, { align: "left" });
		tempOffset += pdf.getTextWidth("Hip: ");
		pdf.setTextColor(42, 146, 216);
		pdf.text(`${Math.abs(postureDb[postureId].front.frontHipAngle).toFixed(2)}°`, tempOffset, verticalStart, {
			align: "left",
		});
		tempOffset += pdf.getTextWidth(`${Math.abs(postureDb[postureId].front.frontHipAngle).toFixed(2)}°`);
		pdf.setTextColor(0, 0, 0);
		pdf.text(" lower on the ", tempOffset, verticalStart, { align: "left" });
		tempOffset += pdf.getTextWidth(" lower on the ");
		pdf.setTextColor(42, 146, 216);
		pdf.text(`${postureDb[postureId].front.frontHipAngle >= 0 ? "right." : "left."}`, tempOffset, verticalStart, {
			align: "left",
		});
		pdf.setTextColor(0, 0, 0);
		tempOffset = offsetLeft;

		// Front Posture Knee
		verticalStart += verticalGap;
		pdf.setFontSize(10);
		pdf.setFont(undefined, "normal");
		pdf.setTextColor(0, 0, 0);
		pdf.text("Knee: ", tempOffset, verticalStart, { align: "left" });
		tempOffset += pdf.getTextWidth("Knee: ");
		pdf.setTextColor(42, 146, 216);
		pdf.text(`${Math.abs(postureDb[postureId].front.frontKneeAngle).toFixed(2)}°`, tempOffset, verticalStart, {
			align: "left",
		});
		tempOffset += pdf.getTextWidth(`${Math.abs(postureDb[postureId].front.frontKneeAngle).toFixed(2)}°`);
		pdf.setTextColor(0, 0, 0);
		pdf.text(" lower on the ", tempOffset, verticalStart, { align: "left" });
		tempOffset += pdf.getTextWidth(" lower on the ");
		pdf.setTextColor(42, 146, 216);
		pdf.text(`${postureDb[postureId].front.frontKneeAngle >= 0 ? "right." : "left."}`, tempOffset, verticalStart, {
			align: "left",
		});
		pdf.setTextColor(0, 0, 0);
		tempOffset = offsetLeft;

		// Front Posture Ankle
		verticalStart += verticalGap;
		pdf.setFontSize(10);
		pdf.setFont(undefined, "normal");
		pdf.setTextColor(0, 0, 0);
		pdf.text("Ankle: ", tempOffset, verticalStart, { align: "left" });
		tempOffset += pdf.getTextWidth("Ankle: ");
		pdf.setTextColor(42, 146, 216);
		pdf.text(`${Math.abs(postureDb[postureId].front.frontAnkleAngle).toFixed(2)}°`, tempOffset, verticalStart, {
			align: "left",
		});
		tempOffset += pdf.getTextWidth(`${Math.abs(postureDb[postureId].front.frontAnkleAngle).toFixed(2)}°`);
		pdf.setTextColor(0, 0, 0);
		pdf.text(" lower on the ", tempOffset, verticalStart, { align: "left" });
		tempOffset += pdf.getTextWidth(" lower on the ");
		pdf.setTextColor(42, 146, 216);
		pdf.text(`${postureDb[postureId].front.frontAnkleAngle >= 0 ? "right." : "left."}`, tempOffset, verticalStart, {
			align: "left",
		});
		pdf.setTextColor(0, 0, 0);
		tempOffset = offsetLeft;

		verticalStart = tempVerticalGap;
		tempOffset = width / 2 + 20;
		pdf.setFontSize(12);
		pdf.setFont(undefined, "bold");
		pdf.setTextColor(0, 0, 0);
		pdf.text("SIDE POSTURE SCORE: ", tempOffset, verticalStart, { align: "left" });
		tempOffset += pdf.getTextWidth("SIDE POSTURE SCORE: ");
		pdf.setTextColor(42, 146, 216);
		pdf.text(`${Math.abs(postureDb[postureId].side.sidePostureNumber).toFixed(2)}`, tempOffset, verticalStart, {
			align: "left",
		});

		// Side Posture Head
		verticalStart += verticalGap;
		tempOffset = width / 2 + 20;
		pdf.setFontSize(10);
		pdf.setFont(undefined, "normal");
		pdf.setTextColor(0, 0, 0);
		pdf.text("Head: ", tempOffset, verticalStart, { align: "left" });
		tempOffset += pdf.getTextWidth("Head: ");
		pdf.setTextColor(42, 146, 216);
		pdf.text(`${Math.abs(postureDb[postureId].side.sideHeadDeviation).toFixed(2)}°`, tempOffset, verticalStart, {
			align: "left",
		});
		tempOffset += pdf.getTextWidth(`${Math.abs(postureDb[postureId].side.sideHeadDeviation).toFixed(2)}°`);
		pdf.setTextColor(0, 0, 0);
		pdf.text(`${postureDb[postureId].side.sideHeadDeviation >= 0 ? " forward from " : " backward from "}`, tempOffset, verticalStart, { align: "left" });
		tempOffset += pdf.getTextWidth(`${postureDb[postureId].side.sideHeadDeviation >= 0 ? " forward from " : " backward from "}`);
		pdf.text("shoulder.", tempOffset, verticalStart, { align: "left" });
		pdf.setTextColor(0, 0, 0);
		tempOffset = offsetLeft;

		// Head: <span style={{color: "#2A92CF"}}>{Math.abs(postureDb[postureId].side.sideHeadDeviation).toFixed(2)}°</span> {postureDb[postureId].side.sideHeadDeviation < 0 ? "backward" : "forward"} from shoulder.

		// Side Posture Shoulder
		verticalStart += verticalGap;
		tempOffset = width / 2 + 20;
		pdf.setFontSize(10);
		pdf.setFont(undefined, "normal");
		pdf.setTextColor(0, 0, 0);
		pdf.text("Shoulder: ", tempOffset, verticalStart, { align: "left" });
		tempOffset += pdf.getTextWidth("Shoulder: ");
		pdf.setTextColor(42, 146, 216);
		pdf.text(`${Math.abs(postureDb[postureId].side.sideShoulderDeviation).toFixed(2)}°`, tempOffset, verticalStart, {
			align: "left",
		});
		tempOffset += pdf.getTextWidth(`${Math.abs(postureDb[postureId].side.sideShoulderDeviation).toFixed(2)}°`);
		pdf.setTextColor(0, 0, 0);
		pdf.text(`${postureDb[postureId].side.sideShoulderDeviation >= 0 ? " forward from " : " backward from "}`, tempOffset, verticalStart, { align: "left" });
		tempOffset += pdf.getTextWidth(`${postureDb[postureId].side.sideShoulderDeviation >= 0 ? " forward from " : " backward from "}`);
		pdf.text("hip.", tempOffset, verticalStart, { align: "left" });
		pdf.setTextColor(0, 0, 0);
		tempOffset = offsetLeft;

		// Side Posture Hip
		verticalStart += verticalGap;
		tempOffset = width / 2 + 20;
		pdf.setFontSize(10);
		pdf.setFont(undefined, "normal");
		pdf.setTextColor(0, 0, 0);
		pdf.text("Hip: ", tempOffset, verticalStart, { align: "left" });
		tempOffset += pdf.getTextWidth("Hip: ");
		pdf.setTextColor(42, 146, 216);
		pdf.text(`${Math.abs(postureDb[postureId].side.sideHipDeviation).toFixed(2)}°`, tempOffset, verticalStart, {
			align: "left",
		});
		tempOffset += pdf.getTextWidth(`${Math.abs(postureDb[postureId].side.sideHipDeviation).toFixed(2)}°`);
		pdf.setTextColor(0, 0, 0);
		pdf.text(`${postureDb[postureId].side.sideHipDeviation >= 0 ? " forward from " : " backward from "}`, tempOffset, verticalStart, { align: "left" });
		tempOffset += pdf.getTextWidth(`${postureDb[postureId].side.sideHipDeviation >= 0 ? " forward from " : " backward from "}`);
		pdf.text("knee.", tempOffset, verticalStart, { align: "left" });
		pdf.setTextColor(0, 0, 0);
		tempOffset = offsetLeft;

		// Side Posture Knee
		verticalStart += verticalGap;
		tempOffset = width / 2 + 20;
		pdf.setFontSize(10);
		pdf.setFont(undefined, "normal");
		pdf.setTextColor(0, 0, 0);
		pdf.text("Knee: ", tempOffset, verticalStart, { align: "left" });
		tempOffset += pdf.getTextWidth("Knee: ");
		pdf.setTextColor(42, 146, 216);
		pdf.text(`${Math.abs(postureDb[postureId].side.sideKneeDeviation).toFixed(2)}°`, tempOffset, verticalStart, {
			align: "left",
		});
		tempOffset += pdf.getTextWidth(`${Math.abs(postureDb[postureId].side.sideKneeDeviation).toFixed(2)}°`);
		pdf.setTextColor(0, 0, 0);
		pdf.text(`${postureDb[postureId].side.sideKneeDeviation >= 0 ? " forward from " : " backward from "}`, tempOffset, verticalStart, { align: "left" });
		tempOffset += pdf.getTextWidth(`${postureDb[postureId].side.sideKneeDeviation >= 0 ? " forward from " : " backward from "}`);
		pdf.text("ankle.", tempOffset, verticalStart, { align: "left" });
		pdf.setTextColor(0, 0, 0);
		tempOffset = offsetLeft;

		verticalStart += 4 * verticalGap;
		tempOffset = offsetLeft;
		pdf.setFontSize(12);
		pdf.setFont(undefined, "bold");
		pdf.setTextColor(0, 0, 0);
		pdf.text("TOTAL POSTURE SCORE: ", tempOffset, verticalStart, { align: "left" });
		tempOffset += pdf.getTextWidth("TOTAL POSTURE SCORE: ");
		pdf.setTextColor(42, 146, 216);
		pdf.text(`${Math.abs(postureDb[postureId].globalPostureNumber).toFixed(2)}`, tempOffset, verticalStart, {
			align: "left",
		});
		tempOffset = offsetLeft;

		verticalStart += verticalGap;
		tempOffset = offsetLeft;
		pdf.setFontSize(12);
		pdf.setFont(undefined, "bold");
		pdf.setTextColor(0, 0, 0);
		pdf.text("PAIN SCORE: ", tempOffset, verticalStart, { align: "left" });
		tempOffset += pdf.getTextWidth("PAIN SCORE: ");
		pdf.setTextColor(42, 146, 216);
		pdf.text(`${postureDb[postureId].painScore}`, tempOffset, verticalStart, { align: "left" });
		tempOffset = offsetLeft;

		verticalStart += verticalGap;
		tempOffset = offsetLeft;
		pdf.setFontSize(12);
		pdf.setFont(undefined, "bold");
		pdf.setTextColor(0, 0, 0);
		pdf.text("PAIN TYPE: ", tempOffset, verticalStart, { align: "left" });
		tempOffset += pdf.getTextWidth("PAIN TYPE: ");
		pdf.setTextColor(42, 146, 216);
		pdf.text(`${postureDb[postureId].painType != "" ? postureDb[postureId].painType.slice(0, 1).toUpperCase() + postureDb[postureId].painType.slice(1) : ""}`, tempOffset, verticalStart, {
			align: "left",
		});
		tempOffset = offsetLeft;

		let noteText = postureDb[postureId].generalNotes;
		let numOfLineBreaks = (noteText.match(/\n|\r|\\n|\\r/g) || []).length;
		let textWidth = pdf.getTextWidth(noteText);
		let numOfLines = textWidth / width;
		noteText = noteText.replace(/\\n|\\r/g, "\n");
		if(Math.max(numOfLineBreaks, numOfLines) > 5){
			verticalStart = 75;
			pdf.addPage();
		}
		verticalStart += verticalGap;
		tempOffset = offsetLeft;
		pdf.setFontSize(12);
		pdf.setFont(undefined, "bold");
		pdf.setTextColor(0, 0, 0);
		pdf.text("NOTES: ", tempOffset, verticalStart, { align: "left" });
		tempOffset += pdf.getTextWidth("NOTES: ");
		pdf.setTextColor(42, 146, 216);
		
		pdf.text(`${noteText}`, tempOffset, verticalStart, {
			align: "left",
			maxWidth: width - 2 * pdf.getTextWidth("NOTES: "),
		});
		tempOffset = offsetLeft;
		pdf.setTextColor(0, 0, 0);
	};

	const PutCaseInfo = (patientId, caseId, data) => {
		props.setState({
			...props.parentState,
			Preloader: true,
		});

		RetrieveToken()
			.then((token) => {
				axios(
					{
						method: "PUT",
						url: props.dbUrl + "/api/patientcases/v3/update",
						headers: {
							Authorization: "Bearer " + token,
							caseId: caseId,
						},
						data: { ...data },
					},
					{ timeout: 10000 }
				)
					.then((response) => {
						props.setState({
							...props.parentState,
							Preloader: false,
						});
						if (response.status === 200) {
							if (response.data.success) {
								let tempFilteredPatientList = { ...state.filteredPatientList };
								tempFilteredPatientList[patientId].caseList[caseId].isNewPatient = data.isNewPatient;
								tempFilteredPatientList[patientId].caseList[caseId].expectedVisits = data.expectedVisits;
								tempFilteredPatientList[patientId].caseList[caseId].overallStatus = data.overallStatus;
								// setFilteredPatientList(tempFilteredPatientList);
								setState({ ...state, filteredPatientList: { ...tempFilteredPatientList } });
							}
						}
					})
					.catch((err) => {
						setState({ ...state, error: [true, "please, check internet connection."] });
						props.setState({
							...props.parentState,
							Preloader: false,
						});
					});
			})
			.catch((err) => console.log(err));
	};

	const PostNewCase = () => {
		props.setState({
			...props.parentState,
			Preloader: true,
		});

		RetrieveToken()
			.then((token) => {
				axios(
					{
						method: "POST",
						url: props.dbUrl + "/api/patientcases/v3/add",
						headers: {
							Authorization: "Bearer " + token,
						},
						data: { ...state.newCaseInfo },
					},
					{ timeout: 10000 }
				)
					.then((response) => {
						props.setState({
							...props.parentState,
							Preloader: false,
						});
						if (response.status === 200) {
							let tempPatientList = { ...state.patientList };
							tempPatientList[state.patientId].caseList[response.data.caseId] = {
								dateCreated: new Date().toLocaleString(dateLocale, defaultDateFormat),
								expectedVisits: state.newCaseInfo.expectedVisits,
								isNewPatient: false,
								overallStatus: 2,
								posture: {
									lastSubmitted: "",
									numOfAssessments: 0,
								},
							};
							setState({
								...state,
								patientList: { ...tempPatientList },
								filteredPatientList: { ...tempPatientList },
								isPostNewCaseModalVisible: false,
							});
							// setState({...state, isPostNewCaseModalVisible: false});
						} else {
							setState({ ...state, error: [true, "something went wrong."] });
						}
					})
					.catch((err) => {
						setState({ ...state, error: [true, "please, check internet connection."] });
						props.setState({
							...props.parentState,
							Preloader: false,
						});
					});
			})
			.catch((err) => console.log(err));
	};

	const GetPatientListFilteredBasedOnCriteria = (patientList, filterCriteria) => {
		let filteredKeys = Object.keys(patientList).filter((ind) => {
			if (!!filterCriteria.name) {
				filterCriteria.name = filterCriteria.name.trim();
				let regex = new RegExp(filterCriteria.name, "i");
				let parts = patientList[ind].summary.name.split(" ");
				let matchExists = false;
				for (let i = 0; i < parts.length; i++) {
					matchExists = matchExists || regex.test(parts[i]);
				}
				return matchExists;
			} else {
				return true;
			}
		});
		if (filteredKeys.length > 0) {
			let patientListSubset = filteredKeys.reduce((newObject, index) => {
				newObject[index] = patientList[index];
				return newObject;
			}, {});
			return patientListSubset;
		} else {
			return {};
		}
	};

	const GetInitialFQDb = () => {
		try {
			props.setState({
				...props.parentState,
				Preloader: true,
			});

			RetrieveToken()
				.then((token) => {
					// Get Initial Functional Data
					const promiseOne = axios(
						{
							method: "GET",
							url: props.dbUrl + "/api/patientfunctionalQuestions/v1/caseid",
							headers: {
								Authorization: "Bearer " + token,
								caseId: state.caseId,
							},
						},
						{ timeout: 10000 }
					);

					Promise.all([promiseOne])
						.then((responseArrayValuesOne) => {
							const [responseOne] = responseArrayValuesOne;

							if (responseOne.status === 200) {
								if (responseOne.data.success) {
									let tempFQData = !!responseOne.data.data ? responseOne.data.data : [];
									let tempFQSorted = tempFQData.sort((a, b) => {
										let d1 = new Date(AddHoursToDate(a.createdAt, a.createdAtOffset));
										let d2 = new Date(AddHoursToDate(b.createdAt, b.createdAtOffset));
										return d1.getTime() < d2.getTime() ? 1 : d1.getTime() === d2.getTime() ? 0 : -1;
									});
									if (tempFQSorted.length >= 2) {
										let arrayOne = tempFQSorted[1].answers.concat([tempFQSorted[1].overallPain]);
										let arrayTwo = tempFQSorted[0].answers.concat([tempFQSorted[0].overallPain]);

										const promiseTwo = axios(
											{
												method: "POST",
												url: props.dbUrl + "/api/patientfunctionalquestions/predict/v1/next",
												headers: {
													Authorization: "Bearer " + token,
													caseId: state.caseId,
												},
												data: { value: arrayOne.concat(arrayTwo) },
											},
											{ timeout: 10000 }
										);

										Promise.all([promiseTwo])
											.then((responseArrayValuesTwo) => {
												props.setState({
													...props.parentState,
													Preloader: false,
												});

												const [responseTwo] = responseArrayValuesTwo;
												setState({
													...state,
													fqDb: tempFQSorted,
													fqDbPrev: cloneDeep(tempFQSorted),
													fqDbPred: responseTwo.data.data.value[0],
													fqId: tempFQSorted.length > 0 ? 0 : -1,
													fqError: [false, ""],
												});
											})
											.catch(() => {
												props.setState({
													...props.parentState,
													Preloader: false,
												});
												setState({
													...state,
													fqError: [true, "something went wrong."],
													fqDbPred: [],
													fqId: -1,
													fqDb: [],
													fqDbPrev: [],
												});
											});
									} else {
										props.setState({
											...props.parentState,
											Preloader: false,
										});
										setState({
											...state,
											fqDb: tempFQSorted,
											fqDbPrev: cloneDeep(tempFQSorted),
											fqDbPred: [],
											fqId: tempFQSorted.length > 0 ? 0 : -1,
											fqError: [false, ""],
										});
									}
								} else {
									props.setState({
										...props.parentState,
										Preloader: false,
									});
									setState({
										...state,
										fqError: [true, responseOne.data.message],
										fqDbPred: [],
										fqId: -1,
										fqDb: [],
										fqDbPrev: [],
									});
								}
							} else {
								props.setState({
									...props.parentState,
									Preloader: false,
								});
								setState({ ...state, fqError: [true, "loading error."], fqDbPred: [], fqId: -1, fqDb: [], fqDbPrev: [] });
							}
						})
						.catch(() => {
							props.setState({
								...props.parentState,
								Preloader: false,
							});
							setState({ ...state, fqError: [true, "loading error."], fqDbPred: [], fqId: -1, fqDb: [], fqDbPrev: [] });
						});
				})
				.catch((err) => console.log(err));
		} catch {
			props.setState({
				...props.parentState,
				Preloader: false,
			});
			setState({ ...state, fqError: [true, "something went wrong."], fqDbPred: [], fqId: -1, fqDb: [], fqDbPrev: [] });
		}
	};

	const GetInitialPostureDataAndDraw = () => {
		try {
			props.setState({
				...props.parentState,
				Preloader: true,
			});

			RetrieveToken()
				.then((token) => {
					// Get Initial Posture Data
					const promiseOne = axios(
						{
							method: "GET",
							url: props.dbUrl + "/api/posturemeasurements/v2/caseid",
							headers: {
								Authorization: "Bearer " + token,
								caseId: state.caseId,
							},
						},
						{ timeout: 10000 }
					);

					// Get Initial Journal Data
					const promiseTwo = axios(
						{
							method: "GET",
							url: props.dbUrl + "/api/journals/v1/patientid",
							headers: {
								Authorization: "Bearer " + token,
								patientId: state.patientId,
							},
						},
						{ timeout: 10000 }
					);

					Promise.all([promiseOne, promiseTwo])
						.then((responseArrayValuesOne) => {
							const [responseOne, responseTwo] = responseArrayValuesOne;

							let tempJournalDb = [];
							if (responseTwo.data.success) {
								tempJournalDb = responseTwo.data.data;
							}

							if (responseOne.data.success) {
								let tempPostureDb = { ...responseOne.data.data };
								let keys = Object.keys(tempPostureDb);

								try {
									for (let i = 0; i < keys.length; i++) {
										tempPostureDb[keys[i]].front.imageLoaded = false;
										tempPostureDb[keys[i]].side.imageLoaded = false;
									}
								} catch {}

								try {
									for (let i = 0; i < keys.length; i++) {
										if (typeof tempPostureDb[keys[i]].front.skeleton === "string") {
											if (IsEmpty(tempPostureDb[keys[i]].front.skeleton)) {
												tempPostureDb[keys[i]].front.skeleton = {};
											} else {
												tempPostureDb[keys[i]].front.skeleton = { ...JSON.parse(tempPostureDb[keys[i]].front.skeleton) };
											}
										}
										if (typeof tempPostureDb[keys[i]].side.skeleton === "string") {
											if (IsEmpty(tempPostureDb[keys[i]].side.skeleton)) {
												tempPostureDb[keys[i]].side.skeleton = {};
											} else {
												tempPostureDb[keys[i]].side.skeleton = { ...JSON.parse(tempPostureDb[keys[i]].side.skeleton) };
											}
										}
									}
								} catch {}

								const compareDates = (a, b) => {
									if (new Date(tempPostureDb[a].createdAt).getTime() >= new Date(tempPostureDb[b].createdAt).getTime()) {
										return -1;
									} else {
										return 1;
									}
								};

								keys = keys.sort(compareDates);

								keys = keys.filter((key) => {
									if (state.postureActiveType === "clinic") {
										return tempPostureDb[key].type === state.postureActiveType;
									} else {
										return tempPostureDb[key].type === state.postureActiveType || tempPostureDb[key].type === "";
									}
								});

								let tempPostureIds = keys;
								let tempPostureId = keys.length > 0 ? keys[0] : "";

								if (!IsEmpty(tempPostureId) && tempPostureIds.length > 0) {
									// get front posture images
									const promiseThree = axios(
										{
											method: "get",
											url: props.dbUrl + "/api/storage/v1/image/posture/front/id/base64",
											headers: {
												Authorization: "Bearer " + token,
												postureId: tempPostureId,
											},
										},
										{ timeout: 10000 }
									);
									const promiseFour = axios(
										{
											method: "get",
											url: props.dbUrl + "/api/storage/v1/image/posture/side/id/base64",
											headers: {
												Authorization: "Bearer " + token,
												postureId: tempPostureId,
											},
										},
										{ timeout: 10000 }
									);

									Promise.all([promiseThree, promiseFour])
										.then((responseArrayValues) => {
											const [responseThree, responseFour] = responseArrayValues;

											tempPostureDb[tempPostureId].front.image = responseThree.data;
											tempPostureDb[tempPostureId].front.imageLoaded = true;
											tempPostureDb[tempPostureId].side.image = responseFour.data;
											tempPostureDb[tempPostureId].side.imageLoaded = true;

											props.setState({
												...props.parentState,
												Preloader: false,
											});

											var jKey = FilterJournal(tempPostureId, tempPostureDb, tempJournalDb);

											setState({
												...state,
												postureIds: tempPostureIds,
												postureId: tempPostureId,
												postureDb: { ...tempPostureDb },
												journalDb: tempJournalDb,
												journalKey: jKey,
												journalPain: jKey >= 0 ? tempJournalDb[jKey].painScore : -1,
												journalNotes: jKey >= 0 ? tempJournalDb[jKey].journal : "",
												painScore: !IsEmpty(tempPostureId) ? tempPostureDb[tempPostureId].painScore : 0,
												painType: !IsEmpty(tempPostureId) ? tempPostureDb[tempPostureId].painType : "",
												generalNotes: !IsEmpty(tempPostureId) ? tempPostureDb[tempPostureId].generalNotes : "",
												journalError: tempJournalDb.length > 0 ? [false, ""] : [true, "no journal entry available"],
												postureScanError: tempJournalDb.length > 0 ? [false, ""] : state.postureScanError,
											});
											if (state.postureActiveTab === "view-posture-individual-scan-tab") {
												DrawMainCanvas(tempPostureId, tempPostureDb);
											}
										})
										.catch((e) => {
											props.setState({
												...props.parentState,
												Preloader: false,
											});
											setState({
												...state,
												postureIds: tempPostureIds,
												postureId: tempPostureId,
												postureDb: { ...tempPostureDb },
												painScore: !IsEmpty(tempPostureId) ? tempPostureDb[tempPostureId].painScore : 0,
												painType: !IsEmpty(tempPostureId) ? tempPostureDb[tempPostureId].painType : "",
												generalNotes: !IsEmpty(tempPostureId) ? tempPostureDb[tempPostureId].generalNotes : "",
												postureScanError: [true, "trouble loading images."],
												journalError: tempJournalDb.length > 0 ? [false, ""] : [true, "no journal entry available"],
												journalDb: tempJournalDb,
												journalKey: tempJournalDb.length > 0 ? 0 : -1,
												journalPain: tempJournalDb.length > 0 ? tempJournalDb[0].painScore : -1,
												journalNotes: tempJournalDb.length > 0 ? tempJournalDb[0].journal : "",
												// journalKey: -1,
												// journalPain: -1,
												// journalNotes: "",
											});
											if (state.postureActiveTab === "view-posture-individual-scan-tab") {
												DrawMainCanvas(tempPostureId, tempPostureDb);
											}
										});
								} else {
									props.setState({
										...props.parentState,
										Preloader: false,
									});
									setState({
										...state,
										postureScanError: [true, "no data available."],
										journalError: tempJournalDb.length > 0 ? [false, ""] : [true, "no journal entry available"],
										journalDb: tempJournalDb,
										journalKey: -1,
										postureId: "",
										postureIds: [],
									});
								}
							} else {
								props.setState({
									...props.parentState,
									Preloader: false,
								});
								setState({ ...state, postureScanError: [true, "something went wrong."], postureId: "", postureIds: [] });
							}
						})
						.catch((e) => {
							props.setState({
								...props.parentState,
								Preloader: false,
							});
							setState({
								...state,
								postureScanError: [true, "problem encountered loading data."],
								postureId: "",
								postureIds: [],
							});
						});
				})
				.catch((err) => console.log(err));
		} catch {
			props.setState({
				...props.parentState,
				Preloader: false,
			});
			setState({ ...state, postureScanError: [true, "something went wrong."], postureId: "", postureIds: [] });
		}
	};

	const RefreshAndDrawPostureImages = (postureId, postureDb, updates) => {
		props.setState({
			...props.parentState,
			Preloader: true,
		});

		RetrieveToken()
			.then((token) => {
				// get front posture images
				const promiseOne = axios(
					{
						method: "get",
						url: props.dbUrl + "/api/storage/v1/image/posture/front/id/base64",
						headers: {
							Authorization: "Bearer " + token,
							postureId: postureId,
						},
					},
					{ timeout: 10000 }
				);
				const promiseTwo = axios(
					{
						method: "get",
						url: props.dbUrl + "/api/storage/v1/image/posture/side/id/base64",
						headers: {
							Authorization: "Bearer " + token,
							postureId: postureId,
						},
					},
					{ timeout: 10000 }
				);

				Promise.all([promiseOne, promiseTwo])
					.then((arrayOfValues) => {
						const [responseOne, responseTwo] = arrayOfValues;

						let tempPostureDb = { ...postureDb };
						tempPostureDb[postureId].front.image = responseOne.data;
						tempPostureDb[postureId].front.imageLoaded = true;
						tempPostureDb[postureId].side.image = responseTwo.data;
						tempPostureDb[postureId].side.imageLoaded = true;

						props.setState({
							...props.parentState,
							Preloader: false,
						});

						setState({
							...state,
							postureDb: { ...tempPostureDb },
							...updates,
						});
						if (state.postureActiveTab === "view-posture-individual-scan-tab") {
							DrawMainCanvas(postureId, tempPostureDb);
						}
					})
					.catch((e) => {
						props.setState({
							...props.parentState,
							Preloader: false,
						});
						setState({ ...state, postureScanError: [true, "image data loading error"] });
						if (state.postureActiveTab === "view-posture-individual-scan-tab") {
							DrawMainCanvas(postureId, postureDb);
						}
					});
			})
			.catch((err) => console.log(err));
	};

	const DrawFrontViewSkeleton = (postureId, postureDb, context, config) => {
		try {
			let isFrontSkeletonValid =
				Object.keys(postureDb).length > 0 &&
				!IsEmpty(postureId) &&
				postureId in postureDb &&
				"front" in postureDb[postureId] &&
				"skeleton" in postureDb[postureId].front &&
				Object.keys(postureDb[postureId].front.skeleton).length > 0;
			if (isFrontSkeletonValid) {
				let maxWidth = config.maxWidth;
				let maxHeight = config.maxHeight;
				let pointWidth = maxWidth / 80;

				let canvasEllipse = (context, cx, cy, rx, ry, color) => {
					context.beginPath();

					context.ellipse(cx, cy, rx, ry, 0, 0, Math.PI * 2);
					context.fillStyle = color;
					context.fill();
				};

				let canvasLine = (context, x1, y1, x2, y2, color, w) => {
					context.beginPath();

					context.moveTo(x1, y1);
					context.lineTo(x2, y2);

					context.strokeStyle = color;
					context.lineWidth = w;
					context.stroke();
				};

				if (config.additionalMeasurements) {
					let leftAnkleX = postureDb[postureId].front.skeleton["leftAnkle"][0] * maxWidth;
					let leftShoulderY = postureDb[postureId].front.skeleton["leftShoulder"][1] * maxHeight;
					let rightAnkleX = postureDb[postureId].front.skeleton["rightAnkle"][0] * maxWidth;
					let rightShoulderY = postureDb[postureId].front.skeleton["rightShoulder"][1] * maxHeight;
					let midX = (leftAnkleX + rightAnkleX) / 2;
					let midY = (leftShoulderY + rightShoulderY) / 2;
					canvasLine(context, 0, maxHeight - midY, maxWidth, maxHeight - midY, "#45B349", maxWidth / 250);
					canvasLine(context, midX, 0, midX, maxHeight, "#45B349", maxWidth / 250);

					context.fillStyle = "white";
					context.font = `bold 20px "Rubik", sans-serif`;
					context.textAlign = "center";
					context.fillText(`FRONT POSTURE NUMBER`, maxWidth / 2, 50);

					context.fillStyle = "#2A93D0";
					context.font = `bold 32px "Rubik", sans-serif`;
					context.textAlign = "center";
					context.fillText(`${Math.abs(postureDb[postureId].front.frontPostureNumber.toFixed(2))}`, maxWidth / 2, 100);
				}

				let dotLabels = ["leftEye", "rightEye", "leftShoulder", "rightShoulder", "leftHip", "rightHip", "leftKnee", "rightKnee", "leftAnkle", "rightAnkle"];

				let lineLabels = [
					["leftEye", "rightEye"],
					["leftShoulder", "rightShoulder"],
					["leftHip", "rightHip"],
					["leftKnee", "rightKnee"],
					["leftAnkle", "rightAnkle"],
				];

				if (Object.keys(postureDb[postureId].front.skeleton).length > 0) {
					for (let i = 0; i < dotLabels.length; i++) {
						canvasEllipse(
							context,
							postureDb[postureId].front.skeleton[dotLabels[i]][0] * maxWidth,
							maxHeight - postureDb[postureId].front.skeleton[dotLabels[i]][1] * maxHeight,
							pointWidth,
							pointWidth,
							"#76C344"
						);
					}

					for (let i = 0; i < lineLabels.length; i++) {
						let x1 = postureDb[postureId].front.skeleton[lineLabels[i][0]][0] * maxWidth;
						let y1 = maxHeight - postureDb[postureId].front.skeleton[lineLabels[i][0]][1] * maxHeight;
						let x2 = postureDb[postureId].front.skeleton[lineLabels[i][1]][0] * maxWidth;
						let y2 = maxHeight - postureDb[postureId].front.skeleton[lineLabels[i][1]][1] * maxHeight;
						canvasLine(context, x1, y1, x2, y2, "#3EABF7", maxWidth / 150);

						if (config.additionalMeasurements) {
							let midX = (x1 + x2) / 2;
							let midY = (y1 + y2) / 2;
							context.fillStyle = "white";
							// context.font = "20px bold Rubik";
							context.font = `bold 20px "Rubik", sans-serif`;
							if (lineLabels[i][0] === "leftEye") {
								context.fillText(`${Math.abs(postureDb[postureId].front.frontHeadAngle.toFixed(2))}˚ ${postureDb[postureId].front.frontHeadAngle >= 0 ? "R" : "L"}`, 40, midY);
							} else if (lineLabels[i][0] === "leftShoulder") {
								context.fillText(`${Math.abs(postureDb[postureId].front.frontShoulderAngle.toFixed(2))}˚ ${postureDb[postureId].front.frontShoulderAngle >= 0 ? "R" : "L"}`, 40, midY);
							} else if (lineLabels[i][0] === "leftHip") {
								context.fillText(`${Math.abs(postureDb[postureId].front.frontHipAngle.toFixed(2))}˚ ${postureDb[postureId].front.frontHipAngle >= 0 ? "R" : "L"}`, 40, midY);
							} else if (lineLabels[i][0] === "leftKnee") {
								context.fillText(`${Math.abs(postureDb[postureId].front.frontKneeAngle.toFixed(2))}˚ ${postureDb[postureId].front.frontKneeAngle >= 0 ? "R" : "L"}`, 40, midY);
							} else if (lineLabels[i][0] === "leftAnkle") {
								context.fillText(`${Math.abs(postureDb[postureId].front.frontAnkleAngle.toFixed(2))}˚ ${postureDb[postureId].front.frontAnkleAngle >= 0 ? "R" : "L"}`, 40, midY);
							}
						}
					}
				}
			}
		} catch {}
	};

	const DrawSideViewSkeleton = (postureId, postureDb, context, config) => {
		try {
			let isSideSkeletonValid =
				Object.keys(postureDb).length > 0 &&
				!IsEmpty(postureId) &&
				postureId in postureDb &&
				"side" in postureDb[postureId] &&
				"skeleton" in postureDb[postureId].side &&
				Object.keys(postureDb[postureId].side.skeleton).length > 0;
			if (isSideSkeletonValid) {
				let maxWidth = config.maxWidth;
				let maxHeight = config.maxHeight;
				let pointWidth = maxWidth / 80;

				let canvasEllipse = (context, cx, cy, rx, ry, color) => {
					context.beginPath();
					context.ellipse(cx, cy, rx, ry, 0, 0, Math.PI * 2);
					context.fillStyle = color;
					context.fill();
				};

				let canvasLine = (context, x1, y1, x2, y2, color, w) => {
					context.beginPath();
					context.moveTo(x1, y1);
					context.lineTo(x2, y2);
					context.strokeStyle = color;
					context.lineWidth = w;
					context.stroke();
				};

				if (config.additionalMeasurements) {
					let midX = maxWidth / 2;
					let rightAnkleX = postureDb[postureId].side.skeleton["rightAnkle"][0] * maxWidth;
					canvasLine(context, rightAnkleX, 0, rightAnkleX, maxHeight, "#45B349", maxWidth / 250);

					context.fillStyle = "white";
					// context.font = "20px bold Rubik";
					context.font = `bold 20px "Rubik", sans-serif`;
					context.textAlign = "center";
					context.fillText(`SIDE POSTURE NUMBER`, midX, 50);

					context.fillStyle = "#2A93D0";
					// context.font = "32px bold Rubik";
					context.font = `bold 32px "Rubik", sans-serif`;
					context.textAlign = "center";
					context.fillText(`${Math.abs(postureDb[postureId].side.sidePostureNumber.toFixed(2))}`, midX, 100);
				}

				let dotLabels = ["rightEar", "rightShoulder", "rightHip", "rightKnee", "rightAnkle"];

				let lineLabels = [
					["rightEar", "rightShoulder"],
					["rightShoulder", "rightHip"],
					["rightHip", "rightKnee"],
					["rightKnee", "rightAnkle"],
				];

				if (Object.keys(postureDb[postureId].side.skeleton).length > 0) {
					for (let i = 0; i < dotLabels.length; i++) {
						canvasEllipse(
							context,
							postureDb[postureId].side.skeleton[dotLabels[i]][0] * maxWidth,
							maxHeight - postureDb[postureId].side.skeleton[dotLabels[i]][1] * maxHeight,
							pointWidth,
							pointWidth,
							"#76C344"
						);
					}
				}

				for (let i = 0; i < lineLabels.length; i++) {
					let x1 = postureDb[postureId].side.skeleton[lineLabels[i][0]][0] * maxWidth;
					let y1 = maxHeight - postureDb[postureId].side.skeleton[lineLabels[i][0]][1] * maxHeight;
					let x2 = postureDb[postureId].side.skeleton[lineLabels[i][1]][0] * maxWidth;
					let y2 = maxHeight - postureDb[postureId].side.skeleton[lineLabels[i][1]][1] * maxHeight;
					canvasLine(context, x1, y1, x2, y2, "#3EABF7", maxWidth / 150);

					if (config.additionalMeasurements) {
						let midX = (x1 + x2) / 2;
						let midY = (y1 + y2) / 2;
						context.fillStyle = "white";
						// context.font = "20px bold Rubik";
						context.font = `bold 20px "Rubik", sans-serif`;
						if (lineLabels[i][0] === "rightEar") {
							context.fillText(`${Math.abs(postureDb[postureId].side.sideHeadDeviation.toFixed(2))}˚ ${postureDb[postureId].side.sideHeadDeviation >= 0 ? "F" : "B"}`, 40, y1);
						} else if (lineLabels[i][0] === "rightShoulder") {
							context.fillText(`${Math.abs(postureDb[postureId].side.sideShoulderDeviation.toFixed(2))}˚ ${postureDb[postureId].side.sideShoulderDeviation >= 0 ? "F" : "B"}`, 40, y1);
						} else if (lineLabels[i][0] === "rightHip") {
							context.fillText(`${Math.abs(postureDb[postureId].side.sideHipDeviation.toFixed(2))}˚ ${postureDb[postureId].side.sideHipDeviation >= 0 ? "F" : "B"}`, 40, y1);
						} else if (lineLabels[i][0] === "rightKnee") {
							context.fillText(`${Math.abs(postureDb[postureId].side.sideKneeDeviation.toFixed(2))}˚ ${postureDb[postureId].side.sideKneeDeviation >= 0 ? "F" : "B"}`, 40, y1);
						}
					}
				}
			}
		} catch (err) {}
	};

	const DrawMainCanvas = (postureId, postureDb) => {
		if (!!frontPostureMainCanvasRef.current) {
			let currentCanvasRef = frontPostureMainCanvasRef.current;
			currentCanvasRef.style.cursor = "pointer";
			let context = currentCanvasRef.getContext("2d");
			let image = new Image();
			image.src = !!postureId && Object.keys(postureDb).length > 0 && postureId in postureDb && postureDb[postureId].front.imageLoaded ? postureDb[postureId].front.image : "";
			image.onload = () => {
				let imgWidth = image.width;
				let imgHeight = image.height;
				let aspectRatio = imgWidth / imgHeight;
				let reducedHeight = 400;
				let reducedWidth = reducedHeight * aspectRatio;
				context.canvas.width = reducedWidth;
				context.canvas.height = reducedHeight;

				let elements = document.getElementsByClassName("posture-canvas-overlay");

				for (let i = 0; i < elements.length; i++) {
					elements[i].style.width = `${reducedWidth}px`;
					elements[i].style.height = `${reducedHeight}px`;
				}

				context.drawImage(image, 0, 0, reducedWidth, reducedHeight);
				DrawFrontViewSkeleton(postureId, postureDb, context, {
					maxWidth: reducedWidth,
					maxHeight: reducedHeight,
					additionalMeasurements: false,
				});
			};
		}
		if (!!sidePostureMainCanvasRef.current) {
			let currentCanvasRef = sidePostureMainCanvasRef.current;
			currentCanvasRef.style.cursor = "pointer";
			let context = currentCanvasRef.getContext("2d");
			let image = new Image();
			image.src = !!postureId && Object.keys(postureDb).length > 0 && postureId in postureDb && postureDb[postureId].side.imageLoaded ? postureDb[postureId].side.image : "";
			image.onload = () => {
				let imgWidth = image.width;
				let imgHeight = image.height;
				let aspectRatio = imgWidth / imgHeight;
				let reducedHeight = 400;
				let reducedWidth = reducedHeight * aspectRatio;
				context.canvas.width = reducedWidth;
				context.canvas.height = reducedHeight;

				let elements = document.getElementsByClassName("posture-canvas-overlay");

				for (let i = 0; i < elements.length; i++) {
					elements[i].style.width = `${reducedWidth}px`;
					elements[i].style.height = `${reducedHeight}px`;
				}

				context.drawImage(image, 0, 0, reducedWidth, reducedHeight);
				DrawSideViewSkeleton(postureId, postureDb, context, {
					maxWidth: reducedWidth,
					maxHeight: reducedHeight,
					additionalMeasurements: false,
				});
			};
		}
	};

	const DrawZoomedCanvas = (postureId, postureDb) => {
		if (!!postureZoomedCanvasRef.current) {
			let currentCanvasRef = postureZoomedCanvasRef.current;
			currentCanvasRef.style.cursor = "pointer";
			let context = currentCanvasRef.getContext("2d");
			let image = new Image();
			if (state.zoomedViewDirection === "front") {
				image.src = !!postureId && Object.keys(postureDb).length > 0 && postureId in postureDb && postureDb[postureId].front.imageLoaded ? postureDb[postureId].front.image : "";
			} else {
				image.src = !!postureId && Object.keys(postureDb).length > 0 && postureId in postureDb && postureDb[postureId].side.imageLoaded ? postureDb[postureId].side.image : "";
			}
			image.onload = () => {
				let imgWidth = image.width;
				let imgHeight = image.height;
				let aspectRatio = imgWidth / imgHeight;
				let reducedHeight = 1024;
				let reducedWidth = reducedHeight * aspectRatio;
				context.canvas.width = reducedWidth;
				context.canvas.height = reducedHeight;

				let elements = document.getElementsByClassName("posture-canvas-overlay");

				for (let i = 0; i < elements.length; i++) {
					elements[i].style.width = `${reducedWidth}px`;
					elements[i].style.height = `${reducedHeight}px`;
				}

				context.drawImage(image, 0, 0, reducedWidth, reducedHeight);
				if (state.zoomedViewDirection === "front") {
					DrawFrontViewSkeleton(postureId, postureDb, context, {
						maxWidth: reducedWidth,
						maxHeight: reducedHeight,
						additionalMeasurements: true,
					});
				} else {
					DrawSideViewSkeleton(postureId, postureDb, context, {
						maxWidth: reducedWidth,
						maxHeight: reducedHeight,
						additionalMeasurements: true,
					});
				}
			};
		}
	};

	const SortPatientListKeys = (obj, keys) => {
		const CompareDates = (a, b) => {
			if ("summary" in obj[a] && "summary" in obj[b] && "dateCreated" in obj[a].summary && "dateCreated" in obj[b].summary) {
				if (new Date(obj[a].summary.dateCreated).getTime() >= new Date(obj[b].summary.dateCreated).getTime()) {
					return -1;
				} else {
					return 1;
				}
			}
			return -1;
		};
		return keys.sort(CompareDates);
	};

	const FilterPatientListByType = (obj, keys, type) => {
		let filteredKeys = [];
		for (let i = 0; i < keys.length; i++) {
			if (type === "active") {
				if (!obj[keys[i]].summary.isArchived && !obj[keys[i]].summary.isAnonymized) {
					filteredKeys.push(keys[i]);
				}
			} else if (type === "archived") {
				if (obj[keys[i]].summary.isArchived) {
					filteredKeys.push(keys[i]);
				}
			} else if (type === "anonymized") {
				if (obj[keys[i]].summary.isAnonymized) {
					filteredKeys.push(keys[i]);
				}
			}
		}

		return filteredKeys;
	};

	const SortCaseListKeys = (obj, keys) => {
		const CompareDates = (a, b) => {
			if ("dateCreated" in obj[a] && "dateCreated" in obj[b]) {
				if (new Date(obj[a].dateCreated).getTime() >= new Date(obj[b].dateCreated).getTime()) {
					return -1;
				} else {
					return 1;
				}
			}
			return -1;
		};
		return keys.sort(CompareDates);
	};

	const RenderTrendImg = (x, y) => {
		if (x === 0 || y === 0 || x === y) {
			return <Fragment />;
		} else {
			if (x > y) {
				return <img src={trendDown} style={{ maxWidth: "30px", marginRight: "5px" }} alt="trend down" />;
			} else {
				return <img src={trendUp} style={{ maxWidth: "30px", marginRight: "5px" }} alt="trend up" />;
			}
		}
	};

	const RenderTrendValue = (x, y) => {
		if (x === 0 && y === 0) {
			return "0";
		} else if (((x === 0 && y > 0) || (x > 0 && y === 0)) && x != y) {
			return `+${y}`;
		} else {
			if (x === y) {
				return `0%` + y > 0 ? `(+${y})` : "";
			} else {
				return `${((y / x) * 100).toFixed(2)}% (+${y})`;
			}
		}
	};

	const RenderDashboardOverviewSection = () => {
		return (
			<Fragment>
				<div className="ui-section-wrapper">
					<section className="form-section-wrapper" style={{ marginBottom: "0.5em" }}>
						<Row>
							<Col>
								<div className="dashboard-main-analytics-card">
									<Row style={{ alignItems: "flex-start" }}>
										<Col style={{ textAlign: "left" }}>
											<div style={{ color: "var(--logo-color-1)", fontSize: "250%", marginBottom: "20px" }}>{FormatNumberWithCommas(state.analytics.Patients.Total)}</div>
											<div>total patients</div>
										</Col>
										<Col style={{ textAlign: "right" }}>
											<div style={{ color: "var(--logo-color-2)", fontSize: "70%", marginBottom: "0px" }}>
												{RenderTrendImg(state.analytics.Patients.PastPastMonth, state.analytics.Patients.PastMonth)}
												{/* <img src={trendUp} style={{maxWidth: "30px", marginRight: "5px"}} /> */}
												{RenderTrendValue(state.analytics.Patients.PastPastMonth, state.analytics.Patients.PastMonth)}
											</div>
											<div style={{ fontSize: "80%" }}>last 30 days</div>
										</Col>
									</Row>
								</div>
							</Col>
							<Col>
								<div className="dashboard-main-analytics-card">
									<Row style={{ alignItems: "flex-start" }}>
										<Col style={{ textAlign: "left" }}>
											<div style={{ color: "var(--logo-color-1)", fontSize: "250%", marginBottom: "20px" }}>{FormatNumberWithCommas(state.analytics.Postures.Total)}</div>
											<div>total assessments</div>
										</Col>
										<Col style={{ textAlign: "right" }}>
											<div style={{ color: "var(--logo-color-2)", fontSize: "70%", marginBottom: "0px" }}>
												{RenderTrendImg(state.analytics.Postures.PastPastMonth, state.analytics.Postures.PastMonth)}
												{/* <img src={trendDown} style={{maxWidth: "30px", marginRight: "5px"}} /> */}
												{RenderTrendValue(state.analytics.Postures.PastPastMonth, state.analytics.Postures.PastMonth)}
											</div>
											<div style={{ fontSize: "80%" }}>last 30 days</div>
										</Col>
									</Row>
								</div>
							</Col>
							<Col></Col>
						</Row>
					</section>

					<section className="form-section-wrapper" style={{ marginBottom: "0.5em" }} ref={dashboardPaginationRef}>
						<Row>
							<Col>
								<h1>Active</h1>
							</Col>
						</Row>

						<Row>
							<Col xs={12} md={4}>
								<Form.Group style={{ color: "#6c7d85" }}>
									<Form.Control
										size="md"
										type="input"
										onKeyUp={(e) => {
											clearTimeout(searchTimer);
											searchTimer = setTimeout(() => {
												// console.log(state.searchedPatientName);
												ReloadDashboardOverviewSection(state.activePageNumber, state.singlePageTotal, state.searchedPatientName);
												clearTimeout(searchTimer);
											}, doneTypingInterval);
										}}
										onKeyDown={(_) => clearTimeout(searchTimer)}
										onChange={(e) => {
											setState({ ...state, searchedPatientName: e.target.value });
										}}
										placeholder="Search Patients"
										required
									/>
								</Form.Group>
							</Col>
							<Col xs={12} md={4}>
								<Form.Group>
									<Button
										style={{ background: "#45b249" }}
										onClick={(e) => {
											setState({ ...state, view: "view-add-patient-form" });
										}}
									>
										Add Patient +
									</Button>
								</Form.Group>
							</Col>
						</Row>
					</section>

					{Object.keys(state.filteredPatientList).length > 0 ? (
						<Fragment>
							<section className="form-section-wrapper">
								<Row>
									<Col>
										<div className="table-responsive">
											<table key="patient-list-table" className="align-middle mb-0 table table-borderless">
												<thead>
													<tr className="text-center" style={{ height: "60px" }}>
														<th>Name</th>
														<th>Age</th>
														<th>Gender</th>
														<th>Enrolled</th>
														<th>Actions</th>
													</tr>
													<tr className="hline-grey">
														<td colSpan={"100%"}></td>
													</tr>
												</thead>
												<tbody>
													{Object.keys(state.filteredPatientList).length <= 0 ? (
														<Fragment>
															<tr style={{ textAlign: "center" }}>
																<td className="empty-record" colSpan={"100%"}>
																	{/* record not found */}
																	<Alert variant="warning" show={state.error[0] || props.Preloader ? true : false}>
																		<div className="flex-row">
																			<p>{state.error[1]}</p>
																		</div>
																	</Alert>
																</td>
															</tr>
														</Fragment>
													) : (
														<Fragment></Fragment>
													)}

													{Object.keys(state.filteredPatientList)
													.filter(k => !state.filteredPatientList[k].summary.isArchived)
													.filter(k => !state.filteredPatientList[k].summary.isAnonymized)
													.map((k1, ind1) => {
														
														return (
															// <Fragment></Fragment>
															<Fragment key={GenerateComponentKey(k1, ind1)}>
																<tr style={{ textAlign: "center" }}>
																	<td
																		style={{ color: "var(--logo-color-1)", cursor: "pointer" }}
																		onClick={(e) => {
																			if (state.caseId === k1) {
																				setState({ ...state, patientId: "", caseId: "" });
																			} else {
																				setState({
																					...state,
																					patientId: state.filteredPatientList[k1].summary.patientId,
																					caseId: k1,
																				});
																			}
																		}}
																	>
																		{state.filteredPatientList[k1].summary.name}
																	</td>
																	<td>{state.filteredPatientList[k1].summary.age > 0 ? state.filteredPatientList[k1].summary.age : "-"}</td>
																	<td>{state.filteredPatientList[k1].summary.gender}</td>
																	<td>{!!state.filteredPatientList[k1]?.caseList[k1]?.dateCreated ? new Date(state.filteredPatientList[k1].caseList[k1].dateCreated).toLocaleString(dateLocale, shortDateFormat) : "-"}</td>
																	<td className="td-action">
																		<OverlayTrigger key={`${k1}-bottom`} placement={"bottom"} overlay={<Tooltip id={`${k1}-tooltip-profile`}>Profile</Tooltip>}>
																			<Button style={{ background: "transparent", padding: "0px", margin: "0px" }}>
																				<FontAwesomeIcon
																					icon={faUserAlt}
																					size="sm"
																					color={"var(--logo-color-1)"}
																					onClick={() => {
																						setState({
																							...state,
																							view: "view-patient-information-selected",
																							selectedPatientKey: state.filteredPatientList[k1].summary.patientId,
																						});
																					}}
																				/>
																			</Button>
																		</OverlayTrigger>

																		

																		<OverlayTrigger
																			key={`${k1}-hover-bottom-archive`}
																			trigger="click"
																			placement="bottom"
																			overlay={RenderArchiveToOverlay}
																			show={state.isPatientToArchiveConfirmationVisible}
																		>
																			{/* <span ref={isPatientToArchiveConfirmationRef}> */}
																				<Button style={{ background: "transparent", padding: "0px", margin: "0px" }}>
																					<FontAwesomeIcon
																						icon={faFileArchive}
																						size="sm"
																						color={"var(--logo-color-1)"}
																						onClick={(e) => {
																							
																							setState({
																								...state,
																								isPatientToArchiveConfirmationVisible: true,
																								isPatientToArchiveConfirmationTarget: e.target,
																								selectedPatientId: state.filteredPatientList[k1].summary.patientId,
																								selectedCaseId: k1,
																							});
																						}}
																					/>
																				</Button>

																				
																			{/* </span> */}
																			
																		</OverlayTrigger>

																		{activeAccessLevel <= 1 && (
																			<OverlayTrigger
																				key={`${k1}-hover-bottom-remove`}
																				trigger={["hover", "focus"]}
																				placement="bottom"
																				overlay={RenderPatientRemoveOverlay}
																				show={state.isPatientRemoveConfirmationVisible}
																			>
																				<span ref={isPatientRemoveConfirmationRef}>
																					<Button style={{ background: "transparent", padding: "0px", margin: "0px" }}>
																						<FontAwesomeIcon
																							icon={faTrash}
																							size="sm"
																							color={"var(--logo-color-1)"}
																							onClick={(e) => {
																								setState({
																									...state,
																									isPatientRemoveConfirmationVisible: true,
																									isPatientRemoveConfirmationTarget: e.target,
																									selectedPatientKey: state.filteredPatientList[k1].summary.patientId,
																									selectedPatientId: state.filteredPatientList[k1].summary.patientId,
																									selectedCaseId: k1,
																								});
																							}}
																						/>
																					</Button>
																				</span>
																			</OverlayTrigger>
																		)}

																		

																		

																		
																	</td>
																</tr>

																{!!state.caseId && state.caseId === k1 && (
																	<tr>
																		<td colSpan={"100%"}>
																			<Row>
																				<Col xs={1}></Col>
																				<Col xs={11}>
																					{
																						<div className="case-container">
																							{SortCaseListKeys(state.filteredPatientList[k1].caseList, Object.keys(state.filteredPatientList[k1].caseList)).map((k2, ind2) => {
																								return (
																									<Fragment key={GenerateComponentKey(k2, ind2)}>
																										<Row>
																											<Col className="case-header">
																												<Row>
																													<Col xs={8}>
																														<span
																															onClick={(e) => {
																																if (k2 === state.caseId) {
																																	// setCaseKey("");
																																	setState({ ...state, caseId: "" });
																																} else {
																																	// setCaseKey(k2);
																																	setState({ ...state, caseId: k2 });
																																}
																															}}
																														>
																															Case #{ind2 + 1}, {new Date(state.filteredPatientList[k1].caseList[k2].dateCreated).toLocaleString(dateLocale, defaultDateFormat)}
																														</span>
																													</Col>
																													<Col xs={4} style={{ textAlign: "right" }}>
																														<span>
																															Status: {state.filteredPatientList[k1].caseList[k2].overallStatus === 0 ? "Cancel" : ""}
																															{state.filteredPatientList[k1].caseList[k2].overallStatus === 1 ? "Discharge" : ""}
																															{state.filteredPatientList[k1].caseList[k2].overallStatus === 2 ? "In Progress" : ""}
																															{state.filteredPatientList[k1].caseList[k2].overallStatus === 3 ? "Maintenance" : ""}
																															{state.filteredPatientList[k1].caseList[k2].overallStatus === 4 ? "Pause" : ""}
																														</span>

																														
																														<OverlayTrigger show={state.isPatientStatusConfirmationVisible} trigger={"click"}  placement="left" overlay={RenderCaseStatusUpdateOverlay}>
																														
																															<Button
																																id={`button-${k1}`}
																																variant="link"
																																onClick={(e) => {
																																	
																																	setState({
																																		...state,
																																		isPatientStatusConfirmationVisible: !state.isPatientStatusConfirmationVisible,
																																		// isPatientStatusConfirmationTarget: e.target,
																																		selectedPatientCasePair: {
																																			patientId: k1,
																																			caseId: k2,
																																		},
																																	});
																																}}
																																className="edit-button"
																															>
																																
																																
																																	<FontAwesomeIcon icon={faEdit} width={18} />
																																
																																
																																
																															</Button>
																														</OverlayTrigger>
																														
																													</Col>
																												</Row>
																											</Col>
																										</Row>
																										{!IsEmpty(state.caseId) && state.caseId === k2 && (
																											<Row>
																												{/* Intake */}
																												<Col
																													xs={4}
																													md={3}
																													className="case-section"
																													onClick={() => {
																														if (HasIntakeAccess) {
																															setState({
																																...state,
																																view: "view-patient-pro-intake-main",
																																patientId: state.filteredPatientList[k1].summary.patientId,
																															});
																														}
																													}}
																													style={HasIntakeAccess ? { cursor: "pointer" } : { cursor: "not-allowed" }}
																												>
																													<div id="lock" style={{ textAlign: "right" }}>
																														{!HasIntakeAccess && <FontAwesomeIcon icon={faLock} color={"#9FAAB0"} style={{ marginRight: "5px" }} />}
																													</div>

																													<div id="title">Intake</div>
																													<div id="date">{state.filteredPatientList[k1].caseList[k2].intake.lastSubmitted}</div>
																												</Col>

																												{/* Force Plate */}
																												<Col
																													xs={4}
																													md={3}
																													className="case-section"
																													onClick={() => {
																														if (HasForcePlateAccess) {
																															setState({
																																...state,
																																view: "view-patient-forceplate-main",
																																patientId: state.filteredPatientList[k1].summary.patientId,
																															});
																														}
																													}}
																													style={HasForcePlateAccess ? { cursor: "pointer" } : { cursor: "not-allowed" }}
																												>
																													<div id="lock" style={{ textAlign: "right" }}>
																														{!HasForcePlateAccess && <FontAwesomeIcon icon={faLock} color={"#9FAAB0"} style={{ marginRight: "5px" }} />}
																													</div>
																													<div id="num">{state.filteredPatientList[k1].caseList[k2].fp.numOfAssessments}</div>
																													<div id="title">Force Plate</div>
																													<div id="date">{state.filteredPatientList[k1].caseList[k2].fp.lastSubmitted}</div>
																												</Col>

																												{/* Posture */}
																												<Col
																													xs={4}
																													md={3}
																													className="case-section"
																													onClick={() => {
																														if (HasPostureAccess) {
																															setState({
																																...state,
																																view: "view-patient-posture-main",
																																postureActiveTab: "view-posture-individual-scan-tab",
																																patientId: state.filteredPatientList[k1].summary.patientId,
																																caseId: k1,
																															});
																														}
																													}}
																													style={HasPostureAccess ? { cursor: "pointer" } : { cursor: "not-allowed" }}
																												>
																													<div id="lock" style={{ textAlign: "right" }}>
																														{!HasPostureAccess && <FontAwesomeIcon icon={faLock} color={"#9FAAB0"} style={{ marginRight: "5px" }} />}
																													</div>
																													<div id="num">{state.filteredPatientList[k1].caseList[k2].posture.numOfAssessments}</div>
																													<div id="title">Posture</div>
																													<div id="date">{state.filteredPatientList[k1].caseList[k2].posture.lastSubmitted}</div>
																												</Col>

																												{/* Functional Questionnaire */}
																												<Col
																													xs={4}
																													md={3}
																													className="case-section"
																													onClick={() => {
																														if (HasFQAccess) {
																															setState({
																																...state,
																																view: "view-patient-fq-main",
																																fqActiveTab: "view-fq-individual-qa-tab",
																																patientId: state.filteredPatientList[k1].summary.patientId,
																															});
																														}
																													}}
																													style={HasFQAccess ? { cursor: "pointer" } : { cursor: "not-allowed" }}
																												>
																													<div id="lock" style={{ textAlign: "right" }}>
																														{!HasFQAccess && <FontAwesomeIcon icon={faLock} color={"#9FAAB0"} style={{ marginRight: "5px" }} />}
																													</div>

																													<div id="num">{state.filteredPatientList[k1].caseList[k2].fq.numOfAssessments}</div>
																													<div id="title">Functional Questionnaire</div>
																													<div id="date">{state.filteredPatientList[k1].caseList[k2].fq.lastSubmitted}</div>
																												</Col>

																												{(props.parentState.ActiveOrganization.id == "a0b4f0" ||
																													props.parentState.ActiveOrganization.id == "6ce053" ||
																													props.parentState.ActiveOrganization.id == "f3bb93" ||
																													props.parentState.ActiveOrganization.id == "3febc1" ||
																													props.parentState.ActiveOrganization.id == "d1c657" ||
																													props.parentState.ActiveOrganization.id == "2a2300") && (
																													<Fragment>
																														{/* Functional Questionnaire */}
																														<Col
																															xs={4}
																															md={3}
																															className="case-section"
																															onClick={() => {
																																if (HasHSAccess) {
																																	setState({
																																		...state,
																																		view: "view-patient-hs-main",
																																		patientId: state.filteredPatientList[k1].summary.patientId,
																																	});
																																}
																															}}
																															style={HasHSAccess ? { cursor: "pointer" } : { cursor: "not-allowed" }}
																														>
																															<div id="lock" style={{ textAlign: "right" }}>
																																{!HasHSAccess && <FontAwesomeIcon icon={faLock} color={"#9FAAB0"} style={{ marginRight: "5px" }} />}
																															</div>

																															<div id="num">{state.filteredPatientList[k1].caseList[k2].hsu.numOfAssessments}</div>
																															<div id="title">Health Status Update</div>
																															<div id="date">{state.filteredPatientList[k1].caseList[k2].hsu.lastSubmitted}</div>
																														</Col>
																													</Fragment>
																												)}
																											</Row>
																										)}
																									</Fragment>
																								);
																							})}
																						</div>
																					}
																				</Col>
																			</Row>
																		</td>
																	</tr>
																)}

																{ind1 < state.singlePageTotal - 1 ? (
																	<tr className="hline-white">
																		<td colSpan={"100%"}></td>
																	</tr>
																) : null}
															</Fragment>
														);
													})}
												</tbody>
												<tfoot>
													<tr className="dashboard-pagination" style={{ textAlign: "center" }}>
														<td colSpan={"100%"}>
															{state.totalPages > 1 && state.activePageNumber > 1 && (
																<Button
																	className="left"
																	variant="link"
																	onClick={() => {
																		dashboardPaginationRef.current.scrollIntoView({ behavior: "smooth" });
																		// setState({ ...state, activePageNumber: state.activePageNumber - 1 });

																		ReloadDashboardOverviewSection(state.activePageNumber - 1, state.singlePageTotal, state.searchedPatientName);
																	}}
																>
																	{"<"}
																</Button>
															)}

															{state.activePageNumber - (state.activePageNumber % 5) + 5 > 5 && state.totalPages > 5 && (
																<Button
																	className="left"
																	variant="link"
																	onClick={() => {
																		dashboardPaginationRef.current.scrollIntoView({ behavior: "smooth" });
																		// setState({ ...state, activePageNumber: 1 });
																		ReloadDashboardOverviewSection(1, state.singlePageTotal, state.searchedPatientName);
																	}}
																>
																	1
																</Button>
															)}

															{state.activePageNumber - (state.activePageNumber % 5) + 5 > 5 && state.totalPages > 5 && <span>...</span>}

															{new Array(5).fill(0).map((val, ind) => {
																return (
																	<Fragment key={GenerateComponentKey(val, ind)}>
																		{state.activePageNumber - (state.activePageNumber % 5) + ind >= 1 && state.activePageNumber - (state.activePageNumber % 5) + ind <= state.totalPages && (
																			<Button
																				key={`pagination-${ind}`}
																				variant="link"
																				active={state.activePageNumber === state.activePageNumber - (state.activePageNumber % 5) + ind ? true : false}
																				onClick={() => {
																					dashboardPaginationRef.current.scrollIntoView({ behavior: "smooth" });
																					// setState({ ...state, activePageNumber: state.activePageNumber - (state.activePageNumber % 5) + ind });
																					ReloadDashboardOverviewSection(state.activePageNumber - (state.activePageNumber % 5) + ind, state.singlePageTotal, state.searchedPatientName);
																				}}
																			>
																				{state.activePageNumber - (state.activePageNumber % 5) + ind}
																			</Button>
																		)}
																	</Fragment>
																);
															})}

															{state.activePageNumber - (state.activePageNumber % 5) + 5 < state.totalPages && <span>...</span>}

															{state.activePageNumber - (state.activePageNumber % 5) + 5 < state.totalPages && (
																<Button
																	className="right"
																	variant="link"
																	onClick={() => {
																		dashboardPaginationRef.current.scrollIntoView({ behavior: "smooth" });
																		// setState({ ...state, activePageNumber: state.totalPages });
																		ReloadDashboardOverviewSection(state.totalPages, state.singlePageTotal, state.searchedPatientName);
																	}}
																>
																	{state.totalPages}
																</Button>
															)}

															{state.totalPages > 1 && state.activePageNumber < state.totalPages && (
																<Button
																	className="right"
																	variant="link"
																	onClick={() => {
																		dashboardPaginationRef.current.scrollIntoView({ behavior: "smooth" });
																		// setState({ ...state, activePageNumber: state.activePageNumber + 1 });
																		ReloadDashboardOverviewSection(state.activePageNumber + 1, state.singlePageTotal, state.searchedPatientName);
																	}}
																>
																	{">"}
																</Button>
															)}
														</td>
													</tr>
													<tr className="dashboard-pagination" style={{ textAlign: "center" }}>
														<td colSpan={"100%"}>
															<Form.Control
																size="sm"
																as="select"
																custom
																className="ui-select small-int-select"
																value={state.singlePageTotal}
																onChange={(e) => {
																	var value = e.currentTarget.value;
																	ReloadDashboardOverviewSection(state.activePageNumber, value, state.searchedPatientName);
																}}
															>
																<option value={20} selected={state.singlePageTotal == 20}>
																	20
																</option>
																<option value={30} selected={state.singlePageTotal == 30}>
																	30
																</option>
																<option value={40} selected={state.singlePageTotal == 40}>
																	40
																</option>
																<option value={50} selected={state.singlePageTotal == 50}>
																	50
																</option>
																<option value={100} selected={state.singlePageTotal == 100}>
																	100
																</option>
															</Form.Control>

															{
																<span>
																	Results: {(state.activePageNumber - 1) * state.singlePageTotal + 1} - {state.activePageNumber * state.singlePageTotal} of {state.analytics.Patients.Total}
																</span>
															}
														</td>
													</tr>
												</tfoot>
											</table>
										</div>
									</Col>
								</Row>
							</section>
						</Fragment>
					) : (
						<Fragment></Fragment>
					)}

					{FilterPatientListByType(state.patientList, Object.keys(state.patientList), "archived").length > 0 ? (
						<Fragment>
							<section className="form-section-wrapper" style={{ marginBottom: "0.5em" }}>
								<Row>
									<Col>
										<h1>Archive</h1>
									</Col>
								</Row>
							</section>

							<section className="form-section-wrapper">
								<Row>
									<Col>
										<div className="table-responsive">
											<table key="patient-list-table" className="align-middle mb-0 table table-borderless">
												<thead>
													<tr className="text-center" style={{ height: "60px" }}>
														<th>Name</th>
														<th>Date Created</th>
														<th>Actions</th>
													</tr>
													<tr className="hline-grey">
														<td colSpan={"100%"}></td>
													</tr>
												</thead>

												<tbody>
													{SortPatientListKeys(state.patientList, FilterPatientListByType(state.patientList, Object.keys(state.patientList), "archived")).map((k1, ind1) => {
														return (
															<Fragment key={k1}>
																<tr style={{ textAlign: "center" }}>
																	<td>{state.patientList[k1].summary.name}</td>
																	<td>{new Date(state.patientList[k1].summary.dateCreated).toLocaleString(dateLocale, shortDateFormat)}</td>
																	<td className="td-action">
																		{/* <OverlayTrigger key={`${k1}-bottom`} placement={"bottom"} overlay={<Tooltip id={`${k1}-tooltip-archive`}>Archive</Tooltip>}> */}
																		<span ref={isPatientFromArchiveConfirmationRef}>
																			<Button style={{ background: "transparent", padding: "0px", margin: "0px" }}>
																				<FontAwesomeIcon
																					icon={faFileArchive}
																					size="sm"
																					color={"var(--logo-color-1)"}
																					onClick={(e) => {
																						setState({
																							...state,
																							isPatientFromArchiveConfirmationVisible: true,
																							isPatientFromArchiveConfirmationTarget: e.target,
																							selectedPatientId: state.patientList[k1].summary.patientId,
																							selectedCaseId: k1,
																						});
																					}}
																				/>
																			</Button>
																		</span>
																		{/* </OverlayTrigger> */}

																		<Overlay
																			rootClose
																			show={state.isPatientFromArchiveConfirmationVisible}
																			target={state.isPatientFromArchiveConfirmationTarget}
																			placement="bottom"
																			container={isPatientFromArchiveConfirmationRef.current}
																			onHide={(e) => setState({ ...state, isPatientFromArchiveConfirmationVisible: false })}
																			containerPadding={20}
																		>
																			<Popover className="posture-from-archive-wrapper" style={{ backgroundColor: "#707070" }} id={`popover${Math.floor(Math.random()).toString()}`}>
																				<Popover.Content>
																					<Row>
																						<Col>
																							<FontAwesomeIcon icon={faExclamation} color={"#ff8000"} style={{ marginRight: "5px" }} />
																							The patient named {state.filteredPatientList[state.selectedCaseId]?.summary?.name} will be moved to the active directory. Do you confirm?
																						</Col>
																					</Row>
																					<Row>
																						<Col md={6} xs={12} style={{ textAlign: "center", margin: "0px", padding: "0px" }}>
																							<Button
																								style={{ background: "var(--primary-green)", marginRight: "5px" }}
																								onClick={(e) => {
																									// PutSelectedPatientData({...state.selectedPatientData});
																									PutSelectedPatientOutOfArchive(state.selectedCaseId, state.selectedPatientId);
																								}}
																							>
																								Confirm
																							</Button>
																						</Col>
																						<Col md={6} xs={12} style={{ textAlign: "center", margin: "0px", padding: "0px" }}>
																							<Button style={{ background: "orangered" }} onClick={(e) => setState({ ...state, isPatientFromArchiveConfirmationVisible: false })}>
																								Cancel
																							</Button>
																						</Col>
																					</Row>
																				</Popover.Content>
																			</Popover>
																		</Overlay>
																	</td>
																</tr>
															</Fragment>
														);
													})}
												</tbody>
											</table>
										</div>
									</Col>
								</Row>
							</section>
						</Fragment>
					) : (
						<Fragment></Fragment>
					)}
				</div>
			</Fragment>
		);
	};

	const RenderAddPatientForm = () => {
		return (
			<div className="ui-section-wrapper">
				<div
					style={{ marginBottom: "5vh", cursor: "pointer", fontWeight: "500", fontSize: "1.2rem" }}
					onClick={(e) => {
						setState({
							...state,
							view: "view-dashboard-overview-section",
							newPatientInfo: JSON.parse(JSON.stringify(defaultPatientData)),
							error: [false, ""],
							isNewPatientInfoInValid: { ...defaultIsNewPatientDataInValid },
							isNewPatientInfoValidated: { ...defaultIsNewPatientDataValidated },
							isAddPatientConfirmationVisible: false,
						});
					}}
				>
					{"<"} back
				</div>

				<section>
					<Row>
						<Col>
							<h1>Add Patient</h1>
						</Col>
					</Row>
				</section>

				<section className="form-container" style={{ maxWidth: "800px" }}>
					{/* First & Last Name */}
					<Row>
						<Form.Group as={Col} md={6} sm={12} controlId="add-patient-form-firstname">
							<Form.Label>First Name</Form.Label>
							<Form.Control
								size="sm"
								type="input"
								onChange={(e) => {
									if (!!e.target.value) {
										let tempPatientData = { ...state.newPatientInfo };
										tempPatientData.Personal.FirstName = e.target.value;
										setState({
											...state,
											newPatientInfo: { ...tempPatientData },
											isNewPatientInfoInValid: { ...state.isNewPatientInfoInValid, firstName: [false, ""] },
											isNewPatientInfoValidated: { ...state.isNewPatientInfoValidated, firstName: true },
										});
									} else {
										setState({
											...state,
											isNewPatientInfoInValid: {
												...state.isNewPatientInfoInValid,
												firstName: [true, "first name is required"],
											},
											isNewPatientInfoValidated: { ...state.isNewPatientInfoValidated, firstName: true },
										});
									}
								}}
								placeholder="First Name"
								required
								isInvalid={state.isNewPatientInfoInValid.firstName[0] && state.isNewPatientInfoValidated.firstName}
							/>
							<Form.Control.Feedback type="invalid">{state.isNewPatientInfoInValid.firstName[1]}</Form.Control.Feedback>
						</Form.Group>
						<Form.Group as={Col} md={6} sm={12} controlId="add-patient-form-lastname">
							<Form.Label>Last Name</Form.Label>
							<Form.Control
								size="sm"
								type="input"
								onChange={(e) => {
									// setIsNewPatientInfoValidated({...state.isNewPatientInfoValidated, lastName: true});
									if (!!e.target.value) {
										let tempPatientData = { ...state.newPatientInfo };
										tempPatientData.Personal.LastName = e.target.value;
										setState({
											...state,
											newPatientInfo: { ...tempPatientData },
											isNewPatientInfoInValid: { ...state.isNewPatientInfoInValid, lastName: [false, ""] },
											isNewPatientInfoValidated: { ...state.isNewPatientInfoValidated, lastName: true },
										});
									} else {
										// setIsNewPatientInfoInValid({...state.isNewPatientInfoInValid, lastName: [true, "last name is required"]});
										setState({
											...state,
											isNewPatientInfoInValid: {
												...state.isNewPatientInfoInValid,
												lastName: [true, "last name is required"],
											},
											isNewPatientInfoValidated: { ...state.isNewPatientInfoValidated, lastName: true },
										});
									}
								}}
								placeholder="Last Name"
								required
								isInvalid={state.isNewPatientInfoInValid.lastName[0] && state.isNewPatientInfoValidated.lastName}
							/>
							<Form.Control.Feedback type="invalid">{state.isNewPatientInfoInValid.lastName[1]}</Form.Control.Feedback>
						</Form.Group>
					</Row>

					{/* Date of Birth & Injury */}
					<Row>
						<Form.Group as={Col} md={6} sm={12} controlId="add-patient-form-dob">
							<Form.Label>Date Of Birth</Form.Label>
							<Datetime
								style={{ fontSize: "0.875rem", fontWeight: "400", color: "#495057", lineHeight: "1.5" }}
								// initialValue={new Date(state.newPatientInfo.Personal.DateOfBirth)}
								initialValue={!!state.newPatientInfo.Personal.DateOfBirth ? moment(state.newPatientInfo.Personal.DateOfBirth).format("MM/DD/YYYY") : ""}
								onChange={(evt) => {
									try {
										var tempPatientData = { ...state.newPatientInfo };
										tempPatientData.Personal.DateOfBirth = moment(evt._d).format("MM/DD/YYYY");
										setState({ ...state, newPatientInfo: { ...tempPatientData } });
									} catch {}
								}}
								dateFormat="MM/DD/YYYY"
								timeFormat={false}
								ref={dateTimePickerRef}
								closeOnSelect
							/>
						</Form.Group>

						<Form.Group as={Col} controlId="add-patient-form-injury">
							<Form.Label>Injury</Form.Label>
							<Form.Control
								size="sm"
								type="input"
								onChange={(e) => {
									let tempPatientData = { ...state.newPatientInfo };
									tempPatientData.Medical.Injury = e.target.value;
									setState({ ...state, newPatientInfo: { ...tempPatientData } });
								}}
								placeholder="Injury"
								required
							/>
						</Form.Group>
					</Row>

					{/* Sex */}
					<Row style={{ marginBottom: "15px", marginTop: "15px" }}>
						<Form.Group as={Col} md={6} sm={12} controlId="add-patient-form-sex" className="custom-toggle-1-container">
							<Button
								className={state.newPatientInfo.Personal.Gender === "Male" ? "active" : ""}
								onClick={(e) => {
									let tempPatientData = { ...state.newPatientInfo };
									tempPatientData.Personal.Gender = "Male";
									setState({ ...state, newPatientInfo: { ...tempPatientData } });
								}}
							>
								Male
							</Button>
							<Button
								className={state.newPatientInfo.Personal.Gender == "Female" ? "active" : ""}
								onClick={(e) => {
									let tempPatientData = { ...state.newPatientInfo };
									tempPatientData.Personal.Gender = "Female";
									setState({ ...state, newPatientInfo: { ...tempPatientData } });
								}}
							>
								Female
							</Button>
						</Form.Group>
					</Row>

					{/* Email & Phone */}
					<Row>
						<Form.Group as={Col} controlId="add-patient-form-email">
							<Form.Label>Email</Form.Label>
							<Form.Control
								size="sm"
								type="input"
								onChange={(e) => {
									if (!!e.target.value) {
										if (ValidateEmail(e.target.value)) {
											let tempPatientData = { ...state.newPatientInfo };
											tempPatientData.Personal.Email = e.target.value;
											setState({
												...state,
												newPatientInfo: { ...tempPatientData },
												isNewPatientInfoInValid: { ...state.isNewPatientInfoInValid, email: [false, ""] },
												isNewPatientInfoValidated: { ...state.isNewPatientInfoValidated, email: true },
											});
										} else {
											setState({
												...state,
												isNewPatientInfoInValid: {
													...state.isNewPatientInfoInValid,
													email: [true, "email must be valid"],
												},
												isNewPatientInfoValidated: { ...state.isNewPatientInfoValidated, email: true },
											});
										}
									} else {
										setState({
											...state,
											// isNewPatientInfoInValid: { ...state.isNewPatientInfoInValid, email: [true, "email is required"] },
											isNewPatientInfoValidated: { ...state.isNewPatientInfoValidated, email: true },
										});
									}
								}}
								placeholder="Email"
								required
								isInvalid={state.isNewPatientInfoInValid.email[0] && state.isNewPatientInfoValidated.email}
							/>
							<Form.Control.Feedback type="invalid">{state.isNewPatientInfoInValid.email[1]}</Form.Control.Feedback>
						</Form.Group>
						<Form.Group as={Col} controlId="add-patient-form-cellphone">
							<Form.Label>Phone</Form.Label>
							<Form.Control
								size="sm"
								type="input"
								onChange={(e) => {
									let tempPatientData = { ...state.newPatientInfo };
									tempPatientData.Personal.CellPhone = e.target.value;
									setState({ ...state, newPatientInfo: { ...tempPatientData } });
								}}
								placeholder="Phone Number"
								required
							/>
						</Form.Group>
					</Row>

					<Row style={{ marginTop: "5vh", marginBottom: "5vh" }}></Row>

					{/* Address line */}
					<Row>
						<Form.Group as={Col} controlId="add-patient-form-st-1">
							<Form.Label>Street Address</Form.Label>
							<Form.Control
								size="sm"
								type="input"
								onChange={(e) => {
									let tempPatientData = { ...state.newPatientInfo };
									tempPatientData.Personal.Address.StreetAddress1 = e.target.value;
									setState({ ...state, newPatientInfo: { ...tempPatientData } });
								}}
								placeholder="Address Line 1"
								required
							/>
						</Form.Group>
					</Row>

					{/* City & Postal Code */}
					<Row>
						<Form.Group as={Col} controlId="add-patient-form-city">
							<Form.Label>City</Form.Label>
							<Form.Control
								size="sm"
								type="input"
								onChange={(e) => {
									let tempPatientData = { ...state.newPatientInfo };
									tempPatientData.Personal.Address.City = e.target.value;
									setState({ ...state, newPatientInfo: { ...tempPatientData } });
								}}
								placeholder="City"
								required
							/>
						</Form.Group>

						<Form.Group as={Col} controlId="add-patient-form-province">
							<Form.Label>Province / State</Form.Label>
							<Form.Control
								size="sm"
								type="input"
								onChange={(e) => {
									let tempPatientData = { ...state.newPatientInfo };
									tempPatientData.Personal.Address.Province = e.target.value;
									setState({ ...state, newPatientInfo: { ...tempPatientData } });
								}}
								placeholder="Province / State"
								required
							/>
						</Form.Group>
					</Row>

					{/* Country & Province */}
					<Row>
						<Form.Group as={Col} controlId="add-patient-form-country">
							<Form.Label>Country</Form.Label>
							<Form.Control
								size="sm"
								as="select"
								onChange={(e) => {
									let tempPatientData = { ...state.newPatientInfo };
									tempPatientData.Personal.Address.Country = e.target.value;
									setState({ ...state, newPatientInfo: { ...tempPatientData } });
								}}
								custom
								className="ui-select"
							>
								<option value={""} selected={props.parentState.ActiveOrganization.country === ""}></option>
								{countryList.map((country) => {
									return (
										<option value={country.name} selected={!!props.parentState.ActiveOrganization.country && country.name.toLowerCase() == props.parentState.ActiveOrganization.country.toLowerCase()}>
											{country.name}
										</option>
									);
								})}
							</Form.Control>
						</Form.Group>

						<Form.Group as={Col} controlId="add-patient-postal-code">
							<Form.Label>Postal Code</Form.Label>
							<Form.Control
								size="sm"
								type="input"
								onChange={(e) => {
									let tempPatientData = { ...state.newPatientInfo };
									tempPatientData.Personal.Address.PostalCode = e.target.value;
									setState({ ...state, newPatientInfo: { ...tempPatientData } });
								}}
								placeholder="Postal Code"
								required
							/>
						</Form.Group>
					</Row>

					<Row style={{ marginTop: "5vh", marginBottom: "5vh" }}></Row>

					{/* Emergency Contact Name & Phone */}
					<Row>
						<Form.Group as={Col} controlId="add-patient-emergency-contact">
							<Form.Label>Emergency Contact Name</Form.Label>
							<Form.Control
								size="sm"
								type="input"
								onChange={(e) => {
									let tempPatientData = { ...state.newPatientInfo };
									let fullName = e.target.value;
									let nameParts = fullName.split(" ");
									tempPatientData.Medical.EmergencyFirstName = nameParts[0];
									tempPatientData.Medical.EmergencyLastName = nameParts.length >= 2 ? nameParts.slice(1).join(" ") : "";
									setState({ ...state, newPatientInfo: { ...tempPatientData } });
								}}
								placeholder="Emergency Contact Name"
								required
							/>
						</Form.Group>
						<Form.Group as={Col} controlId="add-patient-emergency-phone">
							<Form.Label>Emergency Contact Number</Form.Label>
							<Form.Control
								size="sm"
								type="input"
								onChange={(e) => {
									let tempPatientData = { ...state.newPatientInfo };
									tempPatientData.Medical.EmergencyPhone = e.target.value;
									setState({ ...state, newPatientInfo: { ...tempPatientData } });
								}}
								placeholder="Emergency Contact Number"
								required
							/>
						</Form.Group>
					</Row>

					<Row style={{ marginTop: "5vh", marginBottom: "5vh" }}></Row>

					{/* Expected Visits */}
					<Row>
						<Form.Group as={Col} md={6} sm={12} controlId="add-patient-form-expected-visits">
							<Form.Label>What is the expected number of visits?</Form.Label>
						</Form.Group>

						<Form.Group as={Col} md={3} sm={12} controlId="change-patient-expected-visits">
							<Form.Control
								size="sm"
								as="select"
								onChange={(e) => {
									console.log(e.target.value, "clicked");
									let tempPatientData = { ...state.newPatientInfo };
									tempPatientData.Case.ExpectedVisits = parseInt(e.target.value);
									setState({ ...state, newPatientInfo: { ...tempPatientData } });
								}}
								custom
								className="ui-select"
								required
							>
								{new Array(50).fill(0).map((val, ind) => {
									return (
										<option value={ind + 1} selected={state.newPatientInfo.Case.ExpectedVisits === ind + 1}>
											{ind + 1}
										</option>
									);
								})}
							</Form.Control>
						</Form.Group>
					</Row>

					<Row style={{ marginTop: "10px", marginBottom: "10px" }}></Row>

					{/* New Patients */}
					<Row>
						<Form.Group as={Col} md={6} sm={12} controlId="add-patient-is-patient-new">
							<Form.Label>Is this a new patient?</Form.Label>
						</Form.Group>

						<Form.Group as={Col} md={3} sm={12} controlId="add-patient-is-patient-new" className="custom-toggle-1-container">
							<Button
								className={state.newPatientInfo.Case.IsNewPatient === true ? "active" : ""}
								onClick={(e) => {
									let tempPatientData = { ...state.newPatientInfo };
									tempPatientData.Case.IsNewPatient = true;
									setState({ ...state, newPatientInfo: { ...tempPatientData } });
								}}
							>
								Yes
							</Button>
							<Button
								className={state.newPatientInfo.Case.IsNewPatient === false ? "active" : ""}
								onClick={(e) => {
									let tempPatientData = { ...state.newPatientInfo };
									tempPatientData.Case.IsNewPatient = false;
									setState({ ...state, newPatientInfo: { ...tempPatientData } });
								}}
							>
								No
							</Button>
						</Form.Group>
					</Row>

					<Row style={{ marginTop: "5vh", marginBottom: "5vh" }}></Row>

					<Alert variant="warning" show={state.error[0] ? true : false}>
						<div className="flex-row">
							<p>{state.error[1]}</p>
						</div>
					</Alert>

					{/* Submission Button */}
					<Row>
						<Form.Group as={Col} controlId="add-patient-submit-button">
							<OverlayTrigger rootClose={true} show={state.isAddPatientConfirmationVisible} trigger="click" placement="right" overlay={RenderAddPatientConfirmationOverlay()}>
								<Button
									style={{ background: "#45b249", margin: "0px" }}
									onClick={(e) => {
										if (!IsFormValid(state.isNewPatientInfoInValid)) {
											setState({
												...state,
												error: [true, "Required fields must be completed"],
												isNewPatientInfoValidated: { ...newPatientDataAllValidated },
											});
										} else {
											setState({
												...state,
												error: [false, ""],
												isAddPatientConfirmationVisible: true,
												isNewPatientInfoValidated: { ...newPatientDataAllValidated },
											});
										}
									}}
								>
									Add
								</Button>
							</OverlayTrigger>
						</Form.Group>
					</Row>
				</section>
			</div>
		);
	};

	const IsFormValid = (obj) => {
		let valid = true;
		let fieldNames = Object.keys(obj);
		for (let i = 0; i < fieldNames.length; i++) {
			valid = valid && !obj[fieldNames[i]][0];
		}
		return valid;
	};

	const RenderAddPatientConfirmationOverlay = () => {
		return (
			<Popover className="action-toggle-wrapper roles-toggle-wrapper" id={`popover${Math.floor(Math.random()).toString()}`}>
				<Popover.Content>
					<Row>
						<Col>
							<h3 className="green-color">Are you sure you want to add this patient?</h3>
						</Col>
					</Row>
					<Row>
						<Col>
							<Button
								style={{ background: "#45b249", marginRight: "5px" }}
								onClick={(e) => {
									PostNewPatientData(state.newPatientInfo);
								}}
							>
								Yes
							</Button>
							<Button style={{ background: "#6c7d85" }} onClick={(e) => setState({ ...state, isAddPatientConfirmationVisible: false })}>
								No
							</Button>
						</Col>
					</Row>
				</Popover.Content>
			</Popover>
		);
	};

	const PostNewPatientData = (patientData) => {
		props.setState({
			...props.parentState,
			Preloader: true,
		});

		try {
			patientData.Personal.DateOfBirth = new Date(patientData.Personal.DateOfBirth).toISOString();
		} catch {
			patientData.Personal.DateOfBirth = new Date().toISOString();
		}

		console.log({
			...patientData,
			CreatedAt: new Date().toISOString(),
			CreatedAtOffset: -(new Date().getTimezoneOffset() / 60),
			LastModified: new Date().toISOString(),
			LastModifiedOffset: -(new Date().getTimezoneOffset() / 60),
		});

		RetrieveToken()
			.then((token) => {
				axios(
					{
						method: "POST",
						url: props.dbUrl + "/api/patients/v4/create",
						headers: {
							Authorization: "Bearer " + token,
							BusinessId: props.parentState.ActiveOrganization.id,
							"Content-Type": "application/json",
						},
						data: {
							...patientData,
							CreatedAt: new Date().toISOString(),
							CreatedAtOffset: -(new Date().getTimezoneOffset() / 60),
							LastModified: new Date().toISOString(),
							LastModifiedOffset: -(new Date().getTimezoneOffset() / 60),
						},
					},
					{ timeout: 10000 }
				)
					.then((response) => {
						if (response.status === 200) {
							props.setState({
								...props.parentState,
								Preloader: false,
							});
							if (response.data.success) {
								setState({
									...state,
									view: "view-add-patient-successful-message",
									isNewPatientInfoInValid: { ...defaultIsNewPatientDataInValid },
									isNewPatientInfoValidated: { ...defaultIsNewPatientDataValidated },
								});
							} else {
								setState({
									...state,
									view: "view-add-patient-failure-message",
									isNewPatientInfoInValid: { ...defaultIsNewPatientDataInValid },
									isNewPatientInfoValidated: { ...defaultIsNewPatientDataValidated },
								});
							}
						} else {
							setState({
								...state,
								view: "view-add-patient-failure-message",
								isNewPatientInfoInValid: { ...defaultIsNewPatientDataInValid },
								isNewPatientInfoValidated: { ...defaultIsNewPatientDataValidated },
							});
						}
					})
					.catch((err) => {
						setState({ ...state, error: [true, "please, check internet connection"] });
						props.setState({
							...props.parentState,
							Preloader: false,
						});
					});
			})
			.catch((err) => console.log(err));
	};

	const RenderAddPatientSuccessfulMessageView = () => {
		return (
			<Fragment>
				<div
					style={{ marginBottom: "5vh", cursor: "pointer", fontSize: "1.2rem", fontWeight: "500" }}
					onClick={(e) => {
						// setView("view-dashboard-overview-section");
						setState({
							...state,
							view: "view-dashboard-overview-section",
							newPatientInfo: JSON.parse(JSON.stringify(defaultPatientData)),
							error: [false, ""],
							isAddPatientConfirmationVisible: false,
							isNewPatientInfoInValid: { ...defaultIsNewPatientDataInValid },
							isNewPatientInfoValidated: { ...defaultIsNewPatientDataValidated },
						});
					}}
				>
					x close
				</div>

				<div style={{ borderLeftColor: "#45b249", borderLeftStyle: "solid", borderLeftWidth: "5px", paddingLeft: "10px" }}>
					<h2>Patient was saved successfully</h2>
				</div>
			</Fragment>
		);
	};

	const RenderAddPatientFailureMessageView = () => {
		return (
			<Fragment>
				<div
					style={{ marginBottom: "5vh", cursor: "pointer", fontSize: "1.2rem", fontWeight: "500" }}
					onClick={(e) => {
						// setView("view-dashboard-overview-section");
						setState({
							...state,
							view: "view-dashboard-overview-section",
							newPatientInfo: JSON.parse(JSON.stringify(defaultPatientData)),
							error: [false, ""],
							isAddPatientConfirmationVisible: false,
							isNewPatientInfoInValid: { ...defaultIsNewPatientDataInValid },
							isNewPatientInfoValidated: { ...defaultIsNewPatientDataValidated },
						});
					}}
				>
					x close
				</div>

				<div style={{ borderLeftColor: "red", borderLeftStyle: "solid", borderLeftWidth: "5px", paddingLeft: "10px" }}>
					<h2>Something went wrong !!!</h2>
				</div>
			</Fragment>
		);
	};

	const RenderSelectedPatientInformationView = () => {
		return (
			<Fragment>
				<div style={{ marginBottom: "5vh" }}>
					<span
						style={{ cursor: "pointer", fontSize: "1.2rem", fontWeight: "500" }}
						onClick={(e) => {
							setState({ ...state, view: "view-dashboard-overview-section" });
						}}
					>
						{"<"} back{" "}
					</span>
				</div>

				<section style={{ paddingBottom: "5vh", paddingTop: "10vh" }}>
					<Row>
						<Col>
							<p className="uppercase p-heading">
								{props.parentState.ActiveOrganization.name}
								{" PATIENT"}
							</p>
							<h1>
								{state.selectedPatientData.Personal.FirstName} {state.selectedPatientData.Personal.LastName}
							</h1>
						</Col>
					</Row>
				</section>

				<section style={{ paddingBottom: "5vh", paddingTop: "5vh" }}>
					<Row>
						<Col>
							<div className="flex-row super-button-wrapper">
								<h4>PATIENT INFORMATION</h4>
								<img
									src="../../photos/icons/dashboard/SVG/edit-green.svg"
									width="25"
									style={{ cursor: "pointer" }}
									onClick={(e) => {
										setState({ ...state, view: "view-edit-patient-form" });
									}}
									alt="edit icon"
								/>
							</div>
						</Col>
					</Row>
					<Row className="info-details">
						<Col>
							<p>
								First Name:
								{!!state.selectedPatientData.Personal.FirstName ? <span>{state.selectedPatientData.Personal.FirstName}</span> : <span>-</span>}
							</p>
							<p>
								Date of Birth (MM/DD/YYYY):
								{!!state.selectedPatientData.Personal.DateOfBirth ? <span>{`${moment(state.selectedPatientData.Personal.DateOfBirth).format("MM/DD/YYYY")}`}</span> : <span>-</span>}
							</p>
							<p>
								Email:
								{!!state.selectedPatientData.Personal.Email ? <span>{state.selectedPatientData.Personal.Email}</span> : <span>-</span>}
							</p>
						</Col>
						<Col>
							<p>
								Last Name:
								{!!state.selectedPatientData.Personal.LastName ? <span>{state.selectedPatientData.Personal.LastName}</span> : <span>-</span>}
							</p>
							<p>
								Sex:
								{!!state.selectedPatientData.Personal.Gender ? <span>{state.selectedPatientData.Personal.Gender}</span> : <span>-</span>}
							</p>
							<p>
								Phone:
								{!!state.selectedPatientData.Personal.CellPhone ? <span>{state.selectedPatientData.Personal.CellPhone}</span> : <span>-</span>}
							</p>
						</Col>
					</Row>

					<Row className="info-details">
						<Col>
							<p>
								Injury:
								{!!state.selectedPatientData.Medical.Injury ? <span>{state.selectedPatientData.Medical.Injury}</span> : <span>-</span>}
							</p>
						</Col>
					</Row>

					<Row className="info-details">
						<Col>
							<p>
								Country:
								{!!state.selectedPatientData.Personal.Address.Country ? <span>{state.selectedPatientData.Personal.Address.Country}</span> : <span>-</span>}
							</p>
							<p>
								City:
								{!!state.selectedPatientData.Personal.Address.City ? <span>{state.selectedPatientData.Personal.Address.City}</span> : <span>-</span>}
							</p>
						</Col>
						<Col>
							<p>
								Province:
								{!!state.selectedPatientData.Personal.Address.Province ? <span>{state.selectedPatientData.Personal.Address.Province}</span> : <span>-</span>}
							</p>
							<p>
								Postal Code:
								{!!state.selectedPatientData.Personal.Address.PostalCode ? <span>{state.selectedPatientData.Personal.Address.PostalCode}</span> : <span>-</span>}
							</p>
						</Col>
					</Row>

					<Row className="info-details">
						<Col>
							<p>
								Street Address Line 1:
								{!!state.selectedPatientData.Personal.Address.StreetAddress1 ? <span>{state.selectedPatientData.Personal.Address.StreetAddress1}</span> : <span>-</span>}
							</p>
						</Col>
					</Row>
				</section>
				<section style={{ paddingBottom: "5vh", paddingTop: "2vh" }}>
					<Row>
						<Col>
							<div className="flex-row super-button-wrapper">
								<h5>EMERGENCY CONTACT</h5>
							</div>
						</Col>
					</Row>
					<Row className="info-details">
						<Col>
							<p>
								Name:
								{!!!state.selectedPatientData.Medical.EmergencyFirstName && !!!state.selectedPatientData.Medical.EmergencyLastName ? (
									<span>-</span>
								) : (
									<span>
										{(!!state.selectedPatientData.Medical.EmergencyFirstName ? state.selectedPatientData.Medical.EmergencyFirstName : "") +
											(!!state.selectedPatientData.Medical.EmergencyFirstName ? " " : "") +
											(!!state.selectedPatientData.Medical.EmergencyLastName ? state.selectedPatientData.Medical.EmergencyLastName : "")}
									</span>
								)}
							</p>
						</Col>
						<Col>
							<p>
								Phone:
								{!!state.selectedPatientData.Medical.EmergencyPhone ? <span>{state.selectedPatientData.Medical.EmergencyPhone}</span> : <span>-</span>}
							</p>
						</Col>
					</Row>
				</section>
			</Fragment>
		);
	};

	const RenderEditPatientForm = () => {
		return (
			<div className="ui-section-wrapper">
				<div style={{ marginBottom: "5vh" }}>
					<span
						style={{ cursor: "pointer", fontSize: "1.2rem", fontWeight: "500" }}
						onClick={(e) => {
							setState({
								...state,
								view: "view-dashboard-overview-section",
								selectedPatientData: JSON.parse(JSON.stringify(defaultPatientData)),
								isSelectedPatientDataInValid: { ...defaultIsSelectedPatientDataInValid },
								isSelectedPatientDataValidated: { ...defaultIsSelectedPatientDataValidated },
								isEditPatientConfirmationVisible: false,
								error: [false, ""],
							});
						}}
					>
						{"<"} back{" "}
					</span>
				</div>

				<section>
					<Row>
						<Col>
							<h1>Edit Patient</h1>
						</Col>
					</Row>
				</section>

				<section className="form-container" style={{ maxWidth: "800px" }}>
					{/* First & Last Name */}
					<Row>
						<Form.Group as={Col} md={6} sm={12} controlId="edit-patient-form-firstname">
							<Form.Label>First Name</Form.Label>
							<Form.Control
								size="sm"
								type="input"
								onChange={(e) => {
									var tempPatientData = state.selectedPatientData;
									tempPatientData.Personal.FirstName = e.target.value;
									if (!!e.target.value) {
										setState({
											...state,
											selectedPatientData: { ...tempPatientData },
											isSelectedPatientDataInValid: { ...state.isSelectedPatientDataInValid, firstName: [false, ""] },
											isSelectedPatientDataValidated: { ...state.isSelectedPatientDataValidated, firstName: true },
										});
									} else {
										setState({
											...state,
											isSelectedPatientDataInValid: {
												...state.isSelectedPatientDataInValid,
												firstName: [true, "first name is required"],
											},
											isSelectedPatientDataValidated: { ...state.isSelectedPatientDataValidated, firstName: true },
										});
									}
								}}
								placeholder="First Name"
								defaultValue={state.selectedPatientData.Personal.FirstName}
								required
								isInvalid={state.isSelectedPatientDataInValid.firstName[0] && state.isSelectedPatientDataValidated.firstName}
							/>
							<Form.Control.Feedback type="invalid">{state.isSelectedPatientDataInValid.firstName[1]}</Form.Control.Feedback>
						</Form.Group>
						<Form.Group as={Col} md={6} sm={12} controlId="edit-patient-form-lastname">
							<Form.Label>Last Name</Form.Label>
							<Form.Control
								size="sm"
								type="input"
								onChange={(e) => {
									let tempPatientData = state.selectedPatientData;
									tempPatientData.Personal.LastName = e.target.value;
									if (!!e.target.value) {
										setState({
											...state,
											selectedPatientData: { ...tempPatientData },
											isSelectedPatientDataInValid: { ...state.isSelectedPatientDataInValid, lastName: [false, ""] },
											isSelectedPatientDataValidated: { ...state.isSelectedPatientDataValidated, lastName: true },
										});
									} else {
										setState({
											...state,
											isSelectedPatientDataInValid: {
												...state.isSelectedPatientDataInValid,
												lastName: [true, "last name is required"],
											},
											isSelectedPatientDataValidated: { ...state.isSelectedPatientDataValidated, lastName: true },
										});
									}
								}}
								placeholder="Last Name"
								defaultValue={state.selectedPatientData.Personal.LastName}
								required
								isInvalid={state.isSelectedPatientDataInValid.lastName[0] && state.isSelectedPatientDataValidated.lastName}
							/>
						</Form.Group>
						<Form.Control.Feedback type="invalid">{state.isSelectedPatientDataInValid.lastName[1]}</Form.Control.Feedback>
					</Row>

					{/* DOB & Injury */}
					<Row>
						<Form.Group as={Col} controlId="edit-patient-form-dob">
							<Form.Label>Date Of Birth (MM/DD/YYYY)</Form.Label>
							<Datetime
								style={{ fontSize: "0.875rem", fontWeight: "400", color: "#495057", lineHeight: "1.5" }}
								// initialValue={new Date(state.selectedPatientData.Personal.DateOfBirth).toLocaleDateString()}
								initialValue={!!state.selectedPatientData.Personal.DateOfBirth ? moment(state.selectedPatientData.Personal.DateOfBirth).format("MM/DD/YYYY") : ""}
								onChange={(evt) => {
									try {
										var tempPatientData = { ...state.selectedPatientData };
										tempPatientData.Personal.DateOfBirth = moment(evt._d).format("MM/DD/YYYY");
										setState({ ...state, selectedPatientData: { ...tempPatientData } });
									} catch {}
								}}
								dateFormat="MM/DD/YYYY"
								timeFormat={false}
								ref={dateTimePickerRef}
								closeOnSelect
							/>
						</Form.Group>
						<Form.Group as={Col} controlId="edit-patient-form-injury">
							<Form.Label>Injury</Form.Label>
							<Form.Control
								size="sm"
								type="input"
								onChange={(e) => {
									var tempPatientData = state.selectedPatientData;
									tempPatientData.Medical.Injury = e.target.value;
									setState({ ...state, selectedPatientData: { ...tempPatientData } });
								}}
								defaultValue={state.selectedPatientData.Medical.Injury}
								placeholder="Injury"
								required
							/>
						</Form.Group>
					</Row>

					{/* Sex */}
					<Row style={{ marginTop: "15px", marginBottom: "15px" }}>
						<Form.Group as={Col} md={6} sm={12} controlId="edit-patient-form-sex" className="custom-toggle-1-container">
							<Button
								className={state.selectedPatientData.Personal.Gender === "Male" ? "active" : ""}
								onClick={(e) => {
									let tempPatientData = { ...state.selectedPatientData };
									tempPatientData.Personal.Gender = "Male";
									// setSelectedPatientData(tempPatientData);
									setState({ ...state, selectedPatientData: { ...tempPatientData } });
								}}
							>
								Male
							</Button>
							<Button
								className={state.selectedPatientData.Personal.Gender === "Female" ? "active" : ""}
								onClick={(e) => {
									let tempPatientData = { ...state.selectedPatientData };
									tempPatientData.Personal.Gender = "Female";
									// setSelectedPatientData(tempPatientData);
									setState({ ...state, selectedPatientData: { ...tempPatientData } });
								}}
							>
								Female
							</Button>
						</Form.Group>
					</Row>

					{/* Email & Phone */}
					<Row>
						<Form.Group as={Col} controlId="edit-patient-form-email">
							<Form.Label>Email</Form.Label>
							<Form.Control
								size="sm"
								type="input"
								onChange={(e) => {
									let tempPatientData = state.selectedPatientData;
									tempPatientData.Personal.Email = e.target.value;
									if (!!e.target.value) {
										if (ValidateEmail(e.target.value)) {
											setState({
												...state,
												selectedPatientData: { ...tempPatientData },
												isSelectedPatientDataInValid: { ...state.isSelectedPatientDataInValid, email: [false, ""] },
												isSelectedPatientDataValidated: { ...state.isSelectedPatientDataValidated, email: true },
											});
										} else {
											setState({
												...state,
												isSelectedPatientDataInValid: {
													...state.isSelectedPatientDataInValid,
													email: [true, "email must be valid"],
												},
												isSelectedPatientDataValidated: { ...state.isSelectedPatientDataValidated, email: true },
											});
										}
									} else {
										// setIsSelectedPatientDataInValid({...state.isSelectedPatientDataInValid, email: [true, "email is required"]});
										setState({
											...state,
											isSelectedPatientDataInValid: {
												...state.isSelectedPatientDataInValid,
												// email: [true, "email is required"],
												email: [false, ""],
											},
										});
									}
								}}
								placeholder="Email"
								defaultValue={state.selectedPatientData.Personal.Email}
								required
								isInvalid={state.isSelectedPatientDataInValid.email[0] && state.isSelectedPatientDataValidated.email}
							/>
							<Form.Control.Feedback type="invalid">{state.isSelectedPatientDataInValid.email[1]}</Form.Control.Feedback>
						</Form.Group>
						<Form.Group as={Col} controlId="edit-patient-form-cellphone">
							<Form.Label>Phone</Form.Label>
							<Form.Control
								size="sm"
								type="input"
								onChange={(e) => {
									let tempPatientData = state.selectedPatientData;
									tempPatientData.Personal.CellPhone = e.target.value;
									// setSelectedPatientData(tempPatientData);
									setState({ ...state, selectedPatientData: { ...tempPatientData } });
								}}
								defaultValue={state.selectedPatientData.Personal.CellPhone}
								placeholder="Phone Number"
								required
							/>
						</Form.Group>
					</Row>

					<Row style={{ marginTop: "5vh", marginBottom: "5vh" }}></Row>

					{/* Street Address */}
					<Row>
						<Form.Group as={Col} controlId="edit-patient-form-st-1">
							<Form.Label>Street Address Line 1</Form.Label>
							<Form.Control
								size="sm"
								type="input"
								onChange={(e) => {
									let tempPatientData = state.selectedPatientData;
									tempPatientData.Personal.Address.StreetAddress1 = e.target.value;
									// setSelectedPatientData(tempPatientData);
									setState({ ...state, selectedPatientData: { ...tempPatientData } });
								}}
								placeholder="Street Address Line 1"
								defaultValue={state.selectedPatientData.Personal.Address.StreetAddress1}
								required
							/>
						</Form.Group>
					</Row>

					{/* City & Province */}
					<Row>
						<Form.Group as={Col} controlId="edit-patient-form-city">
							<Form.Label>City</Form.Label>
							<Form.Control
								size="sm"
								type="input"
								onChange={(e) => {
									let tempPatientData = state.selectedPatientData;
									tempPatientData.Personal.Address.City = e.target.value;
									// setSelectedPatientData(tempPatientData);
									setState({ ...state, selectedPatientData: { ...tempPatientData } });
								}}
								placeholder="City"
								defaultValue={state.selectedPatientData.Personal.Address.City}
								required
							/>
						</Form.Group>

						<Form.Group as={Col} controlId="edit-patient-form-province">
							<Form.Label>Province / State</Form.Label>
							<Form.Control
								size="sm"
								type="input"
								onChange={(e) => {
									let tempPatientData = state.selectedPatientData;
									tempPatientData.Personal.Address.Province = e.target.value;
									setState({ ...state, selectedPatientData: { ...tempPatientData } });
								}}
								defaultValue={state.selectedPatientData.Personal.Address.Province}
								placeholder="Province / State"
								required
							/>
						</Form.Group>
					</Row>

					{/* Country & Postal Code */}
					<Row>
						<Form.Group as={Col} controlId="edit-patient-form-country">
							<Form.Label>Country</Form.Label>
							<Form.Control
								size="sm"
								as="select"
								onChange={(e) => {
									let tempPatientData = state.selectedPatientData;
									tempPatientData.Personal.Address.Country = e.target.value;
									setState({ ...state, selectedPatientData: { ...tempPatientData } });
								}}
								defaultValue={state.selectedPatientData.Personal.Address.Country}
								custom
								className="ui-select"
							>
								<option value={""} selected={state.selectedPatientData.Personal.Address.Country === ""}></option>
								{countryList.map((country) => {
									return (
										<option value={country.name} selected={country.name === state.selectedPatientData.Personal.Address.Country}>
											{country.name}
										</option>
									);
								})}
							</Form.Control>
						</Form.Group>

						<Form.Group as={Col} controlId="edit-patient-postal-code">
							<Form.Label>Postal Code</Form.Label>
							<Form.Control
								size="sm"
								type="input"
								onChange={(e) => {
									let tempPatientData = state.selectedPatientData;
									tempPatientData.Personal.Address.PostalCode = e.target.value;
									// setSelectedPatientData(tempPatientData);
									setState({ ...state, selectedPatientData: { ...tempPatientData } });
								}}
								placeholder="Postal Code"
								defaultValue={state.selectedPatientData.Personal.Address.PostalCode}
								required
							/>
						</Form.Group>
					</Row>

					<Row style={{ marginTop: "5vh", marginBottom: "5vh" }}></Row>

					{/* Emergency Contact & Phone */}
					<Row>
						<Form.Group as={Col} controlId="edit-patient-emergency-contact">
							<Form.Label>Emergency Contact Name</Form.Label>
							<Form.Control
								size="sm"
								type="input"
								onChange={(e) => {
									let tempPatientData = state.selectedPatientData;
									let fullName = e.target.value;
									let nameParts = fullName.split(" ");
									tempPatientData.Medical.EmergencyFirstName = nameParts.length >= 1 ? nameParts[0] : "";
									tempPatientData.Medical.EmergencyLastName = nameParts.length >= 2 ? nameParts.slice(1).join(" ") : "";
									setState({ ...state, selectedPatientData: { ...tempPatientData } });
								}}
								placeholder="Emergency Contact Name"
								defaultValue={(state.selectedPatientData.Medical.EmergencyFirstName + " " + state.selectedPatientData.Medical.EmergencyLastName).trim()}
								required
							/>
						</Form.Group>

						<Form.Group as={Col} controlId="edit-patient-emergency-phone">
							<Form.Label>Emergency Contact Number</Form.Label>
							<Form.Control
								size="sm"
								type="input"
								onChange={(e) => {
									let tempPatientData = state.selectedPatientData;
									tempPatientData.Medical.EmergencyPhone = e.target.value;
									setState({ ...state, selectedPatientData: { ...tempPatientData } });
								}}
								placeholder="Emergency Contact Number"
								defaultValue={state.selectedPatientData.Medical.EmergencyPhone}
								required
							/>
						</Form.Group>
					</Row>

					<Row style={{ marginTop: "5vh", marginBottom: "5vh" }}></Row>

					<Alert variant="warning" show={state.error[0] ? true : false}>
						<div className="flex-row">
							<p>{state.error[1]}</p>
						</div>
					</Alert>

					{/* Submission Button */}
					<Row>
						<Form.Group as={Col} controlId="edit-patient-submit-button">
							<OverlayTrigger rootClose={true} show={state.isEditPatientConfirmationVisible} trigger="click" placement="right" overlay={RenderEditPatientFormSubmissionConfirmationOverlay()}>
								<Button
									style={{ background: "#45b249", margin: "0px" }}
									onClick={(e) => {
										if (!IsFormValid(state.isSelectedPatientDataInValid)) {
											setState({
												...state,
												error: [true, "required fields must be completed"],
												isSelectedPatientDataValidated: { ...selectedPatientDataAllValidated },
											});
										} else {
											setState({
												...state,
												error: [false, ""],
												isEditPatientConfirmationVisible: true,
												isSelectedPatientDataValidated: { ...selectedPatientDataAllValidated },
											});
										}
									}}
								>
									Save
								</Button>
							</OverlayTrigger>
						</Form.Group>
					</Row>
				</section>
			</div>
		);
	};

	const RenderEditPatientFormSubmissionConfirmationOverlay = () => {
		return (
			<Popover className="action-toggle-wrapper roles-toggle-wrapper" id={`popover${Math.floor(Math.random()).toString()}`}>
				<Popover.Content>
					<Row>
						<Col>
							<h3 className="green-color">Are you sure you want to save this information?</h3>
						</Col>
					</Row>
					<Row>
						<Col>
							<Button
								style={{ background: "#45b249", marginRight: "5px" }}
								onClick={(e) => {
									PutSelectedPatientData({ ...state.selectedPatientData });
								}}
							>
								Yes
							</Button>
							<Button style={{ background: "#6c7d85" }} onClick={(e) => setState({ ...state, isEditPatientConfirmationVisible: false })}>
								No
							</Button>
						</Col>
					</Row>
				</Popover.Content>
			</Popover>
		);
	};

	const PutSelectedPatientData = (patientData) => {
		props.setState({
			...props.parentState,
			Preloader: true,
		});

		try {
			patientData.Personal.DateOfBirth = new Date(patientData.Personal.DateOfBirth).toISOString();
			// patientData.Personal.DateOfBirth = patientData.Personal.DateOfBirth.substring(0, 10);
			// console.log(new Date().toISOString())
		} catch {
			patientData.Personal.DateOfBirth = new Date().toISOString();
		}

		RetrieveToken()
			.then((token) => {
				axios(
					{
						method: "PUT",
						url: props.dbUrl + "/api/patients/v4/update",
						headers: {
							Authorization: "Bearer " + token,
							PatientId: patientData.PatientId,
							"Content-Type": "application/json",
						},
						data: { ...patientData, LastModified: new Date().toISOString(), LastModifiedOffset: -(new Date().getTimezoneOffset() / 60) },
					},
					{ timeout: 10000 }
				)
					.then((response) => {
						if (response.status === 200) {
							props.setState({
								...props.parentState,
								Preloader: false,
							});
							if (response.data.success) {
								setState({
									...state,
									view: "view-edit-patient-successful-message",
									isSelectedPatientDataInValid: { ...defaultIsSelectedPatientDataInValid },
									isSelectedPatientDataValidated: { ...defaultIsSelectedPatientDataValidated },
									isEditPatientConfirmationVisible: false,
								});
							} else {
								setState({
									...state,
									view: "view-edit-patient-failure-message",
									isSelectedPatientDataInValid: { ...defaultIsSelectedPatientDataInValid },
									isSelectedPatientDataValidated: { ...defaultIsSelectedPatientDataValidated },
									isEditPatientConfirmationVisible: false,
								});
							}
						} else {
							setState({
								...state,
								view: "view-edit-patient-failure-message",
								isSelectedPatientDataInValid: { ...defaultIsSelectedPatientDataInValid },
								isSelectedPatientDataValidated: { ...defaultIsSelectedPatientDataValidated },
								isEditPatientConfirmationVisible: false,
							});
						}
					})
					.catch((err) => {
						setState({ ...state, error: [true, "please, check internet connection."] });
						props.setState({
							...props.parentState,
							Preloader: false,
						});
					});
			})
			.catch((err) => console.log(err));
	};

	const PutSelectedPatientInArchive = (caseId, patientId) => {
		setState({ ...state, isPatientToArchiveConfirmationVisible: false });
		props.setState({
			...props.parentState,
			Preloader: true,
		});

		RetrieveToken()
			.then((token) => {
				axios(
					{
						method: "PUT",
						url: props.dbUrl + "/api/patients/v4/update/archived/patientid",
						headers: {
							Authorization: "Bearer " + token,
							PatientId: patientId,
							"Content-Type": "application/json",
						},
						data: { IsArchived: true },
					},
					{ timeout: 10000 }
				)
					.then((response) => {
						if (response.status === 200) {
							if (response.data.success) {
								let tempPatientList = { ...state.patientList };
								tempPatientList[caseId].summary.isArchived = true;
								// delete tempPatientList[caseId];
								props.setState({
									...props.parentState,
									Preloader: false,
								});
								setState({
									...state,
									patientList: { ...tempPatientList },
									filteredPatientList: { ...tempPatientList },
									isPatientToArchiveConfirmationVisible: false,
								});
							} else {
								props.setState({
									...props.parentState,
									Preloader: false,
								});
							}
						} else {
							props.setState({
								...props.parentState,
								Preloader: false,
							});
						}
					})
					.catch((err) => {
						setState({ ...state, error: [true, "please, check internet connection."] });
						props.setState({
							...props.parentState,
							Preloader: false,
						});
					});
			})
			.catch((err) => console.log(err));
	};

	const RemoveSelectedPatient = (caseId, patientId) => {
		setState({ ...state, isPatientRemoveConfirmationVisible: false });
		props.setState({
			...props.parentState,
			Preloader: true,
		});

		RetrieveToken()
			.then((token) => {
				axios(
					{
						method: "PUT",
						url: props.dbUrl + "/api/patients/v4/update/anonymize/patientid",
						headers: {
							Authorization: "Bearer " + token,
							PatientId: patientId,
							"Content-Type": "application/json",
						},
					},
					{ timeout: 10000 }
				)
					.then((response) => {
						if (response.status === 200) {
							if (response.data.success) {
								var tempPatientList = { ...state.patientList };
								delete tempPatientList[caseId];
								props.setState({
									...props.parentState,
									Preloader: false,
								});
								setState({
									...state,
									patientList: { ...tempPatientList },
									filteredPatientList: { ...tempPatientList },
									isPatientRemoveConfirmationVisible: false,
								});
							} else {
								props.setState({
									...props.parentState,
									Preloader: false,
								});
							}
						} else {
							props.setState({
								...props.parentState,
								Preloader: false,
							});
						}
					})
					.catch((err) => {
						setState({ ...state, error: [true, "please, check internet connection."] });
						props.setState({
							...props.parentState,
							Preloader: false,
						});
					});
			})
			.catch((err) => console.log(err));
	};

	const PutSelectedPatientOutOfArchive = (caseId, patientId) => {
		setState({ ...state, isPatientFromArchiveConfirmationVisible: false });
		props.setState({
			...props.parentState,
			Preloader: true,
		});

		RetrieveToken()
			.then((token) => {
				axios(
					{
						method: "PUT",
						url: props.dbUrl + "/api/patients/v4/update/archived/patientid",
						headers: {
							Authorization: "Bearer " + token,
							PatientId: patientId,
							"Content-Type": "application/json",
						},
						data: { IsArchived: false },
					},
					{ timeout: 10000 }
				)
					.then((response) => {
						if (response.status === 200) {
							if (response.data.success) {
								let tempPatientList = { ...state.patientList };
								tempPatientList[caseId].summary.isArchived = false;
								props.setState({
									...props.parentState,
									Preloader: false,
								});
								setState({
									...state,
									patientList: { ...tempPatientList },
									filteredPatientList: { ...tempPatientList },
									isPatientFromArchiveConfirmationVisible: false,
								});
							} else {
								props.setState({
									...props.parentState,
									Preloader: false,
								});
							}
						} else {
							props.setState({
								...props.parentState,
								Preloader: false,
							});
						}
					})
					.catch((err) => {
						setState({ ...state, error: [true, "please, check internet connection."] });
						props.setState({
							...props.parentState,
							Preloader: false,
						});
					});
			})
			.catch((err) => console.log(err));
	};

	const UpdateFQConfirmationOverlay = () => {
		return (
			<Popover className="action-toggle-wrapper roles-toggle-wrapper" id={`popover${Math.floor(Math.random()).toString()}`}>
				<Popover.Content>
					<Row>
						<Col>
							<h3 className="green-color">Are you sure you want to submit the answers?</h3>
						</Col>
					</Row>
					<Row>
						<Col>
							<Button
								style={{ background: "#45b249", marginRight: "5px" }}
								onClick={(e) => {
									UpdateFQ();
								}}
							>
								Yes
							</Button>
							<Button style={{ background: "#6c7d85" }} onClick={(e) => setState({ ...state, isUpdateFQConfirmationOpened: false })}>
								No
							</Button>
						</Col>
					</Row>
				</Popover.Content>
			</Popover>
		);
	};

	const UpdateFQ = () => {
		let promiseList = [];

		props.setState({
			...props.parentState,
			Preloader: true,
		});

		RetrieveToken()
			.then((token) => {
				for (let i = 0; i < state.fqDb.length; i++) {
					if (!isEqual(state.fqDb[i], state.fqDbPrev[i])) {
						promiseList.push(
							axios(
								{
									method: "put",
									url: props.dbUrl + "/api/patientfunctionalQuestions/v1/update",
									headers: {
										Authorization: "Bearer " + token,
									},
									data: {
										...state.fqDb[i],
										lastSubmitted: new Date().toLocaleString(dateLocale, defaultDateFormat),
										lastSubmittedOffset: 0.0,
									},
								},
								{ timeout: 10000 }
							)
						);
					}
				}

				Promise.all(promiseList)
					.then((responseArrayValues) => {
						props.setState({
							...props.parentState,
							Preloader: false,
						});

						let status = true;
						for (let i = 0; i < responseArrayValues.length; i++) {
							status = status && responseArrayValues[i].status === 200;
						}

						if (status) {
							setState({ ...state, fqDbPrev: cloneDeep(state.fqDb), isUpdateFQConfirmationOpened: false });
						}
					})
					.catch(() => {
						props.setState({
							...props.parentState,
							Preloader: false,
						});
						setState({ ...state, isUpdateFQConfirmationOpened: false });
					});
			})
			.catch((err) => console.log(err));
	};

	const RemoveFQConfirmationOverlay = () => {
		return (
			<Popover className="action-toggle-wrapper roles-toggle-wrapper" id={`popover${Math.floor(Math.random()).toString()}`}>
				<Popover.Content>
					<Row>
						<Col>
							<h3 className="green-color">Are you sure you want to remove this functional assessment?</h3>
						</Col>
					</Row>
					<Row>
						<Col>
							<Button
								style={{ background: "#45b249", marginRight: "5px" }}
								onClick={(e) => {
									RemoveFQ();
								}}
							>
								Yes
							</Button>
							<Button style={{ background: "#6c7d85" }} onClick={(e) => setState({ ...state, isRemoveFQConfirmationOpened: false })}>
								No
							</Button>
						</Col>
					</Row>
				</Popover.Content>
			</Popover>
		);
	};

	const CreateFQConfirmationOverlay = () => {
		return (
			<Popover className="action-toggle-wrapper roles-toggle-wrapper" id={`popover${Math.floor(Math.random()).toString()}`}>
				<Popover.Content>
					<Row>
						<Col>
							<h3 className="green-color">Do you want to create a new assessment?</h3>
						</Col>
					</Row>
					<Row>
						<Col>
							<Button
								style={{ background: "#45b249", marginRight: "5px" }}
								onClick={(e) => {
									CreateFQ();
								}}
							>
								Yes
							</Button>
							<Button style={{ background: "#6c7d85" }} onClick={(e) => setState({ ...state, isCreateFQConfirmationOpened: false })}>
								No
							</Button>
						</Col>
					</Row>
				</Popover.Content>
			</Popover>
		);
	};

	const RemoveFQ = () => {
		let promiseList = [];

		props.setState({
			...props.parentState,
			Preloader: true,
		});

		RetrieveToken()
			.then((token) => {
				promiseList.push(
					axios(
						{
							method: "delete",
							url: props.dbUrl + "/api/patientfunctionalQuestions/v1/remove/id",
							headers: {
								Authorization: "Bearer " + token,
								id: state.fqDb[state.fqId].id,
							},
						},
						{ timeout: 10000 }
					)
				);

				Promise.all(promiseList)
					.then((responseArrayValues) => {
						props.setState({
							...props.parentState,
							Preloader: false,
						});

						let status = responseArrayValues[0].status === 200 && responseArrayValues[0].data.success;

						state.fqDb.splice(state.fqId, 1);

						if (status) {
							setState({
								...state,
								fqDbPrev: cloneDeep(state.fqDb),
								fqDb: state.fqDb,
								fqId: state.fqDb.length > 0 ? 0 : -1,
								isRemoveFQConfirmationOpened: false,
							});
						}
					})
					.catch(() => {
						props.setState({
							...props.parentState,
							Preloader: false,
						});
						setState({ ...state, isRemoveFQConfirmationOpened: false });
					});
			})
			.catch((err) => console.log(err));
	};

	const CreateFQ = () => {
		props.setState({
			...props.parentState,
			Preloader: true,
		});

		RetrieveToken()
			.then((token) => {
				var promiseList = [];

				var createdAt = new Date().toLocaleString(dateLocale, defaultDateFormat);

				var defaultAnswers = state.fqDb.length > 0 ? state.fqDb[0].answers : Array(17).fill(5);
				var defaultPain = state.fqDb.length > 0 ? state.fqDb[0].overallPain : 0;

				var postedData = {
					caseId: state.caseId,
					createdAt: createdAt,
					createdAtOffset: 0,
					lastSubmitted: createdAt,
					lastSubmittedOffset: 0,
					answers: defaultAnswers,
					overallPain: defaultPain,
				};

				promiseList.push(
					axios(
						{
							method: "post",
							url: props.dbUrl + "/api/PatientFunctionalQuestions/v1/create",
							headers: {
								Authorization: "Bearer " + token,
							},
							data: postedData,
						},
						{ timeout: 10000 }
					)
				);

				Promise.all(promiseList)
					.then((responseArrayValues) => {
						props.setState({
							...props.parentState,
							Preloader: false,
						});

						var status = responseArrayValues[0].status === 200 && responseArrayValues[0].data.success;

						if (status) {
							var newFQDb = [
								{
									id: responseArrayValues[0].data.data.id,
									caseId: state.caseId,
									answers: defaultAnswers,
									overallPain: defaultPain,
									createdAt: createdAt,
									createdAtOffset: 0.0,
									lastSubmitted: createdAt,
									lastSubmittedOffset: 0,
								},
							];

							for (var i = 0; i < state.fqDb.length; i++) {
								newFQDb.push(state.fqDb[i]);
							}

							setState({
								...state,
								fqDb: newFQDb,
								fqDbPrev: cloneDeep(newFQDb),
								fqId: 0,
								isCreateFQConfirmationOpened: false,
							});
						}
					})
					.catch(() => {
						props.setState({
							...props.parentState,
							Preloader: false,
						});
						setState({ ...state, isCreateFQConfirmationOpened: false });
					});
			})
			.catch((err) => console.log(err));
	};

	const RenderEditPatientSuccessfulMessageView = () => {
		return (
			<Fragment>
				<div
					style={{ marginBottom: "5vh", cursor: "pointer", fontSize: "1.2rem", fontWeight: "500" }}
					onClick={(e) => {
						// setView("view-dashboard-overview-section");
						setState({
							...state,
							view: "view-dashboard-overview-section",
							selectedPatientData: JSON.parse(JSON.stringify(defaultPatientData)),
							isSelectedPatientDataInValid: { ...defaultIsSelectedPatientDataInValid },
							isSelectedPatientDataValidated: { ...defaultIsSelectedPatientDataValidated },
							isEditPatientConfirmationVisible: false,
							error: [false, ""],
						});
					}}
				>
					x close
				</div>

				<div style={{ borderLeftColor: "#45b249", borderLeftStyle: "solid", borderLeftWidth: "5px", paddingLeft: "10px" }}>
					<h2>Patient was saved successfully</h2>
				</div>
			</Fragment>
		);
	};

	const RenderEditPatientFailureMessageView = () => {
		return (
			<Fragment>
				<div
					style={{ marginBottom: "5vh", cursor: "pointer", fontSize: "1.2rem", fontWeight: "500" }}
					onClick={(e) => {
						// setView("view-dashboard-overview-section");
						setState({
							...state,
							view: "view-dashboard-overview-section",
							selectedPatientData: JSON.parse(JSON.stringify(defaultPatientData)),
							isSelectedPatientDataInValid: { ...defaultIsSelectedPatientDataInValid },
							isSelectedPatientDataValidated: { ...defaultIsSelectedPatientDataValidated },
							isEditPatientConfirmationVisible: false,
							error: [false, ""],
						});
					}}
				>
					x close
				</div>

				<div style={{ borderLeftColor: "red", borderLeftStyle: "solid", borderLeftWidth: "5px", paddingLeft: "10px" }}>
					<h2>Something went wrong !!!</h2>
				</div>
			</Fragment>
		);
	};

	const RenderPatientPostureMainView = () => {
		return (
			<Fragment>
				<div style={{ position: "absolute", left: "-9999px", top: "-9999px", width: "100%", height: "100%" }}>
					<Row>
						<Col>
							<Line ref={postureVPainHiddenChartRef} data={state?.postureVPainData} options={postureVPainLineChartOptions} />
						</Col>
					</Row>
					<Row>
						<Col>
							<Line ref={frontPostureHiddenChartRef} data={state?.frontPostureData} options={frontPostureLineChartOptions} />
						</Col>
					</Row>
					<Row>
						<Col>
							<Line ref={sidePostureHiddenChartRef} data={state?.sidePostureData} options={sidePostureLineChartOptions} />
						</Col>
					</Row>
				</div>
				<div style={{ marginBottom: "5vh" }}>
					<span
						style={{ cursor: "pointer", fontSize: "1.2rem", fontWeight: "500" }}
						onClick={(e) => {
							setState({
								...state,
								view: "view-dashboard-overview-section",
								postureActiveTab: "view-posture-individual-scan-tab",
								postureDb: {},
								postureId: "",
								postureScanError: [false, ""],
								postureActiveType: "clinic",
							});
						}}
					>
						{"<"} back{" "}
					</span>
				</div>
				<Modal
					className="zoomed-posture-modal"
					size="xl"
					show={state.isPostureImageZoomed}
					onExit={() => {
						DrawMainCanvas(state.postureId, state.postureDb);
					}}
					onHide={() => {
						// setIsPostureImageZoomed(false);
						setState({ ...state, isPostureImageZoomed: false });
					}}
					onShow={() => {
						DrawZoomedCanvas(state.postureId, state.postureDb);
					}}
				>
					<Modal.Header closeButton>{/* <Modal.Title>Modal title</Modal.Title> */}</Modal.Header>
					<Modal.Body>
						<Row>
							<Col>
								<canvas ref={postureZoomedCanvasRef} style={{ borderRadius: "20px", fontFamily: `"Rubik", sans-serif` }} className="zoomed-posture-canvas" />
							</Col>
						</Row>
					</Modal.Body>
				</Modal>

				<section className="patient-info">
					<h2>{state.patientList[state.caseId]?.summary?.name}</h2>
					<p>
						<a href={`mailto:${state.patientList[state.caseId]?.summary?.email}`}>{state.patientList[state.caseId]?.summary?.email}</a>, {state.patientList[state.caseId]?.summary?.gender}{" "}
						{state.patientList[state.caseId]?.summary?.age} years
					</p>
				</section>

				<Modal show={state.isHomeConnectionAccessViolationModalVisible} refName="AddonAccess">
					<div className="centered-text" style={{ margin: "10px" }}>
						<h3>Access Violation</h3>
						<p>Unfortunately, you do not have access to postures taken by patients at home. To get access to this section, please purchase the Pro - Home Monitoring addon from the license page.</p>
						<Button variant="warning" onClick={() => setState({ ...state, isHomeConnectionAccessViolationModalVisible: false })}>
							Okay
						</Button>
					</div>
				</Modal>

				<section className="module-top-nav">
					<Row>
						<Col className="left">
							<div
								className={state.postureActiveTab === "view-posture-individual-scan-tab" ? "active" : ""}
								onClick={() => setState({ ...state, postureActiveTab: "view-posture-individual-scan-tab" })}
							>
								Scans
							</div>
							{state.postureActiveType === "home" && (
								<div className={state.postureActiveTab === "view-journals-tab" ? "active" : ""} onClick={() => setState({ ...state, postureActiveTab: "view-journals-tab" })}>
									Journals
								</div>
							)}
							<div
								style={{ minWidth: "180px" }}
								className={state.postureActiveTab === "view-posture-progress-charts-tab" ? "active" : ""}
								onClick={() => setState({ ...state, postureActiveTab: "view-posture-progress-charts-tab" })}
							>
								Progress Over Time
							</div>
						</Col>
						<Col className="right">
							{/* className={props.parentState.Preloader ? "sync-rotation" : ""} */}
							<div className={props.parentState.Preloader ? "sync-rotation" : ""} style={{ margin: "auto 0px", marginLeft: "10px", padding: "0px", cursor: "pointer" }}>
								<FontAwesomeIcon
									size={24}
									icon={faSync}
									onClick={() => {
										GetInitialPostureDataAndDraw();
									}}
								/>
							</div>
							<UiSwitch
								checked={!(state.postureActiveType === "clinic")}
								onClick={() => {
									if (HasHomeConnection) {
										setState({
											...state,
											postureActiveType: state.postureActiveType === "clinic" ? "home" : "clinic",
											postureActiveTab: state.postureActiveType === "home" && state.postureActiveTab === "view-journals-tab" ? "view-posture-individual-scan-tab" : state.postureActiveTab,
										});
									} else {
										setState({ ...state, isHomeConnectionAccessViolationModalVisible: true });
									}
								}}
								label="Home"
								falseLabel="Clinic"
								className={!(state.postureActiveType === "clinic") ? "monthlyChecked" : "yearlyChecked"}
							/>
						</Col>
					</Row>
					<Row>
						<Col>
							<div className="border"></div>
						</Col>
					</Row>
				</section>

				{state.postureActiveTab === "view-posture-individual-scan-tab" ||
					(state.postureActiveTab === "view-posture-progress-charts-tab" && (
						<section style={{ paddingTop: "0px", paddingBottom: "0px" }}>
							<Alert variant="warning" show={state.postureScanError[0]}>
								<div className="flex-row">
									<p>{state.postureScanError[1]}</p>
								</div>
							</Alert>
						</section>
					))}

				{!!state.postureId && !!state.postureDb && state.postureId in state.postureDb && state.postureActiveTab === "view-posture-individual-scan-tab" ? (
					<Fragment>
						<section className="module-date-selection">
							<Row>
								<Col>
									<div>{AddHoursToDate(state.postureDb[state.postureId].createdAt, state.postureDb[state.postureId].createdAtOffset)}</div>
									<OverlayTrigger key={`id-overlay-dates`} rootClose={true} trigger="click" placement="right" overlay={RenderPostureDateSelectionOverlay()}>
										<div style={{ cursor: "pointer", color: "#2A92CF" }}>Change</div>
									</OverlayTrigger>
								</Col>
							</Row>
						</section>

						{!state.postureScanError[0] && (
							<section className="posture-individual-scan-container">
								<Row style={{ marginTop: "2vh", marginBottom: "2vh", display: "flex", alignItems: "flex-start" }}>
									<Col>
										<div className="posture-canvas-overlay">
											<canvas
												ref={frontPostureMainCanvasRef}
												style={{ borderRadius: "20px", cursor: "pointer" }}
												onClick={() => {
													setState({ ...state, isPostureImageZoomed: true, zoomedViewDirection: "front" });
												}}
											/>
										</div>
									</Col>
									<Col className="posture-score">
										<h3>
											FRONT POSTURE SCORE: <span style={{ color: "#2A92CF" }}>{state.postureDb[state.postureId].front.frontPostureNumber.toFixed(2)}</span>
										</h3>
										<div>
											Head: <span style={{ color: "#2A92CF" }}>{Math.abs(state.postureDb[state.postureId].front.frontHeadAngle).toFixed(2)}°</span> lower on the{" "}
											<span style={{ color: "#2A92CF" }}>{state.postureDb[state.postureId].front.frontHeadAngle >= 0 ? "right" : "left"}</span>
										</div>
										<div>
											Shoulder: <span style={{ color: "#2A92CF" }}>{Math.abs(state.postureDb[state.postureId].front.frontShoulderAngle).toFixed(2)}°</span> lower on the{" "}
											<span style={{ color: "#2A92CF" }}>{state.postureDb[state.postureId].front.frontShoulderAngle >= 0 ? "right" : "left"}</span>
										</div>
										<div>
											Hip: <span style={{ color: "#2A92CF" }}>{Math.abs(state.postureDb[state.postureId].front.frontHipAngle).toFixed(2)}°</span> lower on the{" "}
											<span style={{ color: "#2A92CF" }}>{state.postureDb[state.postureId].front.frontHipAngle >= 0 ? "right" : "left"}</span>
										</div>
										<div>
											Knee: <span style={{ color: "#2A92CF" }}>{Math.abs(state.postureDb[state.postureId].front.frontKneeAngle).toFixed(2)}°</span> lower on the{" "}
											<span style={{ color: "#2A92CF" }}>{state.postureDb[state.postureId].front.frontKneeAngle >= 0 ? "right" : "left"}</span>
										</div>
										<div>
											Ankle: <span style={{ color: "#2A92CF" }}>{Math.abs(state.postureDb[state.postureId].front.frontAnkleAngle).toFixed(2)}°</span> lower on the{" "}
											<span style={{ color: "#2A92CF" }}>{state.postureDb[state.postureId].front.frontAnkleAngle >= 0 ? "right" : "left"}</span>
										</div>
										<div className="posture-score-highlight">
											<h4>
												FRONT POSTURE SCORE <br /> <span style={{ color: "#2A92CF", fontSize: "130%" }}>{state.postureDb[state.postureId].front.frontPostureNumber.toFixed(2)}</span>
											</h4>
										</div>
									</Col>
								</Row>
								<Row style={{ marginTop: "2vh", marginBottom: "2vh", display: "flex", alignItems: "flex-start" }}>
									<Col>
										<div className="posture-canvas-overlay">
											<canvas
												ref={sidePostureMainCanvasRef}
												style={{ borderRadius: "20px", cursor: "pointer" }}
												onClick={() => {
													setState({ ...state, isPostureImageZoomed: true, zoomedViewDirection: "side" });
												}}
											/>
										</div>
									</Col>
									<Col className="posture-score">
										<h3>
											SIDE POSTURE SCORE: <span style={{ color: "#2A92CF" }}>{state.postureDb[state.postureId].side.sidePostureNumber.toFixed(2)}</span>
										</h3>
										<div>
											Head: <span style={{ color: "#2A92CF" }}>{Math.abs(state.postureDb[state.postureId].side.sideHeadDeviation).toFixed(2)}°</span>{" "}
											{state.postureDb[state.postureId].side.sideHeadDeviation < 0 ? "backward" : "forward"} from shoulder.
										</div>
										<div>
											Shoulder: <span style={{ color: "#2A92CF" }}>{Math.abs(state.postureDb[state.postureId].side.sideShoulderDeviation).toFixed(2)}°</span>{" "}
											{state.postureDb[state.postureId].side.sideShoulderDeviation < 0 ? "backward" : "forward"} from hip.
										</div>
										<div>
											Hip: <span style={{ color: "#2A92CF" }}>{Math.abs(state.postureDb[state.postureId].side.sideHipDeviation).toFixed(2)}°</span>{" "}
											{state.postureDb[state.postureId].side.sideHipDeviation < 0 ? "backward" : "forward"} from knee.
										</div>
										<div>
											Knee: <span style={{ color: "#2A92CF" }}>{Math.abs(state.postureDb[state.postureId].side.sideKneeDeviation).toFixed(2)}°</span>{" "}
											{state.postureDb[state.postureId].side.sideKneeDeviation < 0 ? "backward" : "forward"} from ankle.
										</div>
										<div className="posture-score-highlight">
											<h4>
												SIDE POSTURE SCORE <br /> <span style={{ color: "#2A92CF", fontSize: "130%" }}>{state.postureDb[state.postureId].side.sidePostureNumber.toFixed(2)}</span>
											</h4>
										</div>
									</Col>
								</Row>
								<Row style={{ marginTop: "2vh", marginBottom: "2vh" }}>
									<Col>
										<div className="posture-score-highlight">
											<h4>
												TOTAL POSTURE SCORE <span style={{ color: "#2A92CF", fontSize: "130%", marginLeft: "10px" }}>{state.postureDb[state.postureId].globalPostureNumber.toFixed(2)}</span>
											</h4>
										</div>
									</Col>
								</Row>

								{state.postureActiveType === "clinic" && (
									<Fragment>
										<h3 style={{ textAlign: "center" }}>CLINIC NOTES</h3>

										<Row style={{ marginTop: "2vh", marginBottom: "2vh" }}>
											<Form.Group as={Col}>
												<div className="textarea-container">
													<Form.Control
														as={"textarea"}
														value={state.generalNotes.replace(/\\n/g, "\n")}
														rows={5}
														onChange={(e) => {
															setState({ ...state, generalNotes: e.target.value });
														}}
														id={`id-general-notes`}
													/>
													<span
														style={state.generalNotes === state.postureDb[state.postureId].generalNotes ? { display: "none" } : {}}
														onClick={(e) => PutGeneralNotes(state.postureId, state.generalNotes)}
													>
														✓
													</span>
												</div>
											</Form.Group>
										</Row>

										<Row style={{ marginTop: "2vh", marginBottom: "2vh" }}>
											<Col>
												<div className="pain-slider-container">
													<span>PAIN SCORE</span>
													<span>
														<input
															type="range"
															min="0"
															max="10"
															value={state.painScore}
															onChange={(e) => {
																setState({ ...state, painScore: parseInt(e.target.value) });
															}}
															class="pain-slider"
															id="id-pain-score"
														/>
													</span>
													<span>{state.painScore}</span>

													{state.painScore === state.postureDb[state.postureId].painScore ? (
														<span style={{ width: "30px", background: "transparent" }}></span>
													) : (
														<span onClick={(e) => PutPainScore(state.postureId, state.painScore)}>&#10004;</span>
													)}
												</div>
											</Col>
										</Row>

										<Row style={{ marginTop: "2vh", marginBottom: "2vh" }}>
											<Col>
												<div className="pain-slider-container">
													<span>PAIN TYPE</span>
													<Form.Control
														size="sm"
														as="select"
														onChange={(e) => {
															setState({ ...state, painType: e.target.value });
														}}
														custom
														className="ui-select"
														style={{ maxWidth: "150px", marginRight: "5px" }}
													>
														<option value={""} selected={state.postureDb[state.postureId].painType === ""}></option>
														<option value={"dull"} selected={state.postureDb[state.postureId].painType.toLowerCase() === "dull"}>
															{"Dull"}
														</option>
														<option value={"sharp"} selected={state.postureDb[state.postureId].painType.toLowerCase() === "sharp"}>
															{"Sharp"}
														</option>
														<option value={"stabbing"} selected={state.postureDb[state.postureId].painType.toLowerCase() === "stabbing"}>
															{"Stabbing"}
														</option>
														<option value={"tingling"} selected={state.postureDb[state.postureId].painType.toLowerCase() === "tingling"}>
															{"Tingling"}
														</option>
														<option value={"burning"} selected={state.postureDb[state.postureId].painType.toLowerCase() === "burning"}>
															{"Burning"}
														</option>
														<option value={"cramping"} selected={state.postureDb[state.postureId].painType.toLowerCase() === "cramping"}>
															{"Cramping"}
														</option>
														<option value={"numb"} selected={state.postureDb[state.postureId].painType.toLowerCase() === "numb"}>
															{"Numb"}
														</option>
													</Form.Control>

													{state.painType === state.postureDb[state.postureId].painType ? (
														<span style={{ width: "30px", background: "transparent" }}></span>
													) : (
														<span
															style={{
																background: "var(--primary-green)",
																color: "white",
																width: "30px",
																height: "30px",
																cursor: "pointer",
																justifyContent: "center",
																alignItems: "center",
																borderRadius: "5px",
															}}
															onClick={(e) => PutPainType(state.postureId, state.painType)}
														>
															&#10004;
														</span>
													)}
												</div>
											</Col>
										</Row>
									</Fragment>
								)}

								{state.postureActiveType === "home" && (
									<Fragment>
										{/* {state.journalPain >= 0 && (
                      <Row style={{marginTop: "2vh", marginBottom: "2vh"}}>
                        <Col>
                          <div className="pain-slider-container">
                            <span>PAIN SCORE</span>
                            <span>
                              <input type="range" min="0" max="10" value={state.journalPain} class="pain-slider" id="id-pain-score" disabled />
                            </span>
                            <span>{state.journalPain}</span>
                          </div>
                        </Col>
                      </Row>
                    )} */}

										{(!!state.journalNotes || state.journalPain >= 0) && <h3 style={{ textAlign: "center" }}>PATIENT NOTES</h3>}

										{!!state.journalNotes && (
											<Row style={{ marginTop: "2vh", marginBottom: "2vh" }}>
												<Form.Group as={Col}>
													<div className="textarea-container">
														<Form.Control as={"textarea"} value={state.journalNotes} rows={5} id={`id-general-notes`} disabled />
													</div>
												</Form.Group>
											</Row>
										)}

										{state.journalPain >= 0 && (
											<Row className="journal-container">
												<Col>
													<div className="posture-score-highlight">
														<h4>
															PAIN SCORE <br /> <span style={{ color: "#2A92CF", fontSize: "130%" }}>{state.journalPain}</span>
														</h4>
													</div>
												</Col>
											</Row>
										)}
									</Fragment>
								)}
							</section>
						)}

						{/* {state.postureActiveType === "clinic" && (
                <Button
                  style={{background: "#45b249", marginRight: "5px"}}
                  onClick={() => {
                    let keys = Object.keys(state.postureDb);
                    keys = FilterCaseIdByType(state.postureDb, state.postureActiveType, [...keys]);
                    keys = SortCaseIdByDate(state.postureDb, [...keys]);
                    let computedChartData = ComputeChartData(state.postureDb, state.postureActiveType, keys);

                    setState({
                      ...state,
                      postureVPainData: {...computedChartData.postureVPainData},
                      frontPostureData: {...computedChartData.frontPostureData},
                      sidePostureData: {...computedChartData.sidePostureData},
                      journalPainData: {...computedChartData.journalPainData},
                      isPPdfDownloadSelected: true,
                    });
                  }}
                >
                  Download
                </Button>
              )} */}

						{state.postureActiveType === "clinic" && (
							<Fragment>
								<section style={{ padding: "10px", textAlign: "center" }}>
									<Button
										style={{ background: "#45b249", marginRight: "5px" }}
										onClick={() => {
											let keys = Object.keys(state.postureDb);
											keys = FilterCaseIdByType(state.postureDb, state.postureActiveType, [...keys]);
											keys = SortCaseIdByDate(state.postureDb, [...keys]);
											let computedChartData = ComputeChartData(state.postureDb, state.postureActiveType, keys);

											setState({
												...state,
												postureVPainData: { ...computedChartData.postureVPainData },
												frontPostureData: { ...computedChartData.frontPostureData },
												sidePostureData: { ...computedChartData.sidePostureData },
												journalPainData: { ...computedChartData.journalPainData },
												isPPdfDownloadSelected: true,
											});
										}}
									>
										Download
									</Button>

									<Button
										style={{ marginRight: "5px" }}
										onClick={() => {
											let keys = Object.keys(state.postureDb);
											keys = FilterCaseIdByType(state.postureDb, state.postureActiveType, [...keys]);
											keys = SortCaseIdByDate(state.postureDb, [...keys]);
											let computedChartData = ComputeChartData(state.postureDb, state.postureActiveType, keys);

											setState({
												...state,
												postureVPainData: { ...computedChartData.postureVPainData },
												frontPostureData: { ...computedChartData.frontPostureData },
												sidePostureData: { ...computedChartData.sidePostureData },
												journalPainData: { ...computedChartData.journalPainData },
												isPPdfDownloadAllSelected: true,
											});
										}}
									>
										Download All
									</Button>
									<OverlayTrigger rootClose={true} show={state.isRemovePostureConfirmationVisible} trigger="click" placement="right" overlay={RenderRemovePostureConfirmationOverlay()}>
										<Button
											variant="danger"
											onClick={() => {
												setState({ ...state, isRemovePostureConfirmationVisible: true });
											}}
										>
											Remove
										</Button>
									</OverlayTrigger>
								</section>

								<section style={{ padding: "10px", textAlign: "center" }}>
									<Button
										style={{ background: "#6c7d85", marginRight: "5px" }}
										onClick={() => {
											let keys = Object.keys(state.postureDb);
											keys = FilterCaseIdByType(state.postureDb, state.postureActiveType, [...keys]);
											keys = SortCaseIdByDate(state.postureDb, [...keys]);
											let computedChartData = ComputeChartData(state.postureDb, state.postureActiveType, keys);

											setState({
												...state,
												postureVPainData: { ...computedChartData.postureVPainData },
												frontPostureData: { ...computedChartData.frontPostureData },
												sidePostureData: { ...computedChartData.sidePostureData },
												journalPainData: { ...computedChartData.journalPainData },
												isPPdfDownloadAllSelected: true,
											});

											SendPostureEmail();
										}}
									>
										Email
									</Button>
								</section>
							</Fragment>
						)}
					</Fragment>
				) : (
					<Fragment></Fragment>
				)}

				{!!state.postureId && !!state.postureDb && state.postureId in state.postureDb && state.postureActiveTab === "view-posture-progress-charts-tab" && !state.postureScanError[0] ? (
					<Fragment>
						{state.postureActiveType === "clinic" && (
							<Fragment>
								<section className="progress-charts-container">
									<Row>
										<Col>
											<Line
												data={state.postureVPainData}
												options={Object.keys(state.postureVPainLineChartOptions).length > 0 ? state.postureVPainLineChartOptions : postureVPainLineChartOptions}
												id={`chart-1`}
												key={`chart-1`}
											/>
										</Col>
									</Row>
									<Row>
										<Col>
											<h3>POSTURE SCORE VS OVERALL PAIN</h3>
										</Col>
									</Row>
									<Row>
										<Col>
											<Line
												data={state.frontPostureData}
												options={Object.keys(state.frontPostureLineChartOptions).length > 0 ? state.frontPostureLineChartOptions : frontPostureLineChartOptions}
												id={`chart-2`}
												key={`chart-2`}
											/>
										</Col>
									</Row>
									<Row>
										<Col>
											<h3>FRONT POSTURE CHART</h3>
										</Col>
									</Row>

									<Row>
										<Col>
											<Line
												data={state.sidePostureData}
												options={Object.keys(state.sidePostureLineChartOptions).length > 0 ? state.sidePostureLineChartOptions : sidePostureLineChartOptions}
												id={`chart-3`}
												key={`chart-3`}
											/>
										</Col>
									</Row>
									<Row>
										<Col>
											<h3>SIDE POSTURE CHART</h3>
										</Col>
									</Row>
								</section>
							</Fragment>
						)}
						{state.postureActiveType === "home" && (
							<section className="progress-charts-container">
								<Row>
									<Col>
										<Line data={state.journalPainData} options={journalPainLineChartOptions} />
									</Col>
								</Row>
								<Row>
									<Col>
										<h3>SELF-REPORTED PAIN SCORES</h3>
									</Col>
								</Row>

								<Row>
									<Col>
										<Line
											data={state.frontPostureData}
											options={Object.keys(state.frontPostureLineChartOptions).length > 0 ? state.frontPostureLineChartOptions : frontPostureLineChartOptions}
											id={`chart-3`}
											key={`chart-3`}
										/>
									</Col>
								</Row>
								<Row>
									<Col>
										<h3>FRONT POSTURE CHART</h3>
									</Col>
								</Row>

								<Row>
									<Col>
										<Line
											data={state.sidePostureData}
											options={Object.keys(state.sidePostureLineChartOptions).length > 0 ? state.sidePostureLineChartOptions : sidePostureLineChartOptions}
											id={`chart-4`}
											key={`chart-4`}
										/>
									</Col>
								</Row>
								<Row>
									<Col>
										<h3>SIDE POSTURE CHART</h3>
									</Col>
								</Row>
							</section>
						)}
					</Fragment>
				) : (
					<Fragment></Fragment>
				)}

				{!!state.journalKey >= 0 && state.postureActiveTab === "view-journals-tab" && state.postureActiveType === "home" ? (
					<Fragment>
						<section style={{ paddingTop: "0px", paddingBottom: "0px" }}>
							<Alert variant="warning" show={state.journalError[0]}>
								<div className="flex-row">
									<p>{state.journalError[1]}</p>
								</div>
							</Alert>
						</section>
						{!state.journalError[0] && state.journalKey in state.journalDb && (
							<Fragment>
								<section className="module-date-selection">
									<Row>
										<Col>
											<div>{AddHoursToDate(state.journalDb[state.journalKey].createdAt, state.journalDb[state.journalKey].createdAtOffset)}</div>
											<OverlayTrigger rootClose={true} trigger="click" placement="right" overlay={RenderJournalDateSelectionOverlay()}>
												<div style={{ cursor: "pointer", color: "#2A92CF" }}>Change</div>
											</OverlayTrigger>
										</Col>
									</Row>
								</section>

								<Row style={{ marginTop: "2vh", marginBottom: "2vh" }}>
									<Form.Group as={Col}>
										<div className="textarea-container">
											<Form.Control as={"textarea"} value={state.journalDb[state.journalKey].journal} rows={6} id={`id-journals`} disabled />
										</div>
									</Form.Group>
								</Row>

								<Row className="journal-container">
									<Col>
										<div className="posture-score-highlight">
											<h4>
												PAIN SCORE <br /> <span style={{ color: "#2A92CF", fontSize: "130%" }}>{state.journalDb[state.journalKey].painScore}</span>
											</h4>
										</div>
									</Col>
								</Row>
							</Fragment>
						)}
					</Fragment>
				) : (
					<Fragment></Fragment>
				)}
			</Fragment>
		);
	};

	const RenderPostureDateSelectionOverlay = () => {
		return (
			<Popover className="posture-date-overlay-wrapper" style={{ backgroundColor: "#707070" }} id={`popover${Math.floor(Math.random()).toString()}`}>
				<Popover.Content>
					<div>
						<ul>
							{state.postureIds.map((key) => {
								return (
									<li>
										<UIButton
											className={state.postureId === key ? "active-date" : "white-link"}
											onClick={() =>
												setState({
													...state,
													postureId: key,
													painScore: state.postureDb[key].painScore,
													generalNotes: state.postureDb[key].generalNotes,
													painType: state.postureDb[key].painType,
												})
											}
										>
											{AddHoursToDate(state.postureDb[key].createdAt, state.postureDb[key].createdAtOffset)}
											{state.postureId === key ? <img src="../../photos/icons/dashboard/SVG/check-green.svg" width="15" alt="check icon" /> : null}
										</UIButton>
									</li>
								);
							})}
						</ul>
					</div>
				</Popover.Content>
			</Popover>
		);
	};

	const RenderFQDateSelectionOverlay = () => {
		return (
			<Popover className="posture-date-overlay-wrapper" style={{ backgroundColor: "#707070" }} id={`popover${Math.floor(Math.random()).toString()}`}>
				<Popover.Content>
					<div>
						<ul>
							{state.fqDb.map((_, key) => {
								return (
									<li>
										<UIButton className={state.fqId === key ? "active-date" : "white-link"} onClick={() => setState({ ...state, fqId: key })}>
											{/* {AddHoursToDate(state.fqDb[key].createdAt, state.fqDb[key].createdAtOffset)} */}
											<span style={state.fqDb[key].type == "home" ? { color: "#a5ced5" } : {}}>{AddHoursToDate(state.fqDb[key].createdAt, state.fqDb[key].createdAtOffset)}</span>
											{state.fqId === key ? <img src="../../photos/icons/dashboard/SVG/check-green.svg" alt="check icon" width="15" /> : null}
										</UIButton>
									</li>
								);
							})}
						</ul>
					</div>
				</Popover.Content>
			</Popover>
		);
	};

	const RenderJournalDateSelectionOverlay = () => {
		return (
			<Popover className="posture-date-overlay-wrapper" style={{ backgroundColor: "#707070" }} id={`popover${Math.floor(Math.random()).toString()}`}>
				<Popover.Content>
					<div>
						<ul>
							{state.journalDb.map((obj, index) => {
								return (
									<li>
										<UIButton className={state.journalKey === index ? "active-date" : "white-link"} onClick={() => setState({ ...state, journalKey: index })}>
											{AddHoursToDate(state.journalDb[index].createdAt, state.journalDb[index].createdAtOffset)}
											{state.journalKey === index ? <img src="../../photos/icons/dashboard/SVG/check-green.svg" alt="check icon" width="15" /> : null}
										</UIButton>
									</li>
								);
							})}
						</ul>
					</div>
				</Popover.Content>
			</Popover>
		);
	};

	const RenderCaseStatusUpdateOverlay = () => {
		
		return (
			
				<Popover className="posture-date-overlay-wrapper" style={{ backgroundColor: "#707070" }}>
					<Popover.Content>
						{!!state.selectedPatientCasePair.patientId &&
						!!state.selectedPatientCasePair.caseId ? (
							<Fragment>
								<Row style={{ marginTop: "5px", marginBottom: "5px" }}></Row>

								<Row>
									<Form.Group as={Col}>
										<Form.Label>What is the expected number of visits?</Form.Label>
									</Form.Group>
								</Row>

								<Row>
									<Form.Group as={Col} controlId="case-status-overlay-visits">
										<Form.Control
											size="sm"
											as="select"
											onChange={(e) => {
												
												PutCaseInfo(state.selectedPatientCasePair.patientId, state.selectedPatientCasePair.caseId, {
													isNewPatient: state.filteredPatientList[state.selectedPatientCasePair.patientId].caseList[state.selectedPatientCasePair.caseId].isNewPatient,
													overallStatus: state.filteredPatientList[state.selectedPatientCasePair.patientId].caseList[state.selectedPatientCasePair.caseId].overallStatus,
													expectedVisits: parseInt(e.target.value),
												});
											}}
											custom
											className="ui-select"
										>
											{new Array(50).fill(0).map((val, ind) => {
												return (
													<option value={ind + 1} key={ind} selected={state.filteredPatientList[state.selectedPatientCasePair.patientId].caseList[state.selectedPatientCasePair.caseId].expectedVisits === ind + 1}>
														{ind + 1}
													</option>
												);
											})}
										</Form.Control>
									</Form.Group>
								</Row>

								<Row style={{ marginTop: "5px", marginBottom: "5px" }}></Row>

								<Row>
									<Form.Group as={Col}>
										<Form.Label>Is this a new patient?</Form.Label>
									</Form.Group>
								</Row>

								<Row>
									<Form.Group as={Col} controlId="case-status-overlay-new-patient" className="custom-toggle-2-container">
										<Button
											className={state.filteredPatientList[state.selectedPatientCasePair.patientId].caseList[state.selectedPatientCasePair.caseId].isNewPatient === true ? "active" : ""}
											onClick={(e) => {
												PutCaseInfo(state.selectedPatientCasePair.patientId, state.selectedPatientCasePair.caseId, {
													isNewPatient: true,
													overallStatus: state.filteredPatientList[state.selectedPatientCasePair.patientId].caseList[state.selectedPatientCasePair.caseId].overallStatus,
													expectedVisits: state.filteredPatientList[state.selectedPatientCasePair.patientId].caseList[state.selectedPatientCasePair.caseId].expectedVisits,
												});
											}}
										>
											Yes
										</Button>
										<Button
											className={state.filteredPatientList[state.selectedPatientCasePair.patientId].caseList[state.selectedPatientCasePair.caseId].isNewPatient === false ? "active" : ""}
											onClick={(e) => {
												PutCaseInfo(state.selectedPatientCasePair.patientId, state.selectedPatientCasePair.caseId, {
													isNewPatient: false,
													overallStatus: state.filteredPatientList[state.selectedPatientCasePair.patientId].caseList[state.selectedPatientCasePair.caseId].overallStatus,
													expectedVisits: state.filteredPatientList[state.selectedPatientCasePair.patientId].caseList[state.selectedPatientCasePair.caseId].expectedVisits,
												});
											}}
										>
											No
										</Button>
									</Form.Group>
								</Row>

								<Row style={{ marginTop: "5px", marginBottom: "5px" }}></Row>
							</Fragment>
						) : (
							<Fragment></Fragment>
						)}
						<div>
							{/* Hello */}
							<ul>
								{!!state.selectedPatientCasePair.patientId &&
								!!state.selectedPatientCasePair.caseId ? (
									<Fragment>
										<li>
											<h4>Status</h4>
										</li>
										{[0, 1, 2, 3, 4].map((val, ind) => {
											return (
												<li key={ind}>
													<UIButton
														className={
															parseInt(state.filteredPatientList[state.selectedPatientCasePair.patientId].caseList[state.selectedPatientCasePair.caseId].overallStatus) === val
																? "active-date"
																: "white-link"
														}
														value={val}
														onClick={(e) => {
															PutCaseInfo(state.selectedPatientCasePair.patientId, state.selectedPatientCasePair.caseId, {
																isNewPatient: state.filteredPatientList[state.selectedPatientCasePair.patientId].caseList[state.selectedPatientCasePair.caseId].isNewPatient,
																overallStatus: parseInt(e.target.value),
																expectedVisits: state.filteredPatientList[state.selectedPatientCasePair.patientId].caseList[state.selectedPatientCasePair.caseId].expectedVisits,
															});
														}}
													>
														{val === 0 ? "Cancel" : ""}
														{val === 1 ? "Discharge" : ""}
														{val === 2 ? "In Progress" : ""}
														{val === 3 ? "Maintenance" : ""}
														{val === 4 ? "Pause" : ""}

														{parseInt(state.filteredPatientList[state.selectedPatientCasePair.patientId].caseList[state.selectedPatientCasePair.caseId].overallStatus) === val ? (
															<img src="../../photos/icons/dashboard/SVG/check-green.svg" width="15" alt="check icon" />
														) : null}
													</UIButton>
												</li>
											);
										})}
									</Fragment>
								) : (
									<Fragment></Fragment>
								)}
							</ul>
						</div>
					</Popover.Content>
				</Popover>
		);
	};

	const RenderArchiveToOverlay = () => {
		return (
			// <Overlay
			// 	rootClose
			// 	show={state.isPatientToArchiveConfirmationVisible}
			// 	target={state.isPatientToArchiveConfirmationTarget}
			// 	placement="bottom"
			// 	container={isPatientToArchiveConfirmationRef.current}
			// 	onHide={(e) => setState({ ...state, isPatientToArchiveConfirmationVisible: false })}
			// 	containerPadding={20}
			// >
				<Popover className="posture-to-archive-wrapper" style={{ backgroundColor: "#707070" }} id={`popover${Math.floor(Math.random()).toString()}`}>
					<Popover.Content>
						<Row>
							<Col>
								<FontAwesomeIcon icon={faExclamation} color={"red"} style={{ marginRight: "5px" }} />
								The patient named {state.filteredPatientList[state.selectedCaseId]?.summary?.name} will be moved to the archive directory. No patient related information
								is lost by this action. Are you sure?
							</Col>
						</Row>
						<Row>
							<Col md={6} xs={12} style={{ textAlign: "center", margin: "0px", padding: "0px" }}>
								<Button
									style={{ background: "#45b249", marginRight: "5px" }}
									onClick={(e) => {
										// PutSelectedPatientData({...state.selectedPatientData});
										PutSelectedPatientInArchive(state.selectedCaseId, state.selectedPatientId);
									}}
								>
									Confirm
								</Button>
							</Col>
							<Col md={6} xs={12} style={{ textAlign: "center", margin: "0px", padding: "0px" }}>
								<Button style={{ background: "orangered" }} onClick={(e) => setState({ ...state, isPatientToArchiveConfirmationVisible: false })}>
									Cancel
								</Button>
							</Col>
						</Row>
					</Popover.Content>
				</Popover>
			// </Overlay>
		)
	}

	const RenderPatientRemoveOverlay = () => {
		return (
			// <Overlay
			// 	rootClose
			// 	show={state.isPatientRemoveConfirmationVisible}
			// 	target={state.isPatientRemoveConfirmationTarget}
			// 	placement="bottom"
			// 	container={isPatientRemoveConfirmationRef.current}
			// 	onHide={(e) => setState({ ...state, isPatientRemoveConfirmationVisible: false })}
			// 	containerPadding={20}
			// >
				<Popover className="posture-to-archive-wrapper" style={{ backgroundColor: "#707070" }} id={`popover${Math.floor(Math.random()).toString()}`}>
					<Popover.Content>
						<Row>
							<Col>
								<FontAwesomeIcon icon={faExclamation} color={"#ff8000"} style={{ marginRight: "5px" }} />
								Information related to patient named {state.filteredPatientList[state.selectedCaseId]?.summary?.name} with email{" "}
								{state.filteredPatientList[state.selectedCaseId]?.summary?.email} will be completely removed. This action is irreversible. Are you sure?
							</Col>
						</Row>
						<Row>
							<Col md={6} xs={12} style={{ textAlign: "center", margin: "0px", padding: "0px" }}>
								<Button
									style={{ background: "#45b249", marginRight: "5px" }}
									onClick={(e) => {
										RemoveSelectedPatient(state.selectedCaseId, state.selectedPatientId);
									}}
								>
									Confirm
								</Button>
							</Col>
							<Col md={6} xs={12} style={{ textAlign: "center", margin: "0px", padding: "0px" }}>
								<Button style={{ background: "orangered" }} onClick={(e) => setState({ ...state, isPatientRemoveConfirmationVisible: false })}>
									Cancel
								</Button>
							</Col>
						</Row>
					</Popover.Content>
				</Popover>
			// </Overlay>
		)
	}

	const RenderNewCaseSelectionOverlay = () => {
		return (
			<Popover className="posture-date-overlay-wrapper" style={{ backgroundColor: "#707070" }} id={`popover${Math.floor(Math.random()).toString()}`}>
				<Popover.Content>
					<Fragment>
						<Row style={{ marginTop: "5px", marginBottom: "5px" }}></Row>

						<Row>
							<Form.Group as={Col}>
								<Form.Label>What is the expected number of visits?</Form.Label>
							</Form.Group>
						</Row>

						<Row>
							<Form.Group as={Col} controlId="case-status-overlay-visits">
								<Form.Control
									size="sm"
									as="select"
									onChange={(e) => {
										let tempCaseInfo = { ...state.newCaseInfo };
										tempCaseInfo.expectedVisits = parseInt(e.target.value);
										setState({ ...state, newCaseInfo: { ...tempCaseInfo } });
									}}
									custom
									className="ui-select"
								>
									{new Array(50).fill(0).map((val, ind) => {
										return (
											<option value={ind + 1} selected={state.newCaseInfo.expectedVisits === ind + 1}>
												{ind + 1}
											</option>
										);
									})}
								</Form.Control>
							</Form.Group>
						</Row>

						<Row style={{ marginTop: "5px", marginBottom: "5px" }}></Row>

						<Row style={{ marginTop: "5px", marginBottom: "5px" }}></Row>

						<Alert variant="warning" show={state.error[0] ? true : false}>
							<div className="flex-row">
								<p>{state.error[1]}</p>
							</div>
						</Alert>

						<Row>
							<Form.Group as={Col}>
								<Button
									style={{ background: "#45b249" }}
									onClick={(e) => {
										if (!!state.newCaseInfo.patientId && !!state.newCaseInfo.businessId) {
											PostNewCase();
										}
									}}
								>
									Create
								</Button>
							</Form.Group>
							<Form.Group as={Col}>
								<Button
									variant="danger"
									onClick={(e) => {
										setState({ ...state, isPostNewCaseModalVisible: false });
									}}
								>
									Cancel
								</Button>
							</Form.Group>
						</Row>
					</Fragment>
				</Popover.Content>
			</Popover>
		);
	};

	const PutPainScore = (postureId, painScore) => {
		props.setState({
			...props.parentState,
			Preloader: true,
		});

		RetrieveToken()
			.then((token) => {
				axios(
					{
						method: "PUT",
						url: props.dbUrl + "/api/posturemeasurements/v2/update/postureid",
						headers: {
							Authorization: "Bearer " + token,
							postureId: postureId,
							"Content-Type": "application/json",
						},
						data: { painScore: painScore },
					},
					{ timeout: 10000 }
				)
					.then((response) => {
						if (response.status === 200) {
							props.setState({
								...props.parentState,
								Preloader: false,
							});
							if (response.data.success) {
								let tempPostureDb = { ...state.postureDb };
								tempPostureDb[postureId].painScore = parseInt(painScore);
								setState({ ...state, postureDb: tempPostureDb });
							} else {
							}
						} else {
						}
					})
					.catch((err) => {
						setState({ ...state, error: [true, "please, check internet connection."] });
						props.setState({
							...props.parentState,
							Preloader: false,
						});
					});
			})
			.catch((err) => console.log(err));
	};

	const PutPainType = (postureId, painType) => {
		props.setState({
			...props.parentState,
			Preloader: true,
		});

		RetrieveToken()
			.then((token) => {
				axios(
					{
						method: "PUT",
						url: props.dbUrl + "/api/posturemeasurements/v2/update/postureid",
						headers: {
							Authorization: "Bearer " + token,
							postureId: postureId,
							"Content-Type": "application/json",
						},
						data: { painType: painType },
					},
					{ timeout: 10000 }
				)
					.then((response) => {
						if (response.status === 200) {
							props.setState({
								...props.parentState,
								Preloader: false,
							});
							if (response.data.success) {
								let tempPostureDb = { ...state.postureDb };
								tempPostureDb[postureId].painType = painType;
								setState({ ...state, postureDb: tempPostureDb });
							} else {
							}
						} else {
						}
					})
					.catch((err) => {
						setState({ ...state, error: [true, "please, check internet connection."] });
						props.setState({
							...props.parentState,
							Preloader: false,
						});
					});
			})
			.catch((err) => console.log(err));
	};

	const PutGeneralNotes = (postureId, notes) => {
		props.setState({
			...props.parentState,
			Preloader: true,
		});

		RetrieveToken()
			.then((token) => {
				var changedNotes = notes.replace(/\n/g, "\\n");

				axios(
					{
						method: "PUT",
						url: props.dbUrl + "/api/posturemeasurements/v2/update/postureid",
						headers: {
							Authorization: "Bearer " + token,
							postureId: postureId,
							"Content-Type": "application/json",
						},
						data: { notes: changedNotes },
					},
					{ timeout: 10000 }
				)
					.then((response) => {
						if (response.status === 200) {
							props.setState({
								...props.parentState,
								Preloader: false,
							});
							if (response.data.success) {
								let tempPostureDb = { ...state.postureDb };
								tempPostureDb[postureId].generalNotes = notes;
								setState({ ...state, postureDb: tempPostureDb });
							} else {
							}
						} else {
						}
					})
					.catch((err) => {
						setState({ ...state, error: [true, "please, check internet connection."] });
						props.setState({
							...props.parentState,
							Preloader: false,
						});
					});
			})
			.catch((err) => console.log(err));
	};

	const postureVPainLineChartOptions = {
		scales: {
			y: {
				max: 100,
				min: 0,
				grid: {
					display: false,
				},
			},
			x: {
				offset: true,
				bounds: {
					ticks: {
						labelOffset: 2,
					},
				},
			},
		},
		plugins: {
			legend: {
				display: true,
				labels: {
					usePointStyle: true,
					pointStyle: "rectRounded",
					borderRadius: 2,
					generateLabels: (chart) => {
						var fillColors = ["#6C7D85", "#2A92CF", "#FAAF3B", "#FA0000", "#FA0000", "#FA0000"];
						var labels = [];

						for (let i = 0; i < chart.data.datasets.length; i++) {
							labels.push({
								text: `${chart.data.datasets[i].label}`,
								fillStyle: `${fillColors[i]}`,
								borderRadius: `${3}`,
								hidden: chart.data.datasets[i].hidden,
								datasetIndex: i,
								pointStyle: "rectRounded",
							});
						}
						return labels;
					},
				},
				onClick: (event, legendItem, legend) => {
					let tempPostureVPainData = { ...state.postureVPainData };

					tempPostureVPainData.datasets[legendItem.datasetIndex].hidden = !tempPostureVPainData.datasets[legendItem.datasetIndex].hidden;
					setState({ ...state, postureVPainData: tempPostureVPainData });
				},
			},
			annotation: {
				annotations: [
					{
						drawTime: "beforeDraw",
						id: "box1",
						type: "box",
						scaleID: "y-axis-0",
						yMin: 0,
						yMax: 10,
						backgroundColor: "rgba(69,178,73, 0.2)",
						borderWidth: 0,
						borderColor: "white",
					},
				],
			},
		},
		tooltips: {},
	};

	const frontPostureLineChartOptions = {
		scales: {
			y: {
				max: 75,
				min: -75,
				grid: {
					display: false,
				},
				ticks: {
					callback: (value) => {
						if (value <= -75) {
							return "B 75";
						} else if (value >= 75) {
							return "F 75";
						} else if (value === 0) {
							return "0";
						} else if (value % 10 === 0) {
							return Math.abs(value).toString();
						}
					},
				},
			},
			x: {
				offset: true,
				bounds: {
					ticks: {
						labelOffset: 2,
					},
				},
			},
		},
		plugins: {
			legend: {
				display: true,
				labels: {
					usePointStyle: true,
					pointStyle: "rectRounded",
					borderRadius: 2,
					generateLabels: (chart) => {
						var fillColors = ["#6C7D85", "#2A92CF", "#FAAF3B", "#FA0000", "#000000", "#000000"];
						var labels = [];

						for (let i = 0; i < chart.data.datasets.length; i++) {
							labels.push({
								text: `${chart.data.datasets[i].label}`,
								fillStyle: `${fillColors[i]}`,
								borderRadius: `${3}`,
								hidden: chart.data.datasets[i].hidden,
								datasetIndex: i,
								pointStyle: "rectRounded",
							});
						}
						return labels;
					},
				},
				onClick: (event, legendItem, legend) => {
					let tempFrontPostureData = { ...state.frontPostureData };
					let tempPostureVPainOptions = { ...state.postureVPainLineChartOptions };
					tempFrontPostureData.datasets[legendItem.datasetIndex].hidden = !tempFrontPostureData.datasets[legendItem.datasetIndex].hidden;
					setState({
						...state,
						frontPostureData: tempFrontPostureData,
						postureVPainLineChartOptions: tempPostureVPainOptions,
					});
				},
			},
			annotation: {
				annotations: [
					{
						drawTime: "beforeDraw",
						id: "box1",
						type: "box",
						scaleID: "y-axis-0",
						yMin: -5,
						yMax: 5,
						backgroundColor: "rgba(69,178,73, 0.2)",
						borderWidth: 0,
						borderColor: "white",
					},
				],
			},
		},
		tooltips: {},
	};

	const sidePostureLineChartOptions = {
		scales: {
			y: {
				max: 75,
				min: -75,
				grid: {
					display: false,
				},
				ticks: {
					callback: (value) => {
						if (value <= -75) {
							return "B 75";
						} else if (value >= 75) {
							return "F 75";
						} else if (value === 0) {
							return "0";
						} else if (value % 10 === 0) {
							return Math.abs(value).toString();
						}
					},
				},
			},
			x: {
				offset: true,
				bounds: {
					ticks: {
						labelOffset: 2,
					},
				},
			},
		},
		plugins: {
			legend: {
				display: true,
				labels: {
					usePointStyle: true,
					pointStyle: "rectRounded",
					borderRadius: 2,
					generateLabels: (chart) => {
						var fillColors = ["#6C7D85", "#2A92CF", "#FAAF3B", "#FA0000", "#000000", "#000000"];
						var labels = [];

						for (let i = 0; i < chart.data.datasets.length; i++) {
							labels.push({
								text: `${chart.data.datasets[i].label}`,
								fillStyle: `${fillColors[i]}`,
								borderRadius: `${3}`,
								hidden: chart.data.datasets[i].hidden,
								datasetIndex: i,
								pointStyle: "rectRounded",
							});
						}
						return labels;
					},
				},
				onClick: (event, legendItem, legend) => {
					let tempSidePostureData = { ...state.sidePostureData };
					tempSidePostureData.datasets[legendItem.datasetIndex].hidden = !tempSidePostureData.datasets[legendItem.datasetIndex].hidden;
					// setSidePostureData(tempSidePostureData);
					setState({ ...state, sidePostureData: tempSidePostureData });
				},
			},
			annotation: {
				annotations: [
					{
						drawTime: "beforeDraw",
						id: "box1",
						type: "box",
						scaleID: "y-axis-0",
						yMin: -5,
						yMax: 5,
						backgroundColor: "rgba(69,178,73, 0.2)",
						borderWidth: 0,
						borderColor: "white",
					},
				],
			},
		},
		tooltips: {},
	};

	const journalPainLineChartOptions = {
		scales: {
			y: {
				max: 100,
				min: 0,
				grid: {
					display: false,
				},
			},
			x: {
				offset: true,
				bounds: {
					ticks: {
						labelOffset: 2,
					},
				},
			},
		},
		plugins: {
			legend: {
				display: true,
				labels: {
					usePointStyle: true,
					pointStyle: "rectRounded",
					borderRadius: 2,
					generateLabels: (chart) => {
						var fillColors = ["#6C7D85", "#2A92CF", "#FAAF3B", "#FA0000", "#FA0000", "#FA0000"];
						var labels = [];

						for (let i = 0; i < chart.data.datasets.length; i++) {
							labels.push({
								text: `${chart.data.datasets[i].label}`,
								fillStyle: `${fillColors[i]}`,
								borderRadius: `${3}`,
								hidden: chart.data.datasets[i].hidden,
								datasetIndex: i,
								pointStyle: "rectRounded",
							});
						}
						return labels;
					},
				},
				onClick: (event, legendItem, legend) => {
					let tempPostureVPainData = { ...state.postureVPainData };
					tempPostureVPainData.datasets[legendItem.datasetIndex].hidden = !tempPostureVPainData.datasets[legendItem.datasetIndex].hidden;
					setState({ ...state, postureVPainData: tempPostureVPainData });
				},
			},
			annotation: {
				annotations: [
					{
						drawTime: "beforeDraw",
						id: "box1",
						type: "box",
						scaleID: "y-axis-0",
						yMin: 0,
						yMax: 20,
						backgroundColor: "rgba(69,178,73, 0.2)",
						borderWidth: 0,
						borderColor: "white",
					},
				],
			},
		},
		tooltips: {},
	};

	const fqLineChartOptions = {
		scales: {
			y: {
				max: 10,
				min: 0,
				beginAtZero: true,
				suggestedMin: 0,
				grid: {
					display: false,
				},
			},
			x: {
				offset: true,
				bounds: {
					ticks: {
						labelOffset: 2,
					},
				},
			},
		},
		plugins: {
			legend: {
				display: true,
				labels: {
					usePointStyle: true,
					pointStyle: "rectRounded",
					borderRadius: 2,
					generateLabels: (chart) => {
						let labels = [];

						if (chart.data.datasets.length > 2) {
							for (let i = 2; i < chart.data.datasets.length; i++) {
								labels.push({
									text: `${chart.data.datasets[i].label}`,
									fillStyle: `${chart.data.datasets[i].backgroundColor}`,
									borderRadius: `${3}`,
									hidden: chart.data.datasets[i].hidden,
									datasetIndex: i,
									pointStyle: "rectRounded",
								});
							}
						} else {
							for (let i = 0; i < chart.data.datasets.length; i++) {
								labels.push({
									text: `${chart.data.datasets[i].label}`,
									fillStyle: `${chart.data.datasets[i].backgroundColor}`,
									borderRadius: `${3}`,
									hidden: chart.data.datasets[i].hidden,
									datasetIndex: i,
									pointStyle: "rectRounded",
								});
							}
						}
						return labels;
					},
				},
				onClick: (event, legendItem, legend) => {
					let tempFQData = { ...state.fqChartData };
					let tempFQLineChartOptions = { ...state.fqLineChartOptions };
					tempFQData.datasets[legendItem.datasetIndex].hidden = !tempFQData.datasets[legendItem.datasetIndex].hidden;
					setState({ ...state, fqChartData: tempFQData, fqLineChartOptions: tempFQLineChartOptions });
				},
			},
			annotation: {
				annotations: [
					{
						drawTime: "beforeDraw",
						id: "box1",
						type: "box",
						scaleID: "y-axis-0",
						yMin: 0,
						yMax: 2,
						backgroundColor: "rgba(69,178,73, 0.2)",
						borderWidth: 0,
						borderColor: "white",
					},
				],
			},
		},
		tooltips: {},
	};

	const RenderRemovePostureConfirmationOverlay = () => {
		return (
			<Popover className="action-toggle-wrapper roles-toggle-wrapper" id={`popover${Math.floor(Math.random()).toString()}`}>
				<Popover.Content>
					<Row>
						<Col>
							<h3 className="green-color">Are you sure you want to remove this posture?</h3>
						</Col>
					</Row>
					<Row>
						<Col>
							<Button
								style={{ background: "#45b249", marginRight: "5px" }}
								onClick={(e) => {
									DeletePostureData(state.postureId);
								}}
							>
								Yes
							</Button>
							<Button style={{ background: "#6c7d85" }} onClick={(e) => setState({ ...state, isRemovePostureConfirmationVisible: false })}>
								No
							</Button>
						</Col>
					</Row>
				</Popover.Content>
			</Popover>
		);
	};

	const DeletePostureData = (postureId) => {
		props.setState({
			...props.parentState,
			Preloader: true,
		});

		RetrieveToken()
			.then((token) => {
				axios(
					{
						method: "DELETE",
						url: props.dbUrl + "/api/posturemeasurements/v1/remove/postureid",
						headers: {
							Authorization: "Bearer " + token,
							postureId: postureId,
							"Content-Type": "application/json",
						},
					},
					{ timeout: 10000 }
				)
					.then((response) => {
						if (response.status === 200) {
							if (response.data.success) {
								let tempPostureDb = { ...state.postureDb };
								delete tempPostureDb[postureId];
								let tempPostureIds = state.postureIds.filter((pId) => {
									return pId != postureId;
								});
								let tempPostureId = tempPostureIds.length > 0 ? tempPostureIds[0] : "";
								setState({
									...state,
									isRemovePostureConfirmationVisible: false,
									postureId: tempPostureId,
									postureIds: tempPostureIds,
									postureDb: { ...tempPostureDb },
								});
							} else {
								setState({ ...state, isRemovePostureConfirmationVisible: false });
							}
						} else {
							setState({ ...state, isRemovePostureConfirmationVisible: false });
						}
						props.setState({
							...props.parentState,
							Preloader: false,
						});
					})
					.catch((err) => {
						setState({ ...state, error: [true, "please, check internet connection"] });
						props.setState({
							...props.parentState,
							Preloader: false,
						});
					});
			})
			.catch((err) => console.log(err));
	};

	const MailtoLink = (email, subject = "", body = "") => {
		if (email) {
			let params = subject || body ? "?" : "";
			if (subject) params += `subject=${encodeURIComponent(subject)}`;
			if (body) params += `${subject ? "&" : ""}body=${encodeURIComponent(body)}`;
			// window.location.href = `mailto:${email}${params}`;
			return `mailto:${email}${params}`;
		}
	};

	const SendPostureEmail = () => {
		let email = state.patientList[state.patientId]?.summary?.email ?? "";
		let subject = `Posture Report ${!!(props?.parentState?.ActiveOrganization?.name ?? "") ? "From " + (props?.parentState?.ActiveOrganization?.name ?? "") : ""}`;
		let userName = ((props?.parentState?.User?.title ?? "") + " " + (props?.parentState?.User?.fname ?? "") + " " + (props?.parentState?.User?.lname ?? "") + " ").trim();
		let body = `Hi ${state.patientList[state.patientId]?.summary?.name ?? ""}, \n \nWe have generated a detailed report on your posture based on your visits at ${
			props?.parentState?.ActiveOrganization?.name ?? ""
		}. Let us know if you have any queries regarding this report. \n \nKind Regards,\n${userName}\n${props?.parentState?.ActiveOrganization?.name ?? ""}`;
		window.open(MailtoLink(email, subject, body), "emailWindow");
	};

	const SendFQEmail = () => {
		let email = state.patientList[state.patientId]?.summary?.email ?? "";
		let subject = `Functional Questionnaire Report ${!!(props?.parentState?.ActiveOrganization?.name ?? "") ? "From " + (props?.parentState?.ActiveOrganization?.name ?? "") : ""}`;
		let userName = ((props?.parentState?.User?.title ?? "") + " " + (props?.parentState?.User?.fname ?? "") + " " + (props?.parentState?.User?.lname ?? "") + " ").trim();
		let body = `Hi ${state.patientList[state.patientId]?.summary?.name ?? ""}, \n \nWe have generated a detailed report on your functional progress based on your visits at ${
			props?.parentState?.ActiveOrganization?.name ?? ""
		}. Let us know if you have any queries regarding this report. \n \nKind Regards,\n${userName}\n${props?.parentState?.ActiveOrganization?.name ?? ""}`;
		window.open(MailtoLink(email, subject, body), "emailWindow");
	};

	return (
		<Fragment>
			{state.view === "view-dashboard-overview-section" ? RenderDashboardOverviewSection() : <Fragment></Fragment>}
			{state.view === "view-add-patient-form" ? RenderAddPatientForm() : <Fragment></Fragment>}
			{state.view === "view-add-patient-successful-message" ? RenderAddPatientSuccessfulMessageView() : <Fragment></Fragment>}
			{state.view === "view-add-patient-failure-message" ? RenderAddPatientFailureMessageView() : <Fragment></Fragment>}
			{state.view === "view-patient-information-selected" ? RenderSelectedPatientInformationView() : <Fragment></Fragment>}
			{state.view === "view-edit-patient-form" ? RenderEditPatientForm() : <Fragment></Fragment>}
			{state.view === "view-edit-patient-successful-message" ? RenderEditPatientSuccessfulMessageView() : <Fragment></Fragment>}
			{state.view === "view-edit-patient-failure-message" ? RenderEditPatientFailureMessageView() : <Fragment></Fragment>}
			{state.view === "view-patient-posture-main" ? RenderPatientPostureMainView() : <Fragment></Fragment>}

			{state.view === "view-patient-fq-main" ? (
				<Questionnaire
					onBack={() => {
						setState({
							...state,
							view: "view-dashboard-overview-section",
							fqActiveTab: "view-fq-individual-qa-tab",
						});
					}}
					CaseId={state.caseId}
					PatientId={state.patientId}
					ActiveOrganization={props.parentState.ActiveOrganization}
					dbUrl={props.dbUrl}
				/>
			) : (
				<Fragment></Fragment>
			)}

			{state.view === "view-patient-pro-intake-main" ? (
				<IntakeDashboard
					onBack={() => {
						setState({
							...state,
							view: "view-dashboard-overview-section",
						});
					}}
					CaseId={state.caseId}
					PatientId={state.patientId}
					ActiveOrganization={props.parentState.ActiveOrganization}
					dbUrl={props.dbUrl}
					ActiveAccessLevel={activeAccessLevel}
				/>
			) : null}

			{state.view === "view-patient-forceplate-main" ? (
				<ForcePlate
					CaseId={state.caseId}
					PatientId={state.patientId}
					BusinessId={props.ActiveOrganization.id}
					ActiveOrganization={props.parentState.ActiveOrganization}
					dbUrl={props.dbUrl}
					onBack={() => {
						setState({
							...state,
							view: "view-dashboard-overview-section",
						});
					}}
				/>
			) : null}

			{state.view === "view-patient-hs-main" ? (
				<HealthStatus
					CaseId={state.caseId}
					PatientId={state.patientId}
					BusinessId={props.ActiveOrganization.id}
					ActiveOrganization={props.parentState.ActiveOrganization}
					onBack={() => {
						setState({
							...state,
							view: "view-dashboard-overview-section",
						});
					}}
				/>
			) : null}
		</Fragment>
	);
}

const postureVPainDefaultData = {
	labels: [],
	datasets: [
		{
			label: "Global Posture Score",
			hidden: false,
			fill: false,
			lineTension: 0.1,
			backgroundColor: "#6C7D85",
			borderCapStyle: "round",
			borderColor: "#6C7D85",
			borderDash: [],
			borderDashOffset: 0.0,
			borderJoinStyle: "round",
			borderWidth: 2.0,
			pointBorderColor: "#6C7D85",
			pointBackgroundColor: "#6C7D85",
			pointBorderWidth: 0,
			pointHoverRadius: 9,
			pointHoverBackgroundColor: "#6C7D85",
			pointHoverBorderColor: "#6C7D85",
			pointHoverBorderWidth: 0,
			pointRadius: 6,
			pointHitRadius: 9,
			data: [],
		},
		{
			label: "Front Posture Score",
			hidden: true,
			fill: false,
			lineTension: 0.1,
			backgroundColor: "#2A92CF",
			borderColor: "#2A92CF",
			borderCapStyle: "round",
			borderDash: [],
			borderDashOffset: 0.0,
			borderJoinStyle: "round",
			pointBorderColor: "#2A92CF",
			pointBackgroundColor: "#2A92CF",
			pointBorderWidth: 0,
			pointHoverRadius: 9,
			pointHoverBackgroundColor: "#2A92CF",
			pointHoverBorderColor: "#2A92CF",
			pointHoverBorderWidth: 0,
			pointRadius: 6,
			pointHitRadius: 9,
			data: [],
		},
		{
			label: "Side Posture Score",
			hidden: true,
			fill: false,
			lineTension: 0.1,
			backgroundColor: "#FAAF3B",
			borderColor: "#FAAF3B",
			borderCapStyle: "round",
			borderDash: [],
			borderDashOffset: 0.0,
			borderJoinStyle: "round",
			pointBorderColor: "#FAAF3B",
			pointBackgroundColor: "#FAAF3B",
			pointBorderWidth: 0,
			pointHoverRadius: 9,
			pointHoverBackgroundColor: "#FAAF3B",
			pointHoverBorderColor: "#FAAF3B",
			pointHoverBorderWidth: 0,
			pointRadius: 6,
			pointHitRadius: 9,
			data: [],
		},
		{
			label: "Pain Score",
			hidden: false,
			fill: false,
			lineTension: 0.1,
			backgroundColor: "#FA0000",
			borderColor: "#FA0000",
			borderCapStyle: "round",
			borderDash: [],
			borderDashOffset: 0.0,
			borderJoinStyle: "round",
			pointBorderColor: "#FA0000",
			pointBackgroundColor: "#FA0000",
			pointBorderWidth: 0,
			pointHoverRadius: 9,
			pointHoverBackgroundColor: "#FA0000",
			pointHoverBorderColor: "#FA0000",
			pointHoverBorderWidth: 0,
			pointRadius: 6,
			pointHitRadius: 9,
			data: [],
		},
	],
};

const frontPostureDefaultData = {
	labels: [],
	datasets: [
		{
			label: "Head",
			hidden: false,
			fill: false,
			lineTension: 0.1,
			backgroundColor: "#6C7D85",
			borderCapStyle: "round",
			borderColor: "#6C7D85",
			borderDash: [],
			borderDashOffset: 0.0,
			borderJoinStyle: "round",
			borderWidth: 2.0,
			pointBorderColor: "#6C7D85",
			pointBackgroundColor: "#6C7D85",
			pointBorderWidth: 0,
			pointHoverRadius: 9,
			pointHoverBackgroundColor: "#6C7D85",
			pointHoverBorderColor: "#6C7D85",
			pointHoverBorderWidth: 0,
			pointRadius: 6,
			pointHitRadius: 9,
			data: [],
		},
		{
			label: "Shoulder",
			hidden: false,
			fill: false,
			lineTension: 0.1,
			backgroundColor: "#2A92CF",
			borderColor: "#2A92CF",
			borderCapStyle: "round",
			borderDash: [],
			borderDashOffset: 0.0,
			borderJoinStyle: "round",
			pointBorderColor: "#2A92CF",
			pointBackgroundColor: "#2A92CF",
			pointBorderWidth: 0,
			pointHoverRadius: 9,
			pointHoverBackgroundColor: "#2A92CF",
			pointHoverBorderColor: "#2A92CF",
			pointHoverBorderWidth: 0,
			pointRadius: 6,
			pointHitRadius: 9,
			data: [],
		},
		{
			label: "Hip",
			hidden: false,
			fill: false,
			lineTension: 0.1,
			backgroundColor: "#FAAF3B",
			borderColor: "#FAAF3B",
			borderCapStyle: "round",
			borderDash: [],
			borderDashOffset: 0.0,
			borderJoinStyle: "round",
			pointBorderColor: "#FAAF3B",
			pointBackgroundColor: "#FAAF3B",
			pointBorderWidth: 0,
			pointHoverRadius: 9,
			pointHoverBackgroundColor: "#FAAF3B",
			pointHoverBorderColor: "#FAAF3B",
			pointHoverBorderWidth: 0,
			pointRadius: 6,
			pointHitRadius: 9,
			data: [],
		},
		{
			label: "Knee",
			hidden: false,
			fill: false,
			lineTension: 0.1,
			backgroundColor: "#FA0000",
			borderColor: "#FA0000",
			borderCapStyle: "round",
			borderDash: [],
			borderDashOffset: 0.0,
			borderJoinStyle: "round",
			pointBorderColor: "#FA0000",
			pointBackgroundColor: "#FA0000",
			pointBorderWidth: 0,
			pointHoverRadius: 9,
			pointHoverBackgroundColor: "#FA0000",
			pointHoverBorderColor: "#FA0000",
			pointHoverBorderWidth: 0,
			pointRadius: 6,
			pointHitRadius: 9,
			data: [],
		},
		{
			label: "Ankle",
			hidden: false,
			fill: false,
			lineTension: 0.1,
			backgroundColor: "#000000",
			borderColor: "#000000",
			borderCapStyle: "round",
			borderDash: [],
			borderDashOffset: 0.0,
			borderJoinStyle: "round",
			pointBorderColor: "#000000",
			pointBackgroundColor: "#000000",
			pointBorderWidth: 0,
			pointHoverRadius: 9,
			pointHoverBackgroundColor: "#000000",
			pointHoverBorderColor: "#000000",
			pointHoverBorderWidth: 0,
			pointRadius: 6,
			pointHitRadius: 9,
			data: [],
		},
	],
};

const sidePostureDefaultData = {
	labels: [],
	datasets: [
		{
			label: "Head",
			hidden: false,
			fill: false,
			lineTension: 0.1,
			backgroundColor: "#6C7D85",
			borderCapStyle: "round",
			borderColor: "#6C7D85",
			borderDash: [],
			borderDashOffset: 0.0,
			borderJoinStyle: "round",
			borderWidth: 2.0,
			pointBorderColor: "#6C7D85",
			pointBackgroundColor: "#6C7D85",
			pointBorderWidth: 0,
			pointHoverRadius: 9,
			pointHoverBackgroundColor: "#6C7D85",
			pointHoverBorderColor: "#6C7D85",
			pointHoverBorderWidth: 0,
			pointRadius: 6,
			pointHitRadius: 9,
			data: [],
		},
		{
			label: "Shoulder",
			hidden: false,
			fill: false,
			lineTension: 0.1,
			backgroundColor: "#2A92CF",
			borderColor: "#2A92CF",
			borderCapStyle: "round",
			borderDash: [],
			borderDashOffset: 0.0,
			borderJoinStyle: "round",
			pointBorderColor: "#2A92CF",
			pointBackgroundColor: "#2A92CF",
			pointBorderWidth: 0,
			pointHoverRadius: 9,
			pointHoverBackgroundColor: "#2A92CF",
			pointHoverBorderColor: "#2A92CF",
			pointHoverBorderWidth: 0,
			pointRadius: 6,
			pointHitRadius: 9,
			data: [],
		},
		{
			label: "Hip",
			hidden: false,
			fill: false,
			lineTension: 0.1,
			backgroundColor: "#FAAF3B",
			borderColor: "#FAAF3B",
			borderCapStyle: "round",
			borderDash: [],
			borderDashOffset: 0.0,
			borderJoinStyle: "round",
			pointBorderColor: "#FAAF3B",
			pointBackgroundColor: "#FAAF3B",
			pointBorderWidth: 0,
			pointHoverRadius: 9,
			pointHoverBackgroundColor: "#FAAF3B",
			pointHoverBorderColor: "#FAAF3B",
			pointHoverBorderWidth: 0,
			pointRadius: 6,
			pointHitRadius: 9,
			data: [],
		},
		{
			label: "Knee",
			hidden: false,
			fill: false,
			lineTension: 0.1,
			backgroundColor: "#FA0000",
			borderColor: "#FA0000",
			borderCapStyle: "round",
			borderDash: [],
			borderDashOffset: 0.0,
			borderJoinStyle: "round",
			pointBorderColor: "#FA0000",
			pointBackgroundColor: "#FA0000",
			pointBorderWidth: 0,
			pointHoverRadius: 9,
			pointHoverBackgroundColor: "#FA0000",
			pointHoverBorderColor: "#FA0000",
			pointHoverBorderWidth: 0,
			pointRadius: 6,
			pointHitRadius: 9,
			data: [],
		},
	],
};

const journalPainDefaultData = {
	labels: [],
	datasets: [
		{
			label: "Pain",
			hidden: false,
			fill: false,
			lineTension: 0.1,
			backgroundColor: "#6C7D85",
			borderCapStyle: "round",
			borderColor: "#6C7D85",
			borderDash: [],
			borderDashOffset: 0.0,
			borderJoinStyle: "round",
			borderWidth: 2.0,
			pointBorderColor: "#6C7D85",
			pointBackgroundColor: "#6C7D85",
			pointBorderWidth: 0,
			pointHoverRadius: 9,
			pointHoverBackgroundColor: "#6C7D85",
			pointHoverBorderColor: "#6C7D85",
			pointHoverBorderWidth: 0,
			pointRadius: 6,
			pointHitRadius: 9,
			data: [],
		},
	],
};

const GenerateRandomHex = () => {
	return "#" + Math.floor(Math.random() * 16777215).toString(16);
};

const GetFQDataset = () => {
	let scoreData = {
		label: "Functional Score",
		hidden: false,
		fill: false,
		lineTension: 0.1,
		backgroundColor: "#2a93d0",
		borderCapStyle: "round",
		borderColor: "#2a93d0",
		borderDash: [],
		borderDashOffset: 0.0,
		borderJoinStyle: "round",
		borderWidth: 3.0,
		pointBorderColor: "#2a93d0",
		pointBackgroundColor: "#2a93d0",
		pointBorderWidth: 0,
		pointHoverRadius: 9,
		pointHoverBackgroundColor: "#2a93d0",
		pointHoverBorderColor: "#2a93d0",
		pointHoverBorderWidth: 0,
		pointRadius: 6,
		pointHitRadius: 9,
		data: [],
	};

	let painData = {
		label: "Overall Pain",
		hidden: false,
		fill: false,
		lineTension: 0.1,
		backgroundColor: "#45b349",
		borderCapStyle: "round",
		borderColor: "#45b349",
		borderDash: [],
		borderDashOffset: 0.0,
		borderJoinStyle: "round",
		borderWidth: 3.0,
		pointBorderColor: "#45b349",
		pointBackgroundColor: "#45b349",
		pointBorderWidth: 0,
		pointHoverRadius: 9,
		pointHoverBackgroundColor: "#45b349",
		pointHoverBorderColor: "#45b349",
		pointHoverBorderWidth: 0,
		pointRadius: 6,
		pointHitRadius: 9,
		data: [],
	};

	let expectedScoreData = {
		label: "Expected Functional Score",
		hidden: false,
		fill: false,
		lineTension: 0.1,
		backgroundColor: "#ff4538",
		borderCapStyle: "round",
		borderColor: "#2a93d0",
		borderDash: [10, 10],
		borderDashOffset: 0.0,
		borderJoinStyle: "miter",
		borderWidth: 2.0,
		pointBorderColor: "#2a93d0",
		pointBackgroundColor: "#2a93d0",
		pointBorderWidth: 0,
		pointHoverRadius: 9,
		pointHoverBackgroundColor: "#2a93d0",
		pointHoverBorderColor: "#2a93d0",
		pointHoverBorderWidth: 0,
		pointRadius: 6,
		pointHitRadius: 9,
		data: [],
	};

	let expectedPainData = {
		label: "Expected Overall Pain",
		hidden: false,
		fill: false,
		lineTension: 0.1,
		backgroundColor: "#88c775",
		borderCapStyle: "round",
		borderColor: "#45b349",
		borderDash: [10, 10],
		borderDashOffset: 0.0,
		borderJoinStyle: "miter",
		borderWidth: 2.0,
		pointBorderColor: "#45b349",
		pointBackgroundColor: "#45b349",
		pointBorderWidth: 0,
		pointHoverRadius: 9,
		pointHoverBackgroundColor: "#45b349",
		pointHoverBorderColor: "#45b349",
		pointHoverBorderWidth: 0,
		pointRadius: 6,
		pointHitRadius: 9,
		data: [],
	};

	let dataset = [{ ...expectedScoreData }, { ...expectedPainData }, { ...scoreData }, { ...painData }];

	return dataset;
};

const fqDefaultChartData = {
	labels: [],
	datasets: GetFQDataset(),
};

const countryList = [
	{ name: "Afghanistan", code: "AF" },
	{ name: "Åland Islands", code: "AX" },
	{ name: "Albania", code: "AL" },
	{ name: "Algeria", code: "DZ" },
	{ name: "American Samoa", code: "AS" },
	{ name: "AndorrA", code: "AD" },
	{ name: "Angola", code: "AO" },
	{ name: "Anguilla", code: "AI" },
	{ name: "Antarctica", code: "AQ" },
	{ name: "Antigua and Barbuda", code: "AG" },
	{ name: "Argentina", code: "AR" },
	{ name: "Armenia", code: "AM" },
	{ name: "Aruba", code: "AW" },
	{ name: "Australia", code: "AU" },
	{ name: "Austria", code: "AT" },
	{ name: "Azerbaijan", code: "AZ" },
	{ name: "Bahamas", code: "BS" },
	{ name: "Bahrain", code: "BH" },
	{ name: "Bangladesh", code: "BD" },
	{ name: "Barbados", code: "BB" },
	{ name: "Belarus", code: "BY" },
	{ name: "Belgium", code: "BE" },
	{ name: "Belize", code: "BZ" },
	{ name: "Benin", code: "BJ" },
	{ name: "Bermuda", code: "BM" },
	{ name: "Bhutan", code: "BT" },
	{ name: "Bolivia", code: "BO" },
	{ name: "Bosnia and Herzegovina", code: "BA" },
	{ name: "Botswana", code: "BW" },
	{ name: "Bouvet Island", code: "BV" },
	{ name: "Brazil", code: "BR" },
	{ name: "British Indian Ocean Territory", code: "IO" },
	{ name: "Brunei Darussalam", code: "BN" },
	{ name: "Bulgaria", code: "BG" },
	{ name: "Burkina Faso", code: "BF" },
	{ name: "Burundi", code: "BI" },
	{ name: "Cambodia", code: "KH" },
	{ name: "Cameroon", code: "CM" },
	{ name: "Canada", code: "CA" },
	{ name: "Cape Verde", code: "CV" },
	{ name: "Cayman Islands", code: "KY" },
	{ name: "Central African Republic", code: "CF" },
	{ name: "Chad", code: "TD" },
	{ name: "Chile", code: "CL" },
	{ name: "China", code: "CN" },
	{ name: "Christmas Island", code: "CX" },
	{ name: "Cocos (Keeling) Islands", code: "CC" },
	{ name: "Colombia", code: "CO" },
	{ name: "Comoros", code: "KM" },
	{ name: "Congo", code: "CG" },
	{ name: "Congo, The Democratic Republic of the", code: "CD" },
	{ name: "Cook Islands", code: "CK" },
	{ name: "Costa Rica", code: "CR" },
	{ name: "Cote D'Ivoire", code: "CI" },
	{ name: "Croatia", code: "HR" },
	{ name: "Cuba", code: "CU" },
	{ name: "Cyprus", code: "CY" },
	{ name: "Czech Republic", code: "CZ" },
	{ name: "Denmark", code: "DK" },
	{ name: "Djibouti", code: "DJ" },
	{ name: "Dominica", code: "DM" },
	{ name: "Dominican Republic", code: "DO" },
	{ name: "Ecuador", code: "EC" },
	{ name: "Egypt", code: "EG" },
	{ name: "El Salvador", code: "SV" },
	{ name: "Equatorial Guinea", code: "GQ" },
	{ name: "Eritrea", code: "ER" },
	{ name: "Estonia", code: "EE" },
	{ name: "Ethiopia", code: "ET" },
	{ name: "Falkland Islands (Malvinas)", code: "FK" },
	{ name: "Faroe Islands", code: "FO" },
	{ name: "Fiji", code: "FJ" },
	{ name: "Finland", code: "FI" },
	{ name: "France", code: "FR" },
	{ name: "French Guiana", code: "GF" },
	{ name: "French Polynesia", code: "PF" },
	{ name: "French Southern Territories", code: "TF" },
	{ name: "Gabon", code: "GA" },
	{ name: "Gambia", code: "GM" },
	{ name: "Georgia", code: "GE" },
	{ name: "Germany", code: "DE" },
	{ name: "Ghana", code: "GH" },
	{ name: "Gibraltar", code: "GI" },
	{ name: "Greece", code: "GR" },
	{ name: "Greenland", code: "GL" },
	{ name: "Grenada", code: "GD" },
	{ name: "Guadeloupe", code: "GP" },
	{ name: "Guam", code: "GU" },
	{ name: "Guatemala", code: "GT" },
	{ name: "Guernsey", code: "GG" },
	{ name: "Guinea", code: "GN" },
	{ name: "Guinea-Bissau", code: "GW" },
	{ name: "Guyana", code: "GY" },
	{ name: "Haiti", code: "HT" },
	{ name: "Heard Island and Mcdonald Islands", code: "HM" },
	{ name: "Holy See (Vatican City State)", code: "VA" },
	{ name: "Honduras", code: "HN" },
	{ name: "Hong Kong", code: "HK" },
	{ name: "Hungary", code: "HU" },
	{ name: "Iceland", code: "IS" },
	{ name: "India", code: "IN" },
	{ name: "Indonesia", code: "ID" },
	{ name: "Iran, Islamic Republic Of", code: "IR" },
	{ name: "Iraq", code: "IQ" },
	{ name: "Ireland", code: "IE" },
	{ name: "Isle of Man", code: "IM" },
	{ name: "Israel", code: "IL" },
	{ name: "Italy", code: "IT" },
	{ name: "Jamaica", code: "JM" },
	{ name: "Japan", code: "JP" },
	{ name: "Jersey", code: "JE" },
	{ name: "Jordan", code: "JO" },
	{ name: "Kazakhstan", code: "KZ" },
	{ name: "Kenya", code: "KE" },
	{ name: "Kiribati", code: "KI" },
	{ name: "Korea, Democratic People'S Republic of", code: "KP" },
	{ name: "Korea, Republic of", code: "KR" },
	{ name: "Kuwait", code: "KW" },
	{ name: "Kyrgyzstan", code: "KG" },
	{ name: "Lao People'S Democratic Republic", code: "LA" },
	{ name: "Latvia", code: "LV" },
	{ name: "Lebanon", code: "LB" },
	{ name: "Lesotho", code: "LS" },
	{ name: "Liberia", code: "LR" },
	{ name: "Libyan Arab Jamahiriya", code: "LY" },
	{ name: "Liechtenstein", code: "LI" },
	{ name: "Lithuania", code: "LT" },
	{ name: "Luxembourg", code: "LU" },
	{ name: "Macao", code: "MO" },
	{ name: "Macedonia, The Former Yugoslav Republic of", code: "MK" },
	{ name: "Madagascar", code: "MG" },
	{ name: "Malawi", code: "MW" },
	{ name: "Malaysia", code: "MY" },
	{ name: "Maldives", code: "MV" },
	{ name: "Mali", code: "ML" },
	{ name: "Malta", code: "MT" },
	{ name: "Marshall Islands", code: "MH" },
	{ name: "Martinique", code: "MQ" },
	{ name: "Mauritania", code: "MR" },
	{ name: "Mauritius", code: "MU" },
	{ name: "Mayotte", code: "YT" },
	{ name: "Mexico", code: "MX" },
	{ name: "Micronesia, Federated States of", code: "FM" },
	{ name: "Moldova, Republic of", code: "MD" },
	{ name: "Monaco", code: "MC" },
	{ name: "Mongolia", code: "MN" },
	{ name: "Montserrat", code: "MS" },
	{ name: "Morocco", code: "MA" },
	{ name: "Mozambique", code: "MZ" },
	{ name: "Myanmar", code: "MM" },
	{ name: "Namibia", code: "NA" },
	{ name: "Nauru", code: "NR" },
	{ name: "Nepal", code: "NP" },
	{ name: "Netherlands", code: "NL" },
	{ name: "Netherlands Antilles", code: "AN" },
	{ name: "New Caledonia", code: "NC" },
	{ name: "New Zealand", code: "NZ" },
	{ name: "Nicaragua", code: "NI" },
	{ name: "Niger", code: "NE" },
	{ name: "Nigeria", code: "NG" },
	{ name: "Niue", code: "NU" },
	{ name: "Norfolk Island", code: "NF" },
	{ name: "Northern Mariana Islands", code: "MP" },
	{ name: "Norway", code: "NO" },
	{ name: "Oman", code: "OM" },
	{ name: "Pakistan", code: "PK" },
	{ name: "Palau", code: "PW" },
	{ name: "Palestinian Territory, Occupied", code: "PS" },
	{ name: "Panama", code: "PA" },
	{ name: "Papua New Guinea", code: "PG" },
	{ name: "Paraguay", code: "PY" },
	{ name: "Peru", code: "PE" },
	{ name: "Philippines", code: "PH" },
	{ name: "Pitcairn", code: "PN" },
	{ name: "Poland", code: "PL" },
	{ name: "Portugal", code: "PT" },
	{ name: "Puerto Rico", code: "PR" },
	{ name: "Qatar", code: "QA" },
	{ name: "Reunion", code: "RE" },
	{ name: "Romania", code: "RO" },
	{ name: "Russian Federation", code: "RU" },
	{ name: "RWANDA", code: "RW" },
	{ name: "Saint Helena", code: "SH" },
	{ name: "Saint Kitts and Nevis", code: "KN" },
	{ name: "Saint Lucia", code: "LC" },
	{ name: "Saint Pierre and Miquelon", code: "PM" },
	{ name: "Saint Vincent and the Grenadines", code: "VC" },
	{ name: "Samoa", code: "WS" },
	{ name: "San Marino", code: "SM" },
	{ name: "Sao Tome and Principe", code: "ST" },
	{ name: "Saudi Arabia", code: "SA" },
	{ name: "Senegal", code: "SN" },
	{ name: "Serbia and Montenegro", code: "CS" },
	{ name: "Seychelles", code: "SC" },
	{ name: "Sierra Leone", code: "SL" },
	{ name: "Singapore", code: "SG" },
	{ name: "Slovakia", code: "SK" },
	{ name: "Slovenia", code: "SI" },
	{ name: "Solomon Islands", code: "SB" },
	{ name: "Somalia", code: "SO" },
	{ name: "South Africa", code: "ZA" },
	{ name: "South Georgia and the South Sandwich Islands", code: "GS" },
	{ name: "Spain", code: "ES" },
	{ name: "Sri Lanka", code: "LK" },
	{ name: "Sudan", code: "SD" },
	{ name: "Suriname", code: "SR" },
	{ name: "Svalbard and Jan Mayen", code: "SJ" },
	{ name: "Swaziland", code: "SZ" },
	{ name: "Sweden", code: "SE" },
	{ name: "Switzerland", code: "CH" },
	{ name: "Syrian Arab Republic", code: "SY" },
	{ name: "Taiwan, Province of China", code: "TW" },
	{ name: "Tajikistan", code: "TJ" },
	{ name: "Tanzania, United Republic of", code: "TZ" },
	{ name: "Thailand", code: "TH" },
	{ name: "Timor-Leste", code: "TL" },
	{ name: "Togo", code: "TG" },
	{ name: "Tokelau", code: "TK" },
	{ name: "Tonga", code: "TO" },
	{ name: "Trinidad and Tobago", code: "TT" },
	{ name: "Tunisia", code: "TN" },
	{ name: "Turkey", code: "TR" },
	{ name: "Turkmenistan", code: "TM" },
	{ name: "Turks and Caicos Islands", code: "TC" },
	{ name: "Tuvalu", code: "TV" },
	{ name: "Uganda", code: "UG" },
	{ name: "Ukraine", code: "UA" },
	{ name: "United Arab Emirates", code: "AE" },
	{ name: "United Kingdom", code: "GB" },
	{ name: "United States", code: "US" },
	{ name: "United States Minor Outlying Islands", code: "UM" },
	{ name: "Uruguay", code: "UY" },
	{ name: "Uzbekistan", code: "UZ" },
	{ name: "Vanuatu", code: "VU" },
	{ name: "Venezuela", code: "VE" },
	{ name: "Viet Nam", code: "VN" },
	{ name: "Virgin Islands, British", code: "VG" },
	{ name: "Virgin Islands, U.S.", code: "VI" },
	{ name: "Wallis and Futuna", code: "WF" },
	{ name: "Western Sahara", code: "EH" },
	{ name: "Yemen", code: "YE" },
	{ name: "Zambia", code: "ZM" },
	{ name: "Zimbabwe", code: "ZW" },
];

const defaultPatientData = {
	Personal: {
		FirstName: "",
		LastName: "",
		Email: "",
		HomePhone: "",
		HomePhoneExt: "",
		CellPhone: "",
		CellPhoneExt: "",
		WorkPhone: "",
		WorkPhoneExt: "",
		DateOfBirth: "",
		Address: {
			Country: "Canada",
			City: "",
			Province: "",
			PostalCode: "",
			StreetAddress1: "",
			StreetAddress2: "",
		},
		Gender: "Male",
		Handedness: -1,
		WeightLbs: 0,
		WeightKgs: 0,
		HeightFt: 0,
		HeightInch: 0,
		HeightMeter: 0,
		PersonalHealthNumber: "",
		Occupation: "",
		ReferredBy: "",
	},
	Medical: {
		EmergencyFirstName: "",
		EmergencyLastName: "",
		EmergencyPhone: "",
		EmergencyPhoneExt: "",
		EmergencyRelation: "",
		EmergencyEmail: "",
		PhysicianFirstName: "",
		PhysicianLastName: "",
		PhysicianPhone: "",
		PhysicianPhoneExt: "",
		PhysicianClinic: "",
		PhysicianEmail: "",
		PhysicianFax: "",
		PhysicianAddress: {
			Country: "",
			City: "",
			Province: "",
			PostalCode: "",
			StreetAddress1: "",
			StreetAddress2: "",
		},
		Injury: "",
	},
	Case: {
		ExpectedVisits: 12,
		IsNewPatient: true,
	},
};

const defaultCaseData = { businessId: "", patientId: "", expectedVisits: 1 };

const defaultIsNewPatientDataInValid = {
	firstName: [true, "first name is required"],
	lastName: [true, "last name is required"],
	// email: [true, "email is required"],
	email: [false, ""],
};

const defaultIsSelectedPatientDataInValid = {
	firstName: [true, "first name is required"],
	lastName: [true, "last name is required"],
	// email: [true, "email is required"],
	email: [false, ""],
};

const defaultIsNewPatientDataValidated = {
	firstName: false,
	lastName: false,
	email: false,
};

const newPatientDataAllValidated = {
	firstName: true,
	lastName: true,
	email: true,
};

const defaultIsSelectedPatientDataValidated = {
	firstName: false,
	lastName: false,
	email: false,
};

const selectedPatientDataAllValidated = {
	firstName: true,
	lastName: true,
	email: true,
};

const selectedPatientData = {
	personal: {
		firstName: "Ferdous",
		lastName: "Ahmed",
		email: "ferdous.ahmed1@yahoo.com",
		homePhone: "",
		homePhoneExt: "",
		cellPhone: "4036295755",
		cellPhoneExt: "",
		workPhone: "",
		workPhoneExt: "",
		dateOfBirth: {
			day: 2,
			month: 2,
			year: 1966,
		},
		address: {
			country: "Bangladesh",
			city: "Dhaka",
			province: "Dhaka",
			postalCode: "TT2",
			streetAddress1: "1005 A1",
			streetAddress2: "Street",
		},
		gender: "Male",
		handedness: 0,
		weightLbs: 0,
		weightKgs: 0,
		heightFt: 0,
		heightInch: 0,
		heightMeter: 0,
		personalHealthNumber: "",
		occupation: "",
		referredBy: "",
	},
	medical: {
		emergencyFirstName: "Steven",
		emergencyLastName: "Smith",
		emergencyPhone: "96666",
		emergencyPhoneExt: "",
		emergencyRelation: "",
		emergencyEmail: "",
		physicianFirstName: "",
		physicianLastName: "",
		physicianPhone: "",
		physicianPhoneExt: "",
		physicianClinic: "",
		physicianEmail: "",
		physicianFax: "",
		physicianAddress: {
			country: "Canada",
			city: "",
			province: "",
			postalCode: "",
			streetAddress1: "",
			streetAddress2: "",
		},
	},
};

const defaultDateFormat = {
	year: "numeric",
	month: "short",
	day: "numeric",
	hour: "numeric",
	minute: "numeric",
};

const shortDateFormat = {
	year: "numeric",
	month: "short",
	day: "numeric",
};

const defaultChartDateFormat = {
	year: "2-digit",
	month: "short",
	day: "numeric",
	weekday: "short",
};

const dateLocale = "en-Us";

var adjectives = [
	"adamant",
	"adroit",
	"amatory",
	"animistic",
	"antic",
	"arcadian",
	"baleful",
	"bellicose",
	"bilious",
	"boorish",
	"calamitous",
	"caustic",
	"cerulean",
	"comely",
	"concomitant",
	"contumacious",
	"corpulent",
	"crapulous",
	"defamatory",
	"didactic",
	"dilatory",
	"dowdy",
	"efficacious",
	"effulgent",
	"egregious",
	"endemic",
	"equanimous",
	"execrable",
	"fastidious",
	"feckless",
	"fecund",
	"friable",
	"fulsome",
	"garrulous",
	"guileless",
	"gustatory",
	"heuristic",
	"histrionic",
	"hubristic",
	"incendiary",
	"insidious",
	"insolent",
	"intransigent",
	"inveterate",
	"invidious",
	"irksome",
	"jejune",
	"jocular",
	"judicious",
	"lachrymose",
	"limpid",
	"loquacious",
	"luminous",
	"mannered",
	"mendacious",
	"meretricious",
	"minatory",
	"mordant",
	"munificent",
	"nefarious",
	"noxious",
	"obtuse",
	"parsimonious",
	"pendulous",
	"pernicious",
	"pervasive",
	"petulant",
	"platitudinous",
	"precipitate",
	"propitious",
	"puckish",
	"querulous",
	"quiescent",
	"rebarbative",
	"recalcitant",
	"redolent",
	"rhadamanthine",
	"risible",
	"ruminative",
	"sagacious",
	"salubrious",
	"sartorial",
	"sclerotic",
	"serpentine",
	"spasmodic",
	"strident",
	"taciturn",
	"tenacious",
	"tremulous",
	"trenchant",
	"turbulent",
	"turgid",
	"ubiquitous",
	"uxorious",
	"verdant",
	"voluble",
	"voracious",
	"wheedling",
	"withering",
	"zealous",
];

var nouns = [
	"ninja",
	"chair",
	"pancake",
	"statue",
	"unicorn",
	"rainbows",
	"laser",
	"senor",
	"bunny",
	"captain",
	"nibblets",
	"cupcake",
	"carrot",
	"gnomes",
	"glitter",
	"potato",
	"salad",
	"toejam",
	"curtains",
	"beets",
	"toilet",
	"exorcism",
	"stick figures",
	"mermaid eggs",
	"sea barnacles",
	"dragons",
	"jellybeans",
	"snakes",
	"dolls",
	"bushes",
	"cookies",
	"apples",
	"ice cream",
	"ukulele",
	"kazoo",
	"banjo",
	"opera singer",
	"circus",
	"trampoline",
	"carousel",
	"carnival",
	"locomotive",
	"hot air balloon",
	"praying mantis",
	"animator",
	"artisan",
	"artist",
	"colorist",
	"inker",
	"coppersmith",
	"director",
	"designer",
	"flatter",
	"stylist",
	"leadman",
	"limner",
	"make-up artist",
	"model",
	"musician",
	"penciller",
	"producer",
	"scenographer",
	"set decorator",
	"silversmith",
	"teacher",
	"auto mechanic",
	"beader",
	"bobbin boy",
	"clerk of the chapel",
	"filling station attendant",
	"foreman",
	"maintenance engineering",
	"mechanic",
	"miller",
	"moldmaker",
	"panel beater",
	"patternmaker",
	"plant operator",
	"plumber",
	"sawfiler",
	"shop foreman",
	"soaper",
	"stationary engineer",
	"wheelwright",
	"woodworkers",
];

const ValidateEmail = (email) => {
	// const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
	// return re.test(String(email).toLowerCase());
	return true;
};

const IsEmpty = (val) => {
	return val === undefined || val === null || val.length <= 0 ? true : false;
};

const AddHoursToDate = (str, h) => {
	var newDate = new Date(str);
	newDate.setTime(newDate.getTime() + h * 60 * 60 * 1000);
	return newDate.toLocaleString(dateLocale, defaultDateFormat);
};

const FormatNumberWithCommas = (x) => {
	return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

const CalculateDifferenceInDays = (d1, d2) => {
	// To calculate the time difference of two dates
	var Difference_In_Time = Math.abs(d1.getTime() - d2.getTime());

	// To calculate the no. of days between two dates
	var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);

	return Difference_In_Days;
};

const CalculateAge = (dateString) => {
	var today = new Date();
	var birthDate = new Date(dateString);
	var age = today.getFullYear() - birthDate.getFullYear();
	var m = today.getMonth() - birthDate.getMonth();
	if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
		age--;
	}
	return age;
};

const fqQuesOrder = [1, 10, 6, 11, 4, 15, 2, 14, 9, 13, 3, 12, 5, 7, 8, 16, 17];

const fqQuesList = [
	"How normally do you sleep?", // 1 - 1
	"How difficult is it for you to move up and down stairs?", // 2 - 7
	"How difficult is it for you to perform food preparation / cooking / eating?", // 3 - 11
	"How difficult is it for you to walk?", // 4 - 5
	"How difficult is it for you to groom (bath, comb, hair, shave, etc.)?", // 5 - 13
	"How difficult is it for you to get up or down from bed or chair?", // 6 - 3
	"How well do you manage normal dressing activities?", // 7 - 14
	"How well do you manage to tie shoes / button shirt?", // 8 - 15
	"How difficult is it for you to carry items up to 10 pounds?", // 9 - 9
	"How difficult is it for you to sit for normal periods of time?", // 10 - 2
	"How difficult is it for you to stand for normal periods of time?", // 11 - 4
	"How difficult is it for you to reach above head or across body?", // 12 - 12
	"How difficult is it for you to perform leisure / recreational / sports activities?", // 13 - 10
	"How difficult is it for you to squat down to pick up items?", // 14 - 8
	"How difficult is it for you to perform running / jogging?", // 15 - 6
	"How difficult is it for you to drive? ", // 16 - 16
	"How difficult is it for you to perform regular job related activities?", // 17 - 17
];

// -----------------------------------------------
// --------------- Previous / Original -----------
// const fqQuesListTemp = [
//   "Sleep normally",
//   "Up and Down Stairs",
//   "Food Prep/Cooking/Eating",
//   "Walking",
//   "Grooming (bath, comb hair, shave, etc)",
//   "Getting up/down from chair or bed",
//   "Dressing – manage normal dressing activities",
//   "Dressing – Tie Shoes/Button Shirt",
//   "Lifting/Carrying up to 10 pounds",
//   "Sitting for normal periods of time",
//   "Standing for normal periods of time",
//   "Reaching above head or across body",
//   "Leisure/Recreational/Sports Activities",
//   "Squatting down to pick up item",
//   "Running/Jogging",
//   "Driving",
//   "Job Requirements – can do all activities required of my job"
// ];
