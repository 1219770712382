import React, { useEffect } from 'react';
import {Container, Col, Row, Card, Button, Modal, Carousel, Image} from 'react-bootstrap';
import {Dropdown} from 'react-bootstrap';
import NumericInput from 'react-numeric-input';
import MediaQuery from 'react-responsive';

import "../App.css";
import "../resources/styles/product.scss";


import store from 'local-storage';

export default function ProductModal(props) {

  // const productType = (type) => {
  //   if (type === 'desktop') {
  //       return {number: 2, video: videoDesktop};
  //   } else if (type === 'pro') {
  //       return {number: 1, video: videoLite};
  //   } else if (type === 'lite') {
  //       return {number: 0, video: videoLite};
  //   }
  // }

  const state = props.state;
  const setState = props.setState;
  // const [state, setState] = useState({

  //   showCart: false,

  //   subscriptionButtonVisible: false,

  //   activeSubscription: 2,

  //   product_list : [
  //       {
  //           name : "Bodiometer " + "Lite",
  //           description: "Bodiometer Lite is a free iPhone app we launched as a quick response to COVID-19. It helps patients who are practicing social distancing track their posture and outcomes digitally and send the reports to their care providers. This app provides the basic functionality of Bodiometer Pro (without a subscription) which is the ability to rapidly take a posture scan, produce a report and show the report to the patient/practitioner. This app is a scaled-down beta prototype that can be used as a quick postural assessment tool for maintaining healthy body at home.",
  //           image: prodImgLite,
  //           quantity: 0,
  //           price_per_month: 100,
  //           price_per_year: 1200,
  //           sale_ongoing: false,
  //           sale_occasion: "Black Friday",
  //           sale_price_per_month: 100,
  //           sale_price_per_year: 1200,
  //           plan: 'Y',           // M: Monthly, Y: Yearly
  //           n_months: 12,        // number of months if subscription is selected
  //           version: 11,         // version keeps track of the product price (helps for better cache management)
  //       },
  //       {
  //           name : "Bodiometer " + "Pro",
  //           description: "As the world's first real-time posture assessment app, Bodiometer Pro enables clinicians to quickly and accurately assess patient posture without markers or other devices in almost any setting. Bodiometer utilizes an AI-driven computer vision system to automatically extract patient skeletal data relying only on the camera of the mobile device. Patient scans are saved in app, enabling users to track progress or changes in their posture over the course of treatment. Clinicians can compare scans directly with comparative reports. These visual reports are easy to print or share with the patient as a pdf. These reports makes a huge difference in patient education and engagement which means better outcomes for everyone.",
  //           image: prodImgMobile,
  //           quantity: 0,
  //           price_per_month: 65,
  //           price_per_year: 650,
  //           sale_ongoing: false,
  //           sale_occasion: "Christmas",
  //           sale_price_per_month: 52,
  //           sale_price_per_year: 520,
  //           plan: 'Y',          // M: Monthly, Y: Yearly
  //           n_months: 12,       // number of months if monthly subscription is selected
  //           version: 11,        // version keeps track of the product price (helps for better cache management)
  //       },
  //       {
  //           name : "Bodiometer " + "Desktop",
  //           description: "Bodiometer Desktop is our most advanced clinical biomechanics assessment tool. Using markerless motion-tracking, you can quickly and accurately measure range of motion, posture, and functional movement patterns. Our range of motion module allows you to measure 14 different joints in a variety of different movements. Our posture module allows you to quickly measure posture in the coronal, sagittal and traverse planes. Lastly, our functional movement module allows you to measure any kind of functional movement you can think of and compare a patient’s performance over time/  Bodiometer allows you to track patient data easily, and show a patient their progress with engaging and informative progress reports. Bodiometer gives you the tools to collect accurate, objective data quickly, and educate your patients about the structure of their bodies.",
  //           image: prodImgDesktop,
  //           quantity: 0,
  //           price_per_month: 160,
  //           price_per_year: 1600,
  //           sale_ongoing: false, // replace it with true during sale
  //           sale_occasion: "Christmas",
  //           sale_price_per_month: 128,
  //           sale_price_per_year: 1280,
  //           plan: 'Y',          // M: Monthly, Y: Yearly
  //           n_months: 12,       // number of months if monthly subscription is selected
  //           version: 11,         // version keeps track of the product price (helps for better cache management)
  //       }
  //   ], 
  //   currentProductId: productType(props.type).number,
  //   currentProductVersion: 11,

  //   currency: 'Canadian Dollar',
  //   currencyAbbr: 'CAD',
  //   currencySymbol: 'C$',

  //   encryptionKey: 'secured information of the bodiometer product page',

  // });



  const productQuantity = React.useRef();

  const EncryptedObject = (obj) => {
      // loading encrytion module
      var CryptoJS = require("crypto-js");
      var ciphertext = CryptoJS.AES.encrypt(JSON.stringify(obj), state.encryptionKey);
      return ciphertext.toString();
  }

  const DecryptObject = (obj) => {
      // loading decrytion module
      var CryptoJS = require("crypto-js");
      var bytes  = CryptoJS.AES.decrypt(obj, state.encryptionKey);
      var decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
      return decryptedData;
  }

  useEffect(() => {
      
      try{
          if(store.get('product_list')){
              let product_list = DecryptObject(store.get('product_list'));
              let updatedVersion = true;
              product_list.forEach((x) => {
                  if(x.hasOwnProperty('version')){
                      if(x.version < state.currentProductVersion){
                          updatedVersion = false;
                      }
                  }
                  else{
                      updatedVersion = false;
                  }
              });
              if(updatedVersion){
                  setState({
                      ...state,
                      product_list : DecryptObject(store.get('product_list')),
                  });
              }
              else{
                  store.set('product_list', EncryptedObject(state.product_list));
              }  

          }
          else{
              store.set('product_list', EncryptedObject(state.product_list));
          }
      }
      catch(err){
          store.set('product_list', EncryptedObject(state.product_list));
      }
      
  },[]);

  const RemoveProduct = (e) => {
      var prodList = state.product_list;
      const prodName = e.target.value;
      prodList.forEach((x) => {
              if(x.name === prodName){
                  x.quantity = 0;
              }
          }
      ) 
      
      store.set('product_list', EncryptedObject(prodList));
      setState({
          ...state,
          product_list: prodList
      });
      
  }

  const ResetProductQuantity = () => {
      var productQuantity = 0;
      productQuantity.current.setState({
          value: 0
      });

      let currentProductId = state.currentProductId;
      let currentProductList = state.product_list;
      currentProductList[currentProductId].quantity = productQuantity;
      
      setState({
          ...state,
          product_list: currentProductList
      });

      // store.set('product_list', state.product_list);
      store.set('product_list', EncryptedObject(state.product_list));
      
  }

  const AdjustQuantity = () => {
      let currentProductId = state.currentProductId;
      let currentProductList = state.product_list;
      currentProductList[currentProductId].quantity = productQuantity.current.state.value;
      setState({
          ...state,
          product_list: currentProductList
      });
      // store.set('product_list', state.product_list);
      store.set('product_list', EncryptedObject(state.product_list));
  }

  const ComputeTotal = () => {
      let sum = 0;
      state.product_list.forEach( (x) => {
          if(x.sale_ongoing){
              if(x.plan == 'M'){
                  let fractionSaved = 0;
                  if(x.n_months > 1){
                      fractionSaved = (x.sale_price_per_month * 12 - x.sale_price_per_year) * (x.n_months / 12) * x.quantity;
                  }
                  
                  sum = x.quantity * x.sale_price_per_month * x.n_months - fractionSaved + sum;
              }
              else{
                  sum = x.quantity * x.sale_price_per_year + sum;
              }

          }
          else{
              if(x.plan == 'M'){
                  let fractionSaved = 0;
                  if(x.n_months > 1){
                      fractionSaved = (x.price_per_month * 12 - x.price_per_year) * (x.n_months / 12) * x.quantity;
                  }
                  sum = x.quantity * x.price_per_month * x.n_months - fractionSaved + sum;
              }
              else{
                  sum = x.quantity * x.price_per_year + sum;
              }
              
          }
          
      });
      return sum;
      
  }

  const SelectSubscription = (val) => {
      
      let currentProductId = state.currentProductId;
      let currentProductList = state.product_list;
      if(val == '1'){
          currentProductList[currentProductId].plan = 'M';
          currentProductList[currentProductId].n_months = 1;
      }
      else if(val == '2'){
          currentProductList[currentProductId].plan = 'M';
          currentProductList[currentProductId].n_months = 3;
      }
      else if(val == '3'){
          currentProductList[currentProductId].plan = 'M';
          currentProductList[currentProductId].n_months = 6;
      }
      else if(val == '4'){
          currentProductList[currentProductId].plan = 'M';
          currentProductList[currentProductId].n_months = 9;
      }
      else{
          currentProductList[currentProductId].plan = 'Y';
          currentProductList[currentProductId].n_months = 12;
      }
      store.set('product_list', EncryptedObject(currentProductList));
      setState({
          ...state,
          product_list: currentProductList
      })
  }

  const SelectedSubscription = () => {
      if(state.product_list[state.currentProductId].plan === 'M'){
          if(state.product_list[state.currentProductId].n_months === 1){
              return state.product_list[state.currentProductId].n_months + " Month";
          }
          else{
              return state.product_list[state.currentProductId].n_months + " Months";
          }
          
      }
      else{
          return "Annual";
      }
  }

  const FeatureIcon = () => {
      return (
          <svg style={{width:'24px', height: '24px'}} viewBox="0 0 24 24">
              <path fill="#D50000" d="M12,5.32L18,8.69V15.31L12,18.68L6,15.31V8.69L12,5.32M21,16.5C21,16.88 20.79,17.21 20.47,17.38L12.57,21.82C12.41,21.94 12.21,22 12,22C11.79,22 11.59,21.94 11.43,21.82L3.53,17.38C3.21,17.21 3,16.88 3,16.5V7.5C3,7.12 3.21,6.79 3.53,6.62L11.43,2.18C11.59,2.06 11.79,2 12,2C12.21,2 12.41,2.06 12.57,2.18L20.47,6.62C20.79,6.79 21,7.12 21,7.5V16.5M12,4.15L5,8.09V15.91L12,19.85L19,15.91V8.09L12,4.15Z" />
          </svg>
      );
  }

  // Display product icons
  const TabletIcon = () => {
      return(
          <svg style={{width: '32px', height: '32px'}} viewBox="0 0 24 24">
              <path fill="#2a93d0" d="M19,19H4V3H19M11.5,23A1.5,1.5 0 0,1 10,21.5A1.5,1.5 0 0,1 11.5,20A1.5,1.5 0 0,1 13,21.5A1.5,1.5 0 0,1 11.5,23M18.5,0H4.5A2.5,2.5 0 0,0 2,2.5V21.5A2.5,2.5 0 0,0 4.5,24H18.5A2.5,2.5 0 0,0 21,21.5V2.5A2.5,2.5 0 0,0 18.5,0Z" />
          </svg>
      );
  }

  const DesktopIcon = () => {
      return(
          <svg style={{width: '32px', height: '32px'}} viewBox="0 0 24 24">
              <path fill="#2a93d0" d="M21,14H3V4H21M21,2H3C1.89,2 1,2.89 1,4V16A2,2 0 0,0 3,18H10L8,21V22H16V21L14,18H21A2,2 0 0,0 23,16V4C23,2.89 22.1,2 21,2Z" />
          </svg>
      );
  }

  const LiteIcon = () => {
      return(
          <svg style={{width: '32px', height: '32px'}} viewBox="0 0 24 24">
              <path fill="#2a93d0" d="M17,19H7V5H17M17,1H7C5.89,1 5,1.89 5,3V21A2,2 0 0,0 7,23H17A2,2 0 0,0 19,21V3C19,1.89 18.1,1 17,1Z" />
          </svg>
      );
  }

  // display or hide shopping cart
  const ShowCart = (e) => {
      var product_list = state.product_list;
      if(product_list[e.target.value].quantity === 0){
          product_list[e.target.value].quantity = 1;
      }
      setState({
          ...state,
          currentProductId: Number(e.target.value),
          showCart: true,
          product_list: product_list
      });

      store.set('product_list', EncryptedObject(state.product_list));
  }

  const HideCart = () => {
      let product_id = 0;
      switch(props.prodName){
          case 'desktop': {
              product_id = 2;
              break;
          }
          case 'pro': {
              product_id = 1;
              break;
          }
          case 'lite': {
              product_id = 0;
              break;
          }
      }
      setState({
          ...state,
          showCart: false,
          currentProductId: product_id
      });
  }

  const DisplaySubscriptionName = (x, ind) => {

      if(x.plan == 'M'){
          if(x.n_months == 1){
              return (
                  <>
                      Monthly License
                  </>
              );
          }
          else{
              return (
                  <>
                      {x.n_months} Months License
                  </>
              );
          }
      }
      else{
          return (
              <>
                  Annual License
              </>
          );
      }
  }

  const DisplaySubTotal = (x) => {
      
      if(x.sale_ongoing){
          if(x.plan == 'M'){
              let fractionSaved = 0;
              if(x.n_months > 1){
                  fractionSaved = (x.sale_price_per_month * 12 - x.sale_price_per_year) * (x.n_months / 12) * x.quantity;
              }
              return(
                  <>
                      {(x.sale_price_per_month * x.quantity * x.n_months - fractionSaved).toFixed(1)}
                  </>
              );
          }
          else{
              
              return(
                  <>
                      {(x.sale_price_per_year * x.quantity).toFixed(1)}
                  </>
              );
          }
          
      }
      else{
          if(x.plan == 'M'){
              let fractionSaved = 0;
              if(x.n_months > 1){
                  fractionSaved = (x.price_per_month * 12 - x.price_per_year) * (x.n_months / 12) * x.quantity;
              }
              else{
                  // fractionSaved = (x.price_per_month - x.sale_price_per_month) * x.quantity; 
              }
              return(
                  <>
                      {(x.price_per_month * x.quantity * x.n_months - fractionSaved).toFixed(1)}
                  </>
              );
          }
          else{
              return(
                  <>
                      {(x.price_per_year * x.quantity).toFixed(1)}
                  </>
              );
          }
      }
  }

  const DisplaySubTotalPerItem = (x) => {
      
      if(x.sale_ongoing){
          if(x.plan == 'M'){
              let fractionSaved = 0;
              if(x.n_months > 1){
                  fractionSaved = (x.sale_price_per_month * 12 - x.sale_price_per_year) * (x.n_months / 12);
              }
              return(
                  <>
                      {(x.sale_price_per_month * x.n_months - fractionSaved).toFixed(1)}
                  </>
              );
          }
          else{
              
              return(
                  <>
                      {(x.sale_price_per_year).toFixed(1)}
                  </>
              );
          }
          
      }
      else{
          if(x.plan == 'M'){
              let fractionSaved = 0;
              if(x.n_months > 1){
                  fractionSaved = (x.price_per_month * 12 - x.price_per_year) * (x.n_months / 12);
              }
              else{
                  fractionSaved = (x.price_per_month - x.sale_price_per_month); 
              }
              return(
                  <>
                      {(x.price_per_month * x.n_months - fractionSaved).toFixed(1)}
                  </>
              );
          }
          else{
              return(
                  <>
                      {(x.price_per_year).toFixed(1)}
                  </>
              );
          }
      }
  }

  const DisplaySavedAmount = (x) => {
      if(x.sale_ongoing){
          if(x.plan == 'M'){
              let fractionSaved = 0;
              if(x.n_months > 1){
                  fractionSaved = (x.price_per_month * 12 - x.sale_price_per_year) * (x.n_months / 12) * x.quantity;
              }
              else{
                  fractionSaved = (x.price_per_month - x.sale_price_per_month) * x.quantity; 
              }
              return(
                  <>
                      <div className="green-text">
                          You Saved&nbsp;
                          {(fractionSaved).toFixed(1)}&nbsp;
                          {state.currencyAbbr}
                      </div>

                      
                      <div>
                          ** Disclaimer: Promotion rate is only applied to the first 6 months.
                      </div>
                  </>
              );
          }
          else{
              let fractionSaved  = (x.price_per_month * 12 - x.sale_price_per_year) * x.quantity;
              
              return(
                  <>
                      <div className="green-text">
                          You Saved&nbsp;
                          {(fractionSaved).toFixed(1)}&nbsp;
                          {state.currencyAbbr}
                      </div>
                  </>
              );
          }
          
      }
      else{
          return(
              <>
              
              </>
          );
      }
  }

  const DisplayAdditionalSavedAmount = (x) => {
      if(x.sale_ongoing){
          if(x.plan == 'M'){
              let fractionSaved = 0;
              let fractionSavedOverall = 0;
              let fractionSavedSale = 0;
              if(x.n_months > 1){
                  fractionSavedOverall = (x.price_per_month * 12 - x.sale_price_per_year) * (x.n_months / 12) * x.quantity;
                  fractionSavedSale = (x.price_per_month * 12 - x.price_per_year) * (x.n_months / 12) * x.quantity;
              }
              else{
                  fractionSavedSale = (x.price_per_month - x.sale_price_per_month) * x.quantity;
              }
              fractionSaved = Math.abs(fractionSavedOverall - fractionSavedSale);
              return(
                  <>
                      <div className="green-text">
                          (You saved&nbsp;
                          {(fractionSaved).toFixed(1)}&nbsp;{state.currencyAbbr}&nbsp;
                          for Christmas discount)
                      </div>
                      <div>
                          ** Disclaimer: Promotion rate is only applied to the first 6 months.
                      </div>
                  </>
              );
          }
          else{
              let fractionSaved = 0;
              let fractionSavedOverall = 0;
              let fractionSavedSale = 0;
              fractionSavedOverall  = (x.price_per_month * 12 - x.sale_price_per_year) * x.quantity;
              fractionSavedSale  = (x.price_per_month * 12 - x.price_per_year) * x.quantity;
              fractionSaved = Math.abs(fractionSavedOverall - fractionSavedSale);

              return(
                  <>
                      <div className="green-text">
                          (Additionally,&nbsp;
                          {(fractionSaved).toFixed(1)}&nbsp;{state.currencyAbbr}&nbsp;
                          Christmas discount)
                      </div>
                  </>
              );
          }
          
      }
  }

  const DisplaySavedAmountPerMonth = (x, months) => {
      let savedAmount = (x.price_per_month * 12 - x.price_per_year) * (months / 12) * x.quantity;
      if(savedAmount > 0){
          return(
              <>
                  ({savedAmount.toFixed(1)}&nbsp;{state.currencyAbbr}&nbsp;saving)
              </>
          );
      }
      
  }

  const DisplaySavedAmountRegular = (x) => {
      let savedAmount = 0;
      if(x.plan == 'M'){
          savedAmount = 0;
      }
      else{
          savedAmount = (x.price_per_month * 12 - x.price_per_year) * x.quantity;
      }
      
      if(savedAmount > 0){
          return(
              <div className="green-text">
                  (You saved {savedAmount.toFixed(1)}&nbsp;{state.currencyAbbr})
              </div>
          );
      }
  }




              
              
  return(
    <Modal className="cart-modal" show={state.showCart} onEscapeKeyDown={HideCart} size={'xl'} centered={true}>
      <Modal.Body>
          <Card>
              <Card.Header>
                  <h2 className="blue-color">
                      {state.product_list[state.currentProductId].name}&nbsp;&nbsp;
                      {state.currentProductId == 0 ? LiteIcon(): ''}
                      {state.currentProductId == 1 ? TabletIcon(): ''}
                      {state.currentProductId == 2 ? DesktopIcon(): ''}
                  </h2>
                  
              </Card.Header>
              <Card.Body>

                  <Container fluid>
                      <Row>
                      <Col className="cart-col-left" md={6}>
                          <Row>
                              <Col>
                                  <h3>Number of Devices</h3>
                              </Col>
                          </Row>
                              
                          <Row className="numeric-input-wrapper">
                              <Col xs={8} md={6} lg={3}>
                                  <NumericInput className="form-control" min={0} max={100} value={state.product_list[state.currentProductId].quantity} step={1} ref={productQuantity} onChange={AdjustQuantity} />
                              </Col>
                          </Row>

                          <Row>
                              <Col>
                                  <h3>Subscription</h3>
                              </Col>
                          </Row>

                          <Row>
                              <Col xs={8} md={6} lg={4}>
                                  <Dropdown size="sm">
                                      <Dropdown.Toggle id="dropdown-custom-1">{SelectedSubscription()}</Dropdown.Toggle>
                                      <Dropdown.Menu variant="info">
                                          <Dropdown.Item onSelect={SelectSubscription} eventKey="1" active={(state.product_list[state.currentProductId].n_months == 1) ? true: false}>1 Month</Dropdown.Item>
                                          <Dropdown.Item onSelect={SelectSubscription} eventKey="5" active={(state.product_list[state.currentProductId].n_months == 12) ? true: false}>Annual (Two-months saving)</Dropdown.Item>
                                      </Dropdown.Menu>
                                  </Dropdown>
                                  
                              </Col>
                              
                          </Row>

                      
                          <Row className="subtotal-wrapper">
                              <Col>

                                  {state.product_list[state.currentProductId].sale_ongoing ? 
                                      <>
                                          <div>
                                              
                                              {(state.product_list[state.currentProductId].plan == 'M') ? 
                                                  <>
                                                      <h3>Subtotal ({state.currencyAbbr}): </h3>
                                                      <span className="old-price-text">
                                                          {(state.product_list[state.currentProductId].price_per_month * state.product_list[state.currentProductId].n_months * state.product_list[state.currentProductId].quantity).toFixed(1)} {state.currencyAbbr}
                                                      </span>
                                                      &nbsp; {DisplaySubTotalPerItem(state.product_list[state.currentProductId])} {state.currencyAbbr}
                                                      &nbsp;X&nbsp;
                                                      {state.product_list[state.currentProductId].quantity}
                                                      &nbsp;=&nbsp;
                                                      {DisplaySubTotal(state.product_list[state.currentProductId])} {state.currencyAbbr}
                                                      {DisplayAdditionalSavedAmount(state.product_list[state.currentProductId])}
                                                  </> 
                                                  : 
                                                  <>
                                                      <h3>Subtotal ({state.currencyAbbr}): </h3>
                                                      <span className="old-price-text">
                                                          {(state.product_list[state.currentProductId].price_per_month * state.product_list[state.currentProductId].n_months * state.product_list[state.currentProductId].quantity).toFixed(1)} {state.currencyAbbr}
                                                      </span>
                                                      &nbsp; {state.product_list[state.currentProductId].sale_price_per_year.toFixed(1)} {state.currencyAbbr}
                                                      &nbsp;X&nbsp;
                                                      {state.product_list[state.currentProductId].quantity}
                                                      &nbsp;=&nbsp;
                                                      {DisplaySubTotal(state.product_list[state.currentProductId])} {state.currencyAbbr}
                                                      {DisplaySavedAmountRegular(state.product_list[state.currentProductId])}
                                                      {DisplayAdditionalSavedAmount(state.product_list[state.currentProductId])}
                                                  </>
                                              } 

                                              
                                          </div>
                                      </>
                                  : 
                                      
                                      <>
                                      {
                                          (state.product_list[state.currentProductId].plan == 'M') ? 
                                          <>
                                              <h3>Subtotal ({state.currencyAbbr}): </h3>
                                              {DisplaySubTotal(state.product_list[state.currentProductId])} {state.currencyAbbr}
                                              &nbsp;X&nbsp;
                                              {state.product_list[state.currentProductId].quantity}
                                              &nbsp;=&nbsp;
                                              {DisplaySubTotal(state.product_list[state.currentProductId])} {state.currencyAbbr}
                                              {DisplaySavedAmountRegular(state.product_list[state.currentProductId])}
                                          </> 
                                          
                                          : 
                                          
                                          <>
                                              <h3>Subtotal ({state.currencyAbbr}): </h3>
                                              {(state.product_list[state.currentProductId].price_per_year).toFixed(1)} {state.currencyAbbr}
                                              &nbsp;X&nbsp;
                                              {state.product_list[state.currentProductId].quantity}
                                              &nbsp;=&nbsp;
                                              {(state.product_list[state.currentProductId].price_per_year * state.product_list[state.currentProductId].quantity).toFixed(1)} {state.currencyAbbr}
                                              {DisplaySavedAmountRegular(state.product_list[state.currentProductId])}
                                          </>
                                      }
                                      
                                      </>
                                  
                                  }

                              </Col>
                          </Row>
                      </Col>
                      <Col md={6} className="cart-col-right">
                          <Row>
                              <Col>
                                  <h3>Your Cart:</h3>
                              </Col>
                          </Row>
                          {
                              state.product_list.filter(x => x.quantity > 0).map((x, ind) => {
                                  return (
                                      <>
                                      <MediaQuery minWidth='600px'>
                                          <Row className="query-min-600" md={1} lg={2}>
                                              <Col xs={4}>
                                                  <Image src={x.image} width='128px' height='auto' fluid={true} alt={x.description} />
                                              </Col>
                                              <Col xs={8}>
                                                  <div><span>Product:</span> {x.name} - {DisplaySubscriptionName(x, ind)}</div>
                                                  <div><span>{DisplaySubscriptionName(x, ind)} Price:</span> {DisplaySubTotal(x)} {state.currencyAbbr}</div>
                                                  <div><span>Number of Devices:</span> {x.quantity}</div>
                                                  {DisplaySavedAmount(x)}
                                                  <Button variant="outline-danger" size='sm' value={x.name} onClick={RemoveProduct}>Remove</Button>
                                              </Col>
                                          </Row>
                                      </MediaQuery>

                                      <MediaQuery maxWidth='599px'>
                                          <Row className="query-max-599 top-row">
                                              <Col>
                                                  <Image src={x.image} width='128px' height='auto' fluid={true} alt={x.description} />
                                              </Col>
                                          </Row>
                                          <Row className="query-max-599 bottom-row">
                                              <Col>
                                                  <div><span>Product:</span> {x.name} - {DisplaySubscriptionName(x, ind)}</div>
                                                  <div><span>{DisplaySubscriptionName(x, ind)} Price:</span> {DisplaySubTotal(x)} &nbsp;{state.currencyAbbr}</div>
                                                  <div><span>Number of Devices:</span> {x.quantity}</div>
                                                  {DisplaySavedAmount(x)}
                                                  <Button variant="light" size='sm' value={x.name} onClick={RemoveProduct}>Remove</Button>
                                              </Col>
                                          </Row>
                                      </MediaQuery>
                                      </>
                                  ); 
                              })
                          }
                          
                          <Row className="cart-total-wrapper">
                              <Col>
                                  <h3>Total ({state.currencyAbbr}): </h3>
                                  {
                                      ComputeTotal().toFixed(1) 
                                  }  &nbsp;{state.currencyAbbr}
                              </Col>
                          </Row>
                      </Col>
                      </Row>
                  </Container>
              </Card.Body>
          </Card>
      </Modal.Body>    
      <Modal.Footer>
          <Button variant="dark" onClick={HideCart}>Close</Button>
          <Button variant="secondary" onClick={() => { window.location.replace("/checkout")}}>Checkout</Button>
      </Modal.Footer>
  </Modal>
  );

}