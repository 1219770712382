import React, { useEffect, Fragment, useState } from "react";
import { Alert, Row, Col, Popover, OverlayTrigger, Modal, Button, Spinner } from "react-bootstrap";
import { useAppContext, useUserDashboardContext } from "../lib/context.js";
import UIButton from "../components/dashboard/ui-button.js";
import axios from "axios";

import "bootstrap/dist/css/bootstrap.min.css";
import "../resources/styles/dashboard.scss";

import blankImage from "../resources/photos/blank.png";

const UserScansMain = ({ isPersonal, dbUrl }) => {
  const frontPostureMainCanvasRef = React.useRef();
  const sidePostureMainCanvasRef = React.useRef();
  const postureZoomedCanvasRef = React.useRef();

  const cancelToken = axios.CancelToken;

  const { appState } = useAppContext();
  const { dashboardState } = useUserDashboardContext();

  const userId = appState.userData.Id;
  const businessId = dashboardState.ActiveClinicId;
  const connections = appState.userData.PatientUserMerges;
  const patientId = !!businessId && businessId in connections ? connections[businessId].PatientId : "";

  const [state, setState] = useState({
    postureDb: {},
    postureIds: [],
    postureId: "",
    isPostureDbLoaded: false,
    isLoading: true,
    isRemovePostureConfirmationVisible: false,
    hasJoinedClinic: false,
    isPostureImageZoomed: false,
    zoomedViewDirection: "front",
  });

  const LoadPosture = async (tokens, patientId, businessId) => {
    try {
      setState({ ...state, isLoading: true });

      console.log('hello');

      let tokenCounter = 0;

      let configOne = {
        method: "POST",
        url: dbUrl + "/api/token/dbo",
        headers: {
          password: "746C419785D84DC8B3A20B9195363105",
        },
      };

      tokens.push(cancelToken.source());
      let responseOne = await axios(configOne, { timeout: 5000, cancelToken: tokens[tokenCounter].token });
      tokenCounter += 1;
      if (responseOne.data != "Invalid Credentials") {
        let configTwo = {
          method: "GET",
          url: isPersonal
            ? dbUrl + "/api/posturemeasurements/v3/userid"
            : dbUrl + "/api/posturemeasurements/v5/patientid",
          headers: {
            Authorization: "Bearer " + responseOne.data,
            "Content-Type": "application/json",
            [isPersonal ? "userId" : "patientId"]: isPersonal ? userId : patientId,
          },
        };

        tokens.push(cancelToken.source());
        let responseTwo = await axios(configTwo, { timeout: 5000, cancelToken: tokens[tokenCounter].token });
        tokenCounter += 1;
        if (responseTwo.data.success) {
          let newPostureDb = { ...responseTwo.data.data };
          let newPostureIds = Object.keys(newPostureDb);
          newPostureIds = !!businessId ? newPostureIds : newPostureIds.slice(0, 1);
          let newPostureId = newPostureIds.length > 0 ? newPostureIds[0] : "";

          if (!!newPostureId) {
            let configThree = {
              method: "get",
              url: dbUrl + "/api/storage/v1/image/posture/front/id/base64",
              headers: {
                Authorization: "Bearer " + responseOne.data,
                postureId: newPostureId,
              },
            };
            let configFour = {
              method: "get",
              url: dbUrl + "/api/storage/v1/image/posture/side/id/base64",
              headers: {
                Authorization: "Bearer " + responseOne.data,
                postureId: newPostureId,
              },
            };

            tokens.push(cancelToken.source());
            try {
              let responseThree = await axios(configThree, { timeout: 5000, cancelToken: tokens[tokenCounter].token });
              if (!!responseThree.data) {
                newPostureDb[newPostureId].FrontImage = responseThree.data;
              }
              tokenCounter += 1;
              tokens.push(cancelToken.source());
              let responseFour = await axios(configFour, { timeout: 5000, cancelToken: tokens[tokenCounter].token });
              tokenCounter += 1;
              if (!!responseFour.data) {
                newPostureDb[newPostureId].SideImage = responseFour.data;
              }
            } catch {
              // console.log("failed");
            }
          }

          setState({
            ...state,
            postureDb: newPostureDb,
            postureIds: newPostureIds,
            postureId: newPostureIds.length > 0 ? newPostureIds[0] : "",
            isLoading: false,
            isPostureDbLoaded: true,
          });

          DrawMainCanvas(newPostureId, newPostureDb);
        } else {
          setState({ ...state, isLoading: false, isPostureDbLoaded: false });
        }
      } else {
        setState({ ...state, isLoading: false, isPostureDbLoaded: false });
      }
    } catch {
      setState({ ...state, isLoading: false, isPostureDbLoaded: false });
    }
  };

  useEffect(() => {
    let tokens = [];
    LoadPosture(tokens, patientId, businessId);
    setState({
      ...state,
    });
    return () => {
      tokens.forEach((token, i) => {
        token.cancel(`request ${i + 1} cancelled`);
      });
    };
  }, [dashboardState.ActiveClinicId, appState.userData.PatientUserMerges]);

  const RefreshAndLoadImages = async (tokens) => {
    if (!!state.postureId && state.postureId in state.postureDb && !!!state.postureDb[state.postureId].FrontImage) {
      setState({ ...state, isLoading: true });
      let tokenCounter = 0;

      try {
        let configOne = {
          method: "POST",
          url: dbUrl + "/api/token/dbo",
          headers: {
            password: "746C419785D84DC8B3A20B9195363105",
          },
        };

        tokens.push(cancelToken.source());
        let responseOne = await axios(configOne, { timeout: 5000, cancelToken: tokens[tokenCounter].token });
        tokenCounter += 1;

        if (responseOne.data != "Invalid Credentials") {
          let newPostureDb = { ...state.postureDb };

          let configTwo = {
            method: "GET",
            url: dbUrl + "/api/storage/v1/image/posture/front/id/base64",
            headers: {
              Authorization: "Bearer " + responseOne.data,
              postureId: state.postureId,
            },
          };
          let configThree = {
            method: "GET",
            url: dbUrl + "/api/storage/v1/image/posture/side/id/base64",
            headers: {
              Authorization: "Bearer " + responseOne.data,
              postureId: state.postureId,
            },
          };

          tokens.push(cancelToken.source());
          let responseTwo = await axios(configTwo, { timeout: 5000, cancelToken: tokens[tokenCounter].token });
          if (!!responseTwo.data) {
            newPostureDb[state.postureId].FrontImage = responseTwo.data;
          }
          tokenCounter += 1;
          tokens.push(cancelToken.source());
          let responseThree = await axios(configThree, { timeout: 5000, cancelToken: tokens[tokenCounter].token });
          tokenCounter += 1;
          if (!!responseThree.data) {
            newPostureDb[state.postureId].SideImage = responseThree.data;
          }

          setState({
            ...state,
            postureDb: { ...newPostureDb },
            isLoading: false,
          });

          DrawMainCanvas(state.postureId, newPostureDb);
        } else {
          setState({ ...state, isLoading: false });
        }
      } catch {
        setState({ ...state, isLoading: false });
        DrawMainCanvas(state.postureId, state.postureDb);
      }
    } else if (
      !!state.postureId &&
      state.postureId in state.postureDb &&
      !!state.postureDb[state.postureId].FrontImage
    ) {
      DrawMainCanvas(state.postureId, state.postureDb);
    }
  };

  useEffect(() => {
    let tokens = [];

    RefreshAndLoadImages(tokens);

    return () => {
      tokens.forEach((token, i) => {
        token.cancel(`request ${i + 1} cancelled`);
      });
    };
  }, [state.postureId]);

  const DrawFrontViewSkeleton = (postureId, postureDb, context, config) => {
    try {
      let isFrontSkeletonValid =
        Object.keys(postureDb).length > 0 &&
        !IsEmpty(postureId) &&
        postureId in postureDb &&
        "FrontSkeleton" in postureDb[postureId] &&
        Object.keys(postureDb[postureId].FrontSkeleton).length > 0;

      if (isFrontSkeletonValid) {
        let maxWidth = config.maxWidth;
        let maxHeight = config.maxHeight;
        let pointWidth = maxWidth / 80;

        let canvasEllipse = (context, cx, cy, rx, ry, color) => {
          context.beginPath();

          context.ellipse(cx, cy, rx, ry, 0, 0, Math.PI * 2);
          context.fillStyle = color;
          context.fill();
        };

        let canvasLine = (context, x1, y1, x2, y2, color, w) => {
          context.beginPath();

          context.moveTo(x1, y1);
          context.lineTo(x2, y2);

          context.strokeStyle = color;
          context.lineWidth = w;
          context.stroke();
        };

        if (config.additionalMeasurements) {
          let leftAnkleX = postureDb[postureId].FrontSkeleton.LeftAnkle.X * maxWidth;
          let leftShoulderY = postureDb[postureId].FrontSkeleton.LeftShoulder.Y * maxHeight;
          let rightAnkleX = postureDb[postureId].FrontSkeleton.RightAnkle.X * maxWidth;
          let rightShoulderY = postureDb[postureId].FrontSkeleton.RightShoulder.Y * maxHeight;
          let midX = (leftAnkleX + rightAnkleX) / 2;
          let midY = (leftShoulderY + rightShoulderY) / 2;
          canvasLine(context, 0, maxHeight - midY, maxWidth, maxHeight - midY, "#45B349", maxWidth / 250);
          canvasLine(context, midX, 0, midX, maxHeight, "#45B349", maxWidth / 250);

          context.fillStyle = "white";
          context.font = `bold 20px "Rubik", sans-serif`;
          context.textAlign = "center";
          context.fillText(`FRONT POSTURE NUMBER`, maxWidth / 2, 50);

          context.fillStyle = "#2A93D0";
          context.font = `bold 32px "Rubik", sans-serif`;
          context.textAlign = "center";
          context.fillText(`${Math.abs(postureDb[postureId].FrontPostureNumber.toFixed(1))}`, maxWidth / 2, 100);
        }

        let dotLabels = [
          "LeftEye",
          "RightEye",
          "LeftShoulder",
          "RightShoulder",
          "LeftHip",
          "RightHip",
          "LeftKnee",
          "RightKnee",
          "LeftAnkle",
          "RightAnkle",
        ];

        let lineLabels = [
          ["LeftEye", "RightEye"],
          ["LeftShoulder", "RightShoulder"],
          ["LeftHip", "RightHip"],
          ["LeftKnee", "RightKnee"],
          ["LeftAnkle", "RightAnkle"],
        ];

        if (Object.keys(postureDb[postureId].FrontSkeleton).length > 0) {
          for (let i = 0; i < dotLabels.length; i++) {
            canvasEllipse(
              context,
              postureDb[postureId].FrontSkeleton[dotLabels[i]].X * maxWidth,
              maxHeight - postureDb[postureId].FrontSkeleton[dotLabels[i]].Y * maxHeight,
              pointWidth,
              pointWidth,
              "#76C344"
            );
          }

          for (let i = 0; i < lineLabels.length; i++) {
            let x1 = postureDb[postureId].FrontSkeleton[lineLabels[i][0]].X * maxWidth;
            let y1 = maxHeight - postureDb[postureId].FrontSkeleton[lineLabels[i][0]].Y * maxHeight;
            let x2 = postureDb[postureId].FrontSkeleton[lineLabels[i][1]].X * maxWidth;
            let y2 = maxHeight - postureDb[postureId].FrontSkeleton[lineLabels[i][1]].Y * maxHeight;
            canvasLine(context, x1, y1, x2, y2, "#3EABF7", maxWidth / 150);

            if (config.additionalMeasurements) {
              let midX = (x1 + x2) / 2;
              let midY = (y1 + y2) / 2;
              context.fillStyle = "white";
              // context.font = "20px bold Rubik";
              context.font = `bold 20px "Rubik", sans-serif`;
              if (lineLabels[i][0] == "LeftEye") {
                context.fillText(
                  `${Math.abs(postureDb[postureId].FrontHeadAngle.toFixed(1))}˚ ${
                    postureDb[postureId].FrontHeadAngle >= 0 ? "R" : "L"
                  }`,
                  40,
                  midY
                );
              } else if (lineLabels[i][0] == "LeftShoulder") {
                context.fillText(
                  `${Math.abs(postureDb[postureId].FrontShoulderAngle.toFixed(1))}˚ ${
                    postureDb[postureId].FrontShoulderAngle >= 0 ? "R" : "L"
                  }`,
                  40,
                  midY
                );
              } else if (lineLabels[i][0] == "LeftHip") {
                context.fillText(
                  `${Math.abs(postureDb[postureId].FrontHipAngle.toFixed(1))}˚ ${
                    postureDb[postureId].FrontHipAngle >= 0 ? "R" : "L"
                  }`,
                  40,
                  midY
                );
              } else if (lineLabels[i][0] == "LeftKnee") {
                context.fillText(
                  `${Math.abs(postureDb[postureId].FrontKneeAngle.toFixed(1))}˚ ${
                    postureDb[postureId].FrontKneeAngle >= 0 ? "R" : "L"
                  }`,
                  40,
                  midY
                );
              } else if (lineLabels[i][0] == "LeftAnkle") {
                context.fillText(
                  `${Math.abs(postureDb[postureId].FrontAnkleAngle.toFixed(1))}˚ ${
                    postureDb[postureId].FrontAnkleAngle >= 0 ? "R" : "L"
                  }`,
                  40,
                  midY
                );
              }
            }
          }
        }
      }
    } catch (error) {}
  };

  const DrawSideViewSkeleton = (postureId, postureDb, context, config) => {
    try {
      let isSideSkeletonValid =
        Object.keys(postureDb).length > 0 &&
        !IsEmpty(postureId) &&
        postureId in postureDb &&
        "SideSkeleton" in postureDb[postureId] &&
        Object.keys(postureDb[postureId].SideSkeleton).length > 0;
      if (isSideSkeletonValid) {
        let maxWidth = config.maxWidth;
        let maxHeight = config.maxHeight;
        let pointWidth = maxWidth / 80;

        let canvasEllipse = (context, cx, cy, rx, ry, color) => {
          context.beginPath();
          context.ellipse(cx, cy, rx, ry, 0, 0, Math.PI * 2);
          context.fillStyle = color;
          context.fill();
        };

        let canvasLine = (context, x1, y1, x2, y2, color, w) => {
          context.beginPath();
          context.moveTo(x1, y1);
          context.lineTo(x2, y2);
          context.strokeStyle = color;
          context.lineWidth = w;
          context.stroke();
        };

        if (config.additionalMeasurements) {
          let midX = maxWidth / 2;
          let rightAnkleX = postureDb[postureId].SideSkeleton.RightAnkle.X * maxWidth;
          canvasLine(context, rightAnkleX, 0, rightAnkleX, maxHeight, "#45B349", maxWidth / 250);

          context.fillStyle = "white";
          // context.font = "20px bold Rubik";
          context.font = `bold 20px "Rubik", sans-serif`;
          context.textAlign = "center";
          context.fillText(`SIDE POSTURE NUMBER`, midX, 50);

          context.fillStyle = "#2A93D0";
          // context.font = "32px bold Rubik";
          context.font = `bold 32px "Rubik", sans-serif`;
          context.textAlign = "center";
          context.fillText(`${Math.abs(postureDb[postureId].SidePostureNumber.toFixed(1))}`, midX, 100);
        }

        let dotLabels = ["RightEar", "RightShoulder", "RightHip", "RightKnee", "RightAnkle"];

        let lineLabels = [
          ["RightEar", "RightShoulder"],
          ["RightShoulder", "RightHip"],
          ["RightHip", "RightKnee"],
          ["RightKnee", "RightAnkle"],
        ];

        if (Object.keys(postureDb[postureId].SideSkeleton).length > 0) {
          for (let i = 0; i < dotLabels.length; i++) {
            canvasEllipse(
              context,
              postureDb[postureId].SideSkeleton[dotLabels[i]].X * maxWidth,
              maxHeight - postureDb[postureId].SideSkeleton[dotLabels[i]].Y * maxHeight,
              pointWidth,
              pointWidth,
              "#76C344"
            );
          }
        }

        for (let i = 0; i < lineLabels.length; i++) {
          let x1 = postureDb[postureId].SideSkeleton[lineLabels[i][0]].X * maxWidth;
          let y1 = maxHeight - postureDb[postureId].SideSkeleton[lineLabels[i][0]].Y * maxHeight;
          let x2 = postureDb[postureId].SideSkeleton[lineLabels[i][1]].X * maxWidth;
          let y2 = maxHeight - postureDb[postureId].SideSkeleton[lineLabels[i][1]].Y * maxHeight;
          canvasLine(context, x1, y1, x2, y2, "#3EABF7", maxWidth / 150);

          if (config.additionalMeasurements) {
            let midX = (x1 + x2) / 2;
            let midY = (y1 + y2) / 2;
            context.fillStyle = "white";
            // context.font = "20px bold Rubik";
            context.font = `bold 20px "Rubik", sans-serif`;
            if (lineLabels[i][0] == "RightEar") {
              context.fillText(
                `${Math.abs(postureDb[postureId].SideHeadDeviation.toFixed(1))}˚ ${
                  postureDb[postureId].SideHeadDeviation >= 0 ? "F" : "B"
                }`,
                40,
                y1
              );
            } else if (lineLabels[i][0] == "RightShoulder") {
              context.fillText(
                `${Math.abs(postureDb[postureId].SideShoulderDeviation.toFixed(1))}˚ ${
                  postureDb[postureId].SideShoulderDeviation >= 0 ? "F" : "B"
                }`,
                40,
                y1
              );
            } else if (lineLabels[i][0] == "RightHip") {
              context.fillText(
                `${Math.abs(postureDb[postureId].SideHipDeviation.toFixed(1))}˚ ${
                  postureDb[postureId].SideHipDeviation >= 0 ? "F" : "B"
                }`,
                40,
                y1
              );
            } else if (lineLabels[i][0] == "RightKnee") {
              context.fillText(
                `${Math.abs(postureDb[postureId].SideKneeDeviation.toFixed(1))}˚ ${
                  postureDb[postureId].SideKneeDeviation >= 0 ? "F" : "B"
                }`,
                40,
                y1
              );
            }
          }
        }
      }
    } catch (err) {}
  };

  const DrawMainCanvas = (postureId, postureDb) => {
    if (!!frontPostureMainCanvasRef.current) {
      let currentCanvasRef = frontPostureMainCanvasRef.current;
      currentCanvasRef.style.cursor = "pointer";
      let context = currentCanvasRef.getContext("2d");
      let image = new Image();
      image.src =
        !!postureId && Object.keys(postureDb).length > 0 && postureId in postureDb && !!postureDb[postureId].FrontImage
          ? postureDb[postureId].FrontImage
          : blankImage;
      image.onload = () => {
        let imgWidth = image.width;
        let imgHeight = image.height;
        let aspectRatio = imgWidth / imgHeight;
        let reducedHeight = 400;
        let reducedWidth = reducedHeight * aspectRatio;
        context.canvas.width = reducedWidth;
        context.canvas.height = reducedHeight;

        let elements = document.getElementsByClassName("posture-canvas-overlay");

        for (let i = 0; i < elements.length; i++) {
          elements[i].style.width = `${reducedWidth}px`;
          elements[i].style.height = `${reducedHeight}px`;
        }

        context.drawImage(image, 0, 0, reducedWidth, reducedHeight);
        DrawFrontViewSkeleton(postureId, postureDb, context, {
          maxWidth: reducedWidth,
          maxHeight: reducedHeight,
          additionalMeasurements: false,
        });
      };
    }
    if (!!sidePostureMainCanvasRef.current) {
      let currentCanvasRef = sidePostureMainCanvasRef.current;
      currentCanvasRef.style.cursor = "pointer";
      let context = currentCanvasRef.getContext("2d");
      let image = new Image();
      image.src =
        !!postureId && Object.keys(postureDb).length > 0 && postureId in postureDb && !!postureDb[postureId].SideImage
          ? postureDb[postureId].SideImage
          : blankImage;

      image.onload = () => {
        let imgWidth = image.width;
        let imgHeight = image.height;
        let aspectRatio = imgWidth / imgHeight;
        let reducedHeight = 400;
        let reducedWidth = reducedHeight * aspectRatio;
        context.canvas.width = reducedWidth;
        context.canvas.height = reducedHeight;

        let elements = document.getElementsByClassName("posture-canvas-overlay");

        for (let i = 0; i < elements.length; i++) {
          elements[i].style.width = `${reducedWidth}px`;
          elements[i].style.height = `${reducedHeight}px`;
        }

        context.drawImage(image, 0, 0, reducedWidth, reducedHeight);
        DrawSideViewSkeleton(postureId, postureDb, context, {
          maxWidth: reducedWidth,
          maxHeight: reducedHeight,
          additionalMeasurements: false,
        });
      };
    }
  };

  const DrawZoomedCanvas = (postureId, postureDb) => {
    if (!!postureZoomedCanvasRef.current) {
      let currentCanvasRef = postureZoomedCanvasRef.current;
      currentCanvasRef.style.cursor = "pointer";
      let context = currentCanvasRef.getContext("2d");
      let image = new Image();
      if (state.zoomedViewDirection == "front") {
        image.src =
          !!postureId && Object.keys(postureDb).length > 0 && postureId in postureDb && postureDb[postureId].FrontImage
            ? postureDb[postureId].FrontImage
            : blankImage;
      } else {
        image.src =
          !!postureId && Object.keys(postureDb).length > 0 && postureId in postureDb && postureDb[postureId].FrontImage
            ? postureDb[postureId].SideImage
            : blankImage;
      }
      image.onload = () => {
        let imgWidth = image.width;
        let imgHeight = image.height;
        let aspectRatio = imgWidth / imgHeight;
        let reducedHeight = 1024;
        let reducedWidth = reducedHeight * aspectRatio;
        context.canvas.width = reducedWidth;
        context.canvas.height = reducedHeight;

        let elements = document.getElementsByClassName("posture-canvas-overlay");

        for (let i = 0; i < elements.length; i++) {
          elements[i].style.width = `${reducedWidth}px`;
          elements[i].style.height = `${reducedHeight}px`;
        }

        context.drawImage(image, 0, 0, reducedWidth, reducedHeight);
        if (state.zoomedViewDirection == "front") {
          DrawFrontViewSkeleton(postureId, postureDb, context, {
            maxWidth: reducedWidth,
            maxHeight: reducedHeight,
            additionalMeasurements: true,
          });
        } else {
          DrawSideViewSkeleton(postureId, postureDb, context, {
            maxWidth: reducedWidth,
            maxHeight: reducedHeight,
            additionalMeasurements: true,
          });
        }
      };
    }
  };

  const RenderScanDateSelectionOverlay = (postureDb, postureId) => {
    return (
      <Popover
        className="posture-date-overlay-wrapper"
        style={{ backgroundColor: "#707070" }}
        id={`popover${Math.floor(Math.random()).toString()}`}
      >
        <Popover.Content>
          <div>
            <ul>
              {state.postureIds.map((key) => {
                return (
                  <li key={key}>
                    <UIButton
                      className={postureId == key ? "active-date" : "white-link"}
                      onClick={() => {
                        setState({ ...state, postureId: key });
                        // LoadPosture(postureDb, key)
                      }}
                    >
                      {AddHoursToDate(postureDb[key].CreatedAt, postureDb[key].CreatedAtOffset)}
                      {postureId == key ? <img src="../photos/icons/dashboard/SVG/check-green.svg" width="15" /> : null}
                    </UIButton>
                  </li>
                );
              })}
            </ul>
          </div>
        </Popover.Content>
      </Popover>
    );
  };

  const RemovePosture = async (postureId) => {
    try {
      setState({ ...state, isLoading: true });

      let configOne = {
        method: "post",
        url: dbUrl + "/api/token/dbo",
        headers: {
          password: "746C419785D84DC8B3A20B9195363105",
        },
      };

      let responseOne = await axios(configOne, { timeout: 5000 });
      if (responseOne.data != "Invalid Credentials") {
        let configTwo = {
          method: "DELETE",
          url: dbUrl + "/api/posturemeasurements/v1/remove/postureid",
          headers: {
            Authorization: `Bearer ${responseOne.data}`,
            postureId: postureId,
            "Content-Type": "application/json",
          },
        };
        let responseTwo = await axios(configTwo, { timeout: 5000 });
        if (responseTwo.data.success) {
          let newPostureDb = { ...state.postureDb };
          delete newPostureDb[postureId];
          let findIndex = state.postureIds.findIndex((id) => id === postureId);
          let newPostureIds = state.postureIds.filter((id) => id !== postureId);
          let newPostureId =
            findIndex < newPostureIds.length
              ? newPostureIds[findIndex]
              : newPostureIds.length >= 1
              ? newPostureIds[0]
              : "";

          setState({
            ...state,
            postureDb: newPostureDb,
            postureIds: newPostureIds,
            postureId: newPostureId,
            isLoading: false,
          });
        } else {
          setState({ ...state, isLoading: false });
        }
      } else {
        setState({ ...state, isLoading: false });
      }
    } catch {
      setState({ ...state, isLoading: false });
    }
  };

  const RemovePostureConfirmationOverlay = () => {
    return (
      <Popover
        className="action-toggle-wrapper roles-toggle-wrapper"
        id={`popover${Math.floor(Math.random()).toString()}`}
      >
        <Popover.Content>
          <Row>
            <Col>
              <h3 className="green-color">Are you sure you want to remove this posture?</h3>
            </Col>
          </Row>
          <Row>
            <Col>
              <Button
                style={{ background: "#45b249", marginRight: "5px" }}
                onClick={(e) => {
                  RemovePosture(state.postureId);
                }}
              >
                Yes
              </Button>
              <Button
                style={{ background: "#6c7d85" }}
                onClick={(e) => setState({ ...state, isRemovePostureConfirmationVisible: false })}
              >
                No
              </Button>
            </Col>
          </Row>
        </Popover.Content>
      </Popover>
    );
  };

  return (
    <Fragment>
      <Modal
        className="zoomed-posture-modal"
        size="md"
        show={state.isPostureImageZoomed}
        onExit={() => {
          // DrawMainCanvas(state.postureId, state.postureDb);
          DrawMainCanvas(state.postureId, state.postureDb);
        }}
        onHide={() => {
          // setIsPostureImageZoomed(false);
          setState({ ...state, isPostureImageZoomed: false });
        }}
        onShow={() => {
          // DrawZoomedCanvas(state.postureId, state.postureDb);
          DrawZoomedCanvas(state.postureId, state.postureDb);
        }}
      >
        <Modal.Header closeButton>{/* <Modal.Title>Modal title</Modal.Title> */}</Modal.Header>
        <Modal.Body>
          <Row>
            <Col>
              <canvas
                ref={postureZoomedCanvasRef}
                style={{ borderRadius: "20px", fontFamily: `"Rubik", sans-serif` }}
                className="zoomed-posture-canvas"
              />
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
      {state.isLoading ? (
        <div className="preloader-wrapper">
          <Spinner animation="grow" variant="secondary" />
        </div>
      ) : (
        <Fragment>
          {!!!isPersonal ? (
            !!!businessId ? (
              <section style={{ padding: "5px 20px" }}>Please, connect to a clinic first.</section>
            ) : Object.keys(state.postureDb).length <= 0 ? (
              <section style={{ padding: "5px 20px" }}>no scans available.</section>
            ) : null
          ) : Object.keys(state.postureDb).length <= 0 ? (
            <section style={{ padding: "5px 20px" }}>no scans available.</section>
          ) : null}

          {state.isPostureDbLoaded &&
            Object.keys(state.postureDb).length > 0 &&
            !!state.postureId &&
            state.postureId in state.postureDb && (
              <Fragment>
                <section className="module-date-selection">
                  <Row>
                    <Col>
                      <div>
                        {AddHoursToDate(
                          state.postureDb[state.postureId]?.CreatedAt,
                          state.postureDb[state.postureId]?.CreatedAtOffset
                        )}
                      </div>
                      <OverlayTrigger
                        key={`id-overlay-dates`}
                        rootClose={true}
                        trigger="click"
                        placement="right"
                        overlay={RenderScanDateSelectionOverlay(state.postureDb, state.postureId)}
                      >
                        <div style={{ cursor: "pointer", color: "#2A92CF" }}>Change</div>
                      </OverlayTrigger>
                    </Col>
                  </Row>
                </section>
                <section className="posture-individual-scan-container">
                  <Row style={{ marginTop: "2vh", marginBottom: "2vh", display: "flex", alignItems: "flex-start" }}>
                    <Col>
                      <div className="posture-canvas-overlay">
                        <canvas
                          ref={frontPostureMainCanvasRef}
                          style={{ borderRadius: "20px", cursor: "pointer" }}
                          onClick={() => {
                            setState({
                              ...state,
                              isPostureImageZoomed: true,
                              zoomedViewDirection: "front",
                            });
                          }}
                        />
                      </div>
                    </Col>
                    <Col className="posture-score">
                      <h3>
                        FRONT POSTURE SCORE:{" "}
                        <span style={{ color: "#2A92CF" }}>
                          {state.postureDb[state.postureId].FrontPostureNumber.toFixed(1)}
                        </span>
                      </h3>
                      <div>
                        Head:{" "}
                        <span style={{ color: "#2A92CF" }}>
                          {Math.abs(state.postureDb[state.postureId].FrontHeadAngle).toFixed(1)}°
                        </span>{" "}
                        lower on the{" "}
                        <span style={{ color: "#2A92CF" }}>
                          {state.postureDb[state.postureId].FrontHeadAngle >= 0 ? "right" : "left"}
                        </span>
                      </div>
                      <div>
                        Shoulder:{" "}
                        <span style={{ color: "#2A92CF" }}>
                          {Math.abs(state.postureDb[state.postureId].FrontShoulderAngle).toFixed(1)}°
                        </span>{" "}
                        lower on the{" "}
                        <span style={{ color: "#2A92CF" }}>
                          {state.postureDb[state.postureId].FrontShoulderAngle >= 0 ? "right" : "left"}
                        </span>
                      </div>
                      <div>
                        Hip:{" "}
                        <span style={{ color: "#2A92CF" }}>
                          {Math.abs(state.postureDb[state.postureId].FrontHipAngle).toFixed(1)}°
                        </span>{" "}
                        lower on the{" "}
                        <span style={{ color: "#2A92CF" }}>
                          {state.postureDb[state.postureId].FrontHipAngle >= 0 ? "right" : "left"}
                        </span>
                      </div>
                      <div>
                        Knee:{" "}
                        <span style={{ color: "#2A92CF" }}>
                          {Math.abs(state.postureDb[state.postureId].FrontKneeAngle).toFixed(1)}°
                        </span>{" "}
                        lower on the{" "}
                        <span style={{ color: "#2A92CF" }}>
                          {state.postureDb[state.postureId].FrontKneeAngle >= 0 ? "right" : "left"}
                        </span>
                      </div>
                      <div>
                        Ankle:{" "}
                        <span style={{ color: "#2A92CF" }}>
                          {Math.abs(state.postureDb[state.postureId].FrontAnkleAngle).toFixed(1)}°
                        </span>{" "}
                        lower on the{" "}
                        <span style={{ color: "#2A92CF" }}>
                          {state.postureDb[state.postureId].FrontAnkleAngle >= 0 ? "right" : "left"}
                        </span>
                      </div>
                      <div className="posture-score-highlight">
                        <h4>
                          FRONT POSTURE SCORE <br />{" "}
                          <span style={{ color: "#2A92CF", fontSize: "130%" }}>
                            {state.postureDb[state.postureId].FrontPostureNumber.toFixed(1)}
                          </span>
                        </h4>
                      </div>
                    </Col>
                  </Row>
                  <Row style={{ marginTop: "2vh", marginBottom: "2vh", display: "flex", alignItems: "flex-start" }}>
                    <Col>
                      <div className="posture-canvas-overlay">
                        <canvas
                          ref={sidePostureMainCanvasRef}
                          style={{ borderRadius: "20px", cursor: "pointer" }}
                          onClick={() => {
                            setState({
                              ...state,
                              isPostureImageZoomed: true,
                              zoomedViewDirection: "side",
                            });
                          }}
                        />
                      </div>
                    </Col>
                    <Col className="posture-score">
                      <h3>
                        SIDE POSTURE SCORE:{" "}
                        <span style={{ color: "#2A92CF" }}>
                          {state.postureDb[state.postureId].SidePostureNumber.toFixed(1)}
                        </span>
                      </h3>
                      <div>
                        Head:{" "}
                        <span style={{ color: "#2A92CF" }}>
                          {Math.abs(state.postureDb[state.postureId].SideHeadDeviation).toFixed(1)}°
                        </span>{" "}
                        {state.postureDb[state.postureId].SideHeadDeviation < 0 ? "backward" : "forward"} from shoulder.
                      </div>
                      <div>
                        Shoulder:{" "}
                        <span style={{ color: "#2A92CF" }}>
                          {Math.abs(state.postureDb[state.postureId].SideShoulderDeviation).toFixed(1)}°
                        </span>{" "}
                        {state.postureDb[state.postureId].SideShoulderDeviation < 0 ? "backward" : "forward"} from hip.
                      </div>
                      <div>
                        Hip:{" "}
                        <span style={{ color: "#2A92CF" }}>
                          {Math.abs(state.postureDb[state.postureId].SideHipDeviation).toFixed(1)}°
                        </span>{" "}
                        {state.postureDb[state.postureId].SideHipDeviation < 0 ? "backward" : "forward"} from knee.
                      </div>
                      <div>
                        Knee:{" "}
                        <span style={{ color: "#2A92CF" }}>
                          {Math.abs(state.postureDb[state.postureId].SideKneeDeviation).toFixed(1)}°
                        </span>{" "}
                        {state.postureDb[state.postureId].SideKneeDeviation < 0 ? "backward" : "forward"} from ankle.
                      </div>
                      <div className="posture-score-highlight">
                        <h4>
                          SIDE POSTURE SCORE <br />{" "}
                          <span style={{ color: "#2A92CF", fontSize: "130%" }}>
                            {state.postureDb[state.postureId].SidePostureNumber.toFixed(1)}
                          </span>
                        </h4>
                      </div>
                    </Col>
                  </Row>

                  <Row style={{ marginTop: "2vh", marginBottom: "2vh" }}>
                    <Col>
                      <div className="posture-score-highlight">
                        <h4>
                          TOTAL POSTURE SCORE{" "}
                          <span style={{ color: "#2A92CF", fontSize: "130%", marginLeft: "10px" }}>
                            {state.postureDb[state.postureId].GlobalPostureNumber.toFixed(1)}
                          </span>
                        </h4>
                      </div>
                    </Col>
                  </Row>

                  <Row style={{ marginTop: "2vh", marginBottom: "2vh" }}>
                    <Col>
                      <div className="pain-slider-container">
                        <span>PAIN SCORE</span>
                        <span>
                          <input
                            type="range"
                            min="0"
                            max="10"
                            value={
                              !!state.postureDb[state.postureId].PainScore
                                ? state.postureDb[state.postureId].PainScore
                                : 0
                            }
                            className="pain-slider"
                            id="id-pain-score"
                            disabled
                          />
                        </span>
                        <span style={styles.textStyle}>
                          {!!state.postureDb[state.postureId].PainScore
                            ? state.postureDb[state.postureId].PainScore
                            : 0}
                        </span>
                      </div>
                    </Col>
                  </Row>

                  {!!state.postureDb[state.postureId].PainType && (
                    <Row style={{ marginTop: "2vh", marginBottom: "2vh" }}>
                      <Col>
                        <div className="pain-slider-container">
                          <span>PAIN TYPE </span>
                          {state.postureDb[state.postureId].PainType.toLowerCase() == "dull" && (
                            <span style={styles.textStyle}>Dull</span>
                          )}
                          {state.postureDb[state.postureId].PainType.toLowerCase() == "sharp" && (
                            <span style={styles.textStyle}>Sharp</span>
                          )}
                          {state.postureDb[state.postureId].PainType.toLowerCase() == "stabbing" && (
                            <span style={styles.textStyle}>Stabbing</span>
                          )}
                          {state.postureDb[state.postureId].PainType.toLowerCase() == "tingling" && (
                            <span style={styles.textStyle}>Tingling</span>
                          )}
                          {state.postureDb[state.postureId].PainType.toLowerCase() == "burning" && (
                            <span style={styles.textStyle}>Burning</span>
                          )}
                          {state.postureDb[state.postureId].PainType.toLowerCase() == "cramping" && (
                            <span style={styles.textStyle}>Cramping</span>
                          )}
                          {state.postureDb[state.postureId].PainType.toLowerCase() == "numb" && (
                            <span style={styles.textStyle}>Numb</span>
                          )}
                          {!!!state.postureDb[state.postureId].PainType && <span style={styles.textStyle}>--</span>}
                        </div>
                      </Col>
                    </Row>
                  )}
                  {!!!state.postureDb[state.postureId].PainType && (
                    <Row style={{ marginTop: "2vh", marginBottom: "2vh" }}>
                      <Col>
                        <div className="pain-slider-container" style={{ alignItems: "center" }}>
                          <span>PAIN TYPE </span>
                          <span style={styles.textStyle}>NA</span>
                        </div>
                      </Col>
                    </Row>
                  )}
                </section>

                {isPersonal && (
                  <section style={{ padding: "20px" }}>
                    <Row>
                      <Col style={{ textAlign: "center" }}>
                        <OverlayTrigger
                          rootClose={true}
                          show={state.isRemoveFQConfirmationOpened}
                          trigger="click"
                          placement="top"
                          overlay={RemovePostureConfirmationOverlay()}
                        >
                          <Button
                            variant="danger"
                            onClick={() => {
                              setState({ ...state, isRemovePostureConfirmationOpened: true });
                            }}
                          >
                            Remove
                          </Button>
                        </OverlayTrigger>
                      </Col>
                    </Row>
                  </section>
                )}
              </Fragment>
            )}
        </Fragment>
      )}
    </Fragment>
  );
};

export default UserScansMain;

const AddHoursToDate = (str, h) => {
  let newDate = new Date(str);
  newDate.setTime(newDate.getTime() + h * 60 * 60 * 1000);
  return newDate.toLocaleString(dateLocale, defaultDateFormat);
};

const IsEmpty = (val) => {
  return val === undefined || val == null || val.length <= 0 ? true : false;
};

const defaultDateFormat = {
  year: "numeric",
  month: "short",
  day: "numeric",
  hour: "numeric",
  minute: "numeric",
};

const dateLocale = "en-Us";

const styles = {
  textStyle: {
    background: "var(--primary-green)",
    color: "white",
    cursor: "pointer",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "5px",
    textAlign: "center",
    padding: "5px",
  },
};
