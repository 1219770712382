import React, {useState, useEffect, useMemo} from "react";
import {Col, Row, Form, Alert} from "react-bootstrap";
import axios from "axios";
import countryList from "react-select-country-list";

import formatPhone from "../../resources/helpers/format-phone.js";
import DisplayPhone from "../../resources/helpers/display-phone.js";
import UiButton from "./ui-button.js";

import "bootstrap/dist/css/bootstrap.min.css";

export default function EditOrganization(props) {
  const [state, setState] = useState({
    Error: [false, ""],
    Success: [false, ""],
    EditFormValidated: false,
    PhoneInValid: [false, ""],
    FaxInValid: [false, ""],
    Different: false,
    Type: props.organization.type,
    isOtherTypeVisible: false,
    Name: props.organization.name,
    Phone: props.organization.phone,
    Fax: props.organization.fax,
    Address: props.organization.address,
    City: props.organization.city,
    Province: props.organization.province,
    Country: props.organization.country,
    Postal: props.organization.postal,
    Email: props.organization.email,
    Logo: props.parentState.ActiveOrganizationLogo ? ["Current Logo", props.parentState.ActiveOrganizationLogo] : ["Upload Logo (Optional)", null],
  });

  const Initial = {
    Error: [false, ""],
    Success: [false, ""],
    EditFormValidated: false,
    PhoneInValid: [false, ""],
    FaxInValid: [false, ""],
    Different: false,
    Type: props.organization.type,
    Name: props.organization.name,
    Phone: props.organization.phone,
    Fax: props.organization.fax,
    Address: props.organization.address,
    City: props.organization.city,
    Province: props.organization.province,
    Country: props.organization.country,
    Postal: props.organization.postal,
    Email: props.organization.email,
    Logo: props.parentState.ActiveOrganizationLogo ? ["Current Logo", props.parentState.ActiveOrganizationLogo] : ["Upload Logo (Optional)", null],
  };

  // hide success/error alert after 4 seconds
  useEffect(() => {
    const timer = setTimeout(() => {
      setState(prev => ({...prev, Success: [false, ""], Error: [false, ""]}))
    }, 4000);
    return () => clearTimeout(timer);
  }, [state.Success, state.Error]);

  const HandleSubmit = (e) => {
    e.preventDefault();

    props.setState({...props.parentState, Preloader: true}); 

    let promiseList = [];

    const PostData = {
      businessDetail: {
        businessName: state.Name,
        businessType: state.Type,
        email: state.Email,
        emailActivated: state.Email === props.parentState.ActiveOrganization.email ? props.parentState.ActiveOrganization.emailActivated : false,
        phoneNumber: formatPhone(state.Phone),
        fax: formatPhone(state.Fax),
      },
      businessAddress: {
        country: state.Country,
        city: state.City,
        province: state.Province,
        streetAddress1: state.Address,
        postalCode: state.Postal,
      },
    };

    console.log(props.parentState.ActiveOrganization.id, props.parentState);

    const PostHeaders = {
      headers: {
        Authorization: props.parentState.DBAccessToken,
        "Content-Type": "application/json",
        businessId: props.parentState.ActiveOrganization.id
      },
    };

    promiseList.push(
      axios
        .put(
          props.dbUrl + "/api/businessaccounts/v1/update/businessid",
          {...PostData},
          {
            headers: {...PostHeaders.headers},
            timeout: 10000,
          }
        )
        .then((response) => response)
        .catch((err) => err)
    );

    if (state.Logo[0] === "Upload Logo (Optional)") {
      promiseList.push(
        axios(
          {
            method: "DELETE",
            url: props.dbUrl + "/api/storage/v1/image/remove/ba/logo/id",
            headers: {
              Authorization: props.parentState.DBAccessToken,
              "Content-Type": "application/json",
              businessId: props.parentState.ActiveOrganization.id,
              "Access-Control-Allow-Origin": "*",
            },
          },
          {timeout: 10000}
        )
          .then((response) => response)
          .catch((err) => err)
      );
    } else {
      if (state.Logo[0] === "Current Logo") {
        promiseList.push(
          // axios(
          //   {
          //     method: "POST",
          //     url: props.dbUrl + "/api/storage/v1/image/create/ba/logo/id/base64",
          //     headers: {
          //       Authorization: props.parentState.DBAccessToken,
          //       "Content-Type": "application/json",
          //       businessId: props.parentState.ActiveOrganization.id,
          //       quality: 100,
          //       "Access-Control-Allow-Origin": "*",
          //       imageStr: state.Logo[1],
          //     },
          //   },
          //   {timeout: 10000}
          // )
          //   .then((response) => response)
          //   .catch((err) => err)
          Promise.resolve({
            status: 200,
            data: {
              success: true,
            },
          }).then((response) => response)
        );
      } else {
        // const formData2 = new FormData();
        // formData2.append("imageFile", state.Logo[1]);
        promiseList.push(
          axios(
            {
              method: "POST",
              url: props.dbUrl + "/api/storage/v1/image/create/ba/logo/id/base64",
              data: {imageStr: state.Logo[1]},
              headers: {
                Authorization: props.parentState.DBAccessToken,
                "Content-Type": "application/json",
                businessId: props.parentState.ActiveOrganization.id,
                quality: 100,
                "Access-Control-Allow-Origin": "*",
              },
            },
            {timeout: 10000}
          )
            .then((response) => response)
            .catch((err) => err)
        );
      }
    }

    Promise.allSettled(promiseList)
      .then((arrayValues) => {
        try {
          if (arrayValues[0].value.status === 200 && arrayValues[1].value.status === 200) {
            let responseOne = arrayValues[0].value.data;
            let responseTwo = arrayValues[1].value.data;
            if (responseOne.success && responseTwo.success) {
              let tempState = {
                ...props.parentState,
                ActiveOrganizationLogo: state.Logo[1],
                // Refresh: props.parentState.Refresh + 1,
                Preloader: false,
              };
              tempState.ActiveOrganization = {
                ...tempState.ActiveOrganization,
                type: state.Type,
                name: state.Name,
                phone: state.Phone,
                fax: state.Fax,
                address: state.Address,
                city: state.City,
                province: state.Province,
                country: state.Country,
                postal: state.Postal,
                email: state.Email,
              };

              props.setState(tempState);
              setState({...state, Success: [true, "Organization updated successfully"], Different: false});
            } else {
              props.setState({...props.parentState, Preloader: false}); // stop preloader
              setState({...state, Success: [false, "Something went wrong"]});
            }
          }
        } catch {
          props.setState({...props.parentState, Preloader: false}); // stop preloader
          setState({...state, Success: [false, "Something went wrong"]});
        }
      })
      .catch((err) => {
        props.setState({...props.parentState, Preloader: false}); // stop preloader
        setState({...state, Success: [false, "Something went wrong"]});
      });

    
  };

  const CheckOtherTypes = (allTypes, type) => {
    let tempTypes = allTypes.filter((key) => {
      if (allTypes[key] != "Other") {
        return true;
      }
      return false;
    });
    return tempTypes.indexOf(type) >= 0 ? false : true;
  };

  useEffect(() => {
    setState({...state, isOtherTypeVisible: CheckOtherTypes(props.types, state.Type)});
  }, []);

  

  const ResendEmailConfirmation = () => {
    // send email confirmation email
    const confirmationPostData = {
      businessId: props.parentState.ActiveOrganization.id,
    };

    const consfirmationPostHeaders = {
      headers: {
        Authorization: props.parentState.DBAccessToken,
        "Content-Type": "application/json",
      },
    };

    axios.post(props.dbUrl + "/api/email/v2/businessaccounts/confirmation", confirmationPostData, consfirmationPostHeaders).then((res) => {
      // email sent
      setState({...state, Success: [true, "Email has been sent, please check your inbox"]});
    });
  };

  const SetThing = (thing, e) => {
    return setState({...state, [thing]: e.target.value, Different: true});
  };

  const DeleteAccount = () => {
    props.setState({...props.parentState, Preloader: true}); // start preloader

    const PostHeaders = {
      headers: {
        Authorization: props.parentState.DBAccessToken,
        businessId: props.organization.id,
      },
    };

    axios.delete(props.dbUrl + "/api/businessaccounts/v1/remove/businessid", PostHeaders)
    .then((res) => {
      props.setState({...props.parentState, Preloader: false}); // end preloader
      if (res.data.success) {
        props.setState((prev) => {
          return {...props.parentState, View: "overview"};
        });
      } else {
        setState({...state, Error: [true, "Oops, Something went wrong.."]});
      }
    });
  };

  const CheckValid = (thing, value) => {
    if (thing === "Phone") {
      if (value.length == 0) {
        setState({...state, PhoneInValid: [true, "Invalid Number"]});
      } else {
        setState({...state, PhoneInValid: [false, ""]});
      }
    }

    // if (thing === "Fax") {
    //   if (value.length !== 0) {
    //     setState({...state, FaxInValid: [true, "Invalid Number"]});
    //   } else {
    //     setState({...state, FaxInValid: [false, ""]});
    //   }
    // }
  };

  const Countries = useMemo(() => countryList().getLabels(), []);

  return (
    <div className="ui-section-wrapper">
      <section>
        <Row>
          <Col className="flex-row no-wrap">
            <h1>Update Organization</h1>
            <Alert variant="warning" show={state.Error[0] ? true : false}>
              <div className="flex-row">
                <p>{state.Error[1]}</p>
              </div>
            </Alert>
            <Alert variant="info" show={state.Success[0] ? true : false}>
              <div className="flex-row">
                <p>{state.Success[1]}</p>
              </div>
            </Alert>
          </Col>
        </Row>
      </section>
      <section className="form-section-wrapper">
        <Row>
          <Col className="landing-form-wrapper create-org-wrapper" xl={7} lg={8} md={12}>
            <Form validated={state.Validated}>
              <Row>
                <Form.Group as={Col} md={6} sm={12} controlId="create-organization_name">
                  <Form.Label>Name:</Form.Label>
                  <Form.Control size="sm" type="input" value={state.Name} onChange={(e) => SetThing("Name", e)} placeholder="Name" required />
                </Form.Group>

                <Form.Group as={Col} md={6} sm={12} controlId="register_title" lg={6}>
                  <Form.Label>Type:</Form.Label>

                  {
                    <Form.Control
                      size="sm"
                      as="select"
                      onChange={(e) => {
                        // SetThing("Type", e);
                        if (e.target.value === "Other") {
                          setState({...state, isOtherTypeVisible: true});
                        } else {
                          setState({...state, isOtherTypeVisible: false, Type: e.target.value});
                        }
                      }}
                      custom
                      className="ui-select"
                    >
                      {props.types.map((type) => (
                        <option selected={type === state.Type || (type === "Other" && CheckOtherTypes(props.types, state.Type))} value={type}>
                          {type}
                        </option>
                      ))}
                    </Form.Control>
                  }
                </Form.Group>
              </Row>

              {state.isOtherTypeVisible && (
                <Row>
                  <Form.Group as={Col} controlId="register_type_other">
                    <Form.Control size="sm" type="input" defaultValue={CheckOtherTypes(props.types, state.Type) ? state.Type : ""} onChange={(e) => SetThing("Type", e)} placeholder="Organization Type" required />
                  </Form.Group>
                </Row>
              )}

              <Row>
                <Form.Group as={Col} md={6} sm={12} controlId="update-organization_phone">
                  <Form.Label>Phone:</Form.Label>
                  <Form.Control size="sm" type="phone" isInvalid={state.PhoneInValid[0]} onBlur={(e) => CheckValid("Phone", e.currentTarget.value)} value={DisplayPhone(state.Phone)} onChange={(e) => SetThing("Phone", e)} placeholder="Phone" required />
                  <Form.Control.Feedback type="invalid">{state.PhoneInValid[1]}</Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} md={6} sm={12} controlId="update-organization_fax">
                  <Form.Label>Fax:</Form.Label>
                  <Form.Control size="sm" type="input" isInvalid={state.FaxInValid[0]} onBlur={(e) => CheckValid("Fax", e.currentTarget.value)} value={DisplayPhone(state.Fax)} onChange={(e) => SetThing("Fax", e)} placeholder="Fax" required />
                  <Form.Control.Feedback type="invalid">{state.FaxInValid[1]}</Form.Control.Feedback>
                </Form.Group>
              </Row>

              <div className="inline-button-input">
                <Form.Group as={Col} sm={12} md={12} controlId="update-organization_address">
                  <Form.Label>Email{props.parentState.ActiveOrganization.emailActivated ? <span className="green-color"> (Verified)</span> : " (Not Verified)"}:</Form.Label>
                  <Form.Control size="sm" type="input" value={state.Email} onChange={(e) => SetThing("Email", e)} placeholder="Email" required />
                  {!props.parentState.ActiveOrganization.emailActivated && (
                    <UiButton variant="primary" onClick={(e) => ResendEmailConfirmation()} size="sm" className="input-inline-btn">
                      Resend
                    </UiButton>
                  )}
                </Form.Group>
              </div>

              <Row>
                <Form.Group as={Col} sm={12} controlId="update-organization_address">
                  <Form.Label>Address:</Form.Label>
                  <Form.Control size="sm" type="input" value={state.Address} onChange={(e) => SetThing("Address", e)} placeholder="Address" required />
                </Form.Group>
              </Row>

              <Row>
                <Form.Group as={Col} md={6} sm={12} controlId="update-organization_city">
                  <Form.Label>City:</Form.Label>
                  <Form.Control size="sm" type="input" value={state.City} onChange={(e) => SetThing("City", e)} placeholder="City" required />
                </Form.Group>
                <Form.Group as={Col} md={6} sm={12} controlId="update-organization_province">
                  <Form.Label>Province:</Form.Label>
                  <Form.Control size="sm" type="input" value={state.Province} onChange={(e) => SetThing("Province", e)} placeholder="Province/State" required />
                </Form.Group>
              </Row>

              <Row>
                <Form.Group as={Col} md={6} sm={12} controlId="update-organization_postal">
                  <Form.Label>Postal:</Form.Label>
                  <Form.Control size="sm" type="input" value={state.Postal} onChange={(e) => SetThing("Postal", e)} placeholder="Postal/Zip" required />
                </Form.Group>
                <Form.Group as={Col} md={6} sm={12} controlId="update_organization_country">
                  <Form.Label>Country:</Form.Label>
                  <Form.Control size="sm" as="select" options={Countries} placeholder="Country" custom className="ui-select" value={state.Country.toLowerCase()} onChange={(e) => SetThing("Country", e)} required>
                    <option value="">Country</option>
                    {Countries.map((country) => (
                      <option value={country.toLowerCase()} selected={country.toLowerCase() === state.Country.toLowerCase()}>
                        {country}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>
              </Row>

              <Row>
                <Form.Group as={Col} sm={12} controlId="update-organization_logo" className="upload-wrapper">
                  {/* <Form.File id="formcheck-api-custom" custom value={state.Logo} onChange={e => setState({...state, Logo: e.target.files[0].name})}> */}
                  <Form.File id="formcheck-api-custom" custom>
                    {/* <Form.File.Input isValid={(state.Logo[0] !== 'Upload Logo (Optional)' && state.Logo[0] !== 'Current Logo') ? true : false} onChange={e => setState({...state, Logo: [e.target.files[0].name, e.target.files[0]], Different: true})}/> */}
                    <Form.File.Input
                      onChange={(e) => {
                        const file = e.target.files[0];
                        const reader = new FileReader();
                        reader.addEventListener(
                          "load",
                          function () {
                            setState({...state, Logo: [file.name, reader.result], Different: true});
                          },
                          false
                        );

                        if (file) {
                          reader.readAsDataURL(file);
                        }

                        // setState({...state, Logo: [e.target.files[0].name, e.target.files[0]], Different: true});
                      }}
                    />
                    <Form.File.Label data-browse={props.parentState.ActiveOrganizationLogo ? "Change Image" : "Choose Image"}>{state.Logo[0]}</Form.File.Label>
                    {/* <Form.Control.Feedback type="valid">Logo uploaded!</Form.Control.Feedback> */}
                  </Form.File>
                </Form.Group>
              </Row>
              {state.Logo[1] && (
                <Row>
                  <Col className="logo-preview">
                    <div>
                      {/* <img src={state.Logo[0] === "Current Logo" ? state.Logo[1] : URL.createObjectURL(state.Logo[1])} height="60" /> */}
                      <img src={state.Logo[1]} height="60" alt="organization logo" />
                      <span className="remove-image" onClick={(e) => setState({...state, Logo: ["Upload Logo (Optional)", ""], Different: true})}>
                        Remove
                      </span>
                    </div>
                  </Col>
                </Row>
              )}

              <Row>
                <Form.Group controlId="consent" className="form-footer-wrapper">
                  <UiButton type="submit" variant="secondary" onClick={(e) => HandleSubmit(e)}>
                    Save
                  </UiButton>
                </Form.Group>

                <Form.Group controlId="consent" className="form-footer-wrapper">
                  <UiButton onClick={(e) => setState({...Initial})} variant="link" className="gray-color no-padding" visibility={state.Different ? false : true}>
                    Cancel
                  </UiButton>
                </Form.Group>

                {/* <Form.Group controlId="consent" className="form-footer-wrapper delete-user-wrapper">
                  <UiButton
                  onClick={e => DeleteAccount()}
                  variant="danger"
                  size="sm"
                  >
                    Delete Account
                  </UiButton>
                </Form.Group> */}
              </Row>
            </Form>
          </Col>
        </Row>
      </section>
    </div>
  );
}
