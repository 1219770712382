import React from 'react';
import { FormCheck }  from 'react-bootstrap';


import "../../resources/styles/dashboard.scss";
import "bootstrap/dist/css/bootstrap.min.css";

export default function UiSwitch(props) {

  return(
    <FormCheck
      custom
      type="switch"
      id={`custom-switch-${Math.floor(Math.random()).toString()}`}
      className={`ui-switch ${props.className ? props.className : ''}`}
      
    >
      {props.falseLabel && <p onClick={e => props.onClick()}>{props.falseLabel}</p>}
      <FormCheck.Input checked={props.checked} />
      <FormCheck.Label onClick={e => props.onClick()}>
        <p>{props.label}</p>
      </FormCheck.Label>
    </FormCheck>
  );
};