import React, { useState, Fragment, useEffect } from "react";
import { Col, Row, Form } from "react-bootstrap";


import "bootstrap/dist/css/bootstrap.min.css";
import "react-phone-number-input/style.css";
import "react-datetime/css/react-datetime.css";
import MinusImg from "../Assets/minus-circle-solid.svg";
import PlusImg from "../Assets/plus-circle-solid.svg";
import "../Assets/style.scss";

const HealthReport = ({ data, screeningData, validatedDb, isValidDb, pageCompleted, setPageCompleted, activeIndex, viewInvalidFields, onChange }) => {
	const [validatedFields, setValidatedFields] = useState({ ...validatedDb });
	const [isValidFields, setIsValidFields] = useState({ ...isValidDb });

	const Header = ({ label }) => {
		return <h2 style={{ textAlign: "center", marginBottom: "20px" }}>{label}</h2>;
	};

	const [isGeneralVisible, setIsGeneralVisible] = useState(true);
	const [isNeuroSystemVisible, setIsNeuroSystemVisible] = useState(true);
	const [isNeuroHeadNeckVisible, setIsNeuroHeadNeckVisible] = useState(screeningData.Headache || screeningData.JawPain || screeningData.NeckPainStiffness);
	const [isNeuroShoulderVisible, setIsNeuroShoulderVisible] = useState(screeningData.ShoulderPain);
	const [isNeuroMidBackVisible, setIsNeuroMidBackVisible] = useState(screeningData.MidBackPain);
	const [isNeuroLowBackVisible, setIsNeuroLowBackVisible] = useState(screeningData.LowBackPain || screeningData.TailbonePain);
	const [isNeuroArmsAndHandsVisible, setIsNeuroArmsAndHandsVisible] = useState(screeningData.NumbnessArmsHands && screeningData.ElbowWristHandPain);
	const [isNeuroHipsLegsVisible, setIsNeuroHipsLegsVisible] = useState(screeningData.HipKneeAnklePain && screeningData.NumbnessLegsFeet);

	const GeneralIds = [
		["GeneralDiabetesPrev", "GeneralDiabetesPres"],
		["GeneralHeartPrev", "GeneralHeartPres"],
		["GeneralCancerPrev", "GeneralCancerPres"],
		["GeneralHypertensionPrev", "GeneralHypertensionPres"],
	];

	const GeneralLabels = ["Diabetes", "Heart Disease", "Cancer", "Hypertension"];

	const HeadAndNeckIds = [
		["NeuromusculoskeletalSystemsHeadNeckHeadachePrev", "NeuromusculoskeletalSystemsHeadNeckHeadachePres"],
		["NeuromusculoskeletalSystemsHeadNeckNeckPainPrev", "NeuromusculoskeletalSystemsHeadNeckNeckPainPres"],
		["NeuromusculoskeletalSystemsHeadNeckPinchedNervePrev", "NeuromusculoskeletalSystemsHeadNeckPinchedNervePres"],
		["NeuromusculoskeletalSystemsHeadNeckJawPainPrev", "NeuromusculoskeletalSystemsHeadNeckJawPainPres"],
		["NeuromusculoskeletalSystemsHeadNeckArthritisNeckPrev", "NeuromusculoskeletalSystemsHeadNeckArthritisNeckPres"],
	];
	const HeadAndNeckLabels = ["Headaches", "Neck Pain / Stiffness", "Pinched Nerve", "Jaw Pain / TMJ", "Arthritis in Neck"];

	const ShoulderIds = [
		["NeuromusculoskeletalSystemsShoulderShoulderPainPrev", "NeuromusculoskeletalSystemsShoulderShoulderPainPres"],
		["NeuromusculoskeletalSystemsShoulderRaiseArmPrev", "NeuromusculoskeletalSystemsShoulderRaiseArmPres"],
		["NeuromusculoskeletalSystemsShoulderArthritisPrev", "NeuromusculoskeletalSystemsShoulderArthritisPres"],
		["NeuromusculoskeletalSystemsShoulderBursitisPrev", "NeuromusculoskeletalSystemsShoulderBursitisPres"],
		["NeuromusculoskeletalSystemsShoulderPoppingPrev", "NeuromusculoskeletalSystemsShoulderPoppingPres"],
	];
	const ShoulderLabels = ["Shoulder Pain", "Can't Raise Arm", "Arthritis", "Bursitis", "Clicking / Popping"];

	const MidBackIds = [
		["NeuromusculoskeletalSystemsMidBackMidBackPainPrev", "NeuromusculoskeletalSystemsMidBackMidBackPainPres"],
		["NeuromusculoskeletalSystemsMidBackPainBetweenShouldersPrev", "NeuromusculoskeletalSystemsMidBackPainBetweenShouldersPres"],
	];
	const MidBackLabels = ["Mid-Back Pain", "Pain Between Shoulders"];

	const LowBackIds = [
		["NeuromusculoskeletalSystemsLowBackLowBackPainPrev", "NeuromusculoskeletalSystemsLowBackLowBackPainPres"],
		["NeuromusculoskeletalSystemsLowBackLowBackStiffnessPrev", "NeuromusculoskeletalSystemsLowBackLowBackStiffnessPres"],
		["NeuromusculoskeletalSystemsLowBackTailbonePainPrev", "NeuromusculoskeletalSystemsLowBackTailbonePainPres"],
		["NeuromusculoskeletalSystemsLowBackMuscleSpasmsPrev", "NeuromusculoskeletalSystemsLowBackMuscleSpasmsPres"],
		["NeuromusculoskeletalSystemsLowBackPinchedNervePrev", "NeuromusculoskeletalSystemsLowBackPinchedNervePres"],
	];
	const LowBackLabels = ["Low Back Pain", "Low Back Stiffness", "Tailbone Pain", "Muscle Spasms", "Pinched Nerve"];

	const ArmsHandsIds = [
		["NeuromusculoskeletalSystemsArmsHandsElbowArmPainPrev", "NeuromusculoskeletalSystemsArmsHandsElbowArmPainPres"],
		["NeuromusculoskeletalSystemsArmsHandsForearmPainPrev", "NeuromusculoskeletalSystemsArmsHandsForearmPainPres"],
		["NeuromusculoskeletalSystemsArmsHandsHandsFingersPainPrev", "NeuromusculoskeletalSystemsArmsHandsHandsFingersPainPres"],
		["NeuromusculoskeletalSystemsArmsHandsArthritisSwollenHandsPrev", "NeuromusculoskeletalSystemsArmsHandsArthritisSwollenHandsPres"],
		["NeuromusculoskeletalSystemsArmsHandsColdHandsPrev", "NeuromusculoskeletalSystemsArmsHandsColdHandsPres"],
		["NeuromusculoskeletalSystemsArmsHandsCarpalTunnelPrev", "NeuromusculoskeletalSystemsArmsHandsCarpalTunnelPres"],
		["NeuromusculoskeletalSystemsArmsHandsHandsTinglingPrev", "NeuromusculoskeletalSystemsArmsHandsHandsTinglingPres"],
		["NeuromusculoskeletalSystemsArmsHandsLossOfGripPrev", "NeuromusculoskeletalSystemsArmsHandsLossOfGripPres"],
	];
	const ArmsHandsLabels = [
		"Pain in Elbow / Arm",
		"Pain in Forearm",
		"Pain in Hands / Fingers",
		"Arthritis / Swollen Hands",
		"Cold Hands",
		"Carpal Tunnel",
		"Tingling in Hands",
		"Loss of Grip Strength",
	];

	const HipsLegsIds = [
		["NeuromusculoskeletalSystemsHipsLegsFeetButtockPainPrev", "NeuromusculoskeletalSystemsHipsLegsFeetButtockPainPres"],
		["NeuromusculoskeletalSystemsHipsLegsFeetHipKneePainPrev", "NeuromusculoskeletalSystemsHipsLegsFeetHipKneePainPres"],
		["NeuromusculoskeletalSystemsHipsLegsFeetColdFeetPrev", "NeuromusculoskeletalSystemsHipsLegsFeetColdFeetPres"],
		["NeuromusculoskeletalSystemsHipsLegsFeetTinglingFeetPrev", "NeuromusculoskeletalSystemsHipsLegsFeetTinglingFeetPres"],
		["NeuromusculoskeletalSystemsHipsLegsFeetArthritisPrev", "NeuromusculoskeletalSystemsHipsLegsFeetArthritisPres"],
		["NeuromusculoskeletalSystemsHipsLegsFeetSprainStrainPrev", "NeuromusculoskeletalSystemsHipsLegsFeetSprainStrainPres"],
		["NeuromusculoskeletalSystemsHipsLegsFeetLegPainPrev", "NeuromusculoskeletalSystemsHipsLegsFeetLegPainPres"],
		["NeuromusculoskeletalSystemsHipsLegsFeetTinglingLegPrev", "NeuromusculoskeletalSystemsHipsLegsFeetTinglingLegPres"],
		["NeuromusculoskeletalSystemsHipsLegsFeetSwollenAnkleFeetPrev", "NeuromusculoskeletalSystemsHipsLegsFeetSwollenAnkleFeetPres"],
		["NeuromusculoskeletalSystemsHipsLegsFeetAnkleFeetPainPrev", "NeuromusculoskeletalSystemsHipsLegsFeetAnkleFeetPainPres"],
		["NeuromusculoskeletalSystemsHipsLegsFeetLossOfLegStrengthPrev", "NeuromusculoskeletalSystemsHipsLegsFeetLossOfLegStrengthPres"],
		["NeuromusculoskeletalSystemsHipsLegsFeetMuscleCrampPrev", "NeuromusculoskeletalSystemsHipsLegsFeetMuscleCrampPres"],
	];
	const HipsLegsLabels = [
		"Pain in Buttock",
		"Pain in Hip / Knee Joint",
		"Cold Feet",
		"Tingling in Feet",
		"Arthritis",
		"Sprain / Strain",
		"Pain Down Leg(s)",
		"Tingling in Leg(s)",
		"Swollen Ankle / Feet",
		"Ankle / Feet Pain",
		"Loss of Leg Strength",
		"Muscles Cramps",
	];

	const RenderChecklistRow = ({ Ids, Labels, leftOffset = "50px" }) => {
		return (
			<Fragment>
				{Ids.map((item, k) => {
					return (
						<Col md={6} style={{ paddingLeft: leftOffset, display: "flex", alignItems: "center", marginBottom: "5px" }}>
							{data[item[0]] ? (
								<span class="dot" onClick={() => onChange({ ...data, [item[0]]: false })}></span>
							) : (
								<span class="dot-outline" onClick={() => onChange({ ...data, [item[0]]: true })}></span>
							)}
							{data[item[1]] ? (
								<span class="square" onClick={() => onChange({ ...data, [item[1]]: false })}></span>
							) : (
								<span class="square-outline" onClick={() => onChange({ ...data, [item[1]]: true })}></span>
							)}
							{Labels[k]}
						</Col>
					);
				})}
			</Fragment>
		);
	};

	const CheckIfFieldsAreValid = () => {
		var isValid = true;
		return isValid;
	};

	const InitialFieldsValidity = () => {
		var cIsValid = { ...isValidFields };
		return cIsValid;
	};

	useEffect(() => {
		setIsValidFields({ ...InitialFieldsValidity() });
	}, []);

	useEffect(() => {
		const iterate = (obj) => {
			Object.keys(obj).forEach((key) => {
				if (typeof obj[key] === "object" && obj[key] !== null) {
					iterate(obj[key]);
				} else {
					obj[key] = true;
				}
			});
		};
		if (viewInvalidFields) {
			window.scrollTo({
				top: "0",
				behavior: "smooth",
			});
			var newObj = { ...validatedFields };
			iterate(newObj);
			setValidatedFields({ ...newObj });
		}
	}, [viewInvalidFields]);

	useEffect(() => {
		pageCompleted[activeIndex] = CheckIfFieldsAreValid();
		setPageCompleted([...pageCompleted]);
	}, [isValidFields]);

	return (
		<div className="intake" style={styles.screen}>
			<Header label={"Health Questionnaire"} />

			<Row>
				<Col>
					<Form.Label style={{ fontWeight: "bold", paddingLeft: "20px" }}>
						Please check <span class="dot"></span> for previously had and check <span class="square"></span> for presently have
					</Form.Label>
				</Col>
			</Row>

			<Row>
				<Col>
					{isGeneralVisible ? <img src={MinusImg} className="minus" onClick={() => setIsGeneralVisible(false)} /> : <img src={PlusImg} className="plus" onClick={() => setIsGeneralVisible(true)} />}{" "}
					General
				</Col>
			</Row>

			{isGeneralVisible && (
				<Row>
					<RenderChecklistRow Ids={GeneralIds} Labels={GeneralLabels} />
				</Row>
			)}

			<Row>
				<Col>
					{isNeuroSystemVisible ? (
						<img src={MinusImg} className="minus" onClick={() => setIsNeuroSystemVisible(false)} />
					) : (
						<img src={PlusImg} className="plus" onClick={() => setIsNeuroSystemVisible(true)} />
					)}{" "}
					Neuromusculoskeletal Systems
				</Col>
			</Row>

			{isNeuroSystemVisible && (
				<Fragment>
					<Row>
						<Col style={{ paddingLeft: "50px" }}>
							{isNeuroHeadNeckVisible ? (
								<img src={MinusImg} className="minus" onClick={() => setIsNeuroHeadNeckVisible(false)} />
							) : (
								<img src={PlusImg} className="plus" onClick={() => setIsNeuroHeadNeckVisible(true)} />
							)}{" "}
							Head & Neck
						</Col>
					</Row>

					{isNeuroHeadNeckVisible && (
						<Row>
							<RenderChecklistRow Ids={HeadAndNeckIds} Labels={HeadAndNeckLabels} leftOffset="100px" />
						</Row>
					)}

					<Row>
						<Col style={{ paddingLeft: "50px" }}>
							{isNeuroShoulderVisible ? (
								<img src={MinusImg} className="minus" onClick={() => setIsNeuroShoulderVisible(false)} />
							) : (
								<img src={PlusImg} className="plus" onClick={() => setIsNeuroShoulderVisible(true)} />
							)}{" "}
							Shoulder
						</Col>
					</Row>

					{isNeuroShoulderVisible && (
						<Row>
							<RenderChecklistRow Ids={ShoulderIds} Labels={ShoulderLabels} leftOffset="100px" />
						</Row>
					)}

					<Row>
						<Col style={{ paddingLeft: "50px" }}>
							{isNeuroMidBackVisible ? (
								<img src={MinusImg} className="minus" onClick={() => setIsNeuroMidBackVisible(false)} />
							) : (
								<img src={PlusImg} className="plus" onClick={() => setIsNeuroMidBackVisible(true)} />
							)}{" "}
							Mid-Back
						</Col>
					</Row>

					{isNeuroMidBackVisible && (
						<Row>
							<RenderChecklistRow Ids={MidBackIds} Labels={MidBackLabels} leftOffset="100px" />
						</Row>
					)}

					<Row>
						<Col style={{ paddingLeft: "50px" }}>
							{isNeuroLowBackVisible ? (
								<img src={MinusImg} className="minus" onClick={() => setIsNeuroLowBackVisible(false)} />
							) : (
								<img src={PlusImg} className="plus" onClick={() => setIsNeuroLowBackVisible(true)} />
							)}{" "}
							Low-Back
						</Col>
					</Row>

					{isNeuroLowBackVisible && (
						<Row>
							<RenderChecklistRow Ids={LowBackIds} Labels={LowBackLabels} leftOffset="100px" />
						</Row>
					)}

					<Row>
						<Col style={{ paddingLeft: "50px" }}>
							{isNeuroArmsAndHandsVisible ? (
								<img src={MinusImg} className="minus" onClick={() => setIsNeuroArmsAndHandsVisible(false)} />
							) : (
								<img src={PlusImg} className="plus" onClick={() => setIsNeuroArmsAndHandsVisible(true)} />
							)}{" "}
							Arms & Hands
						</Col>
					</Row>

					{isNeuroArmsAndHandsVisible && (
						<Row>
							<RenderChecklistRow Ids={ArmsHandsIds} Labels={ArmsHandsLabels} leftOffset="100px" />
						</Row>
					)}

					<Row>
						<Col style={{ paddingLeft: "50px" }}>
							{isNeuroHipsLegsVisible ? (
								<img src={MinusImg} className="minus" onClick={() => setIsNeuroHipsLegsVisible(false)} />
							) : (
								<img src={PlusImg} className="plus" onClick={() => setIsNeuroHipsLegsVisible(true)} />
							)}{" "}
							Hips, Legs and Feet
						</Col>
					</Row>

					{isNeuroHipsLegsVisible && (
						<Row>
							<RenderChecklistRow Ids={HipsLegsIds} Labels={HipsLegsLabels} leftOffset="100px" />
						</Row>
					)}
				</Fragment>
			)}
		</div>
	);
};

const styles = {
	screen: {
		margin: "20px auto",
		maxWidth: "800px",
	},
};

export default HealthReport;
