import React, { useState, Fragment, useEffect } from "react";
import { Col, Row, Spinner, Button, Modal, Popover, OverlayTrigger } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfo } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import { jsPDF } from "jspdf";
import { QRCodeSVG } from "qrcode.react";

import IntakeMain from "./IntakeMain";
import { RetrieveToken } from "../../../lib/token";
import { dbUrl } from "../../../lib/config";
import { CustomFormatPhoneNumber } from "../../../lib/scripts";

import "bootstrap/dist/css/bootstrap.min.css";
import "react-phone-number-input/style.css";
import "react-datetime/css/react-datetime.css";
import "./Assets/style.scss";

import bodiometerLogoImg from "../../../resources/logos/bodiometer-logo.png";
import HumanBodyImg from "./Assets/human_body.png";
import CheckboxSelectedImg from "./Assets/checkbox_selected.png";
import CheckboxDSelectedImg from "./Assets/checkbox_dselected.png";
import PrevSelectedImg from "./Assets/prev_selected.png";
import PrevDSelectedImg from "./Assets/prev_dselected.png";
import PresSelectedImg from "./Assets/pres_selected.png";
import PresDSelectedImg from "./Assets/pres_dselected.png";

const IntakeDashboard = ({ CaseId, PatientId, ActiveOrganization, ActiveAccessLevel, onBack }) => {
	const [isLoading, setIsLoading] = useState(false);
	const [editMode, setEditMode] = useState(false);
	const [data, setData] = useState({ intake: null, intakeSubmission: null, patientInfo: null });
	const [isSubmitModalVisible, setIsSubmitModalVisible] = useState(false);
	const [openEmailClient, setOpenEmailClient] = useState(false);

	// ActiveAccessLevel = 2;

	const LoadInitialData = async (CallBack) => {
		try {
			setIsLoading(true);

			RetrieveToken()
				.then(async (token) => {
					var configTwo = {
						method: "GET",
						url: dbUrl + "/api/intake/v4/intakesubmission/caseid/patientid",
						headers: {
							Authorization: `Bearer ${token}`,
							CaseId,
							PatientId,
						},
					};

					var responseTwo = await axios(configTwo, { timeout: 5000 });

					if (responseTwo.data.success) {
						setData({ ...responseTwo.data.data });
						setIsLoading(false);
						CallBack();
					} else {
						// handle error
						setIsLoading(false);
					}
				})
				.catch((_) => {
					// error
					setIsLoading(false);
				});
		} catch (_) {
			setIsLoading(false);
		}
	};

	const CreateNewIntakeSubmission = async () => {
		try {
			setIsLoading(true);

			RetrieveToken()
				.then(async (token) => {
					let configTwo = {
						method: "POST",
						url: dbUrl + "/api/intake/v4/intakesubmission",
						headers: {
							Authorization: `Bearer ${token}`,
							"Content-Type": "application/json",
						},
						data: JSON.stringify({
							CaseId,
							PatientId,
							CreatedAt: new Date().toISOString(),
							LastModified: new Date().toISOString(),
							LastEmailSent: new Date().toISOString(),
						}),
					};

					var responseTwo = await axios(configTwo, { timeout: 5000 });

					if (responseTwo.data.success) {
						setIsLoading(false);
						LoadInitialData(() => {
							setOpenEmailClient(true);
							setTimeout(() => {
								setOpenEmailClient(false);
							}, 200);
						});
					} else {
						// handle error
						setIsLoading(false);
					}
				})
				.catch((_) => {
					// handle error
					setIsLoading(false);
				});
		} catch (_) {
			setIsLoading(false);
		}
	};

	const UpdateIntakeSubmissionSendEmail = async () => {
		try {
			setIsLoading(true);

			RetrieveToken()
				.then(async (token) => {
					var configTwo = {
						method: "PUT",
						url: dbUrl + "/api/intake/v1/update/intakesubmission",
						headers: {
							Authorization: `Bearer ${token}`,
							"Content-Type": "application/json",
						},
						data: JSON.stringify({
							...data.intakeSubmission,
							Status: "EMAIL SENT",
							LastEmailSent: new Date().toISOString(),
						}),
					};

					var responseTwo = await axios(configTwo, { timeout: 5000 });

					if (responseTwo.data.success) {
						setIsLoading(false);
						LoadInitialData(() => {
							setOpenEmailClient(true);
							setTimeout(() => setOpenEmailClient(false), 200);
						});
					} else {
						// handle error
						setIsLoading(false);
					}
				})
				.catch((_) => {
					// handle error
					setIsLoading(false);
				});
		} catch (_) {
			setIsLoading(false);
		}
	};

	useEffect(() => {
		LoadInitialData(() => {});
	}, []);

	const DisplayEmailClient = () => {
		return (
			<Fragment>
				<Mailto
					email={data?.intake?.Personal.Email}
					subject={`Patient Intake form`}
					body={`Hi ${data?.intake?.Personal.FirstName} ${data?.intake?.Personal.LastName},\n\nThis email is to confirm that you recently requested a physiotherapy or chiropractic appointment at ${
						data.businessInfo.BusinessName
					}.\n\nPlease complete the digital intake form, using the link below, prior to your appointment. Once you complete and submit the intake form, the information will be automatically sent to us.\n\nhttps://www.bodiometer.com/api/public/intakeform/${
						data.intakeSubmission.Id
					}\n\n To start the intake process, simply click on the link above or copy and paste the link into your browser.\n\n If you encounter any technical issues, please contact us at ${
						data.businessInfo.Email
					} or call ${CustomFormatPhoneNumber(data.businessInfo.PhoneNumber, data.businessAddressInfo?.Country ?? "Canada")} for assistance.\n\n [N.B. Unfortunately, we do not support Internet Explorer (IE) to submit the intake form. We strongly recommend you use another browser like Chrome, Firefox, Edge, Safari or Opera.]\n\n\n\n\nThank you for choosing ${data.businessInfo.BusinessName}.\n\n`}
				>
					Send
				</Mailto>
			</Fragment>
		);
	};

	const UpdateIntake = async (IntakeData) => {
		try {
			setIsLoading(true);

			try{
				IntakeData.Personal.DateOfBirth = new Date(IntakeData.Personal.DateOfBirth).toISOString();
			}
			catch {
				IntakeData.Personal.DateOfBirth = new Date().toISOString();
			}

			RetrieveToken()
				.then(async (token) => {
					var configTwo = {
						method: "PUT",
						url: dbUrl + "/api/intake/v1/updateintake",
						headers: {
							Authorization: `Bearer ${token}`,
							"Content-Type": "application/json",
						},
						data: JSON.stringify(IntakeData),
					};

					await axios(configTwo, { timeout: 5000 });

					setData({ ...data, intake: { ...data.intake, ...IntakeData } });

					setIsLoading(false);
				})
				.catch((_) => {
					setIsLoading(false);
				});
		} catch (_) {}
	};

	const OnSaveHandler = async (intakeData, callBack) => {
		await UpdateIntake(intakeData);
		callBack();
	};

	const RenderInfoToolTip = (props) => {
		return (
			<Popover {...props} className="posture-date-overlay-wrapper" id={`popover${Math.floor(Math.random()).toString()}`}>
				<Popover.Content>
					<Row>
						<Col>
							<div>You need to setup a default email client. On windows operating system, open the "Mail" application. Now login using your preferred email address.</div>
						</Col>
					</Row>
				</Popover.Content>
			</Popover>
		);
	};

	const RenderInfo = () => {
		return (
			<OverlayTrigger placement="right" trigger="click" overlay={RenderInfoToolTip}>
				<Button variant="warning" id="info-button" style={{ padding: "5px", marginLeft: "5px", borderRadius: "10px", color: "white" }}>
					<FontAwesomeIcon icon={faInfo} size="sm" />
				</Button>
			</OverlayTrigger>
		);
	};

	const GeneratePdf = () => {
		var pdf = new jsPDF(DefaultPdfReportConfig);

		RetrieveToken()
			.then((token) => {
				axios({
					method: "get",
					url: dbUrl + "/api/storage/v1/image/ba/logo/id/base64",
					headers: {
						Authorization: "Bearer " + token,
						"Content-Type": "application/json",
						businessId: ActiveOrganization.id,
					},
				})
					.then((logoRes) => {
						if (logoRes.status === 200) {
							var imageDb = {};
							var ActiveOrganizationLogo = logoRes.data;

							if (!!bodiometerLogoImg) {
								imageDb["bodiometer.logo"] = new Image();
								imageDb["bodiometer.logo"].src = bodiometerLogoImg;
							} else {
								imageDb["bodiometer.logo"] = null;
							}

							if (!!ActiveOrganizationLogo) {
								imageDb["clinic.logo"] = new Image();
								imageDb["clinic.logo"].src = ActiveOrganizationLogo;
							} else {
								imageDb["clinic.logo"] = null;
							}

							if (!!HumanBodyImg) {
								imageDb["human.body"] = new Image();
								imageDb["human.body"].src = HumanBodyImg;
							} else {
								imageDb["human.body"] = null;
							}

							if (!!CheckboxSelectedImg && !!CheckboxDSelectedImg) {
								imageDb["checkbox.selected"] = new Image();
								imageDb["checkbox.dselected"] = new Image();
								imageDb["checkbox.selected"].src = CheckboxSelectedImg;
								imageDb["checkbox.dselected"].src = CheckboxDSelectedImg;
							} else {
								imageDb["checkbox.selected"] = null;
								imageDb["checkbox.dselected"] = null;
							}

							var imageKeys = Object.keys(imageDb);
							var imageCount = 0;
							var nImgLoaded = 0;
							for (var i = 0; i < imageKeys.length; i++) {
								if (imageDb[imageKeys[i]] != null) {
									imageCount++;
								}
							}

							for (var i = 0; i < imageKeys.length; i++) {
								if (imageDb[imageKeys[i]] != null) {
									imageDb[imageKeys[i]].onload = () => {
										nImgLoaded++;
										if (nImgLoaded === imageCount) {
											// Page 1 : Basic Layout
											GenerateBasicLayout(pdf, imageDb["bodiometer.logo"], imageDb["clinic.logo"], 1);

											var verticalPointer = OverflowSpace;

											if (ActiveAccessLevel <= 1) {
												verticalPointer = GeneratePersonalAndMedicalInformationPdf(pdf, verticalPointer, imageDb);
											}

											if (data.intake.WorkRelatedInjury.ExistsWorkRelatedInjury) {
												verticalPointer = GenerateWorkRelatedInjuryPdf(pdf, verticalPointer, imageDb);
											}
											if (data.intake.MotorVehicleInjury.ExistsMotorVehicleInjury) {
												verticalPointer = GenerateMotorVehicleInjuryPdf(pdf, verticalPointer, imageDb);
											}
											if (data.intake.ExtendedHealth.ExistsExtendedHealth) {
												verticalPointer = GenerateExtendedHealthPdf(pdf, verticalPointer, imageDb);
											}
											verticalPointer = GenerateScreeningQuestionsPdf(pdf, verticalPointer, imageDb);
											verticalPointer = GeneratePreviousTreatmentsPdf(pdf, verticalPointer, imageDb);
											verticalPointer = GenerateHealthReportPdf(pdf, verticalPointer, imageDb);
											verticalPointer = GenerateFunctionalQuestionsPdf(pdf, verticalPointer, imageDb);
											pdf.save(`intake_${PatientId}.pdf`);
										}
									};
								}
							}

							// setState((prev) => ({ ...prev, ActiveOrganizationLogo: logoRes.data, Preloader: false }));
						}
					})
					.catch((err) => console.log(err));
			})
			.catch((err) => console.log(err));
	};

	const GenerateBasicLayout = (pdf, bodiometerLogo, clinicLogo, pageNumber) => {
		var width = pdf.internal.pageSize.getWidth();
		var height = pdf.internal.pageSize.getHeight();
		var offsetLeft = 20;

		if (bodiometerLogo != null) {
			pdf.addImage(bodiometerLogo, width - 100, 20, (bodiometerLogo.width / bodiometerLogo.height) * 20, 20);
		}
		if (clinicLogo != null) {
			pdf.addImage(clinicLogo, 20, 20, (clinicLogo.width / clinicLogo.height) * 20, 20);
		}

		pdf.setFontSize(8);
		pdf.setFont(undefined, "normal");
		pdf.setDrawColor(0, 0, 0);
		pdf.line(20, 45, width - 20, 45);
		pdf.line(20, height - 45, width - 20, height - 45);
		pdf.text(`Address: ${ActiveOrganization?.address}, ${ActiveOrganization?.city}, ${ActiveOrganization?.country}`, 20, height - 35, {
			align: "left",
		});
		pdf.text(`Email: ${ActiveOrganization?.email}, Phone: ${ActiveOrganization?.phone}, Fax: ${ActiveOrganization?.fax}`, 20, height - 25, {
			align: "left",
		});
		pdf.text(`Page ${pageNumber}`, width - offsetLeft - pdf.getTextWidth(`Page ${pageNumber}`), height - 30, { align: "left" });

		pdf.setFontSize(10);
		pdf.setFont(undefined, "normal");
	};

	const AddVerticalSpace = (pdf, space, h, v, imageDb) => {
		if (h - (v % h) < OverflowSpace) {
			pdf.addPage(DefaultPdfReportConfig);
			GenerateBasicLayout(pdf, imageDb["bodiometer.logo"], imageDb["clinic.logo"], Math.ceil(v / h) + 1);
			return v + OverflowSpace + (h - (v % h));
		} else if (Math.floor(v / h) < Math.floor((v + space) / h)) {
			pdf.addPage(DefaultPdfReportConfig);
			GenerateBasicLayout(pdf, imageDb["bodiometer.logo"], imageDb["clinic.logo"], Math.ceil(v / h) + 1);
		}
		return v + space;
	};

	const GeneratePersonalAndMedicalInformationPdf = (pdf, verticalPointer, imageDb) => {
		var height = pdf.internal.pageSize.getHeight();
		var verticalStart = verticalPointer;
		var verticalGap = 10;
		var offsetLeft = 20;

		const GetHandednessLabel = (label) => {
			switch(label) {
				case 0: return "Right-Handed";
				case 1: return "Left-Handed";
				case 2: return "Cross-Dominant / Mixed-Handed";
				case 3: return "Ambidexter";
				default: return "___"
			}
		}

		pdf.setFontSize(16);
		pdf.setFont(undefined, "bold");
		pdf.text("Personal Information", offsetLeft, verticalStart % height);
		verticalStart = AddVerticalSpace(pdf, 1.5 * verticalGap, height, verticalStart, imageDb);
		pdf.setFontSize(10);
		pdf.setFont(undefined, "normal");
		var fullName = data.intake.Personal.Salutation + `${!IsEmpty(data.intake.Personal.Salutation) ? " " : ""}`;
		fullName += data.intake.Personal.FirstName + `${!IsEmpty(data.intake.Personal.FirstName) ? " " : ""}`;
		fullName += data.intake.Personal.LastName + `${!IsEmpty(data.intake.Personal.LastName) ? " " : ""}`;
		pdf.text(!IsEmpty(fullName) ? `Name: ${fullName}` : "Name: ___", offsetLeft, verticalStart % height);
		verticalStart = AddVerticalSpace(pdf, verticalGap, height, verticalStart, imageDb);
		pdf.text(!IsEmpty(data.intake.Personal.Email) ? `Email: ${data.intake.Personal.Email}` : "Email: ___", offsetLeft, verticalStart % height);

		var addressInfo = AddressObjToStr(data.intake.Personal.Address);
		verticalStart = AddVerticalSpace(pdf, 1.5 * verticalGap, height, verticalStart, imageDb);
		pdf.text(!IsEmpty(addressInfo) ? `Address: ${addressInfo}` : "Address: ___", offsetLeft, verticalStart);
		verticalStart = AddVerticalSpace(pdf, verticalGap, height, verticalStart, imageDb);
		pdf.text(
			!IsEmpty(data.intake.Personal.HomePhone + data.intake.Personal.HomePhoneExt) ? `Home Phone: ${data.intake.Personal.HomePhone} ${data.intake.Personal.HomePhoneExt}` : "Home Phone: ___",
			offsetLeft,
			verticalStart % height
		);
		verticalStart = AddVerticalSpace(pdf, verticalGap, height, verticalStart, imageDb);
		pdf.text(
			!IsEmpty(data.intake.Personal.CellPhone + data.intake.Personal.CellPhoneExt) ? `Cell Phone: ${data.intake.Personal.CellPhone} ${data.intake.Personal.CellPhoneExt}` : "Cell Phone: ___",
			offsetLeft,
			verticalStart % height
		);
		verticalStart = AddVerticalSpace(pdf, verticalGap, height, verticalStart, imageDb);
		pdf.text(
			!IsEmpty(data.intake.Personal.WorkPhone + data.intake.Personal.WorkPhoneExt) ? `Work Phone: ${data.intake.Personal.WorkPhone} ${data.intake.Personal.WorkPhoneExt}` : "Work Phone: ___",
			offsetLeft,
			verticalStart % height
		);

		verticalStart = AddVerticalSpace(pdf, 1.5 * verticalGap, height, verticalStart, imageDb);
		pdf.text(
			!IsEmpty(data.intake.Personal.DateOfBirth) ? `Date Of Birth: ${new Date(data.intake.Personal.DateOfBirth.replace(/-/g, '\/').replace(/T.+/, '')).toLocaleDateString(dateLocale, bDayFormat)}` : "Date Of Birth: ___",
			offsetLeft,
			verticalStart % height
		);
		verticalStart = AddVerticalSpace(pdf, verticalGap, height, verticalStart, imageDb);
		pdf.text(!IsEmpty(data.intake.Personal.Gender) ? `Sex: ${data.intake.Personal.Gender}` : "Sex: ___", offsetLeft, verticalStart % height);
		verticalStart = AddVerticalSpace(pdf, verticalGap, height, verticalStart, imageDb);
		pdf.text(!IsEmpty(data.intake.Personal.Handedness) ? `Handedness: ${GetHandednessLabel(data.intake.Personal.Handedness)}` : "Handedness: ___", offsetLeft, verticalStart % height);
		verticalStart = AddVerticalSpace(pdf, verticalGap, height, verticalStart, imageDb);
		pdf.text(
			!IsEmpty(data.intake.Personal.WeightLbs) || !IsEmpty(data.intake.Personal.WeightKgs) ? `Weight: ${data.intake.Personal.WeightLbs} (lb) / ${data.intake.Personal.WeightKgs} (kg)` : "Weight: ___",
			offsetLeft,
			verticalStart % height
		);
		verticalStart = AddVerticalSpace(pdf, verticalGap, height, verticalStart, imageDb);
		pdf.text(!IsEmpty(data.intake.Personal.HeightFt) ? `Height: ${data.intake.Personal.HeightFt}'${data.intake.Personal.HeightInch}"` : "Height: ___", offsetLeft, verticalStart % height);
		verticalStart = AddVerticalSpace(pdf, verticalGap, height, verticalStart, imageDb);
		pdf.text(
			!IsEmpty(data.intake.Personal.PersonalHealthNumber) ? `Personal Health Number: ${data.intake.Personal.PersonalHealthNumber}` : "Personal Health Number: ___",
			offsetLeft,
			verticalStart % height
		);
		verticalStart = AddVerticalSpace(pdf, verticalGap, height, verticalStart, imageDb);
		pdf.text(!IsEmpty(data.intake.Personal.Occupation) ? `Occupation: ${data.intake.Personal.Occupation}` : "Occupation: ___", offsetLeft, verticalStart % height);
		verticalStart = AddVerticalSpace(pdf, verticalGap, height, verticalStart, imageDb);
		pdf.text(!IsEmpty(data.intake.Personal.ReferredBy) ? `Referred By: ${data.intake.Personal.PersonalHealthNumber}` : "Referred By: ___", offsetLeft, verticalStart % height);

		verticalStart = AddVerticalSpace(pdf, 1.5 * verticalGap, height, verticalStart, imageDb);
		pdf.setFontSize(16);
		pdf.setFont(undefined, "bold");
		pdf.text("Medical Information", offsetLeft, verticalStart % height);
		pdf.setFontSize(10);
		pdf.setFont(undefined, "normal");

		verticalStart = AddVerticalSpace(pdf, 1.5 * verticalGap, height, verticalStart, imageDb);
		var emergencyName = data.intake.Medical.EmergencyFirstName + " " + data.intake.Medical.EmergencyLastName;
		pdf.text(
			!IsEmpty(data.intake.Medical.EmergencyFirstName) || !IsEmpty(data.intake.Medical.EmergencyLastName) ? `Emergency Name: ${emergencyName}` : "Emergency Name: ___",
			offsetLeft,
			verticalStart % height
		);
		verticalStart = AddVerticalSpace(pdf, verticalGap, height, verticalStart, imageDb);
		pdf.text(!IsEmpty(data.intake.Medical.EmergencyRelation) ? `Relation: ${data.intake.Medical.EmergencyRelation}` : "Relation: ___", offsetLeft, verticalStart % height);
		verticalStart = AddVerticalSpace(pdf, verticalGap, height, verticalStart, imageDb);
		pdf.text(!IsEmpty(data.intake.Medical.EmergencyEmail) ? `Email: ${data.intake.Medical.EmergencyEmail}` : "Email: ___", offsetLeft, verticalStart % height);
		verticalStart = AddVerticalSpace(pdf, verticalGap, height, verticalStart, imageDb);
		pdf.text(
			!IsEmpty(data.intake.Medical.EmergencyPhone) || !IsEmpty(data.intake.Medical.EmergencyPhoneExt)
				? `Contact: ${data.intake.Medical.EmergencyPhone} ${data.intake.Medical.EmergencyPhoneExt}`
				: "Contact: ___",
			offsetLeft,
			verticalStart % height
		);
		var emAddressInfo = AddressObjToStr(data.intake.Medical.EmergencyAddress);
		verticalStart = AddVerticalSpace(pdf, verticalGap, height, verticalStart, imageDb);
		pdf.text(!IsEmpty(emAddressInfo) ? `Address: ${emAddressInfo}` : "Address: ___", offsetLeft, verticalStart % height);

		if (!IsEmpty(data.intake.Medical.PhysicianFirstName) || !IsEmpty(data.intake.Medical.PhysicianLastName)) {
			verticalStart = AddVerticalSpace(pdf, 1.5 * verticalGap, height, verticalStart, imageDb);
			var physicianName = data.intake.Medical.PhysicianFirstName + " " + data.intake.Medical.PhysicianLastName;
			pdf.text(
				!IsEmpty(data.intake.Medical.PhysicianFirstName) || !IsEmpty(data.intake.Medical.PhysicianLastName) ? `Physician Name: ${physicianName}` : "Physician Name: ___",
				offsetLeft,
				verticalStart % height
			);
			verticalStart = AddVerticalSpace(pdf, verticalGap, height, verticalStart, imageDb);
			pdf.text(!IsEmpty(data.intake.Medical.PhysicianClinic) ? `Clinic: ${data.intake.Medical.PhysicianClinic}` : "Clinic: ___", offsetLeft, verticalStart % height);
			verticalStart = AddVerticalSpace(pdf, verticalGap, height, verticalStart, imageDb);
			pdf.text(!IsEmpty(data.intake.Medical.PhysicianEmail) ? `Email: ${data.intake.Medical.PhysicianEmail}` : "Email: ___", offsetLeft, verticalStart % height);
			var phyAddressInfo = AddressObjToStr(data.intake.Medical.PhysicianAddress);
			verticalStart = AddVerticalSpace(pdf, verticalGap, height, verticalStart, imageDb);
			pdf.text(!IsEmpty(phyAddressInfo) ? `Address: ${phyAddressInfo}` : "Address: ___", offsetLeft, verticalStart % height);
			verticalStart = AddVerticalSpace(pdf, verticalGap, height, verticalStart, imageDb);
			pdf.text(
				!IsEmpty(data.intake.Medical.PhysicianPhone + data.intake.Medical.PhysicianPhoneExt)
					? `Contact: ${data.intake.Medical.PhysicianPhone} ${data.intake.Medical.PhysicianPhoneExt}`
					: "Contact: ___",
				offsetLeft,
				verticalStart % height
			);
			verticalStart = AddVerticalSpace(pdf, verticalGap, height, verticalStart, imageDb);
			pdf.text(!IsEmpty(data.intake.Medical.PhysicianFax) ? `Fax: ${data.intake.Medical.PhysicianFax}` : "Fax: ___", offsetLeft, verticalStart % height);
		}
		verticalStart = AddVerticalSpace(pdf, verticalGap, height, verticalStart, imageDb);
		pdf.text(!IsEmpty(data.intake.Medical.Injury) ? `Injury: ${data.intake.Medical.Injury}` : "Injury: ___", offsetLeft, verticalStart % height);
		verticalStart = AddVerticalSpace(pdf, 1.5 * verticalGap, height, verticalStart, imageDb);
		return verticalStart;
	};

	const GenerateWorkRelatedInjuryPdf = (pdf, verticalPointer, imageDb) => {
		var height = pdf.internal.pageSize.getHeight();
		var verticalStart = verticalPointer;
		var verticalGap = 10;
		var offsetLeft = 20;

		pdf.setFontSize(16);
		pdf.setFont(undefined, "bold");
		pdf.text("Work-related Injury Information", offsetLeft, verticalStart % height);
		verticalStart = AddVerticalSpace(pdf, 1.5 * verticalGap, height, verticalStart, imageDb);
		pdf.setFontSize(10);
		pdf.setFont(undefined, "normal");
		verticalStart = AddVerticalSpace(pdf, verticalGap, height, verticalStart, imageDb);
		pdf.text(`Are you here because of work-related injury? ${data.intake.WorkRelatedInjury.ExistsWorkRelatedInjury ? "Yes" : "No"}`, offsetLeft, verticalStart % height);
		verticalStart = AddVerticalSpace(pdf, verticalGap, height, verticalStart, imageDb);
		pdf.text(
			!IsEmpty(data.intake.WorkRelatedInjury.InjuryDate)
				? `Date of Injury (MM/DD/YYYY): ${new Date(data.intake.WorkRelatedInjury.InjuryDate).toLocaleDateString()}`
				: "Date of Injury (MM/DD/YYYY): ___",
			offsetLeft,
			verticalStart % height
		);
		verticalStart = AddVerticalSpace(pdf, verticalGap, height, verticalStart, imageDb);
		pdf.text(!IsEmpty(data.intake.WorkRelatedInjury.InjuryType) ? `Injury Type: ${data.intake.WorkRelatedInjury.InjuryType}` : "Injury Type: ___", offsetLeft, verticalStart % height);
		var bodyParts = "";
		for (let i = 0; i < data.intake.WorkRelatedInjury.BodyParts.length; i++) {
			bodyParts += data.intake.WorkRelatedInjury.BodyParts[i];
			if (i < data.intake.WorkRelatedInjury.BodyParts.length - 1) {
				bodyParts += ", ";
			}
		}
		verticalStart = AddVerticalSpace(pdf, verticalGap, height, verticalStart, imageDb);
		pdf.text(!IsEmpty(bodyParts) ? `Injured Body Parts: ${bodyParts}` : "Injured Body Parts: ___", offsetLeft, verticalStart % height);
		verticalStart = AddVerticalSpace(pdf, verticalGap, height, verticalStart, imageDb);
		pdf.text(!IsEmpty(data.intake.WorkRelatedInjury.WcbClaim) ? `WCB Claim: ${data.intake.WorkRelatedInjury.WcbClaim}` : "WCB Claim: ___", offsetLeft, verticalStart % height);
		verticalStart = AddVerticalSpace(pdf, verticalGap, height, verticalStart, imageDb);
		pdf.text(
			!IsEmpty(data.intake.WorkRelatedInjury.EmployerCompanyName) ? `Employer Company Name: ${data.intake.WorkRelatedInjury.EmployerCompanyName}` : "Employer Company Name: ___",
			offsetLeft,
			verticalStart % height
		);
		verticalStart = AddVerticalSpace(pdf, verticalGap, height, verticalStart, imageDb);
		var repName = data.intake.WorkRelatedInjury.EmployerRepresentativeFirstName + " " + data.intake.WorkRelatedInjury.EmployerRepresentativeLastName;
		pdf.text(
			!IsEmpty(data.intake.WorkRelatedInjury.EmployerRepresentativeFirstName) || !IsEmpty(data.intake.WorkRelatedInjury.EmployerRepresentativeLastName)
				? `Representative Name: ${repName}`
				: "Representative Name: ___",
			offsetLeft,
			verticalStart % height
		);
		verticalStart = AddVerticalSpace(pdf, verticalGap, height, verticalStart, imageDb);
		pdf.text(
			!IsEmpty(data.intake.WorkRelatedInjury.EmployerPhone + data.intake.WorkRelatedInjury.EmployerPhoneExt)
				? `Employer Contact: ${data.intake.WorkRelatedInjury.EmployerPhone} ${data.intake.WorkRelatedInjury.EmployerPhoneExt}`
				: "Employer Contact: ___",
			offsetLeft,
			verticalStart % height
		);
		var empAddressInfo = AddressObjToStr(data.intake.WorkRelatedInjury.EmployerAddress);
		verticalStart = AddVerticalSpace(pdf, verticalGap, height, verticalStart, imageDb);
		pdf.text(!IsEmpty(empAddressInfo) ? `Employer Address: ${empAddressInfo}` : "Employer Address: ___", offsetLeft, verticalStart % height);
		verticalStart = AddVerticalSpace(pdf, verticalGap, height, verticalStart, imageDb);
		pdf.text(`May we contact your employer? ${data.intake.WorkRelatedInjury.AllowEmployerContact ? "Yes" : "No"}`, offsetLeft, verticalStart % height);
		verticalStart = AddVerticalSpace(pdf, 2 * verticalGap, height, verticalStart, imageDb);
		return verticalStart;
	};

	const GenerateMotorVehicleInjuryPdf = (pdf, verticalPointer, imageDb) => {
		var height = pdf.internal.pageSize.getHeight();
		var verticalStart = verticalPointer;
		var verticalGap = 10;
		var offsetLeft = 20;

		verticalStart = AddVerticalSpace(pdf, verticalGap, height, verticalStart, imageDb);

		pdf.setFontSize(16);
		pdf.setFont(undefined, "bold");
		pdf.text("Motor Vehicle Related Injury Information", offsetLeft, verticalStart % height);
		verticalStart = AddVerticalSpace(pdf, 1.5 * verticalGap, height, verticalStart, imageDb);
		pdf.setFontSize(10);
		pdf.setFont(undefined, "normal");
		pdf.text(`Are you here because of motor vehicle related injury? ${data.intake.MotorVehicleInjury.ExistsMotorVehicleInjury ? "Yes" : "No"}`, offsetLeft, verticalStart % height);
		verticalStart = AddVerticalSpace(pdf, verticalGap, height, verticalStart, imageDb);
		pdf.text(
			!IsEmpty(data.intake.MotorVehicleInjury.AccidentDate)
				? `Date of Accident (MM/DD/YYYY): ${new Date(data.intake.MotorVehicleInjury.AccidentDate.substring(0, 10)).toLocaleDateString()}`
				: "Date of Accident (MM/DD/YYYY): ___",
			offsetLeft,
			verticalStart % height
		);
		verticalStart = AddVerticalSpace(pdf, verticalGap, height, verticalStart, imageDb);
		pdf.text(
			!IsEmpty(data.intake.MotorVehicleInjury.InsuranceCompanyName) ? `Insurance Company Name: ${data.intake.MotorVehicleInjury.InsuranceCompanyName}` : "Insurance Company Name: ___",
			offsetLeft,
			verticalStart % height
		);
		verticalStart = AddVerticalSpace(pdf, verticalGap, height, verticalStart, imageDb);
		pdf.text(!IsEmpty(data.intake.MotorVehicleInjury.Claim) ? `Claim Number: ${data.intake.MotorVehicleInjury.Claim}` : "Claim Number: ___", offsetLeft, verticalStart % height);
		verticalStart = AddVerticalSpace(pdf, verticalGap, height, verticalStart, imageDb);
		pdf.text(!IsEmpty(data.intake.MotorVehicleInjury.Policy) ? `Policy Number: ${data.intake.MotorVehicleInjury.Claim}` : "Policy Number: ___", offsetLeft, verticalStart % height);
		verticalStart = AddVerticalSpace(pdf, verticalGap, height, verticalStart, imageDb);
		pdf.text(`Is this your policy? ${data.intake.MotorVehicleInjury.OwnPolicy ? "Yes" : "No"}`, offsetLeft, verticalStart % height);
		verticalStart = AddVerticalSpace(pdf, verticalGap, height, verticalStart, imageDb);
		pdf.text(!IsEmpty(data.intake.MotorVehicleInjury.AdjusterName) ? `Adjuster Name: ${data.intake.MotorVehicleInjury.AdjusterName}` : "Adjuster Name: ___", offsetLeft, verticalStart % height);
		verticalStart = AddVerticalSpace(pdf, verticalGap, height, verticalStart, imageDb);
		pdf.text(!IsEmpty(data.intake.MotorVehicleInjury.Fax) ? `Fax Number: ${data.intake.MotorVehicleInjury.Fax}` : "Fax Number: ___", offsetLeft, verticalStart % height);
		verticalStart = AddVerticalSpace(pdf, verticalGap, height, verticalStart, imageDb);
		pdf.text(
			!IsEmpty(data.intake.MotorVehicleInjury.Phone + data.intake.MotorVehicleInjury.PhoneExt)
				? `Phone Number: ${data.intake.MotorVehicleInjury.Phone} ${data.intake.MotorVehicleInjury.PhoneExt}`
				: "Phone Number: ___",
			offsetLeft,
			verticalStart % height
		);
		verticalStart = AddVerticalSpace(pdf, verticalGap, height, verticalStart, imageDb);
		pdf.text(!IsEmpty(data.intake.MotorVehicleInjury.NameOnPolicy) ? `Name On Policy: ${data.intake.MotorVehicleInjury.NameOnPolicy}` : "Name On Policy: ___", offsetLeft, verticalStart % height);
		verticalStart = AddVerticalSpace(pdf, verticalGap, height, verticalStart, imageDb);
		pdf.text(
			!IsEmpty(data.intake.MotorVehicleInjury.LegalRepresentative) ? `Legal Representative: ${data.intake.MotorVehicleInjury.LegalRepresentative}` : "Legal Representative: ___",
			offsetLeft,
			verticalStart % height
		);
		verticalStart = AddVerticalSpace(pdf, verticalGap, height, verticalStart, imageDb);
		return verticalStart;
	};

	const GenerateExtendedHealthPdf = (pdf, verticalPointer, imageDb) => {
		var height = pdf.internal.pageSize.getHeight();
		var verticalStart = verticalPointer;
		var verticalGap = 10;
		var offsetLeft = 20;

		verticalStart = AddVerticalSpace(pdf, verticalGap, height, verticalStart, imageDb);

		pdf.setFontSize(16);
		pdf.setFont(undefined, "bold");
		pdf.text("Extended Health Information", offsetLeft, verticalStart % height);
		verticalStart = AddVerticalSpace(pdf, 1.5 * verticalGap, height, verticalStart, imageDb);
		pdf.setFontSize(10);
		pdf.setFont(undefined, "normal");
		pdf.text(`Do you have extended health coverage? ${data.intake.ExtendedHealth.ExistsExtendedHealth ? "Yes" : "No"}`, offsetLeft, verticalStart % height);
		verticalStart = AddVerticalSpace(pdf, verticalGap, height, verticalStart, imageDb);
		pdf.text(
			!IsEmpty(data.intake.ExtendedHealth.InsuranceCompany) ? `Insurance Company Name: ${data.intake.ExtendedHealth.InsuranceCompany}` : "Insurance Company Name: ___",
			offsetLeft,
			verticalStart % height
		);
		verticalStart = AddVerticalSpace(pdf, verticalGap, height, verticalStart, imageDb);
		pdf.text(!IsEmpty(data.intake.ExtendedHealth.GroupNumber) ? `Group Number: ${data.intake.ExtendedHealth.GroupNumber}` : "Group Number: ___", offsetLeft, verticalStart % height);
		verticalStart = AddVerticalSpace(pdf, verticalGap, height, verticalStart, imageDb);
		pdf.text(!IsEmpty(data.intake.ExtendedHealth.MemberNumber) ? `Member Number: ${data.intake.ExtendedHealth.MemberNumber}` : "Member Number: ___", offsetLeft, verticalStart % height);
		verticalStart = AddVerticalSpace(pdf, verticalGap, height, verticalStart, imageDb);
		pdf.text(
			!IsEmpty(data.intake.ExtendedHealth.PrimaryCardHolderName) ? `Primary CardHolder Name: ${data.intake.ExtendedHealth.PrimaryCardHolderName}` : "Primary CardHolder Name: ___",
			offsetLeft,
			verticalStart % height
		);
		verticalStart = AddVerticalSpace(pdf, verticalGap, height, verticalStart, imageDb);
		pdf.text(
			!IsEmpty(data.intake.ExtendedHealth.RelationToCardHolder) ? `Relation To CardHolder: ${data.intake.ExtendedHealth.RelationToCardHolder}` : "Relation To CardHolder: ___",
			offsetLeft,
			verticalStart % height
		);

		verticalStart = AddVerticalSpace(pdf, verticalGap, height, verticalStart, imageDb);
		return verticalStart;
	};

	const GetPainLevels = (data) => {
		return [
			data.FrontFaceRight,
			data.FrontFaceLeft,
			data.FrontHead,
			data.FrontTrapsRight,
			data.FrontTrapsLeft,
			data.FrontNeck,
			data.FrontShoulderRight,
			data.FrontShoulderLeft,
			data.FrontChestRight,
			data.FrontChestLeft,
			data.FrontUpperAbdominal,
			data.FrontAbsRight,
			data.FrontAbsLeft,
			data.FrontLowerAbdominal,
			data.FrontHipRight,
			data.FrontHipLeft,
			data.FrontUpperLegRight,
			data.FrontUpperLegLeft,
			data.FrontKneeJointRight,
			data.FrontKneeJointLeft,
			data.FrontLowerLegRight,
			data.FrontLowerLegLeft,
			data.FrontAnkleRight,
			data.FrontAnkleLeft,
			data.FrontFootRight,
			data.FrontFootLeft,
			data.FrontBicepsRight,
			data.FrontBicepsLeft,
			data.FrontElbowJointRight,
			data.FrontElbowJointLeft,
			data.FrontForeArmsRight,
			data.FrontForeArmsLeft,
			data.FrontWristJointRight,
			data.FrontWristJointLeft,
			data.FrontHandRight,
			data.FrontHandLeft,
			data.BackHead,
			data.BackNeck,
			data.BackTrapsLeft,
			data.BackTrapsRight,
			data.BackUpperBackLeft,
			data.BackUpperBackRight,
			data.BackMiddleBack,
			data.BackLowerBack,
			data.BackSijointLeft,
			data.BackSijointRight,
			data.BackSacrum,
			data.BackGlutesLeft,
			data.BackGlutesRight,
			data.BackUpperLegLeft,
			data.BackUpperLegRight,
			data.BackHamstringLeft,
			data.BackHamstringRight,
			data.BackKneeJointLeft,
			data.BackKneeJointRight,
			data.BackUpperCalvesLeft,
			data.BackUpperCalvesRight,
			data.BackCalvesLeft,
			data.BackCalvesRight,
			data.BackHeelLeft,
			data.BackHeelRight,
			data.BackShoulderLeft,
			data.BackShoulderRight,
			data.BackTricepsLeft,
			data.BackTricepsRight,
			data.BackElbowJointLeft,
			data.BackElbowJointRight,
			data.BackForeArmsLeft,
			data.BackForeArmsRight,
			data.BackWristJointLeft,
			data.BackWristJointRight,
			data.BackHandLeft,
			data.BackHandRight,
		];
	};

	const DrawHumanCanvas = (image) => {
		var painDiagram = document.createElement("CANVAS");
		var ctx = painDiagram.getContext("2d");
		var newPainLevels = [...GetPainLevels(data.intake.ScreeningQuestions)];

		var imageWidth = image.naturalWidth == 0 ? 600 : image.naturalWidth;
		var imageHeight = image.naturalHeight == 0 ? 800 : image.naturalHeight;
		var aspectRatio = imageWidth / imageHeight;
		var maxWidth = !!painDiagram.parentNode && "offsetWidth" in painDiagram.parentNode ? painDiagram.parentNode.offsetWidth : 600;
		var maxHeight = maxWidth / aspectRatio;
		image.width = maxWidth;
		image.height = maxHeight;
		var fontSizeLarge = maxWidth > 600 ? 18 : 10;
		ctx.canvas.width = maxWidth;
		ctx.canvas.height = maxHeight;
		ctx.drawImage(image, 0, 0, maxWidth, maxHeight);

		var CanvasEllipse = (context, cx, cy, rx, ry, color) => {
			context.beginPath();
			context.ellipse(cx, cy, rx, ry, 0, 0, Math.PI * 2);
			context.fillStyle = color;
			context.fill();
		};

		var image_coordinates = [
			[170, 104],
			[206, 104],
			[187, 71], // front -> head
			[156, 148], // front -> traps -> left
			[228, 148], // front -> traps -> right
			[189, 147], // front -> neck
			[115, 170], // front -> shoulder -> left
			[265, 170], // front -> shoulder -> right
			[156, 210], // front -> chest -> left
			[220, 210], // front -> chest -> right
			[188, 235], // front -> chest -> middle
			[150, 300], // front -> abs -> left
			[230, 300], // front -> abs -> right
			[188, 330], // front -> abs -> middle
			[150, 380], // front -> hip -> left
			[220, 380], // front -> hip -> right
			[150, 440], // front -> quadriceps -> left
			[225, 440], // front -> quadriceps -> right
			[160, 550], // front -> knee joint -> left
			[215, 550], // front -> knee joint -> right
			[160, 630], // front -> calves -> left
			[215, 630], // front -> calves -> right
			[168, 715], // front -> ankle -> left
			[208, 715], // front -> ankle -> right
			[164, 749], // front -> feet -> right
			[214, 749], // front -> feet -> left
			[105, 230], // front -> biceps -> left
			[270, 230], // front -> bicepts -> right
			[100, 273], // front -> elbow joint -> left
			[273, 273], // front -> elbow joint -> right
			[97, 315], // front -> forearms -> left
			[277, 315], // front -> forearms -> right
			[85, 375], // front -> wrist joint -> left
			[280, 380], // front -> wrist joint -> right
			[80, 400], // front -> hand -> left
			[285, 400], // front -> hand -> right
			[508, 78], // back -> head
			[509, 113], // back -> neck
			[473, 154], // back -> traps -> left
			[540, 154], // back -> traps -> right
			[464, 208], // back -> upperback -> left
			[562, 208], // back -> upperback -> right
			[512, 206], // back -> traps -> middle
			[512, 304], // back -> spine -> center
			[485, 340], // back -> si joint -> left
			[545, 340], // back -> si joint -> right
			[516, 338], // back -> sacrum
			[485, 388], // back -> glutes -> left
			[548, 388], // back -> glutes -> right
			[485, 424], // back -> lower hip -> left
			[550, 424], // back -> lower hip -> right
			[481, 481], // back -> hamstring -> left
			[549, 481], // back -> hamstring -> right
			[481, 555], // back -> knee joint -> left
			[551, 555], // back -> knee joint -> right
			[485, 608], // back -> upper calves -> left
			[552, 608], // back -> upper calves -> right
			[489, 662], // back -> lower calves -> left
			[540, 662], // back -> lower calves -> right
			[500, 746], // back -> feet -> left
			[532, 746], // back -> feet -> right
			[427, 178], // back -> shoulder -> left
			[589, 178], // back -> shoulder -> right
			[436, 242], // back -> triceps -> left
			[592, 242], // back -> triceps -> right
			[436, 293], // back -> elbow joint -> left
			[595, 293], // back -> elbow joint -> right
			[423, 343], // back -> forearms -> left
			[609, 343], // back -> forearms -> right
			[419, 385], // back -> wrist joint -> left
			[613, 380], // back -> wrist joint -> right
			[413, 412], // back -> hand -> left
			[618, 412], // back -> hand -> right
		];

		var marker_labels = [
			"face (right)", // 0
			"face (left)", // 1
			"Head (front side)", // front -> head 2
			"traps (right)", // front -> traps -> left 3
			"traps (left)", // front -> traps -> right 4
			"neck", // 5
			"shoulder (right)", // front -> shoulder -> left // 6
			"shoulder (left)", // front -> shoulder -> right // 7
			"chest (right)", // front -> chest -> left // 8
			"chest (left)", // front -> chest -> right // 9
			"upper abdominal", // front -> chest -> middle // 10
			"abs (right)", // front -> abs -> left // 11
			"abs (left)", // front -> abs -> right // 12
			"lower abdominal", // front -> abs -> middle // 13
			"hip (right)", // front -> abs & quadriceps intersection -> left // 14
			"hip (left)", // front -> abs & quadriceps intersection -> right // 15
			"upper leg (right)", // front -> quadriceps -> left // 16
			"upper leg (left)", // front -> quadriceps -> right // 17
			"knee joint (right)", // front -> knee joint -> left // 18
			"knee joint (left)", // front -> knee joint -> right // 19
			"lower leg (right)", // front -> calves -> left // 20
			"lower leg (left)", // front -> calves -> right // 21
			"ankle (right)", // front -> feet -> left // 22
			"ankle (left)", // front -> feet -> right // 23
			"foot (right)", // front -> feet -> left // 24
			"foot (left)", // front -> feet -> right // 25
			"biceps (right)", // front -> biceps -> left // 26
			"biceps (left)", // front -> bicepts -> right // 27
			"elbow joint (right)", // front -> elbow joint -> left // 28
			"elbow joint (left)", // front -> elbow joint -> right // 29
			"forearms (right)", // front -> forearms -> left // 30
			"forearms (left)", // front -> forearms -> right // 31
			"wrist joint (right)", // front -> wrist joint -> left // 32
			"wrist joint (left)", // front -> wrist joint -> right // 33
			"hand (right)", // front -> hand -> left // 34
			"hand (left)", // front -> hand -> right // 35
			"head (back side)", // back -> head // 36
			"neck (back side)", // back -> neck // 37
			"traps (left)", // back -> traps -> left // 38
			"traps (right)", // back -> traps -> right // 39
			"upper back (left)", // back -> upperback -> left // 40
			"upper back (right)", // back -> upperback -> right // 41
			"middle back", // back -> middle back // 42
			"lower back", // back -> lower back // 43
			"si joint (left)", // back -> lowerback -> left // 44
			"si joint (right)", // back -> lowerback -> right // 45
			"sacrum", // back -> lowerback -> middle // 46
			"glutes (left)", // back -> glutes -> left // 47
			"glutes (right)", // back -> glutes -> right //
			"upper leg (left)", // back -> lower hip -> left
			"upper leg (right)", // back -> lower hip -> right
			"hamstring (left)", // back -> hamstring -> left
			"hamstring (right)", // back -> hamstring -> right
			"knee joint (left)", // back -> knee joint -> left
			"knee joint (right)", // back -> knee joint -> right
			"upper calves (left)", // back -> upper calves -> left
			"upper calves (right)", // back -> upper calves -> right
			"calves (left)", // back -> lower calves -> left
			"calves (right)", // back -> lower calves -> right
			"heel (left)", // back -> feet -> left
			"heel (right)", // back -> feet -> right
			"shoulder (left)", // back -> shoulder -> left
			"shoulder (right)", // back -> shoulder -> right
			"triceps (left)", // back -> triceps -> left
			"triceps (right)", // back -> triceps -> right
			"elbow joint (left)", // back -> elbow joint -> left
			"elbow joint (right)", // back -> elbow joint -> right
			"forearms (left)", // back -> forearms -> left
			"forearms (right)", // back -> forearms -> right
			"wrist joint (left)", // back -> wrist joint -> left
			"wrist joint (right)", // back -> wrist joint -> right
			"hand (left)", // back -> hand -> left
			"hand (right)", // back -> hand -> right
		];

		let pain_colors = [
			"rgba(225, 229, 232, 0.7)", // 0: comfortable / relaxed
			"rgba(67, 176, 73, 1.0)", // 1: mild discomfort / pain
			"rgba(255, 238, 56, 1.0)", // 2: moderate discomfort / pain
			"rgba(255, 79, 79, 1.0)", // 3: severe discomfort / pain
		];

		let circle_rad = Math.max(5, Math.round(maxWidth * 0.012));

		var DrawSideIndicators = () => {
			let x = 50,
				y = 50,
				w = 80,
				h = 80,
				r = 20; // rectangle location x, y, width, height, radius
			let image_x = (x / imageWidth) * maxWidth;
			let image_y = (y / imageHeight) * maxHeight;
			ctx.fillStyle = "rgba(0, 0, 0, 1.0)";
			ctx.font = `bold ${fontSizeLarge}px Rubik`;
			ctx.textAlign = "left";
			ctx.fillText("Right Side", image_x, image_y);

			x = 250;
			y = 50;
			image_x = (x / imageWidth) * maxWidth;
			image_y = (y / imageHeight) * maxHeight;
			ctx.fillStyle = "rgba(0, 0, 0, 1.0)";
			ctx.font = `bold ${fontSizeLarge}px Rubik`;
			ctx.textAlign = "left";
			ctx.fillText("Left Side", image_x, image_y);

			x = 360;
			y = 50;
			image_x = (x / imageWidth) * maxWidth;
			image_y = (y / imageHeight) * maxHeight;
			ctx.fillStyle = "rgba(0, 0, 0, 1.0)";
			ctx.font = `bold ${fontSizeLarge}px Rubik`;
			ctx.textAlign = "left";
			ctx.fillText("Left Side", image_x, image_y);

			x = 560;
			y = 50;
			image_x = (x / imageWidth) * maxWidth;
			image_y = (y / imageHeight) * maxHeight;
			ctx.fillStyle = "rgba(0, 0, 0, 1.0)";
			ctx.font = `bold ${fontSizeLarge}px Rubik`;
			ctx.textAlign = "left";
			ctx.fillText("Right Side", image_x, image_y);
		};

		var DrawCycle = (painLevels) => {
			ctx.clearRect(image, 0, 0, maxWidth, maxHeight);
			ctx.drawImage(image, 0, 0, maxWidth, maxHeight);
			DrawSideIndicators();
			for (let i = 0; i < image_coordinates.length; i++) {
				let image_x = (image_coordinates[i][0] / imageWidth) * maxWidth;
				let image_y = (image_coordinates[i][1] / imageHeight) * maxHeight;
				let pain_index = Math.max(painLevels[i], 0);
				CanvasEllipse(ctx, image_x, image_y, circle_rad, circle_rad, pain_colors[pain_index]);
			}
		};

		DrawCycle(newPainLevels);

		return painDiagram.toDataURL("image/png");
	};

	const GenerateScreeningQuestionsPdf = (pdf, verticalPointer, imageDb) => {
		var height = pdf.internal.pageSize.getHeight();
		var width = pdf.internal.pageSize.getWidth();
		var verticalStart = verticalPointer;
		var verticalGap = 10;
		var offsetLeft = 20;

		verticalStart = AddVerticalSpace(pdf, 1.5 * verticalGap, height, verticalStart, imageDb);

		var image = imageDb["human.body"];
		// var imageHeight = image.naturalHeight <= 0 ? 600 : image.naturalHeight;
		// var imageWidth = image.naturalWidth <= 0 ? 800 : image.naturalWidth;

		var imageHeight = 779 * 0.5;
		var imageWidth = 684 * 0.5;

		if (height - (verticalStart % height) - OverflowSpace * 2 < (width * 0.4) * (imageHeight / imageWidth)) {
			verticalStart = AddVerticalSpace(pdf, height - (verticalStart % height) + OverflowSpace, height, verticalStart, imageDb);
		}

		pdf.setFontSize(16);
		pdf.setFont(undefined, "bold");
		pdf.text("Screening Questions", offsetLeft, verticalStart % height);
		verticalStart = AddVerticalSpace(pdf, 1.5 * verticalGap, height, verticalStart, imageDb);
		pdf.setFontSize(10);
		pdf.setFont(undefined, "normal");

		var imageBase64 = DrawHumanCanvas(image);
		pdf.addImage(imageBase64, 50, verticalStart % height, width * 0.4, (width * 0.4) * (imageHeight / imageWidth), null, "NONE");
		verticalStart = AddVerticalSpace(pdf, (width * 0.4) * (imageHeight / imageWidth), height, verticalStart, imageDb);

		var conditions = [
			"NeckPainStiffness",
			"ShoulderPain",
			"ArthritisNeck",
			"ElbowWristHandPain",
			"Headache",
			"HipKneeAnklePain",
			"JawPain",
			"Diabetes",
			"SkinCondition",
			"Anemia",
			"MidBackPain",
			"LowBackPain",
			"MuscleCrampSpasm",
			"TailbonePain",
			"CarpalTunnel",
			"NumbnessLegsFeet",
			"SwellingFeetHands",
			"NumbnessArmsHands",
			"ArthritisLimb",
			"Dizziness",
		];

		var conditionLabels = [
			"Neck Pain / Stiffness",
			"Shoulder Pain",
			"Arthritis in Neck",
			"Pain in Elbows / Wrists / Hands",
			"Headaches",
			"Pain in Hips / Knees / Ankles",
			"Jaw Pain / TMJ",
			"Diabetes",
			"Skin Conditions / Rashes",
			"Anemia / Fatigue",
			"Mid Back pain",
			"Low Back Pain / Stiffness",
			"Muscle Cramps / Spasms",
			"Tailbone Pain",
			"Carpal Tunnel",
			"Numbness / Tinglings in Legs / Feet",
			"Swelling Feet / Hands",
			"Numbness / Tinglings in Arms / Hands",
			"Arthritis in Limbs",
			"Dizziness",
		];

		for (var i = 0; i < conditions.length; i += 2) {
			var k1 = conditions[i];
			var k2 = conditions[i + 1];
			if (data.intake.ScreeningQuestions[k1]) {
				pdf.addImage(CheckboxSelectedImg, 50, verticalStart % height, 8, 8, null, "NONE");
			} else {
				pdf.addImage(CheckboxDSelectedImg, 50, verticalStart % height, 8, 8, null, "NONE");
			}
			pdf.text(conditionLabels[i], 60, (verticalStart + 8) % height);

			if (data.intake.ScreeningQuestions[k2]) {
				pdf.addImage(CheckboxSelectedImg, width / 2, verticalStart % height, 8, 8, null, "NONE");
			} else {
				pdf.addImage(CheckboxDSelectedImg, width / 2, verticalStart % height, 8, 8, null, "NONE");
			}
			pdf.text(conditionLabels[i + 1], width / 2 + 10, (verticalStart + 8) % height);

			verticalStart = AddVerticalSpace(pdf, verticalGap, height, verticalStart, imageDb);
		}

		verticalStart = AddVerticalSpace(pdf, verticalGap, height, verticalStart, imageDb);
		pdf.text(`Do you have any allergies? ${data.intake.ScreeningQuestions.Allergies.length > 0 ? "Yes" : "No"}`, offsetLeft, verticalStart % height);
		if (data.intake.ScreeningQuestions.Allergies.length > 0) {
			var allergies = "";
			for (let i = 0; i < data.intake.ScreeningQuestions.Allergies.length; i++) {
				allergies += data.intake.ScreeningQuestions.Allergies[i];
				if (i < data.intake.ScreeningQuestions.Allergies.length - 1) {
					allergies += ", ";
				}
			}
			verticalStart = AddVerticalSpace(pdf, verticalGap, height, verticalStart, imageDb);
			pdf.text(`Allergies: ${allergies}`, offsetLeft, verticalStart % height);
			verticalStart = AddVerticalSpace(pdf, verticalGap, height, verticalStart, imageDb);
			pdf.text(`Allergy Notes: ${data.intake.ScreeningQuestions.AllergyNotes}`, offsetLeft, verticalStart % height);
		}

		verticalStart = AddVerticalSpace(pdf, verticalGap, height, verticalStart, imageDb);
		pdf.text(`Do you take any medications? ${data.intake.ScreeningQuestions.Medications.length > 0 ? "Yes" : "No"}`, offsetLeft, verticalStart % height);
		if (data.intake.ScreeningQuestions.Medications.length > 0) {
			var medications = "";
			for (let i = 0; i < data.intake.ScreeningQuestions.Medications.length; i++) {
				medications += data.intake.ScreeningQuestions.Medications[i];
				if (i < data.intake.ScreeningQuestions.Medications.length - 1) {
					medications += ", ";
				}
			}
			verticalStart = AddVerticalSpace(pdf, verticalGap, height, verticalStart, imageDb);
			pdf.text(`Medications: ${medications}`, offsetLeft, verticalStart % height);
			verticalStart = AddVerticalSpace(pdf, verticalGap, height, verticalStart, imageDb);
			pdf.text(`Medication Notes: ${data.intake.ScreeningQuestions.MedicationNotes}`, offsetLeft, verticalStart % height);
		}

		verticalStart = AddVerticalSpace(pdf, verticalGap, height, verticalStart, imageDb);
		pdf.text(`Do you regularly use any medical devices? ${data.intake.ScreeningQuestions.MedicalDevices.length > 0 ? "Yes" : "No"}`, offsetLeft, verticalStart % height);
		if (data.intake.ScreeningQuestions.MedicalDevices.length > 0) {
			var medicalDvs = "";
			for (let i = 0; i < data.intake.ScreeningQuestions.MedicalDevices.length; i++) {
				medicalDvs += data.intake.ScreeningQuestions.MedicalDevices[i];
				if (i < data.intake.ScreeningQuestions.MedicalDevices.length - 1) {
					medicalDvs += ", ";
				}
			}
			verticalStart = AddVerticalSpace(pdf, verticalGap, height, verticalStart, imageDb);
			pdf.text(`Medical Devices: ${medicalDvs}`, offsetLeft, verticalStart % height);
			verticalStart = AddVerticalSpace(pdf, verticalGap, height, verticalStart, imageDb);
			pdf.text(`Medical Device Notes: ${data.intake.ScreeningQuestions.MedicalDeviceNotes}`, offsetLeft, verticalStart % height);
		}

		verticalStart = AddVerticalSpace(pdf, verticalGap, height, verticalStart, imageDb);
		pdf.text(`Additional Comments: ${data.intake.ScreeningQuestions.OtherNotes}`, offsetLeft, verticalStart % height);

		verticalStart = AddVerticalSpace(pdf, verticalGap, height, verticalStart, imageDb);
		return verticalStart;
	};

	const GenerateFunctionalQuestionsPdf = (pdf, verticalPointer, imageDb) => {
		var height = pdf.internal.pageSize.getHeight();
		var width = pdf.internal.pageSize.getWidth();
		var verticalStart = verticalPointer;
		var verticalGap = 10;
		var offsetLeft = 20;
		var horizontalStart = 30;
		var horizontalMidPoint = width / 2;

		verticalStart = AddVerticalSpace(pdf, verticalGap, height, verticalStart, imageDb);

		if (height - (verticalStart % height) < OverflowSpace * 2) {
			verticalStart = AddVerticalSpace(pdf, height - (verticalStart % height) + OverflowSpace, height, verticalStart, imageDb);
		}

		pdf.setFontSize(16);
		pdf.setFont(undefined, "bold");
		pdf.text("Functional Questions", offsetLeft, verticalStart % height);
		verticalStart = AddVerticalSpace(pdf, 5 * verticalGap, height, verticalStart, imageDb);

		pdf.setDrawColor(33, 136, 56);
		pdf.setFillColor(33, 136, 56);

		pdf.roundedRect(horizontalStart, (verticalStart - 5) % height, 40, 30, 10, 10, "F");
		pdf.roundedRect(horizontalStart + 80 * 1, (verticalStart - 5) % height, 40, 30, 10, 10, "F");
		pdf.roundedRect(horizontalStart + 80 * 2, (verticalStart - 5) % height, 40, 30, 10, 10, "F");
		pdf.roundedRect(horizontalStart + 80 * 3, (verticalStart - 5) % height, 40, 30, 10, 10, "F");
		pdf.roundedRect(horizontalStart + 80 * 4, (verticalStart - 5) % height, 40, 30, 10, 10, "F");

		pdf.setTextColor("white");
		pdf.setFontSize(16);
		pdf.setFont(undefined, "bold");
		pdf.text("0", horizontalStart + 20, ((verticalStart - 5) % height) + 20, { align: "center" });
		pdf.text("1", horizontalStart + 20 + 80 * 1, ((verticalStart - 5) % height) + 20, { align: "center" });
		pdf.text("2", horizontalStart + 20 + 80 * 2, ((verticalStart - 5) % height) + 20, { align: "center" });
		pdf.text("3", horizontalStart + 20 + 80 * 3, ((verticalStart - 5) % height) + 20, { align: "center" });
		pdf.text("4", horizontalStart + 20 + 80 * 4, ((verticalStart - 5) % height) + 20, { align: "center" });

		pdf.setTextColor("#2994d1");
		pdf.setFontSize(12);
		pdf.setFont(undefined, "bold");
		pdf.text("Unable", horizontalStart + 20, (verticalStart - 5 + 20 - 25) % height, { align: "center" });
		pdf.text("Very", horizontalStart + 20 + 80 * 1, (verticalStart - 5 + 20 - 35) % height, { align: "center" });
		pdf.text("Difficult", horizontalStart + 20 + 80 * 1, (verticalStart - 5 + 20 - 25) % height, { align: "center" });
		pdf.text("Moderately", horizontalStart + 20 + 80 * 2, (verticalStart - 5 + 20 - 35) % height, { align: "center" });
		pdf.text("Difficult", horizontalStart + 20 + 80 * 2, (verticalStart - 5 + 20 - 25) % height, { align: "center" });
		pdf.text("Minimally", horizontalStart + 20 + 80 * 3, (verticalStart - 5 + 20 - 35) % height, { align: "center" });
		pdf.text("Difficult", horizontalStart + 20 + 80 * 3, (verticalStart - 5 + 20 - 25) % height, { align: "center" });
		pdf.text("Normal", horizontalStart + 20 + 80 * 4, (verticalStart - 5 + 20 - 25) % height, { align: "center" });

		verticalStart = AddVerticalSpace(pdf, 50, height, verticalStart, imageDb);

		pdf.setDrawColor("#6c7d85");
		pdf.setFillColor("#6c7d85");
		pdf.setLineWidth(2);
		pdf.line(horizontalMidPoint - 100, verticalStart % height, horizontalMidPoint + 100, verticalStart % height, "F");

		verticalStart = AddVerticalSpace(pdf, 20, height, verticalStart, imageDb);

		for (var i = 0; i < QuesOrder.length; i++) {
			var index = QuesOrder[i];
			if (index === 1) {
				pdf.setTextColor("#2994d1");
				pdf.setFontSize(12);
				pdf.setFont(undefined, "bold");
				pdf.text("SLEEPING", horizontalStart + 5, verticalStart % height, { align: "left" });
				verticalStart = AddVerticalSpace(pdf, 15, height, verticalStart, imageDb);
			} else if (index === 2) {
				pdf.setTextColor("#2994d1");
				pdf.setFontSize(12);
				pdf.setFont(undefined, "bold");
				pdf.text("SITTING", horizontalStart + 5, verticalStart % height, { align: "left" });
				verticalStart = AddVerticalSpace(pdf, 15, height, verticalStart, imageDb);
			} else if (index === 4) {
				pdf.setTextColor("#2994d1");
				pdf.setFontSize(12);
				pdf.setFont(undefined, "bold");
				pdf.text("MOVING - STANDING/WALKING/RUNNING", horizontalStart + 5, verticalStart % height, { align: "left" });
				verticalStart = AddVerticalSpace(pdf, 15, height, verticalStart, imageDb);
			} else if (index === 10) {
				pdf.setTextColor("#2994d1");
				pdf.setFontSize(12);
				pdf.setFont(undefined, "bold");
				pdf.text("DAILY ACTIVITIES", horizontalStart + 5, verticalStart % height, { align: "left" });
				verticalStart = AddVerticalSpace(pdf, 15, height, verticalStart, imageDb);
			} else if (index === 16) {
				pdf.setTextColor("#2994d1");
				pdf.setFontSize(12);
				pdf.setFont(undefined, "bold");
				pdf.text("JOB ACTIVITIES", horizontalStart + 5, verticalStart % height, { align: "left" });
				verticalStart = AddVerticalSpace(pdf, 15, height, verticalStart, imageDb);
			}

			pdf.setTextColor("#000000");
			pdf.setFontSize(10);
			pdf.setFont(undefined, "normal");
			pdf.text(`${i + 1}. ${QuesList[index - 1]}`, horizontalStart + 5, verticalStart % height, { align: "left" });
			verticalStart = AddVerticalSpace(pdf, 15, height, verticalStart, imageDb);

			var horizontalStartingPointRect = horizontalMidPoint - 30 * 3 + 15;
			var horizontalStartingPointText = horizontalMidPoint - 30 * 3 + 15 + 8;

			pdf.setFontSize(8);
			pdf.setFont(undefined, "bold");

			for (let j = 0; j < 6; j++) {
				if (data.intake.FunctionalQuestions.Answers[index - 1] === j) {
					pdf.setDrawColor(41, 148, 209);
					pdf.setFillColor(41, 148, 209);
					pdf.setTextColor("#ffffff");
				} else {
					pdf.setDrawColor(217, 240, 252);
					pdf.setFillColor(217, 240, 252);
					pdf.setTextColor("#6c7d85");
				}
				pdf.roundedRect(horizontalStartingPointRect + j * 30, (verticalStart - 5) % height, 15, 15, 15, 15, "F");
				pdf.text(j === 5 ? "NA" : `${j}`, horizontalStartingPointText + j * 30, (verticalStart - 5 + 10) % height, {
					align: "center",
				});
			}

			verticalStart = AddVerticalSpace(pdf, 25, height, verticalStart, imageDb);
		}

		verticalStart = AddVerticalSpace(pdf, verticalGap, height, verticalStart, imageDb);
		return verticalStart;
	};

	const GeneratePreviousTreatmentsPdf = (pdf, verticalPointer, imageDb) => {
		var height = pdf.internal.pageSize.getHeight();
		var width = pdf.internal.pageSize.getWidth();
		var verticalStart = verticalPointer;
		var verticalGap = 10;
		var offsetLeft = 20;

		verticalStart = AddVerticalSpace(pdf, verticalGap, height, verticalStart, imageDb);

		pdf.setFontSize(16);
		pdf.setFont(undefined, "bold");
		pdf.text("Previous Treatments and Procedures", offsetLeft, verticalStart % height);
		verticalStart = AddVerticalSpace(pdf, 1.5 * verticalGap, height, verticalStart, imageDb);
		pdf.setFontSize(10);
		pdf.setFont(undefined, "normal");

		const PrintCheckboxRow = (a, b, c, d) => {
			if (data.intake.PreviousTreatments[a]) {
				pdf.addImage(CheckboxSelectedImg, offsetLeft, verticalStart % height, 8, 8, null, "NONE");
			} else {
				pdf.addImage(CheckboxDSelectedImg, offsetLeft, verticalStart % height, 8, 8, null, "NONE");
			}
			pdf.text(b, offsetLeft + 10, (verticalStart + 6) % height);
			if (data.intake.PreviousTreatments[c]) {
				pdf.addImage(CheckboxSelectedImg, width / 2, verticalStart % height, 8, 8, null, "NONE");
			} else {
				pdf.addImage(CheckboxDSelectedImg, width / 2, verticalStart % height, 8, 8, null, "NONE");
			}
			pdf.text(d, width / 2 + 10, (verticalStart + 6) % height);
			verticalStart = AddVerticalSpace(pdf, verticalGap, height, verticalStart, imageDb);
		};

		PrintCheckboxRow("Medication", "Medication", "Physiotherapy", "Physiotherapy");
		PrintCheckboxRow("Chiropractic", "Chiropractic", "Massage", "Massage");
		PrintCheckboxRow("Acupuncture", "Acupuncture", "Naturopathic", "Naturopathic");

		verticalStart = AddVerticalSpace(pdf, verticalGap, height, verticalStart, imageDb);
		pdf.text(`Other Treatments: ${data.intake.PreviousTreatments.OtherTreatment}`, offsetLeft, verticalStart % height);
		verticalStart = AddVerticalSpace(pdf, verticalGap, height, verticalStart, imageDb);

		if (data.intake.PreviousTreatments.JointInjection) {
			pdf.addImage(CheckboxSelectedImg, offsetLeft, verticalStart % height, 8, 8, null, "NONE");
		} else {
			pdf.addImage(CheckboxDSelectedImg, offsetLeft, verticalStart % height, 8, 8, null, "NONE");
		}
		pdf.text("Joint Injection", offsetLeft + 10, (verticalStart + 6) % height);
		verticalStart = AddVerticalSpace(pdf, verticalGap, height, verticalStart, imageDb);

		verticalStart = AddVerticalSpace(pdf, verticalGap, height, verticalStart, imageDb);
		pdf.text("Type of Injection(s):", offsetLeft, verticalStart % height);
		verticalStart = AddVerticalSpace(pdf, verticalGap, height, verticalStart, imageDb);

		PrintCheckboxRow("Steriod", "Steriod", "Anesthetic", "Anesthetic (lidocaine)");

		if (data.intake.PreviousTreatments.TriggerPoint) {
			pdf.addImage(CheckboxSelectedImg, offsetLeft, verticalStart % height, 8, 8, null, "NONE");
		} else {
			pdf.addImage(CheckboxDSelectedImg, offsetLeft, verticalStart % height, 8, 8, null, "NONE");
		}
		pdf.text("Trigger Point", offsetLeft + 10, (verticalStart + 6) % height);
		verticalStart = AddVerticalSpace(pdf, verticalGap, height, verticalStart, imageDb);

		verticalStart = AddVerticalSpace(pdf, verticalGap, height, verticalStart, imageDb);
		pdf.text(`Other Injections: ${data.intake.PreviousTreatments.OtherInjection}`, offsetLeft, verticalStart % height);
		verticalStart = AddVerticalSpace(pdf, verticalGap, height, verticalStart, imageDb);

		verticalStart = AddVerticalSpace(pdf, verticalGap, height, verticalStart, imageDb);
		pdf.text(`Have you had surgery or a procedure for this condition? ${data.intake.PreviousTreatments.Surgeries.length > 0 ? "Yes" : "No"}`, offsetLeft, verticalStart % height);

		if (data.intake.PreviousTreatments.Surgeries.length > 0) {
			for (var j = 0; j < data.intake.PreviousTreatments.Surgeries.length; j++) {
				verticalStart = AddVerticalSpace(pdf, verticalGap, height, verticalStart, imageDb);
				pdf.text(`Surgery #${j + 1}`, offsetLeft, verticalStart % height);
				verticalStart = AddVerticalSpace(pdf, verticalGap, height, verticalStart, imageDb);
				pdf.text(`Surgery Name: ${data.intake.PreviousTreatments.Surgeries[j].SurgeryName}`, offsetLeft, verticalStart % height);
				verticalStart = AddVerticalSpace(pdf, verticalGap, height, verticalStart, imageDb);
				pdf.text(`Surgeon Name: ${data.intake.PreviousTreatments.Surgeries[j].SurgeonName}`, offsetLeft, verticalStart % height);
				verticalStart = AddVerticalSpace(pdf, verticalGap, height, verticalStart, imageDb);
				pdf.text(`Surgery Date: ${new Date(data.intake.PreviousTreatments.Surgeries[j].SurgeryDate).toLocaleDateString(dateLocale, bDayFormat)}`, offsetLeft, verticalStart % height);
			}
		}

		verticalStart = AddVerticalSpace(pdf, verticalGap, height, verticalStart, imageDb);
		pdf.text("Which Diagnostic test(s) or Investigations have you had for this condition?", offsetLeft, verticalStart % height);
		verticalStart = AddVerticalSpace(pdf, verticalGap, height, verticalStart, imageDb);

		PrintCheckboxRow("XRay", "X-Ray", "Mri", "MRI");
		PrintCheckboxRow("CtScan", "CT Scan", "UltraSound", "Ultra Sound");
		PrintCheckboxRow("BoneScan", "Bone Scan", "BloodTest", "Blood Test");

		var labels = ["Excellent", "Very Good", "Good", "Fair", "Poor"];
		verticalStart = AddVerticalSpace(pdf, 1.5 * verticalGap, height, verticalStart, imageDb);
		pdf.text(`In general would you say your health is: ${labels[data.intake.PreviousTreatments.OverallHealth]}`, offsetLeft, verticalStart % height);

		verticalStart = AddVerticalSpace(pdf, verticalGap, height, verticalStart, imageDb);
		return verticalStart;
	};

	const GenerateHealthReportPdf = (pdf, verticalPointer, imageDb) => {
		var height = pdf.internal.pageSize.getHeight();
		var width = pdf.internal.pageSize.getWidth();
		var verticalStart = verticalPointer;
		var verticalGap = 10;
		var offsetLeft = 20;

		verticalStart = AddVerticalSpace(pdf, 1.5 * verticalGap, height, verticalStart, imageDb);

		pdf.setFontSize(16);
		pdf.setFont(undefined, "bold");
		pdf.text("Health Questionnaire", offsetLeft, verticalStart % height);
		verticalStart = AddVerticalSpace(pdf, 1.5 * verticalGap, height, verticalStart, imageDb);
		pdf.setFontSize(10);
		pdf.setFont(undefined, "normal");
		pdf.text("General", offsetLeft, verticalStart % height);
		verticalStart = AddVerticalSpace(pdf, verticalGap, height, verticalStart, imageDb);

		const RenderRow = (a, b, c, d, e, f) => {
			if (data.intake.HealthReport[a]) {
				pdf.addImage(PresSelectedImg, offsetLeft, verticalStart % height, 8, 8, null, "NONE");
			} else {
				pdf.addImage(PresDSelectedImg, offsetLeft, verticalStart % height, 8, 8, null, "NONE");
			}
			if (data.intake.HealthReport[b]) {
				pdf.addImage(PrevSelectedImg, offsetLeft + 10, verticalStart % height, 8, 8, null, "NONE");
			} else {
				pdf.addImage(PrevDSelectedImg, offsetLeft + 10, verticalStart % height, 8, 8, null, "NONE");
			}
			pdf.text(c, offsetLeft + 20, (verticalStart + 6) % height);
			if (data.intake.HealthReport[d]) {
				pdf.addImage(PresSelectedImg, width / 2, verticalStart % height, 8, 8, null, "NONE");
			} else {
				pdf.addImage(PresDSelectedImg, width / 2, verticalStart % height, 8, 8, null, "NONE");
			}
			if (data.intake.HealthReport[e]) {
				pdf.addImage(PrevSelectedImg, width / 2 + 10, verticalStart % height, 8, 8, null, "NONE");
			} else {
				pdf.addImage(PrevDSelectedImg, width / 2 + 10, verticalStart % height, 8, 8, null, "NONE");
			}
			pdf.text(f, width / 2 + 20, (verticalStart + 6) % height);
			verticalStart = AddVerticalSpace(pdf, verticalGap, height, verticalStart, imageDb);
		};

		const RenderRowCustom = (a, b, c) => {
			if (data.intake.HealthReport[a]) {
				pdf.addImage(PresSelectedImg, offsetLeft, verticalStart % height, 8, 8, null, "NONE");
			} else {
				pdf.addImage(PresDSelectedImg, offsetLeft, verticalStart % height, 8, 8, null, "NONE");
			}
			if (data.intake.HealthReport[b]) {
				pdf.addImage(PrevSelectedImg, offsetLeft + 10, verticalStart % height, 8, 8, null, "NONE");
			} else {
				pdf.addImage(PrevDSelectedImg, offsetLeft + 10, verticalStart % height, 8, 8, null, "NONE");
			}
			pdf.text(c, offsetLeft + 20, (verticalStart + 6) % height);
			verticalStart = AddVerticalSpace(pdf, verticalGap, height, verticalStart, imageDb);
		};

		RenderRow("GeneralDiabetesPrev", "GeneralDiabetesPres", "Diabetes", "GeneralHypoglycemiaPrev", "GeneralHypoglycemiaPres", "Hypoglycemia");
		RenderRow("GeneralStressPrev", "GeneralStressPres", "Stress", "GeneralEpilepsyPrev", "GeneralEpilepsyPres", "Epilepsy");
		RenderRow("GeneralSkinConditionPrev", "GeneralSkinConditionPres", "Skin Condition", "GeneralAlcoholismPrev", "GeneralAlcoholismPres", "Alcoholism");
		RenderRow("GeneralHighCholesterolPrev", "GeneralHighCholesterolPres", "High Cholesterol", "GeneralParkinsonPrev", "GeneralParkinsonPres", "Parkinson");
		RenderRow("GeneralHeartPrev", "GeneralHeartPres", "Heart Disease", "GeneralCancerPrev", "GeneralCancerPres", "Cancer");

		verticalStart = AddVerticalSpace(pdf, verticalGap, height, verticalStart, imageDb);
		pdf.text("Immune System", offsetLeft, verticalStart % height);
		verticalStart = AddVerticalSpace(pdf, verticalGap, height, verticalStart, imageDb);

		RenderRow("ImmuneSystemHivPrev", "ImmuneSystemHivPres", "HIV", "ImmuneSystemPneumoniaPrev", "ImmuneSystemPneumoniaPres", "Pneumonia");
		RenderRow("ImmuneSystemAllergiesPrev", "ImmuneSystemAllergiesPres", "Allergies / Sinus Troubles", "ImmuneSystemColdPrev", "ImmuneSystemColdPres", "Catch Colds / Flue Easily");
		RenderRow("ImmuneSystemRheumatoidPrev", "ImmuneSystemRheumatoidPres", "Rheumatadoid Arthrities", "ImmuneSystemVenerealPrev", "ImmuneSystemVenerealPres", "Venereal Disease");
		RenderRow("ImmuneSystemTuberculosisPrev", "ImmuneSystemTuberculosisPres", "Tuberculosis", "ImmuneSystemRheumaticPrev", "ImmuneSystemRheumaticPres", "Rheumatic Fever");

		verticalStart = AddVerticalSpace(pdf, verticalGap, height, verticalStart, imageDb);
		pdf.text("Cardiovascular System", offsetLeft, verticalStart % height);
		verticalStart = AddVerticalSpace(pdf, verticalGap, height, verticalStart, imageDb);

		RenderRow("CardiovascularIrregularHeartbeatPrev", "CardiovascularIrregularHeartbeatPres", "Irregular Heartbeat", "CardiovascularStrokePrev", "CardiovascularStrokePres", "Stroke / Heart Attack");
		RenderRow(
			"CardiovascularLowBloodPressurePrev",
			"CardiovascularLowBloodPressurePres",
			"Low Blood Pressure",
			"CardiovascularHighBloodPressurePrev",
			"CardiovascularHighBloodPressurePres",
			"High Blood Pressure"
		);
		RenderRow("CardiovascularPalpitationsPrev", "CardiovascularPalpitationsPres", "Palpitations", "CardiovascularAnkleSwellPrev", "CardiovascularAnkleSwellPres", "Swelling Ankles");
		RenderRow(
			"CardiovascularChestPainPrev",
			"CardiovascularChestPainPres",
			"Pain / Pressure in Chest",
			"CardiovascularShortnessBreathPrev",
			"CardiovascularShortnessBreathPres",
			"Shortness of Breath"
		);

		verticalStart = AddVerticalSpace(pdf, verticalGap, height, verticalStart, imageDb);
		pdf.text("Gastrointestinal System", offsetLeft, verticalStart % height);
		verticalStart = AddVerticalSpace(pdf, verticalGap, height, verticalStart, imageDb);

		RenderRow("GastrointestinalGallBladderPrev", "GastrointestinalGallBladderPres", "Gall Bladder Problem", "GastrointestinalColitisPrev", "GastrointestinalColitisPres", "Colitis");
		RenderRow("GastrointestinalStoolBloodPrev", "GastrointestinalStoolBloodPres", "Blood in Stool", "GastrointestinalConstipationPrev", "GastrointestinalConstipationPres", "Constipation");
		RenderRow("GastrointestinalStomachBurnPrev", "GastrointestinalStomachBurnPres", "Burning in Stomach", "GastrointestinalHeartBurnPrev", "GastrointestinalHeartBurnPres", "Heartburn");
		RenderRow("GastrointestinalExcessiveThirstPrev", "GastrointestinalExcessiveThirstPres", "Excessive Thirst", "GastrointestinalVomitingPrev", "GastrointestinalVomitingPres", "Vomiting");
		RenderRow(
			"GastrointestinalRecentWeightGainPrev",
			"GastrointestinalRecentWeightGainPres",
			"Recent Weight Gain",
			"GastrointestinalHepatitisPrev",
			"GastrointestinalHepatitisPres",
			"Liver Trouble / Hepatitis"
		);
		RenderRow("GastrointestinalStoolMucusPrev", "GastrointestinalStoolMucusPres", "Mucus in Stool", "GastrointestinalDiarreaPrev", "GastrointestinalDiarreaPres", "Diarrhea");
		RenderRow("GastrointestinalStomachPainPrev", "GastrointestinalStomachPainPres", "Pain Over Stomach", "GastrointestinalBurpingPrev", "GastrointestinalBurpingPres", "Burping / Bloating");
		RenderRow("GastrointestinalDistressGreasyFoodPrev", "GastrointestinalDistressGreasyFoodPres", "Distress from Greasy Food", "GastrointestinalNauseaPrev", "GastrointestinalNauseaPres", "Nausea");
		RenderRow("GastrointestinalHerniaPrev", "GastrointestinalHerniaPres", "Hiatal Hernia", "GastrointestinalMetalicTastePrev", "GastrointestinalMetalicTastePres", "Metalic Taste");
		RenderRowCustom("GastrointestinalRecentWeightLossPrev", "GastrointestinalRecentWeightLossPres", "Recent Weight Loss");

		verticalStart = AddVerticalSpace(pdf, verticalGap, height, verticalStart, imageDb);
		pdf.text("Neuromusculoskeletal Systems (Head & Neck)", offsetLeft, verticalStart % height);
		verticalStart = AddVerticalSpace(pdf, verticalGap, height, verticalStart, imageDb);

		RenderRow(
			"NeuromusculoskeletalSystemsHeadNeckHeadachePrev",
			"NeuromusculoskeletalSystemsHeadNeckHeadachePres",
			"Headache",
			"NeuromusculoskeletalSystemsHeadNeckNeckPainPrev",
			"NeuromusculoskeletalSystemsHeadNeckNeckPainPres",
			"Neck Pain"
		);
		RenderRow(
			"NeuromusculoskeletalSystemsHeadNeckPinchedNervePrev",
			"NeuromusculoskeletalSystemsHeadNeckPinchedNervePres",
			"Pinched Nerve",
			"NeuromusculoskeletalSystemsHeadNeckJawPainPrev",
			"NeuromusculoskeletalSystemsHeadNeckJawPainPres",
			"Jaw Pain"
		);
		RenderRowCustom("NeuromusculoskeletalSystemsHeadNeckArthritisNeckPrev", "NeuromusculoskeletalSystemsHeadNeckArthritisNeckPres", "Arthritis in Neck");

		verticalStart = AddVerticalSpace(pdf, verticalGap, height, verticalStart, imageDb);
		pdf.text("Neuromusculoskeletal Systems (Shoulder)", offsetLeft, verticalStart % height);
		verticalStart = AddVerticalSpace(pdf, verticalGap, height, verticalStart, imageDb);

		RenderRow(
			"NeuromusculoskeletalSystemsShoulderShoulderPainPrev",
			"NeuromusculoskeletalSystemsShoulderShoulderPainPres",
			"Shoulder Pain",
			"NeuromusculoskeletalSystemsShoulderRaiseArmPrev",
			"NeuromusculoskeletalSystemsShoulderRaiseArmPres",
			"Can't Raise Arm"
		);
		RenderRow(
			"NeuromusculoskeletalSystemsShoulderArthritisPrev",
			"NeuromusculoskeletalSystemsShoulderArthritisPres",
			"Arthritis",
			"NeuromusculoskeletalSystemsShoulderBursitisPrev",
			"NeuromusculoskeletalSystemsShoulderBursitisPres",
			"Bursitis"
		);
		RenderRowCustom("NeuromusculoskeletalSystemsShoulderPoppingPrev", "NeuromusculoskeletalSystemsShoulderPoppingPres", "Clicking / Popping");

		verticalStart = AddVerticalSpace(pdf, verticalGap, height, verticalStart, imageDb);
		pdf.text("Neuromusculoskeletal Systems (Mid-Back)", offsetLeft, verticalStart % height);
		verticalStart = AddVerticalSpace(pdf, verticalGap, height, verticalStart, imageDb);

		RenderRow(
			"NeuromusculoskeletalSystemsMidBackMidBackPainPrev",
			"NeuromusculoskeletalSystemsMidBackMidBackPainPres",
			"Mid-Back Pain",
			"NeuromusculoskeletalSystemsMidBackPainBetweenShouldersPrev",
			"NeuromusculoskeletalSystemsMidBackPainBetweenShouldersPres",
			"Pain Between Shoulders"
		);

		verticalStart = AddVerticalSpace(pdf, verticalGap, height, verticalStart, imageDb);
		pdf.text("Neuromusculoskeletal Systems (Low-Back)", offsetLeft, verticalStart % height);
		verticalStart = AddVerticalSpace(pdf, verticalGap, height, verticalStart, imageDb);

		RenderRow(
			"NeuromusculoskeletalSystemsLowBackLowBackPainPrev",
			"NeuromusculoskeletalSystemsLowBackLowBackPainPres",
			"Low Back Pain",
			"NeuromusculoskeletalSystemsLowBackLowBackStiffnessPrev",
			"NeuromusculoskeletalSystemsLowBackLowBackStiffnessPres",
			"Low Back Stiffness"
		);
		RenderRow(
			"NeuromusculoskeletalSystemsLowBackTailbonePainPrev",
			"NeuromusculoskeletalSystemsLowBackTailbonePainPres",
			"Tailbone Pain",
			"NeuromusculoskeletalSystemsLowBackMuscleSpasmsPrev",
			"NeuromusculoskeletalSystemsLowBackMuscleSpasmsPres",
			"Muscle Spasms"
		);
		RenderRowCustom("NeuromusculoskeletalSystemsLowBackPinchedNervePrev", "NeuromusculoskeletalSystemsLowBackPinchedNervePres", "Pinched Nerve");

		verticalStart = AddVerticalSpace(pdf, verticalGap, height, verticalStart, imageDb);
		pdf.text("Neuromusculoskeletal Systems (Arms & Hands)", offsetLeft, verticalStart % height);
		verticalStart = AddVerticalSpace(pdf, verticalGap, height, verticalStart, imageDb);

		RenderRow(
			"NeuromusculoskeletalSystemsArmsHandsElbowArmPainPrev",
			"NeuromusculoskeletalSystemsArmsHandsElbowArmPainPres",
			"Pain in Elbow / Arm",
			"NeuromusculoskeletalSystemsArmsHandsForearmPainPrev",
			"NeuromusculoskeletalSystemsArmsHandsForearmPainPres",
			"Pain in Forearm"
		);
		RenderRow(
			"NeuromusculoskeletalSystemsArmsHandsHandsFingersPainPrev",
			"NeuromusculoskeletalSystemsArmsHandsHandsFingersPainPres",
			"Pain in Hands / Fingers",
			"NeuromusculoskeletalSystemsArmsHandsArthritisSwollenHandsPrev",
			"NeuromusculoskeletalSystemsArmsHandsArthritisSwollenHandsPres",
			"Arthritis / Swollen Hands"
		);
		RenderRow(
			"NeuromusculoskeletalSystemsArmsHandsColdHandsPrev",
			"NeuromusculoskeletalSystemsArmsHandsColdHandsPres",
			"Cold Hands",
			"NeuromusculoskeletalSystemsArmsHandsCarpalTunnelPrev",
			"NeuromusculoskeletalSystemsArmsHandsCarpalTunnelPres",
			"Carpal Tunnel"
		);
		RenderRow(
			"NeuromusculoskeletalSystemsArmsHandsHandsTinglingPrev",
			"NeuromusculoskeletalSystemsArmsHandsHandsTinglingPres",
			"Tingling in Hands",
			"NeuromusculoskeletalSystemsArmsHandsLossOfGripPrev",
			"NeuromusculoskeletalSystemsArmsHandsLossOfGripPres",
			"Loss of Grip Strength"
		);

		verticalStart = AddVerticalSpace(pdf, verticalGap, height, verticalStart, imageDb);
		pdf.text("Neuromusculoskeletal Systems (Hips, Legs and Feet)", offsetLeft, verticalStart % height);
		verticalStart = AddVerticalSpace(pdf, verticalGap, height, verticalStart, imageDb);

		RenderRow(
			"NeuromusculoskeletalSystemsHipsLegsFeetButtockPainPrev",
			"NeuromusculoskeletalSystemsHipsLegsFeetButtockPainPres",
			"Pain in Buttock",
			"NeuromusculoskeletalSystemsHipsLegsFeetHipKneePainPrev",
			"NeuromusculoskeletalSystemsHipsLegsFeetHipKneePainPres",
			"Pain in Hip / Knee Joint"
		);
		RenderRow(
			"NeuromusculoskeletalSystemsHipsLegsFeetColdFeetPrev",
			"NeuromusculoskeletalSystemsHipsLegsFeetColdFeetPres",
			"Cold Feet",
			"NeuromusculoskeletalSystemsHipsLegsFeetTinglingFeetPrev",
			"NeuromusculoskeletalSystemsHipsLegsFeetTinglingFeetPres",
			"Tingling in Feet"
		);
		RenderRow(
			"NeuromusculoskeletalSystemsHipsLegsFeetArthritisPrev",
			"NeuromusculoskeletalSystemsHipsLegsFeetArthritisPres",
			"Arthritis",
			"NeuromusculoskeletalSystemsHipsLegsFeetSprainStrainPrev",
			"NeuromusculoskeletalSystemsHipsLegsFeetSprainStrainPres",
			"Sprain / Strain"
		);
		RenderRow(
			"NeuromusculoskeletalSystemsHipsLegsFeetLegPainPrev",
			"NeuromusculoskeletalSystemsHipsLegsFeetLegPainPres",
			"Pain Down Leg(s)",
			"NeuromusculoskeletalSystemsHipsLegsFeetTinglingLegPrev",
			"NeuromusculoskeletalSystemsHipsLegsFeetTinglingLegPres",
			"Tingling in Leg(s)"
		);
		RenderRow(
			"NeuromusculoskeletalSystemsHipsLegsFeetSwollenAnkleFeetPrev",
			"NeuromusculoskeletalSystemsHipsLegsFeetSwollenAnkleFeetPres",
			"Swollen Ankle / Feet",
			"NeuromusculoskeletalSystemsHipsLegsFeetAnkleFeetPainPrev",
			"NeuromusculoskeletalSystemsHipsLegsFeetAnkleFeetPainPres",
			"Ankle / Feet Pain"
		);
		RenderRow(
			"NeuromusculoskeletalSystemsHipsLegsFeetLossOfLegStrengthPrev",
			"NeuromusculoskeletalSystemsHipsLegsFeetLossOfLegStrengthPres",
			"Loss of Leg Strength",
			"NeuromusculoskeletalSystemsHipsLegsFeetMuscleCrampPrev",
			"NeuromusculoskeletalSystemsHipsLegsFeetMuscleCrampPres",
			"Muscles Cramps"
		);

		verticalStart = AddVerticalSpace(pdf, verticalGap, height, verticalStart, imageDb);
		pdf.text("Other Systems (Nervous System)", offsetLeft, verticalStart % height);
		verticalStart = AddVerticalSpace(pdf, verticalGap, height, verticalStart, imageDb);

		RenderRow(
			"OtherSystemsNervousSystemDizzinessPrev",
			"OtherSystemsNervousSystemDizzinessPres",
			"Dizziness / Lighheaded",
			"OtherSystemsNervousSystemFaintingPrev",
			"OtherSystemsNervousSystemFaintingPres",
			"Fainting"
		);
		RenderRow(
			"OtherSystemsNervousSystemDiscoordinationPrev",
			"OtherSystemsNervousSystemDiscoordinationPres",
			"Discoordination",
			"OtherSystemsNervousSystemMemoryLossPrev",
			"OtherSystemsNervousSystemMemoryLossPres",
			"Memory Loss"
		);

		verticalStart = AddVerticalSpace(pdf, verticalGap, height, verticalStart, imageDb);
		pdf.text("Other Systems (Respiratory System)", offsetLeft, verticalStart % height);
		verticalStart = AddVerticalSpace(pdf, verticalGap, height, verticalStart, imageDb);

		RenderRow(
			"OtherSystemsRespiratorySystemChronicCoughPrev",
			"OtherSystemsRespiratorySystemChronicCoughPres",
			"Chronic Cough",
			"OtherSystemsRespiratorySystemSpittingBloodPrev",
			"OtherSystemsRespiratorySystemSpittingBloodPres",
			"Spitting Blood"
		);
		RenderRow(
			"OtherSystemsRespiratorySystemDifficultyBreathingPrev",
			"OtherSystemsRespiratorySystemDifficultyBreathingPres",
			"Difficulty Breathing",
			"OtherSystemsRespiratorySystemShortnessBreathingPrev",
			"OtherSystemsRespiratorySystemShortnessBreathingPres",
			"Shortness of Breath"
		);
		RenderRow(
			"OtherSystemsRespiratorySystemAsthmaPrev",
			"OtherSystemsRespiratorySystemAsthmaPres",
			"Asthma",
			"OtherSystemsRespiratorySystemPhlegmPrev",
			"OtherSystemsRespiratorySystemPhlegmPres",
			"Spitting up Phlegm"
		);
		RenderRow(
			"OtherSystemsRespiratorySystemEmphysemaPrev",
			"OtherSystemsRespiratorySystemEmphysemaPres",
			"Emphysema",
			"OtherSystemsRespiratorySystemCopdPrev",
			"OtherSystemsRespiratorySystemCopdPres",
			"COPD"
		);

		verticalStart = AddVerticalSpace(pdf, verticalGap, height, verticalStart, imageDb);
		pdf.text("Other Systems (Blood Sugar)", offsetLeft, verticalStart % height);
		verticalStart = AddVerticalSpace(pdf, verticalGap, height, verticalStart, imageDb);

		RenderRow(
			"OtherSystemsBloodSugarIrritableBeforeMealsPrev",
			"OtherSystemsBloodSugarIrritableBeforeMealsPres",
			"Irritable Before Meals",
			"OtherSystemsBloodSugarPalpitationsMealsPrev",
			"OtherSystemsBloodSugarPalpitationsMealsPres",
			"Palpitations If Miss Meals"
		);
		RenderRow(
			"OtherSystemsBloodSugarHungryShakyPrev",
			"OtherSystemsBloodSugarHungryShakyPres",
			"Gets Shaky If Hungry",
			"OtherSystemsBloodSugarAwakenSleepPrev",
			"OtherSystemsBloodSugarAwakenSleepPres",
			"Awaken from Sleep"
		);

		verticalStart = AddVerticalSpace(pdf, verticalGap, height, verticalStart, imageDb);
		pdf.text("Other Systems (Eye, Ear, Nose, & Throat)", offsetLeft, verticalStart % height);
		verticalStart = AddVerticalSpace(pdf, verticalGap, height, verticalStart, imageDb);

		RenderRow(
			"OtherSystemsEyeEarNoseThroatVisionPrev",
			"OtherSystemsEyeEarNoseThroatVisionPres",
			"Vision Problems",
			"OtherSystemsEyeEarNoseThroatHoarsenessPrev",
			"OtherSystemsEyeEarNoseThroatHoarsenessPres",
			"Hoarseness"
		);
		RenderRow(
			"OtherSystemsEyeEarNoseThroatNoseBleedingPrev",
			"OtherSystemsEyeEarNoseThroatNoseBleedingPres",
			"Nose Bleeding",
			"OtherSystemsEyeEarNoseThroatEarPainPrev",
			"OtherSystemsEyeEarNoseThroatEarPainPres",
			"Ear Pain"
		);
		RenderRow(
			"OtherSystemsEyeEarNoseThroatDentalPrev",
			"OtherSystemsEyeEarNoseThroatDentalPres",
			"Dental Problems",
			"OtherSystemsEyeEarNoseThroatHearingLossPrev",
			"OtherSystemsEyeEarNoseThroatHearingLossPres",
			"Hearing Loss"
		);
		RenderRow(
			"OtherSystemsEyeEarNoseThroatSoreThroatPrev",
			"OtherSystemsEyeEarNoseThroatSoreThroatPres",
			"Sore Throat",
			"OtherSystemsEyeEarNoseThroatEarRingingPrev",
			"OtherSystemsEyeEarNoseThroatEarRingingPres",
			"Ear Ringing"
		);

		verticalStart = AddVerticalSpace(pdf, verticalGap, height, verticalStart, imageDb);
		pdf.text("Other Systems (Urinary Tract)", offsetLeft, verticalStart % height);
		verticalStart = AddVerticalSpace(pdf, verticalGap, height, verticalStart, imageDb);

		RenderRow(
			"OtherSystemsUrinaryTractBloodUrinePrev",
			"OtherSystemsUrinaryTractBloodUrinePres",
			"Blood in Urine",
			"OtherSystemsUrinaryTractBladderInfectionPrev",
			"OtherSystemsUrinaryTractBladderInfectionPres",
			"Bladder Infection"
		);
		RenderRow(
			"OtherSystemsUrinaryTractUrinationControlPrev",
			"OtherSystemsUrinaryTractUrinationControlPres",
			"Inability to Control Urination",
			"OtherSystemsUrinaryTractKidneyStonePrev",
			"OtherSystemsUrinaryTractKidneyStonePres",
			"Kidney Stones"
		);
		RenderRowCustom("OtherSystemsUrinaryTractUrinationPainPrev", "OtherSystemsUrinaryTractUrinationPainPres", "Urination Pain");

		verticalStart = AddVerticalSpace(pdf, 1.5 * verticalGap, height, verticalStart, imageDb);
		return verticalStart;
	};

	return (
		<Fragment>
			{isLoading ? (
				<div className="preloader-wrapper">
					<Spinner animation="grow" variant="secondary" />
				</div>
			) : (
				<div style={{ width: "100%", height: "100%" }}>
					<div style={{ marginBottom: "5vh" }}>
						<span style={{ cursor: "pointer", fontSize: "1.2rem", fontWeight: "500" }} onClick={onBack}>
							{"<"} back{" "}
						</span>
					</div>

					{!!data.intake && (
						<div style={{ marginBottom: "20px", marginTop: "20px", padding: "5px", textAlign: "center" }}>
							<h4>
								{data?.intake?.Personal.FirstName} {data?.intake?.Personal.LastName}
							</h4>
							<p>
								{data?.intake?.Personal.Email}, {data?.intake?.Personal.Gender} {CalculateAge(data?.intake?.Personal.DateOfBirth)} years
							</p>
						</div>
					)}

					{editMode ? (
						<Fragment>
							<IntakeMain
								data={data.intake}
								companyName={data.businessInfo.BusinessName}
								editMode={true}
								onSubmit={() => {}}
								onSave={OnSaveHandler}
								onBack={() => {
									setEditMode(false);
									LoadInitialData(() => {});
								}}
								onLocalSave={() => {}}
							/>
						</Fragment>
					) : (
						<Fragment>
							{!!data.intakeSubmission ? (
								<Fragment>
									{openEmailClient && <DisplayEmailClient />}
									<Row>
										<Col>
											<div style={{ marginBottom: "25px" }}>
												<QRCodeSVG value={`https://www.bodiometer.com/api/public/intakeform/${data.intakeSubmission.Id}`} />
											</div>

											<div style={{ margin: "5px 0px 5px 0px" }}>Status: {data.intakeSubmission.Status}</div>
											<div style={{ margin: "5px 0px 5px 0px" }}>Created At: {new Date(data.intakeSubmission.CreatedAt).toLocaleString(dateFormat, dateLocale)}</div>
											<div style={{ margin: "5px 0px 5px 0px" }}>
												Last Modified: {!!data.intakeSubmission.LastModified ? new Date(data.intakeSubmission.LastModified).toLocaleString(dateFormat, dateLocale) : "NA"}
											</div>
											<div style={{ margin: "5px 0px 5px 0px" }}>
												Email Sent: {!!data.intakeSubmission.LastEmailSent ? new Date(data.intakeSubmission.LastEmailSent).toLocaleString(dateFormat, dateLocale) : "Not Sent Yet"}
											</div>
											<div style={{ margin: "5px 0px 5px 0px" }}>
												Intake Completed: {!!data.intakeSubmission.CompletedAt ? new Date(data.intakeSubmission.CompletedAt).toLocaleString(dateFormat, dateLocale) : "Not Completed Yet"}
											</div>
											<div style={{ margin: "10px 0px 10px 0px" }}>
												{!!data.intakeSubmission.CompletedAt && ActiveAccessLevel <= 1 && (
													<Button
														variant="primary"
														style={{ marginRight: "10px" }}
														onClick={() => {
															setIsSubmitModalVisible(false);
															setEditMode(true);
														}}
													>
														Edit
													</Button>
												)}
												<Button
													variant="secondary"
													onClick={() => {
														setIsSubmitModalVisible(false);
														UpdateIntakeSubmissionSendEmail();
													}}
												>
													{!!data.intakeSubmission.LastEmailSent ? "Resend Email" : "Send Email"}
												</Button>

												<RenderInfo />
											</div>
										</Col>
									</Row>

									<Row>
										<Col>
											<Button
												variant="success"
												onClick={() => {
													GeneratePdf();
												}}
											>
												Download PDF
											</Button>
										</Col>
									</Row>
								</Fragment>
							) : (
								<Fragment>
									<Row>
										<Col>
											<Button variant="primary" onClick={() => setIsSubmitModalVisible(true)}>
												Send Email
											</Button>
											<RenderInfo />
										</Col>
									</Row>
								</Fragment>
							)}
						</Fragment>
					)}

					<Modal show={isSubmitModalVisible} onHide={() => setIsSubmitModalVisible(false)}>
						<Modal.Body>Are you sure you want to send a new intake submission form?</Modal.Body>
						<Modal.Footer>
							<Button variant="danger" onClick={() => setIsSubmitModalVisible(false)}>
								Cancel
							</Button>
							<Button
								variant="primary"
								onClick={() => {
									setIsSubmitModalVisible(false);
									CreateNewIntakeSubmission();
								}}
							>
								Confirm
							</Button>
						</Modal.Footer>
					</Modal>
				</div>
			)}
		</Fragment>
	);
};

const styles = {
	screen: {
		margin: "20px auto",
		maxWidth: "800px",
	},
};

const CalculateAge = (dateString) => {
	var today = new Date();
	var birthDate = new Date(dateString);
	var age = today.getFullYear() - birthDate.getFullYear();
	var m = today.getMonth() - birthDate.getMonth();
	if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
		age--;
	}
	return age;
};

const dateFormat = {
	year: "numeric",
	month: "long",
	day: "numeric",
	weekday: "long",
	hour: "numeric",
	minute: "numeric",
};

const bDayFormat = {
	year: "numeric",
	month: "long",
	day: "numeric",
};

const dateLocale = "en-Us";

const Mailto = ({ email, subject = "", body = "" }) => {
	let params = subject || body ? "?" : "";
	if (subject) params += `subject=${encodeURIComponent(subject)}`;
	if (body) params += `${subject ? "&" : ""}body=${encodeURIComponent(body)}`;
	window.location.href = `mailto:${email}${params}`;
	return null;
};

const DefaultPdfReportConfig = {
	orientation: "p",
	unit: "px",
	format: "a4",
	putOnlyUsedFonts: true,
	floatPrecision: "smart", // or "smart", default is 16
	compress: true
};

const OverflowSpace = 70;

const IsEmpty = (val) => {
	return val === undefined || val === null || val.length <= 0 ? true : false;
};

const AddressObjToStr = (Address) => {
	var addressInfo = Address.StreetAddress1;
	addressInfo += `${!IsEmpty(Address.StreetAddress1) && !IsEmpty(Address.StreetAddress2) ? " " : ""}` + Address.StreetAddress2;
	addressInfo += !IsEmpty(Address.StreetAddress1 + Address.StreetAddress2) ? ", " : "";
	addressInfo += Address.Province + `${!IsEmpty(Address.Province) ? ", " : ""}`;
	addressInfo += Address.City + `${!IsEmpty(Address.City) ? ", " : ""}`;
	addressInfo += Address.Country + ". ";
	addressInfo += !IsEmpty(Address.PostalCode) ? `Postal Code: ${Address.PostalCode}` : "";
	return addressInfo;
};

const AddHoursToDate = (str, h) => {
	let newDate = new Date(str);
	newDate.setTime(newDate.getTime() + h * 60 * 60 * 1000);
	return newDate.toLocaleString(dateLocale, dateFormat);
};

const QuesOrder = [1, 10, 6, 11, 4, 15, 2, 14, 9, 13, 3, 12, 5, 7, 8, 16, 17];

const QuesList = [
	"How normally do you sleep?", // 1 - 1
	"How difficult is it for you to move up and down stairs?", // 2 - 7
	"How difficult is it for you to perform food preparation / cooking / eating?", // 3 - 11
	"How difficult is it for you to walk?", // 4 - 5
	"How difficult is it for you to groom (bath, comb, hair, shave, etc.)?", // 5 - 13
	"How difficult is it for you to get up or down from bed or chair?", // 6 - 3
	"How well do you manage normal dressing activities?", // 7 - 14
	"How well do you manage to tie shoes / button shirt?", // 8 - 15
	"How difficult is it for you to carry items up to 10 pounds?", // 9 - 9
	"How difficult is it for you to sit for normal periods of time?", // 10 - 2
	"How difficult is it for you to stand for normal periods of time?", // 11 - 4
	"How difficult is it for you to reach above head or across body?", // 12 - 12
	"How difficult is it for you to perform leisure / recreational / sports activities?", // 13 - 10
	"How difficult is it for you to squat down to pick up items?", // 14 - 8
	"How difficult is it for you to perform running / jogging?", // 15 - 6
	"How difficult is it for you to drive? ", // 16 - 16
	"How difficult is it for you to perform regular job related activities?", // 17 - 17
];

export default IntakeDashboard;
