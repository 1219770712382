import React from "react";
// import { Button }  from 'react-bootstrap';;

import { DataGrid } from "@material-ui/data-grid";

import "../../resources/styles/dashboard.scss";
import "bootstrap/dist/css/bootstrap.min.css";

export default function UiTable(props) {
  // const columns = [
  //   { field: "id", headerName: "ID", width: 120 },
  //   { field: "firstName", headerName: "First name", width: 160 },
  //   { field: "lastName", headerName: "Last name", width: 160 },
  //   {
  //     field: "age",
  //     headerName: "Age",
  //     // type: 'number',
  //     width: 120,
  //   },
  //   {
  //     field: "fullName",
  //     headerName: "Full name",
  //     description: "This column has a value getter and is not sortable.",
  //     sortable: false,
  //     width: 230,
  //     valueGetter: (params) => `${params.getValue("firstName") || ""} ${params.getValue("lastName") || ""}`,
  //   },
  // ];

  // const rows = [
  //   { id: 1, lastName: "Snow", firstName: "Jon", age: 35 },
  //   { id: 2, lastName: "Lannister", firstName: "Cersei", age: 42 },
  //   { id: 3, lastName: "Lannister", firstName: "Jaime", age: 45 },
  //   { id: 4, lastName: "Stark", firstName: "Arya", age: 16 },
  //   { id: 5, lastName: "Targaryen", firstName: "Daenerys", age: null },
  //   { id: 6, lastName: "Melisandre", firstName: null, age: 150 },
  //   { id: 7, lastName: "Clifford", firstName: "Ferrara", age: 44 },
  //   { id: 8, lastName: "Frances", firstName: "Rossini", age: 36 },
  //   { id: 9, lastName: "Roxie", firstName: "Harvey", age: 65 },
  // ];

  return (
    <div style={{ height: 420, width: "100%" }} className="ui-table-wrapper">
      <DataGrid rows={props.rows} columns={props.cols} pageSize={5} checkboxSelection={false} />
    </div>
  );
}
