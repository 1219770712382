import React from "react";
import { Button, Container, Row, Col, Jumbotron } from "react-bootstrap";
import TrackPad from "../components/trackpad.js"; // Interactive animation
import { useMediaQuery } from "react-responsive";
import { Link } from "react-router-dom";

import "bootstrap/dist/css/bootstrap.min.css";

export default function ConsumerHero() {
	const isDesktopOrLaptop = useMediaQuery({ query: "(min-width: 1225px)" });
	const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });

	return (
		<div className="consumer-hero-wrapper">
			<Container>
				<Row xs={1} lg={2}>
					<Col>
						<Jumbotron>
							<h1 className="blue-color">
								Say Hello to our <span className="green-color">Smart</span> Postural Assessment App
							</h1>
							<p>Bodiometer is the world’s first real-time postural assessment app. You can use it to scan, track, and assess your posture with just a few clicks.</p>
							<footer>
								<a
									target="_blank"
									rel="noopener noreferrer"
									href="https://apps.apple.com/us/app/bodiometer-home/id1461395471"
									onClick={() => {
										// ga('send', 'event', { eventCategory: 'cta', eventAction: 'click', eventLabel: 'bodiometerHome', eventValue: '1'})
									}}
								>
									<img src="../../photos/icons/download-gray.svg" height="46" alt="Download Bodiometer Home on the Apple Store - button" />
								</a>
								<p>or</p>
								<Link to="/professionals">
									<Button variant="secondary">For Professionals</Button>
								</Link>
							</footer>
						</Jumbotron>
					</Col>
					<Col className="animation-wrapper">
						{isDesktopOrLaptop && <TrackPad />}
						{isTabletOrMobile && <img src="../../photos/consumer-landing/mobile-hero.gif" width="100%" alt="Bodiometer Home smart postural assessment app - animation" />}
					</Col>
				</Row>
			</Container>
		</div>
	);
}
