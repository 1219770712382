import React from "react";
import UiButton from "./ui-button";
import ProfileToggle from "./profile-toggle";
import NotificationToggle from "./notification-toggle";
import ConvertDate from "../../resources/helpers/convert-date";

import "../../resources/styles/dashboard.scss";
import "bootstrap/dist/css/bootstrap.min.css";

export default function TopNav(props) {
	var trialLicense =
		props.ActiveOrganization &&
		props.ActiveOrganization.license &&
		props.ActiveOrganization.license[0].details.subscription &&
		props.ActiveOrganization.license[0].details.subscription.type &&
		props.ActiveOrganization.license[0].details.subscription.type == "trial"
			? true
			: false;
	return (
		<div className="top-nav-wrapper">
			{props.freeTrial > 0 && props.ActiveOrganization && (!props.ActiveOrganization.license || (props.ActiveOrganization.license && trialLicense)) && (
				<p>{props.freeTrial} days remaining on free trial</p>
			)}
			{/* {props.ActiveOrganization && props.ActiveOrganization.license && props.ActiveOrganization.license[0].details.subscriptionId == "" && !trialLicense && <p className="muted">License expires {props.ActiveOrganization.license[0].details.expiry.split(" ")[0]}</p> } */}
			{props.ActiveOrganization && props.ActiveOrganization.license && props.ActiveOrganization.license[0].details.subscriptionId == "" && !trialLicense && (
				<p className="muted">License expires {ConvertDate(props.ActiveOrganization.license[0].details.expiry, { year: "numeric", month: "short", day: "numeric" })}</p>
			)}
			{props.view !== "license" &&
				props.freeTrial === 0 &&
				(!props.ActiveOrganization.license || (props.ActiveOrganization.license && trialLicense)) &&
				props.parentState.ActiveOrganization.myAccessLevel <= 2 && (
					<p>
						Your free trial has expired. &nbsp;{" "}
						<UiButton variant="secondary" plus size="sm" onClick={(e) => props.setView("license")}>
							add subscription
						</UiButton>
					</p>
				)}
			<NotificationToggle notifications={props.notifications} setNotifications={props.setNotifications} view={props.view} setView={props.setView} user={props.user} setUser={props.setUser} />
			<a className="help-button" onClick={(e) => props.setView("help")}>
				<span>?</span>
			</a>
			<ProfileToggle setState={props.setState} parentState={props.parentState} view={props.view} setView={props.setView} user={props.user} setUser={props.setUser} />
		</div>
	);
}
