import React, { useState, useEffect, Fragment } from 'react';
import { Col, Form } from 'react-bootstrap';

import "../../resources/styles/dashboard.scss";
import "bootstrap/dist/css/bootstrap.min.css";

export default function UiPassword(props) {
  const [state, setState] = useState({
    Validated: undefined,
    PassType: 'password'
  });

  return(
    <Fragment>
      <Form.Group as={Col} controlId={props.controlId} className="password-input">
        {props.label && <Form.Label>{props.label}</Form.Label>}
        <Form.Control onChange={props.onChange} onBlur={props.onBlur} isInvalid={props.isInvalid} validated={state.Validated} size="sm" type={props.isEyeOpen ? "text": "password"} placeholder={props.placeholder} value={props.value} required/>
        <img className="password-eyeball" src={`../../photos/icons/${props.isEyeOpen ? 'show-password.png' : 'hide-password.png'}`} width="25" onClick={props.onEyeClick} />
        {props.text && 
          <Form.Text muted>
            {props.text}
          </Form.Text>
        }
          <Form.Control.Feedback type="invalid">
            {props.message}
          </Form.Control.Feedback>
      </Form.Group>
    </Fragment>
  );
};