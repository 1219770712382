import React, { useState, Fragment, useRef, useEffect } from "react";
import { Col, Row, Form, Button, ButtonGroup } from "react-bootstrap";
import Datetime from "react-datetime";
import moment from "moment";

import "bootstrap/dist/css/bootstrap.min.css";
import "react-phone-number-input/style.css";
import "react-datetime/css/react-datetime.css";
import "../Assets/style.scss";

const PreviousTreatment = ({ data, requiredDb, validatedDb, isValidDb, pageCompleted, setPageCompleted, activeIndex, viewInvalidFields, onChange }) => {
	const [validatedFields, setValidatedFields] = useState({ ...validatedDb });
	const [isValidFields, setIsValidFields] = useState({ ...isValidDb });
	const requiredFields = { ...requiredDb };

	const Header = ({ label }) => {
		return <h2 style={{ textAlign: "center", marginBottom: "20px" }}>{label}</h2>;
	};

	const CheckboxId = ["Medication", "Physiotherapy", "Chiropractic", "Massage", "Acupuncture", "Naturopathic"];

	const CheckboxLabel = ["Medication", "Physiotherapy", "Chiropractic", "Massage", "Acupuncture", "Naturopathic"];

	const Checkbox2Id = ["XRay", "Mri", "CtScan", "UltraSound", "BoneScan", "BloodTest"];
	const Checkbox2Label = ["X-Ray", "MRI", "Chiropractic", "CT Scan", "Ultrasound", "Bone Scan", "EMG / NCS"];

	const [hasSurgeries, setHasSurgeries] = useState(data.Surgeries.length > 0);

	const dateTimePickerRef = useRef(null);

	const CheckIfFieldsAreValid = () => {
		var isValid = true;
		isValid = isValid && (requiredFields.HadSurgeries ? isValidFields.Surgeries || !hasSurgeries : true);
		isValid = isValid && (requiredFields.OverallHealth ? isValidFields.OverallHealth : true);
		return isValid;
	};

	const InitialFieldsValidity = () => {
		var cIsValid = { ...isValidFields };
		cIsValid.Surgeries = data.Surgeries.length > 0;
		cIsValid.OverallHealth = data.OverallHealth >= 0 && data.OverallHealth <= 4;
		return cIsValid;
	};

	useEffect(() => {
		setIsValidFields({ ...InitialFieldsValidity() });
	}, []);

	useEffect(() => {
		const iterate = (obj) => {
			Object.keys(obj).forEach((key) => {
				if (typeof obj[key] === "object" && obj[key] !== null) {
					iterate(obj[key]);
				} else {
					obj[key] = true;
				}
			});
		};
		if (viewInvalidFields) {
			window.scrollTo({
				top: "0",
				behavior: "smooth",
			});
			var newObj = { ...validatedFields };
			iterate(newObj);
			setValidatedFields({ ...newObj });
		}
	}, [viewInvalidFields]);

	useEffect(() => {
		pageCompleted[activeIndex] = CheckIfFieldsAreValid();
		setPageCompleted([...pageCompleted]);
	}, [isValidFields]);

	return (
		<div className="intake" style={styles.screen}>
			<Header label={"Previous Treatments and Procedures"} />

			<Row>
				<Col>
					<Form.Label>
						<span style={{ fontWeight: "bold" }}>Previous Treatments for this Condition: </span> (Check all treatments previously received for this condition){" "}
					</Form.Label>
				</Col>
			</Row>

			<Row>
				{CheckboxId.map((id, k) => {
					return (
						<Col md={6} className="checkbox" key={k}>
							<Form.Check custom type={"checkbox"} label={""} id={`treatments-checkbox-${k}`} checked={data[id]} defaultChecked={data[id]} onChange={() => onChange({ ...data, [id]: !data[id] })} />
							<Form.Label>{CheckboxLabel[k]}</Form.Label>
						</Col>
					);
				})}
			</Row>

			<Row>
				<Col md={6}>
					<Form.Group>
						<Form.Control placeholder="other treatment" value={data.OtherTreatment} onChange={(evt) => onChange({ ...data, OtherTreatment: evt.target.value })} />
					</Form.Group>
				</Col>
			</Row>

			<Row>
				<Col md={6} className="checkbox">
					<Form.Check
						custom
						type={"checkbox"}
						label={""}
						id={`screening-checkbox-6`}
						checked={data.JointInjection}
						defaultChecked={data.JointInjection}
						onChange={() => onChange({ ...data, JointInjection: !data.JointInjection })}
					/>
					<Form.Label>Joint Injections</Form.Label>
				</Col>

				{data.JointInjection && (
					<Fragment>
						<Row style={{ marginTop: "10px", marginBottom: "10px" }}>
							<Col>
								<Form.Label>Type of Injection(s):</Form.Label>
							</Col>
						</Row>

						<Row>
							<Col md={6} className="checkbox">
								<Form.Check
									custom
									type={"checkbox"}
									label={""}
									id={`injection-checkbox-1`}
									checked={data.Steroid}
									defaultChecked={data.Steroid}
									onChange={() => onChange({ ...data, Steroid: !data.Steroid })}
								/>
								<Form.Label>Steriod</Form.Label>
							</Col>

							<Col md={6} className="checkbox">
								<Form.Check
									custom
									type={"checkbox"}
									label={""}
									id={`injection-checkbox-2`}
									checked={data.Anesthetic}
									defaultChecked={data.Anesthetic}
									onChange={() => onChange({ ...data, Anesthetic: !data.Anesthetic })}
								/>
								<Form.Label>Anesthetic (lidocaine)</Form.Label>
							</Col>

							<Col md={6} className="checkbox">
								<Form.Check
									custom
									type={"checkbox"}
									label={""}
									id={`injection-checkbox-3`}
									checked={data.TriggerPoint}
									defaultChecked={data.TriggerPoint}
									onChange={() => onChange({ ...data, TriggerPoint: !data.TriggerPoint })}
								/>
								<Form.Label>Trigger Point</Form.Label>
							</Col>

							<Col md={6}>
								<Form.Group>
									<Form.Control placeholder="other injection" value={data.OtherInjection} onChange={(evt) => onChange({ ...data, OtherInjection: evt.target.value })} />
								</Form.Group>
							</Col>
						</Row>
					</Fragment>
				)}
			</Row>

			<Row>
				<Col md={8}>
					<Form.Label className={validatedFields.HadSurgeries && requiredFields.HadSurgeries && !(isValidFields.Surgeries || !hasSurgeries) ? "isinvalid" : "isvalid"}>
						Have you had surgery or a procedure for this condition? {requiredFields.HadSurgeries ? "*" : ""}
					</Form.Label>
				</Col>
				<Col md={4} style={{ textAlign: "end" }}>
					<ButtonGroup>
						<Button
							active={hasSurgeries}
							onClick={() => {
								setHasSurgeries(true);
								setIsValidFields({ ...isValidFields, HadSurgeries: data.Surgeries.length > 0 });
							}}
						>
							Yes
						</Button>
						<Button
							active={!hasSurgeries}
							onClick={() => {
								setHasSurgeries(false);
								onChange({ ...data, Surgeries: [] });
								setIsValidFields({ ...isValidFields, HadSurgeries: true });
							}}
						>
							No
						</Button>
					</ButtonGroup>
				</Col>
			</Row>

			{hasSurgeries && (
				<Fragment>
					{data.Surgeries.map((surgery, k) => {
						return (
							<Row>
								<Col md={3}>
									<Form.Group>
										<Form.Control
											placeholder="surgery name"
											value={surgery.SurgeryName}
											onChange={(evt) => {
												var existingSurgeries = [...data.Surgeries];
												surgery.SurgeryName = evt.target.value;
												existingSurgeries[k] = surgery;
												onChange({ ...data, Surgeries: existingSurgeries });
											}}
										/>
									</Form.Group>
								</Col>

								<Col md={3}>
									<Form.Group>
										<Form.Control
											placeholder="surgeon name"
											value={surgery.SurgeonName}
											onChange={(evt) => {
												var existingSurgeries = [...data.Surgeries];
												surgery.SurgeonName = evt.target.value;
												existingSurgeries[k] = surgery;
												onChange({ ...data, Surgeries: existingSurgeries });
											}}
										/>
									</Form.Group>
								</Col>

								<Col md={3}>
									<Datetime
										initialValue={!!surgery.SurgeryDate ? moment(surgery.SurgeryDate).format("MM/DD/YYYY") : moment(new Date().toLocaleDateString()).format("MM/DD/YYYY")}
										placeholder="surgery date"
										onChange={(evt) => {
											var existingSurgeries = [...data.Surgeries];
											surgery.SurgeryDate = moment(evt._d).format("MM/DD/YYYY");
											existingSurgeries[k] = surgery;
											onChange({ ...data, Surgeries: existingSurgeries });
										}}
										dateFormat="MM/DD/YYYY"
										timeFormat={false}
										ref={dateTimePickerRef}
										closeOnSelect
									/>
								</Col>

								<Col md={3} style={{ textAlign: "end" }}>
									<Button
										variant="danger"
										size="sm"
										onClick={() => {
											var existingSurgeries = [...data.Surgeries];
											existingSurgeries.splice(k, 1);
											onChange({ ...data, Surgeries: existingSurgeries });
											setIsValidFields({ ...isValidFields, Surgeries: existingSurgeries.length > 0 });
										}}
									>
										Remove
									</Button>
								</Col>
							</Row>
						);
					})}

					<Row>
						<Col md={6} style={{ textAlign: "start" }}>
							<Button
								variant="success"
								size="sm"
								onClick={() => {
									var existingSurgeries = [...data.Surgeries];
									existingSurgeries.push({SurgeryName: "", SurgeonName: "", SurgeryDate: ""});
									onChange({ ...data, Surgeries: existingSurgeries });
									setIsValidFields({ ...isValidFields, Surgeries: existingSurgeries.length > 0 });
								}}
							>
								Add Surgery
							</Button>
						</Col>
					</Row>
				</Fragment>
			)}

			<Row>
				<Col>
					<Form.Label>Which Diagnostic test(s) or Investigations have you had for this condition?</Form.Label>
				</Col>
			</Row>

			<Row>
				{Checkbox2Id.map((id, k) => {
					return (
						<Col md={3} className="checkbox" key={k}>
							<Form.Check
								custom
								type={"checkbox"}
								label={""}
								id={`diagnostic-checkbox-${k + 12}`}
								checked={data[id]}
								defaultChecked={data[id]}
								onChange={() => onChange({ ...data, [id]: !data[id] })}
							/>
							<Form.Label>{Checkbox2Label[k]}</Form.Label>
						</Col>
					);
				})}
			</Row>

			<Row>
				<Col>
					<Form.Label className={validatedFields.OverallHealth && requiredFields.OverallHealth && !isValidFields.OverallHealth ? "isinvalid" : "isvalid"}>
						In general would you say your health is: {requiredFields.OverallHealth ? "*" : ""}
					</Form.Label>
				</Col>
			</Row>

			<Row>
				{[
					{ label: "Excellent", value: 0 },
					{ label: "Very Good", value: 1 },
					{ label: "Good", value: 2 },
					{ label: "Fair", value: 3 },
					{ label: "Poor", value: 4 },
				].map((item, k) => {
					return (
						<Col md={2} className="checkbox" key={k}>
							<Form.Check
								custom
								type={"checkbox"}
								label={""}
								id={`overall-health-checkbox-${k}`}
								checked={data.OverallHealth == item.value}
								defaultChecked={data.OverallHealth == item.value}
								onChange={() => {
									var newOverallHealth = data.OverallHealth == item.value ? -1 : item.value;
									onChange({ ...data, OverallHealth: newOverallHealth });
									setValidatedFields({ ...validatedFields, OverallHealth: true });
									setIsValidFields({ ...isValidFields, OverallHealth: newOverallHealth >= 0 && newOverallHealth <= 4 });
								}}
							/>
							<Form.Label>{item.label}</Form.Label>
						</Col>
					);
				})}
			</Row>
		</div>
	);
};

const styles = {
	screen: {
		margin: "20px auto",
		maxWidth: "800px",
	},
};

export default PreviousTreatment;
