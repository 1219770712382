import React, { useState } from 'react';
import { Col, Row, Form } from 'react-bootstrap';

import UiButton from "./ui-button.js";

import "bootstrap/dist/css/bootstrap.min.css";

export default function Notifications(props) {
  const [state, setState] = useState({
    EditFormValidated: false,
    Different: false,
    NewStaff: props.user.notifications.newStaff,
    StaffChange: props.user.notifications.staffChange
  });

  const Initial = {
    EditFormValidated: false,
    Different: false,
    NewStaff: props.user.notifications.newStaff,
    StaffChange: props.user.notifications.staffChange
  }

  const HandleEditFormSubmit = (e) => {

  }

  const SetThing = (thing, e) => {
    return setState({...state, [thing]: e.target.value, Different: true })
  }
  
  return(
    <div className="ui-section-wrapper">
      <section>
        <Row>
          <Col>
            <h1>Notification Settings</h1>
          </Col>
        </Row>
      </section>

      <section className="form-section-wrapper">
        <Row>
          <Col md={8}>
            <Form validated={state.EditFormValidated} onSubmit={e => HandleEditFormSubmit(e)}>

              <Row>
                <Form.Group controlId="formCheck">
                  <Col>
                    <Form.Check label="New Staff" checked={state.NewStaff} onChange={e => setState({...state, NewStaff: !state.NewStaff, Different: true })}/>
                  </Col>
                </Form.Group>   
              </Row>
              <Row>
                <Form.Group controlId="formCheck2">
                  <Col>
                    <Form.Check label="Staff Role Change" checked={state.StaffChange} onChange={e => setState({...state, StaffChange: !state.StaffChange, Different: true })}/>
                  </Col>
                </Form.Group> 
              </Row>
              
              <Row>
                <Form.Group controlId="consent" className="form-footer-wrapper">
                  <UiButton
                  type="submit"
                  variant="secondary"
                  disabled={state.Different ? false : true}
                  >
                    Save
                  </UiButton>
                </Form.Group>

                <Form.Group controlId="consent" className="form-footer-wrapper">
                  <UiButton
                  onClick={e => setState({...Initial})}
                  variant="link"
                  className="gray-color no-padding"
                  visibility={state.Different ? false : true}
                  >
                    Cancel
                  </UiButton>
                </Form.Group>
              </Row>
            </Form>
          </Col>
        </Row>

      </section>
    </div>
  );
};