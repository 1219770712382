import React, { useState, Fragment, useEffect } from "react";
import { Col, Row, Button } from "react-bootstrap";

import { Default } from "../Models/index";

import "bootstrap/dist/css/bootstrap.min.css";
import "react-phone-number-input/style.css";
import "react-datetime/css/react-datetime.css";
import "../Assets/style.scss";

const FunctionalQuestions = ({ data, requiredDb, validatedDb, isValidDb, pageCompleted, setPageCompleted, activeIndex, viewInvalidFields, onChange }) => {
	const [validatedFields, setValidatedFields] = useState({ ...validatedDb });
	const [isValidFields, setIsValidFields] = useState({ ...isValidDb });
	const requiredFields = { ...requiredDb };

	const Separator = () => {
		return <div style={{ width: "100%", height: "1px", background: "var(--primary-gradient)", marginTop: "25px", marginBottom: "25px", position: "relative" }}></div>;
	};

	const Header = ({ label }) => {
		return <h2 style={{ textAlign: "center", marginBottom: "20px" }}>{label}</h2>;
	};

	const CheckIfFieldsAreValid = () => {
		var isValid = true;
		isValid = isValid && (requiredFields.OverallPain ? isValidFields.OverallPain : true);
		for (var i = 0; i < data.Answers.length; i++) {
			isValid = isValid && (requiredFields.Answers[i] ? isValidFields.Answers[i] : true);
		}
		return isValid;
	};

	const InitialFieldsValidity = () => {
		var cIsValid = { ...isValidFields };
		cIsValid.OverallPain = data.OverallPain >= 0 && data.OverallPain <= 10;
		for (var i = 0; i < data.Answers.length; i++) {
			cIsValid.Answers[i] = data.Answers[i] >= 0 && data.Answers[i] <= 5;
		}
		return cIsValid;
	};

	useEffect(() => {
		pageCompleted[activeIndex] = CheckIfFieldsAreValid();
		setPageCompleted([...pageCompleted]);
	}, [isValidFields]);

	useEffect(() => {
		setIsValidFields({ ...InitialFieldsValidity() });
	}, []);

	useEffect(() => {
		const iterate = (obj) => {
			Object.keys(obj).forEach((key) => {
				if (obj[key] instanceof Array && obj[key].length > 0) {
					for (var i = 0; i < obj[key].length; i++) {
						obj[key][i] = true;
					}
				} else {
					obj[key] = true;
				}
			});
		};
		if (viewInvalidFields) {
			window.scrollTo({
				top: "0",
				behavior: "smooth",
			});
			var newObj = { ...validatedFields };
			iterate(newObj);
			setValidatedFields({ ...newObj });
		}
	}, [viewInvalidFields]);

	useEffect(() => {
		const handleScroll = (event) => {
			// Get the header
			var header = document.getElementById("stickyHeader");

			// Get the offset position of the navbar
			var sticky = header.offsetTop;

			if (window.pageYOffset > sticky) {
				header.classList.add("sticky");
			} else {
				header.classList.remove("sticky");
			}
		};

		window.addEventListener("scroll", handleScroll);

		return () => {
			window.removeEventListener("scroll", handleScroll);
		};
	}, []);

	return (
		<div className="intake" style={styles.screen}>
			<Header label={"Functional Questions"} />

			<section id="stickyHeader" style={{ padding: "1rem 6%" }}>
				<Row>
					<Col
						style={{
							textAlign: "center",
							fontWeight: "bold",
							color: "var(--logo-color-1)",
						}}
					>
						Unable
					</Col>
					<Col
						style={{
							textAlign: "center",
							fontWeight: "bold",
							color: "var(--logo-color-1)",
						}}
					>
						Very Difficult
					</Col>
					<Col
						style={{
							textAlign: "center",
							fontWeight: "bold",
							color: "var(--logo-color-1)",
						}}
					>
						Moderately Difficult
					</Col>
					<Col
						style={{
							textAlign: "center",
							fontWeight: "bold",
							color: "var(--logo-color-1)",
						}}
					>
						Minimally Difficult
					</Col>
					<Col
						style={{
							textAlign: "center",
							fontWeight: "bold",
							color: "var(--logo-color-1)",
						}}
					>
						Normal
					</Col>
				</Row>
				<Row>
					<Col style={{ textAlign: "center", fontWeight: "bold" }}>
						<div className="module-score-highlight-1">0</div>
					</Col>
					<Col style={{ textAlign: "center", fontWeight: "bold" }}>
						<div className="module-score-highlight-1">1</div>
					</Col>
					<Col style={{ textAlign: "center", fontWeight: "bold" }}>
						<div className="module-score-highlight-1">2</div>
					</Col>
					<Col style={{ textAlign: "center", fontWeight: "bold" }}>
						<div className="module-score-highlight-1">3</div>
					</Col>
					<Col style={{ textAlign: "center", fontWeight: "bold" }}>
						<div className="module-score-highlight-1">4</div>
					</Col>
				</Row>
			</section>

			<Separator />

			<section style={{ padding: "1rem 6%" }}>
				{fqQuesOrder.map((v, k) => {
					return (
						<Fragment>
							{k + 1 == 1 && (
								<Row style={{ marginBottom: "20px" }}>
									<Col style={{ textAlign: "left" }}>
										<span
											style={{
												color: "var(--logo-color-1)",
												fontSize: "120%",
												fontWeight: "bold",
											}}
										>
											SLEEPING
										</span>
									</Col>
								</Row>
							)}
							{k + 1 == 2 && (
								<Row style={{ marginBottom: "20px" }}>
									<Col style={{ textAlign: "left" }}>
										<span
											style={{
												color: "var(--logo-color-1)",
												fontSize: "120%",
												fontWeight: "bold",
											}}
										>
											SITTING
										</span>
									</Col>
								</Row>
							)}
							{k + 1 == 4 && (
								<Row style={{ marginBottom: "20px" }}>
									<Col style={{ textAlign: "left" }}>
										<span
											style={{
												color: "var(--logo-color-1)",
												fontSize: "120%",
												fontWeight: "bold",
											}}
										>
											MOVING - STANDING/WALKING/RUNNING
										</span>
									</Col>
								</Row>
							)}
							{k + 1 == 10 && (
								<Row style={{ marginBottom: "20px" }}>
									<Col style={{ textAlign: "left" }}>
										<span
											style={{
												color: "var(--logo-color-1)",
												fontSize: "120%",
												fontWeight: "bold",
											}}
										>
											DAILY ACTIVITIES
										</span>
									</Col>
								</Row>
							)}
							{k + 1 == 16 && (
								<Row style={{ marginBottom: "20px" }}>
									<Col style={{ textAlign: "left" }}>
										<span
											style={{
												color: "var(--logo-color-1)",
												fontSize: "120%",
												fontWeight: "bold",
											}}
										>
											JOB ACTIVITIES
										</span>
									</Col>
								</Row>
							)}

							<Row style={{ marginBottom: "20px" }}>
								<Col style={{ textAlign: "left" }}>
									<span className={validatedFields.Answers[v - 1] && requiredFields.Answers[v - 1] && !isValidFields.Answers[v - 1] ? "isinvalid" : "isvalid"}>
										{k + 1}. {fqQuesList[v - 1]} {requiredFields.Answers[v - 1] ? "*" : ""}
									</span>
								</Col>
							</Row>

							<Row style={{ marginBottom: "50px" }}>
								<Col style={{ textAlign: "center" }}>
									{Array(6)
										.fill(0)
										.map((v1, k1) => {
											return (
												<Button
													style={{
														marginRight: "20px",
														width: "40px",
														height: "40px",
													}}
													className={data.Answers[v - 1] == k1 ? "fq-rating-button-active" : "fq-rating-button-inactive"}
													onClick={() => {
														var Answers = [...data.Answers];
														Answers[v - 1] = k1;
														onChange({ ...data, Answers });
														var newValidated = { ...validatedFields };
														newValidated.Answers[v - 1] = true;
														setValidatedFields({ ...newValidated });
														var newIsValid = { ...isValidFields };
														newIsValid.Answers[v - 1] = true;
														setIsValidFields({ ...newIsValid });
													}}
													// onClick={() => onChange({ ...data, Answers: data.BodyParts.filter((item) => item != part) })}
												>
													{k1 == 5 ? "NA" : k1}
												</Button>
											);
										})}
								</Col>
							</Row>
						</Fragment>
					);
				})}
			</section>

			<section style={{ padding: "1rem 6%" }}>
				<Row style={{ marginBottom: "20px" }}>
					<Col style={{ textAlign: "left" }}>
						<span
							style={{
								color: "var(--logo-color-1)",
								fontSize: "120%",
								fontWeight: "bold",
							}}
							className={validatedFields.OverallPain && requiredFields.OverallPain && !isValidFields.OverallPain ? "isinvalid" : "isvalid"}
						>
							PAIN SCALE {requiredFields.OverallPain ? "*" : ""}
						</span>
					</Col>
				</Row>

				<Row style={{ marginBottom: "20px" }}>
					<Col style={{ textAlign: "left" }}>
						Please select the number that describes the pain you have experienced over the last week with 0 being no pain and 10 being excruciating pain <b>(when not taking pain medication) </b>.
					</Col>
				</Row>

				<Row style={{ marginBottom: "50px" }}>
					<Col style={{ textAlign: "center" }}>
						{Array(11)
							.fill(0)
							.map((_, k) => {
								return (
									<Button
										style={{
											marginRight: "20px",
											width: "40px",
											height: "40px",
										}}
										className={data.OverallPain == k ? "fq-rating-button-active" : "fq-rating-button-inactive"}
										onClick={() => {
											onChange({ ...data, OverallPain: k });
											setValidatedFields({ ...validatedFields, OverallPain: true });
											setIsValidFields({ ...isValidFields, OverallPain: true });
										}}
									>
										{k}
									</Button>
								);
							})}
					</Col>
				</Row>
			</section>
		</div>
	);
};

const fqQuesOrder = [1, 10, 6, 11, 4, 15, 2, 14, 9, 13, 3, 12, 5, 7, 8, 16, 17];

const fqQuesList = [
	"How normally do you sleep?", // 1 - 1
	"How difficult is it for you to move up and down stairs?", // 2 - 7
	"How difficult is it for you to perform food preparation / cooking / eating?", // 3 - 11
	"How difficult is it for you to walk?", // 4 - 5
	"How difficult is it for you to groom (bath, comb, hair, shave, etc.)?", // 5 - 13
	"How difficult is it for you to get up or down from bed or chair?", // 6 - 3
	"How well do you manage normal dressing activities?", // 7 - 14
	"How well do you manage to tie shoes / button shirt?", // 8 - 15
	"How difficult is it for you to carry items up to 10 pounds?", // 9 - 9
	"How difficult is it for you to sit for normal periods of time?", // 10 - 2
	"How difficult is it for you to stand for normal periods of time?", // 11 - 4
	"How difficult is it for you to reach above head or across body?", // 12 - 12
	"How difficult is it for you to perform leisure / recreational / sports activities?", // 13 - 10
	"How difficult is it for you to squat down to pick up items?", // 14 - 8
	"How difficult is it for you to perform running / jogging?", // 15 - 6
	"How difficult is it for you to drive? ", // 16 - 16
	"How difficult is it for you to perform regular job related activities?", // 17 - 17
];

const styles = {
	screen: {
		margin: "20px auto",
		maxWidth: "800px",
	},
};

export default FunctionalQuestions;
