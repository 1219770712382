
import React from 'react';
import { Button, Popover, OverlayTrigger }  from 'react-bootstrap';


import "../../resources/styles/dashboard.scss";
import "bootstrap/dist/css/bootstrap.min.css";

export default function InfoPopup(props) {

  const popover = (
    <Popover id={`popover${Math.floor(Math.random()).toString()}`}>
      <Popover.Content>
        {/* {props.content} */}
        <div dangerouslySetInnerHTML={{__html: props.content}}></div>
        <div className="no-padding white-link" dangerouslySetInnerHTML={{__html: props.cta}} ></div>
      </Popover.Content>
    </Popover>
  );

  return(
    <OverlayTrigger rootClose={true} trigger={props.trigger} placement={props.placement} overlay={popover}>
      <Button className="info-popup">{props.symbol}</Button>
    </OverlayTrigger>
  );
};