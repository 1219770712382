import React, { useState, useMemo, Fragment } from "react";
import { Container, Col, Row, Form, Alert } from "react-bootstrap";
import axios from "axios";
import countryList from "react-select-country-list";
import { v4 as uuidv4 } from "uuid";
import { RetrieveToken } from "../../lib/token";
import { dbUrl } from "../../lib/config";

import UiButton from "./ui-button.js";
import convertToId from "../../resources/helpers/convert-to-id.js";
import formatPhone from "../../resources/helpers/format-phone.js";
import DisplayPhone from "../../resources/helpers/display-phone.js";

import "bootstrap/dist/css/bootstrap.min.css";

export default function CreateOrganization(props) {
	const [state, setState] = useState({
		Validated: false,
		Name: "",
		Type: "Chiropractic",
		OtherType: "",
		Email: "",
		Phone: "",
		Fax: "",
		Address: "",
		City: "",
		Province: "",
		Postal: "",
		Country: "",
		Logo: ["Organization Logo (Optional)", null],
		NameInValid: [false, ""],
		PhoneInValid: [false, ""],
		FaxInValid: [false, ""],
		WillCreateTrialAccount: !props.parentState.OwnerRole,
		ShowSuccess: false
	});

	const testLink = () => {
		const formData = new FormData();
		formData.append("file", state.Logo[1]);
	};

	const createBusinessId = () => {
		let tempId = uuidv4();
		let tempIdSized = tempId.substring(0, 6);
		return tempIdSized;
	};

	let randomBusinessId = createBusinessId();

	const AddDaysToDate = (days) => {
		let now = new Date();
		now.setDate(now.getDate() + days);
		return now;
	};

	const defaultDateFormat = {
		year: "numeric",
		month: "long",
		day: "numeric",
	};

	const dateLocale = "en-Us";

	// Date for setting free trial license expiry
	const Today = new Date();
	const TodayDay = Today.getDate();
	const TodayCopy = new Date(Today); // this get mutated
	const freeTrialLengthFromToday = new Date(TodayCopy.setDate(TodayDay + (props.parentState.FreeTrialLength + 1))).toLocaleDateString();

	const HandleSubmit = (e) => {
		e.preventDefault();
		e.stopPropagation();

		// check if business account exists with email

		const VerifyBusinessAccountHeaders = {
			headers: {
				Authorization: props.parentState.DBAccessToken,
				businessId: randomBusinessId,
			},
		};

		axios
			.get(dbUrl + `/api/businessaccounts/v1/verify/businessid`, VerifyBusinessAccountHeaders)

			.then((res) => {
				if (res.data.success) {
					setState({ ...state, NameInValid: [true, "An organization already exists with this name"] });
				} else {
					props.setState({ ...props.parentState, Preloader: true }); // start preloader

					setState({ ...state, NameInValid: [false, ""] });

					const AccountPostData = {
						businessDetail: {
							businessId: randomBusinessId,
							ownerId: props.parentState.User.userId,
							businessName: state.Name,
							businessType: state.Type.toLowerCase() != "other" ? state.Type : state.OtherType,
							email: state.Email,
							emailActivated: false,
							phoneNumber: formatPhone(state.Phone),
							phoneNumberExt: state.Ext,
							fax: formatPhone(state.Fax),
							createdAt: new Date().toISOString(),
							createdAtOffset: 0.0,
						},
						businessAddress: {
							country: state.Country,
							city: state.City,
							province: state.Province,
							streetAddress1: state.Address,
							postalCode: state.Postal,
						},
					};

					const AccountPostHeaders = {
						headers: {
							Authorization: props.parentState.DBAccessToken,
							"Content-Type": "application/json",
						},
					};

					const RolePostData = {
						businessId: randomBusinessId,
						userId: props.parentState.User.userId,
						title: "Owner",
						accessCategory: "Owner",
						accessLevel: 1,
						description: "",
						status: "active",
						createdAt: new Date().toISOString(),
						createdAtOffset: 0.0,
					};

					const RolePostHeaders = {
						headers: {
							Authorization: props.parentState.DBAccessToken,
							"Content-Type": "application/json",
						},
					};

					const Role2PostData = {
						businessId: randomBusinessId,
						userId: null,
						title: "Admin",
						accessCategory: "Admin",
						accessLevel: 1,
						description: "",
						status: "active",
						createdAt: new Date().toISOString(),
						createdAtOffset: 0.0,
					};

					const Role3PostData = {
						businessId: randomBusinessId,
						userId: null,
						title: "Unassigned",
						accessCategory: "Unassigned",
						accessLevel: 3,
						description: "",
						status: "active",
						createdAt: new Date().toISOString(),
						createdAtOffset: 0.0,
					};

					axios.post(dbUrl + "/api/businessaccounts/v1/create", AccountPostData, AccountPostHeaders).then((accountCreation) => {
						if (accountCreation.data.success) {
							// send email confirmation email
							const confirmationPostData = {
								businessId: randomBusinessId,
							};

							const confirmationPostHeaders = {
								headers: {
									Authorization: props.parentState.DBAccessToken,
									"Content-Type": "application/json",
								},
							};

							axios.post(dbUrl + "/api/email/v2/businessaccounts/confirmation", confirmationPostData, confirmationPostHeaders).then((res) => {
								// email sent
							});

							// store logo

							if (state.Logo[1]) {
								const formData2 = new FormData();
								formData2.append("imageFile", state.Logo[1]);

								const LogoPostHeaders = {
									headers: {
										Authorization: props.parentState.DBAccessToken,
										"Content-Type": "application/json",
										businessId: randomBusinessId,
										quality: 100,
									},
								};

								const LogoPostData = formData2;

								axios.post(dbUrl + "/api/storage/v1/image/create/ba/logo/id", LogoPostData, LogoPostHeaders).then((logoPost) => {
									// logo posted
									if (logoPost.data.success) {
										// logo uploaded
									} else {
										console.log("logo upload failed");
									}
								});
							}

							// create roles

							axios.post(dbUrl + "/api/businessroles/v1/create", Role2PostData, RolePostHeaders).then((adminRole) => {
								// admin role created
							});

							axios.post(dbUrl + "/api/businessroles/v1/create", Role3PostData, RolePostHeaders).then((adminRole) => {
								// Unassigned role created
							});

							axios.post(dbUrl + "/api/businessroles/v1/create", RolePostData, RolePostHeaders)
								.then((_) => {
									if (state.WillCreateTrialAccount) {
										// Create free trial license
										const LicensePostHeaders = {
											headers: {
												Authorization: props.parentState.DBAccessToken,
												"Content-Type": "application/json",
											},
										};

										var todayStr = new Date().toISOString();
										var trialExpStr = AddDaysToDate(props.parentState.FreeTrialLength).toISOString();

										const LicensePostData = {
											businessId: randomBusinessId,
											productId: 2,
											originalIssueDate: todayStr,
											expiryDate: trialExpStr,
											numberOfDevices: 5,
											unlimited: true,
											maxNumberOfDevice: 1,
											maxBusinessUsers: 10,
											businessPlan: 2,
											maxPatients: 1000,
											subscription: `{\n\"frequency\": \"monthly\",\n\"type\": \"trial\"\n}`,
											addons: {
												3: {
													issueDate: `${todayStr}`,
													expiryDate: `${trialExpStr}`,
												},
											},
											// addons: `{\"1\":{\"issueDate\":\"${todayStr}\",\"expiryDate\":\"${trialExpStr}\"}, \"2\":{\"issueDate\":\"${todayStr}\",\"expiryDate\":\"${trialExpStr}\"}}`,
											subscriptionId: "",
										};

										axios.post(dbUrl + `/api/businesslicenses/v1/create`, LicensePostData, LicensePostHeaders).then((_) => {
											// props.setState(({...props.parentState, Refresh: props.parentState.Refresh + 1, Preloader: false}));
											props.setState({ 
												...props.parentState, 
												Preloader: false, 
												View: "overview", 
												Refresh: props.parentState.Refresh + 1, 
												BussinessAccountRegisterSuccessAlert: true 
											});
											
										});
									} else {
										// no free trial license
										props.setState({ 
											...props.parentState, 
											Preloader: false, 
											View: "overview", 
											Refresh: props.parentState.Refresh + 1, 
											BussinessAccountRegisterSuccessAlert: true 
										});
									}
								})
								.catch((_) => {
									props.setState({ 
										...props.parentState, 
										Preloader: false, 
										View: "overview", 
										Refresh: props.parentState.Refresh + 1,
										BussinessAccountRegisterFailureAlert: true
									});
								});
						} else {
							props.setState({ ...props.parentState, Preloader: false }); // end preloader
							setState({ ...state, NameInValid: [true, "Something went wrong"] });
						}
					});
				}
			});
	};

	const CheckValid = (thing, value) => {
		if (thing === "Phone") {
			if (value.length == 0) {
				setState({ ...state, PhoneInValid: [true, "Invalid Number"] });
			} else {
				setState({ ...state, PhoneInValid: [false, ""] });
			}
		}

		// if (thing === "Fax") {
		// 	if (value.length !== 14 && value.length !== 0) {
		// 		setState({ ...state, FaxInValid: [true, "Number is not 10 digits"] });
		// 	} else {
		// 		setState({ ...state, FaxInValid: [false, ""] });
		// 	}
		// }
	};

	const Countries = useMemo(() => countryList().getLabels(), []);

	return (
		<Row>
			<Col className="landing-form-wrapper create-org-wrapper" xl={7} lg={8} md={12}>
				<h2 className="blue-color" onClick={(e) => testLink()}>
					Create New Organization
				</h2>
				<Form validated={state.Validated} onSubmit={(e) => HandleSubmit(e)}>
					<Row>
						<Form.Group as={Col} md={6} sm={12} controlId="create-organization_name">
							<Form.Control
								size="sm"
								type="input"
								value={state.Name}
								onChange={(e) => setState({ ...state, Name: e.currentTarget.value })}
								placeholder="Name"
								required
								isInvalid={state.NameInValid[0]}
							/>
							<Form.Control.Feedback type="invalid">{state.NameInValid[1]}</Form.Control.Feedback>
						</Form.Group>

						<Form.Group as={Col} md={6} sm={12} controlId="create-organization_type">
							<Form.Control
								size="sm"
								as="select"
								value={state.Type}
								onChange={(e) => setState({ ...state, Type: e.currentTarget.value })}
								placeholder="Type"
								custom
								className="ui-select"
							>
								{/* <option value=''>Type</option> */}
								{props.types.map((type) => (
									<option value={type}>{type}</option>
								))}
							</Form.Control>
						</Form.Group>
					</Row>

					{state.Type.toLowerCase() == "other" && (
						<Row>
							<Form.Group as={Col} sm={12} controlId="create-organization_type_other">
								<Form.Control size="sm" type="text" value={state.OtherType} onChange={(e) => setState({ ...state, OtherType: e.currentTarget.value })} placeholder="Other Type" required />
							</Form.Group>
						</Row>
					)}

					<Row>
						<Form.Group as={Col} md={6} sm={12} controlId="create-organization_phone">
							<Form.Control
								size="sm"
								type="phone"
								isInvalid={state.PhoneInValid[0]}
								onBlur={(e) => CheckValid("Phone", e.currentTarget.value)}
								value={DisplayPhone(state.Phone)}
								onChange={(e) => setState({ ...state, Phone: e.currentTarget.value })}
								placeholder="Phone"
							/>
							<Form.Control.Feedback type="invalid">{state.PhoneInValid[1]}</Form.Control.Feedback>
						</Form.Group>
						<Form.Group as={Col} md={6} sm={12} controlId="create-organization_fax">
							<Form.Control
								size="sm"
								type="phone"
								isInvalid={state.FaxInValid[0]}
								onBlur={(e) => CheckValid("Fax", e.currentTarget.value)}
								value={DisplayPhone(state.Fax)}
								onChange={(e) => setState({ ...state, Fax: e.currentTarget.value })}
								placeholder="Fax"
							/>
							<Form.Control.Feedback type="invalid">{state.FaxInValid[1]}</Form.Control.Feedback>
						</Form.Group>
					</Row>

					<Row>
						<Form.Group as={Col} sm={12} controlId="create-organization_email">
							<Form.Control size="sm" type="email" value={state.Email} onChange={(e) => setState({ ...state, Email: e.currentTarget.value })} placeholder="Email" required />
						</Form.Group>
					</Row>

					<Row>
						<Form.Group as={Col} sm={12} controlId="create-organization_address">
							<Form.Control size="sm" type="input" value={state.Address} onChange={(e) => setState({ ...state, Address: e.currentTarget.value })} placeholder="Address" required />
						</Form.Group>
					</Row>

					<Row>
						<Form.Group as={Col} md={6} sm={12} controlId="create-organization_city">
							<Form.Control size="sm" type="input" value={state.City} onChange={(e) => setState({ ...state, City: e.currentTarget.value })} placeholder="City" required />
						</Form.Group>
						<Form.Group as={Col} md={6} sm={12} controlId="create-organization_province">
							<Form.Control size="sm" type="input" value={state.Province} onChange={(e) => setState({ ...state, Province: e.currentTarget.value })} placeholder="Province/State" required />
						</Form.Group>
					</Row>

					<Row>
						<Form.Group as={Col} md={6} sm={12} controlId="create-organization_postal">
							<Form.Control size="sm" type="input" value={state.Postal} onChange={(e) => setState({ ...state, Postal: e.currentTarget.value })} placeholder="Postal/Zip" required />
						</Form.Group>

						<Form.Group as={Col} md={6} sm={12} controlId="create-organization_country">
							<Form.Control
								size="sm"
								as="select"
								options={Countries}
								placeholder="Country"
								custom
								className="ui-select"
								value={state.Country}
								onChange={(e) => setState({ ...state, Country: e.currentTarget.value })}
								required
							>
								<option value="">Country</option>
								{Countries.map((country) => (
									<option value={country}>{country}</option>
								))}
							</Form.Control>
						</Form.Group>
					</Row>

					<Row>
						<Form.Group as={Col} sm={12} controlId="create-organization_logo" className="upload-wrapper">
							{/* <Form.File id="formcheck-api-custom" custom value={state.Logo} onChange={e => setState({...state, Logo: e.target.files[0].name})}> */}
							<Form.File id="formcheck-api-custom" custom>
								<Form.File.Input onChange={(e) => setState({ ...state, Logo: [e.target.files[0].name, e.target.files[0]] })} />
								<Form.File.Label data-browse="Choose Image">{state.Logo[0]}</Form.File.Label>
							</Form.File>
						</Form.Group>
					</Row>

					{state.Logo[1] && (
						<Row>
							<Col className="logo-preview">
								<div>
									<img src={URL.createObjectURL(state.Logo[1])} height="60" alt="organization logo" />
									<span className="remove-image" onClick={(e) => setState({ ...state, Logo: ["Organization Logo (Optional)", null] })}>
										Remove
									</span>
								</div>
							</Col>
						</Row>
					)}

					<Row>
						<Form.Group controlId="consent" className="form-footer-wrapper">
							<UiButton type="submit" variant="secondary" onSubmit={HandleSubmit}>
								{state.WillCreateTrialAccount ? "Start Free Trial" : "Create Organization"}
							</UiButton>
						</Form.Group>
					</Row>
				</Form>
			</Col>
		</Row>
	);
}
