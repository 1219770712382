import React, { useState, Fragment, useRef, useEffect } from "react";
import { Col, Row, Button, Modal, Image, Form, Spinner } from "react-bootstrap";

// import "../../../resources/styles/dashboard.scss";
import "bootstrap/dist/css/bootstrap.min.css";
// import "../../../App.css";
import LeftIcon from "./Assets/left.svg";
import RightIcon from "./Assets/right.svg";
import BodiometerLogo from "./Assets/bodiometer_logo.svg";

import { Default as DefaultIntake, DefaultRequired, DefaultValidated, DefaultIsValid } from "./Models/index";
import PersonalPage from "./Pages/Personal";
import MedicalPage from "./Pages/Medical";
import WorkRelatedInjuryPage from "./Pages/WorkRelatedInjury";
import MotorVehicleInjuryPage from "./Pages/MotorVehicleInjury";
import ExtendedHealthPage from "./Pages/ExtendedHealth";
import ScreenAndClearancePage from "./Pages/ScreenAndClearance";
import PreviousTreatmentPage from "./Pages/PreviousTreatment";
import HealthReportPage from "./Pages/HealthReport";
import FunctionalQuestionsPage from "./Pages/FunctionalQuestions";
import ConsentFormPage from "./Pages/ConsentForm";

const IndexToPageMapper = [
	"Personal",
	"Medical",
	"WorkRelatedInjury",
	"MotorVehicleInjury",
	"ExtendedHealth",
	"ScreeningQuestions",
	"PreviousTreatments",
	"HealthReport",
	"FunctionalQuestions",
	"ConsentForm",
];

const DrawTopProgressIndicator = ({ completed = [false, false, true, true, false, false, false], progressIndex = 0, activeIndex = 1, onIndexChange }) => {
	const width = 100;
	const height = 5;
	const hcell = Math.round((width - 5) / completed.length);
	const leftOffset = Math.round(hcell / 2);
	const radActive = 1.2;
	const radInActive = 0.9;
	const N = completed.length;

	const ComputeRadius = (k) => {
		if (k == activeIndex) {
			return radActive;
		} else {
			return radInActive;
		}
	};
	const ComputeCircleStyle = (k) => {
		if (completed[k]) {
			if (k == activeIndex) {
				return { fill: "var(--primary-green)", cursor: "pointer", stroke: "var(--primary-gray)", strokeWidth: "0.3" };
			}
			return { fill: "var(--primary-green)", cursor: "pointer" };
		} else {
			if (k == activeIndex) {
				return { fill: "var(--primary-blue)", cursor: "pointer", stroke: "var(--primary-gray)", strokeWidth: "0.3" };
			}
			return { fill: "var(--primary-blue)", cursor: "pointer" };
		}
	};
	const ComputeLineStyle = (k) => {
		if (k <= progressIndex) {
			return { stroke: "var(--primary-blue)", strokeWidth: "0.1" };
		} else {
			return { stroke: "var(--primary-blue)", strokeWidth: "0.1", strokeDasharray: "0.5" };
		}
	};
	return (
		<div style={{ position: "relative", width: "100%" }}>
			<svg style={{ width: "100%" }} viewBox={`0 0 ${width} ${height}`}>
				{completed.map((_, k) => {
					return (
						<Fragment>
							{k > 0 && <line x1={leftOffset + 2 * ComputeRadius(k - 1) + (k - 1) * hcell} y1={2.5} x2={leftOffset + k * hcell} y2={2.5} style={ComputeLineStyle(k)} />}
							<circle onClick={() => onIndexChange(k)} cx={leftOffset + ComputeRadius(k) + k * hcell} cy={2.5} r={ComputeRadius(k)} style={ComputeCircleStyle(k)} />
						</Fragment>
					);
				})}
			</svg>
			{activeIndex - 1 >= 0 && <img src={LeftIcon} onClick={() => onIndexChange(activeIndex - 1)} width="auto" height="60%" style={{ position: "absolute", left: 0, top: "20%", cursor: "pointer" }} />}
			{activeIndex + 1 <= N - 1 && (
				<img src={RightIcon} onClick={() => onIndexChange(activeIndex + 1)} width="auto" height="60%" style={{ position: "absolute", right: 0, top: "20%", cursor: "pointer" }} />
			)}
		</div>
	);
};

const IntakeMain = ({ data = DefaultIntake, showLogo = false, companyLogo = "", companyName = "", editMode = false, onBack, onSubmit, onSave, onLocalSave }) => {
	const [navCompleted, setNavCompleted] = useState(new Array(IndexToPageMapper.length).fill(false));
	const [activeIndex, setActiveIndex] = useState(0);
	const [progressIndex, setProgressIndex] = useState(0);
	const [intake, setIntake] = useState({ ...data });
	const requiredDb = { ...DefaultRequired };
	const validatedDb = { ...DefaultValidated };
	const isValidDb = { ...DefaultIsValid };
	const [viewInvalidFields, setViewInvalidFields] = useState(false);
	const [isSubmitModalVisible, setIsSubmitModalVisible] = useState(false);
	const [isSaving, setIsSaving] = useState(false);

	const OnIndexChangeHandler = (index) => {
		if (navCompleted[activeIndex] || index <= activeIndex) {
			setActiveIndex(index);
			setProgressIndex(Math.max(index, progressIndex));
			setViewInvalidFields(false);
			onLocalSave(intake);
		} else {
			setViewInvalidFields(true);
		}
	};

	const IsIntakeValid = () => {
		var isValid = true;
		for (var i = 0; i < navCompleted.length; i++) {
			isValid = isValid && navCompleted[i];
		}
		return isValid;
	};

	return (
		<Fragment>
			
			{editMode ? (
				<div style={{ marginBottom: "5vh" }}>
					<span style={{ cursor: "pointer", fontSize: "1.2rem", fontWeight: "500" }} onClick={onBack}>
						{"<"} back{" "}
					</span>
				</div>
			) : null}

			{showLogo && (
				<div style={{ maxWidth: "800px", margin: "auto" }}>
					<Row>
						<Col>
							<Image src={BodiometerLogo} width={200} />
						</Col>
						<Col style={{ textAlign: "end" }}>{!!companyLogo ? <Image src={companyLogo} width={200} /> : <Form.Label>{companyName}</Form.Label>}</Col>
					</Row>
				</div>
			)}

			<div style={{ maxWidth: "800px", margin: "auto" }}>
				<DrawTopProgressIndicator completed={navCompleted} activeIndex={activeIndex} progressIndex={progressIndex} onIndexChange={OnIndexChangeHandler} />
			</div>

			<Fragment>
				{IndexToPageMapper[activeIndex] == "Personal" && (
					<PersonalPage
						data={intake["Personal"]}
						requiredDb={requiredDb["Personal"]}
						validatedDb={validatedDb["Personal"]}
						isValidDb={isValidDb["Personal"]}
						pageCompleted={navCompleted}
						setPageCompleted={setNavCompleted}
						activeIndex={activeIndex}
						viewInvalidFields={viewInvalidFields}
						onChange={(data) => {
							setIntake({
								...intake,
								Personal: { ...data },
							});
						}}
					/>
				)}

				{IndexToPageMapper[activeIndex] == "Medical" && (
					<MedicalPage
						data={intake["Medical"]}
						requiredDb={requiredDb["Medical"]}
						validatedDb={validatedDb["Medical"]}
						isValidDb={isValidDb["Medical"]}
						pageCompleted={navCompleted}
						setPageCompleted={setNavCompleted}
						activeIndex={activeIndex}
						viewInvalidFields={viewInvalidFields}
						onChange={(data) => {
							setIntake({
								...intake,
								Medical: { ...data },
							});
						}}
					/>
				)}

				{IndexToPageMapper[activeIndex] == "WorkRelatedInjury" && (
					<WorkRelatedInjuryPage
						data={intake["WorkRelatedInjury"]}
						requiredDb={requiredDb["WorkRelatedInjury"]}
						validatedDb={validatedDb["WorkRelatedInjury"]}
						isValidDb={isValidDb["WorkRelatedInjury"]}
						pageCompleted={navCompleted}
						setPageCompleted={setNavCompleted}
						activeIndex={activeIndex}
						viewInvalidFields={viewInvalidFields}
						onChange={(data) => {
							setIntake({
								...intake,
								WorkRelatedInjury: { ...data },
							});
						}}
					/>
				)}

				{IndexToPageMapper[activeIndex] == "MotorVehicleInjury" && (
					<MotorVehicleInjuryPage
						data={intake["MotorVehicleInjury"]}
						requiredDb={requiredDb["MotorVehicleInjury"]}
						validatedDb={validatedDb["MotorVehicleInjury"]}
						isValidDb={isValidDb["MotorVehicleInjury"]}
						pageCompleted={navCompleted}
						setPageCompleted={setNavCompleted}
						activeIndex={activeIndex}
						viewInvalidFields={viewInvalidFields}
						onChange={(data) => {
							setIntake({
								...intake,
								MotorVehicleInjury: { ...data },
							});
						}}
					/>
				)}

				{IndexToPageMapper[activeIndex] == "ExtendedHealth" && (
					<ExtendedHealthPage
						data={intake["ExtendedHealth"]}
						requiredDb={requiredDb["ExtendedHealth"]}
						validatedDb={validatedDb["ExtendedHealth"]}
						isValidDb={isValidDb["ExtendedHealth"]}
						pageCompleted={navCompleted}
						setPageCompleted={setNavCompleted}
						activeIndex={activeIndex}
						viewInvalidFields={viewInvalidFields}
						onChange={(data) => {
							setIntake({
								...intake,
								ExtendedHealth: { ...data },
							});
						}}
					/>
				)}

				{IndexToPageMapper[activeIndex] == "ScreeningQuestions" && (
					<ScreenAndClearancePage
						data={intake["ScreeningQuestions"]}
						requiredDb={requiredDb["ScreeningQuestions"]}
						validatedDb={validatedDb["ScreeningQuestions"]}
						isValidDb={isValidDb["ScreeningQuestions"]}
						pageCompleted={navCompleted}
						setPageCompleted={setNavCompleted}
						activeIndex={activeIndex}
						viewInvalidFields={viewInvalidFields}
						onChange={(data) => {
							setIntake({
								...intake,
								ScreeningQuestions: { ...data },
							});
						}}
					/>
				)}

				{IndexToPageMapper[activeIndex] == "PreviousTreatments" && (
					<PreviousTreatmentPage
						data={intake["PreviousTreatments"]}
						requiredDb={requiredDb["PreviousTreatments"]}
						validatedDb={validatedDb["PreviousTreatments"]}
						isValidDb={isValidDb["PreviousTreatments"]}
						pageCompleted={navCompleted}
						setPageCompleted={setNavCompleted}
						activeIndex={activeIndex}
						viewInvalidFields={viewInvalidFields}
						onChange={(data) => {
							setIntake({
								...intake,
								PreviousTreatments: { ...data },
							});
						}}
					/>
				)}

				{IndexToPageMapper[activeIndex] == "HealthReport" && (
					<HealthReportPage
						data={intake["HealthReport"]}
						screeningData={intake["ScreeningQuestions"]}
						requiredDb={requiredDb["HealthReport"]}
						validatedDb={validatedDb["HealthReport"]}
						isValidDb={isValidDb["HealthReport"]}
						pageCompleted={navCompleted}
						setPageCompleted={setNavCompleted}
						activeIndex={activeIndex}
						viewInvalidFields={viewInvalidFields}
						onChange={(data) => {
							setIntake({
								...intake,
								HealthReport: { ...data },
							});
						}}
					/>
				)}

				{IndexToPageMapper[activeIndex] == "FunctionalQuestions" && (
					<FunctionalQuestionsPage
						data={intake["FunctionalQuestions"]}
						requiredDb={requiredDb["FunctionalQuestions"]}
						validatedDb={validatedDb["FunctionalQuestions"]}
						isValidDb={isValidDb["FunctionalQuestions"]}
						pageCompleted={navCompleted}
						setPageCompleted={setNavCompleted}
						activeIndex={activeIndex}
						viewInvalidFields={viewInvalidFields}
						onChange={(data) => {
							setIntake({
								...intake,
								FunctionalQuestions: { ...data },
							});
						}}
					/>
				)}

				{IndexToPageMapper[activeIndex] == "ConsentForm" && (
					<ConsentFormPage data={intake["Personal"]} companyName={companyName} pageCompleted={navCompleted} setPageCompleted={setNavCompleted} activeIndex={activeIndex} />
				)}
			</Fragment>

			<div style={{ maxWidth: "800px", margin: "auto", display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
				{activeIndex > 0 ? <Button onClick={() => OnIndexChangeHandler(activeIndex - 1)}>Previous</Button> : <div></div>}
				{activeIndex < IndexToPageMapper.length - 1 && editMode ? (
					<Fragment>
						{isSaving ? (
							<Spinner animation="grow" variant="secondary" />
						) : (
							<Button
								variant="success"
								onClick={() => {
									setIsSaving(true);
									onSave(intake, () => {
										setIsSaving(false);
									});
								}}
							>
								Save
							</Button>
						)}
					</Fragment>
				) : null}

				{activeIndex < IndexToPageMapper.length - 1 ? <Button onClick={() => OnIndexChangeHandler(activeIndex + 1)}>Next</Button> : <div></div>}
				{activeIndex == IndexToPageMapper.length - 1 && IsIntakeValid() && !editMode && (
					<Button
						variant="success"
						onClick={() => {
							setIsSubmitModalVisible(true);
						}}
					>
						Submit
					</Button>
				)}
			</div>

			<Modal show={isSubmitModalVisible} onHide={() => setIsSubmitModalVisible(false)}>
				<Modal.Body>Are you sure you want to submit?</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={() => setIsSubmitModalVisible(false)}>
						Cancel
					</Button>
					<Button
						variant="primary"
						onClick={() => {
							setIsSubmitModalVisible(false);
							onSubmit(intake);
						}}
					>
						Confirm
					</Button>
				</Modal.Footer>
			</Modal>
		</Fragment>
	);
};

export default IntakeMain;
