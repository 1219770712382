import React, { useState, useEffect } from 'react';
import "../resources/styles/scrolling-carousel.scss";

export default function ScrollingCarousel(props) {

  const [state, setState] = useState({
    scroll: true,
    start: 0
  });

  const HandleMouseOver = (e) => {
    setState(prev => {
      return {...prev, scroll: false};
    })
  }

  const HandleMouseLeave = (e) => {
    setState(prev => {
      return {...prev, scroll: true};
    })
  }
  
  useEffect(() => {
    
    if (state.start === -(props.images.length * 200) * 5) {
      setState(prev => {
        return {...prev, start: 0};
      })
    } else if (state.scroll) {
      setTimeout(() => {
        setState(prev => {
          return {...prev, start: prev.start - 1};
        })

      }, 20)
    }

  }, [state.start, state.scroll])


  return (
    <div className="scrolling-wrapper">
      <div style={{left: state.start}} onMouseOver={e => HandleMouseOver()} onMouseLeave={e => HandleMouseLeave()}>
        {props.images.map((image, k) => {
          if(image.link === 'https://www.wd-deo.gc.ca/eng/home.asp') {
            return <a key={`${Math.floor(Math.random()).toString()}-${k}`} href={image.link} target="_blank" rel="noopener noreferrer"><img src={image.src} className="scrolling-image" alt={image.alt} style={{maxWidth: "520px"}} width="520" /></a>
          } else {
            return <a key={`${Math.floor(Math.random()).toString()}-${k}`} href={image.link} target="_blank" rel="noopener noreferrer"><img src={image.src} className="scrolling-image" alt={image.alt} /></a>
          }
        })}
        {props.images.map((image, k) => {
          if(image.link === 'https://www.wd-deo.gc.ca/eng/home.asp') {
            return <a key={`${Math.floor(Math.random()).toString()}-${k}`} href={image.link} target="_blank" rel="noopener noreferrer"><img src={image.src} className="scrolling-image" alt={image.alt} style={{maxWidth: "520px"}} width="520" /></a>
          } else {
            return <a key={`${Math.floor(Math.random()).toString()}-${k}`} href={image.link} target="_blank" rel="noopener noreferrer"><img src={image.src} className="scrolling-image" alt={image.alt} /></a>
          }
        })}
        {props.images.map((image, k) => {
          if(image.link === 'https://www.wd-deo.gc.ca/eng/home.asp') {
            return <a key={`${Math.floor(Math.random()).toString()}-${k}`} href={image.link} target="_blank" rel="noopener noreferrer"><img src={image.src} className="scrolling-image" alt={image.alt} style={{maxWidth: "520px"}} width="520" /></a>
          } else {
            return <a key={`${Math.floor(Math.random()).toString()}-${k}`} href={image.link} target="_blank" rel="noopener noreferrer"><img src={image.src} className="scrolling-image" alt={image.alt} /></a>
          }
        })}
        {props.images.map((image, k)  => {
          if(image.link === 'https://www.wd-deo.gc.ca/eng/home.asp') {
            return <a key={`${Math.floor(Math.random()).toString()}-${k}`} href={image.link} target="_blank" rel="noopener noreferrer"><img src={image.src} className="scrolling-image" alt={image.alt} style={{maxWidth: "520px"}} width="520" /></a>
          } else {
            return <a key={`${Math.floor(Math.random()).toString()}-${k}`} href={image.link} target="_blank" rel="noopener noreferrer"><img src={image.src} className="scrolling-image" alt={image.alt} /></a>
          }
        })}
        {props.images.map((image, k)  => {
          if(image.link === 'https://www.wd-deo.gc.ca/eng/home.asp') {
            return <a key={`${Math.floor(Math.random()).toString()}-${k}`} href={image.link} target="_blank" rel="noopener noreferrer"><img src={image.src} className="scrolling-image" alt={image.alt} style={{maxWidth: "520px"}} width="520" /></a>
          } else {
            return <a key={`${Math.floor(Math.random()).toString()}-${k}`} href={image.link} target="_blank" rel="noopener noreferrer"><img src={image.src} className="scrolling-image" alt={image.alt} /></a>
          }
        })}
      </div>
    </div>
  );
}






  // method 2:

  // useEffect(() => {
  //   window.addEventListener('load', (event) => {
  //     document.querySelector('.scrolling-wrapper > div').style.transition = `transform ${props.images.length * 2}s linear`;
  //     document.querySelector('.scrolling-wrapper > div').style.transform = `translateX(-${(props.images.length * 200) * 3}px)`;
  //   })

  //   setInterval(() => {
  //     document.querySelector('.scrolling-wrapper > div').style.transition = '';
  //     document.querySelector('.scrolling-wrapper > div').style.transform = '';
  //     document.querySelector('.scrolling-wrapper > div').style.color = 'red';

  //     setTimeout(() => {
  //       document.querySelector('.scrolling-wrapper > div').style.transition = `transform ${props.images.length * 2}s linear`;
  //       document.querySelector('.scrolling-wrapper > div').style.transform = `translateX(-${(props.images.length * 200) * 3}px)`;
  //     },0)

  //   }, 16000 );

  // })

  // (props.images.length * 2) * 1000