import React, { Fragment, useEffect, useState } from "react";
import { Alert, Row, Col, Popover, OverlayTrigger, Modal, Form, Button, Spinner } from "react-bootstrap";
import axios from "axios";
import { useAppContext, useUserDashboardContext } from "../lib/context.js";

import "bootstrap/dist/css/bootstrap.min.css";
import "../resources/styles/dashboard.scss";

import UIButton from "../components/dashboard/ui-button";

const FQ = ({ dbUrl }) => {
	// dbUrl = "http://localhost:54928";

	const { appState } = useAppContext();
	const { dashboardState, setDashboardState } = useUserDashboardContext();

	const iniFq = {
		Id: "",
		CaseId: "",
		PatientId:
			!!dashboardState.ActiveClinicId && dashboardState.ActiveClinicId in appState.userData.PatientUserMerges ? appState.userData.PatientUserMerges[dashboardState.ActiveClinicId].PatientId : "",
		OverallPain: -1,
		TherapistName: "",
		Type: "",
		CreatedAt: "",
		CreatedAtOffset: 0,
		Answers: [5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5],
	};

	const [state, setState] = useState({
		isFQLoaded: false,
		fqDb: {},
		fqId: "",
		fqIds: [],
		fq: iniFq,
		addNewFq: false,
	});

	useEffect(() => {
		
		if (!state.isFQLoaded) {
			let configOne = {
				method: "post",
				url: dbUrl + "/api/token/dbo",
				headers: {
					password: "746C419785D84DC8B3A20B9195363105",
				},
			};

			axios(configOne, { timeout: 5000 })
				.then((responseOne) => {
					// let postureId = state.postureId;
					let configTwo = {
						method: "get",
						url: dbUrl + "/api/patientfunctionalquestions/v2/patientid",
						headers: {
							Authorization: "Bearer " + responseOne.data,
							PatientId: appState.userData.PatientUserMerges[dashboardState.ActiveClinicId].PatientId,
						},
					};

					axios(configTwo, { timeout: 5000 })
						.then((responseTwo) => {
							if (responseTwo.data.success) {
								let newFqDb = { ...responseTwo.data.data };
								for (const [k, _] of Object.entries(newFqDb)) {
									newFqDb[k]["Answers"] = [
										newFqDb[k].Answer1,
										newFqDb[k].Answer2,
										newFqDb[k].Answer3,
										newFqDb[k].Answer4,
										newFqDb[k].Answer5,
										newFqDb[k].Answer6,
										newFqDb[k].Answer7,
										newFqDb[k].Answer8,
										newFqDb[k].Answer9,
										newFqDb[k].Answer10,
										newFqDb[k].Answer11,
										newFqDb[k].Answer12,
										newFqDb[k].Answer13,
										newFqDb[k].Answer14,
										newFqDb[k].Answer15,
										newFqDb[k].Answer16,
										newFqDb[k].Answer17,
									];
								}

								const sortFQ = (a, b) => {
									return new Date(AddHoursToDate(newFqDb[a].CreatedAt, newFqDb[a].CreatedAtOffset)).getTime() >= new Date(AddHoursToDate(newFqDb[b].CreatedAt, newFqDb[b].CreatedAtOffset)).getTime()
										? -1
										: 1;
								};
								let newFqIds = Object.keys(newFqDb).sort(sortFQ);
								let newFqId = newFqIds.length > 0 ? newFqIds[0] : "";

								let newIniFqId = newFqIds.length > 0 ? newFqIds[0] : "";
								let newFq = { ...iniFq };
								if (!!newIniFqId) {
									newFq["Answers"] = newFqDb[newIniFqId]["Answers"];
									newFq["CaseId"] = newFqDb[newIniFqId]["CaseId"];
								}

								setState({
									...state,
									fqDb: { ...newFqDb },
									fqId: newFqId,
									fqIds: [...newFqIds],
									fq: { ...newFq },
									isFQLoaded: true,
								});
							}
						})
						.catch((_) => {});
				})
				.catch((_) => {});
		}
	}, [dashboardState.ActiveClinicId, dashboardState.ActiveTopNav]);

	const RenderFQDateSelectionOverlay = () => {
		return (
			<Popover className="posture-date-overlay-wrapper" style={{ backgroundColor: "#707070" }} id={`popover${Math.floor(Math.random()).toString()}`}>
				<Popover.Content>
					<div>
						<ul>
							{state.fqIds.map((k) => {
								return (
									<li>
										<UIButton
											className={state.fqId == k ? "active-date" : "white-link"}
											onClick={() => setState({ ...state, fqId: k })}
											// &#x2605;
										>
											<span style={state.fqDb[k].Type == "home" ? { color: "#a5ced5" } : {}}>{AddHoursToDate(state.fqDb[k].CreatedAt, state.fqDb[k].CreatedAtOffset)}</span>
											{
												state.fqDb[k].Type == "intake" && <span style={{marginLeft: "5px", marginRight: "5px", paddingLeft: "5px", paddingRight: "5px", fontWeight: "bold", border: "2px solid", verticalAlign: "top", fontSize: "6px"}}>INTAKE</span>
											}
											{state.fqId == k ? <img src="../../photos/icons/dashboard/SVG/check-green.svg" width="15" /> : null}
										</UIButton>
									</li>
								);
							})}
						</ul>
					</div>
				</Popover.Content>
			</Popover>
		);
	};

	const SubmitNewFQ = async () => {
		try {
			setState({ ...state, isFQLoaded: false });

			let configOne = {
				method: "post",
				url: dbUrl + "/api/token/dbo",
				headers: {
					password: "746C419785D84DC8B3A20B9195363105",
				},
			};

			let responseOne = await axios(configOne, { timeout: 5000 });

			if (responseOne.data != "Invalid Credentials") {
				let newFq = { ...state.fq };
				newFq.CreatedAt = new Date().toLocaleString("en-US");
				newFq.CreatedAtOffset = 0;
				newFq.Type = "home";
				let configTwo = {
					method: "post",
					url: dbUrl + "/api/patientfunctionalquestions/home/v2/create",
					headers: {
						Authorization: `Bearer ${responseOne.data}`,
						"Content-Type": "application/json",
					},
					data: JSON.stringify(newFq),
				};

				let responseTwo = await axios(configTwo, { timeout: 5000 });
				if (responseTwo.data.success) {
					let addedFq = { ...responseTwo.data.data };
					let currentFqDb = { ...state.fqDb };
					addedFq["Answers"] = [
						addedFq.Answer1,
						addedFq.Answer2,
						addedFq.Answer3,
						addedFq.Answer4,
						addedFq.Answer5,
						addedFq.Answer6,
						addedFq.Answer7,
						addedFq.Answer8,
						addedFq.Answer9,
						addedFq.Answer10,
						addedFq.Answer11,
						addedFq.Answer12,
						addedFq.Answer13,
						addedFq.Answer14,
						addedFq.Answer15,
						addedFq.Answer16,
						addedFq.Answer17,
					];
					currentFqDb[addedFq.Id] = { ...addedFq };
					let newFqIds = [addedFq.Id].concat(state.fqIds);
					let newFqId = addedFq.Id;

					setState({
						...state,
						fq: { ...addedFq },
						fqDb: { ...currentFqDb },
						fqIds: newFqIds,
						fqId: newFqId,
						addNewFq: false,
					});
				} else {
					// handle error
					setState({ ...state, addNewFq: false, isFQLoaded: true });
				}
			} else {
				// error
				setState({ ...state, addNewFq: false, isFQLoaded: true });
			}
		} catch (_) {
			setState({ ...state, isFQLoaded: true });
		}
	};

	return (
		<Fragment>
			{!!dashboardState.ActiveClinicId ? (
				<Fragment>
					{state.isFQLoaded && (
						<Fragment>
							<section className="module-date-selection">
								<Row>
									<Col style={{ alignItems: "center" }}>
										{!!state.fqId && state.fqId in state.fqDb && !state.addNewFq  && (
											<Fragment>
												<div>{AddHoursToDate(state.fqDb[state.fqId].CreatedAt, state.fqDb[state.fqId].CreatedAtOffset)}</div>
												<OverlayTrigger key={`fq-overlay-dates`} rootClose={true} trigger="click" placement="right" overlay={RenderFQDateSelectionOverlay()}>
													<div style={{ cursor: "pointer", color: "#2A92CF" }}>Change</div>
												</OverlayTrigger>
											</Fragment>
										)}

										{!state.addNewFq && (
											<Button
												variant="success"
												onClick={() => {
													setState({ ...state, fq: { ...iniFq }, addNewFq: true });
												}}
											>
												Create
											</Button>
										)}
									</Col>
								</Row>
							</section>

							{(state.addNewFq || !!state.fqId) && (
								<Fragment>
									<section style={{ padding: "1rem 6%" }}>
										<Row>
											<Col
												style={{
													textAlign: "center",
													fontWeight: "bold",
													color: "var(--logo-color-1)",
												}}
											>
												Unable
											</Col>
											<Col
												style={{
													textAlign: "center",
													fontWeight: "bold",
													color: "var(--logo-color-1)",
												}}
											>
												Very Difficult
											</Col>
											<Col
												style={{
													textAlign: "center",
													fontWeight: "bold",
													color: "var(--logo-color-1)",
												}}
											>
												Moderately Difficult
											</Col>
											<Col
												style={{
													textAlign: "center",
													fontWeight: "bold",
													color: "var(--logo-color-1)",
												}}
											>
												Minimally Difficult
											</Col>
											<Col
												style={{
													textAlign: "center",
													fontWeight: "bold",
													color: "var(--logo-color-1)",
												}}
											>
												Normal
											</Col>
										</Row>
										<Row>
											<Col style={{ textAlign: "center", fontWeight: "bold" }}>
												<div className="module-score-highlight-1">0</div>
											</Col>
											<Col style={{ textAlign: "center", fontWeight: "bold" }}>
												<div className="module-score-highlight-1">1</div>
											</Col>
											<Col style={{ textAlign: "center", fontWeight: "bold" }}>
												<div className="module-score-highlight-1">2</div>
											</Col>
											<Col style={{ textAlign: "center", fontWeight: "bold" }}>
												<div className="module-score-highlight-1">3</div>
											</Col>
											<Col style={{ textAlign: "center", fontWeight: "bold" }}>
												<div className="module-score-highlight-1">4</div>
											</Col>
										</Row>
									</section>

									<section style={{ padding: "1rem 6%" }}>
										<hr style={{ width: "40%", height: "2px" }} />
									</section>

									<section style={{ padding: "1rem 6%" }}>
										{fqQuesOrder.map((v, k) => {
											return (
												<Fragment>
													{k + 1 == 1 && (
														<Row style={{ marginBottom: "20px" }}>
															<Col style={{ textAlign: "left" }}>
																<span
																	style={{
																		color: "var(--logo-color-1)",
																		fontSize: "120%",
																		fontWeight: "bold",
																	}}
																>
																	SLEEPING
																</span>
															</Col>
														</Row>
													)}
													{k + 1 == 2 && (
														<Row style={{ marginBottom: "20px" }}>
															<Col style={{ textAlign: "left" }}>
																<span
																	style={{
																		color: "var(--logo-color-1)",
																		fontSize: "120%",
																		fontWeight: "bold",
																	}}
																>
																	SITTING
																</span>
															</Col>
														</Row>
													)}
													{k + 1 == 4 && (
														<Row style={{ marginBottom: "20px" }}>
															<Col style={{ textAlign: "left" }}>
																<span
																	style={{
																		color: "var(--logo-color-1)",
																		fontSize: "120%",
																		fontWeight: "bold",
																	}}
																>
																	MOVING - STANDING/WALKING/RUNNING
																</span>
															</Col>
														</Row>
													)}
													{k + 1 == 10 && (
														<Row style={{ marginBottom: "20px" }}>
															<Col style={{ textAlign: "left" }}>
																<span
																	style={{
																		color: "var(--logo-color-1)",
																		fontSize: "120%",
																		fontWeight: "bold",
																	}}
																>
																	DAILY ACTIVITIES
																</span>
															</Col>
														</Row>
													)}
													{k + 1 == 16 && (
														<Row style={{ marginBottom: "20px" }}>
															<Col style={{ textAlign: "left" }}>
																<span
																	style={{
																		color: "var(--logo-color-1)",
																		fontSize: "120%",
																		fontWeight: "bold",
																	}}
																>
																	JOB ACTIVITIES
																</span>
															</Col>
														</Row>
													)}

													<Row style={{ marginBottom: "20px" }}>
														<Col style={{ textAlign: "left" }}>
															{k + 1}. {fqQuesList[v - 1]}
														</Col>
													</Row>
													<Row style={{ marginBottom: "50px" }}>
														<Col style={{ textAlign: "center" }}>
															{!state.addNewFq && !!state.fqId && state.fqId in state.fqDb && (
																<Fragment>
																	{Array(6)
																		.fill(0)
																		.map((v1, k1) => {
																			return (
																				<Button
																					disabled
																					style={{
																						marginRight: "20px",
																						width: "40px",
																						height: "40px",
																					}}
																					className={state.fqDb[state.fqId].Answers[v - 1] == k1 ? "fq-rating-button-active" : "fq-rating-button-inactive"}
																				>
																					{k1 == 5 ? "NA" : k1}
																				</Button>
																			);
																		})}
																</Fragment>
															)}
															{state.addNewFq && (
																<Fragment>
																	{Array(6)
																		.fill(0)
																		.map((v1, k1) => {
																			return (
																				<Button
																					style={{
																						marginRight: "20px",
																						width: "40px",
																						height: "40px",
																					}}
																					className={state.fq.Answers[v - 1] == k1 ? "fq-rating-button-active" : "fq-rating-button-inactive"}
																					onClick={() => {
																						let newFq = { ...state.fq };
																						newFq.Answers[v - 1] = k1;
																						setState({ ...state, fq: newFq });
																					}}
																				>
																					{k1 == 5 ? "NA" : k1}
																				</Button>
																			);
																		})}
																</Fragment>
															)}
														</Col>
													</Row>
												</Fragment>
											);
										})}

										<Row style={{ marginBottom: "20px" }}>
											<Col style={{ textAlign: "left" }}>
												<span
													style={{
														color: "var(--logo-color-1)",
														fontSize: "120%",
														fontWeight: "bold",
													}}
												>
													PAIN SCALE
												</span>
											</Col>
										</Row>

										{state.addNewFq && (
											<Row style={{ marginBottom: "50px" }}>
												<Col style={{ textAlign: "center" }}>
													{Array(11)
														.fill(0)
														.map((_, k) => {
															return (
																<Button
																	style={{
																		marginRight: "20px",
																		width: "40px",
																		height: "40px",
																	}}
																	className={state.fq.OverallPain == k ? "fq-rating-button-active" : "fq-rating-button-inactive"}
																	onClick={() => {
																		let newFq = { ...state.fq };
																		newFq.OverallPain = k;
																		setState({ ...state, fq: newFq });
																	}}
																>
																	{k}
																</Button>
															);
														})}
												</Col>
											</Row>
										)}
										{!state.addNewFq && !!state.fqId && state.fqId in state.fqDb && (
											<Row style={{ marginBottom: "50px" }}>
												<Col style={{ textAlign: "center" }}>
													{Array(11)
														.fill(0)
														.map((_, k) => {
															return (
																<Button
																	disabled
																	style={{
																		marginRight: "20px",
																		width: "40px",
																		height: "40px",
																	}}
																	className={state.fqDb[state.fqId].OverallPain == k ? "fq-rating-button-active" : "fq-rating-button-inactive"}
																>
																	{k}
																</Button>
															);
														})}
												</Col>
											</Row>
										)}

										{state.addNewFq && (
											<Row>
												<Col>
													<Button
														variant="primary"
														style={{ marginRight: "10px" }}
														onClick={() => {
															SubmitNewFQ();
														}}
													>
														Submit
													</Button>
													<Button
														variant="secondary"
														onClick={() => {
															setState({ ...state, addNewFq: false });
														}}
													>
														Cancel
													</Button>
												</Col>
											</Row>
										)}
									</section>
								</Fragment>
							)}
						</Fragment>
					)}
					{!state.isFQLoaded && (
						<div className="preloader-wrapper">
							<Spinner animation="grow" variant="secondary" />
						</div>
					)}
				</Fragment>
			) : (
				<Fragment>
					<section style={{ padding: "5px 20px" }}>Please, connect to a clinic first.</section>
				</Fragment>
			)}
		</Fragment>
	);
};

const AddHoursToDate = (str, h) => {
	let newDate = new Date(str);
	newDate.setTime(newDate.getTime() + h * 60 * 60 * 1000);
	return newDate.toLocaleString(dateLocale, defaultDateFormat);
};

const IsEmpty = (val) => {
	return val === undefined || val == null || val.length <= 0 ? true : false;
};

const defaultDateFormat = {
	year: "numeric",
	month: "short",
	day: "numeric",
	hour: "numeric",
	minute: "numeric",
};

const dateLocale = "en-Us";

const fqQuesOrder = [1, 10, 6, 11, 4, 15, 2, 14, 9, 13, 3, 12, 5, 7, 8, 16, 17];

const fqQuesList = [
	"How normally do you sleep?", // 1 - 1
	"How difficult is it for you to move up and down stairs?", // 2 - 7
	"How difficult is it for you to perform food preparation / cooking / eating?", // 3 - 11
	"How difficult is it for you to walk?", // 4 - 5
	"How difficult is it for you to groom (bath, comb, hair, shave, etc.)?", // 5 - 13
	"How difficult is it for you to get up or down from bed or chair?", // 6 - 3
	"How well do you manage normal dressing activities?", // 7 - 14
	"How well do you manage to tie shoes / button shirt?", // 8 - 15
	"How difficult is it for you to carry items up to 10 pounds?", // 9 - 9
	"How difficult is it for you to sit for normal periods of time?", // 10 - 2
	"How difficult is it for you to stand for normal periods of time?", // 11 - 4
	"How difficult is it for you to reach above head or across body?", // 12 - 12
	"How difficult is it for you to perform leisure / recreational / sports activities?", // 13 - 10
	"How difficult is it for you to squat down to pick up items?", // 14 - 8
	"How difficult is it for you to perform running / jogging?", // 15 - 6
	"How difficult is it for you to drive? ", // 16 - 16
	"How difficult is it for you to perform regular job related activities?", // 17 - 17
];

export default FQ;
