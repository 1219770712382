
import React from 'react';
// import { Button }  from 'react-bootstrap';


import "../../resources/styles/dashboard.scss";
import "bootstrap/dist/css/bootstrap.min.css";

export default function Modal(props) {

  const HandleOverlayClick = (e) => {
    props.setState({...props.state, [props.refName]: false});
  }

  return(
    <section className={`modal-overlay${props.show ? '' : ' hide'}`} onClick={HandleOverlayClick} >
      <div className="modal-container" onClick={e => e.stopPropagation()}>
        <a className="modal-close-button" onClick={HandleOverlayClick}><img src="../../photos/icons/dashboard/SVG/close-gray.svg" width="20px" /></a> 
        {props.children}
      </div>
    </section>
  );
};