import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import "bootstrap/dist/css/bootstrap.min.css";
import "../App.css";
import "../resources/styles/pricing.scss";
import { Row, Col, Container } from 'react-bootstrap';

import NavBar from "../components/navbar.component.js";
import Footer from "../components/footer.component.js";
import ProductModal from "./product-modal.js";
import PricingCard from "../components/pricingcard.js";
import StandardHero from "../components/standard-hero.js";

import prodImgLite from "../resources/photos/products/lite.jpg";
import prodImgMobile from "../resources/photos/products/mobile.jpg";
import prodImgDesktop from "../resources/photos/products/desktop.jpg";

import store from 'local-storage';

// Helmet for dynamic meta tags in header
import MetaDecorator from "../components/metaDecorator.js";

export default function Pricing(props) {

  const [state, setState] = useState({
    videoModal1: false,
    videoModal2: false,
    formModal: false,
    faqKey: 0,

    prodName: '',

    showCart: false,

    subscriptionButtonVisible: false,

    activeSubscription: 2,

    product_list : [
        {
            name : "Bodiometer " + "Lite",
            description: "Bodiometer Lite is a free iPhone app we launched as a quick response to COVID-19. It helps patients who are practicing social distancing track their posture and outcomes digitally and send the reports to their care providers. This app provides the basic functionality of Bodiometer Pro (without a subscription) which is the ability to rapidly take a posture scan, produce a report and show the report to the patient/practitioner. This app is a scaled-down beta prototype that can be used as a quick postural assessment tool for maintaining healthy body at home.",
            image: prodImgLite,
            quantity: 0,
            price_per_month: 100,
            price_per_year: 1200,
            sale_ongoing: false,
            sale_occasion: "Black Friday",
            sale_price_per_month: 100,
            sale_price_per_year: 1200,
            plan: 'Y',           // M: Monthly, Y: Yearly
            n_months: 12,        // number of months if subscription is selected
            version: 11,         // version keeps track of the product price (helps for better cache management)
        },
        {
            name : "Bodiometer " + "Pro",
            description: "As the world's first real-time posture assessment app, Bodiometer Pro enables clinicians to quickly and accurately assess patient posture without markers or other devices in almost any setting. Bodiometer utilizes an AI-driven computer vision system to automatically extract patient skeletal data relying only on the camera of the mobile device. Patient scans are saved in app, enabling users to track progress or changes in their posture over the course of treatment. Clinicians can compare scans directly with comparative reports. These visual reports are easy to print or share with the patient as a pdf. These reports makes a huge difference in patient education and engagement which means better outcomes for everyone.",
            image: prodImgMobile,
            quantity: 0,
            price_per_month: 65,
            price_per_year: 650,
            sale_ongoing: false,
            sale_occasion: "Christmas",
            sale_price_per_month: 52,
            sale_price_per_year: 520,
            plan: 'Y',          // M: Monthly, Y: Yearly
            n_months: 12,       // number of months if monthly subscription is selected
            version: 11,        // version keeps track of the product price (helps for better cache management)
        },
        {
            name : "Bodiometer " + "Desktop",
            description: "Bodiometer Desktop is our most advanced clinical biomechanics assessment tool. Using markerless motion-tracking, you can quickly and accurately measure range of motion, posture, and functional movement patterns. Our range of motion module allows you to measure 14 different joints in a variety of different movements. Our posture module allows you to quickly measure posture in the coronal, sagittal and traverse planes. Lastly, our functional movement module allows you to measure any kind of functional movement you can think of and compare a patient’s performance over time/  Bodiometer allows you to track patient data easily, and show a patient their progress with engaging and informative progress reports. Bodiometer gives you the tools to collect accurate, objective data quickly, and educate your patients about the structure of their bodies.",
            image: prodImgDesktop,
            quantity: 0,
            price_per_month: 160,
            price_per_year: 1600,
            sale_ongoing: false, // replace it with true during sale
            sale_occasion: "Christmas",
            sale_price_per_month: 128,
            sale_price_per_year: 1280,
            plan: 'Y',          // M: Monthly, Y: Yearly
            n_months: 12,       // number of months if monthly subscription is selected
            version: 11,         // version keeps track of the product price (helps for better cache management)
        }
    ], 
    currentProductId: 1,
    currentProductVersion: 11,
    currency: 'Canadian Dollar',
    currencyAbbr: 'CAD',
    currencySymbol: 'C$',

    encryptionKey: 'secured information of the bodiometer product page',
    
  });

  let location = useLocation();
  

  // Hash linking through passing state from nav Link to location
  useEffect(() => {

    if(location.state) {
      if(location.state.toHome) {
        document.getElementById('order-home').scrollIntoView();
      } else if(location.state.toHomePlus) {
        document.getElementById('order-home-plus').scrollIntoView();
      } else if(location.state.toPro) {
        document.getElementById('order-pro').scrollIntoView();
      } else if(location.state.toDesktop) {
        document.getElementById('order-desktop').scrollIntoView();
      }
    }
    

  })

  const DesktopIcon = () => {
      return(
          <svg style={{width: '32px', height: '32px'}} viewBox="0 0 24 24">
              <path fill="#6C7C85" d="M21,14H3V4H21M21,2H3C1.89,2 1,2.89 1,4V16A2,2 0 0,0 3,18H10L8,21V22H16V21L14,18H21A2,2 0 0,0 23,16V4C23,2.89 22.1,2 21,2Z" />
          </svg>
      );
  }

  const LiteIcon = () => {
      return(
          <svg style={{width: '32px', height: '32px'}} viewBox="0 0 24 24">
              <path fill="#6C7C85" d="M17,19H7V5H17M17,1H7C5.89,1 5,1.89 5,3V21A2,2 0 0,0 7,23H17A2,2 0 0,0 19,21V3C19,1.89 18.1,1 17,1Z" />
          </svg>
      );
  }

  // Apply selected faq class for styling
  const HandleFaqClick = (e) => {

    let element = document.querySelector('.faq-wrapper .card .collapse.show').previousElementSibling;

    if (element.className.includes('active-faq')) {
        element.className = "card-header";
      } else {
        element.className += ' active-faq';
      }

    const element1 = e.target;
    if (element.className.includes('active-faq')) {
      element1.className = "card-header"
    } else {
      element1.className += ' active-faq';
    }

  };


  const email = React.useRef();


  const HideSubscribe = () => {
    setState({
        ...state,
        subscribed: false,
        alreadySubscribed: false, 
        failedSubscription: false,
    });
  }

  const HandleSubmit = (e) => {
    e.preventDefault();
    fetch('/api/subscribe', {
        method: 'POST',
        headers: {
            "Content-Type" : "application/json"
        },
        body: JSON.stringify(
            {
                "email": email.current.value,
                "signupchecked": true,
            }
        )
    })
    .then(result => {
        result.json()
        .then((res) => {
            if(res.status_code === 200){
                setState({
                    ...state,
                    subscribed: true,
                    alreadySubscribed: false, 
                    failedSubscription: false,
                });
            }
            else{
                setState({
                    ...state,
                    subscribed: false,
                    alreadySubscribed: false, 
                    failedSubscription: true,
                });
            }
        })
        
    })
    .catch(err => {
        console.log(err);
        setState({
            ...state,
            subscribed: false,
            alreadySubscribed: false, 
            failedSubscription: true,
        });
    })
  }



  const ShowCart = (e) => {
    var product_list = state.product_list;
    if(product_list[e.target.value].quantity === 0){
        product_list[e.target.value].quantity = 1;
    }
    setState({
        ...state,
        currentProductId: Number(e.target.value),
        showCart: true,
        product_list: product_list
    });

    store.set('product_list', EncryptedObject(state.product_list));
  }

  const HideCart = () => {
    let product_id = 0;
    // switch(props.prodName){
    //     case 'desktop': {
    //         product_id = 2;
    //         break;
    //     }
    //     case 'pro': {
    //         product_id = 1;
    //         break;
    //     }
    //     case 'lite': {
    //         product_id = 0;
    //         break;
    //     }
    // }
    setState({
        ...state,
        showCart: false,
        currentProductId: product_id
    });
  }

  const EncryptedObject = (obj) => {
    // loading encrytion module
    var CryptoJS = require("crypto-js");
    var ciphertext = CryptoJS.AES.encrypt(JSON.stringify(obj), state.encryptionKey);
    return ciphertext.toString();
  }

  const DecryptObject = (obj) => {
    // loading decrytion module
    var CryptoJS = require("crypto-js");
    var bytes  = CryptoJS.AES.decrypt(obj, state.encryptionKey);
    var decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    return decryptedData;
  }


  return(
    <div>
      <MetaDecorator
          description="Smart Postural Analysis Scanning for Clinicians, Ergonomists, & Human Performance Coaches. Scan Your Clients' Biomechanics in Less than 1 Minute. Learn More!"
          title="Bodiometer for Professionals - Smart Postural Analysis"
          url="https://www.bodiometer.com/professionals"
      />
      <NavBar />


      {/* Cart Modal */}
      <ProductModal type={state.prodName} state={state} setState={setState}/>
        
      <StandardHero tall={false} bg={false}>
        <div>
          <img src="../../photos/icons/products.svg" width="80" alt="about us icon" />
          <h1 className="blue-color">Products</h1>
        </div>
      </StandardHero>
      <section className="pricing-section-wrapper">
        <Container className="centered-container">
          <div className="title-icon">
            <img src="../../photos/icons/about-gray.svg" width="80" alt="for everyone icon" />
            <h2 className="gray-color">For Everyone</h2>
          </div>
          <Row xs={1} md={2} lg={2}>
            <Col id="order-home">
              <PricingCard 
                img="../photos/products/home.svg"
                price="FREE"
                includes={["Automatic markerless motion tracking technology", "Automatic posture assessment", "Track progress over time"]}
                excludes={["Generate and send progress reports"]}
                value={1}
                ShowCart={ShowCart}
                cta="Download"
                url="/"
              />
            </Col>
            <Col className="product-coming-soon" id="order-home-plus">
              <div>
                <img src="../photos/products/homeplus.svg" width="180" alt="Bodiometer Home Plus Product Logo"/>
                <br/>
                <h4 className="gray-color">Coming Soon</h4>
              </div>
              {/* <PricingCard
                img="../photos/products/homeplus.svg"
                price={65}
                includes={["Automatic markerless motion tracking technology", "Automatic posture assessment", "Track progress over time", "Generate and send progress reports"]}
                excludes={[]}
                value={1}
                ShowCart={ShowCart}
                cta="Buy Now"
                url="/"
              /> */}
            </Col>
          </Row>
        </Container>

        <Container className="centered-container" id="order-professionals">
          <div className="title-icon">
            <h2 className="gray-color">For Professionals</h2>
            <img src="../../photos/icons/applications-gray.svg" width="80" alt="for everyone icon" />
          </div>
          <Row xs={1} md={2} lg={2}>
            <Col id="order-pro">
              <PricingCard 
                img="../photos/products/pro.svg"
                // price={650}
                includes={["Unlimited number of clinicians / users", "Unlimited number of patients", "Automatic markerless motion tracking technology", "Automatic posture assessment", "Track patient progress over time", "Generate & send progress reports"]}
                excludes={["70+ range of motion tests", "Posture endurance testing", "Custom functional movement analysis"]}
                value={1}
                ShowCart={ShowCart}
                cta="Get Started"
                url="/product/pro"
                download="https://apps.apple.com/ca/app/bodiometer/id1441230362"
                freeTrialLength={props.freeTrialLength}
              />
            </Col>
            <Col id="order-desktop">
              <PricingCard
                img="../photos/products/desktop.svg"
                // price={1600}
                includes={["Unlimited number of clinicians / users", "Unlimited number of patients", "Automatic markerless motion tracking technology", "Automatic posture assessment", "Track patient progress over time", "Generate & send progress reports", "70+ range of motion tests", "Posture endurance testing", "Custom functional movement analysis"]}
                excludes={[]}
                value={2}
                ShowCart={ShowCart}
                cta="Get A Quote"
                url="/product/desktop"
                download="https://www.microsoft.com/en-us/store/p/physio4d/9n2sw42jf4kz"
              />
            </Col>
          </Row>
        </Container>
      </section>
      {/* <p className="gray-color centered-text">All Prices in CAD</p> */}
        <Footer />
        {/* <!-- Start of HubSpot Embed Code --> */}
          <script type="text/javascript" id="hs-script-loader" async defer src="//js.hs-scripts.com/2936832.js"></script>
        {/* <!-- End of HubSpot Embed Code --> */}
    </div>
  );
};