import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTwitter, faFacebook, faLinkedinIn, faYoutube, faInstagram } from '@fortawesome/free-brands-svg-icons';
import CookieConsent from "react-cookie-consent";
import { Link } from 'react-router-dom';


import "bootstrap/dist/css/bootstrap.min.css";
import "../resources/styles/footer.scss";

import localPosts from "../resources/blog/posts/localPosts.json";

export default function Footer() {

    const [state, setState] = useState({
        subscribed: false,
        alreadySubscribed: false,
        failedSubscription: false,
        blogs : {}
    });

    const email = React.useRef();

    // sort blog posts into blogs state with 5 entries max ordered by date
    useEffect(() => {
        const sortedPosts = Object.values(localPosts.post).sort((a, b) => new Date(b.date) - new Date(a.date));
        let blogs = {};
        for (let post of sortedPosts) {
            if (Object.keys(blogs).length <= 5 && post.status === 'publish') {
                blogs[post.id] = {
                    title: post.title,
                    exerpt: post.excerpt,
                    slug: post.slug,
                    date: post.date
                }
            }
        }
        setState({
            ...state,
            blogs: blogs
        });
    },[])

    const HideSubscribe = () => {
        setState({
            ...state,
            subscribed: false,
            alreadySubscribed: false, 
            failedSubscription: false,
        });
    }

    const HandleSubmit = (e) => {
        e.preventDefault();
        fetch('/api/subscribe', {
            method: 'POST',
            headers: {
                "Content-Type" : "application/json"
            },
            body: JSON.stringify(
                {
                    "email": email.current.value,
                    "signupchecked": true,
                }
            )
        })
        .then(result => {
            result.json()
            .then((res) => {
                if(res.status_code == 200){
                    setState({
                        ...state,
                        subscribed: true,
                        alreadySubscribed: false, 
                        failedSubscription: false,
                    });
                }
                else{
                    setState({
                        ...state,
                        subscribed: false,
                        alreadySubscribed: false, 
                        failedSubscription: true,
                    });
                }
            })
            
        })
        .catch(err => {
            console.log(err);
            setState({
                ...state,
                subscribed: false,
                alreadySubscribed: false, 
                failedSubscription: true,
            });
        })
    }

    return (
        <div>
            <Container id="footer-container" fluid>
                <Row xs={1} md={3} lg={4} className="footer-row">
                    <Col>
                        <h3 className="blue-color">Social Media</h3>
                        <a target="_blank" rel="noopener noreferrer" href="https://www.facebook.com/Bodiometer-286000275349066">
                                <FontAwesomeIcon icon={faFacebook} />
                                &nbsp; Facebook
                        </a>
                        <a target="_blank" rel="noopener noreferrer" href="https://twitter.com/bodiometer">
                                <FontAwesomeIcon icon={faTwitter} />
                                &nbsp; Twitter
                        </a>
                        <a target="_blank" rel="noopener noreferrer" href="https://www.linkedin.com/company/bodiometer/">
                                <FontAwesomeIcon icon={faLinkedinIn} />
                                &nbsp; LinkedIn        
                        </a>
                        <a target="_blank" rel="noopener noreferrer" href="https://www.instagram.com/bodiometer/">
                                <FontAwesomeIcon icon={faInstagram} />
                                &nbsp; Instagram        
                        </a>
                        <a target="_blank" rel="noopener noreferrer" href="https://www.youtube.com/channel/UCB-jBwv30ERCXYc7Qs5Fkqw">
                                <FontAwesomeIcon icon={faYoutube} />
                                &nbsp; YouTube        
                        </a>
                    </Col>
                    <Col>
                        <h3 className="blue-color">Quick Links</h3>
                        <Link to="/about">About Us</Link>
                        <Link to="/professionals">For Professionals</Link>
                        <Link to="/careers">Careers</Link>
                        <Link to="/news">News</Link>
                        <Link to="/contact">Contact</Link>
                    </Col>
                    <Col>
                        <h3 className="blue-color">Latest News</h3>
                        {Object.keys(state.blogs).length > 0 &&
                            <Col>
                                
                                {Object.keys(state.blogs)
                                .sort((k1, k2) => {
                                    if(state.blogs[k1].date > state.blogs[k2].date){
                                        return -1;
                                    }
                                    else{
                                        return 1;
                                    }
                                })
                                .map((k, index) => {
                                    return(
                                        <div key={index}>
                                            <Link to={'/news/'+ state.blogs[k].slug}>
                                                {state.blogs[k].title}
                                            </Link>
                                        </div>
                                    );
                                })}
                                <div>
                                    <Link to={'/news/'}>
                                        More...
                                    </Link>
                                </div>
                            </Col>
                        }
                        {
                            Object.keys(state.blogs).length === 0 &&
                            <Col>
                                <div>
                                    Coming Soon
                                </div>
                            </Col>
                        }
                    </Col>
                    <Col md={3} lg={3}>
                        <Link to="/">
                            <img alt="Bodiometer Logo" src="../../photos/consumer-landing/footer-logo.svg" className="company-logo" />
                        </Link>
                    </Col>
                </Row>
                <Row className="footer-footer-wrapper">
                    <hr />
                    <p>Copyright © 2020 BODIOMETER - All Rights Reserved - <Link to="/terms">Terms of Use</Link> - <Link to="/privacy">Privacy Policy</Link></p>
                </Row>
                
            </Container>

            <CookieConsent
                // enableDeclineButton
                location="bottom"
                buttonText="Got it"
                cookieName="bodiometer"
                id="cookie-notice"
                expires={150}
            >
                <p>We use cookies to ensure you get the best experience on our website.{" "}
                For more information view our <span> <Link to='/privacy' target="_blank">privacy policy</Link></span>.</p>
            </CookieConsent>

            
        </div>
    );
};