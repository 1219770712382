export default function Match(x, y, output) {
  if (output) {
    if (x === y) {
      return output;
    } else {
      return '';
    }
  } else {
    if (x === y) {
      return true;
    } else {
      return false;
    }
  }
}