import React, { useState, Fragment, useEffect } from "react";
import { Container, Col, Row, Button, Image, Modal, Form, Alert } from "react-bootstrap";
import axios from "axios";

import "bootstrap/dist/css/bootstrap.min.css";
import "../App.css";
import "../resources/styles/application.css";
import "../resources/styles/nucca.scss";

import StandardHero from "../components/standard-hero.js";
import NavBar from "../components/navbar.component.js";
import Footer from "../components/footer.component.js";
import UiButton from "../components/dashboard/ui-button.js";

import MetaDecorator from "../components/metaDecorator.js";

const dbUrl = "https://bodiometerhomerestapi-dot-project-4839952831808961167.appspot.com";
// const dbUrl = "http://localhost:54928";

export default function Nucca(props) {
	const [state, setState] = useState({
		VideoModal: false,
		Message: "WROTSS2023",
		Fname: "",
		Lname: "",
		Email: "",
		Signup: true,
		Success: [false, ""],
		Error: [false, ""],
	});

	const [isFormValid, setIsFormValid] = useState(false);

	

	const SubmitNucca = async (evt) => {
		try {
			evt.preventDefault();

			if (isFormValid) {
				var configOne = {
					method: "POST",
					url: dbUrl + "/api/token/dbo",
					headers: {
						password: "746C419785D84DC8B3A20B9195363105",
					},
				};

				var responseOne = await axios(configOne, { timeout: 5000 });

				if (responseOne.data != "Invalid Credentials") {
					var newContact = {
						firstName: state.Fname,
						lastName: state.Lname,
						email: state.Email,
						phone: "",
						comment: state.Message,
						date: new Date().toLocaleString("en-US"),
						hasSignedUp: state.Signup,
					};

					var configTwo = {
						method: "POST",
						url: dbUrl + "/api/email/v1/source/contact",
						headers: {
							Authorization: `Bearer ${responseOne.data}`,
							"Content-Type": "application/json",
						},
						data: JSON.stringify(newContact),
					};

					let responseTwo = await axios(configTwo, { timeout: 5000 });

					if (responseTwo.data.success) {
						setState({ ...state, Success: [true, "Thank you! A Bodiometer team member will be in touch shortly."] });
					} else {
						setState({ ...state, Error: [true, "Something went wrong.."] });
					}
				} else {
					setState({ ...state, Error: [true, "Something went wrong.."] });
				}
			}
      else{
        setState({ ...state, Error: [true, "Something went wrong.."] });
      }
		} catch (_) {
			setState({ ...state, Error: [true, "Something went wrong.."] });
		}
	};

	useEffect(() => {
		setIsFormValid(!!state.Fname && !!state.Lname && !!state.Email);
	}, [state]);

	return (
		<div>
			<NavBar />

			<MetaDecorator
				description="Bodiometer provides smart biomechanic scanning and analytics for consumers and clinical professionals to help prevent injury, and track and improve postural health!"
				title="Bodiometer - WROTSS 2023"
				url="https://www.bodiometer.com/wrotss"
			/>

			<StandardHero tall={false} bg={false}>
				<div>
					<h1 className="blue-color">WROTSS 2023</h1>
				</div>
			</StandardHero>

			<section className="nucca-body-wrapper">
				<Container>
					<Row>
						<Col>
							<div className="standalone-video-wrapper">
								<img src="../../photos/nucca21/video-bg.png" width="100%" alt="video testimonial background 2" />
								<img src="../../photos/icons/play-white.svg" width="150" className="video-play-icon" onClick={() => setState({ ...state, VideoModal: true })} alt="video play icon 2" />
							</div>
							<Modal size="xl" className="video-modal-wrapper" centered show={state.VideoModal} onHide={() => setState({ ...state, VideoModal: false })}>
								<Modal.Header closeButton></Modal.Header>
								<Modal.Body>
									<video autoPlay={true} loop={false} width="100%" controls>
										<source src="../../photos/wrotss23/wrotss-video.mp4" type="video/mp4" />
									</video>
								</Modal.Body>
							</Modal>
						</Col>

						<Col lg={7}>
							<h2 className="blue-color">We Want to Help You</h2>
							<h3 className="green-color">Help Your Patients</h3>
							<p className="gray-color">
							We are looking for a select group of occupational therapists attending WROTSS 2023 conference to use Bodiometer’s new patient engagement platform in their clinics and provide monthly feedback on its impact in their clinical revenue. We are offering a one-month free trial of Bodiometer products to this test group. Using Bodiometer will increase patient engagement, patient retention rate and help achieve patient functional goals, leading to better outcomes for your clients and an increase in revenue for your clinic. We want to help you, and we’ll be there every step of the way to get you started with our products!
							</p>
						</Col>
					</Row>
				</Container>
			</section>
			<section className="nucca-benefits-wrapper padding-mobile nucca-benefits-wrapper light-grad-bulge">
				<Container className="centered-container">
					<div className="title-icon stack-mobile">
						<h2 className="blue-color centered-text">
							Bodiometer is the World’s First
							<br />
							Real-Time Posture Assessment App
						</h2>
					</div>
					<br />
					<p>We help you extend the lifetime value of your patients and bring new patients to your clinic by:</p>
					<Row xs={1} md={3} lg={3}>
						<Col>
							<img src="../../photos/nucca21/engagement.svg" height="40" alt="Takes too much time - icon" />
							<hr />
							<h3 className="green-color">
								Improving patient
								<br />
								engagement
							</h3>
						</Col>
						<Col>
							<img src="../../photos/nucca21/referrals.svg" height="40" alt="Inneficient - icon" />
							<hr></hr>
							<h3 className="green-color">
								Assessing patient 
								<br />
								functional outcomes
							</h3>
						</Col>
						<Col>
							<img src="../../photos/nucca21/graph.svg" height="40" alt="Increases non-compliance - icon" />
							<hr></hr>
							<h3 className="green-color">
								Increasing patient
								<br />
								retention rate
							</h3>
						</Col>
					</Row>
				</Container>
			</section>

			<section className="nucca-details-wrapper">
				<Container>
					<Row>
						{state.Success[0] && (
							<Alert variant="info" show={state.Success[0] ? true : false}>
								<div className="flex-row">
									<p>{state.Success[1]}</p>
								</div>
							</Alert>
						)}
						{state.Error[0] && !state.Success[0] && (
							<Alert variant="danger" show={state.Error[0] ? true : false}>
								<div className="flex-row">
									<p>{state.Error[1]}</p>
								</div>
							</Alert>
						)}

						{!state.Success[0] && (
							<Container className="nucca-form-wrapper">
								<Row>
									<div className="centered-text centered-container">
										<h2 className="green-color">Get Started</h2>
										<Row>
											<p>Fill out the form below and one of our representatives will be in touch to discuss next steps.</p>
										</Row>
									</div>
								</Row>
								<Form>
									<Row>
										<Form.Group as={Col} md="6" sm="12" controlId="contact_comment">
											<Form.Control type="text" placeholder="First Name" value={state.Fname} onChange={(e) => setState({ ...state, Fname: e.currentTarget.value })} required />
										</Form.Group>
										<Form.Group as={Col} md="6" sm="12" controlId="contact_comment">
											<Form.Control type="text" placeholder="Last Name" value={state.Lname} onChange={(e) => setState({ ...state, Lname: e.currentTarget.value })} required />
										</Form.Group>
									</Row>

									<Row>
										<Form.Group as={Col} controlId="contact_comment">
											<Form.Control type="email" placeholder="Email" value={state.Email} onChange={(e) => setState({ ...state, Email: e.currentTarget.value })} required />
										</Form.Group>
									</Row>
									<Row>
										<Form.Group as={Col} controlId="contact_signup">
											<Form.Check
												type="checkbox"
												// isValid={state.signup}
												defaultChecked={state.Signup}
												onChange={(e) => setState({ ...state, Signup: !state.Signup })}
												label="Sign up for our email list for updates, promotions, and more."
											/>
										</Form.Group>
									</Row>

									<Row>
										<Form.Group as={Col} controlId="consent" className="form-footer-wrapper">
											<UiButton type="submit" variant="secondary" onClick={(e) => SubmitNucca(e)}>
												Submit
											</UiButton>
										</Form.Group>
									</Row>
								</Form>
							</Container>
						)}
					</Row>
				</Container>
			</section>

			<Footer />
		</div>
	);
}
