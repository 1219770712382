import React, { useState, useMemo, useEffect } from 'react';
import { Col, Row, Form, Alert } from 'react-bootstrap';
import axios from 'axios';
import countryList from 'react-select-country-list';

import formatPhone from "../../resources/helpers/format-phone.js";
import DisplayPhone from "../../resources/helpers/display-phone.js";

import UiButton from "./ui-button.js";

import "bootstrap/dist/css/bootstrap.min.css";

export default function Profile(props) {
  const [state, setState] = useState({
    EditFormValidated: false,
    Different: false,
    Title: props.user.title,
    Fname: props.user.fname,
    Lname: props.user.lname,
    Phone: props.user.phone,
    Ext: props.user.ext,
    UserId: props.user.userId,
    Email: props.user.email,
    Country: props.user.country,
    Error: [false, 'Something went wrong'],
    Success: [false, '']
  });

  const Initial = {
    EditFormValidated: false,
    Different: false,
    Title: props.user.title,
    Fname: props.user.fname,
    Lname: props.user.lname,
    Phone: props.user.phone,
    Ext: props.user.ext,
    UserId: props.user.userId,
    Email: props.user.email,
    Country: props.user.country,
    Error: [false, 'Something went wrong'],
    Success: [false, '']
  }

  // hide success/error alert after 4 seconds
  useEffect(() => {
    const timer = setTimeout(() => {
      setState(prev => ({...prev, Success: [false, ""], Error: [false, ""]}))
    }, 4000);
    return () => clearTimeout(timer);
  }, [state.Success, state.Error]);

  const HandleEditFormSubmit = (e) => {

    e.preventDefault();
    e.stopPropagation();

    props.setState({...props.parentState, Preloader: true}); // start preloader
    
    const PostData = {
      userDetail: {
        salutation: state.Title,
        firstName: state.Fname,
        lastName: state.Lname,
        email: state.Email,
        emailActivated: state.Email === props.user.email ? props.user.emailActivated : false,
        phoneNumber: formatPhone(state.Phone),
        phoneNumberExt: state.Ext
      },
      userAddress: {
        country: state.Country
      }
    }
    
    const PostHeaders = {
      headers: {
        'Authorization': props.parentState.DBAccessToken,
        'Content-Type': 'application/json',
        'userId': props.parentState.User.userId
      }
    };
    
    axios.put(
      props.dbUrl + '/api/businessusers/v1/update/userid', 
      PostData, 
      PostHeaders
    )
    .then((user) => {
      
      props.setState({...props.parentState, Preloader: false}); // end preloader
  
      if (user.data.success) {
        props.setState({...props.parentState, Refresh: props.parentState.Refresh + 1})
        // props.setState( prev => ({...prev, Refresh: prev['Refresh'] + 1 }));
        setState({...state, Success: [true, "Profile saved successfully"], Different: false})

        // let updateSuccess = () => {
        //   setState({...state, Success: [false, ""]})
        // }

        // setTimeout(updateSuccess(), 7000);



      } else {
        setState({...state, Error: [true, "Oops, Something went wrong.."]})
      }

    });

  }

  const ResendEmailConfirmation = () => {

    const confirmationPostData = {
      userId: props.parentState.User.id
    }
    
    const PostHeaders = {
      headers: {
        'Authorization': props.parentState.DBAccessToken,
        'Content-Type': 'application/json',
      }
    };
    
    axios.post(
      props.dbUrl + '/api/email/v2/businessusers/confirmation', 
      confirmationPostData,
      PostHeaders
    )
    .then((res) => {
      setState({...state, Success: [true, 'Confirmation email has been resent']})
    })

  }

  const SetThing = (thing, e) => {
    return setState({...state, [thing]: e.target.value, Different: true })
  }

  const Countries = useMemo(() => countryList().getLabels(), [])
  
  return(
    <div className="ui-section-wrapper">
      <section>
        <Row>
          <Col className="flex-row no-wrap">
            <h1>Profile</h1>
            <Alert variant="warning" show={state.Error[0] ? true : false}>
              <div className="flex-row">
                <p>{state.Error[1]}</p>
              </div>
            </Alert>
            <Alert variant="info" show={state.Success[0] ? true : false}>
              <div className="flex-row">
                <p>{state.Success[1]}</p>
              </div>
            </Alert>
          </Col>
        </Row>
      </section>

      <section className="form-section-wrapper">
        <Row>
          <Col xl={7} lg={8} md={12}>
            <Form validated={state.EditFormValidated}>
              <Row>
                <Form.Group as={Col} controlId="update_title" md={4}>
                  <Form.Label>Title:</Form.Label>
                  <Form.Control size="sm" as="select" placeholder="First Name" value={state.Title} onChange={e => setState({...state, Title: e.currentTarget.value, Different: true})} custom className="ui-select">
                    {props.titles.map(title => <option value={title}>{title}</option> )}
                  </Form.Control>
                </Form.Group>
                <Form.Group as={Col} md={4} sm={12} controlId="update_fname">
                  <Form.Label>First Name:</Form.Label>
                  <Form.Control size="sm" type="input" value={state.Fname} onChange={e => SetThing('Fname',e)} placeholder="First Name" required/>
                </Form.Group>
                <Form.Group as={Col} md={4} sm={12} controlId="update_lname">
                  <Form.Label>Last Name:</Form.Label>
                  <Form.Control size="sm" type="input" value={state.Lname} onChange={e => SetThing('Lname',e)} placeholder="Last Name" required/>
                </Form.Group>
              </Row>
              
              <Row>
                <Form.Group as={Col} md={6} sm={12} controlId="update_phone">
                  <Form.Label>Phone:</Form.Label>
                  <Form.Control size="sm" type="phone" value={DisplayPhone(state.Phone)} onChange={e => SetThing('Phone',e)} placeholder="Phone" required/>
                </Form.Group>
                <Form.Group as={Col} md={6} sm={12} controlId="update_extension">
                  <Form.Label>Ext:</Form.Label>
                  <Form.Control size="sm" type="input" value={state.Ext} onChange={e => SetThing('Ext',e)} placeholder="Ext." required/>
                </Form.Group>
              </Row>

              <Row>
                <Form.Group as={Col} md={6} sm={12} controlId="update_userId">
                  <Form.Label>Username (Cannot change):</Form.Label>
                  <Form.Control size="sm" disabled type="input" value={state.UserId} placeholder="User ID" required/>
                </Form.Group>

                <Form.Group as={Col} md={6} sm={12} controlId="update_country">
                  <Form.Label>Country:</Form.Label>
                  <Form.Control size="sm" as="select" options={Countries} placeholder="Country" custom className="ui-select" value={state.Country} onChange={e => setState({...state, Country: e.currentTarget.value, Different: true})} required>
                    <option value="" >Country</option>
                    {Countries.map(country => <option value={country} >{country}</option>)}
                  </Form.Control>
                </Form.Group>
              </Row>
              
              {/* <Row>
                <Form.Group as={Col} controlId="update_email">
                  <Form.Label>Email{props.user.emailActivated ? <span className="green-color"> (Verified)</span> : ' (Not Verified)'}:</Form.Label>
                  <Form.Control size="sm" type="email" value={state.Email} onChange={e => SetThing('Email',e)} placeholder="Email" required/>
                </Form.Group>
              </Row> */}

              <div className="inline-button-input">
                  <Form.Group as={Col} md={12} controlId="update-email">
                    <Form.Label>Email{props.user.emailActivated ? <span className="green-color"> (Verified)</span> : ' (Not Verified)'}:</Form.Label>
                    <Form.Control size="sm" type="input" value={state.Email} onChange={e => SetThing('Email',e)} placeholder="Email" required/>
                    {!props.user.emailActivated && 
                      <UiButton
                        variant="primary"
                        onClick={e => ResendEmailConfirmation()}
                        size="sm"
                        className="input-inline-btn"
                      > 
                        Resend
                      </UiButton>
                    }
                  </Form.Group>
              </div>





              {/* <div className="inline-button-input">
                <Form.Group as={Col} sm={12} md={12} controlId="update-organization_address">
                  <Form.Label>Email{props.parentState.ActiveOrganization.emailActivated ? <span className="green-color"> (Verified)</span> : ' (Not Verified)'}:</Form.Label>
                  <Form.Control size="sm" type="input" value={state.Email} onChange={e => SetThing('Email',e)} placeholder="Email" required/>
                  {!props.parentState.ActiveOrganization.emailActivated && 
                    <UiButton
                      variant="primary"
                      onClick={e => ResendEmailConfirmation()}
                      size="sm"
                      className="input-inline-btn"
                    > 
                      Resend
                    </UiButton>
                  }
                </Form.Group>
              </div> */}




              
              <Row>
                <Form.Group controlId="consent" className="form-footer-wrapper">
                  <UiButton
                  type="submit"
                  variant="secondary"
                  // className={state.different ? '' : 'muted'}
                  disabled={state.Different ? false : true}
                  onClick={e => HandleEditFormSubmit(e)}
                  >
                    Save
                  </UiButton>
                </Form.Group>

                <Form.Group controlId="consent" className="form-footer-wrapper">
                  <UiButton
                  onClick={e => setState({...Initial})}
                  variant="link"
                  className="gray-color no-padding"
                  visibility={state.Different ? false : true}
                  >
                    Cancel
                  </UiButton>
                </Form.Group>

                {/* <Form.Group controlId="consent" className="form-footer-wrapper delete-user-wrapper">
                  <UiButton
                  onClick={e => DeleteAccount()}
                  variant="danger"
                  size="sm"
                  >
                    Delete Account
                  </UiButton>
                </Form.Group> */}

              </Row>
            </Form>
          </Col>
        </Row>

      </section>
    </div>
  );
};