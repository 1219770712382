import React, { Component } from "react";
import { Container, Row, Col } from "react-bootstrap";

import "bootstrap/dist/css/bootstrap.min.css";

// import logo from "../resources/logos/bodiometer.png";
import logo from "../resources/photos/bodiometer-logo-stacked.svg";

import loading_screen from "../resources/photos/loading/loading_1.gif";

export default class BusinessUserEmailConfirmation extends Component {
	constructor(props) {
        super(props);
		this.state = {
			loading: true,
			token_valid: false,
			confirmed: false,
			counter: 5,
		};
	}

    counter = 5;

    Decrement = () => {
        if(this.counter > 0){
            this.counter -= 1;
        }
        this.forceUpdate();
    }

	componentDidMount() {
		let environment = process.env.NODE_ENV;
		let domain = null;
		if (environment == "development") {
			domain = "http://localhost:54928";
		} else {
			domain = "https://bodiometerhomerestapi-dot-project-4839952831808961167.appspot.com";
		}
		let user_id = this.props.match.params.id;
		let token = this.props.match.params.token;
		var headers = new Headers();
		headers.set("Content-Type", "application/json");
		headers.set("Access-Control-Allow-Origin", "*");
		headers.set("Access-Control-Allow-Headers", "*");
		headers.set("Access-Control-Allow-Credentials", "true");
		headers.set("Access-Control-Allow-Headers", "Content-Type, Accept, X-Requested-With, remember-me");
		headers.set("Authorization", "Bearer " + token);
		fetch(domain + "/api/token/dbo/valid/", {
			method: "GET",
			mode: "cors",
			headers: headers,
		})
			.then((response) => response.json())
			.then((data) => {
				window.setTimeout(() => {
					window.location.href = "/user/login";
				}, 6000);

				window.setInterval(() => {
					this.Decrement();
				}, 1000);
				
				if (data) {
					
					fetch(domain + "/api/personalusers/emailactivate", {
						method: "POST",
						mode: "cors",
						headers: headers,
						body: JSON.stringify({ userID: `${user_id}` }),
					})
						.then((res) => res.json())
						.then((data) => {
							console.log(data);
							if (data) {
								this.setState({
									...this.state,
									loading: false,
									token_valid: true,
									confirmed: true,
								});
							} else {
								this.setState({
									...this.state,
									loading: false,
									token_valid: true,
									confirmed: false,
								});
							}
						})
						.catch((error) => {
							this.setState({
								...this.state,
								loading: false,
								token_valid: true,
								confirmed: false,
							});
						});
				} else {
					this.setState({
						...this.state,
						loading: false,
					});
				}
			})
			.catch((error) => {
				console.log(error);
				this.setState({
					...this.state,
					loading: false,
					token_valid: false,
					confirmed: false,
				});
			});
	}

	render() {
		const mainSectionStyle = {
			minHeight: "90vh",
			minWidth: "90vw",
			display: "flex",
			alignContent: "center",
			justifyContent: "center",
			flexDirection: "column",
			backgroundColor: "#fafcfb",
		};

		const messageStyle = {
			textAlign: "center",
			margin: "30px",
		};

		const logoStyle = {
			maxWidth: "400px",
		};

		return (
			<Container style={mainSectionStyle} fluid>
				<Row>
					<Col style={messageStyle}>
						<img src={logo} style={logoStyle} />
					</Col>
				</Row>

				{!this.state.token_valid && !this.state.loading ? (
					<div>
						<Row>
							<Col style={messageStyle}>
								<h3>
									The link is broken or expired <span style={{ color: "red" }}>&#33;</span>
								</h3>
							</Col>
						</Row>
					</div>
				) : this.state.confirmed && !this.state.loading ? (
					<div>
						<Row>
							<Col style={messageStyle}>
								<h3>
									You have confirmed your email address successfully. <span style={{ color: "green" }}>&#10003;</span>
								</h3>
                                <h5>Redirecting to Bodiometer Home portal in {this.counter} seconds.</h5>
							</Col>
						</Row>
					</div>
				) : (
					!this.state.confirmed &&
					!this.state.loading && (
						<div>
							<Row>
								<Col style={messageStyle}>
									<h3>
										Something went wrong <span style={{ color: "red" }}>&#33;</span>
									</h3>
								</Col>
							</Row>
						</div>
					)
				)}

				{this.state.loading && !this.state.confirmed && !this.state.token_valid && (
					<div>
						<Row>
							<Col style={messageStyle}>
								<img src={loading_screen} style={{ maxWidth: "100px" }} />
							</Col>
						</Row>
					</div>
				)}
			</Container>
		);
	}
}
