import React, { useState, useEffect } from "react";
import { Col, Row, Form, Alert } from "react-bootstrap";
import axios from "axios";

import UiButton from "./ui-button.js";
import { RetrieveToken } from "../../lib/token";
import { dbUrl } from "../../lib/config";

import "bootstrap/dist/css/bootstrap.min.css";

export default function Help(props) {
	const [state, setState] = useState({
		Message: "",
		Success: [false, ""],
		Error: [false, ""],
	});

	const Initial = {
		Message: "",
		Success: [false, ""],
		Error: [false, ""],
	};

	// hide success/error alert after 4 seconds
	useEffect(() => {
		const timer = setTimeout(() => {
			setState((prev) => ({ ...prev, Success: [false, ""], Error: [false, ""] }));
		}, 4000);
		return () => clearTimeout(timer);
	}, [state.Success, state.Error]);

	const HandleSubmit = (e) => {
		e.preventDefault();

		RetrieveToken()
			.then(async (token) => {
				var newContact = {
					firstName: props.parentState.User.fname,
					lastName: props.parentState.User.lname,
					email: props.parentState.User.email,
					phone: "",
					comment: state.Message,
					date: new Date().toISOString(),
					hasSignedUp: false,
				};

				var configTwo = {
					method: "POST",
					url: dbUrl + "/api/email/v1/contact",
					headers: {
						Authorization: `Bearer ${token}`,
						"Content-Type": "application/json",
					},
					data: JSON.stringify(newContact),
				};

				var responseTwo = await axios(configTwo, { timeout: 5000 });

				if (responseTwo.data.success) {
					setState({ ...state, Success: [true, "Thanks, your message has been sent"], Message: "" });
				} else {
					setState({ ...state, Error: [true, "Something went wrong.."], Message: "" });
				}
			})
			.catch((_) => {
				setState({ ...state, Error: [true, "Something went wrong.."], Message: "" });
			});
	};

	return (
		<div className="ui-section-wrapper">
			<Row>
				<Col xl={8} lg={12}>
					<section>
						<Row>
							<Col className="flex-row no-wrap">
								<h1>Support</h1>
								<Alert variant="warning" show={state.Error[0] ? true : false}>
									<div className="flex-row">
										<p>{state.Error[1]}</p>
									</div>
								</Alert>
								<Alert variant="info" show={state.Success[0] ? true : false}>
									<div className="flex-row">
										<p>{state.Success[1]}</p>
									</div>
								</Alert>
							</Col>
						</Row>
					</section>

					<section className="form-section-wrapper">
						<Row>
							<Col>
								<h4>Need Help?</h4>
								<p>If you have any questions or concerns, please send us a message and we will be in touch for assistance.</p>
							</Col>
						</Row>
						<Row>
							<Col>
								<Form>
									<Form.Group as={Col} controlId="contact_comment">
										<Form.Control
											as="textarea"
											rows="3"
											placeholder="Write your message here.."
											value={state.Message}
											onChange={(e) => setState({ ...state, Message: e.currentTarget.value })}
											required
										/>
									</Form.Group>

									<br />
									<Form.Group controlId="consent" className="form-footer-wrapper">
										<UiButton type="submit" variant="secondary" onClick={(e) => HandleSubmit(e)}>
											Send Message
										</UiButton>
									</Form.Group>
								</Form>
							</Col>
						</Row>
					</section>
				</Col>
			</Row>
		</div>
	);
}
