import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import "./resources/styles/global.scss";

// Website Imports
import Home from "./pages/home.pages.js";
import ScrollToTop from "./pages/scroll-to-top.js";
import ConsumerLanding from "./pages/consumer-landing.page.js";
import ForProfessionals from "./pages/professionals.page.js";
import Product from "./pages/product.page.js";
import Pricing from "./pages/pricing.page.js";
import Contact from "./pages/contact.page.js";
import About from "./pages/about.page.js";
import Careers from "./pages/careers.page.js";
import Solutions from "./pages/solution.page.js";
import Cart from "./pages/checkout.page.js";
import Blog from "./pages/blog.page.js";
import Privacy from "./pages/privacy.page.js";
import Terms from "./pages/terms.page.js";
import Nucca from "./pages/nucca.js";
import Wrotss from "./pages/wrotss.js";
import Pilot from "./pages/pilot.js";
import KnowledgeBase from "./pages/knowledge-base.js";
import Trial from "./pages/trial.js";
import PersonalUsersEmailConfirmation from "./pages/personalusers.emailconfirmation.page.js";
import BusinessUsersEmailConfirmation from "./pages/businessusers.emailconfirmation.page.js";
import BusinessAccountsEmailConfirmation from "./pages/businessaccounts.emailconfirmation.page.js";
import PersonalUserResetPassword from "./pages/personalusers.resetpassword.page.js";
import BusinessUserResetPassword from "./pages/businessusers.resetpassword.page.js";
import FourOFour from "./pages/404.page";
import LoginChoice from "./pages/login-choice";
import IntakeMain from "./components/dashboard/Intake/IntakeMain";
import IntakePublic from "./components/dashboard/Intake/IntakePublic";
import LicenseApproval from "./pages/license-approval";
import HealthStatusPublic from "./components/dashboard/HealthStatus/health_status_public";

import GoGetFitBlankPage from "./pages/gogetfit.blank.page";
import FreeTrial from "./pages/14-day-free-trial.page";

// Dashboard Imports
import Application from "./ui/application.js";
import UserHome from "./ui/user-home.js";
import UserDashboard from "./ui/user-dashboard.js";
import EmailPasswordReset from "./components/dashboard/email-password-reset.js";
import UserEmailPasswordReset from "./components/dashboard/user-email-password-reset.js";
import { AppContext } from "./lib/context.js";

import ReactGA from "react-ga";
ReactGA.initialize("UA-151755878-1");
ReactGA.pageview("/home");
ReactGA.pageview("/");
ReactGA.pageview("/professionals");
ReactGA.pageview("/contact");
ReactGA.pageview("/trial");
ReactGA.pageview("/pilot");
ReactGA.pageview("/nucca");
ReactGA.pageview("/wrotss");
ReactGA.pageview("/pricing");
ReactGA.pageview("/product/lite");
ReactGA.pageview("/product/pro");
ReactGA.pageview("/product/desktop");
ReactGA.pageview("/application");
ReactGA.pageview("/solution");
ReactGA.pageview("/news");
ReactGA.pageview("/checkout");
ReactGA.pageview("/news/:blog_slug");

function App() {
	const freeTrialLength = 30;

	const [appState, setAppState] = useState({
		isUserAuthenticated: false,
		userData: null,

		PASSPHRASE: "www.bodiometer.com v1.0",
	});

	return (
		<AppContext.Provider value={{ appState, setAppState }}>
			<Router>
				<ScrollToTop />

				{/* Website Routes */}

				<Switch>
					<Route exact path="/">
						<ConsumerLanding />
					</Route>

					<Route exact path="/login">
						<LoginChoice />
					</Route>

					<Route exact path="/home">
						<Redirect to="/" />
					</Route>

					<Route exact strict={false} path="/professionals">
						<ForProfessionals />
					</Route>

					<Route exact strict={false} path="/professionals/">
						<ForProfessionals />
					</Route>

					<Route exact strict={false} path="/product/lite">
						<Redirect to="/" />
					</Route>

					<Route exact strict={false} path="/product/pro">
						<Product prodName="pro" freeTrialLength={freeTrialLength} />
					</Route>

					<Route exact strict={false} path="/product/dashboard">
						<Product prodName="dashboard" freeTrialLength={freeTrialLength} />
					</Route>

					<Route exact strict={false} path="/product/desktop">
						<Product prodName="desktop" freeTrialLength={freeTrialLength} />
					</Route>

					<Route exact strict={false} path="/about">
						<About />
					</Route>

					<Route exact strict={false} path="/contact">
						<Contact />
					</Route>

					<Route exact strict={false} path="/careers">
						<Careers />
					</Route>

					<Route exact strict={false} path="/nucca">
						<Nucca />
					</Route>

					<Route exact strict={false} path="/wrotss">
						<Wrotss />
					</Route>

					<Route exact strict={false} path="/pilot">
						<Pilot />
					</Route>

					<Route exact strict={false} path="/knowledge-base">
						<KnowledgeBase />
					</Route>

					<Route exact strict={false} path="/trial">
						<Trial />
					</Route>

					<Route exact strict={false} path="/solution">
						<Solutions />
					</Route>

					<Route exact strict={false} path="/checkout">
						<Cart />
					</Route>

					<Route exact strict={false} path="/news/:news_slug" component={Blog} />

					<Route exact strict={false} path="/news">
						<Blog />
					</Route>

					<Route exact strict={false} path="/pricing">
						<Pricing freeTrialLength={freeTrialLength} />
					</Route>

					<Route exact strict={false} path="/privacy">
						<Privacy />
					</Route>

					<Route exact strict={false} path="/terms">
						<Terms />
					</Route>

					<Route exact strict={false} path="/api/personaluser/confirmation/:id/:token" component={PersonalUsersEmailConfirmation} />

					<Route exact strict={false} path="/api/businessuser/confirmation/:id/:token" component={BusinessUsersEmailConfirmation} />

					<Route exact strict={false} path="/api/businessaccount/confirmation/:id/:token" component={BusinessAccountsEmailConfirmation} />

					{/* <Route exact strict={false} path="/api/personaluser/resetpassword/:id/:token" component={PersonalUserResetPassword} /> */}

					{/* <Route exact path="/api/businessuser/resetpassword/:id/:token" component={BusinessUserResetPassword} /> */}

					{/* <Route exact strict={false} path="/api/gogetfit/magazine/clicked" component={GoGetFitBlankPage} /> */}

					<Route exact strict={false} path="/14-days-free-trial" component={FreeTrial} />

					<Route exact strict={false} path="/api/businessuser/resetpassword/:id/:token">
						<EmailPasswordReset />
					</Route>

					<Route exact strict={false} path="/api/personaluser/resetpassword/:id/:token">
						<UserEmailPasswordReset />
					</Route>

					<Route exact strict={false} path="/api/businessaccount/register/:businessId/:email/:roleId/:token">
						<Application freeTrialLength={freeTrialLength} view="registerIntoBusiness" />
					</Route>

					
					<Route exact path="/api/public/intakeform/:id" component={IntakePublic} />

					<Route exact path="/api/public/hsupdate/v1/:id" component={HealthStatusPublic} />

					{/* License Approval Route */}
					<Route exact path="/license/:key/approve" component={LicenseApproval} />

					{/* Dashboard Routes */}

					<Route exact strict={false} path="/portal/register">
						<Application freeTrialLength={freeTrialLength} view="register" />
					</Route>

					<Route exact strict={false} path="/portal/login/:session">
						<Application freeTrialLength={freeTrialLength} view="login" />
					</Route>

					<Route exact strict={false} path="/portal/login">
						<Application freeTrialLength={freeTrialLength} view="login" />
					</Route>

					<Route exact path={["/user/login", "/user/register", "/user/password/reset"]}>
						<UserHome />
					</Route>

					<Route exact path={["/user", "/user/dashboard"]}>
						<UserDashboard />
					</Route>

					<Route exact strict={false} path="/portal">
						<Application freeTrialLength={freeTrialLength} />
					</Route>

					<Route path="*">
						<FourOFour />
					</Route>
				</Switch>
			</Router>
		</AppContext.Provider>
	);
}

export default App;
