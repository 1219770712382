import React from 'react';
import {Container, Col, Row, Button, Carousel} from 'react-bootstrap';

import "bootstrap/dist/css/bootstrap.min.css";
import "../App.css";
import "../resources/styles/about.scss";

import StandardHero from "../components/standard-hero.js";
import NavBar from "../components/navbar.component.js";
import Footer from "../components/footer.component.js";

// Custom page meta with react-helmet
import MetaDecorator from "../components/metaDecorator.js";

export default function Careers(props) {



    return(

        <div>

            <NavBar />
            <MetaDecorator
                description="Start your career with Bodiometer Smart biomechanic scanning and analytics for consumers and clinical professionals"
                title="Bodiometer - Careers"
                url="https://www.bodiometer.com/careers"
            />

            <StandardHero tall={false} bg={false}>
                <div>
                    <img src="../../photos/icons/about-blue.svg" width="80" alt="Careers icon" />
                    <h1 className="blue-color">Careers</h1>
                </div>
            </StandardHero>

            <section className="body-top-wrapper">
                <Container>
                    <Row className="centered-text centered-row">
                        <Col lg={9}>
                          <div>
                              <h2 className="gray-color">Come join our amazing team</h2>
                              <hr/>
                              <br/>
                              <p>We're always on the hunt for amazing talent. If you're passionate about improving the world through innovative technology, contact us to learn more about our current opportunities.</p>
                              <br/>
                              <br/>
                          </div>
                        </Col>
                    </Row>
                </Container>
            </section>

              <section className="cta-centered about-cta-wrapper">
                <Container className="centered-container">
                  <h2 className="gray-color">Get in Touch!</h2>
                  <hr></hr>
                  <Button size="lg" variant="primary" href="/contact">Contact Us</Button>
                </Container>
              </section>

            <Footer />

        </ div>
    );
};
