import React from "react";
import {Button, Popover, OverlayTrigger} from "react-bootstrap";

import UiButton from "./ui-button";
import {useUserDashboardContext, useAppContext} from "../../lib/context.js";

import "../../resources/styles/dashboard.scss";
import "bootstrap/dist/css/bootstrap.min.css";


const UserProfileToggler = props => {
  const {dashboardState, setDashboardState} = useUserDashboardContext();
  const {appState} = useAppContext();

  const SignOut = _ => {
    localStorage.removeItem("_USER");
    localStorage.removeItem("_A");
    localStorage.removeItem("_ACTIVECLINIC");
    window.location.href = "/user";
  }

  const popover = (
    <Popover className="profile-toggle-wrapper" id={`popover${Math.floor(Math.random()).toString()}`}>
      <Popover.Content>
        <div>
          <h5 className="blue-color">
            {appState.userData.FirstName} {appState.userData.LastName}
          </h5>
          <h5 className="blue-color muted smaller-text">
            {appState.userData.Email}
          </h5>
          <hr />
          <UiButton onClick={(e) => setDashboardState({...dashboardState, ActiveSideTab: "profile"})} variant="link" className="no-padding gray-color">
            Manage Profile
          </UiButton>
          <UiButton
            as={"<a>"}
            href="#"
            onClick={(e) => {
              SignOut();
            }}
            variant="link"
            className="no-padding gray-color"
          >
            Sign out
          </UiButton>
        </div>
      </Popover.Content>
    </Popover>
  );

  return (
      <OverlayTrigger rootClose={true} trigger="click" placement="bottom-end" overlay={popover}>
        <Button variant="outline-primary" className="profile-toggle-button">
          {appState.userData.FirstName.slice(0, 1)}
          {appState.userData.LastName.slice(0, 1)}
        </Button>
      </OverlayTrigger>
  );
}

export default UserProfileToggler;