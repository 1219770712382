import React, { useState, useEffect, Fragment } from "react";
import { Spinner } from "react-bootstrap";
import axios from "axios";
import { useParams } from "react-router-dom";
import { dbUrl } from "../lib/config.js";
import { RetrieveToken } from "../lib/token.js";

import UiButton from "../components/dashboard/ui-button.js";

const LicenseApproval = (props) => {
	const params = useParams();
	const { key: licenseKey } = params;
	const [isLoading, setIsLoading] = useState(false);
	const [success, setSuccess] = useState(false);

	useEffect(() => {
		try {
			setIsLoading(true);
			RetrieveToken()
				.then((token) => {
					const PromiseOne = axios({
						method: "GET",
						url: dbUrl + `/api/businesslicenses/v1/licensekey`,
						headers: {
							Authorization: "Bearer " + token,
							"Content-Type": "application/json",
							licenseKey,
						},
					});

					PromiseOne.then((responseOne) => {
						if (responseOne.data.success) {
							var { tempLicenseData } = responseOne.data.data;

							const PromiseTwo = axios({
								method: "PUT",
								url: dbUrl + `/api/businesslicenses/v1/update/licensekey`,
								headers: {
									Authorization: "Bearer " + token,
									"Content-Type": "application/json",
									licenseKey,
								},
								data: {
									...tempLicenseData,
									tempLicenseData: JSON.stringify({}),
								},
							});

							PromiseTwo.then((responseTwo) => {
								if (responseTwo.data.success) {
									setSuccess(true);
								} else {
									setSuccess(false);
								}
								setIsLoading(false);
							}).catch((err) => {
								setSuccess(false);
								setIsLoading(false);
							});
						}
					}).catch((err) => {
						setSuccess(false);
						setIsLoading(false);
					});
				})
				.catch((err) => {
					setSuccess(false);
					setIsLoading(false);
				});
		} catch {}
	}, []);

	return (
		<section>
			{isLoading ? (
				<div className="preloader-wrapper">
					<Spinner animation="grow" variant="secondary" />
				</div>
			) : (
				<Fragment>
					{success ? (
						<Fragment>
							<p>Thank you very much for updating your Bodiometer license. Please, sign out from the Bodiometer account and sign back again to enjoy your newly approved plan.</p>
						</Fragment>
					) : (
						<Fragment>
                            <p>Oops. Something has gone wrong. Contact the Bodiometer Team for more information. </p>
                        </Fragment>
					)}
					<UiButton
						variant="secondary"
						onClick={(e) => {
							window.close('','_parent','');
						}}
						back
					>
						Back
					</UiButton>
				</Fragment>
			)}
		</section>
	);
};

export default LicenseApproval;
