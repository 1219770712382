import React, { useState, Fragment, useRef, useEffect } from "react";
import { Col, Row, Button, Popover, OverlayTrigger, Spinner, Form } from "react-bootstrap";
import axios from "axios";
import { jsPDF } from "jspdf";
import { cloneDeep, isEqual } from "lodash";
import { Line } from "react-chartjs-2";
import { dbUrl } from "../../../lib/config";
import { RetrieveToken } from "../../../lib/token";
import { LoadHealthStatusUpdateByIdV1, LoadHealthStatusUpdateDataV1, DefaultDateFormat, DateLocale } from "../../../lib/scripts";
import HumanBodyImg from "./assets/human_body_2.png";

import "bootstrap/dist/css/bootstrap.min.css";
import "./style/style.scss";

const HealthStatusPublic = (props) => {
	const { id } = props.match.params;
	const [isLoading, setIsLoading] = useState(false);
	const [isError, setIsError] = useState(false);
	const [data, setData] = useState({ ...DefaultData });
	const [validated, setValidated] = useState({ ...DefaultValidated });
	const [painType, setPainType] = useState("D");
	const [vitamin, setVitamin] = useState("");
	const [medication, setMedication] = useState("");
	const canvasRef = useRef(null);

	const LoadData = async () => {
		try {
			setIsLoading(true);
			var token = await RetrieveToken();
			var responseOne = await LoadHealthStatusUpdateByIdV1("Bearer " + token, id);
			if (responseOne.data.success) {
				setData({ ...responseOne.data.data });
				console.log(responseOne.data.data);
			} else {
				setIsError(true);
			}
			setIsLoading(false);
		} catch {}
	};

	const SubmitData = async () => {
		try {
			setIsLoading(true);
			var token = await RetrieveToken();
			if(!!medication && !data["Medications"].includes(medication)){
				data["Medications"].push(medication);
			}
			if(!!vitamin && !data["Vitamins"].includes(vitamin)){
				data["Vitamins"].push(vitamin);
			}
			var responseOne = await LoadHealthStatusUpdateDataV1("Bearer " + token, data);
			if (responseOne.data.success) {
				setData({ ...responseOne.data.data });
			}
			setIsLoading(false);
		} catch {}
	};

	useEffect(() => {
		LoadData();
	}, []);

	const DrawCanvas = (ctx, painDiagram, image, data) => {
		var imageWidth = image.naturalWidth == 0 ? 600 : image.naturalWidth;
		var imageHeight = image.naturalHeight == 0 ? 350 : image.naturalHeight;
		var aspectRatio = imageWidth / imageHeight;
		var maxWidth = !!painDiagram.parentNode && "offsetWidth" in painDiagram.parentNode ? painDiagram.parentNode.offsetWidth : 600;
		var maxHeight = maxWidth / aspectRatio;
		var fontSizeLarge = maxWidth > 600 ? 16 : 12;
		image.width = maxWidth;
		image.height = maxHeight;
		ctx.canvas.width = maxWidth;
		ctx.canvas.height = maxHeight;
		ctx.fillStyle = "black";
		ctx.font = `bold ${fontSizeLarge}px Rubik`;
		ctx.textAlign = "center";
		ctx.drawImage(image, 0, 0, maxWidth, maxHeight);

		for (let i = 0; i < data.PainPoints.length; i++) {
			ctx.fillText(data.PainPoints[i].PainType, data.PainPoints[i].DistanceWidth * ctx.canvas.width, data.PainPoints[i].DistanceHeight * ctx.canvas.height);
		}
	};

	useEffect(() => {
		if (!isLoading) {
			var painDiagram = canvasRef.current;
			if (!!painDiagram) {
				painDiagram.style.cursor = "pointer";
				var ctx = painDiagram.getContext("2d");
				var image = new Image();
				image.src = HumanBodyImg;
				image.onload = () => {
					DrawCanvas(ctx, painDiagram, image, data);
				};
			}
		}
	}, [data, isLoading, painType]);

	const RenderSectionHeader = ({ title }) => {
		return (
			<Row>
				<Col style={Styles.RowType3}>
					<span style={{ color: "var(--logo-color-1)", fontSize: "120%", fontWeight: "bold" }}>{title}</span>
				</Col>
			</Row>
		);
	};

	const RenderSectionInstruction = ({ label }) => {
		return (
			<Row>
				<Col>
					<div style={Styles.RowType1}>{label}</div>
				</Col>
			</Row>
		);
	};

	const RenderExerciseRow = (title, name) => {
		return (
			<Row style={Styles.VerticalGap2}>
				<Col md={3}>
					<Form.Label style={Styles.Label}>{title}</Form.Label>
				</Col>
				<Col md={9} style={Styles.RowType4}>
					<Form.Control
						as="select"
						onChange={(evt) => {
							var currentData = { ...data };
							currentData.Exercise[name].FrequencyCycle = evt.target.value;
							setData({ ...currentData });
						}}
						size="sm"
						style={Styles.Select}
					>
						{Array(10)
							.fill(0)
							.map((_, k) => {
								return <option selected={data.Exercise[name].FrequencyCycle == k ? true : false}>{k}</option>;
							})}
					</Form.Control>
					<Form.Label style={Styles.Label}>Times</Form.Label>
					<Form.Control
						as="select"
						onChange={(evt) => {
							var currentData = { ...data };
							currentData.Exercise[name].FrequencyType = evt.target.value;
							setData({ ...currentData });
						}}
						size="sm"
						style={Styles.Select2}
					>
						{Object.keys(FrequencyTypeLabels).map((v, _) => {
							return (
								<option value={v} selected={data.Exercise[name].FrequencyType == v ? true : false}>
									{FrequencyTypeLabels[v]}
								</option>
							);
						})}
					</Form.Control>
					<Form.Label style={Styles.Label}>For</Form.Label>
					<Form.Control
						size="sm"
						maxLength={4}
						style={Styles.Input}
						value={data.Exercise[name].Duration}
						onChange={(evt) => {
							var value = evt.target.value.replace(/\D/g, "");
							var currentData = { ...data };
							currentData.Exercise[name].Duration = Number(value);
							setData({ ...currentData });
						}}
						id={`duration-${name}`}
					/>
					<Form.Label style={Styles.Label}>Minutes</Form.Label>
				</Col>
			</Row>
		);
	};

	const RenderPractitionerColumn = (title, name) => {
		return (
			<Col md={4} style={Styles.CheckBox}>
				<Form.Check
					custom
					type={"checkbox"}
					id={`screening-checkbox-${name}`}
					label=""
					checked={data.PractitionersUnderCare[name]}
					onClick={() => {
						setData({ ...data, PractitionersUnderCare: { ...data.PractitionersUnderCare, [name]: !data.PractitionersUnderCare[name] } });
					}}
					style={Styles.Check}
				/>
				<Form.Label style={Styles.Label}>{title}</Form.Label>
			</Col>
		);
	};

	const RenderConsumptionRow = (title, name, text) => {
		return (
			<Row style={Styles.VerticalGap2}>
				<Col md={3}>
					<Form.Label style={Styles.Label}>{title}</Form.Label>
				</Col>
				<Col md={9} style={Styles.RowType4}>
					<Form.Control
						size="sm"
						maxLength={3}
						style={Styles.Input}
						value={data.IntakeStatistics[name]}
						onChange={(evt) => {
							setData({ ...data, IntakeStatistics: { ...data.IntakeStatistics, [name]: Number(evt.target.value.replace(/\D/g, "")) } });
						}}
					/>
					<Form.Label style={Styles.Label}>{text}</Form.Label>
				</Col>
			</Row>
		);
	};

	const RenderQuestionOptionRow = (question, options, name) => {
		return (
			<Row>
				<Col>
					<div style={Styles.RowType1}>{question}</div>
				</Col>
				<Col style={Styles.RowType2}>
					{options.map((v, k) => {
						return (
							<div>
								<Button
									className={
										validated[name] ?
										((name == "CurrenlyWearsOrthoticsOrInsoles" || name == "CurrenlyUsesDentalAppliance")
											? (k == 0 && !!data[name]) || (k == 1 && !data[name])
												? "button-active"
												: "button-inactive"
											: data[name] == k
											? "button-active"
											: "button-inactive")
											:
										("button-inactive")
									}
									onClick={() => {
										if (name == "CurrenlyWearsOrthoticsOrInsoles" || name == "CurrenlyUsesDentalAppliance") {
											setData({ ...data, [name]: k == 0 ? true : false });
											setValidated({ ...validated, [name]: true });
										} else {
											setData({ ...data, [name]: k });
											setValidated({ ...validated, [name]: true });
										}
									}}
								>
									{v}
								</Button>
							</div>
						);
					})}
				</Col>
			</Row>
		);
	};

	const RenderTextRow = (title, name) => {
		return (
			<Fragment>
				<Row>
					<Col style={Styles.Header}>{title}</Col>
				</Row>

				<Row>
					<Col style={Styles.Header}>
						<Form.Control
							size="sm"
							style={Styles.Input2}
							value={name == "Vitamins" ? vitamin : medication}
							onChange={(evt) => {
								if (name == "Vitamins") {
									setVitamin(evt.target.value.toLowerCase());
								} else {
									setMedication(evt.target.value.toLowerCase());
								}
							}}
						/>
					</Col>
				</Row>

				{data[name].map((vit) => {
					return (
						<Row>
							<Col md={1}></Col>
							<Col md={11}>
								&#8618; {vit}{" "}
								<Button
									variant="link"
									size="sm"
									style={{ color: "red" }}
									onClick={() => {
										setData({ ...data, [name]: data[name].filter((item) => item != vit) });
									}}
								>
									delete
								</Button>
							</Col>
						</Row>
					);
				})}

				<Row>
					<Col>
						<Button
							style={Styles.RowType2}
							disabled={name == "Vitamins" ? !!!vitamin : !!!medication}
							variant="info"
							size="sm"
							onClick={() => {
								var newVits = data[name];
								if (name == "Vitamins" && !data[name].includes(vitamin)) {
									newVits.push(vitamin);
									setVitamin("");
								} else if (name == "Medications" && !data[name].includes(medication)) {
									newVits.push(medication);
									setMedication("");
								}
								setData({ ...data, [name]: newVits });
							}}
						>
							Add
						</Button>
					</Col>
				</Row>
			</Fragment>
		);
	};

	const Header = ({ label }) => {
		return <h2 style={{ textAlign: "center", marginBottom: "20px" }}>{label}</h2>;
	};

	return (
		<Fragment>
			{isLoading ? (
				<div className="preloader-wrapper">
					<Spinner animation="grow" variant="secondary" />
				</div>
			) : (
				<Fragment>
					{isError ? (
						<Fragment>
							<div style={{ width: "100%", height: "100%", padding: "20px", paddingBottom: "100px" }}>
								<h3 style={{ color: "red" }}>Invalid Link!!</h3>
							</div>
						</Fragment>
					) : (
						<Fragment>
							<div style={{ width: "100%", height: "100%", padding: "20px", paddingBottom: "100px" }}>
								<span
									style={{ cursor: "pointer", fontSize: "1.2rem", fontWeight: "500" }}
									onClick={() => {
										window.location.href = "https://bodiometer.com/";
									}}
								>
									{"<"} home{" "}
								</span>

								{data.Status != "COMPLETED" ? (
									<Fragment>
										<section>
											<Header label={"Health Status Update"} />

											<Row>
												<Col>
													<div style={Styles.RowType1}>
														Please indicate on the scale <b>(0 - terrible; 10 - optimal)</b> below where you perceive your state current well-being, considering physical, mental, social, and spiritual
														aspects of your life.
													</div>
												</Col>
											</Row>

											<Row>
												<Col style={Styles.RowType2}>
													{Array(11)
														.fill(0)
														.map((_, k) => {
															return (
																<div>
																	<Button
																		style={Styles.ButtonSize}
																		className={
																			validated.PerceivedState
																				? data.PerceivedState == k
																					? "rating-button-active"
																					: "rating-button-inactive"
																				: DefaultData.PerceivedState == k
																				? "rating-button-active"
																				: "rating-button-inactive"
																		}
																		onClick={() => {
																			setData({ ...data, PerceivedState: k });
																			setValidated({ ...validated, PerceivedState: true });
																		}}
																	>
																		{k}
																	</Button>
																</div>
															);
														})}
												</Col>
											</Row>

											<Row>
												<Col>
													{" "}
													<div style={Styles.RowType1}>
														{" "}
														Please draw the location of your pain or discomfort on the image below. Use the symbols shown to represent the type(s) of pain. (First, select any symbol D/S/T/B/N/C using
														the button below. Then, click on any location on the image to indicate the position of your pain.){" "}
													</div>
												</Col>
											</Row>

											<Row>
												<Col style={Styles.RowType2}>
													<div style={Styles.VerticalSpace}>D = Dull</div>
													<div style={Styles.VerticalSpace}>S = Stabbing / Sharp</div>
													<div style={Styles.VerticalSpace}>T = Tingling (pins & needles)</div>
												</Col>
											</Row>

											<Row>
												<Col style={Styles.RowType2}>
													<span style={Styles.VerticalSpace}>B = Burning</span>
													<span style={Styles.VerticalSpace}>N = Numb</span>
													<span style={Styles.VerticalSpace}>C = Cramping</span>
												</Col>
											</Row>

											<Row>
												<Col style={Styles.RowType2}>
													{["D", "S", "T", "B", "N", "C"].map((v, k) => {
														return (
															<Button
																style={Styles.ButtonSize}
																className={painType == v ? "rating-button-active" : "rating-button-inactive"}
																onClick={() => {
																	setPainType(v);
																}}
															>
																{v}
															</Button>
														);
													})}
													<Button
														variant="danger"
														onClick={() => {
															setPainType("D");
															setData({ ...data, PainPoints: [] });
														}}
													>
														Reset
													</Button>
												</Col>
											</Row>

											<Row>
												<Col style={{ display: "flex", alignItems: "center" }}>
													<canvas
														onClick={(e) => {
															var bound = e.currentTarget.getBoundingClientRect();
															var width = bound.width;
															var height = bound.height;
															var offsetLeft = bound.x;
															var offsetTop = bound.y;

															// console.log(width, height, e.currentTarget.getBoundingClientRect());

															var x = (e.clientX - offsetLeft) / width;
															var y = (e.clientY - offsetTop) / height;
															var currentData = { ...data };
															currentData.PainPoints.push({
																PainType: painType,
																DistanceWidth: x,
																DistanceHeight: y,
															});
															setData({ ...currentData });
														}}
														style={{ backgroundColor: "var(--primary-gray)" }}
														ref={canvasRef}
													/>
												</Col>
											</Row>

											<Row>
												<Col>
													{" "}
													<div style={Styles.RowType1}> Are there any issues or concerns you can think of that you would like help or guidance with? </div>
												</Col>
											</Row>

											<Row>
												<Form.Group as={Col}>
													<div>
														<Form.Control
															as={"textarea"}
															value={data.Concerns.replace(/\\n/g, "\n")}
															rows={5}
															width="100%"
															onChange={(e) => {
																setData({ ...data, Concerns: e.target.value });
															}}
															id={`id-concerns`}
														/>
													</div>
												</Form.Group>
											</Row>

											<RenderSectionHeader title="Exercise" />
											<Row style={Styles.VerticalGap}>
												{[
													{ title: "Stretching", name: "Stretching" },
													{ title: "Aerobic Training", name: "AerobicTraining" },
													{ title: "Core Stability Training", name: "CoreStabilityTraining" },
													{ title: "Resistance Training", name: "ResistanceTraining" },
													{ title: "Yoga", name: "Yoga" },
													{ title: "Pilates", name: "Pilates" },
												].map((v, _) => {
													return <Fragment>{RenderExerciseRow(v.title, v.name)}</Fragment>;
												})}
											</Row>

											<RenderSectionHeader title="Practitioners" />
											<RenderSectionInstruction label="Please indicate which of the following practitioners you are currently under care" />

											<Row style={Styles.VerticalGap}>
												{[
													{ title: "Physician", name: "Physician" },
													{ title: "Chiropractor", name: "Chiropractor" },
													{ title: "Dentist", name: "Dentist" },
													{ title: "Massage Therapist", name: "MassageTherapist" },
													{ title: "Physiotherapist", name: "Physiotherapist" },
													{ title: "Fitness Trainer", name: "FitnessTrainer" },
													{ title: "Naturopath", name: "Naturopath" },
													{ title: "Acupuncturist", name: "Acupuncturist" },
													{ title: "Optometrist", name: "Optometrist" },
													{ title: "Psychologist", name: "Psychologist" },
													{ title: "Other", name: "Other" },
												].map((v, _) => {
													return <Fragment>{RenderPractitionerColumn(v.title, v.name)}</Fragment>;
												})}
											</Row>

											<RenderSectionHeader title="Consumptions" />
											<Row style={Styles.VerticalGap}>
												{[
													{ title: "Water consumption", name: "Water", text: "glasses daily" },
													{ title: "Caffeinated beverages", name: "CaffeinatedBeverage", text: "glasses daily" },
													{ title: "Alcoholic beverages", name: "AlcoholicBeverage", text: "glasses daily" },
													{ title: "Cigarettes / Cigars", name: "Cigarettes", text: "daily" },
													{ title: "Sleep", name: "Sleep", text: "hours per night on average" },
													{ title: "Sitting", name: "Sitting", text: "average hours per day" },
												].map((v, _) => {
													return <Fragment>{RenderConsumptionRow(v.title, v.name, v.text)}</Fragment>;
												})}
											</Row>

											<Row style={Styles.VerticalGap}>
												{[
													{ question: "How would you rate your stress level?", options: ["None", "Low", "Moderate", "High"], name: "StressLevel" },
													{ question: "Do you feel rested upon waking?", options: ["Always", "Mostly", "Frequently", "Rarely", "Never"], name: "RestedUponWaking" },
													{ question: "Do you feel fatigued throughout the day?", options: ["Always", "Mostly", "Frequently", "Rarely", "Never"], name: "ContinuouslyFatigued" },
													{ question: "Are you currently wearning orthotics or any insoles in either of your shoes?", options: ["Yes", "No"], name: "CurrenlyWearsOrthoticsOrInsoles" },
													{ question: "Are you currently using a dental appliance?", options: ["Yes", "No"], name: "CurrenlyUsesDentalAppliance" },
												].map((v, _) => {
													return <Fragment>{RenderQuestionOptionRow(v.question, v.options, v.name)}</Fragment>;
												})}
											</Row>

											{RenderTextRow("Please list all vitamins, minerals, and supplements that you are currently taking ( ***please press Add after each item):", "Vitamins")}
											{RenderTextRow("Please list all medications you are currently taking ( ***please press Add after each item):", "Medications")}

											<Row>
												<Col>
													<Button
														variant="primary"
														onClick={() => {
															SubmitData();
														}}
													>
														Submit
													</Button>
												</Col>
											</Row>
										</section>
									</Fragment>
								) : (
									<Fragment>
										<div style={{ width: "100%", height: "100%", padding: "20px", paddingBottom: "100px" }}>
											health status submitted at <span style={{ fontWeight: "bold" }}>{new Date(data.CompletedAt).toLocaleString(DateLocale, DefaultDateFormat)}</span>
										</div>
									</Fragment>
								)}
							</div>
						</Fragment>
					)}
				</Fragment>
			)}
		</Fragment>
	);
};

const DefaultValidated = {
	PerceivedState: false,
	StressLevel: false,
	RestedUponWaking: false,
	ContinuouslyFatigued: false,
	CurrenlyWearsOrthoticsOrInsoles: false,
	CurrenlyUsesDentalAppliance: false,
};

const DefaultData = {
	Id: "",
	PatientId: "",
	BusinessId: "",
	PerceivedState: -1,
	PainPoints: [],
	Concerns: "",
	Exercise: {
		Stretching: { FrequencyType: "", FrequencyCycle: 0, Duration: 0 },
		AerobicTraining: { FrequencyType: "", FrequencyCycle: 0, Duration: 0 },
		CoreStabilityTraining: { FrequencyType: "", FrequencyCycle: 0, Duration: 0 },
		ResistanceTraining: { FrequencyType: "", FrequencyCycle: 0, Duration: 0 },
		Yoga: { FrequencyType: "", FrequencyCycle: 0, Duration: 0 },
		Pilates: { FrequencyType: "", FrequencyCycle: 0, Duration: 0 },
		Other: { FrequencyType: "", FrequencyCycle: 0, Duration: 0 },
	},
	PractitionersUnderCare: {
		Physician: false,
		Dentist: false,
		Physiotherapist: false,
		Naturopath: false,
		Optometrist: false,
		Chiropractor: false,
		MassageTherapist: false,
		FitnessTrainer: false,
		Acupuncturist: false,
		Psychologist: false,
		Other: false,
	},
	IntakeStatistics: { Water: 0, CaffeinatedBeverage: 0, AlcoholicBeverage: 0, Cigarettes: 0, Sleep: 0, Sitting: 0 },
	StressLevel: -1,
	RestedUponWaking: -1,
	ContinuouslyFatigued: -1,
	CurrenlyWearsOrthoticsOrInsoles: false,
	CurrenlyUsesDentalAppliance: false,
	Vitamins: [],
	Medications: [],
	Status: "INITIATED",
	CreatedAt: "2022-09-02T23:03:05.659054Z",
	LastModifiedAt: "2022-09-02T23:03:05.6590556Z",
	LastEmailSentAt: "2022-09-02T23:03:05.6590557Z",
	CompletedAt: "2022-09-02T23:03:05.6590558Z",
};

const Styles = {
	Info: {
		padding: "5px",
		textAlign: "center",
	},
	Header: {
		marginBottom: "10px",
	},
	RowType1: {
		marginBottom: "20px",
		textAlign: "justify",
	},
	RowType2: {
		marginBottom: "20px",
		display: "flex",
		flexDirection: "row",
		justifyContent: "center",
	},
	RowType3: {
		marginBottom: "20px",
		textAlign: "left",
	},
	RowType4: {
		display: "flex",
		flexDirection: "row",
		justifyContent: "flex-start",
		alignItems: "center",
	},
	Select: { marginRight: "5px", maxWidth: "50px" },
	Select2: { marginRight: "5px", maxWidth: "150px" },
	Input: { margin: "0px", marginRight: "5px", maxWidth: "100px" },
	Input2: { margin: "0px", marginRight: "5px", width: "100%" },
	Label: { margin: "0px", marginRight: "5px" },
	Check: { marginRight: "10px" },
	CheckBox: {
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
	},
	VerticalSpace: { marginLeft: "5px", marginRight: "5px" },
	ButtonPrimary: { cursor: "pointer", color: "var(--primary-blue)" },
	ButtonSize: { width: "50px", height: "50px" },
	VerticalGap: { marginBottom: "20px" },
	VerticalGap2: { marginBottom: "10px" },
};

const FrequencyTypeLabels = {
	"": "Select",
	D: "Every Day",
	W: "Every Week",
	B: "Bi-Weekly",
	M: "Every Month",
	"2M": "Every 2 Months",
	"3M": "Every 3 Months",
	Q: "Every Quarter",
	Y: "Every Year",
};

export default HealthStatusPublic;
