import React, { useState, Fragment, useRef, useEffect } from "react";
import { Col, Row, Button, Popover, OverlayTrigger, Spinner, Form, Tooltip } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faReply, faTrash, faDownload } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import { jsPDF } from "jspdf";
import { dbUrl } from "../../../lib/config";
import { RetrieveToken } from "../../../lib/token";
import { GenerateHealthStatusUpdateData } from "./dataGenerator";
import {
	DefaultDateFormat,
	DateLocale,
	CalculateAge,
	DisplayHealthStatusUpdateV1EmailClient,
	LoadHealthStatusUpdateByPatientAndBusinessIdV1,
	SubmitHealthStatusUpdateV1,
	RemoveHealthStatusUpdateByIdV1,
} from "../../../lib/scripts";
import bodiometerLogoImg from "../../../resources/logos/bodiometer-logo.png";
import HumanBodyImg from "./assets/human_body_2.png";
import CheckboxSelectedImg from "./assets/checkbox_selected.png";
import CheckboxDSelectedImg from "./assets/checkbox_dselected.png";

import "bootstrap/dist/css/bootstrap.min.css";
import "./style/style.scss";

const HealthStatus = ({ CaseId, PatientId, BusinessId, ActiveOrganization, onBack }) => {
	const [isLoading, setIsLoading] = useState(false);
	const [data, setData] = useState([]);
	const [completedData, setCompletedData] = useState([]);
	const [patientInfo, setPatientInfo] = useState({
		Salutation: "",
		FirstName: "",
		LastName: "",
		Email: "",
		DateOfBirth: null,
		Gender: "",
	});
	const [businessInfo, setBusinessInfo] = useState({
		Email: "",
		BusinessName: "",
	});

	const [currentIndex, setCurrentIndex] = useState(-1);
	const [openEmailClient, setOpenEmailClient] = useState(false);
	const [newId, setNewId] = useState("");
	const canvasRef = useRef(null);

	// type: character string W (Per Week) / B (Bi-Weekly) / M (Per Month) / 2M (Per 2 Months) / 3M (Per 3 Months) / Q (Per Quarter) / Y (Per Year)

	const FrequencyTypeLabels = {
		D: "Every Day",
		W: "Every Week",
		B: "Bi-Weekly",
		M: "Every Month",
		"2M": "Every 2 Months",
		"3M": "Every 3 Months",
		Q: "Every Quarter",
		Y: "Every Year",
	};

	const RenderHSDateSelectionOverlay = () => {
		return (
			<Popover className="posture-date-overlay-wrapper" style={{ backgroundColor: "var(--primary-gray)" }} id={`popover${Math.floor(Math.random()).toString()}`}>
				<Popover.Content>
					{/* <div> */}
					<ul>
						{completedData.map((hs, k) => {
							return (
								<li key={k}>
									<Button className={k === currentIndex ? "active-date" : "white-link"} onClick={() => setCurrentIndex(k)}>
										{new Date(hs.CreatedAt).toLocaleDateString(DateLocale, DefaultDateFormat)}
										{k === currentIndex ? <img src="../../photos/icons/dashboard/SVG/check-green.svg" width="15" /> : null}
									</Button>
								</li>
							);
						})}
					</ul>
					{/* </div> */}
				</Popover.Content>
			</Popover>
		);
	};

	const LoadInitialData = async () => {
		setIsLoading(true);
		// const responseOne = await GenerateHealthStatusUpdateData();
		const token = await RetrieveToken();
		const responseOne = await LoadHealthStatusUpdateByPatientAndBusinessIdV1("Bearer " + token, PatientId, BusinessId);
		if (responseOne.data.success) {
			var { hsuList: tempHsuList, patientInfo: tempPatientInfo, businessInfo: tempBusinessInfo } = responseOne.data.data;
			var tempCompletedData = tempHsuList.filter((value) => (value.Status == "COMPLETED" ? true : false));
			setData(tempHsuList);
			setCompletedData(tempHsuList.filter((value) => (value.Status == "COMPLETED" ? true : false)));
			setPatientInfo(tempPatientInfo);
			setBusinessInfo(tempBusinessInfo);
			if (tempCompletedData.length > 0) {
				setCurrentIndex(0);
			} else {
				setCurrentIndex(-1);
			}
		}
		setIsLoading(false);
	};

	useEffect(() => {
		LoadInitialData();
	}, []);

	const DrawCanvas = (image, data, k) => {
		var painDiagram = canvasRef.current;
		if (!!painDiagram) {
			painDiagram.style.cursor = "pointer";
			var ctx = painDiagram.getContext("2d");
			var imageWidth = image.naturalWidth == 0 ? 600 : image.naturalWidth;
			var imageHeight = image.naturalHeight == 0 ? 350 : image.naturalHeight;
			var aspectRatio = imageWidth / imageHeight;
			var maxWidth = !!painDiagram.parentNode && "offsetWidth" in painDiagram.parentNode ? painDiagram.parentNode.offsetWidth : 600;
			var maxHeight = maxWidth / aspectRatio;
			var fontSizeLarge = maxWidth > 600 ? 16 : 12;
			image.width = maxWidth;
			image.height = maxHeight;
			ctx.canvas.width = maxWidth;
			ctx.canvas.height = maxHeight;
			ctx.fillStyle = "black";
			ctx.font = `bold ${fontSizeLarge}px Rubik`;
			ctx.textAlign = "center";
			ctx.drawImage(image, 0, 0, maxWidth, maxHeight);

			if (k >= 0) {
				for (let i = 0; i < data[k].PainPoints.length; i++) {
					ctx.fillText(data[k].PainPoints[i].PainType, data[k].PainPoints[i].DistanceWidth * ctx.canvas.width, data[k].PainPoints[i].DistanceHeight * ctx.canvas.height);
				}
			}

			return painDiagram.toDataURL("image/png");
		}
	};

	useEffect(() => {
		var image = new Image();
		image.src = HumanBodyImg;
		image.onload = () => {
			DrawCanvas(image, completedData, currentIndex);
		};
	}, [completedData, currentIndex, isLoading]);

	const RenderSectionHeader = ({ title }) => {
		return (
			<Row>
				<Col style={Styles.RowType3}>
					<span style={{ color: "var(--logo-color-1)", fontSize: "120%", fontWeight: "bold" }}>{title}</span>
				</Col>
			</Row>
		);
	};

	const RenderSectionInstruction = ({ label }) => {
		return (
			<Row>
				<Col>
					<div style={Styles.RowType1}>{label}</div>
				</Col>
			</Row>
		);
	};

	const RenderExerciseRow = ({ title, name }) => {
		return (
			<Row style={Styles.VerticalGap}>
				<Col>
					<Form.Label style={Styles.Label}>
						{title}:{" "}
						{data[currentIndex].Exercise[name].FrequencyType in FrequencyTypeLabels && data[currentIndex].Exercise[name].FrequencyCycle > 0 ? (
							<Fragment>
								<span style={{ fontWeight: "bold" }}>{data[currentIndex].Exercise[name].FrequencyCycle}</span> times{" "}
								<span style={{ fontWeight: "bold" }}>
									{data[currentIndex].Exercise[name].FrequencyType in FrequencyTypeLabels ? FrequencyTypeLabels[data[currentIndex].Exercise[name].FrequencyType] : ""}
								</span>{" "}
								for <span style={{ fontWeight: "bold" }}>{data[currentIndex].Exercise[name].Duration}</span> Minutes
							</Fragment>
						) : (
							<Fragment>
								<span style={{ fontWeight: "bold" }}>NA</span>
							</Fragment>
						)}
					</Form.Label>
				</Col>
			</Row>
		);
	};

	const RenderPractitionerColumn = ({ title, name }) => {
		return (
			<Col md={4} style={Styles.CheckBox}>
				<Form.Check
					custom
					type={"checkbox"}
					id={`screening-checkbox-${name}`}
					label=""
					disabled={true}
					checked={data[currentIndex].PractitionersUnderCare[name]}
					defaultChecked={data[currentIndex].PractitionersUnderCare[name]}
					onChange={() => {}}
					style={Styles.Check}
				/>
				<Form.Label style={Styles.Label}>{title}</Form.Label>
			</Col>
		);
	};

	const RenderConsumptionRow = ({ title, name, text }) => {
		return (
			<Row style={Styles.VerticalGap}>
				<Col>
					<Form.Label style={Styles.Label}>
						{title} <span style={{ fontWeight: "bold" }}>{data[currentIndex].IntakeStatistics[name]}</span> {text}
					</Form.Label>
				</Col>
			</Row>
		);
	};

	const RenderQuestionOptionRow = ({ question, options, name }) => {
		return (
			<Row>
				<Col>
					<div style={Styles.RowType1}>
						{question}{" "}
						{name == "CurrenlyUsesDentalAppliance" || name == "CurrenlyWearsOrthoticsOrInsoles" ? (
							<Fragment>
								<span style={{ fontWeight: "bold" }}>{!!data[currentIndex][name] ? options[0] : options[1]}</span>
							</Fragment>
						) : (
							<Fragment>
								{options.map((v, k) => {
									if (data[currentIndex][name] == k) {
										return <span style={{ fontWeight: "bold" }}>{v}</span>;
									}
								})}
							</Fragment>
						)}
					</div>
				</Col>
			</Row>
		);
	};

	const SendEmailClient = async () => {
		try {
			setIsLoading(true);
			var token = await RetrieveToken();
			var responseOne = await SubmitHealthStatusUpdateV1("Bearer " + token, PatientId, BusinessId);
			if (responseOne.data.success) {
				if ("Id" in responseOne.data.data) {
					await LoadInitialData();
					setOpenEmailClient(true);
					setNewId(responseOne.data.data.Id);
					setTimeout(() => {
						setOpenEmailClient(false);
					}, 200);
				}
			}
			setIsLoading(false);
		} catch {
			setIsLoading(false);
		}
	};

	const RemoveHSU = async (Id) => {
		try {
			setIsLoading(true);
			var token = await RetrieveToken();
			var responseOne = await RemoveHealthStatusUpdateByIdV1("Bearer " + token, Id);
			if (responseOne.data.success) {
				await LoadInitialData();
				setOpenEmailClient(false);
			}
			setIsLoading(false);
		} catch {
			setIsLoading(false);
		}
	};

	const GeneratePdf = async (Ind) => {
		var pdf = new jsPDF(DefaultPdfReportConfig);
		try {
			var token = await RetrieveToken();
			var responseOne = await axios({
				method: "GET",
				url: dbUrl + "/api/storage/v1/image/ba/logo/id/base64",
				headers: {
					Authorization: "Bearer " + token,
					"Content-Type": "application/json",
					businessId: BusinessId,
				},
			});
			if (responseOne.status == 200) {
				var imageDb = {};
				var ActiveOrganizationLogo = responseOne.data;

				if (!!bodiometerLogoImg) {
					imageDb["bodiometer.logo"] = new Image();
					imageDb["bodiometer.logo"].src = bodiometerLogoImg;
				} else {
					imageDb["bodiometer.logo"] = null;
				}

				if (!!ActiveOrganizationLogo) {
					imageDb["clinic.logo"] = new Image();
					imageDb["clinic.logo"].src = ActiveOrganizationLogo;
				} else {
					imageDb["clinic.logo"] = null;
				}

				if (!!HumanBodyImg) {
					imageDb["human.body"] = new Image();
					imageDb["human.body"].src = HumanBodyImg;
				} else {
					imageDb["human.body"] = null;
				}

				if (!!CheckboxSelectedImg && !!CheckboxDSelectedImg) {
					imageDb["checkbox.selected"] = new Image();
					imageDb["checkbox.dselected"] = new Image();
					imageDb["checkbox.selected"].src = CheckboxSelectedImg;
					imageDb["checkbox.dselected"].src = CheckboxDSelectedImg;
				} else {
					imageDb["checkbox.selected"] = null;
					imageDb["checkbox.dselected"] = null;
				}

				var imageKeys = Object.keys(imageDb);
				var imageCount = 0;

				for (var i = 0; i < imageKeys.length; i++) {
					if (imageDb[imageKeys[i]] != null) {
						imageCount++;
					}
				}

				var nImgLoaded = 0;
				for (var i = 0; i < imageKeys.length; i++) {
					if (imageDb[imageKeys[i]] != null) {
						imageDb[imageKeys[i]].onload = () => {
							nImgLoaded++;
							if (nImgLoaded == imageCount) {
								// Page 1 : Basic Layout
								GenerateBasicLayout(pdf, imageDb["bodiometer.logo"], imageDb["clinic.logo"], 1);

								var verticalPointer = OverflowSpace;
								verticalPointer = GenerateFirstPagePdf(pdf, verticalPointer, imageDb, Ind);
								pdf.save(`hs.pdf`);
							}
						};
					}
				}
			}
		} catch {}
	};

	const GenerateBasicLayout = (pdf, bodiometerLogo, clinicLogo, pageNumber) => {
		var width = pdf.internal.pageSize.getWidth();
		var height = pdf.internal.pageSize.getHeight();
		var offsetLeft = 20;

		if (bodiometerLogo != null) {
			pdf.addImage(bodiometerLogo, width - 100, 20, (bodiometerLogo.width / bodiometerLogo.height) * 20, 20);
		}
		if (clinicLogo != null) {
			pdf.addImage(clinicLogo, 10, 20, (clinicLogo.width / clinicLogo.height) * 20, 20);
		}

		pdf.setFontSize(8);
		pdf.setFont(undefined, "normal");
		pdf.setDrawColor(0, 0, 0);
		pdf.line(20, 45, width - 20, 45);
		pdf.line(20, height - 45, width - 20, height - 45);
		pdf.text(`Address: ${ActiveOrganization?.address}, ${ActiveOrganization?.city}, ${ActiveOrganization?.country}`, 20, height - 35, {
			align: "left",
		});
		pdf.text(`Email: ${ActiveOrganization?.email}, Phone: ${ActiveOrganization?.phone}, Fax: ${ActiveOrganization?.fax}`, 20, height - 25, {
			align: "left",
		});
		pdf.text(`Page ${pageNumber}`, width - offsetLeft - pdf.getTextWidth(`Page ${pageNumber}`), height - 30, { align: "left" });

		pdf.setFontSize(10);
		pdf.setFont(undefined, "normal");
	};

	const GenerateFirstPagePdf = (pdf, verticalPointer, imageDb, Ind) => {
		var height = pdf.internal.pageSize.getHeight();
		var width = pdf.internal.pageSize.getWidth();
		var verticalStart = verticalPointer;
		var verticalGap = 10;
		var offsetLeft = 20;

		pdf.setFontSize(12);
		pdf.setFont(undefined, "normal");
		pdf.text(`Assessment: ${Ind + 1}`, offsetLeft, verticalStart % height);
		verticalStart = AddVerticalSpace(pdf, verticalGap, height, verticalStart, imageDb);
		pdf.text(`Date: ${new Date().toLocaleString(DateLocale, DefaultDateFormat)}`, offsetLeft, verticalStart % height);
		verticalStart = AddVerticalSpace(pdf, 2 * verticalGap, height, verticalStart, imageDb);

		pdf.setFontSize(16);
		pdf.setFont(undefined, "bold");
		pdf.text("Health Status", offsetLeft, verticalStart % height);
		verticalStart = AddVerticalSpace(pdf, 1.5 * verticalGap, height, verticalStart, imageDb);

		pdf.setFontSize(12);
		pdf.setFont(undefined, "bold");
		pdf.text("Body Pain", offsetLeft, verticalStart % height);
		verticalStart = AddVerticalSpace(pdf, verticalGap, height, verticalStart, imageDb);

		pdf.setFontSize(10);
		pdf.setFont(undefined, "normal");
		pdf.text(
			"Please indicate on the scale (0 - terrible; 10 - optimal) below where you perceive your state current well-being, considering physical, mental, social, and spititual aspects of your life.",
			offsetLeft,
			verticalStart % height,
			{ maxWidth: width - offsetLeft }
		);
		verticalStart = AddVerticalSpace(pdf, 2 * verticalGap, height, verticalStart, imageDb);

		var rad = 10;
		var leftStart = 120;
		pdf.setFont(undefined, "bold");
		Array(11)
			.fill(0)
			.map((_, k) => {
				pdf.setTextColor(255, 255, 255);
				if (data[Ind].PerceivedState == k) {
					pdf.setFillColor(48, 148, 209);
					pdf.circle(leftStart + k * (2 * rad + 5), verticalStart + rad, rad, "F");
					pdf.text(`${k}`, leftStart + k * (2 * rad + 5) - 2, verticalStart + rad + 2);
				} else {
					pdf.setFillColor(166, 227, 255);
					pdf.circle(leftStart + k * (2 * rad + 5), verticalStart + rad, rad, "F");
					pdf.text(`${k}`, leftStart + k * (2 * rad + 5) - 2, verticalStart + rad + 2);
				}
			});

		verticalStart = AddVerticalSpace(pdf, 4 * verticalGap, height, verticalStart, imageDb);

		pdf.setTextColor(0, 0, 0);
		pdf.setFontSize(10);
		pdf.setFont(undefined, "normal");
		pdf.text("Please draw the location of your pain or discomfort on the images below. Use the symbols shown to represent the type(s) of pain.", offsetLeft, verticalStart % height, {
			maxWidth: width - offsetLeft,
		});
		verticalStart = AddVerticalSpace(pdf, 2 * verticalGap, height, verticalStart, imageDb);
		pdf.text("D = Dull S = Stabbing / Sharp T = Tingling (pins & needles)", offsetLeft, verticalStart % height, { maxWidth: width - offsetLeft });
		verticalStart = AddVerticalSpace(pdf, verticalGap, height, verticalStart, imageDb);
		pdf.text("B = Burning N = Numb C = Cramping", offsetLeft, verticalStart % height, { maxWidth: width - offsetLeft });
		verticalStart = AddVerticalSpace(pdf, verticalGap, height, verticalStart, imageDb);

		var image = imageDb["human.body"];
		var imageHeight = image.naturalHeight <= 0 ? 600 : image.naturalHeight;
		var imageWidth = image.naturalWidth <= 0 ? 800 : image.naturalWidth;

		var imageBase64 = DrawCanvas(image, data, Ind);
		pdf.addImage(imageBase64, 50, verticalStart % height, width - 100, (width - 100) * (imageHeight / imageWidth), null, "NONE");
		verticalStart = AddVerticalSpace(pdf, (width - 100) * (imageHeight / imageWidth), height, verticalStart, imageDb);

		pdf.text("Are there any issues or concerns you can think of that you would like help or guidance with?", offsetLeft, verticalStart % height, { maxWidth: width - offsetLeft });
		verticalStart = AddVerticalSpace(pdf, verticalGap, height, verticalStart, imageDb);

		pdf.text(`Answer: ${data[Ind].Concerns}`, offsetLeft, verticalStart % height, { maxWidth: width - offsetLeft });
		var dim = pdf.getTextDimensions(data[Ind].Concerns, { scaleFactor: 1.5 });
		verticalStart = AddVerticalSpace(pdf, data[Ind].Concerns.split("\n").length * verticalGap, height, verticalStart, imageDb);

		pdf.setFontSize(12);
		pdf.setFont(undefined, "bold");
		pdf.text("Exercise", offsetLeft, verticalStart % height);
		verticalStart = AddVerticalSpace(pdf, verticalGap, height, verticalStart, imageDb);

		pdf.setFontSize(10);
		pdf.setFont(undefined, "normal");

		[
			{ title: "Stretching", name: "Stretching" },
			{ title: "Aerobic Training", name: "AerobicTraining" },
			{ title: "Core Stability Training", name: "CoreStabilityTraining" },
			{ title: "Resistance Training", name: "ResistanceTraining" },
			{ title: "Yoga", name: "Yoga" },
			{ title: "Pilates", name: "Pilates" },
			{ title: "Other", name: "Other" },
		].map((v, _) => {
			if (data[Ind].Exercise[v.name].FrequencyCycle > 0) {
				pdf.text(
					`${v.title}: ${data[Ind].Exercise[v.name].FrequencyCycle} times ${FrequencyTypeLabels[data[Ind].Exercise[v.name].FrequencyType]} for ${data[Ind].Exercise[v.name].Duration} Minutes`,
					offsetLeft,
					verticalStart % height
				);
				verticalStart = AddVerticalSpace(pdf, verticalGap, height, verticalStart, imageDb);
			} else {
				pdf.text(`${v.title}: NA`, offsetLeft, verticalStart % height);
				verticalStart = AddVerticalSpace(pdf, verticalGap, height, verticalStart, imageDb);
			}
		});
		verticalStart = AddVerticalSpace(pdf, verticalGap, height, verticalStart, imageDb);

		pdf.setFontSize(12);
		pdf.setFont(undefined, "bold");
		pdf.text("Practitioners", offsetLeft, verticalStart % height);
		verticalStart = AddVerticalSpace(pdf, verticalGap, height, verticalStart, imageDb);

		pdf.setFontSize(10);
		pdf.setFont(undefined, "normal");
		pdf.text("Please indicate which of the following practitioners you are currently under care", offsetLeft, verticalStart % height, { maxWidth: width - offsetLeft });
		verticalStart = AddVerticalSpace(pdf, verticalGap, height, verticalStart, imageDb);

		var conditions = [
			{
				title: "Physician",
				name: "Physician",
			},
			{
				title: "Chiropractor",
				name: "Chiropractor",
			},
			{
				title: "Dentist",
				name: "Dentist",
			},
			{
				title: "Massage Therapist",
				name: "MassageTherapist",
			},
			{
				title: "Physiotherapist",
				name: "Physiotherapist",
			},
			{
				title: "Fitness Trainer",
				name: "FitnessTrainer",
			},
			{
				title: "Naturopath",
				name: "Naturopath",
			},
			{
				title: "Acupuncturist",
				name: "Acupuncturist",
			},
			{
				title: "Optometrist",
				name: "Optometrist",
			},
			{
				title: "Psychologist",
				name: "Psychologist",
			},
			{
				title: "Other",
				name: "Other",
			},
		];

		conditions.map((v, _) => {
			if (data[Ind].PractitionersUnderCare[v.name]) {
				pdf.addImage(CheckboxSelectedImg, offsetLeft, verticalStart % height, 8, 8, null, "NONE");
			} else {
				pdf.addImage(CheckboxDSelectedImg, offsetLeft, verticalStart % height, 8, 8, null, "NONE");
			}
			pdf.text(v.title, 2 * offsetLeft, (verticalStart % height) + 8, { maxWidth: width - offsetLeft });
			verticalStart = AddVerticalSpace(pdf, verticalGap, height, verticalStart, imageDb);
		});
		verticalStart = AddVerticalSpace(pdf, verticalGap, height, verticalStart, imageDb);

		pdf.setFontSize(12);
		pdf.setFont(undefined, "bold");
		pdf.text("Consumptions", offsetLeft, verticalStart % height);
		verticalStart = AddVerticalSpace(pdf, verticalGap, height, verticalStart, imageDb);

		var consumptions = [
			{
				title: "Water consumption",
				name: "Water",
				text: "glasses daily",
			},
			{
				title: "Caffeinated beverages",
				name: "CaffeinatedBeverage",
				text: "glasses daily",
			},
			{
				title: "Alcoholic beverages",
				name: "AlcoholicBeverage",
				text: "glasses daily",
			},
			{
				title: "Cigarettes / Cigars",
				name: "Cigarettes",
				text: "daily",
			},
			{
				title: "Sleep",
				name: "Sleep",
				text: "hours per night on average",
			},
			{
				title: "Sitting",
				name: "Sitting",
				text: "average hours per day",
			},
		];

		pdf.setFontSize(10);
		pdf.setFont(undefined, "normal");
		consumptions.map((v, _) => {
			if (data[Ind].IntakeStatistics[v.name] > 0) {
				pdf.text(`${v.title} ${data[Ind].IntakeStatistics[v.name]} ${v.text}`, offsetLeft, verticalStart % height);
				verticalStart = AddVerticalSpace(pdf, verticalGap, height, verticalStart, imageDb);
			} else {
				pdf.text(`${v.title} NA`, offsetLeft, verticalStart % height);
				verticalStart = AddVerticalSpace(pdf, verticalGap, height, verticalStart, imageDb);
			}
		});

		const GetTextFromOptions = (options, name) => {
			return options.filter((v, k) => {
				if (data[Ind][name] == k) {
					return v;
				}
			});
		};

		pdf.text(`How would you rate your stress level? ${GetTextFromOptions(["None", "Low", "Moderate", "High"], "StressLevel")}`, offsetLeft, verticalStart % height);
		verticalStart = AddVerticalSpace(pdf, verticalGap, height, verticalStart, imageDb);

		pdf.text(`Do you feel rested upon walking? ${GetTextFromOptions(["Always", "Mostly", "Frequently", "Rarely", "Never"], "RestedUponWaking")}`, offsetLeft, verticalStart % height);
		verticalStart = AddVerticalSpace(pdf, verticalGap, height, verticalStart, imageDb);

		pdf.text(`Do you feel fatigued throughout the day? ${GetTextFromOptions(["Always", "Mostly", "Frequently", "Rarely", "Never"], "ContinuouslyFatigued")}`, offsetLeft, verticalStart % height);
		verticalStart = AddVerticalSpace(pdf, verticalGap, height, verticalStart, imageDb);

		pdf.text(`Are you currently wearning orthotics or any insoles in either of your shoes? ${data[Ind].CurrenlyWearsOrthoticsOrInsoles ? "Yes" : "No"}`, offsetLeft, verticalStart % height);
		verticalStart = AddVerticalSpace(pdf, verticalGap, height, verticalStart, imageDb);

		pdf.text(`Are you currently using a dental appliance? ${data[Ind].CurrenlyUsesDentalAppliance ? "Yes" : "No"}`, offsetLeft, verticalStart % height);
		verticalStart = AddVerticalSpace(pdf, verticalGap, height, verticalStart, imageDb);

		pdf.text(`List of all vitamins, minerals, and supplements that you are currently taking: ${data[Ind].Vitamins.join(", ")}`, offsetLeft, verticalStart % height);
		verticalStart = AddVerticalSpace(pdf, verticalGap, height, verticalStart, imageDb);

		pdf.text(`List of all medications that you are currently taking: ${data[Ind].Medications.join(", ")}`, offsetLeft, verticalStart % height);
		verticalStart = AddVerticalSpace(pdf, verticalGap, height, verticalStart, imageDb);

		return verticalStart;
	};

	const AddVerticalSpace = (pdf, space, h, v, imageDb) => {
		if (h - (v % h) <= OverflowSpace) {
			pdf.addPage(DefaultPdfReportConfig);
			GenerateBasicLayout(pdf, imageDb["bodiometer.logo"], imageDb["clinic.logo"], Math.ceil(v / h) + 1);
			return v + OverflowSpace + (h - (v % h));
		} else if (Math.floor(v / h) < Math.floor((v + space) / h)) {
			pdf.addPage(DefaultPdfReportConfig);
			GenerateBasicLayout(pdf, imageDb["bodiometer.logo"], imageDb["clinic.logo"], Math.ceil(v / h) + 1);
		}
		return v + space;
	};

	return (
		<Fragment>
			{isLoading && (
				<div className="preloader-wrapper">
					<Spinner animation="grow" variant="secondary" />
				</div>
			)}

			<div style={{ marginBottom: "5vh" }}>
				<span style={{ cursor: "pointer", fontSize: "1.2rem", fontWeight: "500" }} onClick={onBack}>
					{"<"} back{" "}
				</span>
			</div>

			{!isLoading && (
				<Fragment>
					<section style={Styles.Info}>
						<h2 style={Styles.Header}>
							{patientInfo.FirstName} {patientInfo.LastName}
						</h2>
						<p>
							<a href={`mailto:${patientInfo.Email}`}>{patientInfo.Email}</a>, {patientInfo.Gender} {CalculateAge(patientInfo.DateOfBirth)} years
						</p>
					</section>

					{openEmailClient && !!newId && <DisplayHealthStatusUpdateV1EmailClient Id={newId} patientInfo={patientInfo} businessInfo={businessInfo} />}

					{data.length > 0 ? (
						<Fragment>
							<div className="table-responsive">
								<table key="patient-list-table" className="align-middle mb-0 table table-borderless">
									<thead>
										<tr className="text-center" style={{ height: "60px" }}>
											<th>Created</th>
											<th>Status</th>
											<th>Last Email</th>
											<th>Completed</th>
											<th>Actions</th>
										</tr>
										<tr className="hline-grey">
											<td colSpan={"100%"}></td>
										</tr>
									</thead>
									<tbody>
										{data.map((v, k) => {
											return (
												<Fragment>
													<tr style={{ textAlign: "center" }}>
														<td>{new Date(v.CreatedAt).toLocaleString(DateLocale, DefaultDateFormat)}</td>
														<td>{v.Status}</td>
														<td>{new Date(v.LastEmailSentAt).toLocaleString(DateLocale, DefaultDateFormat)}</td>
														<td>{v.Status == "COMPLETED" ? new Date(v.CompletedAt).toLocaleString(DateLocale, DefaultDateFormat) : "Pending"}</td>
														<td>
															<Button disabled={v.Status == "COMPLETED"} style={{ background: "transparent", padding: "0px", margin: "0px", marginRight: "5px" }}>
																<OverlayTrigger
																	key={`${Math.random()}-${k}-resend-email`}
																	trigger={["hover", "focus"]}
																	placement={"bottom"}
																	overlay={<Tooltip id={`${Math.random()}-${k}-tooltip-profile`}>Resend Email</Tooltip>}
																>
																	<FontAwesomeIcon
																		icon={faReply}
																		size="sm"
																		color={"var(--logo-color-1)"}
																		onClick={() => {
																			if (v.Status == "INITIATED") {
																				setNewId(v.Id);
																				setOpenEmailClient(true);
																			}
																		}}
																	/>
																</OverlayTrigger>
															</Button>

															<Button style={{ background: "transparent", padding: "0px", margin: "0px", marginRight: "5px" }}>
																<OverlayTrigger
																	key={`${Math.random()}-${k}-remove`}
																	trigger={["hover", "focus"]}
																	placement={"bottom"}
																	overlay={<Tooltip id={`${Math.random()}-${k}-tooltip-profile`}>Remove</Tooltip>}
																>
																	<FontAwesomeIcon
																		icon={faTrash}
																		size="sm"
																		color={"var(--logo-color-1)"}
																		onClick={() => {
																			RemoveHSU(v.Id);
																		}}
																	/>
																</OverlayTrigger>
															</Button>

															<Button disabled={v.Status == "INITIATED"} style={{ background: "transparent", padding: "0px", margin: "0px", marginRight: "5px" }}>
																<OverlayTrigger
																	key={`${Math.random()}-${k}-download`}
																	trigger={["hover", "focus"]}
																	placement={"bottom"}
																	overlay={<Tooltip id={`${Math.random()}-${k}-tooltip-profile`}>Download Pdf</Tooltip>}
																>
																	<FontAwesomeIcon
																		icon={faDownload}
																		size="sm"
																		color={"var(--logo-color-1)"}
																		onClick={() => {
																			if (v.Status == "COMPLETED") {
																				GeneratePdf(k);
																			}
																		}}
																	/>
																</OverlayTrigger>
															</Button>
														</td>
													</tr>
												</Fragment>
											);
										})}
									</tbody>
								</table>
								<Button
									variant="success"
									onClick={() => {
										SendEmailClient();
									}}
									style={{ margin: "5px" }}
								>
									Send New Email
								</Button>
							</div>
						</Fragment>
					) : (
						<Fragment>
							<Button
								variant="success"
								onClick={() => {
									SendEmailClient();
								}}
								style={{ margin: "5px" }}
							>
								Send New Email
							</Button>
						</Fragment>
					)}

					{currentIndex >= 0 && (
						<section style={{ paddingLeft: "0px" }} className="module-date-selection">
							<Row>
								<Col style={Styles.TextCenter}>
									<div>{new Date(data[currentIndex].CreatedAt).toLocaleDateString(DateLocale, DefaultDateFormat)}</div>
									<OverlayTrigger key={`hs-overlay-dates`} rootClose={true} trigger="click" placement="right" overlay={RenderHSDateSelectionOverlay()}>
										<div style={Styles.ButtonPrimary}>Change</div>
									</OverlayTrigger>
								</Col>
							</Row>
						</section>
					)}

					{currentIndex >= 0 && (
						<Fragment>
							<RenderSectionHeader title="Body Pain" />

							<Row>
								<Col>
									<div style={Styles.RowType1}>
										Please indicate on the scale <b>(0 - terrible; 10 - optimal)</b> below where you perceive your state current well-being, considering physical, mental, social, and spititual aspects
										of your life.
									</div>
								</Col>
							</Row>

							<Row>
								<Col style={Styles.RowType2}>
									{Array(11)
										.fill(0)
										.map((_, k) => {
											return (
												<div>
													<Button style={Styles.ButtonSize} disabled={true} className={data[currentIndex].PerceivedState == k ? "rating-button-active" : "rating-button-inactive"} onClick={() => {}}>
														{k}
													</Button>
												</div>
											);
										})}
								</Col>
							</Row>

							<Row>
								<Col>
									{" "}
									<div style={Styles.RowType1}> Please draw the location of your pain or discomfort on the images below. Use the symbols shown to represent the type(s) of pain. </div>
								</Col>
							</Row>

							<Row>
								<Col style={Styles.RowType2}>
									<div style={Styles.VerticalSpace}>D = Dull</div>
									<div style={Styles.VerticalSpace}>S = Stabbing / Sharp</div>
									<div style={Styles.VerticalSpace}>T = Tingling (pins & needles)</div>
								</Col>
							</Row>

							<Row>
								<Col style={Styles.RowType2}>
									<span style={Styles.VerticalSpace}>B = Burning</span>
									<span style={Styles.VerticalSpace}>N = Numb</span>
									<span style={Styles.VerticalSpace}>C = Cramping</span>
								</Col>
							</Row>

							<Row>
								<Col style={{ display: "flex", alignItems: "center" }}>
									<canvas style={{ backgroundColor: "var(--primary-gray)" }} ref={canvasRef} />
								</Col>
							</Row>

							<Row>
								<Col>
									{" "}
									<div style={Styles.RowType1}> Are there any issues or concerns you can think of that you would like help or guidance with? </div>
								</Col>
							</Row>

							<Row>
								<Form.Group as={Col}>
									<div>
										<Form.Control
											as={"textarea"}
											disabled
											value={data[currentIndex].Concerns.replace(/\\n/g, "\n")}
											rows={5}
											width="100%"
											// onChange={(e) => {
											// 	setState({ ...state, generalNotes: e.target.value });
											// }}
											id={`id-concerns`}
										/>
									</div>
								</Form.Group>
							</Row>

							<RenderSectionHeader title="Exercise" />
							<RenderExerciseRow title="Stretching" name="Stretching" />
							<RenderExerciseRow title="Aerobic Training" name="AerobicTraining" />
							<RenderExerciseRow title="Core Stability Training" name="CoreStabilityTraining" />
							<RenderExerciseRow title="Resistance Training" name="ResistanceTraining" />
							<RenderExerciseRow title="Yoga" name="Yoga" />
							<RenderExerciseRow title="Pilates" name="Pilates" />
							<RenderExerciseRow title="Other" name="Other" />

							<RenderSectionHeader title="Practitioners" />

							<RenderSectionInstruction label="Please indicate which of the following practitioners you are currently under care" />

							<Row style={Styles.VerticalGap}>
								<RenderPractitionerColumn title="Physician" name="Physician" />
								<RenderPractitionerColumn title="Chiropractor" name="Chiropractor" />
								<RenderPractitionerColumn title="Dentist" name="Dentist" />
								<RenderPractitionerColumn title="Massage Therapist" name="MassageTherapist" />
								<RenderPractitionerColumn title="Physiotherapist" name="Physiotherapist" />
								<RenderPractitionerColumn title="Fitness Trainer" name="FitnessTrainer" />
								<RenderPractitionerColumn title="Naturopath" name="Naturopath" />
								<RenderPractitionerColumn title="Acupuncturist" name="Acupuncturist" />
								<RenderPractitionerColumn title="Optometrist" name="Optometrist" />
								<RenderPractitionerColumn title="Psychologist" name="Psychologist" />
								<RenderPractitionerColumn title="Other" name="Other" />
							</Row>

							<RenderSectionHeader title="Consumptions" />
							<RenderConsumptionRow title="Water consumption" name="Water" text="glasses daily" />
							<RenderConsumptionRow title="Caffeinated beverages" name="CaffeinatedBeverage" text="glasses daily" />
							<RenderConsumptionRow title="Alcoholic beverages" name="AlcoholicBeverage" text="glasses daily" />
							<RenderConsumptionRow title="Cigarettes / Cigars" name="Cigarettes" text="daily" />
							<RenderConsumptionRow title="Sleep" name="Sleep" text="hours per night on average" />
							<RenderConsumptionRow title="Sitting" name="Sitting" text="average hours per day" />

							<RenderQuestionOptionRow question="How would you rate your stress level?" options={["None", "Low", "Moderate", "High"]} name="StressLevel" />
							<RenderQuestionOptionRow question="Do you feel rested upon walking?" options={["Always", "Mostly", "Frequently", "Rarely", "Never"]} name="RestedUponWaking" />
							<RenderQuestionOptionRow question="Do you feel fatigued throughout the day?" options={["Always", "Mostly", "Frequently", "Rarely", "Never"]} name="ContinuouslyFatigued" />
							<RenderQuestionOptionRow question="Are you currently wearning orthotics or any insoles in either of your shoes?" options={["Yes", "No"]} name="CurrenlyWearsOrthoticsOrInsoles" />
							<RenderQuestionOptionRow question="Are you currently using a dental appliance?" options={["Yes", "No"]} name="CurrenlyUsesDentalAppliance" />

							<Row>
								<Col>
									List of all vitamins, minerals, and supplements that you are currently taking: <span style={{ fontWeight: "bold" }}>{data[currentIndex].Vitamins.join(", ")}</span>
								</Col>
							</Row>

							<Row>
								<Col>
									List of all medications that you are currently taking: <span style={{ fontWeight: "bold" }}>{data[currentIndex].Medications.join(", ")}</span>
								</Col>
							</Row>
						</Fragment>
					)}
				</Fragment>
			)}
		</Fragment>
	);
};

const Styles = {
	Info: {
		padding: "5px",
		textAlign: "center",
	},
	Header: {
		marginBottom: "10px",
	},
	RowType1: {
		marginBottom: "20px",
		textAlign: "justify",
	},
	RowType2: {
		marginBottom: "20px",
		display: "flex",
		flexDirection: "row",
		justifyContent: "center",
	},
	RowType3: {
		marginBottom: "20px",
		textAlign: "left",
	},
	RowType4: {
		display: "flex",
		flexDirection: "row",
		justifyContent: "flex-start",
		alignItems: "center",
	},
	Select: { marginRight: "5px", maxWidth: "50px" },
	Select2: { marginRight: "5px", maxWidth: "150px" },
	Input: { margin: "0px", marginRight: "5px", maxWidth: "100px" },
	Label: { margin: "0px", marginRight: "5px" },
	Check: { marginRight: "10px" },
	CheckBox: {
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
	},
	VerticalSpace: { marginLeft: "5px", marginRight: "5px" },
	ButtonPrimary: { cursor: "pointer", color: "var(--primary-blue)" },
	ButtonSize: { width: "40px", height: "40px" },
	VerticalGap: { marginBottom: "20px" },
};

const OverflowSpace = 65;

const DefaultPdfReportConfig = {
	orientation: "p",
	unit: "px",
	format: "a4",
	putOnlyUsedFonts: true,
	floatPrecision: "smart", // or "smart", default is 16
};

export default HealthStatus;
