import React, { Fragment } from "react";
import { Button, Popover, OverlayTrigger, Col, Form, Row } from "react-bootstrap";
import UserSideNavLink from "./user-side-nav-link.js";
import UiButton from "./ui-button.js";
import axios from "axios";

import { useUserDashboardContext, useAppContext } from "../../lib/context.js";

import "../../resources/styles/dashboard.scss";
import "bootstrap/dist/css/bootstrap.min.css";

import { RetrieveToken } from "..//../lib/token";
import { dbUrl } from "../../lib/config";

const UserSideNav = () => {
	const [state, setState] = React.useState({
		isOrganizationSelectionModalVisible: false,
		isRemovalSelected: false,
		isAdditionSelected: false,
		isRemovalSuccessful: false,
		isAdditionSuccessful: false,
		isUserConnectionSuccessful: false,
		isUserConnectionAttempted: false,
		businessId: "",
	});

	const { dashboardState, setDashboardState } = useUserDashboardContext();
	const { appState, setAppState } = useAppContext();

	const HandleSideNavCollapse = (e) => {
		setDashboardState({
			...dashboardState,
			IsSideNavCollapsed: !dashboardState.IsSideNavCollapsed,
		});
	};

	const HandleMobileCollapse = (e) => {
		setDashboardState({
			...dashboardState,
			IsMobileNavActive: !dashboardState.IsMobileNavActive,
		});
	};

	const RemovePatientUserConnection = () => {
		try {
			RetrieveToken()
				.then(async (token) => {
					var configureTwo = {
						method: "DELETE",
						url: dbUrl + "/api/patientusermerges/v1/remove/id",
						headers: {
							Authorization: "Bearer " + token,
							id: appState.userData.PatientUserMerges[dashboardState.ActiveClinicId].Id,
						},
					};

					axios(configureTwo, { timeout: 5000 })
						.then((responseTwo) => {
							if (responseTwo.data.success) {
								var newAppstate = { ...appState };
								delete newAppstate.userData.PatientUserMerges[dashboardState.ActiveClinicId];
								dashboardState.ActiveClinicId = "";

								setState({ ...state, isRemovalSelected: false, isRemovalSuccessful: true });
								setDashboardState({ ...dashboardState });
								setAppState({ ...newAppstate });
							} else {
								setState({ ...state, isRemovalSelected: true, isRemovalSuccessful: false });
							}
						})
						.catch((_) => {
							setState({ ...state, isRemovalSelected: true, isRemovalSuccessful: false });
						});
				})
				.catch((_) => {
					setState({ ...state, isRemovalSelected: true, isRemovalSuccessful: false });
				});
		} catch {
			setState({ ...state, isRemovalSelected: true, isRemovalSuccessful: false });
		}
	};

	const AddPatientUserConnection = async (businessId, userId) => {
		try {
			if (businessId in appState.userData.PatientUserMerges) {
				return;
			}

			RetrieveToken()
				.then(async (token) => {
					var configureTwo = {
						method: "POST",
						url: dbUrl + "/api/patientusermerges/v1/create/businessid/userid",
						headers: {
							Authorization: "Bearer " + token,
							"Content-Type": "application/json",
							userId: userId,
							businessId: businessId,
						},
						data: JSON.stringify({ scanConsent: true, journalConsent: true }),
					};
					var responseTwo = await axios(configureTwo, { timeout: 5000 });
					if (responseTwo.data.success) {
						let newAppState = { ...appState };
						newAppState.userData.PatientUserMerges[responseTwo.data.data.BusinessId] = responseTwo.data.data;

						newAppState.userData.Clinic[responseTwo.data.businessInfo.BusinessId] = {
							ClinicName: responseTwo.data.businessInfo.BusinessName,
							PatientId: responseTwo.data.data.PatientId,
						};

						setState({
							...state,
							isRemovalSelected: false,
							isRemovalSuccessful: false,
							isAdditionSelected: true,
							isAdditionSuccessful: true,
						});
						setAppState({ ...newAppState });
					} else {
						setState({ ...state, isAdditionSelected: true, isAdditionSuccessful: false });
					}
				})
				.catch((_) => {
					setState({ ...state, isAdditionSelected: true, isAdditionSuccessful: false });
				});
		} catch {
			setState({ ...state, isAdditionSelected: true, isAdditionSuccessful: false });
		}
	};

	const OrganizationSelectionOverlay = () => {
		return (
			<Popover id={`popover${Math.floor(Math.random()).toString()}`}>
				<Popover.Content>
					<div className="organizations-wrapper">
						<h5>Organizations</h5>
						{Object.keys(appState.userData.PatientUserMerges).length <= 0 && <ul>no business connected yet</ul>}
						<ul>
							{Object.keys(appState.userData.PatientUserMerges).length > 0 &&
								Object.keys(appState.userData.PatientUserMerges).map((id) => {
									return (
										<li key={`ui-toggle-li`}>
											<UiButton
												onClick={(e) => {
													if (!!dashboardState.ActiveClinicId) {
														localStorage.setItem("_ACTIVECLINIC", "");
														setDashboardState({
															...dashboardState,
															ActiveClinicId: "",
														});
													} else {
														localStorage.setItem("_ACTIVECLINIC", id);
														setDashboardState({
															...dashboardState,
															ActiveClinicId: id,
														});
													}
												}}
												className={dashboardState.ActiveClinicId === id ? "active-organization" : "white-link"}
												variant={dashboardState.ActiveClinicId === id ? "secondary" : "link"}
											>
												{appState.userData.Clinic[id].ClinicName}
												{dashboardState.ActiveClinicId === id ? <img src="../../photos/icons/dashboard/SVG/check-green.svg" width="15" /> : ""}
											</UiButton>
										</li>
									);
								})}

							{!!dashboardState.ActiveClinicId && (
								<li style={{ textAlign: "start", marginTop: "10px" }}>
									{!state.isRemovalSelected && (
										<Button
											variant="danger"
											size="xs"
											onClick={() => {
												setState({ ...state, isRemovalSelected: true });
											}}
										>
											Remove
										</Button>
									)}
									{state.isRemovalSelected && (
										<Fragment>
											<Button
												variant="danger"
												size="xs"
												style={{ marginRight: "5px" }}
												onClick={() => {
													// setState({ ...state, isRemoveFQConfirmationOpened: true });
													localStorage.setItem("_ACTIVECLINIC", "");
													RemovePatientUserConnection();
												}}
											>
												Confirm
											</Button>
											<Button
												variant="secondary"
												size="xs"
												onClick={() => {
													setState({ ...state, isRemovalSelected: false });
												}}
											>
												Cancel
											</Button>
										</Fragment>
									)}
								</li>
							)}
							{state.isRemovalSuccessful && (
								<Fragment>
									<ul>business connection removed successfully.</ul>
									<Button variant="secondary" onClick={() => setState({ ...state, isRemovalSuccessful: false, isRemovalSelected: false })}>
										Ok
									</Button>
								</Fragment>
							)}
						</ul>

						<h5>Add New</h5>
						<section className="form-container" style={{ padding: "0px" }}>
							{!state.isAdditionSelected && (
								<Row>
									<Form.Group as={Col} controlId="add-clinic">
										<Form.Control
											size="sm"
											type="input"
											style={{ width: "100%", background: "white" }}
											onChange={(e) => {
												setState({ ...state, businessId: e.target.value });
											}}
											placeholder="Clinic ID"
										/>
										<Button
											style={{ background: "#45b249", margin: "0px" }}
											size="sm"
											onClick={(e) => {
												AddPatientUserConnection(state.businessId, appState.userData.Id);
											}}
										>
											Add Clinic <span style={{ fontSize: "130%" }}>+</span>
										</Button>
									</Form.Group>
								</Row>
							)}

							{state.isAdditionSelected && (
								<Fragment>
									{state.isAdditionSuccessful && <ul>business connection established successfully.</ul>}
									{!state.isAdditionSuccessful && <ul>business connection failed.</ul>}
									<Button variant="secondary" onClick={() => setState({ ...state, isAdditionSelected: false, isAdditionSuccessful: false })}>
										Ok
									</Button>
								</Fragment>
							)}
						</section>
					</div>
				</Popover.Content>
			</Popover>
		);
	};

	return (
		<div className={"side-nav" + (dashboardState.IsSideNavCollapsed ? " side-collapsed" : "") + (dashboardState.IsMobileNavActive ? " mobile-active" : "")}>
			<img
				style={{ cursor: "pointer" }}
				onClick={() => {
					window.location.href = "/";
				}}
				src={`../../photos/${dashboardState.IsSideNavCollapsed ? "bodiometer_logo_mark_colour" : "bodiometer-logo"}.svg`}
				width={dashboardState.IsSideNavCollapsed ? "60" : "240"}
			/>
			<hr />
			<article>
				<OverlayTrigger rootClose={true} placement={"right"} overlay={OrganizationSelectionOverlay()} trigger="click">
					<Button variant="primary" className="organization-toggle">
						{!!!dashboardState.ActiveClinicId && !dashboardState.IsSideNavCollapsed && <span>Connect</span>}
						{!!dashboardState.ActiveClinicId && !dashboardState.IsSideNavCollapsed && <span>{appState.userData.Clinic[dashboardState.ActiveClinicId].ClinicName}</span>}
						<span
							style={{
								display: "inline-block",
								transform: "scale(1.1)",
								width: dashboardState.IsSideNavCollapsed ? "80%" : "fit-content",
							}}
						>
							&nbsp; &gt;
						</span>
					</Button>
				</OverlayTrigger>
				<UserSideNavLink icon="dashboard" active={dashboardState.ActiveSideTab == "dashboard" ? true : false}>
					{dashboardState.IsSideNavCollapsed ? "" : "Dashboard"}
				</UserSideNavLink>

				<UserSideNavLink icon="profile" active={dashboardState.ActiveSideTab == "profile" ? true : false}>
					{dashboardState.IsSideNavCollapsed ? "" : "Profile"}
					{/* <div>
            <button>{"Security"}</button>
          </div> */}
				</UserSideNavLink>

				<UserSideNavLink icon="setting" active={dashboardState.ActiveSideTab == "setting" ? true : false}>
					{dashboardState.IsSideNavCollapsed ? "" : "Settings"}
				</UserSideNavLink>
			</article>
			<img src="../../photos/icons/dashboard/SVG/nav-toggle-15.svg" className={"mobile-nav-collapse-icon"} width="40" onClick={(e) => HandleMobileCollapse(e)} />
			<img
				src="../../photos/icons/dashboard/SVG/double-caret-stroke-gray.svg"
				className={"side-nav-collapse-icon" + (dashboardState.IsSideNavCollapsed ? " reverse" : "")}
				width="30"
				onClick={(e) => HandleSideNavCollapse(e)}
			/>
		</div>
	);
};

export default UserSideNav;
