import React, { useState, useEffect } from 'react';
import { Col, Row, Form, Alert, Container } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import md5Hash from "../../resources/helpers/md5.js";

import UiButton from "./ui-button.js";
import UiPassword from "./ui-password-2.js";

import logo from "../../resources/photos/bodiometer-pro-logo-stacked.svg";

import "bootstrap/dist/css/bootstrap.min.css";

export default function EmailPasswordReset(props) {
  const [state, setState] = useState({
    EditFormValidated: false,
    Error: [false, ''],
    Success: [false, ''],
    Different: false,
    PassType: 'password',
    PassValue0: '',
    PassValue: '',
    PassValue2: '',
    InValid0: [false, "Incorrect password"],
    InValid: [false,"Something went wrong..."],
    InValid2: [false,"Something went wrong..."]
  });

  const Initial = {
    EditFormValidated: false,
    Error: [false, ''],
    Success: [false, ''],
    Different: false,
    PassType: 'password',
    PassValue0: '',
    PassValue: '',
    PassValue2: '',
    InValid0: [false, "Incorrect password"],
    InValid: [false,"Something went wrong..."],
    InValid2: [false,"Something went wrong..."]
  }

  // hide success/error alert after 4 seconds
  useEffect(() => {
    const timer = setTimeout(() => {
      setState(prev => ({...prev, Success: [false, ""], Error: [false, ""]}))
    }, 4000);
    return () => clearTimeout(timer);
  }, [state.Success, state.Error]);


  const params = useParams()



  let environment = process.env.NODE_ENV;
  let dbUrl = null;
  if(environment == 'development'){
      // domain = 'http://localhost:54928/'; // original
      dbUrl = 'https://bodiometerhomerestapi-dot-project-4839952831808961167.appspot.com';
      
  }
  else{
      dbUrl = 'https://bodiometerhomerestapi-dot-project-4839952831808961167.appspot.com';
  }
  let UserId = params.id;
  let Token = 'Bearer ' + params.token;

  const HandleSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();
    
    const PostData = {
      userDetail: {
        password: md5Hash(state.PassValue),
      }
    }
    
    const PostHeaders = {
      headers: {
        'Authorization': Token,
        'Content-Type': 'application/json',
        'userId': UserId
      }
    };
    
    axios.put(
      dbUrl + '/api/businessusers/v1/update/userid', 
      PostData, 
      PostHeaders
    )
    .then((user) => {
              
      if (user.data.success) {
        setState({...state, Success: [true, "Password updated successfully"], Different: false})
      } else {
        setState({...state, Error: [true, "Something went wrong fetching the data, the link might be expired"]})
      }

    });

  };

  const PasswordCheck = (e, retype) => {

    const field = e.currentTarget;

    if (field.value.length < 6) {
      setState({...state, InValid: [true, "Password is too short"]});
    } else if (field.value.length >= 6) {
      setState({...state, InValid: [false, ""]});
    }
    if (retype) {
      if (field.value !== state.PassValue) {
        setState({...state, InValid2: [true, "Password doesn't match"]});
      } else {
        setState({...state, InValid2: [false, ""], Different: true});
      }
    }
  }

  const HandlePassChange = (e, retype) => {
    if (!retype) {
      setState({...state, PassValue: e.currentTarget.value});
    } else if (retype) {
      setState({...state, PassValue2: e.currentTarget.value});
    }
  }

  const HandleCurrentCheck = (e) => {
    // if password matches current user password
    if (e.currentTarget.value) {
      return true;
    } else {
      setState({...state, InValid0: [true, "Incorrect password"]});
    }
  }

  const HandleCurrentChange = (e) => {
    setState({...state, PassValue0: e.currentTarget.value});
  }

  useEffect(() => {
    setState({...state, Different: state.PassValue == state.PassValue2 && !!state.PassValue  && !!state.PassValue2});
  }, [state.PassValue, state.PassValue2]);
  
  return(

    <section className="landing-form-section-wrapper">
      <Container className="centered-container">
        <Row>
          <Col className="landing-form-wrapper" lg={5} md={12}>
            <img src={logo} width="250" alt="Bodiometer Logo"/>
            <Alert variant="warning" show={state.Error[0] ? true : false}>
              <div className="flex-row">
                <p>{state.Error[1]}</p>
              </div>
            </Alert>
            { !state.Success[0] &&
            <>
              <h2 className="blue-color">Reset Password</h2>
              <Form>

                <Row>
                  <UiPassword 
                    // label="New Password"
                    text="At least 6 characters, but longer is better."
                    placeholder="New Password"
                    controlId="new_password"
                    feedback="Password must be at least 6 characters in length"
                    value={state.PassValue}
                    onChange={HandlePassChange} 
                    onBlur={PasswordCheck}
                    isInvalid={state.InValid[0]}
                    message={state.InValid[1]}
                  />
                </Row>

                <Row>
                  <UiPassword 
                    // label="Retype New Password"
                    placeholder="Retype New Password"
                    controlId="new_password2"
                    retype
                    value={state.PassValue2}
                    onChange={HandlePassChange} 
                    onBlur={PasswordCheck}
                    isInvalid={state.InValid2[0]}
                    message={state.InValid2[1]}
                  />
                </Row>
                
                <Row>
                  <Form.Group controlId="consent" className="form-footer-wrapper">
                    <UiButton
                    type="submit"
                    variant="secondary"
                    disabled={state.Different ? false : true}
                    onClick={e => HandleSubmit(e)}
                    >
                      Save
                    </UiButton>
                  </Form.Group>
                </Row>
              </Form>
            </>
            }
            { state.Success[0] &&
            <div>
              <h2 className="green-color">Success</h2>
              <p>Your password has been reset successfully</p>
              <UiButton
              href="/portal"
              variant="secondary"
              back
              >
                Back to Login
              </UiButton>
            </div>
            }
          </Col>
        </Row>
      </Container>
    </section>

  );
};