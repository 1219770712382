import React, {useState, useEffect} from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "../App.css";
import "../resources/styles/professionals.scss";
import {Carousel, Row, Col, Container, Table, Modal, Button, Accordion, Card, Form, Jumbotron} from "react-bootstrap";
import Fade from "react-reveal/Fade";
import MediaQuery, {useMediaQuery} from "react-responsive";
import {useLocation, Link} from "react-router-dom";

import NavBar from "../components/navbar.component.js";
import Footer from "../components/footer.component.js";
import ScrollingCarousel from "../components/scrolling-carousel.js";
import ProductModal from "./product-modal.js";

import prodImgLite from "../resources/photos/products/lite.jpg";
import prodImgMobile from "../resources/photos/products/mobile.jpg";
import prodImgDesktop from "../resources/photos/products/desktop.jpg";
import bodiometerWorkflowLandscape from "../resources/videos/bodiometer_workflow_landscape.mp4";
import bodiometerWorkflowPortrait from "../resources/videos/bodiometer_workflow_portrait.mp4";

import store from "local-storage";

// Helmet for dynamic meta tags in header
import MetaDecorator from "../components/metaDecorator.js";

export default function ForProfessionals() {
  const [state, setState] = useState({
    videoModal1: false,
    videoModal2: false,
    videoModal3: false,
    formModal: false,
    faqKey: 0,

    prodName: "",

    showCart: false,

    subscriptionButtonVisible: false,

    activeSubscription: 2,

    product_list: [
      {
        name: "Bodiometer " + "Lite",
        description: "Bodiometer Lite is a free iPhone app we launched as a quick response to COVID-19. It helps patients who are practicing social distancing track their posture and outcomes digitally and send the reports to their care providers. This app provides the basic functionality of Bodiometer Pro (without a subscription) which is the ability to rapidly take a posture scan, produce a report and show the report to the patient/practitioner. This app is a scaled-down beta prototype that can be used as a quick postural assessment tool for maintaining healthy body at home.",
        image: prodImgLite,
        quantity: 0,
        price_per_month: 100,
        price_per_year: 1200,
        sale_ongoing: false,
        sale_occasion: "Black Friday",
        sale_price_per_month: 100,
        sale_price_per_year: 1200,
        plan: "Y", // M: Monthly, Y: Yearly
        n_months: 12, // number of months if subscription is selected
        version: 11, // version keeps track of the product price (helps for better cache management)
      },
      {
        name: "Bodiometer " + "Pro",
        description: "As the world's first real-time posture assessment app, Bodiometer Pro enables clinicians to quickly and accurately assess patient posture without markers or other devices in almost any setting. Bodiometer utilizes an AI-driven computer vision system to automatically extract patient skeletal data relying only on the camera of the mobile device. Patient scans are saved in app, enabling users to track progress or changes in their posture over the course of treatment. Clinicians can compare scans directly with comparative reports. These visual reports are easy to print or share with the patient as a pdf. These reports makes a huge difference in patient education and engagement which means better outcomes for everyone.",
        image: prodImgMobile,
        quantity: 0,
        price_per_month: 65,
        price_per_year: 650,
        sale_ongoing: false,
        sale_occasion: "Christmas",
        sale_price_per_month: 52,
        sale_price_per_year: 520,
        plan: "Y", // M: Monthly, Y: Yearly
        n_months: 12, // number of months if monthly subscription is selected
        version: 11, // version keeps track of the product price (helps for better cache management)
      },
      {
        name: "Bodiometer " + "Desktop",
        description: "Bodiometer Desktop is our most advanced clinical biomechanics assessment tool. Using markerless motion-tracking, you can quickly and accurately measure range of motion, posture, and functional movement patterns. Our range of motion module allows you to measure 14 different joints in a variety of different movements. Our posture module allows you to quickly measure posture in the coronal, sagittal and traverse planes. Lastly, our functional movement module allows you to measure any kind of functional movement you can think of and compare a patient’s performance over time/  Bodiometer allows you to track patient data easily, and show a patient their progress with engaging and informative progress reports. Bodiometer gives you the tools to collect accurate, objective data quickly, and educate your patients about the structure of their bodies.",
        image: prodImgDesktop,
        quantity: 0,
        price_per_month: 160,
        price_per_year: 1600,
        sale_ongoing: false, // replace it with true during sale
        sale_occasion: "Christmas",
        sale_price_per_month: 128,
        sale_price_per_year: 1280,
        plan: "Y", // M: Monthly, Y: Yearly
        n_months: 12, // number of months if monthly subscription is selected
        version: 11, // version keeps track of the product price (helps for better cache management)
      },
    ],
    currentProductId: 1,
    currentProductVersion: 11,
    currency: "Canadian Dollar",
    currencyAbbr: "CAD",
    currencySymbol: "C$",

    encryptionKey: "secured information of the bodiometer product page",
  });

  const isDesktopOrLaptop = useMediaQuery({query: "(min-width: 1225px)"});
  const isTabletOrMobile = useMediaQuery({query: "(max-width: 1224px)"});

  let location = useLocation();

  // useEffect(() => {
  //   if (location.state && location.state.proFaq && location.state.offset && !isTabletOrMobile) {
  //     // if (location.state.proFaq) {

  //       const yOffset = 1000;
  //       const element = document.getElementById('proFaq');
  //       const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;

  //       window.scrollTo({top: y, behavior: 'smooth'});
  //       location.state.proFaq = false;
  //       location.state.offset = false;

  //       // document.getElementById('proFaq').scrollIntoView();
  //     // }
  //   } else if ((location.state && location.state.proFaq && !location.state.offset) || isTabletOrMobile) {
  //     const yOffset = 0;
  //     const element = document.getElementById('proFaq');
  //     const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;

  //     window.scrollTo({top: y, behavior: 'smooth'});
  //     location.state.proFaq = false;
  //     location.state.offset = false;
  //   }
  // })

  // const TabletIcon = () => {
  //   return(
  //       <svg style={{width: '32px', height: '32px'}} viewBox="0 0 24 24">
  //           <path fill="#6C7C85" d="M19,19H4V3H19M11.5,23A1.5,1.5 0 0,1 10,21.5A1.5,1.5 0 0,1 11.5,20A1.5,1.5 0 0,1 13,21.5A1.5,1.5 0 0,1 11.5,23M18.5,0H4.5A2.5,2.5 0 0,0 2,2.5V21.5A2.5,2.5 0 0,0 4.5,24H18.5A2.5,2.5 0 0,0 21,21.5V2.5A2.5,2.5 0 0,0 18.5,0Z" />
  //       </svg>
  //   );
  // }

  const DesktopIcon = () => {
    return (
      <svg style={{width: "32px", height: "32px"}} viewBox="0 0 24 24">
        <path fill="#6C7C85" d="M21,14H3V4H21M21,2H3C1.89,2 1,2.89 1,4V16A2,2 0 0,0 3,18H10L8,21V22H16V21L14,18H21A2,2 0 0,0 23,16V4C23,2.89 22.1,2 21,2Z" />
      </svg>
    );
  };

  const LiteIcon = () => {
    return (
      <svg style={{width: "32px", height: "32px"}} viewBox="0 0 24 24">
        <path fill="#6C7C85" d="M17,19H7V5H17M17,1H7C5.89,1 5,1.89 5,3V21A2,2 0 0,0 7,23H17A2,2 0 0,0 19,21V3C19,1.89 18.1,1 17,1Z" />
      </svg>
    );
  };

  // Apply selected faq class for styling
  const HandleFaqClick = (e) => {
    let element = document.querySelector(".faq-wrapper .card .collapse.show").previousElementSibling;

    if (element.className.includes("active-faq")) {
      element.className = "card-header";
    } else {
      element.className += " active-faq";
    }

    const element1 = e.target;
    if (element.className.includes("active-faq")) {
      element1.className = "card-header";
    } else {
      element1.className += " active-faq";
    }
  };

  const email = React.useRef();

  const HideSubscribe = () => {
    setState({
      ...state,
      subscribed: false,
      alreadySubscribed: false,
      failedSubscription: false,
    });
  };

  const HandleSubmit = (e) => {
    e.preventDefault();
    fetch("/api/subscribe", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email: email.current.value,
        signupchecked: true,
      }),
    })
      .then((result) => {
        result.json().then((res) => {
          if (res.status_code === 200) {
            setState({
              ...state,
              subscribed: true,
              alreadySubscribed: false,
              failedSubscription: false,
            });
          } else {
            setState({
              ...state,
              subscribed: false,
              alreadySubscribed: false,
              failedSubscription: true,
            });
          }
        });
      })
      .catch((err) => {
        console.log(err);
        setState({
          ...state,
          subscribed: false,
          alreadySubscribed: false,
          failedSubscription: true,
        });
      });
  };

  const ShowCart = (e) => {
    var product_list = state.product_list;
    if (product_list[e.target.value].quantity === 0) {
      product_list[e.target.value].quantity = 1;
    }
    setState({
      ...state,
      currentProductId: Number(e.target.value),
      showCart: true,
      product_list: product_list,
    });

    store.set("product_list", EncryptedObject(state.product_list));
  };

  const HideCart = () => {
    let product_id = 0;
    // switch(props.prodName){
    //     case 'desktop': {
    //         product_id = 2;
    //         break;
    //     }
    //     case 'pro': {
    //         product_id = 1;
    //         break;
    //     }
    //     case 'lite': {
    //         product_id = 0;
    //         break;
    //     }
    // }
    setState({
      ...state,
      showCart: false,
      currentProductId: product_id,
    });
  };

  const EncryptedObject = (obj) => {
    // loading encrytion module
    var CryptoJS = require("crypto-js");
    var ciphertext = CryptoJS.AES.encrypt(JSON.stringify(obj), state.encryptionKey);
    return ciphertext.toString();
  };

  const DecryptObject = (obj) => {
    // loading decrytion module
    var CryptoJS = require("crypto-js");
    var bytes = CryptoJS.AES.decrypt(obj, state.encryptionKey);
    var decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    return decryptedData;
  };

  useEffect(() => {
    if (state.formModal) {
      const script = document.createElement("script");
      script.src = "//js.hsforms.net/forms/shell.js";
      document.body.appendChild(script);

      script.addEventListener("load", () => {
        if (window.hbspt) {
          window.hbspt.forms.create({
            portalId: "2936832",
            formId: "833db815-b0c1-4aa7-afbe-f10d448783bd",
            target: "#hubspotSignupForm",
          });
        }
      });
    }
  }, [state.formModal]);

  // <div id="hubspotSignupForm"></div>

  const HandleGetStartedClick = () => {
    window.location.href = "/portal/register";
  };

  return (
    <div>
      <MetaDecorator description="Smart Postural Analysis Scanning for Clinicians, Ergonomists, & Human Performance Coaches. Scan Your Clients' Biomechanics in Less than 1 Minute. Learn More!" title="Bodiometer for Professionals - Smart Postural Analysis" url="https://www.bodiometer.com/professionals" />
      <NavBar />

      {/* Cart Modal */}
      <ProductModal type={state.prodName} state={state} setState={setState} />

      <div className="professionals-hero-wrapper">
        <MediaQuery orientation="landscape">
          <div className="video-background">
            <div className="video-foreground">
              <video autoPlay={true} loop={true} muted={true} autobuffer playsInline>
                <source src={bodiometerWorkflowLandscape} type="video/mp4" />
                <source src={bodiometerWorkflowLandscape} type='video/webm; codecs="vp8, vorbis"'></source>
              </video>
            </div>
          </div>
        </MediaQuery>

        <MediaQuery orientation="portrait">
          <div className="video-background">
            <div className="video-foreground">
              <video autoPlay={true} loop={true} muted={true} autobuffer playsInline>
                <source src={bodiometerWorkflowPortrait} type="video/mp4" />
                <source src={bodiometerWorkflowPortrait} type='video/webm; codecs="vp8, vorbis"'></source>
              </video>
            </div>
          </div>
        </MediaQuery>

        <Container>
          <Row>
            <Col></Col>
            <Col lg={6}>
              <Jumbotron>
                <h1>Postural Analysis&thinsp;in Less Than a Minute</h1>
                <p>Bodiometer is the world’s first real-time postural analysis solution. Use our innovative technology to quickly and accurately scan and analyse your client’s posture, range of motion, and functional movements.</p>
                <footer>
                  <Button href="#feature-comparison-section" variant="outline-light">
                    Buy Now
                  </Button>
                  <Button href="#mobile-solution-section" variant="secondary">
                    Learn More
                  </Button>
                </footer>
              </Jumbotron>
            </Col>
          </Row>
        </Container>
      </div>

      <section className="clinical-challenges-wrapper padding-mobile">
        <Container className="centered-container">
          <div className="title-icon stack-mobile">
            <h2 className="green-color">Clinical Patient Engagement Platform</h2>
            <img src="../../photos/icons/check-green.svg" width="80" alt="Clinical challenges - icon" />
          </div>
          <p className="gray-color">We help you extend the lifetime value of your patients and bring new patients to your clinic by:</p>
          <Row xs={1} md={3} lg={3}>
            {/* <Col>
                  <img src="../../photos/for-professionals/time.svg" height="80" alt="Takes too much time - icon" />
                  <h3 className="gray-color">Assessment<br/>time</h3>
                  <hr/>
                  <p>A lot of time is wasted on manual assessment and tracking</p>
                </Col>
                <Col>
                  <img src="../../photos/for-professionals/guage.svg" height="80" alt="Inneficient - icon" />
                  <h3 className="gray-color">Inefficient<br/>progress tracking</h3>
                  <hr></hr>
                  <p>Patient progress is not accurately recorded or tracked over time</p>
                </Col>
                <Col>
                  <img src="../../photos/for-professionals/graph.svg" height="80" alt="Increases non-compliance - icon" />
                  <h3 className="gray-color">Patient<br/>non-compliance</h3>
                  <hr></hr>
                  <p>Over 70% of patients don't adhere to their treatment plans</p>
                </Col> */}
            <Col>
              <img src="../../photos/nucca21/engagement.svg" height="40" alt="Takes too much time - icon" />
              <hr />
              <h3 className="green-color">
                Improving patient
                <br />
                engagement
              </h3>
            </Col>
            <Col>
              <img src="../../photos/nucca21/graph.svg" height="40" alt="Increases non-compliance - icon" />
              <hr></hr>
              <h3 className="green-color">
                Increasing patient
                <br />
                retention rate
              </h3>
            </Col>
            <Col>
              <img src="../../photos/nucca21/referrals.svg" height="40" alt="Inneficient - icon" />
              <hr></hr>
              <h3 className="green-color">
                Increasing new
                <br />
                patient referrals
              </h3>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="light-grad-convex bg-grad-inverse desktop-solution-wrapper" id="web-solution-section">
        <Container className="centered-container">
          <div className="title-icon stack-mobile" style={{marginBottom: "30px"}}>
            <h2 className="gray-color">Our Web Solution</h2>
            <img src={`../../photos/icons/dashboard-gray.svg`} width="80" alt="Bodiometer Postural Analysis Solutions - mobile icon" />
          </div>
          <Row xs={1} md={1} lg={2}>
            
            <Col lg={5}>
              <article>
                <h3 className="blue-color">Bodiometer Dashboard</h3>
                <p>Manage your staff, products, patients, and visualize your patients' scans taken at the clinic and home. In-depth tracking, reporting, and analytics provide a competitive edge to increase patient retention rate and facilitate new patient referrals.</p>
                <Link to="/product/dashboard">
                  <Button variant="primary">Learn More</Button>
                </Link>
              </article>
            </Col>
            <Col lg={7}>
              <Fade left delay={300}>
                <img src="../../photos/for-professionals/portal-bg.svg" width="100%" alt="Bodiometer Postural Analysis Mobile Solution - illustration" />
              </Fade>
            </Col>
            
          </Row>
        </Container>
      </section>

      <section className="mobile-solution-wrapper" id="mobile-solution-section">
        <Container className="centered-container">
          <div className="title-icon stack-mobile">
            <img src="../../photos/icons/mobile-gray.svg" width="80" alt="Bodiometer Postural Analysis Solutions - mobile icon" />
            <h2 className="gray-color">Our Mobile Solution</h2>
          </div>
          <Row xs={1} md={1} lg={2}>
            <Col lg={7}>
              <Fade left delay={300}>
                <img src="../../photos/for-professionals/professionals-mobile.gif" width="100%" alt="Bodiometer Postural Analysis Mobile Solution - illustration" />
              </Fade>
            </Col>
            <Col lg={5}>
              <article>
                <h3 className="blue-color">Bodiometer Pro</h3>
                <p>Pose your patient in front of your device and Bodiometer will read real-time accurate postural analysis measurements of their body in front and side views. Generate and send progress reports to your clients, and track their progress over time.</p>
                <Link to="/product/pro">
                  <Button variant="primary">Learn More</Button>
                </Link>
              </article>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="light-grad-bulge bg-grad-inverse desktop-solution-wrapper">
        <Container className="centered-container">
          <div className="title-icon stack-mobile">
            <h2 className="gray-color">Our Desktop Solution</h2>
            <img src="../../photos/icons/desktop-gray.svg" width="80" alt="Bodiometer Postural Analysis Solution - desktop icon" />
          </div>
          <Row xs={1} md={1} lg={2}>
            <Col lg={5}>
              <article>
                <h3 className="blue-color">Bodiometer Desktop</h3>
                <p>Display accurate bomechanics measurements of your patient’s postural analysis, range of motion, and functional movement analysis with instant feedback and interaction. Also perform posture endurance testing and real-time monitoring. Generate and send progress reports to your clients, and track their progress over time.</p>
                <Link to="/product/desktop">
                  <Button variant="primary">Learn More</Button>
                </Link>
              </article>
            </Col>
            <Col lg={7}>
              <Fade right delay={300}>
                <img src="../../photos/for-professionals/professionals-desktop.gif" width="100%" alt="Bodiometer Postural Analysis Desktop Solutions - illustration" />
              </Fade>
            </Col>
          </Row>
        </Container>
        <Container fluid>
          <Row xs={1} md={1} lg={2} noGutters className="application-section-wrapper">
            <Col lg={8}>
              <div className="professionals-applications-wrapper">
                <img src="../../photos/for-professionals/applications-bg.png" width="100%" alt="professional applications background" />
                <Carousel hover="pause" className="applications-carousel" fade={false} pause="hover" interval={null} nextIcon={<img src="../../photos/consumer-landing/next-arrow.svg" height="27" alt="next carousel item - arrow icon" />}>
                  <Carousel.Item>
                    <h3>Healthcare</h3>
                    <hr />
                    <p>We believe the future of healthcare is built on a foundation of ‘precision measurement’. The problem for many professionals is the expense of precision measurement tools (like MRI and CT) and their lack of portability. Bodiometer solves this problem by putting a real-time motion capture tool in the pockets of professionals who depend on precision measurements to excel. Using Bodiometer Apps professionals can aim their iPhone or iPad cameras at a person and automatically capture their posture and body mechanics in a fraction of the time of the current solutions. This helps them to track people’s progress and outcomes digitally as well as educating them immediately.</p>
                  </Carousel.Item>
                  <Carousel.Item>
                    <h3>Occupational Health and Safety</h3>
                    <hr />
                    <p>Economic burden of musculoskeletal disorders (MSD) in Canada is estimated to be $22 Billion. Employers are required by law to take every reasonable precaution to protect workers from hazards, including those that may result in musculoskeletal (MSK) injuries. Bodiometer puts a real-time motion-capture tool in the pockets of ergonomists to point their iPhone or iPad at a person and track their posture in a few seconds. This reduces assessment time, increases precision and objectivity and enhances engagement and education. Bodiometer can change the game in the field of occupational health and safety by replacing the current subjective, anecdotal and inconsistent measurement tools with an objective, accurate and engaging mobile tool.</p>
                  </Carousel.Item>
                  <Carousel.Item>
                    <h3>Human Performance</h3>
                    <hr />
                    <p>The human body is a wonder of engineering at every level and amazing humans continue to push the boundaries of what can be done. From a child's first steps to professional athletes on game day we rely on our biomechanical systems to perform. Our technology enables objective analysis of these systems and the critical relationships within and between them that must be maintained to promote activity and prevent injury. Our technology has the capacity to decode the body language of action so that we can finally hear what the body has been telling us all this time.</p>
                  </Carousel.Item>
                </Carousel>
              </div>
            </Col>
            <Col lg={4}>
              <div className="title-icon-stacked">
                <div>
                  <img src="../../photos/icons/applications-green.svg" width="80" alt="Business applications - icon" />
                  <h2 className="green-color">
                    Business
                    <br />
                    Applications
                  </h2>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
        <Container className="feature-comparison-wrapper" id="feature-comparison-section">
          <Row>
            <Col>
              {isTabletOrMobile && (
                <div className="title-icon center-title">
                  <img src="../../photos/icons/chart-blue.svg" width="80" alt="Bodiometer postural analysis feature comparison - icon" />
                  <h2 className="blue-color">
                    Bodiometer Professional
                    <br />
                    Feature Comparison
                  </h2>
                  <br />
                </div>
              )}
              <Table bordered={false} responsive>
                <thead>
                  <tr>
                    <th>
                      {isDesktopOrLaptop && (
                        <div className="title-icon">
                          <img src="../../photos/icons/chart-blue.svg" width="80" alt="Bodiometer postural analysis feature comparison - icon" />
                          <h2 className="blue-color">
                            Bodiometer Professional
                            <br />
                            Feature Comparison
                          </h2>
                        </div>
                      )}
                    </th>
                    <th>
                      <div>{LiteIcon()}</div>
                      <h3 className="gray-color">
                        Bodiometer
                        <br />
                        Pro
                      </h3>
                    </th>

                    <th>
                      <div>{DesktopIcon()}</div>
                      <h3 className="gray-color">
                        Bodiometer
                        <br />
                        Desktop
                      </h3>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Number of clinicians / users</td>
                    <td>Unlimited</td>
                    <td>Unlimited</td>
                  </tr>
                  <tr>
                    <td>Number of patients</td>

                    <td>Unlimited</td>
                    <td>Unlimited</td>
                  </tr>
                  <tr>
                    <td>Automatic markerless motion tracking technology</td>
                    <td>Yes</td>
                    <td>Yes</td>
                  </tr>
                  <tr>
                    <td>Automatic posture assessment</td>
                    <td>Yes</td>
                    <td>Yes</td>
                  </tr>
                  <tr>
                    <td>Range of motion</td>
                    <td>Under development</td>
                    <td>70+ tests</td>
                  </tr>

                  <tr>
                    <td>Posture endurance testing</td>
                    <td className="no">No</td>
                    <td>Yes</td>
                  </tr>

                  <tr>
                    <td>Custom functional movement analysis</td>
                    <td className="no">No</td>
                    <td>Yes</td>
                  </tr>

                  <tr>
                    <td>Track patient progress over time</td>
                    <td>Yes</td>
                    <td>Yes</td>
                  </tr>

                  <tr>
                    <td>Generate & send progress reports</td>
                    <td>Yes</td>
                    <td>Yes</td>
                  </tr>

                  <tr>
                    <td></td>
                    <td>
                      <Link to="/product/pro">
                        <Button variant="primary">Learn More</Button>
                      </Link>
                    </td>
                    <td>
                      <Link to="/product/desktop">
                        <Button variant="primary">Learn More</Button>
                      </Link>
                    </td>
                  </tr>
                  <tr>
                    <td></td>
                    {/* <td><Button variant="secondary" href="/portal/register">Get Started</Button></td> */}
                    <td>
                      <Button variant="secondary" onClick={(e) => HandleGetStartedClick(e)}>
                        Get Started
                      </Button>
                    </td>
                    {/* <td><Link to="/portal/register"><Button variant="secondary">Get Started</Button></Link></td> */}
                    <td>
                      <Button variant="secondary" href="/contact">
                        Get A Quote
                      </Button>
                    </td>

                    {/* <td><Button variant="secondary" value={1} onClick={ShowCart}>Buy Now</Button></td>
                            <td><Button variant="secondary" value={2} onClick={ShowCart}>Buy Now</Button></td> */}
                  </tr>
                </tbody>
              </Table>
            </Col>
          </Row>
        </Container>
        <Container className="centered-container centered-text client-home-cta-wrapper">
          <Row>
            <Col>
              <h3 className="blue-color">Offer your clients a tracking tool of their own</h3>
              <hr></hr>
              <p>
                Improve your clients' engagement when they use Bodiometer Home mobile app.<Link to="/"> Learn More</Link>
              </p>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="professionals-partners-wrapper">
        <Container>
          <Row xs={1} md={2} lg={2}>
            <Col md={7} lg={7}>
              <ScrollingCarousel
                images={[
                  {src: "../../photos/for-professionals/partners/alberta-pt.png", link: "https://www.albertapt.com/", alt: "Bodiometer professionals partners - alberta physiotherapists logo"},
                  {src: "./../photos/for-professionals/partners/bluejay.png", link: "https://www.bluejayhealth.com/", alt: "Bodiometer professionals partners - bluejay health logo"},
                  {src: "../../photos/for-professionals/partners/gogetfit.png", link: "https://www.goget.fit/", alt: "Bodiometer professionals partners - gogetfit logo"},
                  {src: "../../photos/for-professionals/partners/move-improve.png", link: "https://www.moveimprove.org/en-us/Home/Activities", alt: "Bodiometer professionals partners - move improve logo"},
                  {src: "../../photos/for-professionals/partners/nucca.png", link: "https://nucca.org/", alt: "Bodiometer professionals partners - nucca logo"},
                  {src: "../../photos/for-professionals/partners/oc.png", link: "https://www.orthopaedicscanada.com/", alt: "Bodiometer professionals partners - Orthopaedics Canada logo"},
                ]}
              />

              {/* Old scrolling carousel */}
              {/* <Carousel className="scrolling-carousel on-white" fade={false} pause={false} interval={3000} nextIcon={<img src="../../photos/consumer-landing/next-arrow.svg" height="27" alt="carousel next item - arrow icon" />}>
                    <Carousel.Item>
                      <Row xs={2} md={2}>
                        <Col>
                          <a href="https://www.albertapt.com/" target="_blank" rel="noopener"><img src="../../photos/for-professionals/partners/alberta-pt.png" width="100%" alt="Bodiometer professionals partners - alberta physiotherapists logo" /></a> 
                        </Col>
                        <Col>
                          <a href="https://www.bluejayhealth.com/" target="_blank" rel="noopener"><img src="../../photos/for-professionals/partners/bluejay.png" width="100%" alt="Bodiometer professionals partners - bluejay health logo" /></a>
                        </Col>
                      </Row>
                    </Carousel.Item>
                    <Carousel.Item>
                      <Row xs={2} md={2}>
                        <Col>
                          <a href="https://www.goget.fit/" target="_blank" rel="noopener"><img src="../../photos/for-professionals/partners/gogetfit.png" width="100%" alt="Bodiometer professionals partners - gogetfit logo" /></a>
                        </Col>
                        <Col>
                          <a href="https://www.moveimprove.org/en-us/Home/Activities" target="_blank" rel="noopener"><img src="../../photos/for-professionals/partners/move-improve.png" width="100%" alt="Bodiometer professionals partners - move improve logo" /></a>
                        </Col>
                      </Row>
                    </Carousel.Item>
                    <Carousel.Item>
                      <Row xs={2} md={2}>
                        <Col>
                          <a href="https://nucca.org/" target="_blank" rel="noopener"><img src="../../photos/for-professionals/partners/nucca.png" width="100%" alt="Bodiometer professionals partners - nucca logo" /></a>
                        </Col>
                        <Col>
                          <a href="https://www.orthopaedicscanada.com/" target="_blank" rel="noopener"><img src="../../photos/for-professionals/partners/oc.png" width="100%" alt="Bodiometer professionals partners - Orthopaedics Canada logo" /></a>
                        </Col>
                      </Row>
                    </Carousel.Item>
                </Carousel> */}
            </Col>
            <Col md={5} lg={5}>
              <div className="title-icon">
                <img src="../../photos/icons/handshake-blue.svg" width="80" alt="Bodiometer partners - handshake icon" />
                <h2 className="blue-color">Our Partners</h2>
              </div>
            </Col>
          </Row>
          <Row>
            <Carousel className="testimonial-carousel" fade={false} pause="hover" interval={null} nextIcon={<img src="../../photos/consumer-landing/next-arrow.svg" height="27" alt="next carousel item - arrow icon" />}>
              <Carousel.Item>
                <Row xs={1} md={1} lg={2}>
                  <Col>
                    <article>
                      <blockquote>
                        <em>“One of the things that's been wonderful about working with Bodiometer is that ... it is able to pick the point and create a wonderfully reliable and valid method for us to quickly and easily assess the patient's posture and understand where they are on a given day.”</em>
                      </blockquote>
                      <hr />
                      <div>
                        <img src="../../photos/for-professionals/jeff.png" width="60" alt="Bodiometer postural analysis testimonial - avatar" />
                        <p>Dr. Jeff Scholten, BSc, DC, DCCJP, FCCJP Clinic Director, The Vital Posture™ Clinic</p>
                      </div>
                    </article>
                  </Col>
                  <Col>
                    <div className="testimonials-video-wrapper">
                      <img src="../../photos/for-professionals/jeff-bg.png" width="100%" alt="video testimonials background" />
                      <img src="../../photos/icons/play-white.svg" width="150" className="video-play-icon" onClick={() => setState({...state, videoModal1: true})} alt="video play icon" />
                    </div>
                    <Modal size="xl" className="video-modal-wrapper" centered show={state.videoModal1} onHide={() => setState({...state, videoModal1: false})}>
                      <Modal.Header closeButton></Modal.Header>
                      <Modal.Body>
                        <video autoPlay={true} loop={true} width="100%" controls>
                          <source src="../../videos/testimonials/Jeff.mp4" type="video/mp4" />
                        </video>
                      </Modal.Body>
                    </Modal>
                  </Col>
                </Row>
              </Carousel.Item>
              <Carousel.Item>
                <Row xs={1} md={1} lg={2}>
                  <Col>
                    <article>
                      <blockquote>
                        <em>“What I really like about [Bodiometer] is that it allows the patient to see themselves with some of the orthogonal lines in place so that it becomes obvious when they are not standing straight.”</em>
                      </blockquote>
                      <hr />
                      <div>
                        <img src="../../photos/for-professionals/ben.png" width="60" alt="Bodiometer postural analysis testimonial - avatar 2" />
                        <p>Dr. Ben Kuhn, DC, DCCJP Clinic Director, Providence Chiropractic Clinic</p>
                      </div>
                    </article>
                  </Col>
                  <Col>
                    <div className="testimonials-video-wrapper">
                      <img src="../../photos/for-professionals/ben-bg.png" width="100%" alt="video testimonial background 2" />
                      <img src="../../photos/icons/play-white.svg" width="150" className="video-play-icon" onClick={() => setState({...state, videoModal2: true})} alt="video play icon 2" />
                    </div>
                    <Modal size="xl" className="video-modal-wrapper" centered show={state.videoModal2} onHide={() => setState({...state, videoModal2: false})}>
                      <Modal.Header closeButton></Modal.Header>
                      <Modal.Body>
                        <video autoPlay={true} loop={true} width="100%" controls>
                          <source src="../../videos/testimonials/Kuhn.mp4" type="video/mp4" />
                        </video>
                      </Modal.Body>
                    </Modal>
                  </Col>
                </Row>
              </Carousel.Item>
              <Carousel.Item>
                <Row xs={1} md={1} lg={2}>
                  <Col>
                    <article>
                      <blockquote>
                        <em>“[Bodiometer] allows me to accurately and quickly measure and document range of motion and map progress of the affected joints. It helps me tailor my specific treatments to a patients needs and produce better results…”</em>
                      </blockquote>
                      <hr />
                      <div>
                        <img src="../../photos/for-professionals/denis2.png" width="60" alt="Bodiometer postural analysis testimonial - avatar 2" />
                        <p>Denis Simons, Chiropractor, Clinic Director, Momentum Health Westbrook</p>
                      </div>
                    </article>
                  </Col>
                  <Col>
                    <div className="testimonials-video-wrapper">
                      <img src="../../photos/for-professionals/denis-bg.png" width="100%" alt="video testimonial background 2" />
                      <img src="../../photos/icons/play-white.svg" width="150" className="video-play-icon" onClick={() => setState({...state, videoModal3: true})} alt="video play icon 2" />
                    </div>
                    <Modal size="xl" className="video-modal-wrapper" centered show={state.videoModal3} onHide={() => setState({...state, videoModal3: false})}>
                      <Modal.Header closeButton></Modal.Header>
                      <Modal.Body>
                        <video autoPlay={true} loop={true} width="100%" controls>
                          <source src="../../videos/testimonials/denis.mp4" type="video/mp4" />
                        </video>
                      </Modal.Body>
                    </Modal>
                  </Col>
                </Row>
              </Carousel.Item>
            </Carousel>
          </Row>
        </Container>
      </section>
      <section className="light-grad-bulge bg-grad-inverse faq-wrapper" id="proFaq">
        <Container className="centered-container">
          <div className="title-icon">
            <img src="../../photos/icons/question-blue.svg" width="80" alt="Frequently Asked Questions - question mark icon" />
            <h2 className="blue-color">Frequently Asked Questions</h2>
          </div>
          <Row>
            <Col>
              <Accordion defaultActiveKey="0" onChange={(e) => setState({...state})}>
                <Card>
                  <Accordion.Toggle as={Card.Header} eventKey="0" onClick={(e) => HandleFaqClick(e)} className="active-faq">
                    Are scans as accurate as current industry standards?
                  </Accordion.Toggle>
                  <Accordion.Collapse eventKey="0">
                    <Card.Body>Yes. Bodiometer solutions are being adopted by many professional clinicians as well as NUCCA doctors (who are well-known for being keen on accuracy) to completely replace their current tools for precision postural analysis.</Card.Body>
                  </Accordion.Collapse>
                </Card>
                <Card>
                  <Accordion.Toggle as={Card.Header} eventKey="1" onClick={(e) => HandleFaqClick(e)}>
                    How much time can be saved by using Bodiometer?
                  </Accordion.Toggle>
                  <Accordion.Collapse eventKey="1">
                    <Card.Body>Anecdotal evidence shows that by using Bodiometer, up to 80% of manual postural analysis time can be saved.</Card.Body>
                  </Accordion.Collapse>
                </Card>
                <Card>
                  <Accordion.Toggle as={Card.Header} eventKey="2" onClick={(e) => HandleFaqClick(e)}>
                    How fast can Bodiometer capture my patients’ measurements?
                  </Accordion.Toggle>
                  <Accordion.Collapse eventKey="2">
                    <Card.Body>It takes just 30 seconds for Bodiometer to scan all postural measurements.</Card.Body>
                  </Accordion.Collapse>
                </Card>
                <Card>
                  <Accordion.Toggle as={Card.Header} eventKey="3" onClick={(e) => HandleFaqClick(e)}>
                    Does Bodiometer desktop work on PC and Mac?
                  </Accordion.Toggle>
                  <Accordion.Collapse eventKey="3">
                    <Card.Body>Bodiometer Desktop uses Microsoft Kinect V2.0 Sensor and Microsoft Kinect for Azure and therefore is only compatible with Windows PC’s.</Card.Body>
                  </Accordion.Collapse>
                </Card>
                <Card>
                  <Accordion.Toggle as={Card.Header} eventKey="4" onClick={(e) => HandleFaqClick(e)}>
                    Is Bodiometer Pro available on both iOS and Android?
                  </Accordion.Toggle>
                  <Accordion.Collapse eventKey="4">
                    <Card.Body>Bodiometer Pro currently is only available on iOS devices. </Card.Body>
                  </Accordion.Collapse>
                </Card>
                <Card>
                  <Accordion.Toggle as={Card.Header} eventKey="5" onClick={(e) => HandleFaqClick(e)}>
                    How can I share the data with my patient?
                  </Accordion.Toggle>
                  <Accordion.Collapse eventKey="5">
                    <Card.Body>You can create PDF reports of your patients’ progress charts and share them by email.</Card.Body>
                  </Accordion.Collapse>
                </Card>
                <Card>
                  <Accordion.Toggle as={Card.Header} eventKey="6" onClick={(e) => HandleFaqClick(e)}>
                    What data is included in the reports?
                  </Accordion.Toggle>
                  <Accordion.Collapse eventKey="6">
                    <Card.Body>Patient progress reports contain scanned images as well as postural angles. You can also generate comparative reports between past scans.</Card.Body>
                  </Accordion.Collapse>
                </Card>
                <Card>
                  <Accordion.Toggle as={Card.Header} eventKey="7" onClick={(e) => HandleFaqClick(e)}>
                    What are the minimum requirements for Bodiometer Desktop?
                  </Accordion.Toggle>
                  <Accordion.Collapse eventKey="7">
                    <Card.Body>
                      <h4>Hardware:</h4>
                      <ul>
                        <li>Microsoft Kinect Sensor V2</li>
                        <li>Kinect Adapter for Windows</li>
                        <li>64-bit (x64) processor</li>
                        <li>Physical dual-core 3.1 GHz (2 logical cores per physical) or faster processor</li>
                        <li>USB 3.0 controller dedicated to the Kinect for Windows v2 sensor</li>
                        <li>8 GB of RAM</li>
                        <li>
                          Graphics card that supports DirectX 11 with at least 1 GB dedicated memory:
                          <ul>
                            <li>Intel HD 4400 integrated display adapter</li>
                            <li>ATI Radeon HD 5400 series</li>
                            <li>NVidia Quadro 600</li>
                            <li>NVidia GeForce GTX 660</li>
                            <li>NVidia Quadro K1000M</li>
                          </ul>
                        </li>
                        <li>Disk Space 500MB (More Disk space will be needed to store patient data)</li>
                      </ul>
                      <h4>Software:</h4>
                      <ul>
                        <li>Windows 8 or 8.1, or Windows 10</li>
                      </ul>
                      <h4>Environmental:</h4>
                      <ul>
                        <li>6.5 - 11.5 Foot depth – optimal distance is around 7 Foot</li>
                        <li>The optimal height of the camera from the ground is around 5.5 Foot</li>
                        <li>The suggested tilt angle of the camera is between 15 to 20 degrees</li>
                      </ul>
                      <h4>In Clinic Setup:</h4>
                      <ul>
                        <li>Ideally a dedicated space with a screen</li>
                        <li>Compact setup using laptop and trolley</li>
                      </ul>
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
                <Card>
                  <Accordion.Toggle as={Card.Header} eventKey="8" onClick={(e) => HandleFaqClick(e)}>
                    What are the minimum requirements for Bodiometer Pro?
                  </Accordion.Toggle>
                  <Accordion.Collapse eventKey="8">
                    <Card.Body>Bodiometer Pro works on any iPad or iPad Pro newer than or equal to iPad Pro 2015 and any iPhone newer than or equal to iPhone 6s.</Card.Body>
                  </Accordion.Collapse>
                </Card>
              </Accordion>
            </Col>
          </Row>
        </Container>
      </section>
      <section>
        <Container>
          <Row xs={1} md={2} className="professionals-infographic-wrapper">
            <Col md={4} lg={6}>
              <Fade left delay={300}>
                <img src="../../photos/for-professionals/newsletter.svg" width="90%" alt="newsletter illustration" />
              </Fade>
            </Col>
            <Col id="signup" md={8} lg={6}>
              <h3 className="blue-color">Subscribe today and stay up to date on our latest news</h3>
              <p>We never spam or sell your information to third parties, feel free to unsubscribe at any time.</p>

              <Modal size="xl" className="form-modal-wrapper" centered show={state.formModal} onHide={() => setState({...state, formModal: false})}>
                <Modal.Header closeButton></Modal.Header>
                <Modal.Body>
                  <Container className="thin-container mailchimp-form-wrapper">
                    <Row>
                      <Col>
                        <h3 className="gray-color">Subscribe to our Newsletter</h3>
                        <div id="hubspotSignupForm"></div>
                      </Col>
                    </Row>
                  </Container>
                </Modal.Body>
              </Modal>
              <Button variant="primary" onClick={() => setState({...state, formModal: true})}>
                Sign Up
              </Button>

              {/* Old hubspot form */}
              {/* <Modal show={state.subscribed} onEscapeKeyDown={HideSubscribe} size={'lg'} centered={true}>
                      <Modal.Body>
                          <div className="confirm-signup">
                              <h3>
                              <FontAwesomeIcon icon={faCheckSquare} /> Thank you for subscribing to Bodiometer mailing list! 
                              </h3>
                          </div>
                      </Modal.Body>
                      <Modal.Footer>
                          <Button variant="success" onClick={HideSubscribe}>Ok</Button>
                      </Modal.Footer>
                  </Modal>

                  { state.alreadySubscribed &&
                      <div className="already-subscribed">
                          <h3>
                              You are already subscribed to Bodiometer <FontAwesomeIcon icon={faCheckSquare} />
                          </h3>
                      </div> 
                  }

                  { state.failedSubscription &&
                      <div className="failed-subscribe">
                          <h3>
                              Something went wrong
                          </h3>
                      </div> 
                  }

                  { !state.subscribed &&
                    <Form onSubmit={HandleSubmit} inline>
                        <Form.Group as={Row} controlId="formPlaintextEmail">
                            <Form.Control type="email" placeholder="Email" ref={email} required/>
                            
                            <Button variant="primary" type="submit">
                                Signup
                            </Button>
                        </Form.Group>
                    </Form>
                  } */}
            </Col>
          </Row>
        </Container>
      </section>
      <Footer />
      {/* <!-- Start of HubSpot Embed Code --> */}
      <script type="text/javascript" id="hs-script-loader" async defer src="//js.hs-scripts.com/2936832.js"></script>
      {/* <!-- End of HubSpot Embed Code --> */}
    </div>
  );
}
