import React from 'react';
import {Container, Row, Col} from 'react-bootstrap';

import "../resources/styles/standard-hero.scss";

export default function StandardHero(props) {

  return (
    <section className={`standard-hero-wrapper${props.tall ? ' more-padding' : ''}`}>
      <Container fluid>
        {props.bg &&
        <div>
          <img src={props.bg} className="bg-image" alt="custom background" />
          <img src="../../photos/consumer-landing/bg-image-grad-overlay.png" width="100%" className="bg-image-grad-overlay" alt="gradient overlay over custom bg" />
          <img src="../../photos/consumer-landing/bulge-bottom.svg" width="100%" className="bottom-clip" alt="bulge graphic" ></img>
        </div>
        }
        <Row>
          <Col>
            {props.children}
          </Col>
        </Row>  
      </Container>      
    </section>
  );
}
