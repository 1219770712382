import React, { useState, useEffect, Fragment } from "react";
import { Col, Row, Form, Popover, OverlayTrigger, Alert } from "react-bootstrap";
import axios from "axios";

import UiButton from "./ui-button.js";
import UiTable from "./ui-table.js";
import DisplayPhone from "../../resources/helpers/display-phone.js";

import { GetAccessLevelFromProp } from "../../lib/scripts.js";

import "bootstrap/dist/css/bootstrap.min.css";

export default function Overview(props) {
	const activeAccessLevel = GetAccessLevelFromProp(props);

	const [state, setState] = useState({
		ShowRemovePopover: false,
		ShowEditPopover: false,
		EditRoleValidated: false,
		RoleValue: "Role",
		AddStaffValidated: false,
		AddStaffEmail: "",
		Role: "Unassigned",
		Error: [false, ""],
		Success: [false, ""],
	});

	// hide success/error alert after 4 seconds
	useEffect(() => {
		const timer = setTimeout(() => {
			setState((prev) => ({ ...prev, Success: [false, ""], Error: [false, ""] }));
		}, 4000);
		return () => clearTimeout(timer);
	}, [state.Success, state.Error]);

	const defaultDateFormat = {
		year: "numeric",
		month: "short",
		day: "numeric",
		// hour: "numeric",
		// minute: "numeric",
	};

	// adjust time based on offset
	const AddHoursToDate = (str, h) => {
		let newDate = new Date(str);
		newDate.setTime(newDate.getTime() + h * 60 * 60 * 1000);
		return newDate.toLocaleString("en-Us", defaultDateFormat);
	};

	// eg: AddHoursToDate("07/13/2021 14:22:32", props.parentState.TodayOffset)

	// if the current license is a trial license
	let trialLicense =
		props.ActiveOrganization.license &&
		props.ActiveOrganization.license[0].details.subscription &&
		props.ActiveOrganization.license[0].details.subscription.type &&
		props.ActiveOrganization.license[0].details.subscription.type == "trial"
			? true
			: false;

	let rolesAccessLevels = {};

	for (let role of props.ActiveOrganization.roles) {
		for (let roleEntry of props.ActiveOrganization.roleEntries) {
			if (roleEntry.title == role) {
				rolesAccessLevels[role] = roleEntry.accessLevel;
			}
		}
	}

	// a business role needs to have a business id (businessId), a title (title), a category of access (admin / staff) (accessCategory), and a number showing level of access (accessLevel).

	const CanAddMoreStaff = () => {
		if (props.parentState.ActiveOrganization.myAccessLevel >= 3) {
			return false;
		}

		let numOfUsers = 0;
		for (let i of props.parentState.ActiveOrganization.roleEntries) {
			if (i.userId !== "") {
				numOfUsers++;
			}
		}

		// still on free trial
		if (!props.parentState.ActiveOrganization.license && props.parentState.ActiveOrganization.freeTrial > 0) {
			if (numOfUsers >= 5) {
				return false;
			} else {
				return true;
			}
		}

		if (props.parentState.ActiveOrganization.license && props.parentState.ActiveOrganization.license[0].details.status == "Expired") {
			return false;
		}

		// has license
		if (props.parentState.ActiveOrganization.license) {
			if (props.parentState.ActiveOrganization.license[0].details.status !== "Active" && numOfUsers >= props.parentState.ActiveOrganization.license[0].details.maxUsers) {
				return false;
			} else {
				return true;
			}
		}
	};

	const HandleAddStaffSubmit = (e) => {
		setState({ ...state, Role: "Unassigned" });
		if (state.Role && state.AddStaffEmail) {
			// See if user already exists
			const Headers = {
				headers: {
					Authorization: props.parentState.DBAccessToken,
					email: state.AddStaffEmail,
				},
			};

			axios.get(props.dbUrl + `/api/businessusers/v1/email/details/full`, Headers).then((user) => {
				const GetAccessLevel = () => {
					for (let i of props.ActiveOrganization.roleEntries) {
						if (i.title === state.Role) {
							return i.accessLevel;
						}
					}
				};

				const CheckIfUserRoleExists = () => {
					for (let i of props.ActiveOrganization.roleEntries) {
						if (i.userId === user.data.data.userId) {
							return true;
						}
					}
					return false;
				};

				// If user exists, create their role
				if (user.data.success && !CheckIfUserRoleExists()) {
					const PostData = {
						businessId: props.ActiveOrganization.id,
						userId: user.data.data.userId,
						title: state.Role,
						accessCategory: state.Role,
						accessLevel: 3,
						description: "",
						status: "active",
						createdAt: new Date().toISOString(),
						createdAtOffset: 0,
					};

					const PostHeaders = {
						headers: {
							Authorization: props.parentState.DBAccessToken,
							"Content-Type": "application/json",
						},
					};

					axios.post(props.dbUrl + "/api/businessroles/v1/create", PostData, PostHeaders).then((res) => {
						props.setState((prev) => ({ ...prev, Refresh: prev.Refresh + 1 }));
						setState({ ...state, Success: [true, "The user was added successfully"] });
					});
				} else if (!user.data.success) {
					// user doesn't exist yet.. create role with empty user, and send email for registration

					// https://bodiometer.com/api/businessaccount/register/:businessId/:token

					const EmptyRolePostData = {
						businessId: props.ActiveOrganization.id,
						userId: null,
						title: state.Role,
						accessCategory: state.Role,
						accessLevel: GetAccessLevel(),
						description: "",
						status: "active",
						createdAt: props.parentState.Today,
						createdAtOffset: "3",
					};

					const EmptyRolePostHeaders = {
						headers: {
							Authorization: props.parentState.DBAccessToken,
							"Content-Type": "application/json",
						},
					};

					axios.post(props.dbUrl + "/api/businessroles/v1/create", EmptyRolePostData, EmptyRolePostHeaders).then((res) => {
						const NewRegisterPostData = {
							businessId: props.parentState.ActiveOrganization.id,
							email: state.AddStaffEmail,
							roleId: res.data.data.roleId,
						};

						const NewRegisterPostHeaders = {
							headers: {
								Authorization: props.parentState.DBAccessToken,
								"Content-Type": "application/json",
							},
						};

						axios.post(props.dbUrl + "/api/email/v2/businessusers/invitation", NewRegisterPostData, NewRegisterPostHeaders).then((res2) => {
							// email sent!
							setState({ ...state, Success: [true, "Success, the invitation has been sent"] });
						});
					});
				} else if (user.data.success && CheckIfUserRoleExists()) {
					setState({ ...state, Error: [true, "User is already a member"] });
				}
			});
		}
	};

	const AddStaff = () => {
		return (
			<Popover className="action-toggle-wrapper" id={`popover${Math.floor(Math.random()).toString()}`}>
				<Popover.Content>
					<p>Invite staff user to {props.ActiveOrganization.name}</p>
					<Form inline validated={state.AddStaffValidated} className="flex-row">
						<Form.Group controlId="edit_role">
							<Form.Control size="sm" type="email" placeholder="User Email" value={state.AddStaffEmail} onChange={(e) => setState({ ...state, AddStaffEmail: e.currentTarget.value })}></Form.Control>
						</Form.Group>

						{/* <Form.Group controlId="set_role">
              <Form.Control size="sm" as="select" placeholder="Role" custom className="ui-select margin-left" value={state.Role} onChange={(e) => setState({...state, Role: e.currentTarget.value})}>
                <option value="Select">Role</option>
                {props.ActiveOrganization.roles.length > 1 && props.ActiveOrganization.roles.map((role) => (role.toLowerCase() !== "owner" && rolesAccessLevels[role] >= 3 ? <option value={role}>{role}</option> : ""))}
              </Form.Control>
            </Form.Group> */}

						<Form.Group controlId="submit">
							<UiButton onClick={(e) => HandleAddStaffSubmit(e)} type="submit" size="sm">
								Invite
							</UiButton>
						</Form.Group>
					</Form>
				</Popover.Content>
			</Popover>
		);
	};

	const EditStaff = (params) => {
		let currentRole = params.row.organization[0].role;

		const UpdateStaff = (e) => {
			e.stopPropagation();
			e.preventDefault();

			if (state.RoleValue !== "Role" && state.RoleValue !== currentRole) {
				let roleInfo = {};

				for (let i of props.parentState.ActiveOrganization.roleEntries) {
					if (state.RoleValue == i.title) {
						roleInfo = {
							title: i.title,
							accessCategory: i.accessCategory,
							accessLevel: i.accessLevel,
							description: i.description,
						};
					}
				}

				const RolePostHeaders = {
					headers: {
						Authorization: props.parentState.DBAccessToken,
						"Content-Type": "application/json",
						roleId: params.row.organization[0].roleId,
					},
				};

				axios
					.put(props.dbUrl + "/api/businessroles/v1/update/roleid", roleInfo, RolePostHeaders)

					.then((res) => {
						if (res.data.success) {
							props.setState({ ...props.parentState, Refresh: props.parentState.Refresh + 1 });
							setState({ ...state, Success: [true, "User was successfully updated"] });
						} else {
							setState({ ...state, Error: [true, "Something went wrong"] });
						}
					});
			}
		};

		return (
			<Popover className="action-toggle-wrapper" id={`popover${Math.floor(Math.random()).toString()}`}>
				<Popover.Content>
					<p>Change {params.row.fname + " " + params.row.lname + "'s"} Role:</p>
					<Form inline className="flex-row">
						<Form.Group controlId="edit_role">
							<Form.Control
								size="sm"
								as="select"
								placeholder="Role"
								custom
								className="ui-select"
								value={state.RoleValue}
								onChange={(e) => setState({ ...state, RoleValue: e.currentTarget.value })}
								required
							>
								{/* <option key={`role-option-default`} value="Select">
                  Role
                </option> */}
								{props.ActiveOrganization.roles.length > 1 &&
									props.ActiveOrganization.roles.map((role) =>
										role.toLowerCase() !== "owner" && rolesAccessLevels[role] >= activeAccessLevel ? (
											<option key={`role-option-${role}`} value={role}>
												{role}
											</option>
										) : (
											""
										)
									)}
							</Form.Control>
						</Form.Group>

						<Form.Group controlId="submit">
							<UiButton onClick={(e) => UpdateStaff(e)} size="sm" className="margin-left">
								Change
							</UiButton>
						</Form.Group>
					</Form>
				</Popover.Content>
			</Popover>
		);
	};

	const RemoveStaff = (params) => {
		const HandleRemoveStaff = (e) => {
			e.stopPropagation();
			e.preventDefault();

			const RolePostHeaders = {
				headers: {
					Authorization: props.parentState.DBAccessToken,
					roleId: params.row.organization[0].roleId,
				},
			};

			axios
				.delete(props.dbUrl + "/api/businessroles/v1/remove/roleid", RolePostHeaders)

				.then((res) => {
					if (res.data.success) {
						props.setState({ ...props.parentState, Refresh: props.parentState.Refresh + 1 });
						setState({ ...state, Success: [true, "Role was successfully removed"] });
					} else {
						setState({ ...state, Error: [true, "Something went wrong"] });
					}
				});
		};

		return (
			<Popover className="action-toggle-wrapper remove-popover" id={`popover${Math.floor(Math.random()).toString()}`}>
				<Popover.Content>
					<p>
						Are you sure you want to remove {params.row.fname} {params.row.lname} from the organization?
					</p>
					<div className="flex-row">
						<UiButton onClick={HandleRemoveStaff} size="sm" variant="danger">
							Remove
						</UiButton>
						<UiButton onClick={(e) => setState((prev) => ({ ...prev, ShowRemovePopover: false }))} variant="outline" className="no-padding gray-color margin-left">
							Cancel
						</UiButton>
					</div>
				</Popover.Content>
			</Popover>
		);
	};

	const CreateColumns = () => {
		const cols = [];
		if (props.users) {
			cols.push({
				field: "fullName",
				headerName: "Name",
				width: 200,
				valueGetter: (params) => `${params.getValue("fname") || ""} ${params.getValue("lname") || ""}`,
			});

			cols.push({
				field: "role",
				headerName: "Role",
				width: 150,
				valueGetter: (params) => {
					for (let i of params.row.organization) {
						return i.role;
					}
				},
			});

			cols.push({
				field: "status",
				headerName: "Status",
				width: 150,
				valueGetter: (params) => {
					for (let i of params.row.organization) {
						return i.status;
					}
				},
			});
			if (activeAccessLevel <= 1) {
				cols.push({
					field: "actions",
					headerName: "Actions",
					width: 150,
					renderCell: (params) => {
						for (let i of params.row.organization) {
							if (i.role !== "Owner" && i.role !== "owner") {
								return (
									<Fragment>
										<OverlayTrigger rootClose={true} trigger="click" placement="left" overlay={EditStaff(params)}>
											<a onClick={(e) => setState({ ...state, ShowEditPopover: true })}>
												<img src="../../photos/icons/dashboard/SVG/edit-green.svg" height="30" alt="edit icon" />
											</a>
										</OverlayTrigger>
										&nbsp; &nbsp;
										<OverlayTrigger rootClose={true} trigger="click" placement="left" overlay={RemoveStaff(params)} show={state.ShowRemovePopover === params.row.id ? true : false}>
											<a onClick={(e) => setState({ ...state, ShowRemovePopover: params.row.id })}>
												<img src="../../photos/icons/dashboard/SVG/trash-gray.svg" height="18" className="trash-icon" alt="trash icon" />
											</a>
										</OverlayTrigger>
									</Fragment>
								);
							} else {
								return "";
							}
						}
					},
				});
			}

			cols.push({
				field: "dateAdded",
				headerName: "Date Added",
				width: 200,
				valueGetter: (params) => {
					for (let i of params.row.organization) {
						return new Date(i.created).toLocaleString("en-Us", defaultDateFormat);
					}
				},
			});
		}

		return cols;
	};

	const CreateRows = () => {
		const rows = [];
		if (props.users) {
			for (let i of props.users) {
				for (let x of i.organization) {
					if (x.id === props.ActiveOrganizationId) {
						// id starts with _ANONYMIZED_ excluded
						if (!new RegExp("^_ANONYMIZED_").test(i.id)) {
							rows.push(i);
						}
					}
				}
			}
		}
		return rows;
	};

	const StaffMemberCount = () => {
		if (props.users) {
			let num = 0;
			for (let i of props.users) {
				for (let x of i.organization) {
					if (x.id === props.ActiveOrganizationId) {
						num++;
					}
				}
			}
			return num.toString();
		} else {
			return "0";
		}
	};

	const GetPlanName = () => {
		if (!!props.plans) {
			for (let plan of props.plans) {
				if (props.ActiveOrganization.license && props.ActiveOrganization.license[0].details.status.toLowerCase() != "expired") {
					if (props.ActiveOrganization.license[0].details.plan === plan.id) {
						return plan.name;
					}
				} else if (props.ActiveOrganization.freeTrial > 0) {
					return "Free Trial";
				} else if (props.ActiveOrganization.freeTrial == null) {
					return "No License";
				} else if (props.ActiveOrganization.freeTrial <= 0) {
					return "Expired";
				}
			}
		}
		return "No License";
	};

	return (
		<div
			className="ui-section-wrapper"
			onClick={(e) => {
				e.preventDefault();
				return state.ShowRemovePopover ? setState({ ...state, ShowRemovePopover: false }) : "";
			}}
		>
			<div
				className="invisible-reset"
				onClick={(e) => {
					e.preventDefault();
					return state.ShowRemovePopover ? setState({ ...state, ShowRemovePopover: false }) : "";
				}}
			></div>
			<section>
				<Row>
					<Col className="flex-row no-wrap">
						<div>
							<p className="uppercase p-heading">{props.ActiveOrganization && props.ActiveOrganization.name}</p>
							<h1>Overview</h1>
						</div>
						<Alert variant="warning" show={state.Error[0] ? true : false}>
							<div className="flex-row">
								<p>{state.Error[1]}</p>
							</div>
						</Alert>
						<Alert variant="info" show={state.Success[0] ? true : false}>
							<div className="flex-row">
								<p>{state.Success[1]}</p>
							</div>
						</Alert>
					</Col>
					{props.parentState.ActiveOrganizationLogo && (
						<Col className="organization-logo-wrapper">
							<img src={props.parentState.ActiveOrganizationLogo} height="50" alt="organization icon" />
						</Col>
					)}
				</Row>
			</section>
			<section>
				<Row>
					<Col>
						<div className="flex-row super-button-wrapper">
							<h4>Details</h4>
							{activeAccessLevel <= 1 && (
								<UiButton onClick={(e) => props.setView("edit-organization")} variant="link" className="no-padding">
									<img src="../../photos/icons/dashboard/SVG/edit-green.svg" width="25" alt="edit icon" />
								</UiButton>
							)}
						</div>
					</Col>
				</Row>
				<Row className="info-details">
					<Col>
						<p>
							Name: <span>{props.ActiveOrganization.name}</span>
						</p>
						<p>
							Type: <span>{props.ActiveOrganization.type}</span>
						</p>
						<p>
							Phone: <span>{DisplayPhone(props.ActiveOrganization.phone)}</span>
						</p>
						<p>
							Fax: <span>{DisplayPhone(props.ActiveOrganization.fax)}</span>
						</p>
					</Col>
					<Col>
						<p>
							Address: <span>{props.ActiveOrganization.address}</span>
						</p>
						<p>
							City: <span>{props.ActiveOrganization.city}</span>
						</p>
						<p>
							{props.ActiveOrganization.country === "Canada" ? "Province: " : "State: "}
							<span>
								{props.ActiveOrganization.province} {" | " + props.ActiveOrganization.country}
							</span>
						</p>
						<p>
							{props.ActiveOrganization.country === "Canada" ? "Postal Code: " : "Zip Code: "}
							<span>{props.ActiveOrganization.postal}</span>
						</p>
					</Col>
					<Col>
						<p>
							Organization ID: <span>{props.ActiveOrganization.id}</span>
						</p>
						<p>
							Email: <span>{props.ActiveOrganization.email}</span>
						</p>
						<p>
							License: <span>{`${GetPlanName()} ${trialLicense ? " - Trial" : ""}`}</span>
						</p>
						{activeAccessLevel <= 1 && (
							<UiButton className="no-padding green-color muted smaller-text" variant="link" plus={props.ActiveOrganization.license ? false : true} onClick={(e) => props.setView("license")}>
								{props.ActiveOrganization.license && !trialLicense ? "Change Subscription" : "Add Subscription"}
							</UiButton>
						)}
					</Col>
				</Row>
			</section>

			<section className="overview-staff-wrapper">
				<Row>
					<Col lg={4} md={4}>
						<h4>
							{StaffMemberCount()} Member{StaffMemberCount() !== "1" ? "s" : ""}
						</h4>
					</Col>
					<Col lg={8} md={8} className="table-cta-top">
						{activeAccessLevel <= 2 ? (
							<Fragment>
								<OverlayTrigger rootClose={true} trigger="click" placement="bottom-end" overlay={AddStaff()}>
									<UiButton plus variant="outline-secondary">
										Add Staff
									</UiButton>
								</OverlayTrigger>
							</Fragment>
						) : (
							<Fragment>
								<p className="muted gray-color">upgrade access level to add new staff</p>
							</Fragment>
						)}
					</Col>
				</Row>
				<Row className={`${props.users.length > 0 ? "" : " loading-bg"}`}>
					<Col>
						<UiTable cols={CreateColumns()} rows={CreateRows()}></UiTable>
					</Col>
				</Row>
			</section>
		</div>
	);
}
