import React, {Fragment} from 'react';

import UserProfileToggler from './user-profile-toggler.js';

import "../../resources/styles/dashboard.scss";
import "bootstrap/dist/css/bootstrap.min.css";

const UserTopNav = props => {
    return(
        <Fragment>
            <div className="top-nav-wrapper" style={{zIndex: "99"}}>
                <UserProfileToggler />
            </div>

        </Fragment>
    )
}

export default UserTopNav;