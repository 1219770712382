export const Default = {
	_id: "",
	PatientId: "",
	CaseId: "",
	BusinessId: "",
	Personal: {
		Salutation: "",
		FirstName: "",
		LastName: "",
		Email: "",
		Address: {
			Country: "Canada",
			City: "",
			Province: "",
			StreetAddress1: "",
			StreetAddress2: "",
			PostalCode: "",
		},
		HomePhone: "",
		HomePhoneExt: "",
		CellPhone: "",
		CellPhoneExt: "",
		WorkPhone: "",
		WorkPhoneExt: "",
		DateOfBirth: "",
		Gender: "",
		Handedness: 0,
		WeightLbs: 0,
		WeightKgs: 0,
		HeightFt: 0,
		HeightInch: 0,
		HeightMeter: 0,
		PersonalHealthNumber: "",
		Occupation: "",
		ReferredBy: "",
	},
	Medical: {
		EmergencyFirstName: "",
		EmergencyLastName: "",
		EmergencyPhone: "",
		EmergencyPhoneExt: "",
		EmergencyRelation: "",
		EmergencyEmail: "",
		EmergencyAddress: {
			Country: "",
			City: "",
			Province: "",
			StreetAddress1: "",
			StreetAddress2: "",
			PostalCode: "",
		},
		PhysicianFirstName: "",
		PhysicianLastName: "",
		PhysicianPhone: "",
		PhysicianPhoneExt: "",
		PhysicianClinic: "",
		PhysicianEmail: "",
		PhysicianFax: "",
		Injury: "",
		PhysicianAddress: {
			Country: "Canada",
			City: "",
			Province: "",
			StreetAddress1: "",
			StreetAddress2: "",
			PostalCode: "",
		},
	},
	WorkRelatedInjury: {
		ExistsWorkRelatedInjury: false,
		InjuryDate: null,
		InjuryType: "",
		BodyParts: [],
		WcbClaim: "",
		EmployerCompanyName: "",
		EmployerRepresentativeFirstName: "",
		EmployerRepresentativeLastName: "",
		EmployerPhone: "",
		EmployerPhoneExt: "",
		EmployerAddress: {
			Country: "",
			City: "",
			Province: "",
			StreetAddress1: "",
			StreetAddress2: "",
			PostalCode: "",
		},
		AllowEmployerContact: false,
	},
	MotorVehicleInjury: {
		ExistsMotorVehicleInjury: false,
		AccidentDate: null,
		InsuranceCompanyName: "",
		Claim: "",
		Policy: "",
		OwnPolicy: false,
		AdjusterName: "",
		Phone: "",
		PhoneExt: "",
		Fax: "",
		NameOnPolicy: "",
		LegalRepresentative: "",
	},
	ExtendedHealth: {
		ExistsExtendedHealth: false,
		InsuranceCompany: "",
		GroupNumber: "",
		MemberNumber: "",
		PrimaryCardHolderName: "",
		RelationToCardHolder: "",
	},
	ScreeningQuestions: {
		FrontFaceRight: -1,
		FrontFaceLeft: -1,
		FrontHead: -1,
		FrontTrapsRight: -1,
		FrontTrapsLeft: -1,
		FrontNeck: -1,
		FrontShoulderRight: -1,
		FrontShoulderLeft: -1,
		FrontChestRight: -1,
		FrontChestLeft: -1,
		FrontUpperAbdominal: -1,
		FrontAbsRight: -1,
		FrontAbsLeft: -1,
		FrontLowerAbdominal: -1,
		FrontHipRight: -1,
		FrontHipLeft: -1,
		FrontUpperLegRight: -1,
		FrontUpperLegLeft: -1,
		FrontKneeJointRight: -1,
		FrontKneeJointLeft: -1,
		FrontLowerLegRight: -1,
		FrontLowerLegLeft: -1,
		FrontAnkleRight: -1,
		FrontAnkleLeft: -1,
		FrontFootRight: -1,
		FrontFootLeft: -1,
		FrontBicepsRight: -1,
		FrontBicepsLeft: -1,
		FrontElbowJointRight: -1,
		FrontElbowJointLeft: -1,
		FrontForeArmsRight: -1,
		FrontForeArmsLeft: -1,
		FrontWristJointRight: -1,
		FrontWristJointLeft: -1,
		FrontHandRight: -1,
		FrontHandLeft: -1,
		BackHead: -1,
		BackNeck: -1,
		BackTrapsLeft: -1,
		BackTrapsRight: -1,
		BackUpperBackLeft: -1,
		BackUpperBackRight: -1,
		BackMiddleBack: -1,
		BackLowerBack: -1,
		BackSijointLeft: -1,
		BackSijointRight: -1,
		BackSacrum: -1,
		BackGlutesLeft: -1,
		BackGlutesRight: -1,
		BackUpperLegLeft: -1,
		BackUpperLegRight: -1,
		BackHamstringLeft: -1,
		BackHamstringRight: -1,
		BackKneeJointLeft: -1,
		BackKneeJointRight: -1,
		BackUpperCalvesLeft: -1,
		BackUpperCalvesRight: -1,
		BackCalvesLeft: -1,
		BackCalvesRight: -1,
		BackHeelLeft: -1,
		BackHeelRight: -1,
		BackShoulderLeft: -1,
		BackShoulderRight: -1,
		BackTricepsLeft: -1,
		BackTricepsRight: -1,
		BackElbowJointLeft: -1,
		BackElbowJointRight: -1,
		BackForeArmsLeft: -1,
		BackForeArmsRight: -1,
		BackWristJointLeft: -1,
		BackWristJointRight: -1,
		BackHandLeft: -1,
		BackHandRight: -1,

		NeckPainStiffness: false,
		ArthritisNeck: false,
		Headache: false,
		JawPain: false,
		SkinCondition: false,
		ShoulderPain: false,
		ElbowWristHandPain: false,
		HipKneeAnklePain: false,
		Diabetes: false,
		Anemia: false,
		MidBackPain: false,
		MuscleCrampSpasm: false,
		CarpalTunnel: false,
		SwellingFeetHands: false,
		ArthritisLimb: false,
		LowBackPain: false,
		TailbonePain: false,
		NumbnessLegsFeet: false,
		NumbnessArmsHands: false,
		Dizziness: false,
		Allergies: [],
		AllergyNotes: "",
		Medications: [],
		MedicationNotes: "",
		MedicalDevices: [],
		MedicalDeviceNotes: "",
		OtherNotes: "",
	},
	PreviousTreatments: {
		Medication: false,
		Physiotherapy: false,
		Chiropractic: false,
		Massage: false,
		Acupuncture: false,
		Naturopathic: false,
		OtherTreatment: "",
		JointInjection: false,
		Steroid: false,
		Anesthetic: false,
		TriggerPoint: false,
		OtherInjection: "",
		ImprovedSymptoms: false,
		NoChangeSymptoms: false,
		Symptoms: false,
		OtherReactions: "",
		Surgeries: [],
		HadHospitalised: false,
		HospitalName: "",
		XRay: false,
		Mri: false,
		CtScan: false,
		UltraSound: false,
		BoneScan: false,
		BloodTest: false,
		Emg: false,
		Ekg: false,
		Endoscopy: false,
		Biopsy: false,
		UrineTest: false,
		OtherTest: "",
		OverallHealth: -1,
	},

	HealthReport: {
		GeneralDiabetesPrev: false,
		GeneralDiabetesPres: false,
		GeneralHypoglycemiaPrev: false,
		GeneralHypoglycemiaPres: false,
		GeneralStressPrev: false,
		GeneralStressPres: false,
		GeneralEpilepsyPrev: false,
		GeneralEpilepsyPres: false,
		GeneralSkinConditionPrev: false,
		GeneralSkinConditionPres: false,
		GeneralAlcoholismPrev: false,
		GeneralAlcoholismPres: false,
		GeneralHighCholesterolPrev: false,
		GeneralHighCholesterolPres: false,
		GeneralParkinsonPrev: false,
		GeneralParkinsonPres: false,
		GeneralHeartPrev: false,
		GeneralHeartPres: false,
		GeneralCancerPrev: false,
		GeneralCancerPres: false,
		GeneralOsteoarthritisPrev: false,
		GeneralOsteoarthritisPres: false,
		GeneralUlcersPrev: false,
		GeneralUlcersPres: false,
		GeneralAnemiaPrev: false,
		GeneralAnemiaPres: false,
		GeneralMultipleSclerosisPrev: false,
		GeneralMultipleSclerosisPres: false,
		GeneralOsteoporosisPrev: false,
		GeneralOsteoporosisPres: false,
		GeneralHypertensionPrev: false,
		GeneralHypertensionPres: false,
		GeneralRespiratoryIllnessPrev: false,
		GeneralRespiratoryIllnessPres: false,
		ImmuneSystemHivPrev: false,
		ImmuneSystemHivPres: false,
		ImmuneSystemPneumoniaPrev: false,
		ImmuneSystemPneumoniaPres: false,
		ImmuneSystemAllergiesPrev: false,
		ImmuneSystemAllergiesPres: false,
		ImmuneSystemColdPrev: false,
		ImmuneSystemColdPres: false,
		ImmuneSystemRheumatoidPrev: false,
		ImmuneSystemRheumatoidPres: false,
		ImmuneSystemVenerealPrev: false,
		ImmuneSystemVenerealPres: false,
		ImmuneSystemTuberculosisPrev: false,
		ImmuneSystemTuberculosisPres: false,
		ImmuneSystemRheumaticPrev: false,
		ImmuneSystemRheumaticPres: false,
		GastrointestinalGallBladderPrev: false,
		GastrointestinalGallBladderPres: false,
		GastrointestinalColitisPrev: false,
		GastrointestinalColitisPres: false,
		GastrointestinalStoolBloodPrev: false,
		GastrointestinalStoolBloodPres: false,
		GastrointestinalConstipationPrev: false,
		GastrointestinalConstipationPres: false,
		GastrointestinalStomachBurnPrev: false,
		GastrointestinalStomachBurnPres: false,
		GastrointestinalHeartBurnPrev: false,
		GastrointestinalHeartBurnPres: false,
		GastrointestinalExcessiveThirstPrev: false,
		GastrointestinalExcessiveThirstPres: false,
		GastrointestinalVomitingPrev: false,
		GastrointestinalVomitingPres: false,
		GastrointestinalRecentWeightGainPrev: false,
		GastrointestinalRecentWeightGainPres: false,
		GastrointestinalHepatitisPrev: false,
		GastrointestinalHepatitisPres: false,
		GastrointestinalStoolMucusPrev: false,
		GastrointestinalStoolMucusPres: false,
		GastrointestinalDiarreaPrev: false,
		GastrointestinalDiarreaPres: false,
		GastrointestinalStomachPainPrev: false,
		GastrointestinalStomachPainPres: false,
		GastrointestinalBurpingPrev: false,
		GastrointestinalBurpingPres: false,
		GastrointestinalDistressGreasyFoodPrev: false,
		GastrointestinalDistressGreasyFoodPres: false,
		GastrointestinalNauseaPrev: false,
		GastrointestinalNauseaPres: false,
		GastrointestinalHerniaPrev: false,
		GastrointestinalHerniaPres: false,
		GastrointestinalMetalicTastePrev: false,
		GastrointestinalMetalicTastePres: false,
		GastrointestinalRecentWeightLossPrev: false,
		GastrointestinalRecentWeightLossPres: false,
		CardiovascularIrregularHeartbeatPrev: false,
		CardiovascularIrregularHeartbeatPres: false,
		CardiovascularStrokePrev: false,
		CardiovascularStrokePres: false,
		CardiovascularLowBloodPressurePrev: false,
		CardiovascularLowBloodPressurePres: false,
		CardiovascularHighBloodPressurePrev: false,
		CardiovascularHighBloodPressurePres: false,
		CardiovascularPalpitationsPrev: false,
		CardiovascularPalpitationsPres: false,
		CardiovascularAnkleSwellPrev: false,
		CardiovascularAnkleSwellPres: false,
		CardiovascularChestPainPrev: false,
		CardiovascularChestPainPres: false,
		CardiovascularShortnessBreathPrev: false,
		CardiovascularShortnessBreathPres: false,
		NeuromusculoskeletalSystemsHeadNeckHeadachePrev: false,
		NeuromusculoskeletalSystemsHeadNeckHeadachePres: false,
		NeuromusculoskeletalSystemsHeadNeckNeckPainPrev: false,
		NeuromusculoskeletalSystemsHeadNeckNeckPainPres: false,
		NeuromusculoskeletalSystemsHeadNeckPinchedNervePrev: false,
		NeuromusculoskeletalSystemsHeadNeckPinchedNervePres: false,
		NeuromusculoskeletalSystemsHeadNeckJawPainPrev: false,
		NeuromusculoskeletalSystemsHeadNeckJawPainPres: false,
		NeuromusculoskeletalSystemsHeadNeckArthritisNeckPrev: false,
		NeuromusculoskeletalSystemsHeadNeckArthritisNeckPres: false,
		NeuromusculoskeletalSystemsShoulderShoulderPainPrev: false,
		NeuromusculoskeletalSystemsShoulderShoulderPainPres: false,
		NeuromusculoskeletalSystemsShoulderRaiseArmPrev: false,
		NeuromusculoskeletalSystemsShoulderRaiseArmPres: false,
		NeuromusculoskeletalSystemsShoulderArthritisPrev: false,
		NeuromusculoskeletalSystemsShoulderArthritisPres: false,
		NeuromusculoskeletalSystemsShoulderBursitisPrev: false,
		NeuromusculoskeletalSystemsShoulderBursitisPres: false,
		NeuromusculoskeletalSystemsShoulderPoppingPrev: false,
		NeuromusculoskeletalSystemsShoulderPoppingPres: false,
		NeuromusculoskeletalSystemsMidBackMidBackPainPrev: false,
		NeuromusculoskeletalSystemsMidBackMidBackPainPres: false,
		NeuromusculoskeletalSystemsMidBackPainBetweenShouldersPrev: false,
		NeuromusculoskeletalSystemsMidBackPainBetweenShouldersPres: false,
		NeuromusculoskeletalSystemsMidBackMuscleSpasmsPrev: false,
		NeuromusculoskeletalSystemsMidBackMuscleSpasmsPres: false,
		NeuromusculoskeletalSystemsMidBackStabbingPainPrev: false,
		NeuromusculoskeletalSystemsMidBackStabbingPainPres: false,
		NeuromusculoskeletalSystemsLowBackLowBackPainPrev: false,
		NeuromusculoskeletalSystemsLowBackLowBackPainPres: false,
		NeuromusculoskeletalSystemsLowBackLowBackStiffnessPrev: false,
		NeuromusculoskeletalSystemsLowBackLowBackStiffnessPres: false,
		NeuromusculoskeletalSystemsLowBackTailbonePainPrev: false,
		NeuromusculoskeletalSystemsLowBackTailbonePainPres: false,
		NeuromusculoskeletalSystemsLowBackMuscleSpasmsPrev: false,
		NeuromusculoskeletalSystemsLowBackMuscleSpasmsPres: false,
		NeuromusculoskeletalSystemsLowBackPinchedNervePrev: false,
		NeuromusculoskeletalSystemsLowBackPinchedNervePres: false,
		NeuromusculoskeletalSystemsArmsHandsElbowArmPainPrev: false,
		NeuromusculoskeletalSystemsArmsHandsElbowArmPainPres: false,
		NeuromusculoskeletalSystemsArmsHandsForearmPainPrev: false,
		NeuromusculoskeletalSystemsArmsHandsForearmPainPres: false,
		NeuromusculoskeletalSystemsArmsHandsHandsFingersPainPrev: false,
		NeuromusculoskeletalSystemsArmsHandsHandsFingersPainPres: false,
		NeuromusculoskeletalSystemsArmsHandsArthritisSwollenHandsPrev: false,
		NeuromusculoskeletalSystemsArmsHandsArthritisSwollenHandsPres: false,
		NeuromusculoskeletalSystemsArmsHandsColdHandsPrev: false,
		NeuromusculoskeletalSystemsArmsHandsColdHandsPres: false,
		NeuromusculoskeletalSystemsArmsHandsCarpalTunnelPrev: false,
		NeuromusculoskeletalSystemsArmsHandsCarpalTunnelPres: false,
		NeuromusculoskeletalSystemsArmsHandsHandsTinglingPrev: false,
		NeuromusculoskeletalSystemsArmsHandsHandsTinglingPres: false,
		NeuromusculoskeletalSystemsArmsHandsLossOfGripPrev: false,
		NeuromusculoskeletalSystemsArmsHandsLossOfGripPres: false,
		NeuromusculoskeletalSystemsHipsLegsFeetButtockPainPrev: false,
		NeuromusculoskeletalSystemsHipsLegsFeetButtockPainPres: false,
		NeuromusculoskeletalSystemsHipsLegsFeetHipKneePainPrev: false,
		NeuromusculoskeletalSystemsHipsLegsFeetHipKneePainPres: false,
		NeuromusculoskeletalSystemsHipsLegsFeetColdFeetPrev: false,
		NeuromusculoskeletalSystemsHipsLegsFeetColdFeetPres: false,
		NeuromusculoskeletalSystemsHipsLegsFeetTinglingFeetPrev: false,
		NeuromusculoskeletalSystemsHipsLegsFeetTinglingFeetPres: false,
		NeuromusculoskeletalSystemsHipsLegsFeetArthritisPrev: false,
		NeuromusculoskeletalSystemsHipsLegsFeetArthritisPres: false,
		NeuromusculoskeletalSystemsHipsLegsFeetSprainStrainPrev: false,
		NeuromusculoskeletalSystemsHipsLegsFeetSprainStrainPres: false,
		NeuromusculoskeletalSystemsHipsLegsFeetLegPainPrev: false,
		NeuromusculoskeletalSystemsHipsLegsFeetLegPainPres: false,
		NeuromusculoskeletalSystemsHipsLegsFeetTinglingLegPrev: false,
		NeuromusculoskeletalSystemsHipsLegsFeetTinglingLegPres: false,
		NeuromusculoskeletalSystemsHipsLegsFeetSwollenAnkleFeetPrev: false,
		NeuromusculoskeletalSystemsHipsLegsFeetSwollenAnkleFeetPres: false,
		NeuromusculoskeletalSystemsHipsLegsFeetAnkleFeetPainPrev: false,
		NeuromusculoskeletalSystemsHipsLegsFeetAnkleFeetPainPres: false,
		NeuromusculoskeletalSystemsHipsLegsFeetLossOfLegStrengthPrev: false,
		NeuromusculoskeletalSystemsHipsLegsFeetLossOfLegStrengthPres: false,
		NeuromusculoskeletalSystemsHipsLegsFeetMuscleCrampPrev: false,
		NeuromusculoskeletalSystemsHipsLegsFeetMuscleCrampPres: false,
		OtherSystemsNervousSystemDizzinessPrev: false,
		OtherSystemsNervousSystemDizzinessPres: false,
		OtherSystemsNervousSystemFaintingPrev: false,
		OtherSystemsNervousSystemFaintingPres: false,
		OtherSystemsNervousSystemDiscoordinationPrev: false,
		OtherSystemsNervousSystemDiscoordinationPres: false,
		OtherSystemsNervousSystemMemoryLossPrev: false,
		OtherSystemsNervousSystemMemoryLossPres: false,
		OtherSystemsRespiratorySystemChronicCoughPrev: false,
		OtherSystemsRespiratorySystemChronicCoughPres: false,
		OtherSystemsRespiratorySystemSpittingBloodPrev: false,
		OtherSystemsRespiratorySystemSpittingBloodPres: false,
		OtherSystemsRespiratorySystemDifficultyBreathingPrev: false,
		OtherSystemsRespiratorySystemDifficultyBreathingPres: false,
		OtherSystemsRespiratorySystemShortnessBreathingPrev: false,
		OtherSystemsRespiratorySystemShortnessBreathingPres: false,
		OtherSystemsRespiratorySystemAsthmaPrev: false,
		OtherSystemsRespiratorySystemAsthmaPres: false,
		OtherSystemsRespiratorySystemPhlegmPrev: false,
		OtherSystemsRespiratorySystemPhlegmPres: false,
		OtherSystemsRespiratorySystemEmphysemaPrev: false,
		OtherSystemsRespiratorySystemEmphysemaPres: false,
		OtherSystemsRespiratorySystemCopdPrev: false,
		OtherSystemsRespiratorySystemCopdPres: false,
		OtherSystemsBloodSugarIrritableBeforeMealsPrev: false,
		OtherSystemsBloodSugarIrritableBeforeMealsPres: false,
		OtherSystemsBloodSugarPalpitationsMealsPrev: false,
		OtherSystemsBloodSugarPalpitationsMealsPres: false,
		OtherSystemsBloodSugarHungryShakyPrev: false,
		OtherSystemsBloodSugarHungryShakyPres: false,
		OtherSystemsBloodSugarAwakenSleepPrev: false,
		OtherSystemsBloodSugarAwakenSleepPres: false,
		OtherSystemsEyeEarNoseThroatVisionPrev: false,
		OtherSystemsEyeEarNoseThroatVisionPres: false,
		OtherSystemsEyeEarNoseThroatHoarsenessPrev: false,
		OtherSystemsEyeEarNoseThroatHoarsenessPres: false,
		OtherSystemsEyeEarNoseThroatNoseBleedingPrev: false,
		OtherSystemsEyeEarNoseThroatNoseBleedingPres: false,
		OtherSystemsEyeEarNoseThroatEarPainPrev: false,
		OtherSystemsEyeEarNoseThroatEarPainPres: false,
		OtherSystemsEyeEarNoseThroatDentalPrev: false,
		OtherSystemsEyeEarNoseThroatDentalPres: false,
		OtherSystemsEyeEarNoseThroatHearingLossPrev: false,
		OtherSystemsEyeEarNoseThroatHearingLossPres: false,
		OtherSystemsEyeEarNoseThroatSoreThroatPrev: false,
		OtherSystemsEyeEarNoseThroatSoreThroatPres: false,
		OtherSystemsEyeEarNoseThroatEarRingingPrev: false,
		OtherSystemsEyeEarNoseThroatEarRingingPres: false,
		OtherSystemsUrinaryTractBloodUrinePrev: false,
		OtherSystemsUrinaryTractBloodUrinePres: false,
		OtherSystemsUrinaryTractBladderInfectionPrev: false,
		OtherSystemsUrinaryTractBladderInfectionPres: false,
		OtherSystemsUrinaryTractUrinationControlPrev: false,
		OtherSystemsUrinaryTractUrinationControlPres: false,
		OtherSystemsUrinaryTractKidneyStonePrev: false,
		OtherSystemsUrinaryTractKidneyStonePres: false,
		OtherSystemsUrinaryTractUrinationPainPrev: false,
		OtherSystemsUrinaryTractUrinationPainPres: false,
	},
	FunctionalQuestions: {
		Answers: [-1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1],
		OverallPain: -1,
		TherapistName: "",
	},
	CreatedAt: "",
	CreatedAtOffset: 0,
	LastModified: "",
	LastModifiedOffset: 0,
};

export const DefaultRequired = {
	Personal: {
		Salutation: false,
		FirstName: true,
		LastName: true,
		Email: true,
		Address: {
			Country: true,
			City: false,
			Province: false,
			StreetAddress1: false,
			StreetAddress2: false,
			PostalCode: false,
		},
		HomePhone: false,
		HomePhoneExt: false,
		CellPhone: false,
		CellPhoneExt: false,
		WorkPhone: false,
		WorkPhoneExt: false,
		DateOfBirth: true,
		Gender: true,
		Handedness: false,
		WeightLbs: false,
		WeightKgs: false,
		HeightFt: false,
		HeightInch: false,
		HeightMeter: false,
		PersonalHealthNumber: false,
		Occupation: false,
		ReferredBy: false,
	},
	Medical: {
		EmergencyFirstName: true,
		EmergencyLastName: false,
		EmergencyPhone: true,
		EmergencyPhoneExt: false,
		EmergencyRelation: true,
		EmergencyEmail: false,
		EmergencyAddress: {
			Country: false,
			City: false,
			Province: false,
			StreetAddress1: false,
			StreetAddress2: false,
			PostalCode: false,
		},
		PhysicianFirstName: false,
		PhysicianLastName: false,
		PhysicianPhone: false,
		PhysicianPhoneExt: false,
		PhysicianClinic: false,
		PhysicianEmail: false,
		PhysicianFax: false,
		Injury: false,
		PhysicianAddress: {
			Country: false,
			City: false,
			Province: false,
			StreetAddress1: false,
			StreetAddress2: false,
			PostalCode: false,
		},
	},
	WorkRelatedInjury: {
		ExistsWorkRelatedInjury: true,
		InjuryDate: true,
		InjuryType: true,
		BodyParts: true,
		WcbClaim: false,
		EmployerCompanyName: true,
		EmployerRepresentativeFirstName: true,
		EmployerRepresentativeLastName: false,
		EmployerPhone: true,
		EmployerPhoneExt: false,
		EmployerAddress: {
			Country: true,
			City: false,
			Province: false,
			StreetAddress1: false,
			StreetAddress2: false,
			PostalCode: false,
		},
		AllowEmployerContact: false,
	},
	MotorVehicleInjury: {
		ExistsMotorVehicleInjury: true,
		AccidentDate: true,
		InsuranceCompanyName: true,
		Claim: false,
		Policy: false,
		OwnPolicy: false,
		AdjusterName: false,
		Phone: false,
		PhoneExt: false,
		Fax: false,
		NameOnPolicy: false,
		LegalRepresentative: false,
	},
	ExtendedHealth: {
		ExistsExtendedHealth: true,
		InsuranceCompany: true,
		GroupNumber: false,
		MemberNumber: false,
		PrimaryCardHolderName: true,
		RelationToCardHolder: true,
	},
	ScreeningQuestions: {
		HasAllergies: true,
		HasMedications: true,
		HasMedicalDevices: true,
	},
	PreviousTreatments: {
		HadSurgeries: true,
		OverallHealth: true,
	},
	HealthReport: {},
	FunctionalQuestions: {
		Answers: [true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true],
		OverallPain: true,
	},
};

export const DefaultValidated = {
	Personal: {
		Salutation: false,
		FirstName: false,
		LastName: false,
		Email: false,
		Address: {
			Country: false,
			City: false,
			Province: false,
			StreetAddress1: false,
			StreetAddress2: false,
			PostalCode: false,
		},
		HomePhone: false,
		HomePhoneExt: false,
		CellPhone: false,
		CellPhoneExt: false,
		WorkPhone: false,
		WorkPhoneExt: false,
		DateOfBirth: false,
		Gender: false,
		Handedness: false,
		WeightLbs: false,
		WeightKgs: false,
		HeightFt: false,
		HeightInch: false,
		HeightMeter: false,
		PersonalHealthNumber: false,
		Occupation: false,
		ReferredBy: false,
	},
	Medical: {
		EmergencyFirstName: false,
		EmergencyLastName: false,
		EmergencyPhone: false,
		EmergencyPhoneExt: false,
		EmergencyRelation: false,
		EmergencyEmail: false,
		EmergencyAddress: {
			Country: false,
			City: false,
			Province: false,
			StreetAddress1: false,
			StreetAddress2: false,
			PostalCode: false,
		},
		PhysicianFirstName: false,
		PhysicianLastName: false,
		PhysicianPhone: false,
		PhysicianPhoneExt: false,
		PhysicianClinic: false,
		PhysicianEmail: false,
		PhysicianFax: false,
		Injury: false,
		PhysicianAddress: {
			Country: true,
			City: false,
			Province: false,
			StreetAddress1: false,
			StreetAddress2: false,
			PostalCode: false,
		},
	},
	WorkRelatedInjury: {
		ExistsWorkRelatedInjury: false,
		InjuryDate: false,
		InjuryType: false,
		BodyParts: false,
		WcbClaim: false,
		EmployerCompanyName: false,
		EmployerRepresentativeFirstName: false,
		EmployerRepresentativeLastName: false,
		EmployerPhone: false,
		EmployerPhoneExt: false,
		EmployerAddress: {
			Country: false,
			City: false,
			Province: false,
			StreetAddress1: false,
			StreetAddress2: false,
			PostalCode: false,
		},
		AllowEmployerContact: false,
	},
	MotorVehicleInjury: {
		ExistsMotorVehicleInjury: false,
		AccidentDate: false,
		InsuranceCompanyName: false,
		Claim: false,
		Policy: false,
		OwnPolicy: false,
		AdjusterName: false,
		Phone: false,
		PhoneExt: false,
		Fax: false,
		NameOnPolicy: false,
		LegalRepresentative: false,
	},
	ExtendedHealth: {
		ExistsExtendedHealth: false,
		InsuranceCompany: false,
		GroupNumber: false,
		MemberNumber: false,
		PrimaryCardHolderName: false,
		RelationToCardHolder: false,
	},
	ScreeningQuestions: {
		HasAllergies: false,
		HasMedications: false,
		HasMedicalDevices: false,
	},
	PreviousTreatments: {
		HadSurgeries: false,
		OverallHealth: false,
	},
	HealthReport: {},
	FunctionalQuestions: {
		Answers: [false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false],
		OverallPain: false,
	},
};

export const DefaultIsValid = {
	Personal: {
		Salutation: false,
		FirstName: false,
		LastName: false,
		Email: false,
		Address: {
			Country: false,
			City: false,
			Province: false,
			StreetAddress1: false,
			StreetAddress2: false,
			PostalCode: false,
		},
		HomePhone: false,
		HomePhoneExt: false,
		CellPhone: false,
		CellPhoneExt: false,
		WorkPhone: false,
		WorkPhoneExt: false,
		DateOfBirth: false,
		Gender: false,
		Handedness: false,
		WeightLbs: false,
		WeightKgs: false,
		HeightFt: false,
		HeightInch: false,
		HeightMeter: false,
		PersonalHealthNumber: false,
		Occupation: false,
		ReferredBy: false,
	},
	Medical: {
		EmergencyFirstName: false,
		EmergencyLastName: false,
		EmergencyPhone: false,
		EmergencyPhoneExt: false,
		EmergencyRelation: false,
		EmergencyEmail: false,
		EmergencyAddress: {
			Country: false,
			City: false,
			Province: false,
			StreetAddress1: false,
			StreetAddress2: false,
			PostalCode: false,
		},
		PhysicianFirstName: false,
		PhysicianLastName: false,
		PhysicianPhone: false,
		PhysicianPhoneExt: false,
		PhysicianClinic: false,
		PhysicianEmail: false,
		PhysicianFax: false,
		Injury: false,
		PhysicianAddress: {
			Country: true,
			City: false,
			Province: false,
			StreetAddress1: false,
			StreetAddress2: false,
			PostalCode: false,
		},
	},
	WorkRelatedInjury: {
		ExistsWorkRelatedInjury: false,
		InjuryDate: false,
		InjuryType: false,
		BodyParts: false,
		WcbClaim: false,
		EmployerCompanyName: false,
		EmployerRepresentativeFirstName: false,
		EmployerRepresentativeLastName: false,
		EmployerPhone: false,
		EmployerPhoneExt: false,
		EmployerAddress: {
			Country: false,
			City: false,
			Province: false,
			StreetAddress1: false,
			StreetAddress2: false,
			PostalCode: false,
		},
		AllowEmployerContact: false,
	},
	MotorVehicleInjury: {
		ExistsMotorVehicleInjury: false,
		AccidentDate: false,
		InsuranceCompanyName: false,
		Claim: false,
		Policy: false,
		OwnPolicy: false,
		AdjusterName: false,
		Phone: false,
		PhoneExt: false,
		Fax: false,
		NameOnPolicy: false,
		LegalRepresentative: false,
	},
	ExtendedHealth: {
		ExistsExtendedHealth: false,
		InsuranceCompany: false,
		GroupNumber: false,
		MemberNumber: false,
		PrimaryCardHolderName: false,
		RelationToCardHolder: false,
	},
	ScreeningQuestions: {
		Allergies: false,
		AllergyNotes: false,
		Medications: false,
		MedicationNotes: false,
		MedicalDevices: false,
		MedicalDeviceNotes: false,
	},
	PreviousTreatments: {
		Surgeries: false,
		OverallHealth: false,
	},
	HealthReport: {},
	FunctionalQuestions: {
		Answers: [false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false],
		OverallPain: false,
	},
};
