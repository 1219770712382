import React from 'react';
import {Container} from 'react-bootstrap';

import NavBar from "../components/navbar.component.js";
import Footer from "../components/footer.component.js";

// Custom page meta with react-helmet
import MetaDecorator from "../components/metaDecorator.js";

export default function Privacy() {
    return(
        <>
            <MetaDecorator
                description="Privacy - Bodiometer smart biomechanic scanning and analytics for consumers and clinical professionals."
                title="Bodiometer - Privacy"
                url="https://www.bodiometer.com/privacy"
            />
        <NavBar />
            <Container className="privacy-wrapper">
                <h1 style={{textAlign: "center"}}>PRIVACY POLICY – BODIOMETER</h1>
                <h3>LAST UPDATED: AUGUST 11, 2020</h3>
                <p>
                    At   AnimationLeader   Technology   Inc.   ("<b>Bodiometer</b>")   we   are   committed   to   maintaining   the privacy, confidentiality and security of your personal information.  This policy (“Privacy Policy”) describes the personal information that Bodiometer, the bodiometer websites, the bodiometer desktop applications, and the bodiometer mobile applications (the “Bodiometer App”) collect from or about you, why we collect personal information, how we use the information and to whom we disclose  that  information when you  use the  Bodiometer  App. This Privacy Policy applies to the identifiable information of all organizations and individuals using the BodiometerApp (and its related services). 
                </p>

                <p>
                    BY USING THE BODIOMETER APP (AND ITS RELATED SERVICES), YOU ARE CONSENTING TO THE   COLLECTION,   USE   AND   DISCLOSURE   OF   YOUR   PERSONAL   INFORMATION   AS DESCRIBED IN THIS PRIVACY POLICY AND, WHERE YOUR INTERACT WITH THE PERSONAL INFORMATION   OF   OTHERS   IN   THE   BODIOMETER   APP   (SUCH   AS   THE   PERSONAL INFORMATION OF A FAMILY MEMBER OR PATIENT), YOU REPRESENT THAT YOU HAVE THEIR CONSENT TO DO SO. IF YOU DO NOT CONSENT OR DO NOT HAVE SUCH CONSENT, PLEASE DO NOT USE THE BODIOMETER APP (OR ANY OF ITS RELATED SERVICES).
                </p>

                <h3>
                    What is Personal Information?
                </h3>

                <p>
                    For   the   purposes   of   this   Privacy   Policy,   personal   information   is   any   information   about   an identifiable individual, other than the person's business title or business contact information when used or disclosed for the purpose of business communications. 
                </p>

                <h3>
                    What Personal Information do we collect?
                </h3>

                <p>
                    We collect and maintain different types of personal information in respect of the individuals with whom we interact.  This includes:
                </p>

                <p className="left-margin">
                    &#10063; personal contact and identification information, such as your name, address, telephone number, date of birth, gender, e-mail address, and unique lifetime identifier number (ULI);
                </p>

                <p className="left-margin">
                    &#10063; business   contact   and   identification   information,   including   name,   address, telephone   number,   e-mail   address,   and   unique   professional   and/or organizational identifier;
                </p>

                <p className="left-margin">
                    &#10063; information concerning the use, operation and development of the Bodiometer App (and any of its related services) such as your photo and biomechanics measurements; and
                </p>

                <p className="left-margin">
                    &#10063; relationship   information,   including   information   related   to   your   agreements, consents, preferences, feed-back and information requested by or provided to you.
                </p>

                <p>
                    As a general rule, Bodiometer collects personal information directly from you. In most circumstances where the personal information that we collect about you is held by a third party, we will obtain your permission before we seek out this information from such sources (such permission may be given directly by you, or implied from your actions).
                </p>

                <p>
                    From time to time, we may utilize the services of third parties in our business and may also receive personal information collected by those third parties in the course of the performance of their services for us or otherwise. Where this is the case, we will take reasonable steps to ensure that such third parties have represented to us that they have the right to disclose your personal information to us.
                </p>

                <p>
                    Where permitted or required by applicable law or regulatory requirements, we may collect information about you without your knowledge or consent.
                </p>

                <h3>
                    Why do we collect Personal Information?
                </h3>

                <p>
                    Bodiometer collects personal information to enable us to manage, maintain, and develop our business and operations, including:
                </p>

                <p className="left-margin">
                    &#10063; to operate the Bodiometer App (or any of its related services), including the support, maintenance and development of same; 
                </p>

                <p className="left-margin">
                    &#10063; to establish, maintain and manage our relationship with you so that we may provide you with, or receive from you, the products and services that havebeen requested;
                </p>

                <p className="left-margin">
                    &#10063; to be able to review the products and services that we provide to you so that we may understand your requirements for our products and services and so that we may work to improve our products and services;
                </p>

                <p className="left-margin">
                    &#10063; to be able to review the products and services that we obtain from you so that we may work with you and so that you may understand our requirements for such products and services;
                </p>

                <p className="left-margin">
                    &#10063; to   be   able   to   comply   with   your   requests   (for   example,   if   you   prefer   to   becontacted at a business or personal email address or telephone number and advise   us   of   your   preference,   we   will   use   this   information   to   contact   you through those means);
                </p>

                <p className="left-margin">
                    &#10063; to protect us against error, fraud, and inappropriate access;
                </p>

                <p className="left-margin">
                    &#10063; to enable us to comply with applicable law or regulatory requirements; and
                </p>

                <p className="left-margin">
                    &#10063; any other reasonable purpose to which you consent.
                </p>

                <h3>
                    How does Bodiometer App use Personal Information?
                </h3>

                <p>
                    You may use the Bodiometer App (or any of its related services) to exchange your data with your practitioner (including, their office or clinic), other practitioners or your patient. You are responsible for and control who you authorize to access the personal information managed by you. Bodiometer is not a health care provider.
                </p>

                <p>
                    The Bodiometer App (and its related services) enables secure data transfer between patients and their practitioners and other practitioners. These practitioners are regulated and licensed by various public or regulatory bodies. Should you have any questions about your practitioner please direct such questions directly to them or the applicable regulatory body. For example, the   relationship   between   you   and   your   chiropractor   is   a   practitioner-patient   relationship. Bodiometer is not a party to that relationship. Each practitioner is independent of Bodiometer and is solely responsible for any services they provide to you.
                </p>

                <p>
                    A practitioner may establish a Bodiometer account for their office or clinic; this account may be accessible by such practitioner, the other practitioners who are part of such office or clinic, and their   staff.       When   you   communicate   with   such   an   account,   you   are   consenting   to   your information being accessible by each of them.
                </p>

                <p>
                    We use the information that we collect in a variety of ways in order to provide better service and   operate   our   business,   including   improving   Smart   Features/Artificial   Intelligence.   When reviewing   your   case,   practitioners   have   the   opportunity   to   utilize   algorithms   powered   by artificial intelligence to support their clinical decisions for an enhanced level of care. In order for   Bodiometer   App’s   algorithms   to   become   “smarter”   over   time   to   fulfill   this   mission   of improved care, they must be continuously trained. To support this intrinsic need, every time a
                    practitioner uses one of our artificial intelligence-powered algorithms, the Bodiometer App has the opportunity to “learn” and become better for you and other patients around the world. Any images   and  data   used   for  these  algorithms  have   been   de-identified,  which   means  that   all identifiable   information   such   as   name,   date   of   birth   and   email   have   been   removed.   You acknowledge and agree that Bodiometer owns access to use your de-identified information to enhance Bodiometer App’s features such as smart features that may be used by practitioners.
                </p>

                <p>
                    You acknowledge and agree that Bodiometer owns access to use your de-identified information to enhance Bodiometer App’s features such as smart features that may be used by practitioners.You acknowledge and agree that access to and use of the Bodiometer App (and any of its related services) is provided via the Internet.   Information stored in the Bodiometer App is stored in Canada but may be accessible in other jurisdictions.
                </p>

                <p>
                    Our systems may record certain information  related to your use of the  Bodiometer App,  by using   various   types   of   technology,   including   cookies,   “clear   gifs”   or   “web   beacons.”   This information may include the IP address or other device address or ID, web browser and/or device type, your photo and biomechanics measurements entered into the Bodiometer App, the web pages or sites visited just before or just after using the Bodiometer App, the pages or other content you view or interact with on the  Bodiometer App, the dates and times of the visit, access, or use of the Bodiometer App and what country you are from. You may limit the collection of certain information by the Bodiometer App, for instance by disabling the cookies using your browser options. Please be aware that by doing so it may prevent you from using specific features on the  Bodiometer App, such as maintaining an online account. We use the collected information on the Bodiometer App through cookies and similar technologies to: (i) monitor our system performance (such as number of visits, average time spent, screen usage), to enable us to operate and manage our systems and services (ii) personalize the Bodiometer App, such as remembering your  information so that you will not have to re-enter it during a visit or on subsequent visits; (iii) better understand our user community so that we can work to continually   upgrade   our   systems   and   services   (iv)  provide   customized   advertisements, content, and information; (v) monitor and analyze the effectiveness of the Bodiometer App and third party marketing activities; (vi) monitor aggregate site usage metrics such as total number of   visitors   and   pages   viewed;   and   (vii)   track   your   entries,   submissions,   and   status   in   any promotions or other activities on the Bodiometer App (or any of its related services).
                </p>

                <p>
                    THERE   ARE   RESTRICTIONS   ON   THE   SERVICES   THAT   CAN   BE   PROVIDED   THROUGH   THE BODIOMETER   APP.   YOU   MUST   NOT   USE   THE   BODIOMETER   APP   FOR   EMERGENCY   MEDICAL NEEDS. IF YOU BELIEVE THAT YOU ARE DEALING WITH A MEDICAL EMERGENCY, CALL 911 (OR YOUR   LOCAL   EMERGENCY   NUMBER)   IMMEDIATELY   OR   ATTEND   YOUR   NEAREST   EMERGENCY ROOM.
                </p>
                    
                <p>
                    Your use of the Bodiometer App (or any of its related services) is governed by the Terms of Use applicable to same. Please see our <a href="https://bodiometer.com/terms">Terms of Use</a> for further details.
                </p>

                <h3>
                    How do we Use and Disclose your Personal Information?
                </h3>

                <p>
                    We may use and disclose your personal information:
                </p>

                <p className="left-margin">
                    &#10063; for the purposes described in this Privacy Policy; or
                </p>

                <p className="left-margin">
                    &#10063; for any additional purposes for which we have obtained your consent to the use or disclosure of your personal information.
                </p>

                <p>
                    We may use or disclose your personal information without your knowledge or consent where we are permitted or required by applicable law or regulatory requirements to do so. 
                </p>

                <p>
                    We may use de-identified information created by us from your personal information without restriction.
                </p>

                <h3>
                    When do we disclose your Personal Information?
                </h3>

                <p>
                    We may share your personal information with our employees, contractors, consultants  and other parties who require such information to assist us with managing our relationship with you, including: third parties that provide services to us or on our behalf; third parties that assist Bodiometer in the provision of services to you; and third parties whose services we use to conduct our business.
                </p>

                <p>
                    In addition, personal information may be disclosed or transferred to another party during the course of, or completion of, a change in ownership of or the grant of a security interest in, allor a part of Bodiometer through, for example, an asset or share sale, or some other form of business   combination,   merger   or   joint   venture,   provided   that   such   party   is   bound   by appropriate   agreements   or   obligations   and   required   to   use   or   disclose   your   personal information in a manner consistent with the use and disclosure provisions of this Privacy Policy, unless you consent otherwise.
                </p>

                <p>
                    Further, your personal information may be disclosed:
                </p>

                <p className="left-margin">
                    &#10063; as permitted or required by applicable law or regulatory requirements;
                </p>

                <p className="left-margin">
                    &#10063; to comply with valid legal processes such as search warrants, subpoenas or court orders;
                </p>

                <p className="left-margin">
                    &#10063; to protect the rights and property of Bodiometer;
                </p>

                <p className="left-margin">
                    &#10063; during  emergency   situations  or   where   necessary   to   protect  the  safety   of   a person or group of persons;
                </p>

                <p className="left-margin">
                    &#10063; where the personal information is publicly available; or
                </p>

                <p className="left-margin">
                    &#10063; with your consent.
                </p>

                <h3>
                    Your Consent is Important to Us
                </h3>

                <p>
                    It is important to us that we collect, use or disclose your personal information where we have your consent to do so.  Depending on the sensitivity of the personal information, your consent may be implied, deemed (using an opt-out mechanism) or express.  Express consent can begiven orally, electronically or in writing.   Implied consent is consent that can reasonably be inferred from your action or inaction.  For example, when you enter into an agreement with us, we will assume your consent to the collection, use and disclosure of your personal information for   purposes   related   to   the   performance   of   that   agreement   and   for   any   other   purposes identified to you at the relevant time.
                </p>

                <p>
                    Typically, we will seek your consent at the time that we collect your personal information.  In certain circumstances, your consent may be obtained after collection but prior to our use or disclosure of your personal information.  If we plan to use or disclose your personal information for  a  purpose  not   previously   identified   (either  in  this   Privacy   Policy  or   separately),  we  will endeavor to advise you of that purpose before such use or disclosure.
                </p>

                <p>
                    As we have described above, we may collect, use or disclose your personal information without your knowledge or consent where we are permitted or required to do so by applicable law or regulatory requirements. 
                </p>

                <p>
                    You   may   change   or   withdraw   your   consent   at   any   time,   subject   to   legal   or   contractual obligations   and   reasonable   notice,   by   contacting   our   Privacy   Officer   using   the   contact information set out below.  All communications with respect to such withdrawal or variation of consent should be in writing and addressed to our Privacy Officer.
                </p>

                <h3>
                    How is Your Personal Information Protected?
                </h3>

                <p>
                    Bodiometer   endeavors   to   maintain   physical,   technical   and   procedural   safeguards   that   are appropriate to the sensitivity of the personal information in question.   These safeguards are designed to prevent your personal information from loss and unauthorized access, copying, use, modification or disclosure.
                </p>

                <p>
                    The security of your personal information is important to us, please advise our Privacy Officer immediately of any incident involving the loss of or unauthorized access to or disclosure of personal information that is in our custody or control.
                </p>

                <h3>
                    Updating Your Personal Information
                </h3>

                <p>
                    It is important that the information contained in our records is both accurate and current.  If your personal information happens to change during the course of our relationship, please keep us informed of such changes. 
                </p>

                <p>
                    In   some   circumstances   we   may   not   agree   with   your   request   to   change   your   personal information and will instead append an alternative text to the record in question.
                </p>

                <h3>
                    Access to Your Personal Information
                </h3>

                <p>
                    You can ask to see your personal information.   If you want to review, verify or correct your personal   information,   please   contact   our   Privacy   Officer.     Please   note   that   any   such communication must be in writing.
                </p>

                <p>
                    When   requesting   access   to   your   personal   information,   please   note   that   we   may   request specific information from you to enable us to confirm your identity and right to access, as wellas to search for and provide you with the personal information that we hold about you.   We may charge you a fee to access your personal information; however, we will advise you of any fee in advance.  If you require assistance in preparing your request, please contact the office of our Privacy Officer.
                </p>

                <p>
                    Your right to access the personal information that we hold about you is not absolute.  There are instances where applicable law or regulatory requirements allow or require us to refuse to provide   some  or  all   of  the  personal   information   that  we   hold   about   you.    In  addition,  the personal information may have been destroyed, erased or made anonymous in accordance with our record retention obligations and practices.
                </p>
                
                <p>
                    In the event that we cannot provide you with access to your personal information, we will endeavor to inform you of the reasons why, subject to any legal or regulatory restrictions.
                </p>

                <h3>
                    Inquiries or Concerns?    
                </h3>

                <p>
                    If you have any questions about this Privacy Policy or concerns about how we manage your personal   information,   please   contact   our   Privacy   Officer,   in   writing   or   by   e-mail.     We   will endeavor to answer your questions and advise you of any steps taken to address the issues raised by you.  If you are dissatisfied with our response, you may be entitled to make a written submission to the Privacy Commissioner in your jurisdiction.
                </p>

                <h3>
                    Privacy Officer
                </h3>

                <p>
                    We   have   appointed   a   Privacy   Officer   to   oversee   compliance   with   this   Privacy   Policy.     The contact information for our Privacy Officer is as follows:
                </p>

                <p className="left-margin">
                    Bodiometer Privacy Officer <br></br>
                    AnimationLeader Technology Inc. <br></br>
                    <a href="mailto:info@bodiometer.com">info@bodiometer.com</a> <br></br>
                </p>

                <h3>
                    Revisions to this Privacy Policy
                </h3>

                <p>
                    From time to time, we may make changes to this Privacy Policy to reflect changes in its legal orregulatory obligations or in the manner in which we deal with your personal information.  Wewill   post   any   revised   version   of   this   Privacy   Policy   on   our   website  <a href="https://www.bodiometer.com">www.bodiometer.com</a>  and   we encourage you to refer back to it on a regular basis.  This Privacy Policy was last updated on Aug 11, 2020.
                </p>

                <h3>
                    Interpretation of this Privacy Policy
                </h3>

                <p>
                    Any interpretation associated with this Privacy Policy will be made by our Privacy Officer.  This Privacy Policy includes examples but is not intended to be restricted in its application to such examples,   therefore   where   the   word   'including'   is   used,   it   shall   mean   'including   without limitation'.
                </p>

                <p>
                    This Privacy Policy does not create or confer upon any individual any rights, or impose upon Bodiometer any obligations outside of, or in addition to, any rights or obligations imposed by Canada's federal and provincial privacy laws, as applicable.  Should there be, in a specific case, any   inconsistency   between   this   Privacy   Policy   and   Canada's   federal   and   provincial   privacy laws, as applicable, this Privacy Policy shall be interpreted, in respect of that case, to give effect to, and comply with, such privacy laws.
                </p>

            </Container>
            <Footer />
        </>

    );
};