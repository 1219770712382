import React from 'react';
// Helmet for dynamic meta tags in header
import {Helmet} from "react-helmet";


const MetaDecorator = ({title, description, url}) => {

    return(
      <Helmet>
        {description && <meta name="description" content={description} />}
        {title && <title>{title}</title>}
        {url && <link rel="canonical" href={url} />}
      </Helmet>
    );
    
}

export default MetaDecorator;