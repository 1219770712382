import React, { useState, Fragment, useEffect, useCallback } from "react";
import { Spinner, Image, Form } from "react-bootstrap";
import axios from "axios";
import CryptoJS from "crypto-js";

import IntakeMain from "./IntakeMain";
import CompletedImg from "./Assets/completed.png";

import "bootstrap/dist/css/bootstrap.min.css";
import "react-phone-number-input/style.css";
import "react-datetime/css/react-datetime.css";
import "./Assets/style.scss";

import { RetrieveToken } from "../../../lib/token";
import { dbUrl } from "../../../lib/config";

const IntakePublic = (props) => {
	const { id } = props.match.params;
	const [isLoading, setIsLoading] = useState(true);
	const [data, setData] = useState({ intake: null, intakeSubmission: null, businessInfo: null, businessLogo: "" });
	const PASSPHRASE = "INTAKEV1";

	const LoadInitialData = useCallback(async () => {
		try {
			setIsLoading(true);

			RetrieveToken()
				.then(async (token) => {
					var configTwo = {
						method: "GET",
						url: dbUrl + "/api/intake/v1/intakesubmission/id",
						headers: {
							Authorization: `Bearer ${token}`,
							intakeId: id,
						},
					};

					var responseTwo = await axios(configTwo, { timeout: 5000 });

					if (responseTwo.data.success) {
						try {
							// real data
							// console.log(responseTwo.data.data);

							// encryption
							// console.log(CryptoJS.AES.encrypt(JSON.stringify(responseTwo.data.data), `${PASSPHRASE}-${id}`));

							// decryption
							// console.log(JSON.parse(CryptoJS.AES.decrypt(CryptoJS.AES.encrypt(JSON.stringify(responseTwo.data.data), `${PASSPHRASE}-${id}`), `${PASSPHRASE}-${id}`).toString(CryptoJS.enc.Utf8)))

							var localData = localStorage.getItem(`${id}`);

							if (!!localData) {
								localData = JSON.parse(CryptoJS.AES.decrypt(localData, `${PASSPHRASE}-${id}`).toString(CryptoJS.enc.Utf8));
								setData({ ...responseTwo.data.data, intake: localData });
							} else {
								localStorage.removeItem(id);
								setData({ ...responseTwo.data.data });
							}
						} catch {
							localStorage.removeItem(id);
							setData({ ...responseTwo.data.data });
						}
						setIsLoading(false);
					} else {
						// handle error
						setIsLoading(false);
					}
				})
				.catch((err) => {
					// handle error
					setIsLoading(false);
				});
		} catch (_) {
			setIsLoading(false);
		}
	}, []);

	const SubmitIntake = async (IntakeData, IntakeSubmissionData) => {
		try {
			setIsLoading(true);

			try{
				IntakeData.Personal.DateOfBirth = new Date(IntakeData.Personal.DateOfBirth).toISOString();
			}
			catch {
				IntakeData.Personal.DateOfBirth = new Date().toISOString();
			}

			RetrieveToken()
				.then(async (token) => {
					var configTwo = {
						method: "PUT",
						url: dbUrl + "/api/intake/v1/updateintake",
						headers: {
							Authorization: `Bearer ${token}`,
							"Content-Type": "application/json",
						},
						data: JSON.stringify(IntakeData),
					};

					var configThree = {
						method: "PUT",
						url: dbUrl + "/api/intake/v1/update/intakesubmission",
						headers: {
							Authorization: `Bearer ${token}`,
							"Content-Type": "application/json",
						},
						data: JSON.stringify(IntakeSubmissionData),
					};

					await axios(configTwo, { timeout: 5000 });
					await axios(configThree, { timeout: 5000 });

					localStorage.removeItem(`${id}`);
					await LoadInitialData();
				})
				.catch((err) => console.log(err));
		} catch (_) {}
	};

	useEffect(() => {
		LoadInitialData();
	}, [LoadInitialData]);

	const OnSubmitHandler = async (intakeData) => {
		var prevSubmission = { ...data.intakeSubmission };
		prevSubmission.CompletedAt = new Date().toISOString();
		prevSubmission.LastModified = new Date().toISOString();
		prevSubmission.Status = "COMPLETED";
		
		await SubmitIntake(intakeData, prevSubmission);
	};

	const SaveIntakeLocally = (intake) => {
		var cypherText = CryptoJS.AES.encrypt(JSON.stringify(intake), `${PASSPHRASE}-${id}`);
		localStorage.setItem(`${id}`, cypherText);
	};

	return (
		<Fragment>
			{isLoading ? (
				<div className="preloader-wrapper">
					<Spinner animation="grow" variant="secondary" />
				</div>
			) : (
				<Fragment>
					{!!data.intakeSubmission ? (
						<Fragment>
							{data.intakeSubmission.Status.toLowerCase() == "initiated" || data.intakeSubmission.Status.toLowerCase() == "email sent" ? (
								<Fragment>
									<div style={{ width: "100%", height: "100%", padding: "20px", paddingBottom: "100px" }}>
										<IntakeMain
											data={data.intake}
											showLogo={true}
											companyLogo={data.businessLogo}
											companyName={data.businessInfo.BusinessName}
											onBack={() => {
												window.location.href = "/";
											}}
											onSubmit={OnSubmitHandler}
											onSave={() => {}}
											onLocalSave={SaveIntakeLocally}
										/>
									</div>
								</Fragment>
							) : (
								<Fragment>
									{data.intakeSubmission.Status.toLowerCase() == "completed" ? (
										<Fragment>
											<div style={{ width: "100vw", height: "100vh", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
												<Image src={CompletedImg} width={200} style={{ margin: "10px" }} />
												<Form.Label>Submitted: {new Date(data.intakeSubmission.CompletedAt).toLocaleString(dateLocale, dateFormat)}</Form.Label>
											</div>
										</Fragment>
									) : (
										<Fragment>
											<div style={{ width: "100vw", height: "100vh", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
												something went wrong. contact your clinic for help
											</div>
										</Fragment>
									)}
								</Fragment>
							)}
						</Fragment>
					) : (
						<Fragment>
							<div style={{ width: "100%", height: "100%", padding: "20px", paddingBottom: "100px" }}>Intake Form Not Found</div>
						</Fragment>
					)}
				</Fragment>
			)}
		</Fragment>
	);
};

const styles = {
	screen: {
		margin: "20px auto",
		maxWidth: "800px",
	},
};

const dateFormat = {
	year: "numeric",
	month: "long",
	day: "numeric",
	weekday: "long",
	hour: "numeric",
	minute: "numeric",
};

const dateLocale = "en-Us";

const IsExpired = (isodate) => {
	// var now = new Date("2022-07-10T23:53:08.596Z");
	// var then = new Date("2022-07-07T22:00:35.467Z");
	var now = new Date();
	var then = new Date(isodate);
	return 24 * 60 * 60 - (now.getTime() - then.getTime()) / 1000 >= 0 ? false : true;
};

const IsLinkValid = (intakeSubmission) => {
	if (intakeSubmission.Status.toLowerCase() == "initiated") {
		if (!!intakeSubmission.LastModified) {
			var now = new Date();
			var then = new Date(intakeSubmission.LastModified);
			if (now.getTime() < then.getTime()) {
				return false;
			}
			return 24 * 60 * 60 - (now.getTime() - then.getTime()) / 1000 >= 0 ? true : false;
		} else {
			return true;
		}
	} else if (intakeSubmission.Status.toLowerCase() == "completed") {
		return false;
	} else if (intakeSubmission.Status.toLowerCase() == "email sent") {
		var now = new Date();
		var then = new Date(intakeSubmission.LastEmailSent);
		if (now.getTime() < then.getTime()) {
			return false;
		}
		return 24 * 60 * 60 - (now.getTime() - then.getTime()) / 1000 >= 0 ? true : false;
	}
	return false;
};

export default IntakePublic;
