import React, {useState, useEffect, Fragment} from "react";
import {Container, Col, Row, Form} from "react-bootstrap";
import axios from "axios";

import UiButton from "../components/dashboard/ui-button.js";

import "../resources/styles/dashboard.scss";
import "bootstrap/dist/css/bootstrap.min.css";

const UserPasswordReset = props => {
    const [state, setState] = useState({
        Success: false,
        Username: "",
        InValid: [false, ""],
        Validated: false
    });

    const SendConfirmationEmail = e => {
      e.preventDefault();
      e.stopPropagation();

      let configOne = {
        method: 'post',
        url: props.dbUrl + '/api/token/dbo',
        headers: { 
            'password': '746C419785D84DC8B3A20B9195363105'
        }
      };
      
      axios(configOne)
      .then(responseOne => {

        let data = "";

        if(state.Username.includes("@")){
          data = JSON.stringify({
              "Email": state.Username
            });
          }
          else{
            data = JSON.stringify({
              "UserId": state.Username
            });
          }

          var config = {
            method: 'post',
            url: props.dbUrl + '/api/email/personalusers/resetpassword/',
            headers: { 
              'Authorization': 'Bearer ' + responseOne.data, 
              'Content-Type': 'application/json'
            },
            data : data
          };

          axios(config)
          .then(function (response) {
            setState({...state, Success: true});
          })
          .catch(function (error) {
            setState({...state, Success: true});
          });
            
        })
        .catch(errorOne => {
          setState({...state, Success: true});
        })

      

    }

    return (
        <section className="landing-form-section-wrapper">
          <Container className="centered-container">
            <Row>
              <Col className="landing-form-wrapper" lg={5} md={6} sm={10}>
                <img src="../../photos/bodiometer-home-logo-stacked.svg" width="250" alt="Bodiometer Logo" />
                <h2 className="blue-color">Reset Password</h2>
                {!state.Success && (
                  <Fragment>
                    <p>Please enter your account Username or Email</p>
                    <Form validated={state.Validated} onSubmit={SendConfirmationEmail}>
                      <Row>
                        <Form.Group as={Col} controlId="register_userId">
                          <Form.Control isInvalid={state.InValid[0]} size="sm" type="input" value={state.Username} onChange={(e) => setState({...state, Username: e.currentTarget.value})} placeholder="User ID or Email" required />
                          <Form.Control.Feedback type="invalid">{state.InValid[1]}</Form.Control.Feedback>
                        </Form.Group>
                      </Row>
                      <Row>
                        <Col>
                          <Form.Group controlId="consent" className="form-footer-wrapper">
                            <br />
                            <UiButton type="submit">Send Email</UiButton>
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row>
                        <br />
                        <p className="centered-text smaller-text">
                          Back to{" "}
                          <a href="#" onClick={ _ => props.setState({...props.state, View: "login"})}>
                            login
                          </a>
                        </p>
                      </Row>
                    </Form>
                  </Fragment>
                )}
                {state.Success && (
                  <div>
                    <p>Please check your email inbox for further instructions</p>
                    <UiButton variant="secondary" onClick={ _ => props.setState({...props.state, View: "login"})} back>
                      Back
                    </UiButton>
                  </div>
                )}
              </Col>
            </Row>
          </Container>
        </section>
      );
}

export default UserPasswordReset;