import React, { useEffect, useState } from "react";

import NavBar from "../components/navbar.component";
import Footer from "../components/footer.component";

import "bootstrap/dist/css/bootstrap.min.css";
import "../resources/styles/dashboard.scss";



const LoginChoice = (props) => {
  const [hoverOne, setHoverOne] = useState(false);
  const [hoverTwo, setHoverTwo] = useState(false);
  

  let obj = localStorage.getItem("_A");
  if (!!obj) {
    obj = JSON.parse(obj);
    if(obj.isLoggedIn && obj.user === "pro"){
      window.location.href = "/portal";
    }
    else if(obj.isLoggedIn && obj.user === "home"){
      window.location.href = "/user";
    }
  }

  useEffect(() => {
    document.title = 'Bodiometer - Portal Login';
  }, []);

  return (
    <div>
      <NavBar />

      <div style={styles.container}>
        <div style={styles.leftContainer}>
          <div
            style={hoverOne ? styles.boxSelected : styles.box}
            onMouseEnter={() => setHoverOne(true)}
            onMouseLeave={() => setHoverOne(false)}
            onTouchStart={() => setHoverOne(true)}
            onClick={() => {
              window.location.href = "/portal/login";
            }}
            onTouchEnd={() => {
              setHoverOne(false);
              window.location.href = "/portal/login";
            }}
          >
            <img src="../../photos/bodiometer-pro-logo-stacked.svg" width="250" alt="Bodiometer Logo" />
            <div style={styles.headerText}>Professional</div>
          </div>
        </div>
        <div style={styles.rightContainer}>
          <div
            style={hoverTwo ? styles.boxSelected : styles.box}
            onMouseEnter={() => setHoverTwo(true)}
            onMouseLeave={() => setHoverTwo(false)}
            onTouchStart={() => setHoverTwo(true)}
            onClick={() => {
              window.location.href = "/user";
            }}
            onTouchEnd={() => {
              setHoverTwo(false);
              window.location.href = "/user";
            }}
          >
            <img src="../../photos/bodiometer-home-logo-stacked.svg" width="250" alt="Bodiometer Logo" />
            <div style={styles.headerText}>Personal</div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

const styles = {
  container: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    // alignItems: "center",
    width: "100%",
    height: "600px"
  },
  leftContainer: {
    flex: 1,
    flexGrow: 1,
    justifyContent: "center",
    padding: "5vw",
    maxWidth: "500px",
    maxHeight: "500px"
  },
  rightContainer: {
    flex: 1,
    flexGrow: 1,
    justifyContent: "center",
    padding: "5vw",
    maxWidth: "500px",
    maxHeight: "500px"
  },
  box: {
    display: "flex",
    width: "100%",
    height: "100%",
    borderRadius: "20px",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    color: "#6C7D85",
  },
  boxSelected: {
    display: "flex",
    width: "100%",
    height: "100%",
    borderRadius: "20px",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "rgba(0, 217, 22, 0.2)",
    cursor: "pointer",
    flexDirection: "column",
    color: "#6C7D85",
  },
  headerText: {
    fontSize: "120%",
    fontWeight: "500",
  },
};

export default LoginChoice;
