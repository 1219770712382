import React, { useEffect, Fragment, useState } from "react";
import { Alert, Row, Col, Popover, OverlayTrigger, Form, Spinner, Button, Modal } from "react-bootstrap";
import axios from "axios";
import { useAppContext, useUserDashboardContext } from "../lib/context.js";

import UiButton from "../components/dashboard/ui-button.js";

import "bootstrap/dist/css/bootstrap.min.css";
import "../resources/styles/dashboard.scss";

const UserJournals = ({ dbUrl }) => {
	const { appState } = useAppContext();

	const userId = appState.userData.Id;

	const [state, setState] = useState({
		isViewMode: true,
		isEditMode: false,
		editedJournal: {},

		journalDb: {},
		journalId: "",
		journalIds: [],

		isRemoveConfirmationVisible: false,

		isCreationMode: false,
		newJournal: {},
		quotaFull: false,
	});

	const blankJournal = {
		Journal1: "",
		PainScore: 0,
	};

	const InitiateStateVariables = (JournalDb, JournalIds) => {
		let journalDb = { ...JournalDb };
		let quotaFull = false;
		for (const k in journalDb) {
			let newDate = new Date(AddHoursToDate(journalDb[k].CreatedAt, journalDb[k].CreatedAtOffset));
			newDate.setHours(0, 0, 0, 0);
			let today = new Date();
			today.setHours(0, 0, 0, 0);
			quotaFull = quotaFull || newDate.toLocaleDateString() === today.toLocaleDateString();
		}

		setState({
			...state,

			journalDb: { ...JournalDb },
			journalIds: JournalIds,
			journalId: JournalIds.length > 0 ? JournalIds[0] : "",

			isEditMode: false,
			isCreationMode: false,
			isViewMode: true,
			quotaFull,
			isRemoveConfirmationVisible: false,
			newJournal: { ...blankJournal },
		});
	};

	const LoadJournals = async (userId) => {
		try {
			let configOne = {
				method: "post",
				url: dbUrl + "/api/token/dbo",
				headers: {
					password: "746C419785D84DC8B3A20B9195363105",
				},
			};

			let responseOne = await axios(configOne, { timeout: 5000 });
			if (responseOne.data != "Invalid Credentials") {
				let configTwo = {
					method: "GET",
					url: dbUrl + "/api/journals/v2/userid",
					headers: {
						Authorization: "Bearer " + responseOne.data,
						userId: userId,
						"Content-Type": "application/json",
					},
				};

				let responseTwo = await axios(configTwo, { timeout: 5000 });

				if (responseTwo.data.success) {
					let journalList = responseTwo.data.data;
					let newJournalDb = {};
					let journalIds = [];
					journalList.forEach((journal) => {
						newJournalDb[journal.JournalId] = journal;
						journalIds.push(journal.JournalId);
					});

					InitiateStateVariables(newJournalDb, journalIds);
				}
			}
		} catch {}
	};

	useEffect(() => {
		LoadJournals(userId);
	}, []);

	const RenderJournalDateSelectionOverlay = (Journals) => {
		return (
			<Popover className="posture-date-overlay-wrapper" style={{ backgroundColor: "#707070" }} id={`popover${Math.floor(Math.random()).toString()}`}>
				<Popover.Content>
					<div>
						<ul>
							{Object.keys(Journals)
								.sort((i, j) => (new Date(Journals[i].CreatedAt).getTime() >= new Date(Journals[j].CreatedAt).getTime() ? -1 : 1))
								.map((key) => {
									return (
										<li key={key}>
											<UiButton
												className={state.journalId == key ? "active-date" : "white-link"}
												onClick={() =>
													setState({
														...state,
														journalId: key,
													})
												}
											>
												{AddHoursToDate(Journals[key].CreatedAt, Journals[key].CreatedAtOffset)}
												{state.journalId == key ? <img src="../photos/icons/dashboard/SVG/check-green.svg" width="15" /> : null}
											</UiButton>
										</li>
									);
								})}
						</ul>
					</div>
				</Popover.Content>
			</Popover>
		);
	};

	const CreateNewJournal = async (Journal) => {
		try {
			let configOne = {
				method: "post",
				url: dbUrl + "/api/token/dbo",
				headers: {
					password: "746C419785D84DC8B3A20B9195363105",
				},
			};

			let responseOne = await axios(configOne, { timeout: 5000 });
			if (responseOne.data != "Invalid Credentials") {
				let data = JSON.stringify({
					painScore: Journal.PainScore,
					journal: Journal.Journal1,
					userId: userId,
					createdAt: new Date().toLocaleString(dateLocale, defaultDateFormat),
					createdAtOffset: 0,
					lastModified: new Date().toLocaleString(dateLocale, defaultDateFormat),
					lastModifiedOffset: 0,
				});

				let configTwo = {
					method: "POST",
					url: dbUrl + "/api/journals/v1/create",
					headers: {
						Authorization: "Bearer " + responseOne.data,
						"Content-Type": "application/json",
					},
					data: data,
				};

				let responseTwo = await axios(configTwo, { timeout: 5000 });

				if (responseTwo.data.success) {
					let newJournalId = responseTwo.data.data.journalId;
					let newJournal = {
						...Journal,
						JournalId: newJournalId,
						UserId: userId,
						PainScore: Journal.PainScore,
						Journal: Journal.Journal1,
						CreatedAt: new Date().toLocaleString(dateLocale, defaultDateFormat),
						CreatedAtOffset: 0,
						LastModified: new Date().toLocaleString(dateLocale, defaultDateFormat),
						LastModifiedOffset: 0,
					};
					let newJournalDb = { ...state.journalDb };
					newJournalDb[newJournalId] = newJournal;

					InitiateStateVariables(newJournalDb, [newJournalId, ...state.journalIds]);
				}
			}
		} catch {}
	};

	const UpdateJournal = async (Journal) => {
		try {
			let configOne = {
				method: "post",
				url: dbUrl + "/api/token/dbo",
				headers: {
					password: "746C419785D84DC8B3A20B9195363105",
				},
			};

			let responseOne = await axios(configOne, { timeout: 5000 });
			if (responseOne.data != "Invalid Credentials") {
				let data = JSON.stringify({
					painScore: Journal.PainScore,
					journal: Journal.Journal1,
					lastModified: new Date().toLocaleString(dateLocale, defaultDateFormat),
					lastModifiedOffset: 0,
				});

				let configTwo = {
					method: "put",
					url: dbUrl + "/api/journals/v1/update/journalid",
					headers: {
						Authorization: "Bearer " + responseOne.data,
						journalId: Journal.JournalId,
						"Content-Type": "application/json",
					},
					data: data,
				};

				let responseTwo = await axios(configTwo, { timeout: 5000 });

				if (responseTwo.data.success) {
					let newJournalDb = { ...state.journalDb };
					newJournalDb[Journal.JournalId] = { ...Journal };
					InitiateStateVariables(newJournalDb, state.journalIds);
				}
			}
		} catch {}
	};

	const RemoveJournal = async (JournalId) => {
		try {
			let configOne = {
				method: "post",
				url: dbUrl + "/api/token/dbo",
				headers: {
					password: "746C419785D84DC8B3A20B9195363105",
				},
			};

			let responseOne = await axios(configOne, { timeout: 5000 });
			if (responseOne.data != "Invalid Credentials") {
				let configTwo = {
					method: "DELETE",
					url: dbUrl + "/api/journals/v1/remove/id",
					headers: {
						Authorization: "Bearer " + responseOne.data,
						id: JournalId,
						"Content-Type": "application/json",
					},
				};

				let responseTwo = await axios(configTwo, { timeout: 5000 });

				if (responseTwo.data.success) {
					let newJournalDb = { ...state.journalDb };
					delete newJournalDb[JournalId];
					InitiateStateVariables(
						newJournalDb,
						state.journalIds.filter((id) => id !== JournalId)
					);
				}
			}
		} catch {}
	};

	const RemoveJournalConfirmationOverlay = () => {
		return (
			<Popover className="action-toggle-wrapper roles-toggle-wrapper" id={`popover${Math.floor(Math.random()).toString()}`}>
				<Popover.Content>
					<Row>
						<Col>
							<h3 className="green-color">Are you sure you want to remove this journal?</h3>
						</Col>
					</Row>
					<Row>
						<Col>
							<Button
								style={{ background: "#45b249", marginRight: "5px" }}
								onClick={(e) => {
									RemoveJournal(state.journalId);
								}}
							>
								Yes
							</Button>
							<Button
								style={{ background: "#6c7d85" }}
								onClick={(e) =>
									setState({
										...state,
										isRemoveConfirmationVisible: false,
										isEditMode: false,
										isCreationMode: false,
										isViewMode: true,
									})
								}
							>
								Cancel
							</Button>
						</Col>
					</Row>
				</Popover.Content>
			</Popover>
		);
	};

	const RenderNewJournal = () => {
		return (
			<Fragment>
				<Row style={{ marginTop: "2vh", marginBottom: "2vh" }}>
					<Form.Group as={Col}>
						<div className="textarea-container">
							<Form.Control
								as={"textarea"}
								value={state.newJournal.Journal1}
								rows={6}
								id={`id-journals`}
								onChange={(e) => {
									setState({ ...state, newJournal: { ...state.newJournal, Journal1: e.target.value } });
								}}
							/>
						</div>
					</Form.Group>
				</Row>

				<Row style={{ marginTop: "2vh", marginBottom: "2vh" }}>
					<Col>
						<div className="pain-slider-container">
							<span>PAIN SCORE</span>
							<span>
								<input
									type="range"
									min="0"
									max="10"
									value={state.newJournal.PainScore}
									onChange={(e) => {
										setState({
											...state,
											newJournal: { ...state.newJournal, PainScore: parseInt(e.target.value) },
										});
									}}
									className="pain-slider"
									id="id-pain-score"
								/>
							</span>
							<span>{state.newJournal.PainScore}</span>
						</div>
					</Col>
				</Row>

				<Row style={{ marginTop: "20px" }}>
					<Col style={{ textAlign: "center" }}>
						<Button
							variant="primary"
							onClick={() => {
								CreateNewJournal(state.newJournal);
							}}
						>
							Create
						</Button>
						<Button
							style={{ marginLeft: "5px" }}
							variant="danger"
							onClick={() => {
								setState({
									...state,
									isEditMode: false,
									isViewMode: true,
									isCreationMode: false,
									newJournal: { ...blankJournal },
								});
							}}
						>
							Cancel
						</Button>
					</Col>
				</Row>
			</Fragment>
		);
	};

	return (
		<Fragment>
			{!!state.journalId && state.journalId in state.journalDb ? (
				<Fragment>
					{!state.isCreationMode && (
						<Fragment>
							<section className="module-date-selection">
								<Row>
									<Col>
										<div>{AddHoursToDate(state.journalDb[state.journalId].CreatedAt, state.journalDb[state.journalId].CreatedAtOffset)}</div>
										<OverlayTrigger rootClose={true} trigger="click" placement="right" overlay={RenderJournalDateSelectionOverlay(state.journalDb)}>
											<div
												style={{
													cursor: "pointer",
													color: "#2A92CF",
												}}
											>
												Change
											</div>
										</OverlayTrigger>

										{state.isViewMode && (
											<Fragment>
												<Button
													style={{ marginLeft: "5px" }}
													variant="info"
													onClick={() => {
														setState({
															...state,
															isEditMode: true,
															isViewMode: false,
															editedJournal: { ...state.journalDb[state.journalId] },
														});
													}}
												>
													Edit
												</Button>
												{!state.quotaFull && (
													<Button
														style={{ marginLeft: "5px" }}
														variant="primary"
														onClick={() => {
															setState({
																...state,
																isCreationMode: true,
																isEditMode: false,
																isViewMode: false,
															});
														}}
													>
														New
													</Button>
												)}
											</Fragment>
										)}
									</Col>
								</Row>
							</section>
						</Fragment>
					)}

					{(state.isEditMode || state.isViewMode) && (
						<Fragment>
							<Row style={{ marginTop: "2vh", marginBottom: "2vh" }}>
								<Form.Group as={Col}>
									<div className="textarea-container">
										<Form.Control
											as={"textarea"}
											value={state.isEditMode ? state.editedJournal.Journal1 : state.journalDb[state.journalId].Journal1}
											rows={6}
											id={`id-journals`}
											disabled={!state.isEditMode}
											onChange={(e) => {
												let newJournalText = e.target.value;
												let newJournal = { ...state.editedJournal };
												newJournal.Journal1 = newJournalText;
												setState({ ...state, editedJournal: { ...newJournal } });
											}}
										/>
									</div>
								</Form.Group>
							</Row>

							<Row style={{ marginTop: "2vh", marginBottom: "2vh" }}>
								<Col>
									<div className="pain-slider-container">
										<span>PAIN SCORE</span>
										<span>
											<input
												type="range"
												min="0"
												max="10"
												value={state.isEditMode ? state.editedJournal.PainScore : state.journalDb[state.journalId].PainScore}
												onChange={(e) => {
													let newPainScore = parseInt(e.target.value);
													let newJournal = { ...state.editedJournal };
													newJournal.PainScore = newPainScore;
													setState({ ...state, editedJournal: { ...newJournal } });
												}}
												className="pain-slider"
												id="id-pain-score"
												disabled={!state.isEditMode}
											/>
										</span>
										<span>{state.isEditMode ? state.editedJournal.PainScore : state.journalDb[state.journalId].PainScore}</span>
									</div>
								</Col>
							</Row>
						</Fragment>
					)}

					{state.isEditMode && (
						<Row style={{ marginTop: "20px" }}>
							<Col style={{ textAlign: "center" }}>
								<Button
									variant="primary"
									onClick={() => {
										// update login
										UpdateJournal(state.editedJournal);
									}}
								>
									Update
								</Button>
								<Button
									style={{ marginLeft: "5px" }}
									variant="danger"
									onClick={() => {
										setState({ ...state, isEditMode: false, isViewMode: true, isCreationMode: false });
									}}
								>
									Cancel
								</Button>
							</Col>
						</Row>
					)}

					{state.isCreationMode && !(state.isEditMode || state.isViewMode) ? (
						<Fragment>
							<Row style={{ marginTop: "2vh", marginBottom: "2vh" }}>
								<Form.Group as={Col}>
									<div className="textarea-container">
										<Form.Control
											as={"textarea"}
											value={state.newJournal.Journal1}
											rows={6}
											id={`id-journals`}
											onChange={(e) => {
												setState({ ...state, newJournal: { ...state.newJournal, Journal1: e.target.value } });
											}}
										/>
									</div>
								</Form.Group>
							</Row>

							<Row style={{ marginTop: "2vh", marginBottom: "2vh" }}>
								<Col>
									<div className="pain-slider-container">
										<span>PAIN SCORE</span>
										<span>
											<input
												type="range"
												min="0"
												max="10"
												value={state.newJournal.PainScore}
												onChange={(e) => {
													setState({
														...state,
														newJournal: { ...state.newJournal, PainScore: parseInt(e.target.value) },
													});
												}}
												className="pain-slider"
												id="id-pain-score"
											/>
										</span>
										<span>{state.newJournal.PainScore}</span>
									</div>
								</Col>
							</Row>

							<Row style={{ marginTop: "20px" }}>
								<Col style={{ textAlign: "center" }}>
									<Button
										variant="primary"
										onClick={() => {
											CreateNewJournal(state.newJournal);
										}}
									>
										Create
									</Button>
									<Button
										style={{ marginLeft: "5px" }}
										variant="danger"
										onClick={() => {
											setState({
												...state,
												isEditMode: false,
												isViewMode: true,
												isCreationMode: false,
												newJournal: { ...blankJournal },
											});
										}}
									>
										Cancel
									</Button>
								</Col>
							</Row>
						</Fragment>
					) : (
						<Fragment></Fragment>
					)}

					{state.isViewMode && (
						<Row style={{ marginTop: "20px" }}>
							<Col style={{ textAlign: "center" }}>
								<OverlayTrigger rootClose={true} show={state.isRemoveConfirmationVisible} trigger="click" placement="top" overlay={RemoveJournalConfirmationOverlay()}>
									<Button
										style={{ marginLeft: "5px" }}
										variant="danger"
										onClick={() => {
											setState({ ...state, isRemoveConfirmationVisible: true });
										}}
										disabled={state.isEditMode}
									>
										Remove
									</Button>
								</OverlayTrigger>
							</Col>
						</Row>
					)}
				</Fragment>
			) : (
				<Fragment>
					{!state.isCreationMode ? (
						<Fragment>
							<section className="module-date-selection">
								<Row>
									<Col>
										<Button
											style={{ marginLeft: "5px" }}
											variant="primary"
											onClick={() => {
												setState({
													...state,
													isCreationMode: true,
												});
											}}
										>
											New
										</Button>
									</Col>
								</Row>
							</section>
						</Fragment>
					) : (
						RenderNewJournal()
					)}
				</Fragment>
			)}
		</Fragment>
	);
};

export default UserJournals;

const AddHoursToDate = (str, h) => {
	let newDate = new Date(str);
	newDate.setTime(newDate.getTime() + h * 60 * 60 * 1000);
	return newDate.toLocaleString(dateLocale, defaultDateFormat);
};

const IsEmpty = (val) => {
	return val === undefined || val == null || val.length <= 0 ? true : false;
};

const defaultDateFormat = {
	year: "numeric",
	month: "short",
	day: "numeric",
	hour: "numeric",
	minute: "numeric",
};

const dateLocale = "en-Us";
