





import React, {useState, useEffect} from 'react';
import {Container, Row, Col} from 'react-bootstrap';
import axios from 'axios';

import "bootstrap/dist/css/bootstrap.min.css";

// import logo from "../resources/logos/bodiometer.png";
import logo from "../resources/photos/bodiometer-logo-stacked.svg";
import loading_screen from "../resources/photos/loading/loading_1.gif";

export default function BusinessAccountEmailConfirmation(props) {
    
    const [state, setState] = useState({
        loading: true,
        token_valid: false,
        confirmed: false
    });

    useEffect(() => {
        let environment = process.env.NODE_ENV;
        let domain = null;
        if(environment == 'development'){
            domain = 'http://localhost:54928'; // Original
            // domain = 'https://bodiometerhomerestapi-dot-project-4839952831808961167.appspot.com';
        }
        else{
            domain = 'https://bodiometerhomerestapi-dot-project-4839952831808961167.appspot.com';
        }
        let business_id = props.match.params.id;
        let token = `Bearer ${props.match.params.token}`;

        const PostData = {
            businessDetail: {
              emailActivated: true
            }
          }
          
        const PostHeaders = {
        headers: {
            'Authorization': token,
            'Content-Type': 'application/json',
            'businessId': business_id
        }
        };
        
        axios.put(
        domain + '/api/businessaccounts/v1/update/businessid', 
        PostData, 
        PostHeaders
        )
        .then((res) => {

        if (res) {
            setState({...state, loading: false, token_valid: true, confirmed: true})
        }
        else {
            setState({...state, loading: false, token_valid: true, confirmed: false})
        }

        })

    },[])



    const mainSectionStyle = {
        minHeight: "90vh",
        minWidth: "90vw",
        display: 'flex',
        alignContent: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        backgroundColor: "#fafcfb"
    };

    const messageStyle = {
        textAlign: 'center', 
        margin: '30px'
    };

    const logoStyle = {
        maxWidth: '400px'
    };

    return(
        <Container style={mainSectionStyle} fluid>
            <Row>
                <Col style={messageStyle}>
                    <img src={logo} style={logoStyle} width="250" />
                </Col>
            </Row>

            {
                !state.token_valid && !state.loading ? 
                <div>
                    <Row>
                        <Col style={messageStyle}>
                            <h2 className="gray-color">Error</h2> 
                            <h3>The link is broken or expired</h3> 
                        </Col>
                    </Row>
                </div>
                :
                state.confirmed && !state.loading ? 
                    <div>
                        <Row>
                            <Col style={messageStyle}>
                                <h2 className="green-color">Success!</h2> 
                                <h3 className="green-color">Your organization email address has been confirmed</h3> 
                            </Col>
                        </Row>
                    </div>
                    :
                    !state.confirmed && !state.loading && <div>
                        <Row>
                            <Col style={messageStyle}>
                                <h2 className="gray-color">Error</h2> 
                                <h3>Oops, something went wrong..</h3> 
                            </Col>
                        </Row>
                    </div>
            }

            {
                state.loading && !state.confirmed && !state.token_valid &&
                <div>
                    <Row>
                        <Col style={messageStyle}>
                            <img src={loading_screen} style={{maxWidth: '100px'}} />
                        </Col>
                    </Row>
                </div>
            }
            
        </Container>
    )
};



















// old



// import React, {useState, useEffect} from 'react';
// import {Container, Row, Col} from 'react-bootstrap';

// import "bootstrap/dist/css/bootstrap.min.css";

// // import logo from "../resources/logos/bodiometer.png";
// import logo from "../resources/photos/bodiometer-logo-stacked.svg";
// import loading_screen from "../resources/photos/loading/loading_1.gif";


// export default function BusinessUserEmailConfirmation(props) {

//     const [state, setState] = useState({
//         loading: true,
//         token_valid: false,
//         confirmed: false
//     });
    
//     useEffect(() => {
//         let environment = process.env.NODE_ENV;
//         let domain = null;
//         if(environment === 'development'){
//             domain = 'http://localhost:54928/';
//         }
//         else{
//             domain = 'https://bodiometerhomerestapi-dot-project-4839952831808961167.appspot.com/';
//         }
//         let user_id = props.match.params.id;
//         let token = props.match.params.token;
//         var headers = new Headers();
//         headers.set('Content-Type', 'application/json');
//         headers.set('Access-Control-Allow-Origin', "*");
//         headers.set('Access-Control-Allow-Headers', "*");
//         headers.set("Access-Control-Allow-Credentials", "true");
//         headers.set("Access-Control-Allow-Headers", "Content-Type, Accept, X-Requested-With, remember-me");
//         headers.set('Authorization', 'Bearer ' + token);
//         fetch(domain + 'api/token/dbo/valid/', {
//             method: 'GET',
//             mode: 'cors',
//             headers: headers,
//         })
//         .then(response => response.json())
//         .then(data => {
//             if(data){
//                 fetch(domain + 'api/businessaccounts/emailactivate', {
//                     method: 'POST',
//                     mode: 'cors',
//                     headers: headers,
//                     body: JSON.stringify({"businessID": `${user_id}`})
//                 })
//                 .then(res => res.json())
//                 .then(data => {
//                     if(data){
//                         setState({
//                             ...state,
//                             loading: false,
//                             token_valid: true,
//                             confirmed: true
//                         })
//                     }
//                     else{
//                         setState({
//                             ...state,
//                             loading: false,
//                             token_valid: true,
//                             confirmed: false
//                         })
//                     }
//                 })
//                 .catch(error => {
//                     setState({
//                         ...state,
//                         loading: false,
//                         token_valid: true,
//                         confirmed: false
//                     })
//                 })
                
//             }
//             else{
                
//                 setState({
//                     ...state,
//                     loading: false,
//                 })
//             }
//         })
//         .catch(error => {
//             setState({
//                 ...state,
//                 loading: false,
//                 token_valid: false,
//                 confirmed: false
//             })
//         });
//     },[])

//     const mainSectionStyle = {
//         minHeight: "90vh",
//         minWidth: "90vw",
//         display: 'flex',
//         alignContent: 'center',
//         justifyContent: 'center',
//         flexDirection: 'column',
//         backgroundColor: "#fafcfb"
//     };

//     const messageStyle = {
//         textAlign: 'center', 
//         margin: '30px'
//     };

//     const logoStyle = {
//         maxWidth: '400px'
//     };

//     return(
//         <Container style={mainSectionStyle} fluid>
//             <Row>
//                 <Col style={messageStyle}>
//                     <img src={logo} style={logoStyle} width="250" />
//                 </Col>
//             </Row>

            

//             {
//                 !state.token_valid && !state.loading ? 
//                 <div>
//                     <Row>
//                         <Col style={messageStyle}>
//                             <h3>The link is broken or expired <span style={{color: 'red'}}>&#33;</span></h3> 
//                         </Col>
//                     </Row>
//                 </div>
//                 :
//                 state.confirmed && !state.loading ? 
//                     <div>
//                         <Row>
//                             <Col style={messageStyle}>
//                                 <h3>You have confirmed your email address successfully. <span style={{color: 'green'}}>&#10003;</span></h3> 
//                             </Col>
//                         </Row>
//                     </div>
//                     :
//                     !state.confirmed && !state.loading && <div>
//                         <Row>
//                             <Col style={messageStyle}>
//                                 <h3>Something went wrong <span style={{color: 'red'}}>&#33;</span></h3> 
//                             </Col>
//                         </Row>
//                     </div>
//             }

//             {
//                 state.loading && !state.confirmed && !state.token_valid &&
//                 <div>
//                     <Row>
//                         <Col style={messageStyle}>
//                             <img src={loading_screen} style={{maxWidth: '100px'}} />
//                         </Col>
//                     </Row>
//                 </div>
//             }
            
//         </Container>
//     )
// };


// Original class based component

// export default class BusinessUserEmailConfirmation extends Component{
    
//     constructor(props){
//         super(props);
//         state = {
//             loading: true,
//             token_valid: false,
//             confirmed: false
//         }

//     }
    
//     componentDidMount(){
//         let environment = process.env.NODE_ENV;
//         let domain = null;
//         if(environment == 'development'){
//             domain = 'http://localhost:54928/';
//         }
//         else{
//             domain = 'https://bodiometerhomerestapi-dot-project-4839952831808961167.appspot.com/';
//         }
//         let user_id = props.match.params.id;
//         let token = props.match.params.token;
//         var headers = new Headers();
//         headers.set('Content-Type', 'application/json');
//         headers.set('Access-Control-Allow-Origin', "*");
//         headers.set('Access-Control-Allow-Headers', "*");
//         headers.set("Access-Control-Allow-Credentials", "true");
//         headers.set("Access-Control-Allow-Headers", "Content-Type, Accept, X-Requested-With, remember-me");
//         headers.set('Authorization', 'Bearer ' + token);
//         fetch(domain + 'api/token/dbo/valid/', {
//             method: 'GET',
//             mode: 'cors',
//             headers: headers,
//         })
//         .then(response => response.json())
//         .then(data => {
//             if(data){
//                 fetch(domain + 'api/businessaccounts/emailactivate', {
//                     method: 'POST',
//                     mode: 'cors',
//                     headers: headers,
//                     body: JSON.stringify({"businessID": `${user_id}`})
//                 })
//                 .then(res => res.json())
//                 .then(data => {
//                     if(data){
//                         setState({
//                             ...state,
//                             loading: false,
//                             token_valid: true,
//                             confirmed: true
//                         })
//                     }
//                     else{
//                         setState({
//                             ...state,
//                             loading: false,
//                             token_valid: true,
//                             confirmed: false
//                         })
//                     }
//                 })
//                 .catch(error => {
//                     setState({
//                         ...state,
//                         loading: false,
//                         token_valid: true,
//                         confirmed: false
//                     })
//                 })
                
//             }
//             else{
                
//                 setState({
//                     ...state,
//                     loading: false,
//                 })
//             }
//         })
//         .catch(error => {
//             setState({
//                 ...state,
//                 loading: false,
//                 token_valid: false,
//                 confirmed: false
//             })
//         });
//     }

//     render(){
//         const mainSectionStyle = {
//             minHeight: "90vh",
//             minWidth: "90vw",
//             display: 'flex',
//             alignContent: 'center',
//             justifyContent: 'center',
//             flexDirection: 'column',
//             backgroundColor: "#fafcfb"
//         };

//         const messageStyle = {
//             textAlign: 'center', 
//             margin: '30px'
//         };

//         const logoStyle = {
//             maxWidth: '400px'
//         };

//         return(
//             <Container style={mainSectionStyle} fluid>
//                 <Row>
//                     <Col style={messageStyle}>
//                         <img src={logo} style={logoStyle} />
//                     </Col>
//                 </Row>

                

//                 {
//                     !state.token_valid && !state.loading ? 
//                     <div>
//                         <Row>
//                             <Col style={messageStyle}>
//                                 <h3>The link is broken or expired <span style={{color: 'red'}}>&#33;</span></h3> 
//                             </Col>
//                         </Row>
//                     </div>
//                     :
//                     state.confirmed && !state.loading ? 
//                         <div>
//                             <Row>
//                                 <Col style={messageStyle}>
//                                     <h3>You have confirmed your email address successfully. <span style={{color: 'green'}}>&#10003;</span></h3> 
//                                 </Col>
//                             </Row>
//                         </div>
//                         :
//                         !state.confirmed && !state.loading && <div>
//                             <Row>
//                                 <Col style={messageStyle}>
//                                     <h3>Something went wrong <span style={{color: 'red'}}>&#33;</span></h3> 
//                                 </Col>
//                             </Row>
//                         </div>
//                 }

//                 {
//                     state.loading && !state.confirmed && !state.token_valid &&
//                     <div>
//                         <Row>
//                             <Col style={messageStyle}>
//                                 <img src={loading_screen} style={{maxWidth: '100px'}} />
//                             </Col>
//                         </Row>
//                     </div>
//                 }
                
//             </Container>
//         )
//     }
// }