import React, {useState, Fragment} from "react";
import {Container, Col, Row, Card, Button, Modal, Carousel} from "react-bootstrap";
import MediaQuery from "react-responsive";
import {useLocation} from "react-router-dom";

import "../App.css";
import "../resources/styles/product.scss";

import ProductModal from "./product-modal.js";

import prodImgLite from "../resources/photos/products/lite.jpg";
import prodImgMobile from "../resources/photos/products/mobile.jpg";
import prodImgDesktop from "../resources/photos/products/desktop.jpg";

import videoLite from "../resources/videos/bodiometer_workflow_landscape.mp4";
import videoDesktop from "../resources/videos/bodiometer_desktop.mp4";
import videoDashboard from "../resources/videos/bodiometer_dashboard.mp4";

import bodiometerWorkflowLandscape from "../resources/videos/bodiometer_workflow_landscape.mp4";
import bodiometerWorkflowPortrait from "../resources/videos/bodiometer_workflow_landscape.mp4";
// import bodiometerWorkflowPortrait from "../resources/videos/bodiometer_workflow_portrait.mp4";

import desktopBg from "../resources/videos/bodiometer-desktop-bg.mp4";

import NavBar from "../components/navbar.component.js";
import Footer from "../components/footer.component.js";

// Custom page meta with react-helmet
import MetaDecorator from "../components/metaDecorator.js";

import store from "local-storage";

export default function Product(props) {
  const productType = (name) => {
    if (name === "/product/desktop" || name === "/product/desktop/") {
      return {number: 2, video: videoDesktop, short: "desktop"};
    } else if (name === "/product/pro" || name === "/product/pro/") {
      return {number: 1, video: videoLite, short: "pro"};
    } else if (name === "/product/lite" || name === "/product/lite/") {
      return {number: 0, video: videoLite, short: "lite"};
    } else if (name === "/product/dashboard" || name === "/product/dashboard/") {
      return {number: 3, video: videoDashboard, short: "dashboard"};
    }
  };

  const location = useLocation();

  const [state, setState] = useState({
    product: productType(location.pathname).short,
    showDemo: false,
    videoSrc: productType(location.pathname).video,

    showCart: false,

    subscriptionButtonVisible: false,

    activeSubscription: 2,

    product_list: [
      {
        name: "Bodiometer " + "Lite",
        description: "Bodiometer Lite is a free iPhone app we launched as a quick response to COVID-19. It helps patients who are practicing social distancing track their posture and outcomes digitally and send the reports to their care providers. This app provides the basic functionality of Bodiometer Pro (without a subscription) which is the ability to rapidly take a posture scan, produce a report and show the report to the patient/practitioner. This app is a scaled-down beta prototype that can be used as a quick postural assessment tool for maintaining healthy body at home.",
        image: prodImgLite,
        quantity: 0,
        price_per_month: 100,
        price_per_year: 1200,
        sale_ongoing: false,
        sale_occasion: "Black Friday",
        sale_price_per_month: 100,
        sale_price_per_year: 1200,
        plan: "Y", // M: Monthly, Y: Yearly
        n_months: 12, // number of months if subscription is selected
        version: 11, // version keeps track of the product price (helps for better cache management)
      },
      {
        name: "Bodiometer " + "Pro",
        description: "As the world's first real-time posture assessment app, Bodiometer Pro enables clinicians to quickly and accurately assess patient posture without markers or other devices in almost any setting. Bodiometer utilizes an AI-driven computer vision system to automatically extract patient skeletal data relying only on the camera of the mobile device. Patient scans are saved in app, enabling users to track progress or changes in their posture over the course of treatment. Clinicians can compare scans directly with comparative reports. These visual reports are easy to print or share with the patient as a pdf. These reports makes a huge difference in patient education and engagement which means better outcomes for everyone.",
        image: prodImgMobile,
        quantity: 0,
        price_per_month: 65,
        price_per_year: 650,
        sale_ongoing: false,
        sale_occasion: "Christmas",
        sale_price_per_month: 52,
        sale_price_per_year: 520,
        plan: "Y", // M: Monthly, Y: Yearly
        n_months: 12, // number of months if monthly subscription is selected
        version: 11, // version keeps track of the product price (helps for better cache management)
      },
      {
        name: "Bodiometer " + "Desktop",
        description: "Bodiometer Desktop is our most advanced clinical biomechanics assessment tool. Using markerless motion-tracking, you can quickly and accurately measure range of motion, posture, and functional movement patterns. Our range of motion module allows you to measure 14 different joints in a variety of different movements. Our posture module allows you to quickly measure posture in the coronal, sagittal and traverse planes. Lastly, our functional movement module allows you to measure any kind of functional movement you can think of and compare a patient’s performance over time/  Bodiometer allows you to track patient data easily, and show a patient their progress with engaging and informative progress reports. Bodiometer gives you the tools to collect accurate, objective data quickly, and educate your patients about the structure of their bodies.",
        image: prodImgDesktop,
        quantity: 0,
        price_per_month: 160,
        price_per_year: 1600,
        sale_ongoing: false, // replace it with true during sale
        sale_occasion: "Christmas",
        sale_price_per_month: 128,
        sale_price_per_year: 1280,
        plan: "Y", // M: Monthly, Y: Yearly
        n_months: 12, // number of months if monthly subscription is selected
        version: 11, // version keeps track of the product price (helps for better cache management)
      },
      {
        name: "Bodiometer " + "Dashboard",
        description: "Bodiometer provides a motion-capture solution to objectively assess joint mobility of the patients in real-time and keep the patients engaged in their care process through visual progress indicators. Our AI-driven real-time postural assessment app automatically scans 14 different biomechanical markers. These markers are measured against each other, for degree of tilt, or asymmetry. We then provide a way for clinicians to better engage with patients at home by connecting our clinical apps to our patient-facing home app.",
        image: prodImgDesktop,
        price_per_year: 1600,
        version: 11, // version keeps track of the product price (helps for better cache management)
      },
    ],
    currentProductId: productType(location.pathname).number,
    currentProductVersion: 11,

    currency: "Canadian Dollar",
    currencyAbbr: "CAD",
    currencySymbol: "C$",

    encryptionKey: "secured information of the bodiometer product page",
  });

  // console.log("location.pathname.split('/'): ", location.pathname.split("/")[2], productType(location.pathname).video, state);

  const EncryptedObject = (obj) => {
    // loading encrytion module
    var CryptoJS = require("crypto-js");
    var ciphertext = CryptoJS.AES.encrypt(JSON.stringify(obj), state.encryptionKey);
    return ciphertext.toString();
  };

  const DecryptObject = (obj) => {
    // loading decrytion module
    var CryptoJS = require("crypto-js");
    var bytes = CryptoJS.AES.decrypt(obj, state.encryptionKey);
    var decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    return decryptedData;
  };

  // Show or hide demo based on
  const ShowDemo = () => {
    setState({
      ...state,
      showDemo: true,
    });
  };

  const HideDemo = () => {
    setState({
      ...state,
      showDemo: false,
    });
  };

  // display or hide shopping cart
  const ShowCart = (e) => {
    var product_list = state.product_list;
    if (product_list[e.target.value].quantity === 0) {
      product_list[e.target.value].quantity = 1;
    }
    setState({
      ...state,
      currentProductId: Number(e.target.value),
      showCart: true,
      product_list: product_list,
    });

    store.set("product_list", EncryptedObject(state.product_list));
  };

  const HideCart = () => {
    let product_id = 0;
    switch (state.product) {
      case "desktop": {
        product_id = 2;
        break;
      }
      case "pro": {
        product_id = 1;
        break;
      }
      case "lite": {
        product_id = 0;
        break;
      }
    }
    setState({
      ...state,
      showCart: false,
      currentProductId: product_id,
    });
  };

  // type='video/webm; codecs="vp8, vorbis"'

  return (
    <div className="product-page-wrapper">
      {state.product === "pro" && <MetaDecorator description="Bodiometer Smart Body Scanner App for Clinicians, Ergonomists, & Human Performance Coaches. Scan Your Clients' Biomechanics in Less than 1 Minute. Learn More!" title="Bodiometer Pro - Body Scanner App" url="https://www.bodiometer.com/product/pro" />}
      {state.product === "desktop" && <MetaDecorator description="Bodiometer Smart Biomechanical Analysis for Clinicians, Ergonomists, & Human Performance Coaches. Scan Your Clients' Biomechanics in Less than 1 Minute. Learn More!" title="Bodiometer Desktop - Biomechanical Analysis" url="https://www.bodiometer.com/product/desktop" />}
      {state.product === "dashboard" && <MetaDecorator description="Bodiometer Smart Biomechanical Analysis for Clinicians, Ergonomists, & Human Performance Coaches. Scan Your Clients' Biomechanics in Less than 1 Minute. Learn More!" title="Bodiometer Dashboard - Patient Engagement Platform" url="https://www.bodiometer.com/product/desktop" />}
      {/* Showcase product */}

      <NavBar />

      {/* Modals are shown initially, only when user interacts they are displayed */}
      {/* This modal is for the video demonstration */}
      <Modal show={state.showDemo} onEscapeKeyDown={HideDemo} size={"lg"} centered={true}>
        <Modal.Body>
          <video autoPlay={true} loop={true} width="100%" controls>
            <source src={state.videoSrc} type="video/mp4" />
          </video>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={HideDemo}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      {/* This modal (a view that pops up) is to display the current cart */}
      {/* <ProductModal type={state.product} state={state} setState={setState} /> */}

      {/*  page content   */}

      <div className="products-hero-wrapper">
        <MediaQuery orientation="landscape">
          <div className="video-background">
            <div className="video-foreground">
              {state.product === "pro" && (
                <video autoPlay={true} loop={true} muted={true} autobuffer playsInline>
                  <source src={bodiometerWorkflowLandscape} type="video/mp4" />
                </video>
              )}
              {state.product === "desktop" && (
                <video autoPlay={true} loop={true} muted={true} autobuffer playsInline>
                  <source src={desktopBg} type="video/mp4" />
                </video>
              )}

              {state.product === "dashboard" && (
                <video autoPlay={true} loop={true} muted={true} autobuffer playsInline>
                  <source src={videoDashboard} type="video/mp4" />
                </video>
              )}
            </div>
          </div>
        </MediaQuery>

        <MediaQuery orientation="portrait">
          <div className="video-background">
            <div className="video-foreground">
              {/* <source src={state.product == 'pro' ? bodiometerWorkflowPortrait : desktopBg} type="video/mp4" /> */}

              {state.product === "pro" && (
                <video autoPlay={true} loop={true} muted={true} autobuffer playsInline>
                  <source src={bodiometerWorkflowPortrait} type="video/mp4" />
                </video>
              )}
              {state.product === "desktop" && (
                <video autoPlay={true} loop={true} muted={true} autobuffer playsInline>
                  <source src={desktopBg} type="video/mp4" />
                </video>
              )}

              {state.product === "dashboard" && (
                <video autoPlay={true} loop={true} muted={true} autobuffer playsInline>
                  <source src={videoDashboard} type="video/mp4" />
                </video>
              )}
            </div>
          </div>
        </MediaQuery>

        <Container className="centered-container main-title-h2 product-single-title">
          <Row>
            <Col>
              <div className="title-icon-stacked">
                <img src={`../../photos/icons/${state.product}-white.svg`} width="80" alt="Bodiometer for Professionals - product icon" />
                <h2 className="white-color">
                  Bodiometer <span>{state.product}</span>
                </h2>
              </div>
            </Col>
          </Row>
        </Container>
      </div>

      <section className="body-top-wrapper main-title-h1">
        <Container>
          <Row className="centered-text centered-row">
            <Col lg={9}>
              {state.product === "pro" && (
                <div>
                  <h1 className="gray-color">The World’s First Real-time Posture Assessment Body Scanner App</h1>
                  <hr />
                  <br />
                  <p>Bodiometer Pro body scanner app enables clinicians to quickly and accurately assess patient posture without markers or other devices in almost any setting. Bodiometer utilizes an AI-driven computer vision system to automatically extract patient skeletal data relying only on the camera of the mobile device.</p>
                </div>
              )}
              {state.product === "desktop" && (
                <div>
                  <h1 className="gray-color">Our Most Advanced Clinical Biomechanical Analysis Solution</h1>
                  <hr />
                  <br />
                  <p>Using markerless motion-tracking, you can quickly and accurately perform biomechanical analysis and measure range of motion, posture, and functional movement patterns. Our range of motion module allows you to measure 14 different joints in a variety of different movements. Our posture module allows you to quickly measure posture in the coronal, sagittal and traverse planes. Lastly, our functional movement module allows you to measure any kind of functional movement you can think of and compare a patient’s performance over time.</p>
                </div>
              )}

              {state.product === "dashboard" && (
                <div>
                  <h1 className="gray-color">Innovative Patient Engagement Platform</h1>
                  <hr />
                  <br />
                  <p>By connecting Bodiometer Pro to Bodiometer Home, patient engagement can be tracked throughout the entire patient journey. This bridges the current gap in patient-practitioner interactions between treatment visits and helps allied health professionals enhance their patient experience, maximize patient retention and drive referrals and revenue to their clinics.</p>
                </div>
              )}
            </Col>
          </Row>
        </Container>
      </section>
      <section className="product-carousel-section-wrapper on-top">
        <Container fluid className="product-details-wrapper">
          <Row xs={1} md={1} lg={2} noGutters className="product-details-section">
            <Col>
              {state.product === "pro" && (
                <div>
                  <h3 className="gray-color">Effective Progress Tracking</h3>
                  <p>Patient scans are saved in the body scanner app, enabling users to track progress or changes in their posture over the course of treatment. Clinicians can compare scans directly with comparative reports. These visual reports are easy to print or share with the patient and leverage patient education and engagement resulting in better outcomes for everyone.</p>
                </div>
              )}
              {state.product === "desktop" && (
                <div>
                  <h3 className="gray-color">Comprehensive Tracking</h3>
                  <p>Bodiometer allows you to track patient data easily, and show a patient their biomechanical analysis progress with engaging and informative progress reports. Bodiometer gives you the tools to collect accurate, objective data quickly, and educate your patients about the structure of their bodies.</p>
                </div>
              )}

              {state.product === "dashboard" && (
                <div>
                  <h3 className="gray-color">Objective Progress Tracking</h3>
                  <p>Bodiometer’s web dashboard is specifically tailored to provide clinicians with rich analytics and an intelligent support system with visual aids and progress indicators. For clinicians, this provides AI-driven big data analytics to assist with triage, prognosis, and evaluation of objective and subjective findings.</p>
                </div>
              )}
            </Col>
            {state.product === "pro" && (
              <Col>
                <div className="product-images-wrapper">
                  <Carousel hover="pause" fade={true} className="applications-carousel" fade={false} pause="hover" interval={null} nextIcon={<img src="../../photos/consumer-landing/next-arrow.svg" height="27" alt="next carousel item - arrow icon" />}>
                    <Carousel.Item>
                      <img src="../../photos/products/mobile-image-1.jpg" width="100%" alt="Bodiometer body scanner app - product gallery 1" />
                      <div className={`${state.product}-image-1`}></div>
                    </Carousel.Item>
                    <Carousel.Item>
                      <img src="../../photos/products/mobile-image-1.jpg" width="100%" alt="Bodiometer body scanner app - product gallery 2" />
                      <div className={`${state.product}-image-2`}></div>
                    </Carousel.Item>
                    <Carousel.Item>
                      <img src="../../photos/products/mobile-image-1.jpg" width="100%" alt="Bodiometer body scanner app - product gallery 3" />
                      <div className={`${state.product}-image-3`}></div>
                    </Carousel.Item>
                  </Carousel>
                </div>
              </Col>
            )}
            {state.product === "desktop" && (
              <Col>
                <img src={`../../photos/products/single-desktop-image.png`} width="100%" alt="Bodiometer biomechanical analysis - product gallery 1" />
              </Col>
            )}

            {state.product === "dashboard" && (
              <Col>
                <div className="product-images-wrapper">
                  <Carousel hover="pause" fade={true} className="applications-carousel" fade={false} pause="hover" interval={null} nextIcon={<img src="../../photos/consumer-landing/next-arrow.svg" height="27" alt="next carousel item - arrow icon" />}>
                    <Carousel.Item>
                      <img src="../../photos/products/mobile-image-1.jpg" width="100%" alt="Bodiometer body scanner app - product gallery 1" />
                      <div className={`${state.product}-image-1`}></div>
                      {/* <div className={`dashboard-image-1`}></div> */}
                    </Carousel.Item>
                    <Carousel.Item>
                      <img src="../../photos/products/mobile-image-1.jpg" width="100%" alt="Bodiometer body scanner app - product gallery 2" />
                      <div className={`${state.product}-image-2`}></div>
                      {/* <div className={`pro-image-2`}></div> */}
                    </Carousel.Item>
                    <Carousel.Item>
                      <img src="../../photos/products/mobile-image-1.jpg" width="100%" alt="Bodiometer body scanner app - product gallery 3" />
                      <div className={`${state.product}-image-3`}></div>
                      {/* <div className={`pro-image-3`}></div> */}
                    </Carousel.Item>
                    <Carousel.Item>
                      <img src="../../photos/products/mobile-image-1.jpg" width="100%" alt="Bodiometer body scanner app - product gallery 3" />
                      <div className={`${state.product}-image-4`}></div>
                      {/* <div className={`pro-image-3`}></div> */}
                    </Carousel.Item>
                  </Carousel>
                </div>
              </Col>
            )}
          </Row>
        </Container>
      </section>

      {state.product === "pro" && (
        <section className="light-grad-bulge product-features-wrapper">
          <Container className="centered-container">
            <Row xs={1} md={3}>
              <Col>
                <div className="title-icon">
                  <img src="../../photos/icons/real-time.svg" height="35" alt="Bodiometer body scanner app features - real-time icon" />
                  <h3 className="blue-color">Real-time</h3>
                </div>
                <p>Keep clients engaged with real-time posture visualization</p>
              </Col>
              <Col>
                <div className="title-icon">
                  <img src="../../photos/icons/interactive.svg" height="35" alt="Bodiometer body scanner app features - interactive icon" />
                  <h3 className="blue-color">Interactive</h3>
                </div>
                <p>Drive engagement with real-time data of your client’s posture</p>
              </Col>
              <Col>
                <div className="title-icon">
                  <h2 className="green-color">
                    Key
                    <br />
                    Features
                  </h2>
                  <img src="../../photos/icons/check-green.svg" height="80" alt="Bodiometer body scanner app features - title icon" />
                </div>
              </Col>
            </Row>
            <Row xs={1} md={3}>
              <Col>
                <div className="title-icon">
                  <img src="../../photos/icons/precision.svg" height="35" alt="Bodiometer body scanner app features - precise measures icon" />
                  <h3 className="blue-color">Precise Measures</h3>
                </div>
                <p>Postural readings are accurate and consistent</p>
              </Col>
              <Col>
                <div className="title-icon">
                  <img src="../../photos/icons/unlimited.svg" height="25" alt="Bodiometer body scanner app features - unlimited usage icon" />
                  <h3 className="blue-color">Unlimited Usage</h3>
                </div>
                <p>There is no limit to access all features for clinicians and users</p>
              </Col>
              <Col>
                <div className="title-icon">
                  <img src="../../photos/icons/tracking.svg" height="35" alt="Bodiometer body scanner app features - progress charts icon" />
                  <h3 className="blue-color">Progress Charts</h3>
                </div>
                <p>Generate and send progress reports to your clients for tracking</p>
              </Col>
            </Row>
          </Container>
        </section>
      )}

      {state.product === "desktop" && (
        <section className="light-grad-bulge product-features-wrapper">
          <Container className="centered-container">
            <Row xs={1} md={3}>
              <Col>
                <div className="title-icon">
                  <img src="../../photos/icons/real-time.svg" height="35" alt="Bodiometer desktop biomechanical analysis features - real-time icon" />
                  <h3 className="blue-color">Real-time</h3>
                </div>
                <p>Keep clients engaged with real-time posture visualization</p>
              </Col>
              <Col>
                <div className="title-icon">
                  <img src="../../photos/icons/interactive.svg" height="35" alt="Bodiometer body scanner app features - interactive icon" />
                  <h3 className="blue-color">Interactive</h3>
                </div>
                <p>Drive engagement with real-time data of your client’s posture</p>
              </Col>
              <Col>
                <div className="title-icon">
                  <h2 className="green-color">
                    Key
                    <br />
                    Features
                  </h2>
                  <img src="../../photos/icons/check-green.svg" height="80" alt="Bodiometer body scanner app features - title icon" />
                </div>
              </Col>
            </Row>
            <Row xs={1} md={3}>
              <Col>
                <div className="title-icon">
                  <img src="../../photos/icons/unlimited.svg" height="25" alt="Bodiometer body scanner app features - unlimited usage icon" />
                  <h3 className="blue-color">Unlimited Usage</h3>
                </div>
                <p>There is no limit to access all features for clinicians and users</p>
              </Col>
              <Col>
                <div className="title-icon">
                  <img src="../../photos/icons/precision.svg" height="35" alt="Bodiometer body scanner app features - precise measures icon" />
                  <h3 className="blue-color">Precise Measures</h3>
                </div>
                <p>Postural readings are accurate and consistent</p>
              </Col>
              <Col>
                <div className="title-icon">
                  <img src="../../photos/icons/tracking.svg" height="35" alt="Bodiometer body scanner app features - progress charts icon" />
                  <h3 className="blue-color">Progress Charts</h3>
                </div>
                <p>Generate and send progress reports to your clients for tracking</p>
              </Col>
            </Row>
          </Container>
        </section>
      )}

      {state.product === "dashboard" && (
        <section className="light-grad-bulge product-features-wrapper">
          <Container className="centered-container">
            <Row xs={1} md={3}>
              <Col>
                <div className="title-icon">
                  <img src="../../photos/icons/increase-patient-retention.svg" height="35" alt="Bodiometer desktop biomechanical analysis features - real-time icon" style={{color: "var(--primary-blue)"}} />
                  <h3 className="blue-color">Increase Patient Retention Rate</h3>
                </div>
                <p>Increase clinic revenue by improving patient retention rate </p>
              </Col>
              <Col>
                <div className="title-icon">
                  <img src="../../photos/icons/maximize-patient-engagement.svg" height="35" alt="Bodiometer body scanner app features - interactive icon" />
                  <h3 className="blue-color">Maximize Patient Engagement</h3>
                </div>
                <p>Increase patients' attachement to the care pathway through personalized reports and touch points</p>
              </Col>
              <Col>
                <div className="title-icon">
                  <h2 className="green-color">
                    Key
                    <br />
                    Benefits
                  </h2>
                  <img src="../../photos/icons/check-green.svg" height="80" alt="Bodiometer body scanner app features - title icon" />
                </div>
              </Col>
            </Row>
            <Row xs={1} md={3}>
              <Col>
                <div className="title-icon">
                  <img src="../../photos/icons/drive-new-referrals.svg" height="25" alt="Bodiometer body scanner app features - unlimited usage icon" />
                  <h3 className="blue-color">Drive New Patient Referrals</h3>
                </div>
                <p>Increase clinic visibility by bringing new referrals from happy patients</p>
              </Col>
              <Col>
                <div className="title-icon">
                  <img src="../../photos/icons/reduce-assessment-time.svg" height="35" alt="Bodiometer body scanner app features - precise measures icon" />
                  <h3 className="blue-color">Reduce Assessment Time</h3>
                </div>
                <p>Provide objective postural reports in less than a minute using a consumer mobile device</p>
              </Col>
              <Col>
                <div className="title-icon">
                  <img src="../../photos/icons/enhance-patient-experience.svg" height="35" alt="Bodiometer body scanner app features - progress charts icon" />
                  <h3 className="blue-color">Enhance Patient Experience</h3>
                </div>
                <p>Increase patients' awareness about treatment by educating them on their progress in clinic and at home</p>
              </Col>
            </Row>
            <Row xs={1} md={3} style={{marginTop: "0px"}}>
              <Col>
                <div className="title-icon">
                  <img src="../../photos/icons/gain-valuable-insights.svg" height="35" alt="Bodiometer body scanner app features - progress charts icon" />
                  <h3 className="blue-color">Gain Valuable Insights</h3>
                </div>
                <p>Provide objective treatment outcomes and clear changes thruogh advanced analytics and visualization</p>
              </Col>
            </Row>
          </Container>
        </section>
      )}

      <section className="cta-centered">
        <Container className="centered-container">
          {state.product === "pro" && (
            <h3 className="gray-color">
              Ready to transform your posture assessments with Bodiometer Pro?
              <br />
              {/* Register today and claim your <del>14 day</del> {props.freeTrialLength} day Free Trial! */}
              Register today and claim your 14 day Free Trial!{" "}
            </h3>
          )}
          {state.product === "desktop" && (
            <h3 className="gray-color">
              Ready to transform your posture assessments with Bodiometer Desktop?
              <br />
              inquire today for pricing and information
            </h3>
          )}

          {state.product === "dashboard" && (
            <h3 className="gray-color">
              Ready to increase patient engagment with Bodiometer Dashboard? 
              <br /> 
              {/* Register today and claim your <del>14 day</del> 30 day Free Trial!{" "} */}
              Register today and claim your 14 day Free Trial!{" "}
            </h3>
          )}
          <hr></hr>
          <div className="two-buttons">
            {/* <Button size="lg" variant="primary" value={state.product === 'pro' ? 1 : 2} onClick={ShowCart}>Buy Now</Button>  */}
            {state.product === "pro" && (
              <Fragment>
                <Button size="lg" variant="primary" href="/portal/register" target="_self">
                  Get Started
                </Button>
                <Button target="_blank" size="lg" variant="outline-primary" href={"https://apps.apple.com/ca/app/bodiometer/id1441230362"}>
                  Download
                </Button>
              </Fragment>
            )}

            {state.product === "desktop" && (
              <Fragment>
                <Button size="lg" variant="primary" href="/contact" target="_self">
                  Get A Quote
                </Button>
                <Button rel="noopener noreferrer" target="_blank" size="lg" variant="outline-primary" href={"https://www.microsoft.com/en-us/store/p/physio4d/9n2sw42jf4kz"}>
                  Download
                </Button>
              </Fragment>
            )}

            {state.product === "dashboard" && (
              <Fragment>
                <Button size="lg" variant="primary" href="/portal/register" target="_self">
                  Get Started
                </Button>
              </Fragment>
            )}
          </div>
        </Container>
      </section>

      <Footer />
      {/* <!-- Start of HubSpot Embed Code --> */}
      <script type="text/javascript" id="hs-script-loader" async defer src="//js.hs-scripts.com/2936832.js"></script>
      {/* <!-- End of HubSpot Embed Code --> */}
    </div>
  );
}
