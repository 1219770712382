import React, { Fragment, useEffect, useState, useRef } from "react";
import { Alert, Row, Col, Form, Spinner } from "react-bootstrap";
import axios from "axios";
import md5Hash from "../resources/helpers/md5.js";
import { useAppContext, useUserDashboardContext } from "../lib/context.js";

import UiButton from "../components/dashboard/ui-button.js";
import UiPassword from "../components/dashboard/ui-password.js";

import "bootstrap/dist/css/bootstrap.min.css";
import "../resources/styles/dashboard.scss";

const UserSecurity = ({ dbUrl }) => {
  const { appState } = useAppContext();
  const { dashboardState } = useUserDashboardContext();
  const businessId = dashboardState.ActiveClinicId;
  const userId = appState.userData.Id;

  const [state, setState] = useState({
    consentId: "",
    scanConsent: false,
    journalConsent: false,
    consentSuccess: [false, ""],
    consentError: [false, ""],
    passwordSuccess: [false, ""],
    passwordError: [false, ""],
    isLoading: false,
  });

  const InitialPasswordState = {
    EditFormValidated: false,
    Error: [false, ""],
    Success: [false, ""],
    Different: false,
    PassType: "password",
    PassValue: "",
    PassValue0: "",
    PassValue1: "",
    PassValue2: "",
    InValid0: [false, "Incorrect password"],
    InValid1: [false, "Something went wrong..."],
    InValid2: [false, "Something went wrong..."],
  };

  const [passwordState, setPasswordState] = useState({ ...InitialPasswordState });

  // hide success/error alert after 4 seconds
  useEffect(() => {
    const timer = setTimeout(() => {
      setPasswordState(prev => ({ ...prev, Success: [false, ""], Error: [false, ""] }));
    }, 4000);
    return () => clearTimeout(timer);
  }, [passwordState.Success, passwordState.Error]);

  const PasswordCheck = (e, retype) => {
    const field = e.currentTarget;
    if (field.value.length < 6) {
      setPasswordState({ ...passwordState, InValid1: [true, "Password is too short"] });
    } else if (field.value.length >= 6) {
      if (passwordState.PassValue1 !== passwordState.PassValue2) {
        setPasswordState({ ...passwordState, InValid2: [true, "Password doesn't match"], InValid1: [false, ""] });
      } else {
        setPasswordState({ ...passwordState, InValid2: [false, ""], InValid1: [false, ""] });
      }
    }
    if (retype) {
      if (field.value !== passwordState.PassValue1) {
        setPasswordState({ ...passwordState, InValid2: [true, "Password doesn't match"] });
      } else {
        setPasswordState({ ...passwordState, InValid2: [false, ""], Different: true });
      }
    }
  };

  const HandlePassChange = (e, retype) => {
    if (!retype) {
      setPasswordState({ ...passwordState, PassValue1: e.currentTarget.value });
    } else if (retype) {
      setPasswordState({ ...passwordState, PassValue2: e.currentTarget.value });
    }
  };

  const HandleCurrentCheck = (e) => {
    // if password matches current user password
    if (e.currentTarget.value) {
      return true;
    } else {
      setState({ ...state, InValid0: [true, "Incorrect password"] });
    }
  };

  const HandleCurrentChange = (e) => {
    setPasswordState({ ...passwordState, PassValue0: e.currentTarget.value });
  };

  const LoadContent = async () => {
    try {
      setState({ ...state, isLoading: true });
      let configOne = {
        method: "POST",
        url: dbUrl + "/api/token/dbo",
        headers: {
          password: "746C419785D84DC8B3A20B9195363105",
        },
      };

      let responseOne = await axios(configOne, { timeout: 5000 });
      if (responseOne.data != "Invalid Credentials") {
        let configTwo = {
          method: "GET",
          url: dbUrl + "/api/patientusermerges/v2/userid/businessid",
          headers: {
            Authorization: "Bearer " + responseOne.data,
            userId: userId,
            businessId: businessId,
          },
        };

        let responseTwo = await axios(configTwo, { timeout: 5000 });

        let configThree = {
          method: "GET",
          url: dbUrl + "/api/personalusers/v1/get/id",
          headers: {
            Authorization: "Bearer " + responseOne.data,
            userId: userId,
          },
        };

        let responseThree = await axios(configThree, { timeout: 5000 });

        if (responseThree.data.success) {
          setPasswordState({ ...passwordState, PassValue: responseThree.data.data.Password });
        }

        if (responseTwo.data.success) {
          setState({
            ...state,
            journalConsent: responseTwo.data.data.JournalConsent,
            scanConsent: responseTwo.data.data.ScanConsent,
            consentId: responseTwo.data.data.Id,
            isLoading: false,
          });
        } else {
          setState({
            ...state,
            consentError: [false, "something wrong happened"],
            isLoading: false,
          });
        }
      }
    } catch (err) {
      setState({
        ...state,
        consentError: [false, "something wrong happened"],
        isLoading: false,
      });
    }
  };

  const ConsentUpdateHandler = async () => {
    setState({ ...state, isLoading: true });
    try {
      let configOne = {
        method: "post",
        url: dbUrl + "/api/token/dbo",
        headers: {
          password: "746C419785D84DC8B3A20B9195363105",
        },
      };

      let responseOne = await axios(configOne, { timeout: 5000 });
      if (responseOne.data != "Invalid Credentials") {
        let configTwo = {
          method: "PUT",
          url: dbUrl + "/api/patientusermerges/v1/update/id",
          headers: {
            Authorization: `Bearer ${responseOne.data}`,
            id: state.consentId,
            "Content-Type": "application/json",
          },
          data: JSON.stringify({
            scanConsent: state.scanConsent,
            journalConsent: state.journalConsent,
          }),
        };

        let responseTwo = await axios(configTwo, { timeout: 5000 });
        if (responseTwo.data.success) {
          setState({
            ...state,
            success: [true, "consent updated successfully"],
            isLoading: false,
          });
        } else {
          setState({ ...state, error: [true, "failed to update consent"], isLoading: false });
        }
      } else {
      }
    } catch {
      setState({ ...state, error: [true, "failed to update consent"], isLoading: false });
    }
  };

  useEffect(() => {
    LoadContent();
  }, []);

  const PasswordSubmissionHandler = async (e) => {
    try {
      e.preventDefault();
      e.stopPropagation();
      setState({ ...state, isLoading: true });

      let currentPassword = md5Hash(passwordState.PassValue0);
      let newPassword = md5Hash(passwordState.PassValue1);

      if (currentPassword == passwordState.PassValue) {
        let configOne = {
          method: "POST",
          url: dbUrl + "/api/token/dbo",
          headers: {
            password: "746C419785D84DC8B3A20B9195363105",
          },
        };

        let responseOne = await axios(configOne, { timeout: 5000 });
        if (responseOne.data != "Invalid Credentials") {
          let configTwo = {
            method: "PUT",
            url: dbUrl + "/api/personalusers/v1/update/id",
            headers: {
              Authorization: "Bearer " + responseOne.data,
              "Content-Type": "application/json",
              userId: userId,
            },
            data: JSON.stringify({
              Password: newPassword,
            }),
          };

          let responseTwo = await axios(configTwo, { timeout: 5000 });

          if (responseTwo.data.success) {
            setState({ ...state, isLoading: false });
            setPasswordState({
              ...InitialPasswordState,
              Success: [true, "password changed successfully"],
            });
          } else {
            throw new Error("something went wrong");
          }
        } else {
          throw new Error("something went wrong");
        }
      } else {
        throw new Error("something went wrong");
      }
    } catch {
      setState({ ...state, isLoading: false });
      setPasswordState({
        ...InitialPasswordState,
        Error: [true, "password change failed"],
      });
    }
  };

  return (
    <div style={{ width: "100%", paddingTop: "10vh", paddingLeft: "20px" }}>
      {state.isLoading ? (
        <div className="preloader-wrapper">
          <Spinner animation="grow" variant="secondary" />
        </div>
      ) : (
        <div className="ui-section-wrapper">
          {!!state.consentId && (
            <Fragment>
              <section>
                <Row>
                  <Col className="flex-row no-wrap">
                    <h1>Consent</h1>
                    <Alert variant="warning" show={state.consentError[0] ? true : false}>
                      <div className="flex-row">
                        <p>{state.consentError[1]}</p>
                      </div>
                    </Alert>
                    <Alert variant="info" show={state.consentSuccess[0] ? true : false}>
                      <div className="flex-row">
                        <p>{state.consentSuccess[1]}</p>
                      </div>
                    </Alert>
                  </Col>
                </Row>
              </section>
              <section className="form-section-wrapper">
                <Row>
                  <Form.Group as={Col} controlId="scan_consent_checkbox" className="form-footer-wrapper terms-wrapper">
                    <Form.Check type="checkbox">
                      <Form.Check.Input
                        checked={state.scanConsent}
                        onChange={() => {
                          setState({
                            ...state,
                            scanConsent: !state.scanConsent,
                          });
                        }}
                        type={"checkbox"}
                      />
                      <Form.Check.Label className="gray-color">
                        I agree to share my scans with the clinic
                      </Form.Check.Label>
                    </Form.Check>
                  </Form.Group>
                </Row>
                <Row>
                  <Form.Group
                    as={Col}
                    controlId="journal_consent_checkbox"
                    className="form-footer-wrapper terms-wrapper"
                  >
                    <Form.Check type="checkbox">
                      <Form.Check.Input
                        checked={state.journalConsent}
                        onChange={() => {
                          setState({
                            ...state,
                            journalConsent: !state.journalConsent,
                          });
                        }}
                        type={"checkbox"}
                      />
                      <Form.Check.Label className="gray-color">
                        I agree to share my journals with the clinic
                      </Form.Check.Label>
                    </Form.Check>
                  </Form.Group>
                </Row>
                <Row>
                  <Form.Group controlId="consent_button" className="form-footer-wrapper">
                    <UiButton type="submit" onClick={() => ConsentUpdateHandler()}>
                      Update Consent
                    </UiButton>
                  </Form.Group>
                </Row>
              </section>
            </Fragment>
          )}

          <section>
            <Row>
              <Col className="flex-row no-wrap">
                <h1>Password</h1>
                <Alert variant="warning" show={passwordState.Error[0] ? true : false}>
                  <div className="flex-row">
                    <p>{passwordState.Error[1]}</p>
                  </div>
                </Alert>
                <Alert variant="info" show={passwordState.Success[0] ? true : false}>
                  <div className="flex-row">
                    <p>{passwordState.Success[1]}</p>
                  </div>
                </Alert>
              </Col>
            </Row>
          </section>

          {!!userId && (
            <Fragment>
              <section className="form-section-wrapper">
                <Row>
                  <Col lg={6} md={12}>
                    <Form validated={passwordState.EditFormValidated}>
                      <Row>
                        <UiPassword
                          // label="Current Password"
                          placeholder="Current Password"
                          controlId="current_password"
                          feedback="Please enter your current password"
                          value={passwordState.PassValue0}
                          onChange={HandleCurrentChange}
                          onBlur={HandleCurrentCheck}
                          isInvalid={passwordState.InValid0[0]}
                          message={passwordState.InValid0[1]}
                        />
                      </Row>

                      <Row>
                        <UiPassword
                          // label="New Password"
                          text="At least 6 characters, but longer is better."
                          placeholder="New Password"
                          controlId="new_password"
                          feedback="Password must be at least 6 characters in length"
                          value={passwordState.PassValue1}
                          onChange={HandlePassChange}
                          onBlur={PasswordCheck}
                          isInvalid={passwordState.InValid1[0]}
                          message={passwordState.InValid1[1]}
                        />
                      </Row>

                      <Row>
                        <UiPassword
                          // label="Retype New Password"
                          placeholder="Retype New Password"
                          controlId="new_password2"
                          retype
                          value={passwordState.PassValue2}
                          onChange={HandlePassChange}
                          onBlur={PasswordCheck}
                          isInvalid={passwordState.InValid2[0]}
                          message={passwordState.InValid2[1]}
                        />
                      </Row>

                      <Row>
                        <Form.Group controlId="consent" className="form-footer-wrapper">
                          <UiButton
                            type="submit"
                            variant="secondary"
                            disabled={passwordState.Different ? false : true}
                            onClick={(e) => PasswordSubmissionHandler(e)}
                          >
                            Save
                          </UiButton>
                        </Form.Group>

                        <Form.Group controlId="consent" className="form-footer-wrapper">
                          <UiButton
                            onClick={(e) => setPasswordState({ ...InitialPasswordState })}
                            variant="link"
                            className="gray-color no-padding"
                            visibility={passwordState.Different ? false : true}
                          >
                            Cancel
                          </UiButton>
                        </Form.Group>
                      </Row>
                    </Form>
                  </Col>
                </Row>
              </section>
            </Fragment>
          )}
        </div>
      )}
    </div>
  );
};

export default UserSecurity;
