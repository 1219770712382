import React, { useState, Fragment, useRef, useEffect } from "react";
import { Col, Row, Form, Button, ButtonGroup } from "react-bootstrap";

import "bootstrap/dist/css/bootstrap.min.css";
import "react-phone-number-input/style.css";
import "react-datetime/css/react-datetime.css";
import "../Assets/style.scss";
import HumanBodyImg from "../Assets/human_body.png";

const ScreenAndClearance = ({ data, requiredDb, validatedDb, isValidDb, pageCompleted, setPageCompleted, activeIndex, viewInvalidFields, onChange }) => {
	const [validatedFields, setValidatedFields] = useState({ ...validatedDb });
	const [isValidFields, setIsValidFields] = useState({ ...isValidDb });
	const requiredFields = { ...requiredDb };

	const Header = ({ label }) => {
		return <h2 style={{ textAlign: "center", marginBottom: "20px" }}>{label}</h2>;
	};

	const SubHeader = ({ label }) => {
		return <h4 style={{ textAlign: "left", marginBottom: "20px", marginTop: "20px" }}>{label}</h4>;
	};

	const GetPainLevels = (data) => {
		return [
			data.FrontFaceRight,
			data.FrontFaceLeft,
			data.FrontHead,
			data.FrontTrapsRight,
			data.FrontTrapsLeft,
			data.FrontNeck,
			data.FrontShoulderRight,
			data.FrontShoulderLeft,
			data.FrontChestRight,
			data.FrontChestLeft,
			data.FrontUpperAbdominal,
			data.FrontAbsRight,
			data.FrontAbsLeft,
			data.FrontLowerAbdominal,
			data.FrontHipRight,
			data.FrontHipLeft,
			data.FrontUpperLegRight,
			data.FrontUpperLegLeft,
			data.FrontKneeJointRight,
			data.FrontKneeJointLeft,
			data.FrontLowerLegRight,
			data.FrontLowerLegLeft,
			data.FrontAnkleRight,
			data.FrontAnkleLeft,
			data.FrontFootRight,
			data.FrontFootLeft,
			data.FrontBicepsRight,
			data.FrontBicepsLeft,
			data.FrontElbowJointRight,
			data.FrontElbowJointLeft,
			data.FrontForeArmsRight,
			data.FrontForeArmsLeft,
			data.FrontWristJointRight,
			data.FrontWristJointLeft,
			data.FrontHandRight,
			data.FrontHandLeft,
			data.BackHead,
			data.BackNeck,
			data.BackTrapsLeft,
			data.BackTrapsRight,
			data.BackUpperBackLeft,
			data.BackUpperBackRight,
			data.BackMiddleBack,
			data.BackLowerBack,
			data.BackSijointLeft,
			data.BackSijointRight,
			data.BackSacrum,
			data.BackGlutesLeft,
			data.BackGlutesRight,
			data.BackUpperLegLeft,
			data.BackUpperLegRight,
			data.BackHamstringLeft,
			data.BackHamstringRight,
			data.BackKneeJointLeft,
			data.BackKneeJointRight,
			data.BackUpperCalvesLeft,
			data.BackUpperCalvesRight,
			data.BackCalvesLeft,
			data.BackCalvesRight,
			data.BackHeelLeft,
			data.BackHeelRight,
			data.BackShoulderLeft,
			data.BackShoulderRight,
			data.BackTricepsLeft,
			data.BackTricepsRight,
			data.BackElbowJointLeft,
			data.BackElbowJointRight,
			data.BackForeArmsLeft,
			data.BackForeArmsRight,
			data.BackWristJointLeft,
			data.BackWristJointRight,
			data.BackHandLeft,
			data.BackHandRight,
		];
	};

	const GetConditions = (data) => {
		var newData = { ...data };

		// console.log(newData);

		const IsComboValid = (combo) => {
			let result = false;
			for (var k in combo) {
				result = result || !!(newData[combo[k]] >= 1);
			}
			return result;
		};

		var comboxOne = [
			"FrontBicepsRight",
			"FrontElbowJointRight",
			"FrontForeArmsRight",
			"FrontWristJointRight",
			"FrontHandRight",
			"FrontBicepsLeft",
			"FrontElbowJointLeft",
			"FrontForeArmsLeft",
			"FrontWristJointLeft",
			"FrontHandLeft",
			"BackBicepsRight",
			"BackElbowJointRight",
			"BackForeArmsRight",
			"BackWristJointRight",
			"BackHandRight",
			"BackBicepsLeft",
			"BackElbowJointLeft",
			"BackForeArmsLeft",
			"BackWristJointLeft",
			"BackHandLeft",
		];
		var isComboxOne = IsComboValid(comboxOne);
		if (isComboxOne) {
			newData = { ...newData, ElbowWristHandPain: true, NumbnessArmsHands: true };
		}


		var comboThree = [
			"FrontHipRight",
			"FrontUpperLegRight",
			"FrontKneeJointRight",
			"FrontLowerLegRight",
			"FrontAnkleRight",
			"FrontFootRight",
			"FrontHipLeft",
			"FrontUpperLegLeft",
			"FrontKneeJointLeft",
			"FrontLowerLegLeft",
			"FrontAnkleLeft",
			"FrontFootLeft",
			"BackUpperLegLeft",
			"BackHamstringLeft",
			"BackKneeJointLeft",
			"BackUpperCalvesLeft",
			"BackCalvesLeft",
			"BackHeelLeft",
			"BackUpperLegRight",
			"BackHamstringRight",
			"BackKneeJointRight",
			"BackUpperCalvesRight",
			"BackCalvesRight",
			"BackHeelRight",
		];
		var isComboxThree = IsComboValid(comboThree);
		if (isComboxThree) {
			newData = { ...newData, HipKneeAnklePain: true, NumbnessLegsFeet: true };
		}


		var comboFive = ["FrontHead", "BackHead"];
		var isComboxFive = IsComboValid(comboFive);
		if (isComboxFive) {
			newData = { ...newData, Headache: true };
		}

		var comboSix = ["FrontFaceLeft", "FrontFaceRight"];
		var isComboxSix = IsComboValid(comboSix);
		if (isComboxSix) {
			newData = { ...newData, JawPain: true };
		}

		var comboSeven = ["FrontNeck", "BackNeck"];
		var isComboxSeven = IsComboValid(comboSeven);
		if (isComboxSeven) {
			newData = { ...newData, NeckPainStiffness: true };
		}

		var comboEight = ["FrontShoulderRight", "FrontTrapsRight", "FrontShoulderLeft", "FrontTrapsLeft", "BackShoulderRight", "BackTrapsRight", "BackShoulderLeft", "BackTrapsLeft", "BackUpperBackLeft", "BackUpperBackRight"];
		var isComboxEight = IsComboValid(comboEight);
		if (isComboxEight) {
			newData = { ...newData, ShoulderPain: true };
		}

		var comboNine = ["BackMiddleBack"];
		var isComboxNine = IsComboValid(comboNine);
		if (isComboxNine) {
			newData = { ...newData, MidBackPain: true };
		}


		
		var comboElleven = ["BackSijointLeft", "BackSijointRight", "BackLowerBack"];
		var isComboElleven = IsComboValid(comboElleven);
		if(newData.BackSacrum && !newData.BackSijointLeft && !newData.BackSijointRight && !newData.BackLowerBack){
			newData = { ...newData, TailbonePain: true };
		}
		else if(isComboElleven || newData.BackSacrum){
			newData = { ...newData, LowBackPain: true, TailbonePain: false };
		}

		return newData;
	};

	const ClearPainLevels = () => {
		return {
			...data,
			FrontFaceRight: 0,
			FrontFaceLeft: 0,
			FrontHead: 0,
			FrontTrapsRight: 0,
			FrontTrapsLeft: 0,
			FrontNeck: 0,
			FrontShoulderRight: 0,
			FrontShoulderLeft: 0,
			FrontChestRight: 0,
			FrontChestLeft: 0,
			FrontUpperAbdominal: 0,
			FrontAbsRight: 0,
			FrontAbsLeft: 0,
			FrontLowerAbdominal: 0,
			FrontHipRight: 0,
			FrontHipLeft: 0,
			FrontUpperLegRight: 0,
			FrontUpperLegLeft: 0,
			FrontKneeJointRight: 0,
			FrontKneeJointLeft: 0,
			FrontLowerLegRight: 0,
			FrontLowerLegLeft: 0,
			FrontAnkleRight: 0,
			FrontAnkleLeft: 0,
			FrontFootRight: 0,
			FrontFootLeft: 0,
			FrontBicepsRight: 0,
			FrontBicepsLeft: 0,
			FrontElbowJointRight: 0,
			FrontElbowJointLeft: 0,
			FrontForeArmsRight: 0,
			FrontForeArmsLeft: 0,
			FrontWristJointRight: 0,
			FrontWristJointLeft: 0,
			FrontHandRight: 0,
			FrontHandLeft: 0,
			BackHead: 0,
			BackNeck: 0,
			BackTrapsLeft: 0,
			BackTrapsRight: 0,
			BackUpperBackLeft: 0,
			BackUpperBackRight: 0,
			BackMiddleBack: 0,
			BackLowerBack: 0,
			BackSijointLeft: 0,
			BackSijointRight: 0,
			BackSacrum: 0,
			BackGlutesLeft: 0,
			BackGlutesRight: 0,
			BackUpperLegLeft: 0,
			BackUpperLegRight: 0,
			BackHamstringLeft: 0,
			BackHamstringRight: 0,
			BackKneeJointLeft: 0,
			BackKneeJointRight: 0,
			BackUpperCalvesLeft: 0,
			BackUpperCalvesRight: 0,
			BackCalvesLeft: 0,
			BackCalvesRight: 0,
			BackHeelLeft: 0,
			BackHeelRight: 0,
			BackShoulderLeft: 0,
			BackShoulderRight: 0,
			BackTricepsLeft: 0,
			BackTricepsRight: 0,
			BackElbowJointLeft: 0,
			BackElbowJointRight: 0,
			BackForeArmsLeft: 0,
			BackForeArmsRight: 0,
			BackWristJointLeft: 0,
			BackWristJointRight: 0,
			BackHandLeft: 0,
			BackHandRight: 0,
		};
	};

	const canvasRef = useRef(null);
	const canvasImg = new Image();
	const canvasImgWidth = 684;
	const canvasImgHeight = 779;
	var canvasTimeoutHandler = null;
	const [painLevels, setPainLevels] = useState(GetPainLevels(data));
	const [hasAllergies, setHasAllergies] = useState(data.Allergies.length > 0 || !!data.AllergyNotes);
	const [hasMedications, setHasMedications] = useState(data.Medications.length > 0 || !!data.MedicationNotes);
	const [hasMD, setHasMD] = useState(data.MedicalDevices.length > 0 || !!data.MedicalDeviceNotes);
	const [allergy, setAllergy] = useState(!!data.AllergyNotes ? "Other" : "");
	const [medication, setMedication] = useState(!!data.MedicationNotes ? "Other" : "");
	const [medDevice, setMedDevice] = useState(!!data.MedicalDeviceNotes ? "Other" : "");

	const CheckboxId = [
		"NeckPainStiffness",
		"ShoulderPain",
		"ArthritisNeck",
		"ElbowWristHandPain",
		"Headache",
		"HipKneeAnklePain",
		"JawPain",
		"Diabetes",
		"SkinCondition",
		"Anemia",
		"MidBackPain",
		"LowBackPain",
		"MuscleCrampSpasm",
		"TailbonePain",
		"CarpalTunnel",
		"NumbnessLegsFeet",
		"SwellingFeetHands",
		"NumbnessArmsHands",
		"ArthritisLimb",
		"Dizziness",
	];

	const CheckboxLabel = [
		"Neck Pain / Stiffness",
		"Shoulder Pain",
		"Arthritis in Neck",
		"Pain in Elbows / Wrists / Hands",
		"Headaches",
		"Pain in Hips / Knees / Ankles",
		"Jaw Pain / TMJ",
		"Diabetes",
		"Skin Conditions / Rashes",
		"Anemia / Fatigue",
		"Mid Back pain",
		"Low Back Pain / Stiffness",
		"Muscle Cramps / Spasms",
		"Tailbone Pain",
		"Carpal Tunnel",
		"Numbness / Tinglings in Legs / Feet",
		"Swelling Feet / Hands",
		"Numbness / Tinglings in Arms / Hands",
		"Arthritis in Limbs",
		"Dizziness",
	];

	const DrawCanvas = (painScores) => {
		var newPainLevels = [...painScores];

		var painDiagram = canvasRef.current;
		painDiagram.style.cursor = "pointer";
		var canvasContext = painDiagram.getContext("2d");

		var imageWidth = canvasImg.naturalWidth == 0 ? 600 : canvasImg.naturalWidth;
		var imageHeight = canvasImg.naturalHeight == 0 ? 800 : canvasImg.naturalHeight;
		var aspectRatio = imageWidth / imageHeight;
		var maxWidth = !!painDiagram.parentNode && "offsetWidth" in painDiagram.parentNode ? painDiagram.parentNode.offsetWidth : 600;
		var maxHeight = maxWidth / aspectRatio;
		canvasImg.width = maxWidth;
		canvasImg.height = maxHeight;
		var fontSizeLarge = maxWidth > 600 ? 18 : 10;
		canvasContext.canvas.width = maxWidth;
		canvasContext.canvas.height = maxHeight;
		canvasContext.drawImage(canvasImg, 0, 0, maxWidth, maxHeight);

		var CanvasEllipse = (context, cx, cy, rx, ry, color) => {
			context.beginPath();
			context.ellipse(cx, cy, rx, ry, 0, 0, Math.PI * 2);
			context.fillStyle = color;
			context.fill();
		};

		let circle_rad = Math.max(5, Math.round(maxWidth * 0.012));

		var DrawSideIndicators = () => {
			let x = 50,
				y = 50,
				w = 80,
				h = 80,
				r = 20; // rectangle location x, y, width, height, radius
			let image_x = (x / imageWidth) * maxWidth;
			let image_y = (y / imageHeight) * maxHeight;
			canvasContext.fillStyle = "rgba(0, 0, 0, 1.0)";
			canvasContext.font = `bold ${fontSizeLarge}px Rubik`;
			canvasContext.textAlign = "left";
			canvasContext.fillText("Right Side", image_x, image_y);

			x = 250;
			y = 50;
			image_x = (x / imageWidth) * maxWidth;
			image_y = (y / imageHeight) * maxHeight;
			canvasContext.fillStyle = "rgba(0, 0, 0, 1.0)";
			canvasContext.font = `bold ${fontSizeLarge}px Rubik`;
			canvasContext.textAlign = "left";
			canvasContext.fillText("Left Side", image_x, image_y);

			x = 360;
			y = 50;
			image_x = (x / imageWidth) * maxWidth;
			image_y = (y / imageHeight) * maxHeight;
			canvasContext.fillStyle = "rgba(0, 0, 0, 1.0)";
			canvasContext.font = `bold ${fontSizeLarge}px Rubik`;
			canvasContext.textAlign = "left";
			canvasContext.fillText("Left Side", image_x, image_y);

			x = 560;
			y = 50;
			image_x = (x / imageWidth) * maxWidth;
			image_y = (y / imageHeight) * maxHeight;
			canvasContext.fillStyle = "rgba(0, 0, 0, 1.0)";
			canvasContext.font = `bold ${fontSizeLarge}px Rubik`;
			canvasContext.textAlign = "left";
			canvasContext.fillText("Right Side", image_x, image_y);
		};

		var DrawCycle = (painLevels) => {
			canvasContext.clearRect(canvasImg, 0, 0, maxWidth, maxHeight);
			canvasContext.drawImage(canvasImg, 0, 0, maxWidth, maxHeight);
			DrawSideIndicators();
			for (let i = 0; i < imageCoordinates.length; i++) {
				let image_x = (imageCoordinates[i][0] / imageWidth) * maxWidth;
				let image_y = (imageCoordinates[i][1] / imageHeight) * maxHeight;
				let pain_index = Math.max(painLevels[i], 0);
				CanvasEllipse(canvasContext, image_x, image_y, circle_rad, circle_rad, painColors[pain_index]);
			}
		};

		DrawCycle(newPainLevels);

		/**
		 * Draws a rounded rectangle using the current state of the canvas.
		 * If you omit the last three params, it will draw a rectangle
		 * outline with a 5 pixel border radius
		 * @param {CanvasRenderingContext2D} ctx
		 * @param {Number} x The top left x coordinate
		 * @param {Number} y The top left y coordinate
		 * @param {Number} width The width of the rectangle
		 * @param {Number} height The height of the rectangle
		 * @param {Number} [radius = 5] The corner radius; It can also be an object
		 *                 to specify different radii for corners
		 * @param {Number} [radius.tl = 0] Top left
		 * @param {Number} [radius.tr = 0] Top right
		 * @param {Number} [radius.br = 0] Bottom right
		 * @param {Number} [radius.bl = 0] Bottom left
		 * @param {Boolean} [fill = false] Whether to fill the rectangle.
		 * @param {Boolean} [stroke = true] Whether to stroke the rectangle.
		 */
		const RoundRect = (ctx, x, y, width, height, radius, fill, stroke) => {
			if (typeof stroke === "undefined") {
				stroke = true;
			}
			if (typeof radius === "undefined") {
				radius = 5;
			}
			if (typeof radius === "number") {
				radius = { tl: radius, tr: radius, br: radius, bl: radius };
			} else {
				var defaultRadius = { tl: 0, tr: 0, br: 0, bl: 0 };
				for (var side in defaultRadius) {
					radius[side] = radius[side] || defaultRadius[side];
				}
			}
			ctx.beginPath();
			ctx.moveTo(x + radius.tl, y);
			ctx.lineTo(x + width - radius.tr, y);
			ctx.quadraticCurveTo(x + width, y, x + width, y + radius.tr);
			ctx.lineTo(x + width, y + height - radius.br);
			ctx.quadraticCurveTo(x + width, y + height, x + width - radius.br, y + height);
			ctx.lineTo(x + radius.bl, y + height);
			ctx.quadraticCurveTo(x, y + height, x, y + height - radius.bl);
			ctx.lineTo(x, y + radius.tl);
			ctx.quadraticCurveTo(x, y, x + radius.tl, y);
			ctx.closePath();
			if (fill) {
				ctx.fill();
			}
			if (stroke) {
				ctx.stroke();
			}
		};

		painDiagram.addEventListener("mousemove", (event) => {
			event.stopPropagation();

			if(!!canvasTimeoutHandler){
				clearTimeout(canvasTimeoutHandler);
			}

			let rect = painDiagram.getBoundingClientRect();
			let x = event.x - rect.left;
			let y = event.y - rect.top;

			let check = false;
			let index = null;

			for (let j = 0; j < imageCoordinates.length; j++) {
				let image_x = (imageCoordinates[j][0] / imageWidth) * maxWidth;
				let image_y = (imageCoordinates[j][1] / imageHeight) * maxHeight;
				let dx = image_x - x;
				let dy = image_y - y;
				let val = dx * dx + dy * dy;
				let inside = val <= circle_rad * circle_rad;
				if (inside) {
					check = true;
					index = j;
					break;
				}
			}

			if (check) {
				DrawCycle(newPainLevels);
				canvasContext.strokeStyle = "rgba(176, 176, 176, 0.9)";
				canvasContext.fillStyle = "rgba(0, 123, 255, 0.9)";
				let leftAdjust = x - canvasContext.measureText(markerLabels[index]).width < 0 ? Math.abs(x - canvasContext.measureText(markerLabels[index]).width) : 0;
				RoundRect(canvasContext, x - canvasContext.measureText(markerLabels[index]).width + leftAdjust, y - circle_rad * 2 - 20, canvasContext.measureText(markerLabels[index]).width + 10, 30, 5, true, false);
				canvasContext.fillStyle = "rgba(255, 255, 255, 1.0)";
				canvasContext.font = "16px Rubik";
				canvasContext.textAlign = "end";
				canvasContext.fillText(markerLabels[index], x + leftAdjust, y - circle_rad * 2);

				canvasTimeoutHandler = setTimeout(() => {
					DrawCycle(newPainLevels);
				}, 2000);
			} else {
				DrawCycle(newPainLevels);
			}
		});

		return painDiagram.toDataURL("image/png");
	};

	const CheckIfFieldsAreValid = () => {
		var isValid = true;
		if (hasAllergies) {
			isValid = isValid && (requiredFields.HasAllergies ? isValidFields.Allergies || isValidFields.AllergyNotes : true);
		}
		if (hasMedications) {
			isValid = isValid && (requiredFields.HasMedications ? isValidFields.Medications || isValidFields.MedicationNotes : true);
		}
		if (hasMD) {
			isValid = isValid && (requiredFields.HasMedicalDevices ? isValidFields.MedicalDevices || isValidFields.MedicalDeviceNotes : true);
		}
		return isValid;
	};

	const InitialFieldsValidity = () => {
		var cIsValid = { ...isValidFields };
		cIsValid.Allergies = data.Allergies.length > 0;
		cIsValid.AllergyNotes = IsFieldValid(data.AllergyNotes, "text");
		cIsValid.Medications = data.Medications.length > 0;
		cIsValid.MedicationNotes = IsFieldValid(data.MedicationNotes, "text");
		cIsValid.MedicalDevices = data.MedicalDevices.length > 0;
		cIsValid.MedicalDeviceNotes = IsFieldValid(data.MedicalDeviceNotes, "text");
		return cIsValid;
	};

	const RefreshCanvas = (newPainLevels) => {
		var painDiagram = canvasRef.current;
		if (!!painDiagram) {
			canvasImg.src = HumanBodyImg;
			canvasImg.onload = () => {
				DrawCanvas(newPainLevels);
			};
		}
	};

	useEffect(() => {
		RefreshCanvas(painLevels);
		setIsValidFields({ ...InitialFieldsValidity() });
	}, []);

	useEffect(() => {
		var newData = {
			...data,
			FrontFaceRight: painLevels[0],
			FrontFaceLeft: painLevels[1],
			FrontHead: painLevels[2],
			FrontTrapsRight: painLevels[3],
			FrontTrapsLeft: painLevels[4],
			FrontNeck: painLevels[5],
			FrontShoulderRight: painLevels[6],
			FrontShoulderLeft: painLevels[7],
			FrontChestRight: painLevels[8],
			FrontChestLeft: painLevels[9],
			FrontUpperAbdominal: painLevels[10],
			FrontAbsRight: painLevels[11],
			FrontAbsLeft: painLevels[12],
			FrontLowerAbdominal: painLevels[13],
			FrontHipRight: painLevels[14],
			FrontHipLeft: painLevels[15],
			FrontUpperLegRight: painLevels[16],
			FrontUpperLegLeft: painLevels[17],
			FrontKneeJointRight: painLevels[18],
			FrontKneeJointLeft: painLevels[19],
			FrontLowerLegRight: painLevels[20],
			FrontLowerLegLeft: painLevels[21],
			FrontAnkleRight: painLevels[22],
			FrontAnkleLeft: painLevels[23],
			FrontFootRight: painLevels[24],
			FrontFootLeft: painLevels[25],
			FrontBicepsRight: painLevels[26],
			FrontBicepsLeft: painLevels[27],
			FrontElbowJointRight: painLevels[28],
			FrontElbowJointLeft: painLevels[29],
			FrontForeArmsRight: painLevels[30],
			FrontForeArmsLeft: painLevels[31],
			FrontWristJointRight: painLevels[32],
			FrontWristJointLeft: painLevels[33],
			FrontHandRight: painLevels[34],
			FrontHandLeft: painLevels[35],
			BackHead: painLevels[36],
			BackNeck: painLevels[37],
			BackTrapsLeft: painLevels[38],
			BackTrapsRight: painLevels[39],
			BackUpperBackLeft: painLevels[40],
			BackUpperBackRight: painLevels[41],
			BackMiddleBack: painLevels[42],
			BackLowerBack: painLevels[43],
			BackSijointLeft: painLevels[44],
			BackSijointRight: painLevels[45],
			BackSacrum: painLevels[46],
			BackGlutesLeft: painLevels[47],
			BackGlutesRight: painLevels[48],
			BackUpperLegLeft: painLevels[49],
			BackUpperLegRight: painLevels[50],
			BackHamstringLeft: painLevels[51],
			BackHamstringRight: painLevels[52],
			BackKneeJointLeft: painLevels[53],
			BackKneeJointRight: painLevels[54],
			BackUpperCalvesLeft: painLevels[55],
			BackUpperCalvesRight: painLevels[56],
			BackCalvesLeft: painLevels[57],
			BackCalvesRight: painLevels[58],
			BackHeelLeft: painLevels[59],
			BackHeelRight: painLevels[60],
			BackShoulderLeft: painLevels[61],
			BackShoulderRight: painLevels[62],
			BackTricepsLeft: painLevels[63],
			BackTricepsRight: painLevels[64],
			BackElbowJointLeft: painLevels[65],
			BackElbowJointRight: painLevels[66],
			BackForeArmsLeft: painLevels[67],
			BackForeArmsRight: painLevels[68],
			BackWristJointLeft: painLevels[69],
			BackWristJointRight: painLevels[70],
			BackHandLeft: painLevels[71],
			BackHandRight: painLevels[72],
		};
		RefreshCanvas(painLevels);
		onChange(GetConditions({ ...newData }));
	}, [painLevels]);

	const IsFieldValid = (str, type) => {
		if (type == "text") {
			return !!str;
		} else if (type == "email") {
			return (
				!!str &&
				str.toLowerCase().match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/).length > 0
			);
		}
		return true;
	};

	useEffect(() => {
		const iterate = (obj) => {
			Object.keys(obj).forEach((key) => {
				if (typeof obj[key] === "object" && obj[key] !== null) {
					iterate(obj[key]);
				} else {
					obj[key] = true;
				}
			});
		};
		if (viewInvalidFields) {
			window.scrollTo({
				top: "0",
				behavior: "smooth",
			});
			var newObj = { ...validatedFields };
			iterate(newObj);
			setValidatedFields({ ...newObj });
		}
	}, [viewInvalidFields]);

	useEffect(() => {
		pageCompleted[activeIndex] = CheckIfFieldsAreValid();
		setPageCompleted([...pageCompleted]);
	}, [isValidFields]);

	const CanvasOnClickHandler = (evt) => {
		var bound = evt.currentTarget.getBoundingClientRect();
		var width = bound.width;
		var height = bound.height;
		var offsetLeft = bound.x;
		var offsetTop = bound.y;
		var x = evt.clientX - offsetLeft;
		var y = evt.clientY - offsetTop;

		var newPainLevels = [...painLevels];

		var painDiagram = canvasRef.current;
		painDiagram.style.cursor = "pointer";

		let circle_rad = Math.max(5, Math.round(width * 0.012));

		for (let j = 0; j < imageCoordinates.length; j++) {
			let image_x = (imageCoordinates[j][0] / canvasImgWidth) * width;
			let image_y = (imageCoordinates[j][1] / canvasImgHeight) * height;

			let dx = image_x - x;
			let dy = image_y - y;
			let val = dx * dx + dy * dy;
			let inside = val <= (circle_rad + 2) * (circle_rad + 2);
			if (inside) {
				newPainLevels[j] = (Math.max(newPainLevels[j], 0) + 1) % 4;
				break;
			}
		}

		setPainLevels(newPainLevels);
	};

	return (
		<div className="intake" style={styles.screen}>
			<Header label={"Screening and Clearance Questions"} />

			{/* pai diagram intro */}
			<Row>
				<Col>
					<Form.Label style={{ paddingLeft: "20px" }}>
						In the following diagram, markers are placed to highlight various muscle and joint sections of the human body. Indicate different pain levels you are experiencing in various sections of
						your body. Click on the circular shaped markers to indicate the following levels: (You may select multiple areas if necessary.)
						<br></br>&nbsp;&nbsp;&nbsp;&nbsp; Choose{" "}
						<span
							style={{
								color: "rgba(225, 229, 232, 0.7)",
								fontSize: "30px",
								lineHeight: "15px",
							}}
						>
							&#9679;
						</span>{" "}
						to indicate <span style={{ fontWeight: "bold" }}>comfortable</span> or <span style={{ fontWeight: "bold" }}>no pain</span>; (0 pain level)
						<br></br>&nbsp;&nbsp;&nbsp;&nbsp; Choose{" "}
						<span
							style={{
								color: "rgba(67, 176, 73, 1.0)",
								fontSize: "30px",
								lineHeight: "15px",
							}}
						>
							&#9679;
						</span>{" "}
						to indicate <span style={{ fontWeight: "bold" }}>mild discomfort</span> or <span style={{ fontWeight: "bold" }}>pain</span>; (1-3 pain level)
						<br></br>&nbsp;&nbsp;&nbsp;&nbsp; Choose{" "}
						<span
							style={{
								color: "rgba(255, 238, 56, 1.0)",
								fontSize: "30px",
								lineHeight: "15px",
							}}
						>
							&#9679;
						</span>{" "}
						to indicate <span style={{ fontWeight: "bold" }}>moderate discomfort</span> or <span style={{ fontWeight: "bold" }}>pain</span>; (4-6 pain level)
						<br></br>&nbsp;&nbsp;&nbsp;&nbsp; Choose{" "}
						<span
							style={{
								color: "rgba(255, 79, 79, 1.0)",
								fontSize: "30px",
								lineHeight: "15px",
							}}
						>
							&#9679;
						</span>{" "}
						to indicate <span style={{ fontWeight: "bold" }}>severe discomfort</span> or <span style={{ fontWeight: "bold" }}>pain</span>; (7-10 pain level)
						<br></br> Pain levels are indicated from: 0 to 10 (0 no pain and 10 the most intense pain). Press the markers repeatedly to choose different pain levels mentioned above.
					</Form.Label>
				</Col>
			</Row>

			{/* pain diagram images */}
			<Row>
				<Col style={{ textAlign: "right" }}>
					<Button
						variant="warning"
						onClick={() => {
							var newPainLevels = [...GetPainLevels(ClearPainLevels())];
							setPainLevels(newPainLevels);
						}}
					>
						Clear All Selections
					</Button>
				</Col>
			</Row>
			<Row>
				<Col style={{ display: "flex", alignItems: "center" }}>
					<canvas style={{ backgroundColor: "var(--primary-gray)" }} ref={canvasRef} onClick={CanvasOnClickHandler} />
				</Col>
			</Row>

			{/* screening questions intro */}
			<Row>
				<Col>
					<Form.Label style={{ fontWeight: "bold", textTransform: "uppercase" }}>
						Check off (<span style={{ color: "black" }}>&#10004;</span>) any of the following conditions that you may be experiencing{" "}
						<Button
							style={{ padding: "0px" }}
							variant="link"
							onClick={() => {
								var newData = { ...data };
								CheckboxId.map((id, _) => {
									newData[id] = false;
								});
								onChange({ ...newData });
							}}
						>
							Clear
						</Button>
					</Form.Label>
				</Col>
			</Row>

			{/* screening questions */}
			<Row>
				{CheckboxId.map((id, k) => {
					return (
						<Col md={6} className="checkbox" key={k}>
							<Form.Check
								custom
								type={"checkbox"}
								label={""}
								id={`screening-checkbox-${k}`}
								checked={data[id]}
								defaultChecked={data[id]}
								onChange={() => {
									// var currentData = { ...data };
									// currentData[id] = !currentData[id];
									// if (currentData.ElbowWristHandPain && currentData.NumbnessArmsHands) {
									// 	currentData = {
									// 		...currentData,
									// 		FrontBicepsRight: data.FrontBicepsRight >= 1 ? data.FrontBicepsRight : 1,
									// 		FrontElbowJointRight: data.FrontElbowJointRight >= 1 ? data.FrontElbowJointRight : 1,
									// 		FrontForeArmsRight: data.FrontForeArmsRight >= 1 ? data.FrontForeArmsRight : 1,
									// 		FrontWristJointRight: data.FrontWristJointRight >= 1 ? data.FrontWristJointRight : 1,
									// 		FrontHandRight: data.FrontHandRight >= 1 ? data.FrontHandRight : 1,

									// 		FrontBicepsLeft: data.FrontBicepsLeft >= 1 ? data.FrontBicepsLeft : 1,
									// 		FrontElbowJointLeft: data.FrontElbowJointLeft >= 1 ? data.FrontElbowJointLeft : 1,
									// 		FrontForeArmsLeft: data.FrontForeArmsLeft >= 1 ? data.FrontForeArmsLeft : 1,
									// 		FrontWristJointLeft: data.FrontWristJointLeft >= 1 ? data.FrontWristJointLeft : 1,
									// 		FrontHandLeft: data.FrontHandLeft >= 1 ? data.FrontHandLeft : 1,

									// 		BackTricepsLeft: data.BackTricepsLeft >= 1 ? data.BackTricepsLeft : 1,
									// 		BackElbowJointLeft: data.BackElbowJointLeft >= 1 ? data.BackElbowJointLeft : 1,
									// 		BackForeArmsLeft: data.BackForeArmsLeft >= 1 ? data.BackForeArmsLeft : 1,
									// 		BackWristJointLeft: data.BackWristJointLeft >= 1 ? data.BackWristJointLeft : 1,
									// 		BackHandLeft: data.BackHandLeft >= 1 ? data.BackHandLeft : 1,

									// 		BackTricepsRight: data.BackTricepsRight >= 1 ? data.BackTricepsRight : 1,
									// 		BackElbowJointRight: data.BackElbowJointRight >= 1 ? data.BackElbowJointRight : 1,
									// 		BackForeArmsRight: data.BackForeArmsRight >= 1 ? data.BackForeArmsRight : 1,
									// 		BackWristJointRight: data.BackWristJointRight >= 1 ? data.BackWristJointRight : 1,
									// 		BackHandRight: data.BackHandRight >= 1 ? data.BackHandRight : 1,
									// 	};
									// } else if (currentData.NeckPainStiffness && currentData.ShoulderPain) {
									// 	currentData = {
									// 		...currentData,
									// 		FrontShoulderRight: 0,
									// 		FrontShoulderLeft: 0,
									// 		FrontTrapsRight: data.FrontTrapsRight >= 1 ? data.FrontTrapsRight : 1,
									// 		FrontTrapsLeft: data.FrontTrapsLeft >= 1 ? data.FrontTrapsLeft : 1,
									// 		FrontNeck: data.FrontNeck >= 1 ? data.FrontNeck : 1,
									// 		BackShoulderLeft: 0,
									// 		BackShoulderRight: 0,
									// 		FrontTrapsRight: data.FrontTrapsRight >= 1 ? data.FrontTrapsRight : 1,
									// 		FrontTrapsLeft: data.FrontTrapsLeft >= 1 ? data.FrontTrapsLeft : 1,
									// 		BackTrapsRight: data.BackTrapsRight >= 1 ? data.BackTrapsRight : 1,
									// 		BackTrapsLeft: data.BackTrapsLeft >= 1 ? data.BackTrapsLeft : 1,
									// 	};
									// } else if (currentData.HipKneeAnklePain && currentData.NumbnessLegsFeet) {
									// 	currentData = {
									// 		...currentData,

									// 		FrontHipRight: data.FrontHipRight >= 1 ? data.FrontHipRight : 1,
									// 		FrontUpperLegRight: data.FrontUpperLegRight >= 1 ? data.FrontUpperLegRight : 1,
									// 		FrontKneeJointRight: data.FrontKneeJointRight >= 1 ? data.FrontKneeJointRight : 1,
									// 		FrontLowerLegRight: data.FrontLowerLegRight >= 1 ? data.FrontLowerLegRight : 1,
									// 		FrontAnkleRight: data.FrontAnkleRight >= 1 ? data.FrontAnkleRight : 1,
									// 		FrontFootRight: data.FrontFootRight >= 1 ? data.FrontFootRight : 1,

									// 		FrontHipLeft: data.FrontHipLeft >= 1 ? data.FrontHipLeft : 1,
									// 		FrontUpperLegLeft: data.FrontUpperLegLeft >= 1 ? data.FrontUpperLegLeft : 1,
									// 		FrontKneeJointLeft: data.FrontKneeJointLeft >= 1 ? data.FrontKneeJointLeft : 1,
									// 		FrontLowerLegLeft: data.FrontLowerLegLeft >= 1 ? data.FrontLowerLegLeft : 1,
									// 		FrontAnkleLeft: data.FrontAnkleLeft >= 1 ? data.FrontAnkleLeft : 1,
									// 		FrontFootLeft: data.FrontFootLeft >= 1 ? data.FrontFootLeft : 1,

									// 		BackUpperLegRight: data.BackUpperLegRight >= 1 ? data.BackUpperLegRight : 1,
									// 		BackHamstringRight: data.BackHamstringRight >= 1 ? data.BackHamstringRight : 1,
									// 		BackKneeJointRight: data.BackKneeJointRight >= 1 ? data.BackKneeJointRight : 1,
									// 		BackUpperCalvesRight: data.BackUpperCalvesRight >= 1 ? data.BackUpperCalvesRight : 1,
									// 		BackCalvesRight: data.BackCalvesRight >= 1 ? data.BackCalvesRight : 1,
									// 		BackHeelRight: data.BackHeelRight >= 1 ? data.BackHeelRight : 1,

									// 		BackUpperLegLeft: data.BackUpperLegLeft >= 1 ? data.BackUpperLegLeft : 1,
									// 		BackHamstringLeft: data.BackHamstringLeft >= 1 ? data.BackHamstringLeft : 1,
									// 		BackKneeJointLeft: data.BackKneeJointLeft >= 1 ? data.BackKneeJointLeft : 1,
									// 		BackUpperCalvesLeft: data.BackUpperCalvesLeft >= 1 ? data.BackUpperCalvesLeft : 1,
									// 		BackCalvesLeft: data.BackCalvesLeft >= 1 ? data.BackCalvesLeft : 1,
									// 		BackHeelLeft: data.BackHeelLeft >= 1 ? data.BackHeelLeft : 1,
									// 	};
									// } else if (currentData.MidBackPain && currentData.ShoulderPain) {
									// 	currentData = {
									// 		...currentData,
									// 		FrontShoulderRight: data.FrontShoulderRight >= 1 ? data.FrontShoulderRight : 1,
									// 		FrontTrapsRight: data.FrontTrapsRight >= 1 ? data.FrontTrapsRight : 1,
									// 		FrontShoulderLeft: data.FrontShoulderLeft >= 1 ? data.FrontShoulderLeft : 1,
									// 		FrontTrapsLeft: data.FrontTrapsLeft >= 1 ? data.FrontTrapsLeft : 1,
									// 		BackShoulderRight: data.BackShoulderRight >= 1 ? data.BackShoulderRight : 1,
									// 		BackTrapsRight: data.BackTrapsRight >= 1 ? data.BackTrapsRight : 1,
									// 		BackShoulderLeft: data.BackShoulderLeft >= 1 ? data.BackShoulderLeft : 1,
									// 		BackTrapsLeft: data.BackTrapsLeft >= 1 ? data.BackTrapsLeft : 1,

									// 		BackMiddleBack: data.BackMiddleBack >= 1 ? data.BackMiddleBack : 1,
									// 		BackUpperBackLeft: data.BackUpperBackLeft >= 1 ? data.BackUpperBackLeft : 1,
									// 		BackUpperBackRight: data.BackUpperBackRight >= 1 ? data.BackUpperBackRight : 1,
									// 	};
									// } else if (currentData.Headache) {
									// 	currentData = { ...currentData, FrontHead: data.FrontHead >= 1 ? data.FrontHead : 1, BackHead: data.BackHead >= 1 ? data.BackHead : 1 };
									// } else if (currentData.JawPain) {
									// 	currentData = { ...currentData, FrontFaceLeft: data.FrontFaceLeft >= 1 ? data.FrontFaceLeft : 1, FrontFaceRight: data.FrontFaceRight >= 1 ? data.FrontFaceRight : 1 };
									// } else if (currentData.NeckPainStiffness) {
									// 	currentData = { ...currentData, FrontNeck: data.FrontNeck >= 1 ? data.FrontNeck : 1, BackNeck: data.BackNeck >= 1 ? data.BackNeck : 1 };
									// } else if (currentData.ShoulderPain) {
									// 	currentData = {
									// 		...currentData,
									// 		FrontShoulderRight: data.FrontShoulderRight >= 1 ? data.FrontShoulderRight : 1,
									// 		FrontTrapsRight: data.FrontTrapsRight >= 1 ? data.FrontTrapsRight : 1,
									// 		FrontShoulderLeft: data.FrontShoulderLeft >= 1 ? data.FrontShoulderLeft : 1,
									// 		FrontTrapsLeft: data.FrontTrapsLeft >= 1 ? data.FrontTrapsLeft : 1,
									// 		BackShoulderRight: data.BackShoulderRight >= 1 ? data.BackShoulderRight : 1,
									// 		BackTrapsRight: data.BackTrapsRight >= 1 ? data.BackTrapsRight : 1,
									// 		BackShoulderLeft: data.BackShoulderLeft >= 1 ? data.BackShoulderLeft : 1,
									// 		BackTrapsLeft: data.BackTrapsLeft >= 1 ? data.BackTrapsLeft : 1,
									// 	};
									// } else if (currentData.MidBackPain) {
									// 	currentData = { ...currentData, BackMiddleBack: data.BackMiddleBack >= 1 ? data.BackMiddleBack : 1 };
									// } else if (currentData.TailbonePain) {
									// 	currentData = { ...currentData, BackSacrum: data.BackSacrum >= 1 ? data.BackSacrum : 1 };
									// } else if (currentData.LowBackPain) {
									// 	currentData = {
									// 		...currentData,
									// 		BackSacrum: data.BackSacrum >= 1 ? data.BackSacrum : 1,
									// 		BackSijointLeft: data.BackSijointLeft >= 1 ? data.BackSijointLeft : 1,
									// 		BackSijointRight: data.BackSijointRight >= 1 ? data.BackSijointRight : 1,
									// 		BackLowerBack: data.BackLowerBack >= 1 ? data.BackLowerBack : 1,
									// 	};
									// }

									// var newPainLevels = [...GetPainLevels({ ...currentData })];
									// setPainLevels(newPainLevels);
									// RefreshImage(newPainLevels);

									onChange({ ...data, [id]: !data[id] });
								}}
							/>
							<Form.Label>{CheckboxLabel[k]}</Form.Label>
						</Col>
					);
				})}
			</Row>

			{/* Allergies */}
			<Row>
				<Col>
					<SubHeader label={"Allergies"} />
				</Col>
			</Row>

			<Row>
				<Col md={8}>
					<Form.Label className={validatedFields.HasAllergies && requiredFields.HasAllergies && !(isValidFields.Allergies || isValidFields.AllergyNotes || !hasAllergies) ? "isinvalid" : "isvalid"}>
						Do you have any allergies? {requiredFields.HasAllergies ? "*" : ""}
					</Form.Label>
				</Col>
				<Col md={4} style={{ textAlign: "end" }}>
					<ButtonGroup>
						<Button
							active={hasAllergies}
							onClick={() => {
								setHasAllergies(true);
								setIsValidFields({ ...isValidFields, Allergies: data.Allergies.length > 0, AllergyNotes: !!data.AllergyNotes });
							}}
						>
							Yes
						</Button>
						<Button
							active={!hasAllergies}
							onClick={() => {
								setAllergy("");
								setHasAllergies(false);
								onChange({ ...data, Allergies: [], AllergyNotes: "" });
								setIsValidFields({ ...isValidFields, Allergies: true, AllergyNotes: true });
							}}
						>
							No
						</Button>
					</ButtonGroup>
				</Col>
			</Row>

			{hasAllergies && (
				<Fragment>
					<Row>
						<Col md={2}>
							<Form.Label>Add Allergies</Form.Label>
						</Col>
						<Col md={4}>
							<Form.Control
								as="select"
								onChange={(evt) => {
									var currAlg = evt.target.value;
									if (!!currAlg) {
										var existingAllergies = [...data.Allergies];
										if (!existingAllergies.includes(currAlg) && currAlg != "Other") {
											existingAllergies.push(currAlg);
										}
										onChange({ ...data, Allergies: existingAllergies });
										setIsValidFields({ ...isValidFields, Allergies: existingAllergies.length > 0 });
										setAllergy(currAlg);
									}
								}}
							>
								{["", "Latex", "Tropical (oils / lotions)", "Drugs", "Other"].map((alg) => {
									return <option selected={allergy === alg ? true : false}>{alg}</option>;
								})}
							</Form.Control>
						</Col>
					</Row>
					{data.Allergies.map((alg) => {
						return (
							<Row>
								<Col md={2}></Col>
								<Col md={10}>
									&#8618; {alg}{" "}
									<Button
										variant="link"
										onClick={() => {
											var newAllergies = data.Allergies.filter((item) => item != alg);
											onChange({ ...data, Allergies: newAllergies });
											setIsValidFields({ ...isValidFields, Allergies: newAllergies.length > 0 });
										}}
									>
										delete
									</Button>
								</Col>
							</Row>
						);
					})}
					{(allergy == "Other" || !!data.AllergyNotes) && (
						<Fragment>
							<Row>
								<Col>
									<Form.Control
										key="allergy-textarea"
										as="textarea"
										rows="3"
										defaultValue={data.AllergyNotes}
										placeholder="other allergies"
										onChange={(evt) => {
											onChange({ ...data, AllergyNotes: evt.target.value });
											setIsValidFields({ ...isValidFields, AllergyNotes: IsFieldValid(evt.target.value, "text") });
										}}
									/>
								</Col>
							</Row>
						</Fragment>
					)}
				</Fragment>
			)}

			{/* Medications */}
			<Row>
				<Col>
					<SubHeader label={"Medications"} />
				</Col>
			</Row>

			<Row>
				<Col md={8}>
					<Form.Label
						className={validatedFields.HasMedications && requiredFields.HasMedications && !(isValidFields.Medications || isValidFields.MedicationNotes || !hasMedications) ? "isinvalid" : "isvalid"}
					>
						Do you take any medications? {requiredFields.HasMedications ? "*" : ""}
					</Form.Label>
				</Col>
				<Col md={4} style={{ textAlign: "end" }}>
					<ButtonGroup>
						<Button
							active={hasMedications}
							onClick={() => {
								setHasMedications(true);
								setIsValidFields({ ...isValidFields, Medications: data.Medications.length > 0, MedicationNotes: !!data.MedicationNotes });
							}}
						>
							Yes
						</Button>
						<Button
							active={!hasMedications}
							onClick={() => {
								setMedication("");
								setHasMedications(false);
								onChange({ ...data, Medications: [], MedicationNotes: "" });
								setIsValidFields({ ...isValidFields, Medications: true, MedicationNotes: true });
							}}
						>
							No
						</Button>
					</ButtonGroup>
				</Col>
			</Row>

			{hasMedications && (
				<Fragment>
					<Row>
						<Col md={2}>
							<Form.Label>Add Medications</Form.Label>
						</Col>
						<Col md={4}>
							<Form.Control
								as="select"
								onChange={(evt) => {
									var curMed = evt.target.value;
									if (!!curMed) {
										var existingMedications = [...data.Medications];
										if (!existingMedications.includes(curMed) && curMed != "Other") {
											existingMedications.push(curMed);
										}
										setMedication(curMed);
										onChange({ ...data, Medications: existingMedications });
										setIsValidFields({ ...isValidFields, Medications: existingMedications.length > 0 });
									}
								}}
							>
								{[
									"",
									"Tylenol (acetaminophen)",
									"Synthroid (levothyroxine)",
									"Crestor (rosuvastatin)",
									"Ventolin HFA (albuterol)",
									"Nexium (esomeprazole)",
									"Advair Diskus (fluticasone)",
									"Lantus Solostar (insulin glargine)",
									"Vyvanse (lisdexamfetamine)",
									"Lyrica (pregabalin)",
									"Spiriva Handihaler (tiotropium)",
									"Januvia (sitagliptin)",
									"Humira (adalimumab)",
									"Abilify (aripiprazole)",
									"Sovaldi (sofosbuvir)",
									"Crestor (rosuvastatin)",
									"Enbrel (etanercept)",
									"Harvoni (ledipasvir and sofosbuvir)",
									"Nexium (esomeprazole)",
									"Lantus Solostar (insulin glargine)",
									"Remicade (infliximab)",
									"Other",
								].map((md) => {
									return <option selected={medication === md ? true : false}>{md}</option>;
								})}
							</Form.Control>
						</Col>
					</Row>
					{data.Medications.map((md) => {
						return (
							<Row>
								<Col md={2}></Col>
								<Col md={10}>
									&#8618; {md}{" "}
									<Button
										variant="link"
										onClick={() => {
											var newMedications = data.Medications.filter((item) => item != md);
											onChange({ ...data, Medications: newMedications });
											setIsValidFields({ ...isValidFields, Medications: newMedications.length > 0 });
										}}
									>
										delete
									</Button>
								</Col>
							</Row>
						);
					})}
					{(medication == "Other" || !!data.MedicationNotes) && (
						<Fragment>
							<Row>
								<Col>
									<Form.Control
										key="medication-textarea"
										as="textarea"
										rows="3"
										defaultValue={data.MedicationNotes}
										placeholder="other medications"
										onChange={(evt) => {
											onChange({ ...data, MedicationNotes: evt.target.value });
											setIsValidFields({ ...isValidFields, MedicationNotes: IsFieldValid(evt.target.value, "text") });
										}}
									/>
								</Col>
							</Row>
						</Fragment>
					)}
				</Fragment>
			)}

			{/* Medical Devices */}
			<Row>
				<Col>
					<SubHeader label={"Medical Devices"} />
				</Col>
			</Row>

			<Row>
				<Col md={8}>
					<Form.Label
						className={
							validatedFields.HasMedicalDevices && requiredFields.HasMedicalDevices && !(isValidFields.HasMedicalDevices || isValidFields.MedicalDeviceNotes || !hasMD) ? "isinvalid" : "isvalid"
						}
					>
						Do you regularly use any medical devices? {requiredFields.HasMedicalDevices ? "*" : ""}
					</Form.Label>
				</Col>
				<Col md={4} style={{ textAlign: "end" }}>
					<ButtonGroup>
						<Button
							active={hasMD}
							onClick={() => {
								setHasMD(true);
								setValidatedFields({ ...validatedFields, MedicalDevices: data.MedicalDevices.length > 0, MedicalDeviceNotes: !!data.MedicalDeviceNotes });
								setIsValidFields({ ...isValidFields, MedicalDevices: data.MedicalDevices.length > 0, MedicalDeviceNotes: !!data.MedicalDeviceNotes });
							}}
						>
							Yes
						</Button>
						<Button
							active={!hasMD}
							onClick={() => {
								setMedDevice("");
								setHasMD(false);
								onChange({ ...data, MedicalDevices: [], MedicalDeviceNotes: "" });
								setIsValidFields({ ...isValidFields, MedicalDevices: true, MedicalDeviceNotes: true });
							}}
						>
							No
						</Button>
					</ButtonGroup>
				</Col>
			</Row>

			{hasMD && (
				<Fragment>
					<Row>
						<Col md={2}>
							<Form.Label>Add Medical Devices</Form.Label>
						</Col>
						<Col md={4}>
							<Form.Control
								as="select"
								onChange={(evt) => {
									var curMdDv = evt.target.value;
									if (!!curMdDv) {
										var existingMds = [...data.MedicalDevices];
										if (!existingMds.includes(curMdDv) && curMdDv != "Other") {
											existingMds.push(curMdDv);
										}
										setMedDevice(curMdDv);
										onChange({ ...data, MedicalDevices: existingMds });
										setIsValidFields({ ...isValidFields, MedicalDevices: existingMds.length > 0 });
									}
								}}
							>
								{["", "Pacemaker", "Metal Implants", "Other"].map((device) => {
									return <option selected={medDevice === device ? true : false}>{device}</option>;
								})}
							</Form.Control>
						</Col>
					</Row>
					{data.MedicalDevices.map((device) => {
						return (
							<Row>
								<Col md={2}></Col>
								<Col md={10}>
									&#8618; {device}{" "}
									<Button
										variant="link"
										onClick={() => {
											var newMedicalDevices = data.MedicalDevices.filter((item) => item != device);
											onChange({ ...data, MedicalDevices: newMedicalDevices });
											setIsValidFields({ ...isValidFields, MedicalDevices: newMedicalDevices.length > 0 });
										}}
									>
										delete
									</Button>
								</Col>
							</Row>
						);
					})}
					{(medDevice == "Other" || !!data.MedicalDeviceNotes) && (
						<Fragment>
							<Row>
								<Col>
									<Form.Control
										as="textarea"
										rows="3"
										value={data.MedicalDeviceNotes}
										placeholder="other medical devices"
										onChange={(evt) => {
											onChange({ ...data, MedicalDeviceNotes: evt.target.value });
											setIsValidFields({ ...isValidFields, MedicalDeviceNotes: IsFieldValid(evt.target.value, "text") });
										}}
									/>
								</Col>
							</Row>
						</Fragment>
					)}
				</Fragment>
			)}
		</div>
	);
};

const styles = {
	screen: {
		margin: "20px auto",
		maxWidth: "800px",
	},
};

const imageCoordinates = [
	[170, 104],
	[206, 104],
	[187, 71], // front -> head
	[156, 148], // front -> traps -> left
	[228, 148], // front -> traps -> right
	[189, 147], // front -> neck
	[115, 170], // front -> shoulder -> left
	[265, 170], // front -> shoulder -> right
	[156, 210], // front -> chest -> left
	[220, 210], // front -> chest -> right
	[188, 235], // front -> chest -> middle
	[150, 300], // front -> abs -> left
	[230, 300], // front -> abs -> right
	[188, 330], // front -> abs -> middle
	[150, 380], // front -> hip -> left
	[220, 380], // front -> hip -> right
	[150, 440], // front -> quadriceps -> left
	[225, 440], // front -> quadriceps -> right
	[160, 550], // front -> knee joint -> left
	[215, 550], // front -> knee joint -> right
	[160, 630], // front -> calves -> left
	[215, 630], // front -> calves -> right
	[168, 715], // front -> ankle -> left
	[208, 715], // front -> ankle -> right
	[164, 749], // front -> feet -> right
	[214, 749], // front -> feet -> left
	[105, 230], // front -> biceps -> left
	[270, 230], // front -> bicepts -> right
	[100, 273], // front -> elbow joint -> left
	[273, 273], // front -> elbow joint -> right
	[97, 315], // front -> forearms -> left
	[277, 315], // front -> forearms -> right
	[85, 375], // front -> wrist joint -> left
	[280, 380], // front -> wrist joint -> right
	[80, 400], // front -> hand -> left
	[285, 400], // front -> hand -> right
	[508, 78], // back -> head
	[509, 113], // back -> neck
	[473, 154], // back -> traps -> left
	[540, 154], // back -> traps -> right
	[464, 208], // back -> upperback -> left
	[562, 208], // back -> upperback -> right
	[512, 206], // back -> traps -> middle
	[512, 304], // back -> spine -> center
	[485, 340], // back -> si joint -> left
	[545, 340], // back -> si joint -> right
	[516, 338], // back -> sacrum
	[485, 388], // back -> glutes -> left
	[548, 388], // back -> glutes -> right
	[485, 424], // back -> lower hip -> left
	[550, 424], // back -> lower hip -> right
	[481, 481], // back -> hamstring -> left
	[549, 481], // back -> hamstring -> right
	[481, 555], // back -> knee joint -> left
	[551, 555], // back -> knee joint -> right
	[485, 608], // back -> upper calves -> left
	[552, 608], // back -> upper calves -> right
	[489, 662], // back -> lower calves -> left
	[540, 662], // back -> lower calves -> right
	[500, 746], // back -> feet -> left
	[532, 746], // back -> feet -> right
	[427, 178], // back -> shoulder -> left
	[589, 178], // back -> shoulder -> right
	[436, 242], // back -> triceps -> left
	[592, 242], // back -> triceps -> right
	[436, 293], // back -> elbow joint -> left
	[595, 293], // back -> elbow joint -> right
	[423, 343], // back -> forearms -> left
	[609, 343], // back -> forearms -> right
	[419, 385], // back -> wrist joint -> left
	[613, 380], // back -> wrist joint -> right
	[413, 412], // back -> hand -> left
	[618, 412], // back -> hand -> right
];

const markerLabels = [
	"face (right)", // 0
	"face (left)", // 1
	"Head (front side)", // front -> head 2
	"traps (right)", // front -> traps -> left 3
	"traps (left)", // front -> traps -> right 4
	"neck", // 5
	"shoulder (right)", // front -> shoulder -> left // 6
	"shoulder (left)", // front -> shoulder -> right // 7
	"chest (right)", // front -> chest -> left // 8
	"chest (left)", // front -> chest -> right // 9
	"upper abdominal", // front -> chest -> middle // 10
	"abs (right)", // front -> abs -> left // 11
	"abs (left)", // front -> abs -> right // 12
	"lower abdominal", // front -> abs -> middle // 13
	"hip (right)", // front -> abs & quadriceps intersection -> left // 14
	"hip (left)", // front -> abs & quadriceps intersection -> right // 15
	"upper leg (right)", // front -> quadriceps -> left // 16
	"upper leg (left)", // front -> quadriceps -> right // 17
	"knee joint (right)", // front -> knee joint -> left // 18
	"knee joint (left)", // front -> knee joint -> right // 19
	"lower leg (right)", // front -> calves -> left // 20
	"lower leg (left)", // front -> calves -> right // 21
	"ankle (right)", // front -> feet -> left // 22
	"ankle (left)", // front -> feet -> right // 23
	"foot (right)", // front -> feet -> left // 24
	"foot (left)", // front -> feet -> right // 25
	"biceps (right)", // front -> biceps -> left // 26
	"biceps (left)", // front -> bicepts -> right // 27
	"elbow joint (right)", // front -> elbow joint -> left // 28
	"elbow joint (left)", // front -> elbow joint -> right // 29
	"forearms (right)", // front -> forearms -> left // 30
	"forearms (left)", // front -> forearms -> right // 31
	"wrist joint (right)", // front -> wrist joint -> left // 32
	"wrist joint (left)", // front -> wrist joint -> right // 33
	"hand (right)", // front -> hand -> left // 34
	"hand (left)", // front -> hand -> right // 35
	"head (back side)", // back -> head // 36
	"neck (back side)", // back -> neck // 37
	"traps (left)", // back -> traps -> left // 38
	"traps (right)", // back -> traps -> right // 39
	"upper back (left)", // back -> upperback -> left // 40
	"upper back (right)", // back -> upperback -> right // 41
	"middle back", // back -> middle back // 42
	"lower back", // back -> lower back // 43
	"si joint (left)", // back -> lowerback -> left // 44
	"si joint (right)", // back -> lowerback -> right // 45
	"sacrum", // back -> lowerback -> middle // 46
	"glutes (left)", // back -> glutes -> left // 47
	"glutes (right)", // back -> glutes -> right //
	"upper leg (left)", // back -> lower hip -> left
	"upper leg (right)", // back -> lower hip -> right
	"hamstring (left)", // back -> hamstring -> left
	"hamstring (right)", // back -> hamstring -> right
	"knee joint (left)", // back -> knee joint -> left
	"knee joint (right)", // back -> knee joint -> right
	"upper calves (left)", // back -> upper calves -> left
	"upper calves (right)", // back -> upper calves -> right
	"calves (left)", // back -> lower calves -> left
	"calves (right)", // back -> lower calves -> right
	"heel (left)", // back -> feet -> left
	"heel (right)", // back -> feet -> right
	"shoulder (left)", // back -> shoulder -> left
	"shoulder (right)", // back -> shoulder -> right
	"triceps (left)", // back -> triceps -> left
	"triceps (right)", // back -> triceps -> right
	"elbow joint (left)", // back -> elbow joint -> left
	"elbow joint (right)", // back -> elbow joint -> right
	"forearms (left)", // back -> forearms -> left
	"forearms (right)", // back -> forearms -> right
	"wrist joint (left)", // back -> wrist joint -> left
	"wrist joint (right)", // back -> wrist joint -> right
	"hand (left)", // back -> hand -> left
	"hand (right)", // back -> hand -> right
];

const painColors = [
	"rgba(225, 229, 232, 0.7)", // 0: comfortable / relaxed
	"rgba(67, 176, 73, 1.0)", // 1: mild discomfort / pain
	"rgba(255, 238, 56, 1.0)", // 2: moderate discomfort / pain
	"rgba(255, 79, 79, 1.0)", // 3: severe discomfort / pain
];

export default ScreenAndClearance;
