import React, { useState, useEffect } from 'react';
import { Button } from 'react-bootstrap';

// Trackpad interactive component on the consumer landing page
export default function TrackPad() {
  const [state, setState] = useState({
    moveable: true,
    value: {
      x: 0, // Default device x position
      y: 20 // Default device y position
    },
    girlSide: false,
    frontClick: false,
    sideClick: false
  });


  const HandleMouseMove = (e) => {

    // Get user mouse coordinates
    const { pageX: x, pageY: y } = e;
    if (state.moveable) {

      // If inside the animation bounds, drag the device around, else revert device to original coordinates
      if ((x < window.innerWidth / 2 - 0 || x > window.innerWidth - 50 ) || (y < 180 || y > 600)) {
        setState(prev => {
          return {...prev, value: {
            x: 0,
            y: 20
          }};
        });
      } else {
        setState(prev => {
          return {...prev, value: {
            x: (x - (window.innerWidth * .5)) - 145,
            y: y - 350
          }};
        });
      }

    }

  };

  // Hide the indicator when the user hovers over the device
  const HandleDeviceMouseOver = (e) => {
    document.querySelector('.indicator').style.opacity = '0';
  }

  // Switch girl image to gif on hover
  const HandleMouseMoveGirl = (e) => {
    if (!state.girlSide) {
      document.querySelector('.girl').src = '../../photos/consumer-landing/g.gif';
    } else {
      document.querySelector('.girl').src = '../../photos/consumer-landing/gs.gif';
    }
  }

  // Switch girl back to image on MouseLeave
  const HandleMouseLeave = (e) => {
    if (state.moveable) {
      if (!state.girlSide) {
        document.querySelector('.girl').src = '../../photos/consumer-landing/g-static.png';
      } else {
        document.querySelector('.girl').src = '../../photos/consumer-landing/gs-static.png';
      }
    }
  }

  // Switch girl orientation on click
  const HandleGirlClick = (e) => {
    if (state.moveable) {
      setState(prev => ({...prev, moveable: false}));
    } else if (!state.moveable) {
      setState(prev => ({...prev, moveable: true}));
    }
  }

  const HandleBgClick = (e) => {
    const { pageX: x, pageY: y } = e;

    if (!state.moveable) {
      setState(prev => ({
        ...prev, 
        moveable: true, 
        value: {
          x: (x - (window.innerWidth * .5)) - 145,
          y: y - 350
        }
      }));

      // setState(prev => ({...prev, moveable: true}));
      // HandleMouseMove(e);

    }
  }

  useEffect(() => {
    // if ((!state.frontClick && !state.sideClick) && !state.moveable) {
    //   document.querySelector('.device-button-wrapper').classList += " hide";
    // } else {
    //   document.querySelector('.device-button-wrapper').classList = "device-button-wrapper";
    // }

    if (!state.moveable) {
      document.querySelector('.girl').src = '../../photos/consumer-landing/g.gif';
      document.querySelector('.girl-top').src = '../../photos/consumer-landing/g.gif';
    }
    

    if (!state.moveable) {
      document.querySelector('.device-button-wrapper').classList = "device-button-wrapper";
      if (!state.girlSide) {
        document.querySelector('.girl').src = '../../photos/consumer-landing/g.gif';
      } else {
        document.querySelector('.girl').src = '../../photos/consumer-landing/gs.gif';
      }
    } else {
      document.querySelector('.device-button-wrapper').classList += " hide";
      if (!state.girlSide) {
        document.querySelector('.girl').src = '../../photos/consumer-landing/g-static.png';
      } else {
        document.querySelector('.girl').src = '../../photos/consumer-landing/gs-static.png';
      }
    }

  },[state.sideClick, state.frontClick, state.moveable, state.girlSide]);

  const HandleFrontClick = (e) => {
    setState(prev => ({...prev, girlSide: false}));
    if (!state.frontClick) {
      setState(prev => ({...prev, frontClick: true}));
    } else {
      setState(prev => ({...prev, frontClick: false}));
    }
    
    // if (!state.moveable) {
    //   document.querySelector('.girl').src = '../../photos/consumer-landing/g.gif';
    //   document.querySelector('.girl-top').src = '../../photos/consumer-landing/g.gif';
    // }

  }

  const HandleSideClick = (e) => {
    setState(prev => ({...prev, girlSide: true}));
    if (!state.sideClick) {
      setState(prev => ({...prev, sideClick: true}));
    } else {
      setState(prev => ({...prev, sideClick: false}));
    }
    
    // if (!state.moveable) {
    //   document.querySelector('.girl').src = '../../photos/consumer-landing/gs.gif';
    //   document.querySelector('.girl-top').src = '../../photos/consumer-landing/gs.gif';
    // }
  }

  return (
    <div
      onMouseMove={e => HandleMouseMove(e)}
      id="phone-wrapper"
      style={{
        position: "absolute",
        width: "100%",
        height: "100%",
        backgroundColor: "rgba(0,0,0,0)"
      }}
    >
      <img className="hero-background" onClick={e => HandleBgClick(e)} src="../../photos/consumer-landing/hero-background.svg" alt="Bodiometer Home postural assessment app - interactive animation background illustration" />
      <img className="girl" src="../../photos/consumer-landing/g-static.png" alt="Girl animation - illustration" />
      <div className="mobile-device" style={{left: state.value.x, top: state.value.y}}>
        <img src="../../photos/consumer-landing/mobile-device.svg" onMouseOver={e => HandleDeviceMouseOver()}  alt="Interactive mobile device to drag around page" />
        <div className="device-button-wrapper hide" onMouseLeave={e => HandleMouseLeave()} onMouseOver={e => HandleMouseMoveGirl(e)}>
          <Button className={!state.girlSide ? 'ipad-btn-blue' : ''} size="sm" variant="secondary" onClick={e => HandleFrontClick()}>Front</Button> 
          <Button className={state.girlSide ? 'ipad-btn-blue' : ''} size="sm" variant="secondary" onClick={e => HandleSideClick()}>Side</Button>
        </div>
      </div>
      <img className="indicator" src="../../photos/consumer-landing/indicator2.gif" alt="Initial indicator that users can hover over and move the mobile device" />
      <img className="girl-top" src="../../photos/consumer-landing/g-static.png" onMouseLeave={e => HandleMouseLeave()} onMouseOver={e => HandleMouseMoveGirl(e)} onClick={e => HandleGirlClick()} alt="Used to trigger the animation gif of the biomechanical assessment animation on the girl" />

    </div>
  );
}
