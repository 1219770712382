import React, { useState, Fragment, useRef, useEffect } from "react";
import { Col, Row, Form, Button, ButtonGroup } from "react-bootstrap";

import { Default as DefaultIntake, DefaultIsValid } from "../Models/index";

import "bootstrap/dist/css/bootstrap.min.css";
import "react-phone-number-input/style.css";
import "react-datetime/css/react-datetime.css";
import "../Assets/style.scss";

const RelationOptions = [
	"",
	"Self",
	"Parent",
	"Grandparent",
	"Spouse",
	"Father",
	"Mother",
	"Sister",
	"Brother",
	"Cousin",
	"Friend",
	"Roommate",
	"Neighbour",
	"Close Relative",
	"Distant Relative",
	"Other",
];

const ExtendedHealth = ({ data, requiredDb, validatedDb, isValidDb, pageCompleted, setPageCompleted, activeIndex, viewInvalidFields, onChange }) => {
	const [validatedFields, setValidatedFields] = useState({ ...validatedDb });
	const [isValidFields, setIsValidFields] = useState({ ...isValidDb });
	const requiredFields = { ...requiredDb };

	const Header = ({ label }) => {
		return <h2 style={{ textAlign: "center", marginBottom: "20px" }}>{label}</h2>;
	};

	const IsFieldValid = (str, type) => {
		if (type == "text") {
			return !!str;
		} else if (type == "email") {
			return (
				!!str &&
				str.toLowerCase().match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/).length > 0
			);
		}
		return true;
	};

	const CheckIfFieldsAreValid = () => {
		var isValid = true;
		if (data.ExistsExtendedHealth) {
			isValid = isValid && (requiredFields.InsuranceCompany ? isValidFields.InsuranceCompany : true);
			isValid = isValid && (requiredFields.GroupNumber ? isValidFields.GroupNumber : true);
			isValid = isValid && (requiredFields.MemberNumber ? isValidFields.MemberNumber : true);
			isValid = isValid && (requiredFields.PrimaryCardHolderName ? isValidFields.PrimaryCardHolderName : true);
			isValid = isValid && (requiredFields.RelationToCardHolder ? isValidFields.RelationToCardHolder : true);
		}
		return isValid;
	};

	const InitialFieldsValidity = () => {
		var cIsValid = { ...isValidFields };
		cIsValid.ExistsExtendedHealth = data.ExistsExtendedHealth == false || data.ExistsExtendedHealth == true;
		cIsValid.InsuranceCompany = IsFieldValid(data.InsuranceCompany, "text");
		cIsValid.GroupNumber = IsFieldValid(data.GroupNumber, "text");
		cIsValid.MemberNumber = IsFieldValid(data.MemberNumber, "text");
		cIsValid.PrimaryCardHolderName = IsFieldValid(data.PrimaryCardHolderName, "text");
		cIsValid.RelationToCardHolder = IsFieldValid(data.RelationToCardHolder, "text");
		return cIsValid;
	};

	useEffect(() => {
		pageCompleted[activeIndex] = CheckIfFieldsAreValid();
		setPageCompleted([...pageCompleted]);
	}, [isValidFields]);

	useEffect(() => {
		setIsValidFields({ ...InitialFieldsValidity() });
	}, []);

	useEffect(() => {
		const iterate = (obj) => {
			Object.keys(obj).forEach((key) => {
				if (typeof obj[key] === "object" && obj[key] !== null) {
					iterate(obj[key]);
				} else {
					obj[key] = true;
				}
			});
		};
		if (viewInvalidFields) {
			window.scrollTo({
				top: "0",
				behavior: "smooth",
			});
			var newObj = { ...validatedFields };
			iterate(newObj);
			setValidatedFields({ ...newObj });
		}
	}, [viewInvalidFields]);

	return (
		<div className="intake" style={styles.screen}>
			<Header label={"Extended Health Related Information"} />

			{/* extended health? */}
			<Row>
				<Col md={8}>
					<Form.Label>Do you have extended health coverage? {requiredFields.ExistsExtendedHealth ? "*" : ""}</Form.Label>
				</Col>
				<Col md={4} style={{ textAlign: "end" }}>
					<ButtonGroup>
						<Button
							active={data.ExistsExtendedHealth}
							onClick={(evt) => {
								onChange({ ...data, ExistsExtendedHealth: true });
								setValidatedFields({ ...validatedFields, ExistsExtendedHealth: true });
								setIsValidFields({ ...isValidFields, ExistsExtendedHealth: true });
							}}
						>
							Yes
						</Button>
						<Button
							active={!data.ExistsExtendedHealth}
							onClick={(evt) => {
								onChange({ ...DefaultIntake.ExtendedHealth, ExistsExtendedHealth: false });
								setValidatedFields({ ...validatedFields, ExistsExtendedHealth: true });
								setIsValidFields({ ...DefaultIsValid.ExtendedHealth, ExistsExtendedHealth: true });
							}}
						>
							No
						</Button>
					</ButtonGroup>
				</Col>
			</Row>

			{data.ExistsExtendedHealth && (
				<Fragment>
					{/* company name & group number */}
					<Row>
						<Col md={2}>
							<Form.Label>Insurance Company Name {requiredFields.InsuranceCompany ? "*" : ""}</Form.Label>
						</Col>
						<Col md={4}>
							<Form.Group className={validatedFields.InsuranceCompany && requiredFields.InsuranceCompany && !isValidFields.InsuranceCompany ? "isinvalid" : "isvalid"}>
								<Form.Control
									placeholder="insurance company name"
									value={data.InsuranceCompany}
									onChange={(evt) => {
										onChange({ ...data, InsuranceCompany: evt.target.value });
										setValidatedFields({ ...validatedFields, InsuranceCompany: true });
										setIsValidFields({ ...isValidFields, InsuranceCompany: IsFieldValid(evt.target.value, "text") });
									}}
								/>
							</Form.Group>
						</Col>

						<Col md={2}>
							<Form.Label>Group # {requiredFields.GroupNumber ? "*" : ""}</Form.Label>
						</Col>
						<Col md={4}>
							<Form.Group className={validatedFields.GroupNumber && requiredFields.GroupNumber && !isValidFields.GroupNumber ? "isinvalid" : "isvalid"}>
								<Form.Control
									placeholder="group number"
									value={data.GroupNumber}
									onChange={(evt) => {
										onChange({ ...data, GroupNumber: evt.target.value });
										setValidatedFields({ ...validatedFields, GroupNumber: true });
										setIsValidFields({ ...isValidFields, GroupNumber: IsFieldValid(evt.target.value, "text") });
									}}
								/>
							</Form.Group>
						</Col>
					</Row>

					{/* member number & card holder name */}
					<Row>
						<Col md={2}>
							<Form.Label>Member Number {requiredFields.MemberNumber ? "*" : ""}</Form.Label>
						</Col>
						<Col md={4}>
							<Form.Group className={validatedFields.MemberNumber && requiredFields.MemberNumber && !isValidFields.MemberNumber ? "isinvalid" : "isvalid"}>
								<Form.Control
									placeholder="member number"
									value={data.MemberNumber}
									onChange={(evt) => {
										onChange({ ...data, MemberNumber: evt.target.value });
										setValidatedFields({ ...validatedFields, MemberNumber: true });
										setIsValidFields({ ...isValidFields, MemberNumber: IsFieldValid(evt.target.value, "text") });
									}}
								/>
							</Form.Group>
						</Col>

						<Col md={2}>
							<Form.Label>Primary Card Holder Name {requiredFields.PrimaryCardHolderName ? "*" : ""}</Form.Label>
						</Col>
						<Col md={4}>
							<Form.Group className={validatedFields.PrimaryCardHolderName && requiredFields.PrimaryCardHolderName && !isValidFields.PrimaryCardHolderName ? "isinvalid" : "isvalid"}>
								<Form.Control
									placeholder="card holder name"
									value={data.PrimaryCardHolderName}
									onChange={(evt) => {
										onChange({ ...data, PrimaryCardHolderName: evt.target.value });
										setValidatedFields({ ...validatedFields, PrimaryCardHolderName: true });
										setIsValidFields({ ...isValidFields, PrimaryCardHolderName: IsFieldValid(evt.target.value, "text") });
									}}
								/>
							</Form.Group>
						</Col>
					</Row>
					
					{/* relationship to card holder name */}
					<Row>
						<Col md={2}>
							<Form.Label>Relationship to Card Holder {requiredFields.RelationToCardHolder ? "*" : ""}</Form.Label>
						</Col>
						<Col md={4}>
							<Form.Group className={validatedFields.RelationToCardHolder && requiredFields.RelationToCardHolder && !isValidFields.RelationToCardHolder ? "isinvalid" : "isvalid"}>
								<Form.Control
									as="select"
									onChange={(evt) => {
										onChange({
											...data,
											RelationToCardHolder: evt.target.value,
										});
										setValidatedFields({ ...validatedFields, RelationToCardHolder: true });
										setIsValidFields({ ...isValidFields, RelationToCardHolder: IsFieldValid(evt.target.value, "text") });
									}}
								>
									{RelationOptions.map((relation) => {
										return <option selected={data.RelationToCardHolder === relation ? true : false}>{relation}</option>;
									})}
								</Form.Control>
							</Form.Group>
						</Col>
					</Row>
				</Fragment>
			)}
		</div>
	);
};

const styles = {
	screen: {
		margin: "20px auto",
		maxWidth: "800px",
	},
};

export default ExtendedHealth;
