import React, { useEffect, Fragment, useState } from "react";
import { Alert, Row, Col } from "react-bootstrap";
import CryptoJS from "crypto-js";
import { useAppContext, UserDashboardContext } from "../lib/context.js";

import UserSideNav from "../components/dashboard/user-side-nav.js";
import UserTopNav from "../components/dashboard/user-top-nav.js";
import UserScansMain from "./user-scans-main.js";
import FQ from "./user-fq";
import UserJournals from "./user-journals.js";
import UserProfile from "./user-profile.js";
import UserSecurity from "./user-security.js";

import "bootstrap/dist/css/bootstrap.min.css";
import "../resources/styles/dashboard.scss";
import { dbUrl } from "../lib/config";

export default function UserDashboard(props) {
  const { appState, setAppState } = useAppContext();
  const savedActiveClinic = !!localStorage.getItem("_ACTIVECLINIC")
    ? localStorage.getItem("_ACTIVECLINIC")
    : "";

  const [dashboardState, setDashboardState] = useState({
    // put state variables
    MainAlert: [false, ""],
    IsSideNavCollapsed: false,
    IsMobileNavActive: false,
    ActiveSideTab: "dashboard",
    ActiveTopNav: "personal-scans",
    ActiveJournalKey: "",
    ActiveClinicId: savedActiveClinic,
    isPostureImageZoomed: false,
    zoomedViewDirection: "front",

    scanConsent: false,
    journalConsent: false,
    consentUpdateConfirmation: false,
    consentUpdateAttempt: false,
  });

  useEffect(() => {
    let AuthData = localStorage.getItem("_USER");
    if (!!AuthData) {
      AuthData = JSON.parse(
        CryptoJS.enc.Utf8.stringify(
          CryptoJS.AES.decrypt(AuthData, appState.PASSPHRASE)
        )
      );
      appState.isUserAuthenticated = AuthData.isUserAuthenticated;
      appState.userData = AuthData.userData;

      if (!appState.isUserAuthenticated) {
        window.location.href = "/user/login";
      } else {
        setAppState({ ...appState });
      }
    } else {
      window.location.href = "/user/login";
    }
  }, []);

  useEffect(() => {
    if (dashboardState.ActiveClinicId in appState.userData.PatientUserMerges) {
      setDashboardState({
        ...dashboardState,
        scanConsent:
          appState.userData.PatientUserMerges[dashboardState.ActiveClinicId]
            .ScanConsent,
        journalConsent:
          appState.userData.PatientUserMerges[dashboardState.ActiveClinicId]
            .JournalConsent,
      });
    }
  }, [dashboardState.ActiveClinicId, appState]);

  return (
    <Fragment>
      {appState.isUserAuthenticated && (
        <UserDashboardContext.Provider
          value={{ dashboardState, setDashboardState }}
        >
          <Alert
            variant="warning"
            className="main-alert"
            show={dashboardState.MainAlert[0]}
          >
            <div className="flex-row flex-center">
              <p className="no-margin">{dashboardState.MainAlert[1]}</p>
            </div>
          </Alert>

          <div className="application-wrapper">
            <UserTopNav />

            <div
              style={{ display: "flex", flexDirection: "row", width: "100%" }}
            >
              <div>
                <UserSideNav />
              </div>

              {dashboardState.ActiveSideTab == "dashboard" && (
                <div style={{ width: "100%" }}>
                  <section
                    className="module-top-nav"
                    style={{ marginTop: "12vh" }}
                  >
                    <Row>
                      <Col>
                        <div
                          className={
                            dashboardState.ActiveTopNav == "personal-scans"
                              ? "active"
                              : ""
                          }
                          onClick={() =>
                            setDashboardState({
                              ...dashboardState,
                              ActiveTopNav: "personal-scans",
                            })
                          }
                        >
                          Personal Scans
                        </div>
                        <div
                          className={
                            dashboardState.ActiveTopNav == "clinical-scans"
                              ? "active"
                              : ""
                          }
                          onClick={() =>
                            setDashboardState({
                              ...dashboardState,
                              ActiveTopNav: "clinical-scans",
                            })
                          }
                        >
                          Clinical Scans
                        </div>
                        <div
                          className={
                            dashboardState.ActiveTopNav == "journals"
                              ? "active"
                              : ""
                          }
                          onClick={() =>
                            setDashboardState({
                              ...dashboardState,
                              ActiveTopNav: "journals",
                            })
                          }
                        >
                          Journals
                        </div>
                        <div
                          className={
                            dashboardState.ActiveTopNav == "fq" ? "active" : ""
                          }
                          onClick={() =>
                            setDashboardState({
                              ...dashboardState,
                              ActiveTopNav: "fq",
                            })
                          }
                        >
                          Functional Questionnaire
                        </div>
                      </Col>
                    </Row>

                    <Row>
                      <Col>
                        <div className="border"></div>
                      </Col>
                    </Row>
                  </section>

                  {dashboardState.ActiveTopNav == "personal-scans" && (
                    <UserScansMain isPersonal={true} dbUrl={dbUrl} />
                  )}
                  {dashboardState.ActiveTopNav == "clinical-scans" && (
                    <UserScansMain isPersonal={false} dbUrl={dbUrl} />
                  )}

                  {dashboardState.ActiveTopNav == "fq" && <FQ dbUrl={dbUrl} />}

                  {dashboardState.ActiveTopNav == "journals" && (
                    <UserJournals dbUrl={dbUrl} />
                  )}
                </div>
              )}
              {dashboardState.ActiveSideTab == "profile" && (
                <UserProfile dbUrl={dbUrl} />
              )}
              {dashboardState.ActiveSideTab == "setting" && (
                <UserSecurity dbUrl={dbUrl} />
              )}
            </div>
          </div>
        </UserDashboardContext.Provider>
      )}
    </Fragment>
  );
}
