import React, {useState, useMemo, useEffect} from "react";
import {useParams} from "react-router-dom";
import {Container, Col, Row, Form, Alert} from "react-bootstrap";
import axios from "axios";
import countryList from "react-select-country-list";
import CryptoJS from "crypto-js";
import md5Hash from "../resources/helpers/md5.js";
import formatPhone from "../resources/helpers/format-phone.js";
import DisplayPhone from "../resources/helpers/display-phone.js";

import UiButton from "../components/dashboard/ui-button.js";
import UiPassword from "../components/dashboard/ui-password-2.js";

import logo from "../resources/photos/bodiometer-logo-stacked.svg";

import "../resources/styles/dashboard.scss";
import "bootstrap/dist/css/bootstrap.min.css";

export default function LandingRegisterBusiness(props) {
  const params = useParams();

  let Email = params.email;

  const [state, setState] = useState({
    Validated: false,
    Title: "Title",
    Success: [false, ""],
    Error: [false, ""],
    Fname: "",
    Lname: "",
    Phone: "",
    Ext: "",
    UserID: "",
    Email: Email,
    Country: "",
    PassType: "password",
    PassValue: "",
    PassValue2: "",
    UsernameInValid: [false, "Something went wrong..."],
    EmailInValid: [false, "Something went wrong..."],
    InValid: [false, "Something went wrong..."],
    InValid2: [false, "Something went wrong..."],
    PhoneInValid: [false, ""],
    BusinessJoining: null,
    DBAccessToken: "",
    HasAgreed: false,
  });

  useEffect(() => {
    axios({
      method: "post",
      url: props.dbUrl + "/api/token/dbo",
      headers: {
        password: "746C419785D84DC8B3A20B9195363105",
      },
      timeout: 10000,
    }).then((responseOne) => {
      if (responseOne.status == 200) {
        // console.log(responseOne.data);
        setState((prev) => {
          return {...prev, DBAccessToken: "Bearer " + responseOne.data};
        });
      }
    });
  }, []);

  let environment = process.env.NODE_ENV;
  let dbUrl = null;
  if (environment == "development") {
    // domain = 'http://localhost:54928/'; // Original
    dbUrl = "https://bodiometerhomerestapi-dot-project-4839952831808961167.appspot.com";
  } else {
    dbUrl = "https://bodiometerhomerestapi-dot-project-4839952831808961167.appspot.com";
  }
  const BusinessId = params.businessId;
  const RoleId = params.roleId;
  const Token = "Bearer " + params.token;

  console.log(BusinessId, RoleId, Token);

  // Get and update BusinessJoining
  useEffect(() => {
    if (BusinessId) {
      const Headers = {
        headers: {
          Authorization: Token,
          businessId: BusinessId,
        },
      };

      axios.get(dbUrl + `/api/businessaccounts/v1/businessid/details/summary`, Headers).then((res) => {
        if (res.data.success) {
          setState({...state, BusinessJoining: res.data.data});
        } else {
          setState({...state, Error: [true, "Something went wrong fetching organization"]});
        }
      });
    }
  }, []);

  const HandlePassChange = (e, retype) => {
    if (!retype) {
      setState({...state, PassValue: e.currentTarget.value});
    } else if (retype) {
      setState({...state, PassValue2: e.currentTarget.value});
    }
  };

  const PasswordCheck = (e, retype) => {
    const field = e.currentTarget;

    if (field.value.length < 6) {
      setState({...state, InValid: [true, "Password is too short"]});
    } else if (field.value.length >= 6) {
      setState({...state, InValid: [false, ""]});
    }
    if (retype) {
      if (field.value !== state.PassValue) {
        setState({...state, InValid2: [true, "Password doesn't match"]});
      } else {
        setState({...state, InValid2: [false, ""]});
      }
    }
  };

  const HandleSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();

    if (!state.InValid[0] && !state.InValid2[0] && !state.PhoneInValid[0]) {
      // check if user exists

      const VerifyUserIdHeaders = {
        headers: {
          Authorization: Token,
          userId: state.UserID,
        },
      };

      const VerifyUserEmailHeaders = {
        headers: {
          Authorization: Token,
          email: state.Email,
        },
      };

      const promiseOne = axios.get(dbUrl + `/api/businessusers/v1/verify/userid`, VerifyUserIdHeaders);

      const promiseTwo = axios.get(dbUrl + `/api/businessusers/v1/verify/email`, VerifyUserEmailHeaders);

      Promise.all([promiseOne, promiseTwo]).then((arrayOfValues) => {
        let [verifyUserId, verifyEmail] = arrayOfValues;
        if (verifyUserId.data.success) {
          setState({...state, UsernameInValid: [true, "Username already exists"]});
        }
        if (verifyEmail.data.success) {
          setState({...state, EmailInValid: [true, "Email already exists, please login instead"]});
        }
        if (!verifyUserId.data.success && !verifyEmail.data.success) {
          setState({...state, EmailInValid: [false, ""], UsernameInValid: [false, ""]});
          props.setState({...props.parentState, Preloader: true}); // start preloader

          const NewUserPostData = {
            userDetail: {
              userId: state.UserID,
              password: md5Hash(state.PassValue),
              salutation: state.Title,
              firstName: state.Fname,
              lastName: state.Lname,
              email: state.Email,
              emailActivated: true,
              phoneNumber: formatPhone(state.Phone),
              phoneNumberExt: state.Ext,
            },
            userAddress: {
              country: state.Country.toUpperCase(),
            },
          };

          const NewUserPostHeaders = {
            headers: {
              Authorization: Token,
              "Content-Type": "application/json",
            },
          };

          axios.post(dbUrl + "/api/businessusers/v1/create", NewUserPostData, NewUserPostHeaders).then((user) => {
            props.setState({...props.parentState, Preloader: false}); // end preloader

            if (user.data.success) {
              // update role with user

              const UpdateRolePostData = {
                userId: state.UserID,
              };

              const UpdateRolePostHeaders = {
                headers: {
                  Authorization: Token,
                  "Content-Type": "application/json",
                  roleId: RoleId,
                },
              };

              axios.put(dbUrl + "/api/businessroles/v1/update/roleid", UpdateRolePostData, UpdateRolePostHeaders).then((updateRole) => {
                // role updated
                if (updateRole.data.success) {
                  window.location.href = "/portal";
                } else {
                  setState({...state, UserInValid: [true, "Something went wrong"]});
                }
              });

              // // log user in!
              // props.setState(prev => {

              //   // const userObj = { id: person.userId, fname: person.firstName, lname: person.lastName, title: person.salutation, phone: person.phoneNumber, ext: person.phoneNumberExt, userId: person.userId, email: person.email, emailActivated: person.emailActivated, organization: [{ id: 1, role: 'owner', status: 'active', created: ConvertDate('2020-05-25T20:12:34', { year: 'numeric', month: 'long', day: 'numeric' })}], notifications: { newStaff: true, staffChange: false } };
              //   const userObj = {
              //     fname: state.Fname,
              //     lname: state.Lname,
              //     title: state.Title,
              //     phone: state.Phone,
              //     ext: state.Ext,
              //     userId: state.UserID,
              //     email: state.Email,
              //     emailActivated: false,
              //     organization: [],
              //     notifications: { newStaff: true, staffChange: false }
              //   };
              //   return {...props.parentState, User: userObj, View: 'overview', EmailVerified: false, Refresh: props.parentState.Refresh + 1}
              // })
            } else {
              setState({...state, Error: [true, "Something went wrong fetching the data"]});
            }
          });
        }
      });
    }
  };

  const GoToLogin = (e) => {
    props.setView("login");
    window.location.href = "/portal";
  };

  const CheckValid = (thing, value) => {
    if (thing === "Phone") {
      if (value.length == 0) {
        setState({...state, PhoneInValid: [true, "Ivalid Number"]});
      } else {
        setState({...state, PhoneInValid: [false, ""]});
      }
    }
  };

  const Countries = useMemo(() => countryList().getLabels(), []);

  return (
    <section className="landing-form-section-wrapper">
      <Container className="centered-container">
        <Row>
          <Col className="landing-form-wrapper" lg={5} md={6} sm={10}>
            <img src={logo} width="250" alt="Bodiometer Logo" />
            <Alert variant="warning" show={state.Error[0] ? true : false}>
              <div className="flex-row">
                <p>{state.Error[1]}</p>
              </div>
            </Alert>
            <Alert variant="info" show={state.Success[0] ? true : false}>
              <div className="flex-row">
                <p>{state.Success[1]}</p>
              </div>
            </Alert>
            <h2 className="blue-color">Register</h2>
            {state.BusinessJoining && <p>Create a new user account and join {state.BusinessJoining.businessName}</p>}
            <Form validated={state.Validated} onSubmit={(e) => HandleSubmit(e)}>
              <Row>
                <Form.Group as={Col} controlId="register_title" lg={3}>
                  <Form.Control size="sm" as="select" placeholder="Title" custom className="ui-select" value={state.Title} onChange={(e) => setState({...state, Title: e.currentTarget.value})}>
                    {props.titles.map((title) => (
                      <option value={title}>{title}</option>
                    ))}
                  </Form.Control>
                </Form.Group>
                <Form.Group as={Col} controlId="register_fname">
                  <Form.Control size="sm" type="input" value={state.Fname} onChange={(e) => setState({...state, Fname: e.currentTarget.value})} placeholder="First Name" required />
                </Form.Group>
                <Form.Group as={Col} controlId="register_lname">
                  <Form.Control size="sm" type="input" value={state.Lname} onChange={(e) => setState({...state, Lname: e.currentTarget.value})} placeholder="Last Name" required />
                </Form.Group>
              </Row>

              <Row>
                <Form.Group as={Col} controlId="register_phone">
                  <Form.Control size="sm" type="phone" value={DisplayPhone(state.Phone)} onChange={(e) => setState({...state, Phone: e.currentTarget.value})} onBlur={(e) => CheckValid("Phone", e.currentTarget.value)} placeholder="Phone" required />
                  <Form.Control.Feedback type="invalid">{state.PhoneInValid[1]}</Form.Control.Feedback>
                  {/* <Form.Control size="sm" type="phone" value={state.Phone} onChange={e => console.log('number.typeof: ', Numbers.includes(e.currentTarget.value))} placeholder="Phone" required/> */}
                </Form.Group>
                <Form.Group as={Col} controlId="register_extension">
                  <Form.Control size="sm" type="input" value={state.Ext} onChange={(e) => setState({...state, Ext: e.currentTarget.value})} placeholder="Ext." />
                </Form.Group>
              </Row>

              <Row>
                <Form.Group as={Col} controlId="register_userId">
                  <Form.Control size="sm" type="input" value={state.UserID} onChange={(e) => setState({...state, UserID: e.currentTarget.value})} placeholder="User ID" required isInvalid={state.UsernameInValid[0]} />
                  <Form.Control.Feedback type="invalid">{state.UsernameInValid[1]}</Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} controlId="register_country">
                  <Form.Control size="sm" as="select" options={Countries} placeholder="Country" custom className="ui-select" value={state.Country} onChange={(e) => setState({...state, Country: e.currentTarget.value})} required>
                    <option value="">Country</option>
                    {Countries.map((country) => (
                      <option value={country}>{country}</option>
                    ))}
                  </Form.Control>
                </Form.Group>
              </Row>

              <Row>
                <Form.Group as={Col} controlId="register_email">
                  <Form.Control size="sm" type="email" value={state.Email} onChange={(e) => setState({...state, Email: e.currentTarget.value})} placeholder="Email" required disabled isInvalid={state.EmailInValid[0]} />
                  <Form.Control.Feedback type="invalid">{state.EmailInValid[1]}</Form.Control.Feedback>
                </Form.Group>
              </Row>

              <Row>
                <UiPassword text="At least 6 characters, but longer is better." placeholder="Password" controlId="register_password" feedback="Password must be at least 6 characters in length" value={state.PassValue} onChange={HandlePassChange} onBlur={PasswordCheck} isInvalid={state.InValid[0]} message={state.InValid[1]} />
              </Row>

              <Row>
                <UiPassword placeholder="Retype Password" controlId="register_password2" retype value={state.PassValue2} onChange={HandlePassChange} onBlur={PasswordCheck} isInvalid={state.InValid2[0]} message={state.InValid2[1]} />
              </Row>

              <Row>
                <Form.Group controlId="terms_checkbox" className="form-footer-wrapper terms-wrapper">
                  <Form.Check type="checkbox">
                    <Form.Check.Input
                      checked={state.HasAgreed}
                      onChange={() => {
                        setState((prev) => {
                          return {...prev, HasAgreed: !prev.HasAgreed};
                        });
                      }}
                      type={"checkbox"}
                      isValid={state.HasAgreed}
                    />
                    <Form.Check.Label className="gray-color">
                      I agree to the{" "}
                      <a target="_blank" href="/terms">
                        {"Terms & Conditions"}
                      </a>{" "}
                      and{" "}
                      <a target="_blank" href="/privacy">
                        {"Privacy Policy"}
                      </a>
                    </Form.Check.Label>
                    {/* <Form.Control.Feedback type="invalid">You have to check the box</Form.Control.Feedback> */}
                  </Form.Check>
                </Form.Group>
              </Row>

              <Row>
                <Form.Group controlId="consent" className="form-footer-wrapper">
                  <UiButton disabled={!state.HasAgreed} type="submit" onSubmit={HandleSubmit}>
                    Register
                  </UiButton>
                </Form.Group>
              </Row>
            </Form>
          </Col>
        </Row>
      </Container>
      <br />
      {/* <p className="centered-text smaller-text">Already have an account? <a href="#" onClick={e => GoToLogin(e)}>Login</a></p> */}
    </section>
  );
}
